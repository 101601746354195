/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import Range from "./Range";

function Step9({ onSubmit, handleBack, isSubmitting, schoolData }) {
  return (
    <div className=" Step step9">
      <div className="Step__inner ">
        <div className="form_wrap">
          <Form noValidate onSubmit={onSubmit}>
            <div className="form__inner">
              <div className="Step__inner__header">
                <img
                  src={schoolData.logo}
                  alt=""
                  style={{ display: "inline" }}
                />
                <p>CIRR Student Intent Survey</p>
              </div>
              <div className="Step__inner__body">
                <p className="title">You’re almost done!</p>
                <p className="text--small">
                  Based on your responses, we’d like you to sign the follow
                  documents:
                </p>
              </div>
              <div className="button-group">
                <button
                  className="button button--back"
                  type="submit"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  className="button button--primary"
                  type="submit"
                  onSubmit={onSubmit}
                  disabled={isSubmitting}
                >
                  Review & Sign
                </button>
              </div>
            </div>
          </Form>
          <Range percent={82} />
        </div>
      </div>
    </div>
  );
}

Step9.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default Step9;
