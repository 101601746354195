import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Loading } from "../../../components/common";
import { CoverSheet } from "../../../components/dashboard/reporting";

function Root() {
  const Params = useParams();
  const reportName = Params.reportName;
  const storeReportingPreview = useSelector((store) => store.reporting.preview);
  const selected = useSelector((store) => store.reporting.selected);

  if (storeReportingPreview.loading) {
    return <Loading />;
  }

  if (!storeReportingPreview.data || !storeReportingPreview.data.cover_sheet) {
    return null;
  }

  let dataInfo = {};
  if (reportName === "accet" && selected.month) {
    dataInfo = storeReportingPreview.data.cover_sheet[selected.month.value];
  } else dataInfo = storeReportingPreview.data.cover_sheet;

  return <CoverSheet dataInfo={dataInfo} />;
}

export default Root;
