import * as types from "../constants";
import request from "../../utils/request";

export function postOnboardingValidate(data) {
  return (dispatch) => {
    dispatch({ type: types.POST_ONBOARDING_VALIDATE });
    return request()
      .post("/onboarding/validate", { school: data })
      .then((dataResponse) => {
        dispatch({
          type: types.POST_ONBOARDING_VALIDATE_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.POST_ONBOARDING_VALIDATE_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function postOnboardingAgencies(data) {
  return (dispatch) => {
    dispatch({ type: types.POST_ONBOARDING_AGENCIES });
    return request()
      .post("/onboarding/agencies", { school: data })
      .then((dataResponse) => {
        dispatch({
          type: types.POST_ONBOARDING_AGENCIES_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.POST_ONBOARDING_AGENCIES_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createOnboarding(data) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_ONBOARDING });
    return request()
      .post("/onboarding/create", { school: data })
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_ONBOARDING_SUCCEED,
          payload: dataResponse,
        });
        return dataResponse;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_ONBOARDING_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
