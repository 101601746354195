import React from "react";

function Branches() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
    >
      <path
        d="M11 17C12.1046 17 13 16.1046 13 15C13 13.8954 12.1046 13 11 13C9.89543 13 9 13.8954 9 15C9 16.1046 9.89543 17 11 17Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 5H12V8L17.7071 13.7071C17.7926 13.7926 17.861 13.892 17.9102 14H21C21.5523 14 22 14.4477 22 15V20C22 20.5523 21.5523 21 21 21H1C0.447715 21 0 20.5523 0 20V15C0 14.4477 0.447715 14 1 14H4.08982C4.13896 13.892 4.20739 13.7926 4.29289 13.7071L10 8V1C10 0.447715 10.4477 0 11 0H16C16.5523 0 17 0.447715 17 1V4C17 4.55228 16.5523 5 16 5ZM12 2V3H15V2H12ZM18 19V16H20V19H18ZM16 14.8284V19H6V14.8284L10.841 9.98743C10.8928 9.9957 10.9459 10 11 10C11.0541 10 11.1072 9.9957 11.159 9.98743L16 14.8284ZM4 16H2L2 19H4V16Z"
        fill="white"
      />
    </svg>
  );
}

export default Branches;
