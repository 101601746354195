import React from "react";
import css from "./styles.module.scss";

type Props = {
  children: ReactNode;
};

export function Card(props: Props) {
  const { children } = props;

  return (
    <div className={css.card}>
      <div className={css.inner}>{children}</div>
    </div>
  );
}
