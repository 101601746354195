import * as types from "../constants";
import request from "../../utils/request";

export function getCIRRSurveyInfo(params, token) {
  return (dispatch) => {
    dispatch({ type: types.GET_SURVEY_INFO });
    return request()
      .get(`/surveys/cirr_student_intent/info?token=${token}`, { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_CIRR_SURVEY_INFO_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_CIRR_SURVEY_INFO_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getCIRRSurveySign(params, token) {
  return (dispatch) => {
    dispatch({ type: types.GET_CIRR_SURVEY_SIGN });
    return request()
      .get(`/surveys/cirr_student_intent/sign?token=${token}`, {
        params,
      })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_CIRR_SURVEY_SIGN_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_CIRR_SURVEY_SIGN_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createCIRRSurvey(data, token) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_CIRR_SURVEY });
    return request()
      .post(`/surveys/cirr_student_intent/create?token=${token}`, data)
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_CIRR_SURVEY_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_CIRR_SURVEY_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createCIRRSurveyComplete(token) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_CIRR_SURVEY_COMPLETE });
    return request()
      .post(`/surveys/cirr_student_intent/complete?token=${token}`, {})
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_CIRR_SURVEY_COMPLETE_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_CIRR_SURVEY_COMPLETE_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
