/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/default-param-last */
import * as types from "../constants";

const initialState = {
  student: {
    data: {},
    loading: false,
    error: null,
  },
  enrollment: {
    list: {
      data: [],
      loading: false,
      error: null,
    },
    create: {
      data: {},
      loading: false,
      error: null,
    },
    delete: {
      data: {},
      loading: false,
      error: null,
    },
    update: {
      data: {},
      loading: false,
      error: null,
    },
  },
  placement: {
    list: {
      data: [],
      loading: false,
      error: null,
    },
    create: {
      data: {},
      loading: false,
      error: null,
    },
    delete: {
      data: {},
      loading: false,
      error: null,
    },
    update: {
      data: {},
      loading: false,
      error: null,
    },
  },
  licensureExam: {
    list: {
      data: [],
      loading: false,
      error: null,
    },
    listLicensureExams: {
      data: [],
      loading: false,
      error: null,
    },
    create: {
      data: {},
      loading: false,
      error: null,
    },
    delete: {
      data: {},
      loading: false,
      error: null,
    },
    update: {
      data: {},
      loading: false,
      error: null,
    },
  },
  financialAid: {
    list: {
      data: [],
      loading: false,
      error: null,
    },
    financialAidPrograms: {
      data: [],
      loading: false,
      error: null,
    },
    create: {
      data: {},
      loading: false,
      error: null,
    },
    delete: {
      data: {},
      loading: false,
      error: null,
    },
    update: {
      data: {},
      loading: false,
      error: null,
    },
  },
  studentTasks: {
    list: {
      data: [],
      loading: false,
      error: null,
    },
  },
  contactLog: {
    list: {
      data: [],
      loading: false,
      error: null,
    },
    create: {
      data: {},
      loading: false,
      error: null,
    },
    delete: {
      data: {},
      loading: false,
      error: null,
    },
    update: {
      data: {},
      loading: false,
      error: null,
    },
  },
  changeLog: {
    list: {
      data: [],
      loading: false,
      error: null,
    },
    listToRender: [],
    isOutOfData: false,
    page: 1,
  },
  documents: {
    list: {
      data: [],
      loading: false,
      error: null,
    },
  },
  programs: {
    data: [],
    loading: false,
    error: null,
  },
  deleteDocument: {
    data: {},
    loading: false,
    error: null,
  },
  deleteStudent: {
    data: {},
    loading: false,
    error: null,
  },
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    // get student
    case types.GET_STUDENT:
      return {
        ...state,
        student: {
          ...state.student,
          loading: true,
        },
      };
    case types.GET_STUDENT_SUCCEED:
      return {
        ...state,
        student: {
          ...state.student,
          data: payload.data,
          loading: false,
        },
      };
    case types.GET_STUDENT_FAIL:
      return {
        ...state,
        student: {
          ...state.list,
          error: payload,
          loading: false,
        },
      };

    // get enrollments
    case types.GET_ENROLLMENTS:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          list: {
            ...state.enrollment.list,
            loading: true,
          },
        },
      };
    case types.GET_ENROLLMENTS_SUCCEED:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          list: {
            ...state.enrollment.list,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_ENROLLMENTS_FAIL:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          list: {
            ...state.enrollment.list,
            error: payload,
            loading: false,
          },
        },
      };
    // create enrollment
    case types.CREATE_ENROLLMENT:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          create: {
            ...state.enrollment.create,
            loading: true,
            error: {},
          },
        },
      };
    case types.CREATE_ENROLLMENT_SUCCEED:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          create: {
            ...state.enrollment.create,
            data: payload,
            loading: false,
          },
        },
      };
    case types.CREATE_ENROLLMENT_FAIL:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          create: {
            ...state.enrollment.create,
            error: payload,
            loading: false,
          },
        },
      };

    // update enrollment
    case types.UPDATE_ENROLLMENT:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          update: {
            ...initialState.enrollment.update,
            loading: true,
          },
        },
      };
    case types.UPDATE_ENROLLMENT_SUCCEED:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          update: {
            ...state.enrollment.update,
            data: payload,
            loading: false,
          },
        },
      };
    case types.UPDATE_ENROLLMENT_FAIL:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          update: {
            ...state.enrollment.update,
            error: payload,
            loading: false,
          },
        },
      };
    // delete enrollment
    case types.DELETE_ENROLLMENT:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          delete: {
            ...initialState.enrollment.delete,
            loading: true,
          },
        },
      };
    case types.DELETE_ENROLLMENT_SUCCEED:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          delete: {
            ...state.enrollment.delete,
            data: payload,
            loading: false,
          },
        },
      };
    case types.DELETE_ENROLLMENT_FAIL:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          delete: {
            ...state.enrollment.delete,
            error: payload,
            loading: false,
          },
        },
      };

    // get placements
    case types.GET_PLACEMENTS:
      return {
        ...state,
        placement: {
          ...state.placement,
          list: {
            ...state.placement.list,
            loading: true,
          },
        },
      };
    case types.GET_PLACEMENTS_SUCCEED:
      return {
        ...state,
        placement: {
          ...state.placement,
          list: {
            ...state.placement.list,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_PLACEMENTS_FAIL:
      return {
        ...state,
        placement: {
          ...state.placement,
          list: {
            ...state.placement.list,
            error: payload,
            loading: false,
          },
        },
      };
    // create placement
    case types.CREATE_PLACEMENT:
      return {
        ...state,
        placement: {
          ...state.placement,
          create: {
            ...state.placement.create,
            loading: true,
            error: {},
          },
        },
      };
    case types.CREATE_PLACEMENT_SUCCEED:
      return {
        ...state,
        placement: {
          ...state.placement,
          create: {
            ...state.placement.create,
            data: payload,
            loading: false,
          },
        },
      };
    case types.CREATE_PLACEMENT_FAIL:
      return {
        ...state,
        placement: {
          ...state.placement,
          create: {
            ...state.placement.create,
            error: payload,
            loading: false,
          },
        },
      };

    // update placement
    case types.UPDATE_PLACEMENT:
      return {
        ...state,
        placement: {
          ...state.placement,
          update: {
            ...initialState.placement.update,
            loading: true,
          },
        },
      };
    case types.UPDATE_PLACEMENT_SUCCEED:
      return {
        ...state,
        placement: {
          ...state.placement,
          update: {
            ...state.placement.update,
            data: payload,
            loading: false,
          },
        },
      };
    case types.UPDATE_PLACEMENT_FAIL:
      return {
        ...state,
        placement: {
          ...state.placement,
          update: {
            ...state.placement.update,
            error: payload,
            loading: false,
          },
        },
      };
    // delete placement
    case types.DELETE_PLACEMENT:
      return {
        ...state,
        placement: {
          ...state.placement,
          delete: {
            ...initialState.placement.delete,
            loading: true,
          },
        },
      };
    case types.DELETE_PLACEMENT_SUCCEED:
      return {
        ...state,
        placement: {
          ...state.placement,
          delete: {
            ...state.placement.delete,
            data: payload,
            loading: false,
          },
        },
      };
    case types.DELETE_PLACEMENT_FAIL:
      return {
        ...state,
        placement: {
          ...state.placement,
          delete: {
            ...state.placement.delete,
            error: payload,
            loading: false,
          },
        },
      };

    // get licensure exams
    case types.GET_LICENSURE_EXAMS:
      return {
        ...state,
        licensureExam: {
          ...state.licensureExam,
          list: {
            ...state.licensureExam.list,
            loading: true,
          },
        },
      };
    case types.GET_LICENSURE_EXAMS_SUCCEED:
      return {
        ...state,
        licensureExam: {
          ...state.licensureExam,
          list: {
            ...state.licensureExam.list,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_LICENSURE_EXAMS_FAIL:
      return {
        ...state,
        licensureExam: {
          ...state.licensureExam,
          list: {
            ...state.licensureExam.list,
            error: payload,
            loading: false,
          },
        },
      };

    // get programs licensure exams
    case types.GET_PROGRAMS_LICENSURE_EXAMS:
      return {
        ...state,
        licensureExam: {
          ...state.licensureExam,
          listLicensureExams: {
            ...state.licensureExam.listLicensureExams,
            loading: true,
          },
        },
      };
    case types.GET_PROGRAMS_LICENSURE_EXAMS_SUCCEED:
      return {
        ...state,
        licensureExam: {
          ...state.licensureExam,
          listLicensureExams: {
            ...state.licensureExam.listLicensureExams,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_PROGRAMS_LICENSURE_EXAMS_FAIL:
      return {
        ...state,
        licensureExam: {
          ...state.licensureExam,
          listLicensureExams: {
            ...state.licensureExam.listLicensureExams,
            error: payload,
            loading: false,
          },
        },
      };
    // create licensure exam
    case types.CREATE_LICENSURE_EXAM:
      return {
        ...state,
        licensureExam: {
          ...state.licensureExam,
          create: {
            ...state.licensureExam.create,
            loading: true,
            error: {},
          },
        },
      };
    case types.CREATE_LICENSURE_EXAM_SUCCEED:
      return {
        ...state,
        licensureExam: {
          ...state.licensureExam,
          create: {
            ...state.licensureExam.create,
            data: payload,
            loading: false,
          },
        },
      };
    case types.CREATE_LICENSURE_EXAM_FAIL:
      return {
        ...state,
        licensureExam: {
          ...state.licensureExam,
          create: {
            ...state.licensureExam.create,
            error: payload,
            loading: false,
          },
        },
      };

    // update licensure exam
    case types.UPDATE_LICENSURE_EXAM:
      return {
        ...state,
        licensureExam: {
          ...state.licensureExam,
          update: {
            ...initialState.licensureExam.update,
            loading: true,
          },
        },
      };
    case types.UPDATE_LICENSURE_EXAM_SUCCEED:
      return {
        ...state,
        licensureExam: {
          ...state.licensureExam,
          update: {
            ...state.licensureExam.update,
            data: payload,
            loading: false,
          },
        },
      };
    case types.UPDATE_LICENSURE_EXAM_FAIL:
      return {
        ...state,
        licensureExam: {
          ...state.licensureExam,
          update: {
            ...state.licensureExam.update,
            error: payload,
            loading: false,
          },
        },
      };
    // delete licensure exam
    case types.DELETE_LICENSURE_EXAM:
      return {
        ...state,
        licensureExam: {
          ...state.licensureExam,
          delete: {
            ...initialState.licensureExam.delete,
            loading: true,
          },
        },
      };
    case types.DELETE_LICENSURE_EXAM_SUCCEED:
      return {
        ...state,
        licensureExam: {
          ...state.licensureExam,
          delete: {
            ...state.licensureExam.delete,
            data: payload,
            loading: false,
          },
        },
      };
    case types.DELETE_LICENSURE_EXAM_FAIL:
      return {
        ...state,
        licensureExam: {
          ...state.licensureExam,
          delete: {
            ...state.licensureExam.delete,
            error: payload,
            loading: false,
          },
        },
      };

    // get financial aid
    case types.GET_STUDENT_FINANCIAL_AID:
      return {
        ...state,
        financialAid: {
          ...state.financialAid,
          list: {
            ...state.financialAid.list,
            loading: true,
          },
        },
      };
    case types.GET_STUDENT_FINANCIAL_AID_SUCCEED:
      return {
        ...state,
        financialAid: {
          ...state.financialAid,
          list: {
            ...state.financialAid.list,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_STUDENT_FINANCIAL_AID_FAIL:
      return {
        ...state,
        financialAid: {
          ...state.financialAid,
          list: {
            ...state.financialAid.list,
            error: payload,
            loading: false,
          },
        },
      };

    // get student tasks
    case types.GET_STUDENT_TASKS:
      return {
        ...state,
        studentTasks: {
          ...state.studentTasks,
          list: {
            ...state.studentTasks.list,
            loading: true,
          },
        },
      };
    case types.GET_STUDENT_TASKS_SUCCEED:
      return {
        ...state,
        studentTasks: {
          ...state.studentTasks,
          list: {
            ...state.studentTasks.list,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_STUDENT_TASKS_FAIL:
      return {
        ...state,
        studentTasks: {
          ...state.studentTasks,
          list: {
            ...state.studentTasks.list,
            error: payload,
            loading: false,
          },
        },
      };

    // get financial aid programs
    case types.GET_STUDENT_FINANCIAL_AID_PROGRAMS:
      return {
        ...state,
        financialAid: {
          ...state.financialAid,
          financialAidPrograms: {
            ...state.financialAid.financialAidPrograms,
            loading: true,
          },
        },
      };
    case types.GET_STUDENT_FINANCIAL_AID_PROGRAMS_SUCCEED:
      return {
        ...state,
        financialAid: {
          ...state.financialAid,
          financialAidPrograms: {
            ...state.financialAid.financialAidPrograms,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_STUDENT_FINANCIAL_AID_PROGRAMS_FAIL:
      return {
        ...state,
        financialAid: {
          ...state.financialAid,
          financialAidPrograms: {
            ...state.financialAid.financialAidPrograms,
            error: payload,
            loading: false,
          },
        },
      };
    // create financial aid
    case types.CREATE_STUDENT_FINANCIAL_AID:
      return {
        ...state,
        financialAid: {
          ...state.financialAid,
          create: {
            ...state.financialAid.create,
            loading: true,
            error: {},
          },
        },
      };
    case types.CREATE_STUDENT_FINANCIAL_AID_SUCCEED:
      return {
        ...state,
        financialAid: {
          ...state.financialAid,
          create: {
            ...state.financialAid.create,
            data: payload,
            loading: false,
          },
        },
      };
    case types.CREATE_STUDENT_FINANCIAL_AID_FAIL:
      return {
        ...state,
        financialAid: {
          ...state.financialAid,
          create: {
            ...state.financialAid.create,
            error: payload,
            loading: false,
          },
        },
      };

    // update financial aid
    case types.UPDATE_STUDENT_FINANCIAL_AID:
      return {
        ...state,
        financialAid: {
          ...state.financialAid,
          update: {
            ...initialState.financialAid.update,
            loading: true,
          },
        },
      };
    case types.UPDATE_STUDENT_FINANCIAL_AID_SUCCEED:
      return {
        ...state,
        financialAid: {
          ...state.financialAid,
          update: {
            ...state.financialAid.update,
            data: payload,
            loading: false,
          },
        },
      };
    case types.UPDATE_STUDENT_FINANCIAL_AID_FAIL:
      return {
        ...state,
        financialAid: {
          ...state.financialAid,
          update: {
            ...state.financialAid.update,
            error: payload,
            loading: false,
          },
        },
      };
    // delete financial aid
    case types.DELETE_STUDENT_FINANCIAL_AID:
      return {
        ...state,
        financialAid: {
          ...state.financialAid,
          delete: {
            ...initialState.financialAid.delete,
            loading: true,
          },
        },
      };
    case types.DELETE_STUDENT_FINANCIAL_AID_SUCCEED:
      return {
        ...state,
        financialAid: {
          ...state.financialAid,
          delete: {
            ...state.financialAid.delete,
            data: payload,
            loading: false,
          },
        },
      };
    case types.DELETE_STUDENT_FINANCIAL_AID_FAIL:
      return {
        ...state,
        financialAid: {
          ...state.financialAid,
          delete: {
            ...state.financialAid.delete,
            error: payload,
            loading: false,
          },
        },
      };

    // get contactLogs
    case types.GET_CONTACT_LOGS:
      return {
        ...state,
        contactLog: {
          ...state.contactLog,
          list: {
            ...state.contactLog.list,
            loading: true,
          },
        },
      };
    case types.GET_CONTACT_LOGS_SUCCEED:
      return {
        ...state,
        contactLog: {
          ...state.contactLog,
          list: {
            ...state.contactLog.list,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_CONTACT_LOGS_FAIL:
      return {
        ...state,
        contactLog: {
          ...state.contactLog,
          list: {
            ...state.contactLog.list,
            error: payload,
            loading: false,
          },
        },
      };
    // create contactLog
    case types.CREATE_CONTACT_LOG:
      return {
        ...state,
        contactLog: {
          ...state.contactLog,
          create: {
            ...state.contactLog.create,
            loading: true,
            error: {},
          },
        },
      };
    case types.CREATE_CONTACT_LOG_SUCCEED:
      return {
        ...state,
        contactLog: {
          ...state.contactLog,
          create: {
            ...state.contactLog.create,
            data: payload,
            loading: false,
          },
        },
      };
    case types.CREATE_CONTACT_LOG_FAIL:
      return {
        ...state,
        contactLog: {
          ...state.contactLog,
          create: {
            ...state.contactLog.create,
            error: payload,
            loading: false,
          },
        },
      };

    // update contactLog
    case types.UPDATE_CONTACT_LOG:
      return {
        ...state,
        contactLog: {
          ...state.contactLog,
          update: {
            ...initialState.contactLog.update,
            loading: true,
          },
        },
      };
    case types.UPDATE_CONTACT_LOG_SUCCEED:
      return {
        ...state,
        contactLog: {
          ...state.contactLog,
          update: {
            ...state.contactLog.update,
            data: payload,
            loading: false,
          },
        },
      };
    case types.UPDATE_CONTACT_LOG_FAIL:
      return {
        ...state,
        contactLog: {
          ...state.contactLog,
          update: {
            ...state.contactLog.update,
            error: payload,
            loading: false,
          },
        },
      };
    // delete contactLog
    case types.DELETE_CONTACT_LOG:
      return {
        ...state,
        contactLog: {
          ...state.contactLog,
          delete: {
            ...initialState.contactLog.delete,
            loading: true,
          },
        },
      };
    case types.DELETE_CONTACT_LOG_SUCCEED:
      return {
        ...state,
        contactLog: {
          ...state.contactLog,
          delete: {
            ...state.contactLog.delete,
            data: payload,
            loading: false,
          },
        },
      };
    case types.DELETE_CONTACT_LOG_FAIL:
      return {
        ...state,
        contactLog: {
          ...state.contactLog,
          delete: {
            ...state.contactLog.delete,
            error: payload,
            loading: false,
          },
        },
      };

    //  get student change log
    case types.GET_STUDENT_CHANGE_LOG:
      return {
        ...state,
        changeLog: {
          ...state.changeLog,
          list: {
            ...state.changeLog.list,
            loading: true,
          },
        },
      };
    case types.GET_STUDENT_CHANGE_LOG_SUCCEED: {
      let listToRender = [...payload.data];
      if (state.changeLog.page !== payload.page && payload.page !== 1) {
        listToRender = [...state.changeLog.listToRender, ...payload.data];
      }
      return {
        ...state,
        changeLog: {
          ...state.changeLog,
          list: {
            ...state.changeLog.list,
            data: payload.data,
            loading: false,
          },
          listToRender,
          isOutOfData: payload.data.length < payload.perPage,
          page: payload.page,
        },
      };
    }
    case types.GET_STUDENT_CHANGE_LOG_FAIL:
      return {
        ...state,
        changeLog: {
          ...state.changeLog,
          list: {
            ...state.changeLog.list,
            error: payload,
            loading: false,
          },
        },
      };
    case types.SET_DATA_CHANG_LOG:
      return {
        ...state,
        changeLog: {
          ...state.changeLog,
          listToRender: [],
        },
      };
    //  get student change log
    case types.GET_STUDENT_DOCUMENTS:
      return {
        ...state,
        documents: {
          ...state.documents,
          list: {
            ...state.documents.list,
            loading: true,
          },
        },
      };
    case types.GET_STUDENT_DOCUMENTS_SUCCEED:
      return {
        ...state,
        documents: {
          ...state.documents,
          list: {
            ...state.documents.list,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_STUDENT_DOCUMENTS_FAIL:
      return {
        ...state,
        documents: {
          ...state.documents,
          list: {
            ...state.documents.list,
            error: payload,
            loading: false,
          },
        },
      };
    // delete document
    case types.DELETE_DOCUMENT:
      return {
        ...state,
        deleteDocument: {
          ...initialState.deleteDocument,
          loading: true,
        },
      };
    case types.DELETE_DOCUMENT_SUCCEED:
      return {
        ...state,
        deleteDocument: {
          ...state.deleteDocument,
          data: payload,
          loading: false,
        },
      };
    case types.DELETE_DOCUMENT_FAIL:
      return {
        ...state,
        deleteDocument: {
          ...state.deleteDocument,
          error: payload,
          loading: false,
        },
      };
    // delete
    case types.DELETE_STUDENT:
      return {
        ...state,
        delete: {
          ...initialState.delete,
          loading: true,
        },
      };
    case types.DELETE_STUDENT_SUCCEED:
      return {
        ...state,
        delete: {
          ...state.delete,
          data: payload,
          loading: false,
        },
      };
    case types.DELETE_STUDENT_FAIL:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: payload,
          loading: false,
        },
      };
    // programs
    case types.GET_STUDENT_PROGRAMS:
      return {
        ...state,
        programs: {
          ...initialState.programs,
          loading: true,
        },
      };
    case types.GET_STUDENT_PROGRAMS_SUCCEED:
      return {
        ...state,
        programs: {
          ...state.programs,
          data: payload.data,
          loading: false,
        },
      };
    case types.GET_STUDENT_PROGRAMS_FAIL:
      return {
        ...state,
        programs: {
          ...state.programs,
          error: payload,
          loading: false,
        },
      };
    default:
      return state;
  }
}
