import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import {
  Dropzone,
  FormGroup,
  SingleDatePicker,
  SingleSelectCheckbox,
} from "../../../common";
import { Delete } from "../../../common/icons";

function AddLicensure({
  form,
  formErrors,
  onSubmit,
  onChangeSelect,
  handleChangeDate,
  handleCheckbox,
  handleBack,
  // eslint-disable-next-line react/prop-types
  dataPrograms,
  // eslint-disable-next-line react/prop-types
  dataProgramLicensureExams,
  onDeleteDocument,
  handleDrop,
  isSubmitting,
}) {
  // eslint-disable-next-line react/prop-types
  const OPTIONS_PROGRAM = dataPrograms.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  // eslint-disable-next-line react/prop-types
  const OPTIONS_PROGRAM_LICENSURE_EXAM = dataProgramLicensureExams.length
    ? // eslint-disable-next-line react/prop-types
      dataProgramLicensureExams.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    : [];

  return (
    <div className="AddLicensure  form-student dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="form-student__text">
          <p className="text--xlarge bold">New Exam Result</p>
        </div>
        <div className="AddLicensure__inner form-student__inner">
          <div className="input__group">
            <div className="select">
              <p>Program</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="program_id"
                options={OPTIONS_PROGRAM}
                value={{ value: form.program_id }}
                error={formErrors.program_id}
                disabled={isSubmitting}
              />
            </div>
          </div>
          {form.program_id && (
            <div className="input__group">
              <div className="select">
                <p>Exam Name</p>
                <SingleSelectCheckbox
                  onChange={onChangeSelect}
                  name="licensure_exam_id"
                  options={OPTIONS_PROGRAM_LICENSURE_EXAM}
                  value={{ value: form.licensure_exam_id }}
                  error={formErrors.licensure_exam_id}
                  disabled={isSubmitting}
                />
              </div>
            </div>
          )}
          <div className="input__group">
            <div className="Date-picker">
              <p className="Date-picker__label">Licensure Date</p>
              <SingleDatePicker
                placeholder="Select Date"
                value={form.exam_date}
                onChange={handleChangeDate}
                disabled={isSubmitting}
                name="exam_date"
              />
            </div>
          </div>
          <div className="checkbox-group">
            <p className=" bold">Result</p>
            <div>
              <div className="checkbox">
                Pass
                <FormGroup
                  propsInput={{
                    type: "radio",
                    name: "result",
                    onChange: handleCheckbox,
                    checked: form.result === "PASS",
                    value: "PASS",
                  }}
                  type="radio"
                />
              </div>
              <div className="checkbox">
                Fail
                <FormGroup
                  propsInput={{
                    type: "radio",
                    name: "result",
                    onChange: handleCheckbox,
                    checked: form.result === "FAIL",
                    value: "FAIL",
                  }}
                  type="radio"
                />
              </div>
              <div className="checkbox">
                Graduated Without License
                <FormGroup
                  propsInput={{
                    type: "radio",
                    name: "result",
                    onChange: handleCheckbox,
                    checked: form.result === "Graduated Without License",
                    value: "Graduated Without License",
                  }}
                  type="radio"
                />
              </div>
            </div>
            {!form.result && (
              <div className="error-message">{formErrors.result}</div>
            )}
          </div>
          <div className="Documents">
            <p>Documentation</p>
            {form.files && !!form.files.length && (
              <div className="Documents__inner">
                {form.files.map((item, index) => (
                  <div className="Document--item" key={item.name || item.id}>
                    <p>{item.name || item.filename}</p>
                    <button
                      type="button"
                      className="Documents__delete"
                      onClick={onDeleteDocument(item, index)}
                    >
                      <Delete />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="input__group">
            <div className="add-document">
              <Dropzone handleDrop={handleDrop} />
            </div>
          </div>
        </div>
        <div className="button-group">
          <button
            className="button button--back"
            type="submit"
            onClick={handleBack}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
}

AddLicensure.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    program_id: PropTypes.number,
    licensure_exam_id: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    exam_date: PropTypes.any,
    result: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    files: PropTypes.array,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChangeSelect: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  onDeleteDocument: PropTypes.func.isRequired,
};

AddLicensure.defaultProps = {
  form: {
    program_id: undefined,
    licensure_exam_id: undefined,
    exam_date: "",
    result: "",
    files: [],
  },
  formErrors: {},
  isSubmitting: false,
};

export default AddLicensure;
