import React from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { TableActions } from "components";
import { ROUTES, SEARCH_NAME, SORT_NAME } from "../../../utils/constants";
import { FormGroup } from "../../common";
import { Plus } from "../../common/icons";
import Table from "../../common/Table";

function CampaignsTable({
  storeCampaigns = { data: [], meta: {}, loading: false, error: null },
  onPageChange = () => null,
  params = {},
  onSort = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onClone = () => {},
}) {
  const history = useHistory();

  const handleClick = () => {
    history.push(ROUTES.addCampaign);
  };

  const columns = [
    {
      title: "Name ",
      dataIndex: "name",
      sortAble: true,
      render: (row) => (
        <Link className="Campaigns__link" to={`/dashboard/campaigns/${row.id}`}>
          {row.name}
        </Link>
      ),
    },
    {
      title: "Program Name ",
      dataIndex: "program_name",
      sortAble: true,
      render: (row) => row.program.name,
    },
    {
      title: "Branch Name",
      dataIndex: "branch_name",
      sortAble: true,
      render: (row) => row.branch.name,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      sortAble: true,
      render: (row) => moment(row.start_date).utc().format("MM/DD/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      sortAble: true,
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (row) => (
        <TableActions
          actions={[
            {
              label: "Edit",
              icon: ["fas", "pen-to-square"],
              onSelect: onEdit(row),
            },
            {
              label: "Clone",
              icon: ["fas", "clone"],
              onSelect: onClone(row),
            },
            {
              label: "Delete",
              icon: ["fas", "trash"],
              onSelect: onDelete(row),
            },
          ]}
        />
      ),
      align: "right",
    },
  ];

  return (
    <div className="Campaigns__inner dashboard__item__inner">
      <div className="filter">
        <div className="filter__left">
          <FormGroup
            propsInput={{
              name: "name",
              className: "inputSearch",
              placeholder: "What are you looking for?",
              onChange: onSearch,
              value: params[SEARCH_NAME] || "",
            }}
          />
        </div>

        <button type="submit" className="button--icon" onClick={handleClick}>
          <Plus />
          Campaign
        </button>
      </div>
      <Table
        loading={storeCampaigns.loading}
        columns={columns}
        data={storeCampaigns.data}
        tableProps={{ striped: true }}
        sort={params[SORT_NAME] || ""}
        onSort={onSort}
        paginationProps={{
          itemsLength: storeCampaigns.meta.total,
          perPage: storeCampaigns.meta.perPage,
          onPageChange,
          activePage: params.page || 1,
        }}
      />
    </div>
  );
}

export default CampaignsTable;
