import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { replaceParamStudent } from "utils/helpers";
import { useHistory, useParams } from "react-router-dom";
import request from "../../../../utils/request";
import {
  deletePlacement,
  getPlacements,
} from "../../../../redux/actions/student";
import { ROUTES } from "../../../../utils/constants";
import RequestChangesModal from "../../../../components/dashboard/students/student/PlacementRequestChangesModal";
import { openMessage } from "../../../../redux/actions/message";
import { Loading } from "../../../../components/common";
import { Placement } from "../../../../components/dashboard/students/student";

export default function () {
  const dispatch = useDispatch();
  const history = useHistory();
  const storePlacement = useSelector((store) => store.student.placement.list);
  const storeDelete = useSelector((store) => store.student.placement.delete);
  const [isOpenRequestChanges, setIsOpenRequestChanges] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [surveyResultId, setSurveyResultId] = useState(null);
  const [pendingChangeRequestId, setPendingChangeRequestId] = useState(null);
  const [notes, setNotes] = React.useState("");
  const [error, setError] = React.useState(null);
  const [isLoadingVerify, setIsLoadingVerify] = React.useState(false);
  const [documents, setDocuments] = React.useState({});
  const { studentId } = useParams();

  const loading =
    storeDelete.loading || storePlacement.loading || isLoadingVerify;

  React.useEffect(() => {
    if (studentId) {
      dispatch(getPlacements({}, studentId));
    }
  }, [studentId]);

  React.useEffect(() => {
    storePlacement.data.forEach((placementData) => {
      if (!placementData?.survey_result?.token) {
        return;
      }
      request()
        .get(
          `/surveys/graduate_follow_up/verify_documents?token=${placementData?.survey_result?.token}`
        )
        .then((resData) => {
          setDocuments((prev) => ({
            ...prev,
            [placementData.id]: resData.data.data?.[0] || "",
          }));
        });
    });
  }, [storePlacement.data]);

  const handleEdit = (rowData) => async () => {
    history.push(replaceParamStudent(ROUTES.addPlacement, studentId), rowData);
  };

  const handleDelete = (rowData) => async () => {
    try {
      await dispatch(deletePlacement(studentId, rowData.id));
      dispatch(
        openMessage({
          title: "Placement has been deleted.",
          type: "success",
        })
      );
      dispatch(getPlacements({}, studentId));
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleOpenRequestChangeModal = (rowData) => async () => {
    setSurveyResultId(rowData?.survey_result?.id);

    const changeRequest = rowData?.survey_result?.change_requests?.sort(
      (a, b) => {
        const date1 = new Date(a.created_at);
        const date2 = new Date(b.created_at);
        return date2 - date1;
      }
    )[0];
    setPendingChangeRequestId(
      changeRequest && changeRequest.status === "pending_verification"
        ? changeRequest.id
        : null
    );

    setNotes("");
    setError(null);
    setIsSubmitting(false);
    setIsOpenRequestChanges(true);
  };

  const handleHideRequestChangesModal = () => {
    setIsOpenRequestChanges(false);
  };

  const handleFocusNotes = () => {
    setError(null);
  };

  const handleSendChangeRequest = () => {
    if (!notes) {
      setError("Notes are required.");
      return;
    }

    setError(null);
    setIsSubmitting(true);

    const params = {
      change_request: {
        changeable_id: surveyResultId,
        changeable_type: "SurveyResult",
        notes,
      },
    };

    const handleAfterCallingAPI = () => {
      dispatch(getPlacements({}, studentId));
      handleHideRequestChangesModal();
      dispatch(
        openMessage({
          title: "Request change has been created.",
          type: "success",
        })
      );
    };

    if (pendingChangeRequestId) {
      request()
        .put(`/change_requests/${pendingChangeRequestId}`, params)
        .then(() => {
          handleAfterCallingAPI();
        });
    } else {
      request()
        .post("/change_requests", params)
        .then(() => {
          handleAfterCallingAPI();
        });
    }
  };

  const handleChangeNotes = (e) => {
    setNotes(e.target.value.trim());
  };

  const handleVerify = (row) => async () => {
    // TODO: fix this
    const token = row.survey_result.token;
    setIsLoadingVerify(true);
    const response = await request().get(
      `/surveys/graduate_follow_up/verify?token=${token}`
    );
    setIsLoadingVerify(false);
    const url = response.data.data;
    window.open(url, "_self");
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Placement
        storePlacement={storePlacement}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onRequestChange={handleOpenRequestChangeModal}
        onVerify={handleVerify}
        documents={documents}
      />

      <RequestChangesModal
        open={isOpenRequestChanges}
        onHide={handleHideRequestChangesModal}
        onSendChangeRequest={handleSendChangeRequest}
        onChangeNotes={handleChangeNotes}
        onFocusNotes={handleFocusNotes}
        error={error}
        isSubmitting={isSubmitting}
      />
    </>
  );
}
