import React from "react";
import classNames from "classnames";
import { Route, Switch } from "react-router-dom";
import { ROUTES } from "utils/constants";
import Attendance from "features/Attendance";
import { Header, Sidebar } from "../../components/dashboard";
import "./styles.scss";
import Root from "./Root";

export default function () {
  const [sidebarMobile, setSidebarMobile] = React.useState(false);

  const handleClickToggle = () => {
    setSidebarMobile(!sidebarMobile);
  };

  return (
    <div
      className={classNames("Dashboard", {
        "--toggle": sidebarMobile,
      })}
    >
      <div className="Dashboard__overlay">
        <button type="button" onClick={handleClickToggle}>
          ✕
        </button>
      </div>
      <Sidebar />
      <div className="Dashboard__inner">
        <Header handleClickToggle={handleClickToggle} />
        <div className="dashboard__wrap">
          <div className="dashboard dashboard-student">
            <Switch>
              <Route path={ROUTES.dashboardStudent} exact component={Root} />
              <Route path={ROUTES.attendanceStudent} component={Attendance} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
