/* eslint-disable no-shadow */
import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import PropTypes from "prop-types";
import classNames from "classnames";
import { range } from "../../utils/helpers";
import { ArrowLeft2, ArrowRight2 } from "./icons";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function SingleDatePicker({
  minYear,
  maxYear,
  value,
  onChange,
  disabled,
  name,
  id,
  ...props
}) {
  const [valueDate, setValueDate] = React.useState(new Date());

  React.useEffect(() => {
    if (typeof value === "string") {
      setValueDate(new Date(value));
    } else {
      setValueDate(value);
    }
  }, [value]);

  const years = range(minYear, maxYear, 1);

  return (
    <DatePicker
      className={classNames("Date-picker__input single_date_picker", {
        "Date-picker__input__disabled": disabled,
      })}
      id={id}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            type="button"
            className=" btn-month btn-prev"
          >
            <ArrowLeft2 />
          </button>
          <select
            value={moment(date).get("year")}
            onChange={({ target: { value: v } }) => changeYear(v)}
            className="date-picker-select select-year"
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[moment(date).get("month")]}
            onChange={({ target: { value: v } }) =>
              changeMonth(months.indexOf(v))
            }
            className="date-picker-select select-month"
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            type="button"
            className="btn-month btn-next"
          >
            <ArrowRight2 />
          </button>
        </div>
      )}
      selected={valueDate}
      onChange={(date) => onChange(date, name)}
      autoComplete="save"
      {...props}
    />
  );
}

SingleDatePicker.propTypes = {
  maxYear: PropTypes.number,
  minYear: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
};

SingleDatePicker.defaultProps = {
  minYear: 1950,
  maxYear: moment(new Date()).get("year") + 10,
  disabled: false,
  name: "",
};

export default SingleDatePicker;
