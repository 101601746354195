import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../../../components/common";
import Profile from "../../../components/dashboard/profileSetting/Profile";
import { getProfile } from "../../../redux/actions/profileSetting";

function Root() {
  const dispatch = useDispatch();
  const storeProfile = useSelector((store) => store.profileSetting.profile);

  React.useEffect(() => {
    dispatch(getProfile());
  }, []);

  if (storeProfile.loading) {
    return <Loading />;
  }

  return <Profile storeProfile={storeProfile} />;
}

export default Root;
