import * as types from "../constants";
import request from "../../utils/request";

export function getDashboard(data) {
  return (dispatch) => {
    dispatch({ type: types.GET_DASHBOARD });
    return request()
      .get("/dashboard", { params: data })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_DASHBOARD_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_DASHBOARD_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
