import React from "react";
import agency from "../../assets/images/agency-badge.png";

const data = [
  // { title: "ACCSC", text: "Accreditation" },
  { title: "ACCET", text: "Accreditation" },
  { title: "COE", text: "Accreditation" },
  { title: "BPPE", text: "California" },
  { title: "TWC", text: "Texas" },
  { title: "BPSS", text: "New York" },
  { title: "VET TEC", text: "Federal" },
  { title: "VRRAP", text: "Federal" },
  // { title: "CIRR", text: "National" },
  // { title: "DPOS", text: "Colorado" },
];
function Support() {
  return (
    <section className="Support">
      <div className="container">
        <div className="Support__inner">
          <div className="text-center title">
            <h4>Agencies We Support</h4>
            <p className="text">
              If your state or accreditation agency is not listed, please reach
              out to us and we’ll add it to our list!
            </p>
          </div>
          <div className="agency-group">
            {data.map((item) => (
              <div className="agency__item" key={item.title}>
                <img src={agency} alt="" />
                <p className="text-xlarge bold">{item.title}</p>
                <p className="text">{item.text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Support;
