import React from "react";
import visual from "../../assets/images/visual.png";
import triangle from "../../assets/images/triangles4.png";

function Agencies() {
  return (
    <section className="Agencies">
      <div id="agencies" className="top-section" />
      <div className="container">
        <div className="Agencies__inner">
          <div className="text-center title">
            <h4>Why AcademySync</h4>
            <p className="text">
              We will help you track each student’s journey from enrollment to
              completion to placement.
            </p>
            <img src={visual} alt="" className="visual" />
          </div>
          <div className="reasons">
            <div className="reasons__item enrollment">
              <div>
                <p className="text--xlarge bold">
                  Enrollment <span />
                </p>
                <p className="text">
                  Enrolment history such as cancellations, withdrawals,
                  transfers-in, transfers-out, and more along with supporting
                  documentation.
                </p>
              </div>
            </div>
            <div className="reasons__item placement">
              <img src={visual} alt="" />
              <div>
                <p className="text--xlarge bold">
                  Placement <span />
                </p>
                <p className="text">
                  Placement details such as in-field employment, out-of-field
                  employment, continuing education, and more along with
                  supporting documentation.
                </p>
              </div>
            </div>
            <div className="reasons__item completion">
              <div>
                <p className="text--xlarge bold">
                  Completion <span />
                </p>
                <p className="text">
                  Completion status within 100% and 150% of normal time to
                  program completion along with supporting documentation.
                </p>
              </div>
            </div>
          </div>
          <img src={triangle} alt="" className="triangle" />
        </div>
      </div>
    </section>
  );
}

export default Agencies;
