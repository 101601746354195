import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import type { SurveyInfo } from "../api";
import {
  Card,
  Button,
  ButtonArea,
  StepHeader,
  Form,
  RadioGroup,
} from "../components";
import { useSurveyStore } from "../store";

type Props = {
  surveyInfo: SurveyInfo;
  progress?: string;
  onNextClick: () => void;
  onBackClick: () => void;
};

const schema = z.object({
  currentlySeekingEmployment: z.enum(["YES", "NO"], {
    invalid_type_error: "Required",
  }),
});

type Inputs = z.infer<typeof schema>;

export function CurrentlySeekingEmploymentStep(props: Props) {
  const { surveyInfo, progress, onNextClick, onBackClick } = props;
  const [state, setState] = useSurveyStore((store) => [
    store.currentlySeekingEmployment,
    store.setCurrentlySeekingEmployment,
  ]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: { currentlySeekingEmployment: state },
    resolver: zodResolver(schema),
  });

  const handleBackClick = () => {
    setState(getValues());
    onBackClick();
  };

  const handleNextClick = async () => {
    await handleSubmit((data) => {
      setState(data);
      onNextClick();
    })();
  };

  return (
    <Card progress={progress}>
      <StepHeader
        surveyInfo={surveyInfo}
        title="Are you currently seeking employment?"
      />

      <Form>
        <RadioGroup<Inputs["currentlySeekingEmployment"]>
          options={[
            { label: "Yes", value: "YES" },
            { label: "No", value: "NO" },
          ]}
          error={errors?.currentlySeekingEmployment?.message}
          {...register("currentlySeekingEmployment")}
        />
      </Form>

      <ButtonArea>
        <Button label="Back" onClick={handleBackClick} />
        <Button primary label="Continue" onClick={handleNextClick} />
      </ButtonArea>
    </Card>
  );
}
