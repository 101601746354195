import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { SelectUserRole } from "components/common";
import FormGroup from "../common/FormGroup";
import logo from "../../assets/images/logo2.svg";
// import google from '../../assets/icons/google.svg';
// import outlook from '../../assets/icons/outlook.svg';
import { ROUTES } from "../../utils/constants";

function LoginForm({
  form,
  formErrors,
  onSubmit,
  onChange,
  onFocus,
  isSubmitting,
  role,
  setRole,
}) {
  return (
    <section className="Login bg-form">
      <Link to="/" className="logo-form">
        <img src={logo} alt="" />
      </Link>
      <div className="container">
        <div className="Login__inner academy-form">
          <Form noValidate onSubmit={onSubmit}>
            <p className="text--xlarge bold">Welcome Back!</p>
            <SelectUserRole
              userRole={role}
              onChangeRole={(selectedRole) => setRole(selectedRole)}
            />
            <div className="form__inner">
              <FormGroup
                propsInput={{
                  name: "email",
                  placeholder: "Email",
                  type: "email",
                  onChange,
                  onFocus,
                  value: form.email,
                  disabled: isSubmitting,
                }}
                error={formErrors.email}
                label="Email"
              />
              <FormGroup
                propsInput={{
                  name: "password",
                  placeholder: "Password",
                  type: "password",
                  onChange,
                  onFocus,
                  value: form.password,
                  disabled: isSubmitting,
                }}
                error={formErrors.password}
                label="Password"
              />
              <div className="remember-me">
                <div className="checkbox opacity-0">
                  Remember Me
                  <FormGroup
                    propsInput={{
                      type: "checkbox",
                      name: "remember",
                    }}
                    type="checkbox"
                  />
                </div>
                <Link to="/forgot-password" className="text--xsmall">
                  Forgot your password?
                </Link>
              </div>
              <button
                className="button button--primary"
                type="submit"
                disabled={isSubmitting}
              >
                Sign In
              </button>
            </div>
            {/* <div className="button-group">
              <button className="button button--secondary" type="button">
                <img src={google} alt="" />
                Google Sign Up
              </button>
              <button className="button button--secondary" type="button">
                <img src={outlook} alt="" />
                Outlook Sign up
              </button>
            </div> */}
            <p className="text--xsmall">
              <Link to={ROUTES.signup}>First time here? Sign up</Link>
            </p>
          </Form>
        </div>
      </div>
    </section>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

LoginForm.defaultProps = {
  form: {
    email: "",
    password: "",
  },
  formErrors: {},
};

export default LoginForm;
