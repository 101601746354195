/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/default-param-last */
import * as types from "../constants";

const initialState = {
  create: {
    data: {},
    loading: false,
    error: null,
  },
  complete: {
    data: {},
    loading: false,
    call: false,
    error: null,
  },
  info: {
    data: {},
    loading: false,
    error: null,
  },
  sign: {
    data: {},
    loading: false,
    error: null,
  },
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    // get survey info
    case types.GET_CIRR_SURVEY_INFO:
      return {
        ...state,
        info: {
          ...state.info,
          loading: true,
        },
      };
    case types.GET_CIRR_SURVEY_INFO_SUCCEED:
      return {
        ...state,
        info: {
          ...state.info,
          data: payload.data,
          loading: false,
        },
      };
    case types.GET_CIRR_SURVEY_INFO_FAIL:
      return {
        ...state,
        info: {
          ...state.info,
          error: payload,
          loading: false,
        },
      };

    // get survey sign
    case types.GET_CIRR_SURVEY_SIGN:
      return {
        ...state,
        sign: {
          ...state.sign,
          loading: true,
        },
      };
    case types.GET_CIRR_SURVEY_SIGN_SUCCEED:
      return {
        ...state,
        sign: {
          ...state.sign,
          data: payload.data,
          loading: false,
        },
      };
    case types.GET_CIRR_SURVEY_SIGN_FAIL:
      return {
        ...state,
        sign: {
          ...state.sign,
          error: payload,
          loading: false,
        },
      };

    // create survey
    case types.CREATE_CIRR_SURVEY:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: {},
        },
      };
    case types.CREATE_CIRR_SURVEY_SUCCEED:
      return {
        ...state,
        create: {
          ...state.create,
          data: payload,
          loading: false,
        },
      };
    case types.CREATE_CIRR_SURVEY_FAIL:
      return {
        ...state,
        create: {
          ...state.create,
          error: payload,
          loading: false,
        },
      };
    // create survey complete
    case types.CREATE_CIRR_SURVEY_COMPLETE:
      return {
        ...state,
        complete: {
          ...state.complete,
          loading: true,
          error: {},
          call: false,
        },
      };
    case types.CREATE_CIRR_SURVEY_COMPLETE_SUCCEED:
      return {
        ...state,
        complete: {
          ...state.complete,
          data: payload,
          loading: false,
          call: true,
        },
      };
    case types.CREATE_CIRR_SURVEY_COMPLETE_FAIL:
      return {
        ...state,
        complete: {
          ...state.complete,
          error: payload,
          loading: false,
          call: true,
        },
      };

    default:
      return state;
  }
}
