import * as types from "../constants";
import request from "../../utils/request";

export function getFinancialAidPrograms(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_FINANCIAL_AID_PROGRAMS });
    return request()
      .get("/financial_aid_programs", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_FINANCIAL_AID_PROGRAMS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            meta: {
              total: dataResponse.headers.total,
              perPage: dataResponse.headers["per-page"],
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_FINANCIAL_AID_PROGRAMS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getAllFinancialAidPrograms(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_ALL_FINANCIAL_AID_PROGRAMS });
    return request()
      .get("/financial_aid_programs/all", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_ALL_FINANCIAL_AID_PROGRAMS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            meta: {
              total: dataResponse.headers.total,
              perPage: dataResponse.headers["per-page"],
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ALL_FINANCIAL_AID_PROGRAMS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function deleteFinancialAidProgram(programId) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_FINANCIAL_AID_PROGRAM });
    return request()
      .delete(`/financial_aid_programs/${programId}`)
      .then((dataResponse) => {
        dispatch({
          type: types.DELETE_FINANCIAL_AID_PROGRAM_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_FINANCIAL_AID_PROGRAM_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createFinancialAidProgram(data) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_FINANCIAL_AID_PROGRAM });
    return request()
      .post("/financial_aid_programs", { financial_aid_program: data })
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_FINANCIAL_AID_PROGRAM_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_FINANCIAL_AID_PROGRAM_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function updateFinancialAidProgram(data, programId) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_FINANCIAL_AID_PROGRAM });
    return request()
      .put(`/financial_aid_programs/${programId}`, {
        financial_aid_program: data,
      })
      .then((dataResponse) => {
        dispatch({
          type: types.UPDATE_FINANCIAL_AID_PROGRAM_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_FINANCIAL_AID_PROGRAM_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
