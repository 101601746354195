import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Loading } from "../../../components/common";
import { Integrations } from "../../../components/dashboard/institution";
import {
  closeModalMessageIntegration,
  openModalMessageIntegration,
} from "../../../redux/actions/global";
import {
  getSalesforceActivate,
  getIntegrations,
  getSalesforceMapping,
  createSalesforceSync,
  deleteIntegration,
  getHubSpotActivate,
  createHubSpotSync,
  getHubSpotMapping,
} from "../../../redux/actions/integration";
import { openMessage } from "../../../redux/actions/message";
import { ROUTES } from "../../../utils/constants";
import salesForce from "../../../assets/images/salesForce.svg";
import hubspot from "../../../assets/images/hubspot.svg";

export default function () {
  const dispatch = useDispatch();
  const history = useHistory();

  const storeIntegrations = useSelector(
    (store) => store.integration.integrations
  );
  const storeIntegrationsDeletes = useSelector(
    (store) => store.integration.delete
  );

  const dataIntegrationSalesforce =
    storeIntegrations.data && storeIntegrations.data.length
      ? storeIntegrations.data.filter((item) => item.is_type === "salesforce")
      : [];

  const dataIntegrationHubSpot =
    storeIntegrations.data && storeIntegrations.data.length
      ? storeIntegrations.data.filter((item) => item.is_type === "hubspot")
      : [];

  const idSalesforce =
    dataIntegrationSalesforce &&
    dataIntegrationSalesforce.length &&
    dataIntegrationSalesforce[0].id;
  const idHubSpot =
    dataIntegrationSalesforce &&
    dataIntegrationHubSpot.length &&
    dataIntegrationHubSpot[0].id;

  const isLoading =
    storeIntegrations.loading || storeIntegrationsDeletes.loading;

  React.useEffect(() => {
    dispatch(getIntegrations());
  }, [storeIntegrationsDeletes]);

  const handleContinueModalMessageIntegrate = (integration) => async () => {
    if (integration === "Salesforce") {
      const dataResponse = await dispatch(getSalesforceActivate());
      window.open(dataResponse, "_self");
    } else if (integration === "HubSpot") {
      const dataResponse = await dispatch(getHubSpotActivate());
      window.open(dataResponse, "_self");
    }
  };

  const handleToggleModalMessageIntegrate = (integration) => () => {
    dispatch(
      openModalMessageIntegration({
        onContinue: handleContinueModalMessageIntegrate(integration),
        text: `To integrate ${integration}, we’ll first need you to login to your account and we’ll guide you through the rest.`,
        logoIntegration: integration === "Salesforce" ? salesForce : hubspot,
        textButton: "Get Started",
      })
    );
  };

  const handleContinueModalMessageDataSync = (integration) => async () => {
    dispatch(closeModalMessageIntegration());
    try {
      if (integration === "Salesforce") {
        const response = await dispatch(createSalesforceSync());
        history.push(ROUTES.dataSyncSalesforce, response);
      } else if (integration === "HubSpot") {
        const response = await dispatch(createHubSpotSync());
        history.push(ROUTES.dataSyncHubSpot, response);
      }
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleToggleModalMessageDataSync = (integration) => () => {
    dispatch(
      openModalMessageIntegration({
        onContinue: handleContinueModalMessageDataSync(integration),
        text: `You are about to initiate a data sync in your ${integration} integration.`,
        textButton: "Continue",
      })
    );
  };

  const HandleClickEditMapping = (integration) => async () => {
    if (integration === "Salesforce") {
      await dispatch(getSalesforceMapping());
      history.push(ROUTES.integrationsStepSalesforce);
    } else if (integration === "HubSpot") {
      await dispatch(getHubSpotMapping());
      history.push(ROUTES.integrationsStepHubSpot);
    }
  };

  const handleUninstall = (integration) => async () => {
    try {
      if (integration === "Salesforce") {
        await dispatch(deleteIntegration(idSalesforce));
      } else if (integration === "HubSpot") {
        await dispatch(deleteIntegration(idHubSpot));
      }
      dispatch(
        openMessage({
          title: `Your ${integration} has been uninstalled`,
          type: "success",
        })
      );
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  if (isLoading) return <Loading />;

  return (
    <Integrations
      storeIntegrations={storeIntegrations}
      handleClickInstall={handleToggleModalMessageIntegrate}
      onClickEditMapping={HandleClickEditMapping}
      onClickSync={handleToggleModalMessageDataSync}
      onUninstall={handleUninstall}
      dataIntegrationSalesforce={dataIntegrationSalesforce}
      dataIntegrationHubSpot={dataIntegrationHubSpot}
    />
  );
}
