/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { File } from "../../../common/icons";
import { ROUTES } from "../../../../utils/constants";

function Step3({ onSubmit, isSubmitting, dataSummary }) {
  const history = useHistory();
  const percentSuccess = Number(
    (
      (parseFloat(dataSummary.valid_rows) / parseFloat(dataSummary.total)) *
      100
    ).toFixed(1)
  );

  const percentError = 100 - percentSuccess;

  return (
    <div className="step3  form-student dashboard__item__inner">
      <Form noValidate>
        <div className="form-student__text">
          <p className="text--large bold">Import Summary</p>
        </div>
        <div className="step3__inner form-student__inner">
          <div className="step3__inner__item">
            <p className="text--xlarge bold">Progress</p>
            <p className="text--small">
              {`Started at: ${moment(dataSummary.created_at).format(
                "MM/DD/YYYY [at] h:mma"
              )}`}
            </p>
          </div>
          <div className="step3__inner__status">
            <p className="text--large bold">Status</p>
            <div className="step3__inner__status__info">
              {dataSummary.status !== "complete" ? (
                <p className="text--small">In progress</p>
              ) : percentError === 0 ? (
                <p className="text--small">Complete</p>
              ) : (
                <p className="text--small">
                  Complete, with <span className="alert02 bold">Errors</span>
                </p>
              )}

              {dataSummary.errors_csv && percentError !== 0 && (
                <a
                  className="text--small primary bold"
                  href={dataSummary.errors_csv}
                  target="_blank"
                  rel="noreferrer"
                >
                  <File /> Download Errors Log
                </a>
              )}
            </div>
            <div className="step3__inner__status__progress">
              <span style={{ width: `${dataSummary.pct_complete}%` }} />
            </div>
            <div className="step3__inner__status__note">
              {percentSuccess !== 0 && dataSummary.status === "complete" && (
                <div>
                  <span className="background-primary" />
                  <p className="text--small bold">{`${percentSuccess}% Success`}</p>
                </div>
              )}

              {percentError !== 0 && dataSummary.status === "complete" && (
                <div>
                  <span className="background-alert02" />
                  <p className="text--small bold">{`${percentError}% Validation Errors`}</p>
                </div>
              )}
            </div>
          </div>
          {percentError !== 0 && dataSummary.status === "complete" ? (
            <div className="button-group">
              <button
                className="button button--primary"
                type="submit"
                disabled={isSubmitting}
                onClick={onSubmit}
              >
                Reimport
              </button>
            </div>
          ) : (
            dataSummary.status === "complete" && (
              <div className="button-group">
                <button
                  className="button button--primary"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={onSubmit}
                >
                  Another Import
                </button>
                <button
                  className="button button--primary"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => history.push(ROUTES.students)}
                >
                  Done
                </button>
              </div>
            )
          )}
        </div>
      </Form>
    </div>
  );
}

Step3.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  dataSummary: PropTypes.object,
};

Step3.defaultProps = {
  isSubmitting: false,
  dataSummary: {},
};

export default Step3;
