import React from "react";

function Institution() {
  return (
    <svg
      width="146"
      height="26"
      viewBox="0 0 146 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.4239 6.0957L42.8715 19.2957H40.362L39.1905 15.8219H34.762L33.6001 19.2909H31.2334L35.0786 8.00047L35.6905 6.0957H38.4239ZM35.262 13.8028H38.6905L37.1191 8.45761H36.9953L35.262 13.8028Z"
        fill="white"
      />
      <path
        d="M50.857 12.029C50.2119 11.5325 49.4256 11.254 48.6118 11.2337C47.3284 11.2337 46.1022 12.1099 46.1022 14.4552C46.1022 16.0266 46.6951 17.7004 48.5713 17.7004C49.5927 17.7004 50.2856 17.3337 51.0594 16.8218L51.9999 18.2909C50.9875 19.0884 49.7386 19.5264 48.4499 19.5361C45.4499 19.5361 43.8594 17.6313 43.8594 14.598C43.8594 11.2647 45.8379 9.43611 48.5713 9.43611C49.7669 9.41837 50.9305 9.82308 51.857 10.579L50.857 12.029Z"
        fill="white"
      />
      <path
        d="M58.6426 19.2903C58.5736 18.9389 58.4854 18.5916 58.3783 18.2498L58.2569 18.2284C57.3998 18.9855 56.6855 19.5355 55.5426 19.5355C54.2569 19.5355 52.9712 18.9022 52.9712 16.8617C52.9712 15.3522 53.6664 14.7617 54.3593 14.4546C55.5702 14.0043 56.8104 13.6368 58.0712 13.3546V12.6594C58.0712 11.8022 57.9093 11.1498 56.8498 11.1498C55.9902 11.1498 54.7069 11.5998 53.9926 11.8641L53.5021 10.2927C54.6236 9.84507 56.195 9.43555 57.3593 9.43555C59.664 9.43555 60.2164 10.6617 60.2164 12.7832V16.3736C60.2671 17.3586 60.4179 18.3359 60.6664 19.2903H58.6426ZM58.0712 14.8236C56.9712 15.1094 56.2164 15.3332 55.645 15.6379C55.4727 15.7492 55.3328 15.9041 55.2396 16.0868C55.1464 16.2695 55.103 16.4736 55.114 16.6784C55.114 17.4141 55.4617 17.8213 56.1545 17.8213C56.8879 17.8213 57.645 17.2903 58.0712 16.8427V14.8236Z"
        fill="white"
      />
      <path
        d="M68.907 18.3313C68.0904 19.1289 67.3166 19.5361 66.1928 19.5361C63.7047 19.5361 62.4189 17.5765 62.4189 14.6813C62.4189 10.9884 64.5213 9.4432 66.7047 9.4432C67.4083 9.44574 68.0962 9.65162 68.6856 10.0361V5.09082C69.4682 5.23714 70.2583 5.33968 71.0523 5.39796C70.8871 6.81321 70.8116 8.23747 70.8261 9.66225V16.2503C70.8568 17.2765 71.0006 18.2961 71.2547 19.2908H69.2142C69.1758 18.9679 69.1081 18.6491 69.0118 18.3384L68.907 18.3313ZM68.6832 11.7432C68.1725 11.3711 67.5602 11.1642 66.9285 11.1503C65.7237 11.1503 64.7237 12.1908 64.7237 14.3337C64.7237 16.1908 65.2142 17.7813 66.6832 17.7813C67.4785 17.7813 68.1523 17.2718 68.6832 16.7408V11.7432Z"
        fill="white"
      />
      <path
        d="M80.476 16.762L81.3522 18.2097C80.251 19.0841 78.8821 19.5525 77.476 19.5359C74.7641 19.5359 73.0093 17.7001 73.0093 14.5573C72.9998 11.5716 74.6403 9.42871 77.476 9.42871C80.1093 9.42871 81.2712 11.3073 81.2712 14.0192L80.5569 14.5906L75.3188 14.8287C75.3593 16.3597 75.9712 17.7049 77.6855 17.7049C78.8022 17.7192 79.5974 17.312 80.476 16.762ZM79.1093 13.212C79.0665 11.8644 78.5569 11.0287 77.395 11.0287C76.2331 11.0287 75.4569 11.9668 75.3141 13.3954L79.1093 13.212Z"
        fill="white"
      />
      <path
        d="M83.2715 13.1713C83.231 12.3736 83.1286 11.1498 82.9453 9.76412C83.5572 9.72126 84.1477 9.68079 84.9429 9.58079C85.0093 9.9596 85.0983 10.3341 85.2096 10.7022L85.312 10.7213C86.2286 9.92602 86.9786 9.43555 88.1477 9.43555C89.1667 9.43555 90.0525 9.76412 90.5286 10.8451C91.4072 10.0903 92.2429 9.43555 93.5477 9.43555C95.5072 9.43555 96.2001 10.626 96.2001 12.5308V19.2832H94.0239V13.0927C94.0239 11.7665 93.6953 11.357 92.9001 11.357C92.0644 11.357 91.3501 11.9713 90.8406 12.4594V19.2736H88.6358V13.0832C88.6358 11.757 88.331 11.3475 87.4953 11.3475C86.6167 11.3475 85.8406 12.0427 85.4548 12.4498V19.2641H83.2715V13.1713Z"
        fill="white"
      />
      <path
        d="M99.8595 9.68066L102.021 16.2926H102.164L104.102 9.68066H106.386L103.767 17.7188C102.705 20.964 102.012 23.045 98.0738 23.2902L97.6857 21.5354C99.4214 21.4521 100.605 20.6973 100.95 19.2902L97.4214 9.68066H99.8595Z"
        fill="white"
      />
      <path
        d="M115.181 8.74332C114.282 8.21275 113.264 7.9179 112.221 7.88618C110.876 7.88618 110.202 8.49809 110.202 9.35523C110.202 10.4362 111.1 10.8457 112.569 11.4362C114.364 12.1314 116.24 12.8862 116.24 15.3338C116.24 18.1481 114.324 19.5362 111.59 19.5362C109.733 19.5362 107.959 18.8838 107.183 18.3124L108 16.3743C108.652 16.8028 110.121 17.4743 111.55 17.4743C113.1 17.4743 113.895 16.8624 113.895 15.6386C113.895 14.2933 112.733 13.8243 111.426 13.3552C109.521 12.66 107.855 11.9052 107.855 9.58142C107.855 7.02904 109.874 5.8457 112.219 5.8457C113.505 5.8457 114.952 6.19332 115.933 6.8457L115.181 8.74332Z"
        fill="white"
      />
      <path
        d="M119.036 9.68066L121.2 16.2926H121.343L123.281 9.68066H125.564L122.945 17.7188C121.883 20.964 121.191 23.045 117.252 23.2902L116.864 21.5354C118.6 21.4521 119.783 20.6973 120.129 19.2902L116.6 9.68066H119.036Z"
        fill="white"
      />
      <path
        d="M127.259 13.1713C127.219 12.3736 127.116 11.1498 126.933 9.76412C127.545 9.72126 128.135 9.68079 128.931 9.58079C129.001 9.95885 129.09 10.3331 129.197 10.7022L129.3 10.7213C130.055 10.1094 130.869 9.43555 132.278 9.43555C134.319 9.43555 135.195 10.626 135.195 12.5308V19.2832H133.012V13.0927C133.012 11.7855 132.645 11.3165 131.624 11.3165C130.769 11.3165 129.957 11.9094 129.443 12.357V19.2617H127.259V13.1713Z"
        fill="white"
      />
      <path
        d="M144.255 12.029C143.61 11.5325 142.824 11.254 142.01 11.2337C140.726 11.2337 139.5 12.1099 139.5 14.4552C139.5 16.0266 140.093 17.7004 141.969 17.7004C142.991 17.7004 143.684 17.3337 144.46 16.8218L145.398 18.2909C144.385 19.0884 143.137 19.5264 141.848 19.5361C138.848 19.5361 137.257 17.6313 137.257 14.598C137.257 11.2647 139.236 9.43611 141.969 9.43611C143.165 9.41837 144.328 9.82308 145.255 10.579L144.255 12.029Z"
        fill="white"
      />
      <path
        d="M14.6713 20.033L4.68555 15.833L5.22126 14.5615L14.6641 18.5353L20.4998 16.0068L21.0498 17.271L14.6713 20.033Z"
        fill="white"
        className="logoMobie"
      />
      <path
        d="M14.0072 0.380787C13.531 0.359358 12.9167 0.326026 12.6215 0.442692C12.5739 0.46174 12.712 0.618883 12.8167 0.792693C13.0977 1.24984 14.9977 4.33793 15.3144 4.76888C15.7144 5.31412 15.1001 2.44745 15.4191 2.49031L15.5001 2.50222C15.5001 2.50222 15.5001 2.50222 15.5905 2.52364C17.1471 2.84418 18.6129 3.50697 19.8817 4.46401C21.1505 5.42104 22.1904 6.64834 22.9263 8.05698L25.4477 9.04031C24.6409 6.60885 23.1154 4.47939 21.0727 2.93326C19.0301 1.38714 16.5664 0.497114 14.0072 0.380787Z"
        fill="#3BACF4"
        className="logoMobie"
      />
      <path
        d="M15.5978 23.4766V20.5028H15.2168V16.0647L26.974 11.2266L13.9835 5.68848L1.3335 11.117L13.3692 16.3099V11.1909C13.2852 11.1238 13.2173 11.0387 13.1707 10.9419C13.1241 10.845 13.1 10.7388 13.1002 10.6313C13.1002 10.44 13.1762 10.2565 13.3115 10.1212C13.4468 9.98591 13.6303 9.90991 13.8216 9.90991C14.0129 9.90991 14.1964 9.98591 14.3317 10.1212C14.467 10.2565 14.543 10.44 14.543 10.6313C14.5427 10.7347 14.52 10.8368 14.4764 10.9305C14.4329 11.0242 14.3695 11.1074 14.2906 11.1742V16.4432V20.4909H13.9287V23.8242L14.2121 24.3766L14.7597 25.398L14.8002 25.348C14.8514 25.4252 14.9309 25.479 15.0216 25.498C15.1088 25.5039 15.1963 25.5039 15.2835 25.498C18.1901 25.0699 20.8568 23.6428 22.8255 21.4621C24.7942 19.2814 25.9419 16.4831 26.0716 13.548C26.0716 13.3766 26.0716 13.2051 26.0716 13.0361L24.0764 13.9885C23.8736 16.2722 22.9389 18.4295 21.4111 20.1391C19.8834 21.8487 17.8445 23.0193 15.5978 23.4766Z"
        fill="#3BACF4"
        className="logoMobie"
      />
      <path
        d="M11.6832 2.41695C12.1832 2.35029 12.7784 2.38362 12.6475 2.14076C12.3784 1.636 12.0023 0.507429 11.6665 0.490763C11.5793 0.48485 11.4918 0.48485 11.4046 0.490763C8.94563 0.854018 6.64835 1.93434 4.8002 3.59657C2.95206 5.2588 1.63512 7.42913 1.01416 9.836L3.34511 8.92648C4.04095 7.20484 5.17792 5.6968 6.64163 4.55409C8.10534 3.41137 9.84418 2.67428 11.6832 2.41695Z"
        fill="#3BACF4"
        className="logoMobie"
      />
      <path
        d="M0.619018 12.448C0.478841 15.7932 1.67001 19.0575 3.93165 21.5262C6.1933 23.995 9.34109 25.4668 12.6857 25.6195C13.1619 25.6409 13.7738 25.6742 14.069 25.5576C14.119 25.5385 13.9809 25.3814 13.8738 25.2076C13.5928 24.7504 11.6952 21.6623 11.3785 21.2314C10.9762 20.6861 11.5904 23.5528 11.2714 23.5099L11.1904 23.498C11.1904 23.498 11.1904 23.498 11.1024 23.4766C8.71328 22.9836 6.56399 21.69 5.00978 19.8098C3.45557 17.9296 2.58949 15.5753 2.55473 13.1361L0.619018 12.2861C0.619018 12.3409 0.619018 12.3933 0.619018 12.448Z"
        fill="#3BACF4"
        className="logoMobie"
      />
    </svg>
  );
}

export default Institution;
