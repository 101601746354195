import React from "react";

function ArrowRight() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.6001 9.59961H10.4001C12.6092 9.59961 14.4001 11.3905 14.4001 13.5996C14.4001 14.0414 14.0419 14.3996 13.6001 14.3996C13.1898 14.3996 12.8517 14.0908 12.8055 13.6929L12.796 13.4586C12.7257 12.2454 11.7543 11.274 10.5411 11.2037L10.4001 11.1996H5.6001C4.27461 11.1996 3.2001 12.2741 3.2001 13.5996C3.2001 14.0414 2.84193 14.3996 2.4001 14.3996C1.95827 14.3996 1.6001 14.0414 1.6001 13.5996C1.6001 11.4486 3.29794 9.69416 5.42659 9.60331L5.6001 9.59961H10.4001H5.6001ZM8.00011 1.59961C10.2092 1.59961 12.0001 3.39047 12.0001 5.59961C12.0001 7.80875 10.2092 9.59961 8.00011 9.59961C5.79097 9.59961 4.00011 7.80875 4.00011 5.59961C4.00011 3.39047 5.79097 1.59961 8.00011 1.59961ZM8.0001 3.19948C6.67461 3.19948 5.6001 4.274 5.6001 5.59948C5.6001 6.92496 6.67461 7.99948 8.0001 7.99948C9.32558 7.99948 10.4001 6.92496 10.4001 5.59948C10.4001 4.274 9.32558 3.19948 8.0001 3.19948Z"
        fill="#16222F"
      />
    </svg>
  );
}

export default ArrowRight;
