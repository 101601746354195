import { ModalSendSurvey } from "components/dashboard/students";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openMessage } from "redux/actions/message";
import { getStudentPrograms } from "redux/actions/student";
import { getUsers } from "redux/actions/users";
import { sendSurvey } from "redux/actions/surveys";
import { GraduateFollowUp } from "utils/constants";
import { General } from "../../../../components/dashboard/students/student";

const FORM_SURVEY = {
  is_type: "",
  student_id: "",
  program_id: "",
  user_id: "",
};

function Root({ selectedStudent }) {
  const dispatch = useDispatch();
  const [program, setProgram] = React.useState({});
  const [verifyingAdmin, setVerifyingAdmin] = React.useState({});
  const [type, setType] = React.useState({
    value: GraduateFollowUp,
    label: GraduateFollowUp,
  });

  const [formSurvey, setFormSurvey] = React.useState({ ...FORM_SURVEY });
  const [openModalSendSurvey, setOpenModalSendSurvey] = React.useState(false);
  const storeStudentPrograms = useSelector((store) => store.student.programs);
  const storeSurvey = useSelector((store) => store.surveys.surveyStudent);
  const storeUsers = useSelector((store) => store.users);
  const isSubmitting = storeSurvey.loading;

  const handleHideModal = () => {
    setOpenModalSendSurvey(false);
    setFormSurvey({ ...FORM_SURVEY });
    setProgram({});
    setType(type);
  };

  const handleChangeType = (selectedItem) => {
    setType(selectedItem);
    setFormSurvey({ ...formSurvey, is_type: selectedItem.value });
  };

  const handleChangeProgram = (selectedItem) => {
    setProgram(selectedItem);
    setFormSurvey({ ...formSurvey, program_id: selectedItem.value });
  };

  const handleChangeVerifyingAdmin = (selectedItem) => {
    setVerifyingAdmin(selectedItem);
    setFormSurvey({ ...formSurvey, user_id: selectedItem.value });
  };

  const handleSendSurvey = () => {
    setFormSurvey({
      ...formSurvey,
      student_id: selectedStudent.id,
      is_type: type.value,
    });
    dispatch(getUsers());
    dispatch(getStudentPrograms(selectedStudent.id));
    setOpenModalSendSurvey(true);
  };

  const handleCreateSurvey = async () => {
    try {
      await dispatch(sendSurvey(formSurvey));

      setOpenModalSendSurvey(false);
      dispatch(
        openMessage({
          title: "Survey has been sent",
          type: "success",
        })
      );
      setFormSurvey({ ...FORM_SURVEY });
      setProgram({});
    } catch (error) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  // Used to set the default verifying admin to the current user
  React.useEffect(() => {
    if (storeUsers.data.length === 0) return;

    const currentUser = storeUsers.data.find((user) => user.current_user);
    handleChangeVerifyingAdmin({
      value: currentUser.id,
      label: currentUser.full_name,
    });
  }, [storeUsers]);

  return (
    <>
      <General
        selectedStudent={selectedStudent}
        handleSendSurvey={handleSendSurvey}
      />
      <ModalSendSurvey
        open={openModalSendSurvey}
        onHide={handleHideModal}
        onSend={handleCreateSurvey}
        onChangeType={handleChangeType}
        formSurvey={formSurvey}
        type={type}
        onChangeProgram={handleChangeProgram}
        program={program}
        onChangeVerifyingAdmin={handleChangeVerifyingAdmin}
        verifyingAdmin={verifyingAdmin}
        dataPrograms={storeStudentPrograms.data}
        dataAdmins={storeUsers.data}
        isSubmitting={isSubmitting}
      />
    </>
  );
}

export default Root;
