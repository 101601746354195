/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/default-param-last */
import * as types from "../constants";

const initialState = {
  list: {
    data: [],
    meta: {
      total: 0,
      perPage: 10,
    },
    loading: false,
    error: null,
  },
  all: {
    data: [],
    loading: false,
    error: null,
  },
  create: {
    data: {},
    loading: false,
    error: null,
  },
  update: {
    data: {},
    loading: false,
    error: null,
  },
  delete: {
    data: {},
    loading: false,
    error: null,
  },
  soc: {
    data: [],
    loading: false,
    error: null,
  },
  programIndex: {
    data: {},
    loading: false,
    error: null,
  },
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    // get
    case types.GET_PROGRAMS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case types.GET_PROGRAMS_SUCCEED:
      return {
        ...state,
        list: {
          ...state.list,
          data: payload.data,
          meta: payload.meta,
          loading: false,
        },
      };
    case types.GET_PROGRAMS_FAIL:
      return {
        ...state,
        list: {
          ...state.list,
          error: payload,
          loading: false,
        },
      };

    // get all
    case types.GET_ALL_PROGRAMS:
      return {
        ...state,
        all: {
          ...state.all,
          loading: true,
        },
      };
    case types.GET_ALL_PROGRAMS_SUCCEED:
      return {
        ...state,
        all: {
          ...state.all,
          data: payload.data,
          meta: payload.meta,
          loading: false,
        },
      };
    case types.GET_ALL_PROGRAMS_FAIL:
      return {
        ...state,
        all: {
          ...state.all,
          error: payload,
          loading: false,
        },
      };

    // get soc
    case types.GET_SOC:
      return {
        ...state,
        soc: {
          ...initialState.soc,
          loading: true,
        },
      };
    case types.GET_SOC_SUCCEED:
      return {
        ...state,
        soc: {
          ...state.soc,
          data: payload,
          loading: false,
        },
      };
    case types.GET_SOC_FAIL:
      return {
        ...state,
        soc: {
          ...state.soc,
          error: payload,
          loading: false,
        },
      };

    // create
    case types.CREATE_PROGRAM:
      return {
        ...state,
        create: {
          ...initialState.create,
          loading: true,
        },
      };
    case types.CREATE_PROGRAM_SUCCEED:
      return {
        ...state,
        create: {
          ...state.create,
          data: payload,
          loading: false,
        },
      };
    case types.CREATE_PROGRAM_FAIL:
      return {
        ...state,
        create: {
          ...state.create,
          error: payload,
          loading: false,
        },
      };

    // delete
    case types.DELETE_PROGRAM:
      return {
        ...state,
        delete: {
          ...initialState.delete,
          loading: true,
        },
      };
    case types.DELETE_PROGRAM_SUCCEED:
      return {
        ...state,
        delete: {
          ...state.delete,
          data: payload,
          loading: false,
        },
      };
    case types.DELETE_PROGRAM_FAIL:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: payload,
          loading: false,
        },
      };

    // update
    case types.UPDATE_PROGRAM:
      return {
        ...state,
        update: {
          ...initialState.update,
          loading: true,
        },
      };
    case types.UPDATE_PROGRAM_SUCCEED:
      return {
        ...state,
        update: {
          ...state.update,
          data: payload,
          loading: false,
        },
      };
    case types.UPDATE_PROGRAM_FAIL:
      return {
        ...state,
        update: {
          ...state.update,
          error: payload,
          loading: false,
        },
      };

    // get program index
    case types.GET_PROGRAM_INDEX:
      return {
        ...state,
        programIndex: {
          ...state.programIndex,
          loading: true,
        },
      };
    case types.GET_PROGRAM_INDEX_SUCCEED:
      return {
        ...state,
        programIndex: {
          ...state.programIndex,
          data: payload.data,
          meta: payload.meta,
          loading: false,
        },
      };
    case types.GET_PROGRAM_INDEX_FAIL:
      return {
        ...state,
        programIndex: {
          ...state.programIndex,
          error: payload,
          loading: false,
        },
      };
    default:
      return state;
  }
}
