/* eslint-disable react/forbid-prop-types */
import React from "react";
import { Dropdown, ButtonGroup, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";

function SingleSelectCheckbox({
  value,
  name,
  options,
  onChange,
  placeholder,
  disabled,
  loading,
  theme,
  size,
  "data-testid": testid,
  error = "",
  allowBlankValue = false,
}) {
  const [show, setShow] = React.useState(false);

  const handleChange = (selectedItem, nameInput) => () => {
    onChange(selectedItem, nameInput);
    setShow(false);
  };

  const handleToggle = () => {
    setShow(!show);
  };

  let selectedOption = {};
  const filterOption = options.filter(
    (item) => item.value === value.value || item.value === value
  );
  if (filterOption.length) {
    selectedOption = filterOption[0];
  }

  return (
    <Dropdown
      className={classNames("SingleSelectCheckbox SelectCheckbox", {
        "SelectCheckbox--disabled": disabled,
        "SelectCheckbox--loading": loading,
        "SelectCheckbox--dark": theme === "dark",
        "SelectCheckbox--small": size === "small",
        "Select--error": error,
      })}
      show={show}
      onToggle={handleToggle}
      data-testid={testid}
    >
      <Dropdown.Toggle as={ButtonGroup}>
        {selectedOption.value ||
        (allowBlankValue && selectedOption.value === "") ? (
          <span className="SelectCheckbox__value-selected">
            {selectedOption.label}
          </span>
        ) : (
          <span>{placeholder}</span>
        )}
        {loading && (
          <Spinner
            className="SelectCheckbox__loading"
            animation="border"
            variant="primary"
          />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {options.map((item) => (
          <div
            key={item.value}
            className={classNames("SelectCheckbox__item", {
              "--selected": value.value === item.value,
            })}
          >
            <label
              className="SelectCheckbox__item__label"
              htmlFor={`${name}-${item.value}`}
            >
              {item.label}
              <input
                id={`${name}-${item.value}`}
                value={item.value}
                name={name}
                data-label={item.label}
                type="checkbox"
                checked={value.value === item.value}
                onChange={handleChange(item, name)}
              />
              <span className="SelectCheckbox__item__checkmark" />
            </label>
          </div>
        ))}
      </Dropdown.Menu>
      {error && <div className="error-message">{error}</div>}
    </Dropdown>
  );
}

SingleSelectCheckbox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  theme: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.string,
};

SingleSelectCheckbox.defaultProps = {
  value: {
    value: "",
    label: "",
  },
  placeholder: "Select",
  disabled: false,
  loading: false,
  theme: "",
  size: "",
  error: "",
};

export default SingleSelectCheckbox;
