import moment from "moment";

export const ROUTES = {
  contactUs: "/contact-us",
  home: "/",
  login: "/login",
  logout: "/logout",
  onBoarding: "/on-boarding",
  signup: "/register",
  pricing: "/pricing",
  privacy: "/privacy-policy",
  tos: "/terms-of-service",
  cookiePolicy: "/cookie-policy",
  userPolicy: "/user-policy",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  dashboard: "/dashboard",
  dashboardStudent: "/dashboard-student",
  attendanceStudent: "/dashboard-student/attendance",
  bppe: "/dashboard/reporting/bppe",
  bpss: "/dashboard/reporting/bpss",
  twc: "/dashboard/reporting/twc",
  cirr: "/dashboard/reporting/cirr",
  dpos: "/dashboard/reporting/dpos",
  coe: "/dashboard/reporting/coe",
  accet: "/dashboard/reporting/accet",
  surveys: "/dashboard/surveys",
  surveySteps: "/graduate-follow-up-survey",
  surveyComplete: "/graduate-follow-up-survey-result",
  cirrSurveyIntend: "/cirr-student-intent-survey",
  cirrSurveyIntendComplete: "/cirr-student-intent-survey-result",
  programs: "/dashboard/programs",
  addPrograms: "/dashboard/programs/form",
  financialAidPrograms: "/dashboard/financialAidPrograms",
  addFinancialAidPrograms: "/dashboard/financialAidPrograms/form",
  branches: "/dashboard/branches",
  addBranch: "/dashboard/branches/form",
  cohorts: "/dashboard/cohorts",
  addCohorts: "/dashboard/cohorts/form",
  // routes institution
  institution: "/dashboard/institution",
  editInstitution: "/dashboard/institution/edit",
  account: "/dashboard/institution/account",
  administrators: "/dashboard/institution/administrators",
  addAdministrator: "/dashboard/institution/administrators/form",
  roles: "/dashboard/institution/roles",
  addRole: "/dashboard/institution/roles/form",
  integrations: "/dashboard/institution/integrations",
  // Salesforce
  dataSyncSalesforce:
    "/dashboard/institution/integrations/salesforce/data-sync",
  instructionSalesforce:
    "/dashboard/institution/integrations/salesforce/instruction",
  pullingDataSalesforce:
    "/dashboard/institution/integrations/salesforce/pulling-data",
  pullingAndPushingDataSalesforce:
    "/dashboard/institution/integrations/salesforce/pulling-and-pushing-data",
  integrationsStepSalesforce:
    "/dashboard/institution/integrations/salesforce/step",
  // HubSpot
  dataSyncHubSpot: "/dashboard/institution/integrations/hubspot/data-sync",
  integrationsStepHubSpot: "/dashboard/institution/integrations/hubspot/step",
  // route students
  students: "/dashboard/students",
  addStudent: "/dashboard/students/add",
  importStudent: "/dashboard/students/import",
  importInstructions: "/dashboard/students/import-instruction",
  importInstructionDetail: "/dashboard/students/import-instruction/:typeName",
  student: "/dashboard/students/:studentId",
  editStudent: "/dashboard/students/:studentId/edit",
  enrollment: "/dashboard/students/:studentId/enrollment",
  addEnrollment: "/dashboard/students/:studentId/enrollment/form",
  licensure: "/dashboard/students/:studentId/licensure",
  addLicensure: "/dashboard/students/:studentId/licensure/form",
  placement: "/dashboard/students/:studentId/placement",
  addPlacement: "/dashboard/students/:studentId/placement/form",
  financial: "/dashboard/students/:studentId/financial",
  studentTasks: "/dashboard/students/:studentId/tasks",
  addFinancial: "/dashboard/students/:studentId/financial/form",
  contactLog: "/dashboard/students/:studentId/contact-log",
  addContact: "/dashboard/students/:studentId/contact-log/form",
  changeLog: "/dashboard/students/:studentId/change-log",
  documents: "/dashboard/students/:studentId/documents",
  signingComplete: "/dashboard/signingComplete",
  profile: "/dashboard/profile",
  profileSetting: "/dashboard/profile/setting",
  campaigns: "/dashboard/campaigns",
  addCampaign: "/dashboard/campaigns/form",
  showCampaign: "/dashboard/campaigns/:id",
  responses: "/dashboard/responses",
  tasks: "/dashboard/tasks",
  studentsRegister: "/students-register",
};

export const ROUTES_MAP = {
  [ROUTES.dashboard]: {
    name: "Dashboard",
  },
  [ROUTES.programs]: {
    name: "Programs",
  },
  [ROUTES.addPrograms]: {
    name: "Add Programs",
  },
  [ROUTES.financialAidPrograms]: {
    name: "Financial Aid Programs",
  },
  [ROUTES.addFinancialAidPrograms]: {
    name: "Add Financial Aid Program",
  },
  [ROUTES.branches]: {
    name: "Branches",
  },
  [ROUTES.addBranch]: {
    name: "Add Branch",
  },
  [ROUTES.cohorts]: {
    name: "Cohorts",
  },
  [ROUTES.addCohorts]: {
    name: "Add Cohort",
  },
  [ROUTES.institution]: {
    name: "Institution",
  },
  [ROUTES.editInstitution]: {
    name: "Edit Institution",
  },
  [ROUTES.administrators]: {
    name: "Administrators",
  },
  [ROUTES.addAdministrator]: {
    name: "Add Administrator",
  },
  [ROUTES.roles]: {
    name: "Roles",
  },
  [ROUTES.addRole]: {
    name: "Add Role",
  },
  [ROUTES.students]: {
    name: "Students",
  },
  [ROUTES.addStudent]: {
    name: "Add a Student",
  },
  // students part
  [ROUTES.students]: {
    name: "Students",
  },
  [ROUTES.addStudent]: {
    name: "Add a Student",
  },
  [ROUTES.editStudent]: {
    name: "Edit Student Information",
  },
  [ROUTES.importStudent]: {
    name: "Import Students",
  },
  [ROUTES.student]: {
    name: "General Info",
  },
  [ROUTES.enrollment]: {
    name: "Enrollment",
  },
  [ROUTES.addEnrollment]: {
    name: "New Enrollment",
  },
  [ROUTES.licensure]: {
    name: "Licensure",
  },
  [ROUTES.addLicensure]: {
    name: "New Licensure",
  },
  [ROUTES.placement]: {
    name: "Placement",
  },
  [ROUTES.addPlacement]: {
    name: "New Placement",
  },
  [ROUTES.financial]: {
    name: "Financial",
  },
  [ROUTES.studentTasks]: {
    name: "Tasks",
  },
  [ROUTES.addFinancial]: {
    name: "New Financial",
  },
  [ROUTES.contactLog]: {
    name: "Contact Log",
  },
  [ROUTES.addContact]: {
    name: "New Contact Log",
  },
  [ROUTES.changeLog]: {
    name: "Change Log",
  },
  [ROUTES.documents]: {
    name: "Documents",
  },
  [ROUTES.campaigns]: {
    name: "Campaigns",
  },
  [ROUTES.addCampaign]: {
    name: "Add Campaign",
  },
  [ROUTES.responses]: {
    name: "Responses",
  },
  [ROUTES.tasks]: {
    name: "Tasks",
  },
};

export const EMPLOYMENT_SALARY = {
  "0-50000": "Under $50,000",
  "50001-60000": "$50,000 - $60,000",
  "60001-70000": "$60,000 - $70,000",
  "70001-80000": "$70,000 - $80,000",
  "80001-90000": "$80,000 - $90,000",
  90001: "Over $90,000",
};

export const NOT_SUPPORTED = "Not Supported";

export const AUDIT_TYPE_STUDENT = "Student";
export const AUDIT_TYPE_ENROLLMENT = "Enrollment";
export const AUDIT_TYPE_PLACEMENT = "Placement";
export const AUDIT_TYPE_FINANCIAL = "StudentFinancialAidProgram";
export const AUDIT_TYPE_LICENSURE = "StudentLicensureExam";
export const AUDIT_TYPE_CONTACT = "ContactLog";

export const AUDIT_TYPES = {
  [AUDIT_TYPE_STUDENT]: {
    label: "General",
    text: "general information for",
  },
  [AUDIT_TYPE_ENROLLMENT]: {
    label: "Enrollment & Completion",
    text: "an enrollment record for",
  },
  [AUDIT_TYPE_PLACEMENT]: {
    label: "Placement",
    text: "a placement record for",
  },
  [AUDIT_TYPE_FINANCIAL]: {
    label: "Financial Aid",
    text: "a financial aid program for",
  },
  [AUDIT_TYPE_LICENSURE]: {
    label: "Licensure",
    text: "a licensure for",
  },
  [AUDIT_TYPE_CONTACT]: {
    label: "Contact Log",
    text: "a contact log for",
  },
};

export const AUDIT_ACTIONS = {
  create: "added",
  update: "updated",
  destroy: "removed",
};

export const FILTER_BRANCHES = "branches";
export const FILTER_PROGRAMS = "programs";
export const FILTER_COHORTS = "cohorts";
export const FILTER_ORIGINAL_COHORTS = "original_cohorts";
export const FILTER_ENROLLMENT = "enrollment";
export const FILTER_COMPLEMENT = "complement";
export const FILTER_PLACEMENT = "placement";
export const FILTER_REPORTING_PERIOD = "reporting_period";
export const FILTER_REPORTING_YEAR = "reporting_year";
export const FILTER_MONTH = "month";

export const DESC = "desc";
export const ASC = "asc";
export const SEARCH_NAME = "q[name_cont]";
export const SORT_NAME = "q[sorts]";
export const SEARCH_STATE_NAME = "q[state_eq]";
export const SURVEY_STATUS_NAME = "q[status_eq]";

export const STUDENTS_SEARCH_NAME = "q[full_name_or_email_cont]";

export const OPTIONS_MONTH = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 6,
    value: 6,
  },
  {
    label: 7,
    value: 7,
  },
  {
    label: 8,
    value: 8,
  },
  {
    label: 9,
    value: 9,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 11,
    value: 11,
  },
  {
    label: 12,
    value: 12,
  },
];

export const MONTH = [
  {
    label: "January",
    value: 1,
  },
  {
    label: "February",
    value: 2,
  },
  {
    label: "March",
    value: 3,
  },
  {
    label: "April",
    value: 4,
  },
  {
    label: "May",
    value: 5,
  },
  {
    label: "June",
    value: 6,
  },
  {
    label: "July",
    value: 7,
  },
  {
    label: "August",
    value: 8,
  },
  {
    label: "September",
    value: 9,
  },
  {
    label: "October",
    value: 10,
  },
  {
    label: "November",
    value: 11,
  },
  {
    label: "December",
    value: 12,
  },
];

export const USA_STATES = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const OPTION_STATE = "state";
export const OPTION_NATIONAL = "national";

export const LICENSURE_TYPES = [
  {
    value: OPTION_STATE,
    label: "State",
  },
  {
    value: OPTION_NATIONAL,
    label: "National",
  },
];

export const DEGREE_TYPES = [
  {
    value: "Certificate",
    label: "Certificate",
  },
  {
    value: "Associate Degree",
    label: "Associate Degree",
  },
  {
    value: "Bachelor Degree",
    label: "Bachelor Degree",
  },
  {
    value: "Master Degree",
    label: "Master Degree",
  },
  {
    value: "Doctorate Degree",
    label: "Doctorate Degree",
  },
];

export const DAY_AFTER_GRADUATION = "DAY_AFTER_GRADUATION";
export const CUSTOM_START = "CUSTOM_START";

export const BY_PROGRAM = "BY_PROGRAM";
export const BY_BRANCH = "BY_BRANCH";
export const BY_PROGRAM_AND_PROGRAM = "BY_PROGRAM_AND_PROGRAM";
export const ALL = "ALL";

export const EDUCATION_LEVELS = [
  {
    label: "Less than high school diploma",
    value: "Less than high school diploma",
  },
  {
    label: "High school diploma or equivalent",
    value: "High school diploma or equivalent",
  },
  {
    label: "Some college but no degree",
    value: "Some college but no degree",
  },
  {
    label: "Associate degree",
    value: "Associate degree",
  },
  {
    label: "Bachelor degree",
    value: "Bachelor degree",
  },
  {
    label: "Graduate degree",
    value: "Graduate degree",
  },
];

export const OPTIONS_ETHNICITY = [
  {
    label: "American Indian or Alaska Native",
    value: "American Indian or Alaska Native",
  },
  {
    label: "Black or African American",
    value: "Black or African American",
  },
  {
    label: "White",
    value: "White",
  },
  {
    label: "Native Hawaiian or Other Pacific Islander",
    value: "Native Hawaiian or Other Pacific Islander",
  },
  {
    label: "Asian",
    value: "Asian",
  },
  {
    label: "Two or more races",
    value: "Two or more races",
  },
  {
    label: "Hispanic or Latino",
    value: "Hispanic or Latino",
  },
  {
    label: "Other/Unknown",
    value: "Other/Unknown",
  },
];

export const START_STATUSES = [
  {
    value: "Newly Enrolled",
    label: "Newly Enrolled",
  },
  {
    value: "Re-Enrolled",
    label: "Re-Enrolled",
  },
  {
    value: "Transferred from another program",
    label: "Transferred from another program",
  },
  {
    value: "Transferred from another cohort",
    label: "Transferred from another cohort",
  },
];

export const END_STATUSES = [
  {
    value: "Graduated",
    label: "Graduated",
  },
  {
    value: "Failed",
    label: "Failed",
  },
  {
    value: "Transferred to another program",
    label: "Transferred to another program",
  },
  {
    value: "Transferred to another cohort",
    label: "Transferred to another cohort",
  },
  {
    value: "Withdrawn",
    label: "Withdrawn",
  },
  {
    value: "Leave for Active Military Service",
    label: "Leave for Active Military Service",
  },
  {
    value: "Incarcerated",
    label: "Incarcerated",
  },
  {
    value: "Deceased",
    label: "Deceased",
  },
];

export const CONTACT_TYPE = [
  {
    value: "Email",
    label: "Email",
  },
  {
    value: "Phone",
    label: "Phone",
  },
  {
    value: "In Person",
    label: "In Person",
  },
  {
    value: "Other",
    label: "Other",
  },
];

export const RESULTS = [
  {
    value: "Reached",
    label: "Reached",
  },
  {
    value: "Could Not Reach",
    label: "Could Not Reach",
  },
];

export const EmployedInField = "Employed In-Field";
export const EmployedInRelatedField = "Employed In Related Field";
export const EmployedOutOfField = "Employed Out of Field";
export const SelfEmployed = "Self-Employed";
export const ContinuingEducation = "Continuing Education";
export const Military = "Military";
export const Incarcerated = "Incarcerated";
export const Deceased = "Deceased";
export const NotSeeking = "Not Seeking Employment for Personal Reasons";
export const IneligibleForEmployment =
  "Ineligible for employment in the United States";
export const StillSeekingEmployment = "Still Seeking Employment";
export const CouldNotContact = "Could Not Contact";

export const StartNewInFieldJob = "1. Start New In-field job";
export const RemainWithMyCurrent = "2. Remain with current employer";
export const NotIntend = "3. Self-enrichment - not pursuing an in-field job";
export const ContinueEducation =
  "4. Continuing education - not pursuing an in-field job";

export const EMPLOYMENT_OUTCOMES = [
  EmployedInField,
  EmployedInRelatedField,
  EmployedOutOfField,
  SelfEmployed,
];

export const UNAVAILABILITY_OUTCOMES = [
  ContinuingEducation,
  Military,
  Incarcerated,
  Deceased,
  NotSeeking,
  IneligibleForEmployment,
];

export const STILL_SEEKING_OUTCOMES = [StillSeekingEmployment, CouldNotContact];

export const OUTCOMES = [
  ...EMPLOYMENT_OUTCOMES,
  ...UNAVAILABILITY_OUTCOMES,
  ...STILL_SEEKING_OUTCOMES,
];

export const questionName = "What is your name?";
export const questionEmail = "What is your email?";
export const questionEmployed = "Are you currently employed?";
export const questionEmploymentStatus =
  "Which of the following describes your current employment status?";
export const questionTypeOfDuties =
  "Which of the following descriptions most closely match the type of duties you do in your current training-related employment?";
export const questionOfferLetter = "When did you sign your offer letter?";
export const questionSalaryType = "What best describes how you get paid?";

export const questionEmploymentPlacement = {
  same_employer:
    "Is your current position with the same employer that they were employed with before they enrolled in Front-End Development?",
  same_employer_condition:
    "Was graduating from/completing the program a condition for continued employment with this employer?",
  same_employer_promotion:
    "Was your employment after graduation a promotion (with increased pay) due (at least in part) to graduating from/completing the program?",
  hired_by_school:
    "For this position, was the you hired by your school, by an employer owned by the school, or by an employer who shares ownership with the school?",
  longer_than_5_weeks: "Do you expect the job to last longer than 5 weeks?",
  placed_by_school: "For this position, placed by school",
  chose_part_time:
    "Did the you specifically choose to seek part-time employment rather than full time employment after graduation?",
  job_title: "What is your current job title?",
  offer_date: "When did the you sign their offer letter?",
  start_date: "What is/was your start date?",
  hours_per_week: "Hours Per Week",
  duration: "What is the expected duration of this job?",
  salary:
    "What is your salary? (please exclude dollar signs and commas: for example, 70000)",
  employer_address: "Address of the firm/company",
  employer_name: "Name of the firm/company",
  employer_city: "City of the firm/company",
  employer_state: "State of the firm/company",
  employer_zip_code: "Zip Code of the firm/company",
  employer_phone: "Telephone number of the firm/company",
  employer_contact_name: "Your Immediate Supervisor’s Full Name",
  employer_contact_phone: "Your Immediate Supervisor’s Phone",
  employer_contact_email: "Your Immediate Supervisor’s Email",
};

export const questionMilitary = {
  branch_name: "Name of military branch",
  recruiting_office_address: "Address of the recruiting office",
  recruiting_office_city: "City of the recruiting office",
  recruiting_office_state: "State of the recruiting office",
  recruiting_office_zip_code: "Zip Code of the recruiting office",
  recruiting_office_phone: "Recruiting office phone number",
};

export const questionEducation = {
  program_name: "Name of the program",
  institution_name: "Name of post secondary education institution",
  institution_city: "City of the institution",
  institution_state: "State of the institution",
  institution_zip_code: "Zip Code of the institution",
};

export const questionUnavailabilityDate = "What is unavailability date?";

export const questionRate =
  "How would you rate your experience with your job search so far?";

export const questionLegallyStudy =
  "Are you legally authorized to work in the country where you are studying?";
export const questionLegallyJob =
  "Are you legally authorized to work in the country where you will be job searching?";
export const questionPrimaryIntentions =
  "What are your primary intentions for enrolling in this program?";

export const SURVEY_RESULT = [
  questionName,
  questionEmail,
  questionEmployed,
  questionEmploymentStatus,
  questionTypeOfDuties,
  questionOfferLetter,
  questionSalaryType,
];

export const componentForm = {
  street_number: "short_name",
  route: "long_name",
  locality: "long_name",
  administrative_area_level_1: "short_name",
  administrative_area_level_2: "short_name",
  country: "long_name",
  postal_code: "short_name",
};

export const OPTION_RELATIONS = [
  {
    value: "Brother",
    label: "Brother",
  },
  {
    value: "Sister",
    label: "Sister",
  },
  {
    value: "Mother",
    label: "Mother",
  },
  {
    value: "Father",
    label: "Father",
  },
  {
    value: "Aunt",
    label: "Aunt",
  },
  {
    value: "Uncle",
    label: "Uncle",
  },
  {
    value: "Grandparent",
    label: "Grandparent",
  },
  {
    value: "Peer",
    label: "Peer",
  },
  {
    value: "Student",
    label: "Student",
  },
  {
    value: "Legal Guardian",
    label: "Legal Guardian",
  },
  {
    value: "Other family relation",
    label: "Other family relation",
  },
];

export const OPTION_MILITARY_BRANCH = [
  {
    value: "Army",
    label: "Army",
  },
  {
    value: "Marine Corps",
    label: "Marine Corps",
  },
  {
    value: "Navy",
    label: "Navy",
  },
  {
    value: "Air Force",
    label: "Air Force",
  },
  {
    value: "Space Force",
    label: "Space Force",
  },
  {
    value: "Coast Guard",
    label: "Coast Guard",
  },
];

export const OPTION_REPORTING_PERIOD = [
  {
    value: 1,
    label: "January - June",
  },
  {
    value: 2,
    label: "July - December",
  },
];

const currentYear = moment().get("year");

export const OPTION_REPORTING_YEAR = [
  {
    value: currentYear,
    label: currentYear,
  },
  {
    value: currentYear - 1,
    label: currentYear - 1,
  },

  {
    value: currentYear - 2,
    label: currentYear - 2,
  },
  {
    value: currentYear - 3,
    label: currentYear - 3,
  },
  {
    value: currentYear - 4,
    label: currentYear - 4,
  },
  {
    value: currentYear - 5,
    label: currentYear - 5,
  },
  {
    value: currentYear - 6,
    label: currentYear - 6,
  },
  {
    value: currentYear - 7,
    label: currentYear - 7,
  },
  {
    value: currentYear - 8,
    label: currentYear - 8,
  },
  {
    value: currentYear - 9,
    label: currentYear - 9,
  },
];

export const reportingMapLabels = {
  school_name: "School Name",
  campus_location: "Campus Location",
  published_course_length: "Published Course Length",
  program_name: "Program Name",
  reporting_period: "Reporting Period",
  graduates_included_in_report: "Graduates in Report",
  graduation_requirements: "Graduation Requirements",
  program_degree: "Program Level",
  program_title: "Program Title",
  institution_id: "Institution ID",
  curriculum_category: "Curriculum Category",
  curriculum_id: "Curriculum ID",
  clock_hours: "Total Clock Hours",
  program_cost: "Total Cost",
  program_length: "Program Length",
};

export const reportingMapLabelsForTable = {
  frequent_job_titles: "Most Frequent Job Titles",
  hired_by_school: "Hired by School",
  salary: "Salary",
  exam_results: "Exam Passage Rate",
  employment_results: "Employment Results",
  graduation_100: "Graduation Data",
  seek_in_field_employment: "Job Seekers",
  available_for_employment: "Placement",
  completion_rate_150: "Financial & Graduation",
  employed_full_time: "Gainfully Employed Categories",
  enrollment_results: "Enrollment",
  completion_results: "Completion",
  placement_results: "Placement",
  admission_results: "Admissions",
  financial_aid_results: "Financial Assistance for Program",
  beginning_enrollment: "Enrollment",
  total_completers: "Completion",
  total_completers_employed_in_field: "Placement",
  graduation_rate: "Rates",
  enrollee_transfers_in: "Enrollment",
  completion_waiver_percentage: "Completion",
  placement_waiver_percentage: "Placement",
  graduates: "Annual Student Graduate Data",
  requested_placement: "Placement Statistics",
  demographics: "Annual Student Graduate Race/Ethnicity & Gender Data",
};

export const reportingMapLabelsForTableItem = {
  Developer: "Developer",
  "Front-End Engineer": "Front-End Engineer",
  "Project Manager": "Project Manager",
  "Software Developer": "Software Developer",
  "Software Engineer": "Software Engineer",
  "New Job Title": "New Job Title",
  "New Job": "New Job",
  graduation_100:
    "How many students graduate within 100% of published program length (on-time)?",
  graduation_150:
    "How many students graduate within 150% of published program length",
  seek_in_field_employment:
    "How many students intended to seek in-field employment within 180 days of graduating?",
  not_seek_in_field_employment:
    "How many students did not intend to seek in-field employment (returning to previous employer, no work authorization, continuing to further education, or self-enrichment)?",
  number_of_degrees_awarded: "Number of Degrees or Diplomas Awarded",
  total_charges_for_this_program: "Total Charges for this Program",
  enrolled_federal_aid:
    "Percentage of enrolled students receiving federal student loans",
  graduated_federal_aid:
    "Percentage of graduates receiving federal student loans",
  began_the_program: "Number of Students Who Began the Program",
  available_for_graduation: "Number of Students Available for Graduation",
  on_time_graduates: "Number of On-Time Graduates",
  completion_rate: "Completion Rate",
  completion_rate_150: "150% Completion Rate",
  available_for_employment: "Graduates Available for Employment",
  employed_in_field: "Graduates Employed in Field",
  placement_rate: "Placement Rate",
  enrolled_prior: "Enrolled Prior",
  new_start: "New Starts",
  re_entry: "Re-Entries",
  transfer_in: "Transfers from Another Program",
  enrollment_total: "Total Enrollments",

  still_enrolled: "Still Enrolled",
  transfer_out: "Transfers to Another Program",
  military: "Leave for Active Military Service",
  incarcerated: "Incarcerated",
  deceased: "Deceased",
  other_leaver: "Other Non-Graduating Leavers",
  completer: "Completers",

  placed: "Placed",
  not_placed: "Not Placed",
  post_sec_education: "Post Secondary Education",
  military_service: "Active Military Service",
  other: "Other",

  // COE
  beginning_enrollment: "Beginning Enrollment",
  new_enrollment: "New Enrollment",
  cumilative_enrollment: "Cumilative Enrollment",

  non_graduate_completers: "Non Graduate Completers",
  graduate_completers: "Graduate Completers",
  total_completers: "Total Completers",

  non_graduate_completers_employed_in_field:
    "Non Graduate Completers Employed In field",
  graduate_completers_employed_in_field:
    "Graduate Completers Employed In Field",
  total_completers_employed_in_field: "Total Completers Employed In field",
  graduate_completers_employed_out_of_field:
    "Graduate Completers Employed Out Of Field",
  graduate_completers_awaiting_results: "Graduate Completers Awaiting Results",
  graduate_completers_took_exam: "Graduate Completers Took Exam",
  graduate_completers_passed_exam: "Graduate Completers Passed exam",
  graduate_completers_unavailble_for_employment:
    "Graduate Completers Unavailable For employment",
  graduate_completers_refused_employment:
    "Graduate Completers Refused Employment",
  graduate_completers_seeking_employment:
    "Graduate Completers Seeking Employment",
  withdrawls: "Withdrawls",

  sum_of_16_19_20: "Sum Of 16 19 20",
  row_10_minus_23: "Row 10 minus 23",
  row_11_minus_23: "Row 11 Minus 23",
  graduation_rate: "Graduation Rate",
  total_completion_rate: "Total Completion Rate",
  graduate_placement_rate: "Graduate Placement Rate",
  total_placement_rate: "Total Placement Rate",
  licensure_exam_pass_rate: "Licensure Exam Pass Rate",

  // ACCET
  gross_starts: "Gross Starts",
  net_starts: "Net Starts",
  enrollee_transfers_in: "Enrollee Transfers In",
  enrollee_transfers_out: "Enrollee Transfers Out",

  completions_a: "Completions A",
  completions_b: "Completions B",
  completions_c: "Completions C",
  completion_waiver_percentage: "Completion Waiver Percentage",
  eligible_completions: "Eligible Completions",
  completion_percentage: "Completion Percentage",

  placement_waivers: "Placement Waivers",
  placement_waiver_percentage: "Placement Waiver Percentage",
  eligible_placements: "Eligible Placements",
  total_placements: "Total Placements",
  placement_percentage: "Placement Percentage",

  // DPOS
  requested_placement: "Number of graduates who requested placement assistance",
  placed_in_field:
    "Number of graduates who received job offers for which they were trained",
  placed_in_related_field:
    "Number of graduates who received job offers in a related area for which they were trained",
};

export const CIRR_SALARY = {
  median_annual_base_salary: "Median Annual Base Salary",
  reported_salaries: "Percentage of job obtainers who reporterd salaries",
  0: "Under $50,000",
  50000: "$50,000-60,000",
  60000: "$60,000-70,000",
  70000: "$70,000-80,000",
  80000: "$80,000-90,000",
  90000: "Over $90,000",
};

export const CIRR_HIRED_BY_SCHOOL = {
  hired_by_school: "Hired by School",
};

export const MAP_LABEL_90_180 = {
  salary_90: "value_90",
  salary_180: "value_180",
  employment_results_90: "value_90",
  employment_results_180: "value_180",
};

export const CIRR_EMPLOYMENT_RESULTS = {
  outcome_1_total: "Employed in-field",
  outcome_1A: "Full-time employee (30+ hours/week, 6+ months)",
  outcome_1B:
    "Full-time apprenticeship, internship, or contract position (30+ hours/week, 3-6 months) ",
  outcome_1C:
    "Short-term contract, part-time position, freelance, or unknown length ",
  outcome_1D: "Started a new company or venture after graduation",
  outcome_2_total: "Not seeking in-field employment",
  outcome_2A: "Employed out-of-field",
  outcome_2B: "Continuing to higher education",
  outcome_2C: "Not seeking a job for health, family, or personal reasons",
  outcome_3: "Still seeking a job in-field",
  outcome_4: "Could not contact",
};

export const BPPE_EXAM_PASSAGE_RATE = {
  entity_name: "Name of Entity",
  exam_name: "Name of State Exam",
  number_of_graduates_taking_state_exam: "Number of Graduates Taking Exam",
  number_of_graduates_passed_exam: "Number Who Passed Exam",
  number_of_graduates_failed_exam: "Number Who Failed Exam",
  passage_rate: "Passage Rate",
};

export const BPPE_FINANCIAL_GRADUATION = {
  number_of_degrees_awarded: "Number of Degrees or Diplomas Awarded",
  total_charges_for_this_program: "Total Charges for this Program",
  enrolled_federal_aid:
    "Percentage of enrolled students receiving federal student loans",
  graduated_federal_aid:
    "Percentage of graduates receiving federal student loans",
  began_the_program: "Number of Students Who Began the Program",
  available_for_graduation: "Number of Students Available for Graduation",
  on_time_graduates: "Number of On-Time Graduates",
  completion_rate: "Completion Rate",
  completion_rate_150: "150% Completion Rate",
};

export const BPPE_PLACEMENT = {
  available_for_employment: "Graduates Available for Employment",
  employed_in_field: "Graduates Employed in Field",
  placement_rate: "Placement Rate",
};

export const BPPE_EMPLOYMENT = {
  employed_full_time: "Graduates Employed In-Field 20-29 hours/week",
  employed_in_field: "Graduates Employed In-Field at least 30 hours/week",
  employed_part_time:
    "Short-term contract, part-time position, freelance, or unknown length ",
  single_position: "Graduates Employed In-Field Single Position ",
  concurrent_position: "Graduates Employed In-Field Concurrent Position ",
  freelance_or_self_employed: "Graduates Employed who are Self-Employed ",
  placement_rate: "Employed by the Institution or Employer???",
};

export const COE_ENROLMENT = {
  beginning_enrollment: "Beginning Enrollment",
  new_enrollment: "New Enrollment",
  cumilative_enrollment: "Cumilative Enrollment",
};

export const COE_COMPLETION = {
  still_enrolled: "Still Enrolled",
  non_graduate_completers: "Non Graduate Completers",
  graduate_completers: "graduate Completers",
  total_completers: "Total Completers",
};

export const COE_PLACEMENT = {
  non_graduate_completers_employed_in_field:
    "Non Graduate Completers Employed In field",
  graduate_completers_employed_in_field:
    "Graduate Completers Employed In Field",
  total_completers_employed_in_field: "Total Completers Employed In field",
  graduate_completers_employed_out_of_field:
    "Graduate Completers Employed Out Of Field",
  graduate_completers_awaiting_results: "Graduate Completers Awaiting Results",
  graduate_completers_took_exam: "Graduate Completers Took Exam",
  graduate_completers_passed_exam: "Graduate Completers Passed exam",
  graduate_completers_unavailble_for_employment:
    "Graduate Completers Unavailable For employment",
  graduate_completers_refused_employment:
    "Graduate Completers Refused Employment",
  graduate_completers_seeking_employment:
    "Graduate Completers Seeking Employment",
  withdrawls: "Withdrawls",
};

export const COE_RATES = {
  sum_of_16_19_20: "Sum Of 16 19 20",
  row_10_minus_23: "Row 10 minus 23",
  row_11_minus_23: "Row 11 Minus 23",
  graduation_rate: "Graduation Rate",
  total_completion_rate: "Total Completion Rate",
  graduate_placement_rate: "Graduate Placement Rate",
  total_placement_rate: "Graduate Placement Rate",
  licensure_exam_pass_rate: "Licensure Exam Pass Rate",
};

export const ACCET_ENROLMENT = {
  gross_starts: "Gross Starts",
  net_starts: "Net Starts",
  enrollee_transfers_in: "Enrollee Transfers In",
  enrollee_transfers_out: "Enrollee Transfers Out",
};

export const ACCET_COMPLETION = {
  completions_a: "Completions A",
  completions_b: "Completions B",
  completions_c: "Completions C",
  completion_waiver_percentage: "Completion Waiver Percentage",
  eligible_completions: "Eligible Completions",
  completion_percentage: "Completion Percentage",
};

export const ACCET_PLACEMENT = {
  placement_waivers: "Placement Waivers",
  placement_waiver_percentage: "Placement Waiver Percentage",
  eligible_placements: "Eligible Placements",
  total_placements: "Total Placements",
  placement_percentage: "Placement Percentage",
};

export const DPOS_PLACEMENT = {
  requested_placement: "Number of graduates who requested placement assistance",
  placed_in_field:
    "Number of graduates who received job offers for which they were trained",
  placed_in_related_field:
    "Number of graduates who received job offers in a related area for which they were trained",
};
export const AGENCIES_TYPES = ["CIRR", "COE", "ACCET", "VET TEC", "VRRAP"];

export const SURVEY_STATUSES = [
  { value: "upcoming", label: "Upcoming" },
  { value: "sent", label: "Sent" },
];

export const questionSeeking = "question_seeking";
export const questionSatisfaction = "question_satisfaction";

export const TABLE_GENERAL_INFORMATION = [
  {
    name: "FIRST_NAME",
    description: "First name of the student",
    required: true,
  },
  {
    name: "LAST_NAME",
    description: "Last name of the student",
    required: true,
  },
  {
    name: "EMAIL",
    description: "Email address of the student",
    required: true,
  },
  {
    name: "PHONE",
    description: "Contact number for the student",
  },
  {
    name: "DOB",
    description: "Date of Birth of the student. Must be in mm/dd/yyyy format. ",
  },
  {
    name: "GENDER",
    description:
      "Gender of the student ( Valid values: ‘male’, ‘female’, ‘non-binary/‘ )",
  },
  {
    name: "ETHNICITY",
    description:
      "Ethnicity of the student ( Valid values: 'American Indian or Alaska Native', 'Black or African American', 'White', 'Native Hawaiian or Other Pacific Islander', 'Asian', 'Two or more races’, ‘Hispanic or Latino', 'Other/Unknown' )",
  },
  {
    name: "EDUCATION_LEVEL",
    description:
      'Education Level of the student ( Valid values:  "Less than high school diploma", "High school diploma or equivalent", "Some college but no degree", "Associate degree", "Bachelor degree", "Graduate degree",)',
  },
  {
    name: "ADDRESS",
    description: "Street address of student’s primary address",
  },
  {
    name: "CITY",
    description: "City of student’s primary address	",
  },
  {
    name: "STATE",
    description: "State of student’s primary address",
  },
  {
    name: "ZIP_CODE",
    description: "ZIP code of student’s primary address",
  },
];

export const TABLE_ENROLMENT = [
  {
    name: "STUDENT_EMAIL",
    description: "Email address of an existing student",
    required: true,
  },
  {
    name: "COHORT",
    description: "Name of an existing cohort",
    required: true,
  },
  {
    name: "START_STATUS",
    description:
      "Start status for the enrollment.  ( Valid values: ‘Newly Enrolled’, ‘Re-Enrolled’, ‘Transferred from another program’, ‘Transferred from another cohort’. )",
    required: true,
  },
  {
    name: "START_DATE",
    description: "Start date for the enrollment",
  },
  {
    name: "START_COMMENTS",
    description: "Comments related to the start of the enrollment ",
  },
  {
    name: "END_STATUS",
    description:
      "End status for the enrollment ( Valid values: ‘Graduated’, ‘Failed’, ‘Transferred to another program’, ‘Transferred to another cohort’, ‘Withdrawn’, ‘Leave for Active Military Service’, ‘Incarcerated’, ‘Deceased’)",
  },
  {
    name: "END_DATE",
    description:
      "End date for the enrollment ( Required if end_status is present )",
  },
  {
    name: "END_COMMENT",
    description: "Comments related to the end of the enrollment",
  },
];

export const TABLE_PLACEMENT = [
  {
    name: "STUDENT_EMAIL",
    description: "Email address of an existing student",
    required: true,
  },
  {
    name: "PROGRAM",
    description:
      "Name of an existing program ( Student must have an existing enrollment for this program )",
    required: true,
  },
  {
    name: "OUTCOME",
    description:
      'Outcome of the placement ( Valid values: ‘Employed In-Field’, ‘Employed In Related Field’, ‘Employed Out of Field’, ‘Self-Employed’, ‘Continuing Education’, ‘Military’, ‘Incarcerated’, ‘Deceased’, ‘Not Seeking Employment for Personal Reasons’ , ‘Ineligible for employment in the United States ‘, ‘Still Seeking Employment‘,"Could Not Contact" )',
    required: true,
  },
  {
    name: "SOC_CODE",
    description:
      "In-Field SOC Code of the employment placement ( Required if outcome is Employed In-Field )",
  },
  {
    name: "SELF_EMPLOYMENT_TYPE",
    description:
      'Self employment type of the employment placement ( Valid Values: "Freelance", "Entrepreneur" )',
  },
  {
    name: "JOB_TITLE",
    description: "Job title of the employment placement",
  },
  {
    name: "OFFER_DATE",
    description: "Offer date of the employment placement",
  },
  {
    name: "START_DATE",
    description: "Start Date of the employment placement ",
  },
  {
    name: "HOURS_PER_WEEK",
    description:
      "Hours Per Week of the employment placement ( Required if Outcome is ‘Employed In-Field’, ‘Employed In Related Field’, ‘Employed Out of Field’, ‘Self-Employed’ )",
  },
  {
    name: "DURATION",
    description:
      'Duration of the employment placement ( Valid Values: "6+ months", "3-6 months", "Less than 3 months" )',
  },
  {
    name: "SALARY_TYPE",
    description:
      'Salary type of the employment placement ( Valid Values:  "Annual", "Weekly", "Hourly" )',
  },
  {
    name: "SALARY",
    description:
      "Salary of the employment placement ( Required if Outcome is ‘Employed In-Field’, ‘Employed In Relate Field’, ‘Employed Out of Field’, ‘Self-Employed )",
  },
  {
    name: "HIRE_BY_SCHOOL",
    description:
      "Indicates if student was hired by the school (Valid values: Yes, No )",
  },
  {
    name: "EMPLOYER_NAME",
    description:
      "Employer name of the employment placement ( Required if Outcome is ‘Employed In-Field’, ‘Employed In Related Field’, ‘Employed Out of Field’, ‘Self-Employed’ )",
  },
  {
    name: "EMPLOYER_ADDRESS",
    description:
      "Employer address of the employment placement ( Required if Outcome is ‘Employed In-Field’, ‘Employed In Related Field’, ‘Employed Out of Field’, ‘Self-Employed’ )",
  },
  {
    name: "EMPLOYER_CITY",
    description:
      "Employer city of the employment placement ( Required if Outcome is ‘Employed In-Field’, ‘Employed In Related Field’, ‘Employed Out of Field’, ‘Self-Employed’ )",
  },
  {
    name: "EMPLOYER_STATE",
    description:
      "Employer state of the employment placement ( Required if Outcome is ‘Employed In-Field’, ‘Employed In Related Field’, ‘Employed Out of Field’, ‘Self-Employed’ )",
  },
  {
    name: "EMPLOYER_ZIP_CODE",
    description:
      "Employer ZIP code of the employment placement ( Required if Outcome is ‘Employed In-Field’, ‘Employed In Related Field’, ‘Employed Out of Field’, ‘Self-Employed’ )",
  },
  {
    name: "EMPLOYER_PHONE",
    description:
      "Employer phone of the employment placement ( Required if Outcome is ‘Employed In-Field’, ‘Employed In Related Field’, ‘Employed Out of Field’, ‘Self-Employed’ )",
  },
  {
    name: "EMPLOYER_CONTACT_NAME",
    description:
      "Employer contact name of the employment placement ( Required if Outcome is ‘Employed In-Field’, ‘Employed In Related Field’, ‘Employed Out of Field’, ‘Self-Employed’ )",
  },
  {
    name: "EMPLOYER_CONTACT_PHONE",
    description:
      "Employer contact phone of the employment placement ( Required if Outcome is ‘Employed In-Field’, ‘Employed In Related Field’, ‘Employed Out of Field’, ‘Self-Employed’ )",
  },
  {
    name: "EMPLOYER_CONTACT_EMAIL",
    description:
      "Employer contact email of the employment placement ( Required if Outcome is ‘Employed In-Field’, ‘Employed In Related Field’, ‘Employed Out of Field’, ‘Self-Employed’ )",
  },
  {
    name: "JOB_TYPE",
    description:
      'Job type of the employment placement ( Valid Values: "Single", "Concurrent" )',
  },
  {
    name: "LONGER_THAN_5_WEEKS",
    description:
      "Indicates if employment is longer than 5 weeks ( Valid values: Yes, No )",
  },
  {
    name: "CHOOSE_PART_TIME",
    description:
      "Indicates if student chose to work part-time ( Valid values: Yes, No )",
  },
  {
    name: "SAME_EMPLOYER",
    description:
      "Indicates if student was hired by the same employer as before starting the program ( Valid values: Yes, No )",
  },
  {
    name: "SAME_EMPLOYER_PROMOTION",
    description:
      "Indicates if job placement was a result of a promotion with the same employer due to graduating from the program ( Required if same_employer is Yes ) (Valid values: Yes, No)",
  },
  {
    name: "SAME_EMPLOYER_CONDITION",
    description:
      "Indicates if graduation from the program was a condition for continued employment with the same employer ( Required if same_employer is Yes ) (Valid values: Yes, No)",
  },
  {
    name: "PROGRAM_NAME",
    description:
      "Program name of the education placement ( Required if Outcome is ‘Continuing Education’ )",
  },
  {
    name: "INSTITUTION_NAME",
    description:
      "Institution name of the education placement  ( Required if Outcome is ‘Continuing Education’ )",
  },
  {
    name: "INSTITUTION_CITY",
    description:
      "Institution city of the education placement  ( Required if Outcome is ‘Continuing Education’ )",
  },
  {
    name: "INSTITUTION_STATE",
    description:
      "Institution name of the education placement ( Required if Outcome is ‘Continuing Education’ )",
  },
  {
    name: "INSTITUTION_ZIP_CODE",
    description:
      "Institution name of the education placement ( Required if Outcome is ‘Continuing Education’ )",
  },
  {
    name: "BRANCH_NAME",
    description:
      "Branch name of the military placement  ( Required if Outcome is ‘Military’ )",
  },
  {
    name: "RECRUITING_OFFICE_ADDRESS",
    description:
      "Recruiting Office address of the military placement  ( Required if Outcome is ‘Military’ )",
  },
  {
    name: "RECRUITING_OFFICE_CITY",
    description:
      "Recruiting Office city of the military placement  ( Required if Outcome is ‘Military’ )",
  },
  {
    name: "RECRUITING_OFFICE_STATE",
    description:
      "Recruiting Office state of the military placement  ( Required if Outcome is ‘Military’ )",
  },
  {
    name: "RECRUITING_OFFICE_ZIP_CODE",
    description:
      "Recruiting Office ZIP code of the military placement  ( Required if Outcome is ‘Military’ )",
  },
  {
    name: "RECRUITING_OFFICE_PHONE",
    description:
      "Recruiting Office phone of the military placement  ( Required if Outcome is ‘Military’ )",
  },
  {
    name: "UNAVAILABLE_DATE",
    description:
      "Unavailability Date for unavailable for employment outcome  ( Required if Outcome is ‘Continuing Education’, ‘Military’, ‘Incarcerated’, ‘Deceased’, ‘Not Seeking Employment for Personal Reasons’, ‘Ineligible for employment in the United States’ )",
  },
];

export const GraduateFollowUp = "Graduate Follow-Up";
export const CIRRStudentIntent = "CIRR Student Intent";

export const SEND_SURVEY_OPTIONS_TYPE = [
  { value: GraduateFollowUp, label: GraduateFollowUp },
  { value: CIRRStudentIntent, label: CIRRStudentIntent },
];

export const GENDER = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Non-binary", value: "non-binary" },
];

export const DAYS_OF_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
