import * as types from "../constants";
import request from "../../utils/request";

export function postLogin(data, role) {
  return (dispatch) => {
    dispatch({ type: types.POST_LOGIN });
    return request()
      .post(
        role === "admin" ? "/auth/sign_in" : "/student-auth/sign_in",
        role === "admin" ? { user: data } : { student: data }
      )
      .then((dataResponse) => {
        dispatch({
          type: types.POST_LOGIN_SUCCEED,
          payload: dataResponse,
        });
        return dataResponse;
      })
      .catch((error) => {
        dispatch({
          type: types.POST_LOGIN_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
