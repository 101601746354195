import React from "react";

export default function () {
  return (
    <svg
      width="10"
      height="4"
      viewBox="0 0 10 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.506 3.85C7.79533 3.25267 8.066 2.80467 8.318 2.506H0.156V1.918H8.318C8.066 1.61933 7.79533 1.17133 7.506 0.574H7.996C8.584 1.25533 9.2 1.75933 9.844 2.086V2.338C9.2 2.65533 8.584 3.15933 7.996 3.85H7.506Z"
        fill="#3C4854"
      />
    </svg>
  );
}
