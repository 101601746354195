import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SelectUserRole } from "components/common";
import PropTypes from "prop-types";
import FormGroup from "../common/FormGroup";
import logo from "../../assets/images/logo2.svg";

function ForgotPassword({
  form,
  formErrors,
  onSubmit,
  onChange,
  onFocus,
  isSubmitting,
  showMessage,
  role,
  setRole,
}) {
  return (
    <section className="ForgotPassword bg-form">
      <Link to="/" className="logo-form">
        <img src={logo} alt="" />
      </Link>
      <div className="container">
        <div className="ForgotPassword__inner academy-form">
          <Form noValidate onSubmit={onSubmit}>
            {showMessage ? (
              <div className="form-message">
                <p className="text--xlarge bold">Password Reset Email Sent!</p>
                <p className="text--xsmall">
                  Please follow the link in the email to complete your password
                  reset request
                </p>
              </div>
            ) : (
              <>
                <p className="text--xlarge bold">Reset Your Password</p>
                <p className="text--xsmall">
                  Please fill your email to reset password
                </p>
                <SelectUserRole
                  userRole={role}
                  onChangeRole={(selectedRole) => setRole(selectedRole)}
                />
                <div className="form__inner">
                  <FormGroup
                    propsInput={{
                      name: "email",
                      placeholder: "Email",
                      type: "email",
                      onChange,
                      onFocus,
                      value: form.email,
                      disabled: isSubmitting,
                    }}
                    error={formErrors.email}
                    label="Email"
                  />

                  <button
                    className="button button--primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Send
                  </button>
                </div>
              </>
            )}
          </Form>
        </div>
      </div>
    </section>
  );
}

ForgotPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    email: PropTypes.string,
  }),
  showMessage: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

ForgotPassword.defaultProps = {
  form: {
    email: "",
  },
  showMessage: false,
  formErrors: {},
};

export default ForgotPassword;
