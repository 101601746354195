import React from "react";

function Programs() {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3"
        y="6"
        width="11"
        height="15"
        rx="1"
        stroke="white"
        strokeWidth="2"
      />
      <path
        d="M12 6.52941V4C12 3.44772 12.4477 3 13 3H19.1301C19.3688 3 19.5996 3.08539 19.7809 3.24074L22.6508 5.70068C22.8724 5.89066 23 6.16801 23 6.45993V17C23 17.5523 22.5523 18 22 18H14.5385"
        stroke="white"
        strokeWidth="2"
      />
      <rect x="6" y="9" width="5" height="2" rx="1" fill="white" />
      <rect x="14" y="11" width="5" height="2" rx="1" fill="white" />
      <rect x="6" y="13" width="5" height="2" rx="1" fill="white" />
      <path
        d="M18 3V7C18 7.55228 18.4477 8 19 8H23"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
}

export default Programs;
