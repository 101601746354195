import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { RequiredStringSchema } from "lib/zod";
import type { SurveyInfo } from "../api";
import { useSurveyStore } from "../store";
import {
  Card,
  ButtonArea,
  Button,
  Form,
  StepHeader,
  TextInput,
} from "../components";

type Props = {
  surveyInfo: SurveyInfo;
  progress?: string;
  onNextClick: () => void;
  onBackClick: () => void;
};

const schema = z.object({
  institutionName: RequiredStringSchema,
  institutionAddress: RequiredStringSchema,
  institutionCity: RequiredStringSchema,
  institutionState: RequiredStringSchema,
  institutionZipCode: RequiredStringSchema,
});

type Inputs = z.infer<typeof schema>;

export function EducationInstitutionInformationStep(props: Props) {
  const { surveyInfo, progress, onNextClick, onBackClick } = props;
  const [state, setState] = useSurveyStore((store) => [
    store.educationInstitutionInformation,
    store.setEducationInstitutionInformation,
  ]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: state,
    resolver: zodResolver(schema),
  });

  const handleAddressAutocomplete = (result: AutocompleteAddress) => {
    if (!result) return;
    setValue("institutionAddress", result.address);
    setValue("institutionCity", result.city);
    setValue("institutionState", result.state);
    setValue("institutionZipCode", result.zip);
  };

  const handleBackClick = () => {
    setState(getValues());
    onBackClick();
  };

  const handleNextClick = async () => {
    await handleSubmit((data) => {
      setState(data);
      onNextClick();
    })();
  };

  return (
    <Card progress={progress}>
      <StepHeader surveyInfo={surveyInfo} />

      <Form>
        <TextInput
          label="Name of post secondary education institution"
          placeholder="Institution Name"
          error={errors.institutionName?.message}
          {...register("institutionName")}
        />
        <TextInput
          autoComplete
          onPlaceSelected={handleAddressAutocomplete}
          label="Address of the institution"
          placeholder="Address"
          error={errors.institutionAddress?.message}
          {...register("institutionAddress")}
        />
        <TextInput
          label="City of the institution"
          placeholder="City"
          error={errors.institutionCity?.message}
          {...register("institutionCity")}
        />
        <TextInput
          label="State of the institution"
          placeholder="State"
          error={errors.institutionState?.message}
          {...register("institutionState")}
        />
        <TextInput
          label="Zip Code of the institution"
          placeholder="Zip Code"
          error={errors.institutionZipCode?.message}
          {...register("institutionZipCode")}
        />
      </Form>

      <ButtonArea>
        <Button label="Back" onClick={handleBackClick} />
        <Button primary label="Continue" onClick={handleNextClick} />
      </ButtonArea>
    </Card>
  );
}
