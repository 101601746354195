/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import FormGroup from "../../../common/FormGroup";

function Step2({ onSubmit, handleBack, isSubmitting, onChange, actionType }) {
  return (
    <div className="pull-Step3  form-student dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="form-student__text">
          <p className="text--large bold">Map Data to Push</p>
          <p className="text--small">
            Keep your student data up to date. Push enrollment, completion, and
            placement information directly from AcademySync into HubSpot.
          </p>
          <div className="text bold">
            Would you like to push enrollment, completion, and placement data to
            HubSpot?
          </div>
          <div className="checkbox__push__hubspot">
            <div className="checkbox">
              Yes
              <FormGroup
                propsInput={{
                  type: "radio",
                  name: "push",
                  value: "yes",
                  onChange,
                  checked: actionType === "yes",
                }}
                type="checkbox"
              />
            </div>
            <div className="checkbox">
              No
              <FormGroup
                propsInput={{
                  type: "radio",
                  name: "push",
                  value: "no",
                  onChange,
                  checked: actionType === "no",
                }}
                type="checkbox"
              />
            </div>
          </div>
        </div>

        <div className="form-student__inner">
          <div className="button-group">
            <button
              className="button button--back"
              type="submit"
              onClick={handleBack}
              disabled={isSubmitting}
            >
              Back
            </button>
            <button
              className="button button--primary"
              type="submit"
              disabled={isSubmitting || !actionType}
            >
              Map
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

Step2.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
};

Step2.defaultProps = {
  isSubmitting: false,
};

export default Step2;
