import { useCallback, useEffect } from "react";

// Get actual height (on mobile) of vertical height and set it on --vh
// https://stackoverflow.com/a/60229913/479732
export function useFullScreenHeight() {
  const resizeCallback = useCallback(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);

  useEffect(() => {
    resizeCallback();
  }, [resizeCallback]);

  useEffect(() => {
    window.addEventListener("resize", resizeCallback);

    return () => window.removeEventListener("resize", resizeCallback);
  }, [resizeCallback]);
}
