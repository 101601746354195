import { FormGroup } from "components/common";
import React from "react";
import { useSelector } from "react-redux";
import request from "utils/request";

function Component() {
  const [enrollments, setEnrollments] = React.useState();
  const [errorEnrollments, setErrorEnrollments] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const storeInstitution = useSelector(
    (store) => store.institution.institution
  );
  const dataInstitution = storeInstitution.data;

  const handleChangeEnrollments = (e) => {
    setEnrollments(e.target.value);
  };

  const handleCheckout = async (e) => {
    e.preventDefault();
    setErrorEnrollments("");
    if (!enrollments || enrollments <= 0) {
      setErrorEnrollments("Enrollments is required");
      return;
    }
    try {
      setLoading(true);
      const res = await request().get(`/payment/checkout?count=${enrollments}`);
      setLoading(false);
      window.location.href = res?.data?.data;
    } catch (err) {
      setLoading(false);
      console.error("submit checkout failed: ", err);
    }
  };

  return (
    <div className="Account dashboard__item__inner ">
      <div className="Account__row">
        <p className="text--small gray03">Total Purchased Enrollments</p>
        <p className="text--small bold">
          {dataInstitution?.purchased_enrollments || 0}
        </p>
      </div>
      <div className="Account__row">
        <p className="text--small gray03">Remaining Enrollments</p>
        <p className="text--small bold">
          {dataInstitution?.available_enrollments || 0}
        </p>
      </div>
      <div className="Account__row">
        <p className="text--small gray03">Purchase New Enrollments</p>
        <p className="text--small bold">
          <FormGroup
            propsInput={{
              name: "count",
              placeholder: "Enter number of student enrollments",
              onChange: handleChangeEnrollments,
              value: enrollments,
              disabled: loading,
            }}
            error={errorEnrollments}
          />
          <button
            className="button button--primary"
            type="submit"
            disabled={loading || !enrollments}
            onClick={handleCheckout}
          >
            Checkout
          </button>
        </p>
      </div>
    </div>
  );
}

export default Component;
