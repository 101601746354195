import React from "react";
import { useLocation } from "react-router-dom";
import {
  GetStart,
  AboutUs,
  HowItWorks,
  Agencies,
  Support,
  ContactUs,
} from "../components/landing";

export default function () {
  const location = useLocation();
  const { hash } = location;

  React.useEffect(() => {
    if (hash) {
      const selectedSection = document.querySelector(hash);
      if (selectedSection) {
        selectedSection.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [hash]);

  return (
    <>
      <GetStart />
      <AboutUs />
      <HowItWorks />
      <Agencies />
      <Support />
      <ContactUs />
    </>
  );
}
