import React from "react";
import { isEmail } from "validator";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddAdministrator from "../../../components/dashboard/institution/AddAdministrator";
import { ROUTES } from "../../../utils/constants";
import { openMessage } from "../../../redux/actions/message";
import {
  createAdministrator,
  getAllRoles,
  updateAdministrator,
} from "../../../redux/actions/institution";
import { handleErrorsAfterCallingAPI } from "../../../utils/helpers";

const DEFAULT_FORM_ADMINISTRAROR = {
  first_name: "",
  last_name: "",
  email: "",
  user_roles_attributes: [],
};

export default function () {
  const [form, setForm] = React.useState({
    ...DEFAULT_FORM_ADMINISTRAROR,
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [role, setRole] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const storeCreate = useSelector(
    (store) => store.institution.createAdministrator
  );
  const storeUpdate = useSelector(
    (store) => store.institution.updateAdministrator
  );
  const isEditing = !!history.location.state;
  const storeAllRoles = useSelector((store) => store.institution.allRoles);

  const isSubmitting = storeCreate.loading || storeUpdate.loading;

  React.useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  React.useEffect(() => {
    const administratorSelected = history.location.state;
    if (!administratorSelected) return;
    setForm({
      ...form,
      first_name: administratorSelected.first_name,
      last_name: administratorSelected.last_name,
      email: administratorSelected.email,
      user_roles_attributes: administratorSelected.user_roles.map((item) => ({
        ...item,
        role_id: item.role.id,
      })),
    });
    setRole(
      administratorSelected.user_roles.map((item) => ({
        value: item.role.id,
        label: item.role.name,
      }))
    );
  }, [history.location.state]);

  const handleSubmitAdministrator = async (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.first_name) {
      errors.first_name = "First Name is required";
    }
    if (!form.last_name) {
      errors.last_name = "Last Name is required";
    }
    if (!form.email || !isEmail(form.email)) {
      errors.email = "Email is required";
    }
    if (!role.length) {
      errors.role = "Role is required";
    }
    setFormErrors(errors);

    // call api
    if (Object.keys(errors).length) return null;

    if (isEditing) {
      try {
        const formData = { ...form };
        const administratorSelected = history.location.state;
        const oldRoleIds = {};
        administratorSelected.user_roles.forEach((item) => {
          oldRoleIds[item.role.id] = item.id;
        });
        formData.user_roles_attributes = formData.user_roles_attributes.map(
          (item) => {
            const roleId = item.role_id;
            const id = oldRoleIds[roleId] ? { id: oldRoleIds[roleId] } : {};
            if (id.id) delete oldRoleIds[roleId];
            return {
              ...item,
              ...id,
            };
          }
        );
        Object.keys(oldRoleIds).forEach((id) => {
          formData.user_roles_attributes.push({
            id: oldRoleIds[id],
            _destroy: "1",
          });
        });

        await dispatch(
          updateAdministrator(formData, history.location.state.id)
        );
        dispatch(
          openMessage({
            title: "Your administrator has been updated",
            type: "success",
          })
        );
        history.push(ROUTES.administrators);
      } catch (err) {
        handleErrorsAfterCallingAPI(
          err,
          DEFAULT_FORM_ADMINISTRAROR,
          setFormErrors,
          dispatch
        );
      }
    } else {
      // create branch
      try {
        await dispatch(createAdministrator(form));
        dispatch(
          openMessage({
            title: "Your administrator has been created",
            type: "success",
          })
        );
        history.push(ROUTES.administrators);
      } catch (err) {
        handleErrorsAfterCallingAPI(
          err,
          DEFAULT_FORM_ADMINISTRAROR,
          setFormErrors,
          dispatch
        );
      }
    }
  };

  const handleChangeAdministrator = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleFocusAdministrator = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  const handleChangeRole = (selectedItem) => () => {
    let newData = [...role];
    if (newData.some((item) => item.value === selectedItem.value)) {
      newData = newData.filter((item) => item.value !== selectedItem.value);
    } else {
      newData = [...newData, selectedItem];
    }
    setRole(newData);
    setForm({
      ...form,
      user_roles_attributes: newData.map((item) => ({
        ...item,
        role_id: item.value,
      })),
    });
  };

  const handleToggle = () => {
    setShow(!show);
  };

  return (
    <AddAdministrator
      form={form}
      formErrors={formErrors}
      onSubmit={handleSubmitAdministrator}
      onChange={handleChangeAdministrator}
      onFocus={handleFocusAdministrator}
      handleChangeRole={handleChangeRole}
      role={role}
      show={show}
      handleToggle={handleToggle}
      storeAllRoles={storeAllRoles}
      isSubmitting={isSubmitting}
    />
  );
}
