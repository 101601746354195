import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { replaceParamStudent } from "utils/helpers";
import { ContactLog } from "../../../../components/dashboard/students/student";
import {
  deleteContactLog,
  getContactLogs,
} from "../../../../redux/actions/student";
import { openMessage } from "../../../../redux/actions/message";
import { Loading } from "../../../../components/common";
import { ROUTES } from "../../../../utils/constants";

export default function () {
  const dispatch = useDispatch();
  const history = useHistory();
  const storeContactLog = useSelector((store) => store.student.contactLog.list);
  const storeDelete = useSelector((store) => store.student.contactLog.delete);
  const loading = storeDelete.loading || storeContactLog.loading;
  const { studentId } = useParams();

  React.useEffect(() => {
    if (studentId) {
      dispatch(getContactLogs({}, studentId));
    }
  }, [studentId]);

  const handleEdit = (rowData) => async () => {
    history.push(replaceParamStudent(ROUTES.addContact, studentId), rowData);
  };

  const handleDelete = (rowData) => async () => {
    try {
      await dispatch(deleteContactLog(studentId, rowData.id));
      dispatch(
        openMessage({
          title: "Contact log has been deleted.",
          type: "success",
        })
      );
      dispatch(getContactLogs({}, studentId));
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <ContactLog
      storeContactLog={storeContactLog}
      onEdit={handleEdit}
      onDelete={handleDelete}
    />
  );
}
