import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { FormGroup, SingleSelectCheckbox } from "../../common";
import {
  LICENSURE_TYPES,
  OPTION_STATE,
  USA_STATES,
} from "../../../utils/constants";

function ModalAddLicensure({
  isOpen,
  onBack,
  onAdd,
  onChange,
  onFocus,
  onChangeSelect,
  data,
  errors,
  isEditing,
}) {
  return (
    <Modal
      show={isOpen}
      onHide={onBack}
      backdropClassName="wrap-modal"
      dialogClassName="Modal Modal__add-licensure"
      backdrop="static"
      keyboard={false}
    >
      <div className="bootstrap">
        <Modal.Header>
          <Modal.Title>Add Licensure Exam</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="select">
            <p>Type</p>
            <SingleSelectCheckbox
              onChange={onChangeSelect}
              name="is_type"
              options={LICENSURE_TYPES}
              value={{ value: data.is_type, label: data.is_type }}
              error={errors.is_type}
            />
          </div>
          {data.is_type === OPTION_STATE && (
            <div className="select">
              <p>State</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="state"
                options={USA_STATES.map((item) => ({
                  value: item,
                  label: item,
                }))}
                value={{ value: data.state, label: data.state }}
                error={errors.state}
              />
            </div>
          )}
          <FormGroup
            propsInput={{
              name: "licensing_entity",
              placeholder: "Name of the licensing entity",
              onChange,
              onFocus,
              value: data.licensing_entity || "",
            }}
            label="Name of the licensing entity"
            error={errors.licensing_entity}
          />
          <FormGroup
            propsInput={{
              name: "name",
              placeholder: "Name of Exam",
              onChange,
              onFocus,
              value: data.name || "",
            }}
            label="Name of Exam"
            error={errors.name}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button button--back"
            type="button"
            onClick={onBack}
          >
            Back
          </button>
          <button
            className="button button--primary"
            type="button"
            onClick={onAdd}
          >
            {isEditing ? "Update" : "Add"}
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

ModalAddLicensure.propTypes = {
  onBack: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
  isOpen: PropTypes.bool,
  isEditing: PropTypes.bool,
};

ModalAddLicensure.defaultProps = {
  isOpen: false,
  data: {},
  errors: {},
  isEditing: false,
};

export default ModalAddLicensure;
