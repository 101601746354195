import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

function Step1({ onSubmit, schoolData }) {
  return (
    <div className=" Step step1">
      <div className="Step__inner ">
        <div className="form_wrap">
          <Form noValidate onSubmit={onSubmit}>
            <div className="form__inner">
              <img src={schoolData.logo} alt="" style={{ display: "inline" }} />
              <h4>{schoolData.name || "XYZ Academy"}</h4>
              <p className="text--large bold">CIRR Student Intent Survey</p>
              <p className="text--small">
                Thank you for taking the time to complete this survey.
              </p>
              <button
                className="button button--primary"
                type="submit"
                onSubmit={onSubmit}
              >
                Start Survey
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

Step1.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schoolData: PropTypes.object.isRequired,
};

export default Step1;
