import React from "react";
import { useHistory } from "react-router-dom";
import { capitalizeFirstLetter, replaceParamStudent } from "utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Edit } from "../../../common/icons";
import { ROUTES } from "../../../../utils/constants";

function General({ selectedStudent = {}, handleSendSurvey }) {
  const history = useHistory();

  const handleClickButton = () => {
    history.push(
      replaceParamStudent(ROUTES.editStudent, selectedStudent.id),
      selectedStudent
    );
  };

  const dataStudent = [
    {
      label: "Student Name",
      value:
        selectedStudent.first_name && selectedStudent.last_name
          ? `${selectedStudent.first_name} ${selectedStudent.last_name}`
          : "-",
    },
    {
      label: "DOB",
      value: selectedStudent.dob
        ? moment(selectedStudent.dob).format("MM/DD/YYYY, hh:mm:ss A")
        : "-",
    },
    {
      label: "Social Security Number",
      value: selectedStudent.social_ecurity_number
        ? selectedStudent.social_ecurity_number
        : "-",
    },
    {
      label: "Gender",
      value: selectedStudent.gender
        ? capitalizeFirstLetter(selectedStudent.gender)
        : "-",
    },
    {
      label: "Ethnicity",
      value: selectedStudent.ethnicity ? selectedStudent.ethnicity : "-",
    },
    {
      label: "Address",
      value:
        selectedStudent.city && selectedStudent.state
          ? `${selectedStudent.city}, ${selectedStudent.state} ${selectedStudent.zip_code}`
          : "-",
    },
    {
      label: "Education Level",
      value: selectedStudent.education_level
        ? selectedStudent.education_level
        : "-",
    },
    {
      label: "Email Address",
      value: selectedStudent.email ? selectedStudent.email : "-",
    },
    {
      label: "Phone",
      value: selectedStudent.phone ? selectedStudent.phone : "-",
    },
  ];

  return (
    <div className="General-student dashboard__item__inner ">
      <div className="General-student__inner">
        <div className="info">
          {dataStudent.length &&
            dataStudent.map((item) => (
              <div key={item.label}>
                <p className="text--small gray03">{item.label}</p>
                <p className="text--small bold">{item.value}</p>
              </div>
            ))}
        </div>
        <div className="General-student__inner__button">
          <button
            className="button--icon"
            type="button"
            onClick={handleSendSurvey}
          >
            <FontAwesomeIcon icon={["fas", "share"]} size="1x" />
            Send Survey
          </button>
          <button
            className="button--icon"
            type="button"
            onClick={handleClickButton}
          >
            <Edit />
            Edit Details
          </button>
        </div>
      </div>
    </div>
  );
}

export default General;
