import * as types from "../constants";
import request from "../../utils/request";

export function getCampaigns(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_CAMPAIGNS });
    return request()
      .get("/campaigns", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_CAMPAIGNS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            meta: {
              total: dataResponse.headers.total,
              perPage: dataResponse.headers["per-page"],
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_CAMPAIGNS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getCampaignDetail(id) {
  return (dispatch) => {
    dispatch({ type: types.GET_CAMPAIGN_DETAIL });
    return request()
      .get(`/campaigns/${id}`)
      .then((dataResponse) => {
        dispatch({
          type: types.GET_CAMPAIGN_DETAIL_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_CAMPAIGN_DETAIL_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createCampaign(data) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_CAMPAIGN });
    return request()
      .post("/campaigns", { campaign: data })
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_CAMPAIGN_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_CAMPAIGN_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function updateCampaign(data, Id) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_CAMPAIGN });
    return request()
      .put(`/campaigns/${Id}`, { campaign: data })
      .then((dataResponse) => {
        dispatch({
          type: types.UPDATE_CAMPAIGN_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_CAMPAIGN_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function deleteCampaign(id) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_CAMPAIGN });
    return request()
      .delete(`/campaigns/${id}`)
      .then((dataResponse) => {
        dispatch({
          type: types.DELETE_CAMPAIGN_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_CAMPAIGN_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
