import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "../utils/constants";
import { getUserData, isAuthenticated } from "../utils/helpers";

const withAuth = (Component) =>
  function (props) {
    const history = useHistory();
    const location = useLocation();
    const { isBadToken } = useSelector((store) => store.global);
    const isAuth = isAuthenticated();
    const userData = getUserData();
    const isHasSchool = userData.school_id;
    const isVerified = userData.is_verified;
    const isStudent = userData.isStudent;

    if (!isAuth) {
      history.replace({
        pathname: ROUTES.login,
        state: { nextPathname: `${location.pathname}${location.search}` },
      });
      return null;
    }

    if (isBadToken) {
      history.push(ROUTES.logout);
      return null;
    }

    if (
      (!isHasSchool || !isVerified) &&
      location.pathname !== ROUTES.onBoarding &&
      !isStudent
    ) {
      history.push(ROUTES.onBoarding);
      return null;
    }

    return <Component {...props} />;
  };

export default withAuth;
