import React from "react";

export default function () {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99999 5.40039L7.59999 0.600391H0.399994L3.99999 5.40039Z"
        fill="#0199F2"
      />
    </svg>
  );
}
