/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/default-param-last */
import * as types from "../constants";

const initialState = {
  branches: {
    data: [],
    loading: false,
    error: null,
  },
  programs: {
    data: [],
    loading: false,
    error: null,
  },
  cohorts: {
    data: [],
    loading: false,
    error: null,
  },
  selected: {
    branches: [],
    programs: [],
    cohorts: [],
  },
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    // branches
    case types.GET_FILTER_BRANCHES:
      return {
        ...state,
        branches: {
          ...initialState.branches,
          loading: true,
        },
      };
    case types.GET_FILTER_BRANCHES_SUCCEED:
      return {
        ...state,
        branches: {
          ...state.branches,
          loading: false,
          data: payload,
        },
      };
    case types.GET_FILTER_BRANCHES_FAIL:
      return {
        ...state,
        branches: {
          ...state.branches,
          loading: false,
          error: payload,
        },
      };
    // programs
    case types.GET_FILTER_PROGRAMS:
      return {
        ...state,
        programs: {
          ...initialState.programs,
          loading: true,
        },
      };
    case types.GET_FILTER_PROGRAMS_SUCCEED:
      return {
        ...state,
        programs: {
          ...state.programs,
          loading: false,
          data: payload,
        },
      };
    case types.GET_FILTER_PROGRAMS_FAIL:
      return {
        ...state,
        programs: {
          ...state.programs,
          loading: false,
          error: payload,
        },
      };
    // cohorts
    case types.GET_FILTER_COHORTS:
      return {
        ...state,
        cohorts: {
          ...initialState.cohorts,
          loading: true,
        },
      };
    case types.GET_FILTER_COHORTS_SUCCEED:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          loading: false,
          data: payload,
        },
        // reset filter cohorts
        selected: {
          ...state.selected,
          cohorts: [],
        },
      };
    case types.GET_FILTER_COHORTS_FAIL:
      return {
        ...state,
        cohorts: {
          ...state.cohorts,
          loading: false,
          error: payload,
        },
      };
    // selected
    case types.SET_SELECTED_FILTER:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...payload,
        },
      };
    default:
      return state;
  }
}
