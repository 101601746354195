import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import { ChangeLog } from "../../../../components/dashboard/reporting";
import {
  getStudentChangelog,
  setDataChangelog,
} from "../../../../redux/actions/student";
import { changeURLWithoutReload } from "../../../../utils/helpers";
import { getUsers } from "../../../../redux/actions/users";

export default function () {
  const dispatch = useDispatch();
  const { studentId } = useParams();
  const location = useLocation();
  const [params, setParams] = React.useState({ page: 1 });
  const storeStudentChangelog = useSelector(
    (store) => store.student.changeLog.list
  );
  const dataChangelog = useSelector(
    (store) => store.student.changeLog.listToRender
  );
  const isOutOfData = useSelector(
    (store) => store.student.changeLog.isOutOfData
  );
  const storeUsers = useSelector((store) => store.users);
  const loading = storeStudentChangelog.loading;

  React.useEffect(() => {
    if (!studentId) return;
    dispatch(getStudentChangelog(params, studentId));
  }, [params, studentId]);

  React.useEffect(() => {
    dispatch(getUsers());
  }, []);

  React.useEffect(
    () => () => {
      setDataChangelog();
    },
    []
  );

  const handleChangeURL = (newParams) => {
    changeURLWithoutReload(
      location.pathname,
      `${location.pathname}?${queryString.stringify(newParams)}`
    );
  };

  const updateParamsAndURL = (newParams) => {
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleChangeSelect = (selectedItem, name) => {
    const newParams = {
      ...params,
      page: 1,
      [name]: selectedItem.value,
    };
    setParams(newParams);
    updateParamsAndURL(newParams);
  };

  const handleCallbackDateRange = (start, end) => {
    const newParams = {
      ...params,
      "q[created_at_gteq]": moment(start).format("YYYY-MM-DD"),
      "q[created_at_lteq]": moment(end).format("YYYY-MM-DD"),
    };
    setParams(newParams);
    updateParamsAndURL(newParams);
  };

  const handleSeeMore = () => {
    if (!isOutOfData) {
      const newParams = {
        ...params,
        page: params.page + 1,
      };
      setParams(newParams);
      updateParamsAndURL(newParams);
    }
  };

  return (
    <ChangeLog
      params={params}
      onChangeSelect={handleChangeSelect}
      data={dataChangelog}
      dataUsers={storeUsers.data}
      onCallbackDateRange={handleCallbackDateRange}
      loading={loading}
      handleSeeMore={handleSeeMore}
      isOutOfData={isOutOfData}
    />
  );
}
