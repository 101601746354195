import { requestClient } from "lib/api";
import qs from "query-string";
import { useQuery } from "react-query";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { getUserData } from "utils/helpers";
import { z } from "zod";

type QueryParams = {
  token: string;
  type?: string;
};

const SurveyInfoSchema = z.object({
  id: z.number(),
  token: z.string(),
});

export type SurveyInfo = z.infer<typeof SurveyInfoSchema>;

export function useCommonSurveyInfo() {
  const location = useLocation();
  const history = useHistory();
  const { token, type } = qs.parse(location.search) as QueryParams;

  const query = useQuery(["commonSurveyInfo", token], async () => {
    if (type !== "common") return undefined;

    await requestClient().get(`/surveys/info?token=${token}`);
    const userData = getUserData();
    const responseAddStudent = await requestClient().post(
      `/surveys/public_surveys/add_student?token=${token}`,
      {
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        phone: userData.phone,
      }
    );

    const data = SurveyInfoSchema.parse(responseAddStudent.data.data);

    if (data.token) {
      history.push(`${ROUTES.surveySteps}?token=${data.token}`);
    }

    return data;
  });

  const isLoading = query.isLoading;
  const isError =
    !query.isLoading && (query.isError || query.data === undefined);
  const data = query.data;

  return { isLoading, isError, data };
}
