import React from "react";
import { useHistory } from "react-router-dom";
import { TableActions } from "components";
import {
  ROUTES,
  SEARCH_NAME,
  SEARCH_STATE_NAME,
  SORT_NAME,
  USA_STATES,
} from "../../../utils/constants";
import { FormGroup, SingleSelectCheckbox } from "../../common";
import { Plus } from "../../common/icons";
import Table from "../../common/Table";

function BranchesTable({
  storeBranches = { data: [], meta: {}, loading: false, error: null },
  onPageChange = () => null,
  params = {},
  onSort = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onEdit = () => {},
  state = {},
  onChangeState = () => {},
}) {
  const history = useHistory();

  const handleClick = () => {
    history.push(ROUTES.addBranch);
  };

  const columns = [
    {
      title: "Branch Name",
      dataIndex: "name",
      sortAble: true,
    },
    {
      title: "City",
      dataIndex: "city",
      sortAble: true,
    },
    {
      title: "State",
      dataIndex: "state",
      sortAble: true,
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (row) => (
        <TableActions
          actions={[
            {
              label: "Edit",
              icon: ["fas", "pen-to-square"],
              onSelect: onEdit(row),
            },
            {
              label: "Delete",
              icon: ["fas", "trash"],
              onSelect: onDelete(row),
            },
          ]}
        />
      ),
      align: "right",
    },
  ];

  return (
    <div className="Branches__inner dashboard__item__inner">
      <div className="filter">
        <div className="filter__left">
          <FormGroup
            propsInput={{
              name: "name",
              className: "inputSearch",
              placeholder: "Search by Branch Name",
              onChange: onSearch,
              value: params[SEARCH_NAME] || "",
            }}
          />
          <SingleSelectCheckbox
            onChange={onChangeState}
            name={SEARCH_STATE_NAME}
            options={[
              { value: undefined, label: "All" },
              ...USA_STATES.map((item) => ({ value: item, label: item })),
            ]}
            value={state}
            size="small"
            placeholder="State"
          />
        </div>

        <button type="submit" className="button--icon" onClick={handleClick}>
          <Plus />
          Branch
        </button>
      </div>
      <Table
        loading={storeBranches.loading}
        columns={columns}
        data={storeBranches.data}
        tableProps={{ striped: true }}
        sort={params[SORT_NAME] || ""}
        onSort={onSort}
        paginationProps={{
          itemsLength: storeBranches.meta.total,
          perPage: storeBranches.meta.perPage,
          onPageChange,
          activePage: params.page || 1,
        }}
      />
    </div>
  );
}

export default BranchesTable;
