import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import {
  Dropzone,
  FormGroup,
  SingleDatePicker,
  SingleSelectCheckbox,
} from "../../../common";
import { Delete } from "../../../common/icons";

function AddFinancial({
  form,
  formErrors,
  onChange,
  onFocus,
  onSubmit,
  onChangeSelect,
  handleChangeDate,
  handleBack,
  // eslint-disable-next-line react/prop-types
  dataPrograms,
  // eslint-disable-next-line react/prop-types
  dataFinancialAidPrograms,
  handleDrop,
  onDeleteDocument,
  isSubmitting,
}) {
  // eslint-disable-next-line react/prop-types
  const OPTIONS_PROGRAM = dataPrograms.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  // eslint-disable-next-line react/prop-types
  const OPTIONS_FINANCIAL_AID_PROGRAMS = dataFinancialAidPrograms.map(
    (item) => ({
      value: item.id,
      label: item.name,
    })
  );

  return (
    <div className="AddFinancial  form-student dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="form-student__text">
          <p className="text--xlarge bold">New Exam Result</p>
        </div>
        <div className="AddFinancial__inner form-student__inner">
          <div className="input__group">
            <div className="select">
              <p>Financial Aid Program</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="financial_aid_program_id"
                options={OPTIONS_FINANCIAL_AID_PROGRAMS}
                value={{ value: form.financial_aid_program_id }}
                error={formErrors.financial_aid_program_id}
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div className="input__group">
            <div className="select">
              <p>Program</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="program_id"
                options={OPTIONS_PROGRAM}
                value={{ value: form.program_id }}
                error={formErrors.program_id}
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div className="input__group">
            <div className="Date-picker">
              <p className="Date-picker__label">Reimbursement Date</p>
              <SingleDatePicker
                placeholder="Select Date"
                value={form.disbursement_date}
                onChange={handleChangeDate}
                disabled={isSubmitting}
                name="disbursement_date"
              />
            </div>
          </div>
          <div className="input__group">
            <FormGroup
              propsInput={{
                name: "amount",
                placeholder: "Aid Amount",
                type: "number",
                onChange,
                onFocus,
                value: form.amount,
                disabled: isSubmitting,
              }}
              error={formErrors.amount}
              label="Aid Amount"
            />
          </div>
          <div className="Documents">
            <p>Documentation</p>
            {form.files && !!form.files.length && (
              <div className="Documents__inner">
                {form.files.map((item, index) => (
                  <div className="Document--item" key={item.name || item.id}>
                    <p>{item.name || item.filename}</p>
                    <button
                      type="button"
                      className="Documents__delete"
                      onClick={onDeleteDocument(item, index)}
                    >
                      <Delete />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="input__group">
            <div className="add-document">
              <Dropzone handleDrop={handleDrop} />
            </div>
          </div>
        </div>
        <div className="button-group">
          <button
            className="button button--back"
            type="submit"
            onClick={handleBack}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
}

AddFinancial.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    program_id: PropTypes.number,
    financial_aid_program_id: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    disbursement_date: PropTypes.any,
    amount: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    files: PropTypes.array,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  onDeleteDocument: PropTypes.func.isRequired,
};

AddFinancial.defaultProps = {
  form: {
    program_id: undefined,
    financial_aid_program_id: undefined,
    disbursement_date: "",
    amount: "",
    files: [],
  },
  formErrors: {},
  isSubmitting: false,
};

export default AddFinancial;
