/* eslint-disable react/forbid-prop-types */
import React from "react";
import { Dropdown, ButtonGroup, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import classNames from "classnames";

function MultiSelectCheckbox({
  value,
  label,
  name,
  options,
  onChange,
  show,
  onToggle,
  placeholder,
  disabled,
  loading,
  theme,
  size,
  error = "",
}) {
  const isValueOfObject = value?.some((item) => typeof item === "object");
  const arrValue = isValueOfObject ? value?.map((item) => item.value) : value;

  return (
    <div className="select">
      {label && <p>{label}</p>}
      <Dropdown
        show={show}
        onToggle={onToggle}
        className={classNames("SelectCheckbox", {
          "SelectCheckbox--show": show,
          "SelectCheckbox--disabled": disabled,
          "SelectCheckbox--loading": loading,
          "SelectCheckbox--dark": theme === "dark",
          "SelectCheckbox--small": size === "small",
          "Select--error": error && !value.length,
        })}
      >
        <Dropdown.Toggle as={ButtonGroup}>
          {arrValue.length ? (
            options
              .filter((item) => arrValue.includes(item.value))
              .map((item, index) => (
                <span
                  className="SelectCheckbox__value-selected"
                  key={item.value}
                >
                  {item.label}
                  {index !== arrValue.length - 1 ? "," : ""}
                </span>
              ))
          ) : (
            <span>{placeholder}</span>
          )}
          {loading && (
            <Spinner
              className="SelectCheckbox__loading"
              animation="border"
              variant="primary"
            />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {options.map((item) => (
            <div key={item.value} className="SelectCheckbox__item">
              <label
                className="SelectCheckbox__item__label"
                htmlFor={`${name}-${item.value}`}
              >
                {item.label}
                <input
                  id={`${name}-${item.value}`}
                  value={item.value}
                  name={name}
                  data-label={item.label}
                  type="checkbox"
                  checked={arrValue.includes(item.value)}
                  onChange={onChange(item)}
                />
                <span className="SelectCheckbox__item__checkmark" />
              </label>
            </div>
          ))}
        </Dropdown.Menu>
        {!value.length && <div className="error-message">{error}</div>}
      </Dropdown>
    </div>
  );
}

MultiSelectCheckbox.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.number])
  ).isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  onToggle: PropTypes.func,
  show: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  theme: PropTypes.string,
  size: PropTypes.string,
  error: PropTypes.string,
};

MultiSelectCheckbox.defaultProps = {
  onToggle: () => {},
  show: false,
  placeholder: "Select",
  disabled: false,
  loading: false,
  theme: "",
  size: "",
  error: "",
};

export default MultiSelectCheckbox;
