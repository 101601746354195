import React from "react";

function Results() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="#fff"
      height="24px"
      width="24px"
      version="1.1"
      id="Capa_1"
      viewBox="0 0 463 463"
      xmlSpace="preserve"
    >
      <g>
        <path d="M191.5,47c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3c0-1.97-0.8-3.91-2.2-5.3c-1.39-1.4-3.33-2.2-5.3-2.2   c-1.97,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.32-2.2,5.3c0,1.97,0.8,3.91,2.2,5.3C187.59,46.2,189.53,47,191.5,47z" />
        <path d="M159.5,143h104c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-104c-4.142,0-7.5,3.358-7.5,7.5S155.358,143,159.5,143z" />
        <path d="M263.5,208h-16c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h16c4.142,0,7.5-3.358,7.5-7.5S267.642,208,263.5,208z" />
        <path d="M159.5,223h56c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-56c-4.142,0-7.5,3.358-7.5,7.5S155.358,223,159.5,223z" />
        <path d="M175.5,288h-16c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h16c4.142,0,7.5-3.358,7.5-7.5S179.642,288,175.5,288z" />
        <path d="M159.5,183h104c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-104c-4.142,0-7.5,3.358-7.5,7.5S155.358,183,159.5,183z" />
        <path d="M159.5,263h32c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-32c-4.142,0-7.5,3.358-7.5,7.5S155.358,263,159.5,263z" />
        <path d="M263.5,248h-40c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h40c4.142,0,7.5-3.358,7.5-7.5S267.642,248,263.5,248z" />
        <path d="M127.5,128h-8c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h8c4.142,0,7.5-3.358,7.5-7.5S131.642,128,127.5,128z" />
        <path d="M127.5,208h-8c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h8c4.142,0,7.5-3.358,7.5-7.5S131.642,208,127.5,208z" />
        <path d="M127.5,288h-8c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h8c4.142,0,7.5-3.358,7.5-7.5S131.642,288,127.5,288z" />
        <path d="M127.5,168h-8c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h8c4.142,0,7.5-3.358,7.5-7.5S131.642,168,127.5,168z" />
        <path d="M127.5,248h-8c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h8c4.142,0,7.5-3.358,7.5-7.5S131.642,248,127.5,248z" />
        <path d="M127.5,328h-8c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h8c4.142,0,7.5-3.358,7.5-7.5S131.642,328,127.5,328z" />
        <path d="M415.5,288H343V63.5c0-17.369-14.131-31.5-31.5-31.5h-41.734c-3.138-9.29-11.93-16-22.266-16h-24.416   C215.674,6.035,203.935,0,191.5,0s-24.174,6.035-31.584,16H135.5c-10.336,0-19.128,6.71-22.266,16H71.5   C54.131,32,40,46.131,40,63.5v336c0,17.369,14.131,31.5,31.5,31.5h108.404c16.061,19.526,40.398,32,67.596,32   c48.248,0,87.5-39.252,87.5-87.5v-25.032l81.903-15.6c3.538-0.674,6.097-3.767,6.097-7.368v-32C423,291.358,419.642,288,415.5,288z    M328,63.5V288h-17V71.5c0-4.142-3.358-7.5-7.5-7.5h-32.513c0.004-0.167,0.013-0.332,0.013-0.5V47h40.5   C320.598,47,328,54.402,328,63.5z M174.05,328H159.5c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h6.762   c-4.036,10.053-6.262,21.021-6.262,32.5c0,2.868,0.143,5.702,0.414,8.5H87V79h30.859c4.31,4.899,10.619,8,17.641,8h112   c7.023,0,13.332-3.101,17.641-8H296v209h-48.5C216.751,288,189.659,303.946,174.05,328z M135.5,31h28.438   c2.67,0,5.139-1.419,6.482-3.728C174.893,19.588,182.773,15,191.5,15s16.607,4.588,21.08,12.272   c1.343,2.308,3.812,3.728,6.482,3.728H247.5c4.687,0,8.5,3.813,8.5,8.5v24c0,4.687-3.813,8.5-8.5,8.5h-112   c-4.687,0-8.5-3.813-8.5-8.5v-24C127,34.813,130.813,31,135.5,31z M71.5,416c-9.098,0-16.5-7.402-16.5-16.5v-336   C55,54.402,62.402,47,71.5,47H112v16.5c0,0.168,0.009,0.333,0.013,0.5H79.5c-4.142,0-7.5,3.358-7.5,7.5v320   c0,4.142,3.358,7.5,7.5,7.5h83.709c1.659,5.945,3.934,11.635,6.747,17H71.5z M408,321.294l-81.903,15.6   c-3.538,0.674-6.097,3.767-6.097,7.368V375.5c0,39.977-32.523,72.5-72.5,72.5S175,415.477,175,375.5s32.523-72.5,72.5-72.5H408   V321.294z" />
        <path d="M192,375.5c0,30.603,24.897,55.5,55.5,55.5s55.5-24.897,55.5-55.5c0-6.342-1.063-12.566-3.16-18.499   c-1.38-3.906-5.667-5.955-9.57-4.572c-3.905,1.38-5.953,5.665-4.572,9.57c1.528,4.323,2.302,8.865,2.302,13.5   c0,22.332-18.168,40.5-40.5,40.5S207,397.832,207,375.5s18.168-40.5,40.5-40.5c7.106,0,14.094,1.864,20.207,5.392   c3.586,2.068,8.174,0.84,10.245-2.748c2.07-3.588,0.84-8.174-2.748-10.245C266.814,322.559,257.234,320,247.5,320   C216.897,320,192,344.897,192,375.5z" />
      </g>
    </svg>
  );
}

export default Results;
