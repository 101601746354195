import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { replaceParamStudent } from "utils/helpers";
import moment from "moment";
import { TableActions } from "components";
import { ROUTES } from "../../../../utils/constants";
import { Plus, Folder } from "../../../common/icons";
import Card from "./Card";

function Enrollment({
  storeEnrollment = { data: [], loading: false, error: null },
  onEdit = () => {},
  onDelete = () => {},
}) {
  const history = useHistory();
  const { studentId } = useParams();
  const handleClick = () => {
    history.push(replaceParamStudent(ROUTES.addEnrollment, studentId));
  };

  const columns1 = [
    {
      title: "Cohort",
      dataIndex: "cohort",
      render: (row) => (row.cohort ? row.cohort.name : "-"),
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      render: (row) => moment(row.start_date).format("MM/DD/YYYY"),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      render: (row) => moment(row.end_date).format("MM/DD/YYYY"),
    },
    {
      title: "Start Status",
      dataIndex: "start_status",
      render: (row) => (row.start_status ? row.start_status : "-"),
    },
    {
      title: "End Status",
      dataIndex: "end_status",
      render: (row) => (row.end_status ? row.end_status : "-"),
    },
    {
      title: "Documentation",
      dataIndex: "files",
      render: (row) => {
        if (!row.files.length) return "-";

        return row.files.map((item) => (
          <a
            className="document"
            key={item.id || item.name}
            href={item.url}
            target="_blank"
            rel="noreferrer"
          >
            <Folder />
            {item.filename}
          </a>
        ));
      },
    },
    {
      title: "",
      dataIndex: "id",
      align: "right",
      render: (row) => (
        <TableActions
          actions={[
            {
              label: "Edit",
              icon: ["fas", "pen-to-square"],
              onSelect: onEdit(row),
            },
            {
              label: "Delete",
              icon: ["fas", "trash"],
              onSelect: onDelete(row),
            },
          ]}
        />
      ),
    },
  ];

  const columns2 = [
    {
      title: "Start Comments",
      dataIndex: "start_comments",
      render: (row) => (row.start_comments ? row.start_comments : "-"),
    },
    {
      title: "End Comments",
      dataIndex: "end_comments",
      render: (row) => (row.end_comments ? row.end_comments : "-"),
    },
    {
      title: "",
      dataIndex: "id",
      align: "right",
      render: () => "",
    },
  ];

  return (
    <div className="Enrollment Student-tap dashboard__item__inner">
      <div className="button__add">
        <button type="submit" className="button--icon" onClick={handleClick}>
          <Plus />
          Enrollment
        </button>
      </div>
      <div>
        {storeEnrollment.data.map((item) => (
          <Card
            title={item.program ? item.program.name : ""}
            data={[item]}
            columns1={columns1}
            columns2={columns2}
            key={item.id}
          />
        ))}
      </div>
    </div>
  );
}

export default Enrollment;
