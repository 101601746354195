/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { TableActions } from "components";
import salesForce from "../../../assets/images/salesForce.svg";
import hubspot from "../../../assets/images/hubspot.svg";
// import slack from '../../../assets/images/slack.svg';
import { Plus, Tick } from "../../common/icons";

function Integrations({
  dataIntegrationSalesforce,
  dataIntegrationHubSpot,
  handleClickInstall,
  onClickEditMapping,
  onClickSync,
  onUninstall,
}) {
  // const history = useHistory ();

  return (
    <div className="Integrations dashboard__item__inner ">
      <div className="Integrations__inner">
        <div className="Integrations__inner__item">
          <div className="action">
            <TableActions
              actions={
                dataIntegrationSalesforce.length
                  ? [
                      {
                        label: "Edit Mapping",
                        onSelect: onClickEditMapping("Salesforce"),
                      },
                      {
                        label: "Sync Data",
                        onSelect: onClickSync("Salesforce"),
                      },
                      {
                        label: "Uninstall",
                        onSelect: onUninstall("Salesforce"),
                      },
                    ]
                  : [
                      {
                        label: "Install",
                        onSelect: handleClickInstall("Salesforce"),
                      },
                    ]
              }
            />
          </div>
          <div className="Integrations__inner__item__info">
            <div className="logo">
              <img src={salesForce} alt="" />
              {dataIntegrationSalesforce.length ? (
                <div className="installed">
                  <Tick />
                  <p className="text--small bold primary">Installed</p>
                </div>
              ) : (
                <button
                  className="button--icon"
                  type="button"
                  onClick={handleClickInstall("Salesforce")}
                >
                  <Plus />
                  Install
                </button>
              )}
            </div>
            <p className="text bold title">Salesforce</p>
            <p className="text--small">
              Sync student data between Salesforce and AcademySync.
            </p>
            {dataIntegrationSalesforce.length &&
            dataIntegrationSalesforce[0].synced_at ? (
              <p className="text--xsmall time">
                {`Last synced ${moment(
                  dataIntegrationSalesforce[0].synced_at
                ).format("MM/DD/YYYY [at] h:mm a")}`}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="Integrations__inner__item">
          <div className="action">
            <TableActions
              actions={
                dataIntegrationHubSpot.length
                  ? [
                      {
                        label: "Edit Mapping",
                        onSelect: onClickEditMapping("HubSpot"),
                      },
                      {
                        label: "Sync Data",
                        onSelect: onClickSync("HubSpot"),
                      },
                      {
                        label: "Uninstall",
                        onSelect: onUninstall("HubSpot"),
                      },
                    ]
                  : [
                      {
                        label: "Install",
                        onSelect: handleClickInstall("HubSpot"),
                      },
                    ]
              }
            />
          </div>
          <div className="Integrations__inner__item__info">
            <div className="logo">
              <img src={hubspot} alt="logo HubSpot" />
              {dataIntegrationHubSpot.length ? (
                <div className="installed">
                  <Tick />
                  <p className="text--small bold primary">Installed</p>
                </div>
              ) : (
                <button
                  className="button--icon"
                  type="button"
                  onClick={handleClickInstall("HubSpot")}
                >
                  <Plus />
                  Install
                </button>
              )}
            </div>
            <p className="text bold title">HubSpot</p>
            <p className="text--small">
              Sync student data between HubSpot and AcademySync.
            </p>
            {dataIntegrationHubSpot.length &&
            dataIntegrationHubSpot[0].synced_at ? (
              <p className="text--xsmall time">
                {`Last synced ${moment(
                  dataIntegrationHubSpot[0].synced_at
                ).format("MM/DD/YYYY [at] h:mm a")}`}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* <div className="Integrations__inner__item">
          <div className="action">
            <TableActions />
          </div>
          <div className="Integrations__inner__item__info">
            <div className="logo">
              <img src={slack} alt="" />
              <button className="button--icon" type="button">
                <Plus />
                Install
              </button>
            </div>
            <p className="text bold title">Slack</p>
            <p className="text--small">
              Bring team communication and collaboration into one place.
            </p>
          </div>
        </div> */}
      </div>
    </div>
  );
}

Integrations.propTypes = {
  handleClickInstall: PropTypes.func.isRequired,
};

export default Integrations;
