import { setUserData } from "utils/helpers";
import * as types from "../constants";
import request from "../../utils/request";

export function getProfile(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_PROFILE });
    return request()
      .get("/me", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_PROFILE_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
        setUserData(dataResponse.data.data);
      })
      .catch((error) => {
        dispatch({
          type: types.GET_PROFILE_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function updateProfile(data) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_PROFILE });
    return request()
      .put("/auth", data)
      .then((dataResponse) => {
        dispatch({
          type: types.UPDATE_PROFILE_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_PROFILE_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
