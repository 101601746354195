import React from "react";

function ReportingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
    >
      <mask id="path-1-inside-1" fill="white">
        <rect y="4" width="6" height="12" rx="1" />
      </mask>
      <rect
        y="4"
        width="6"
        height="12"
        rx="1"
        stroke="white"
        strokeWidth="4"
        mask="url(#path-1-inside-1)"
      />
      <mask id="path-2-inside-2" fill="white">
        <rect x="8" y="8" width="6" height="8" rx="1" />
      </mask>
      <rect
        x="8"
        y="8"
        width="6"
        height="8"
        rx="1"
        stroke="white"
        strokeWidth="4"
        mask="url(#path-2-inside-2)"
      />
      <mask id="path-3-inside-3" fill="white">
        <rect x="16" width="6" height="16" rx="1" />
      </mask>
      <rect
        x="16"
        width="6"
        height="16"
        rx="1"
        stroke="white"
        strokeWidth="4"
        mask="url(#path-3-inside-3)"
      />
      <rect y="18" width="22" height="2" rx="1" fill="white" />
    </svg>
  );
}

export default ReportingIcon;
