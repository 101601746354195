import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import type { SurveyInfo } from "../api";
import {
  Card,
  Button,
  ButtonArea,
  StepHeader,
  Form,
  RadioGroup,
} from "../components";
import { useSurveyStore } from "../store";

type Props = {
  surveyInfo: SurveyInfo;
  progress?: string;
  onNextClick: () => void;
  onBackClick: () => void;
};

const schema = z.object({
  jobSearchRating: z.enum(
    [
      "Very Satisfied",
      "Somewhat Satisfied",
      "Neither Satisfied nor Dissatisfied",
      "Somewhat Dissatisfied",
      "Very Dissatisfied",
    ],
    {
      invalid_type_error: "Required",
    }
  ),
});

type Inputs = z.infer<typeof schema>;

export function JobSearchRatingStep(props: Props) {
  const { surveyInfo, progress, onNextClick, onBackClick } = props;
  const [state, setState] = useSurveyStore((store) => [
    store.jobSearchRating,
    store.setJobSearchRating,
  ]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: { jobSearchRating: state },
    resolver: zodResolver(schema),
  });

  const handleBackClick = () => {
    setState(getValues());
    onBackClick();
  };

  const handleNextClick = async () => {
    await handleSubmit((data) => {
      setState(data);
      onNextClick();
    })();
  };

  return (
    <Card progress={progress}>
      <StepHeader
        surveyInfo={surveyInfo}
        title="How would you rate your experience with your job search so far?"
      />

      <Form>
        <RadioGroup<Inputs["jobSearchRating"]>
          vertical
          options={[
            { label: "Very satisfied", value: "Very Satisfied" },
            { label: "Somewhat satisfied", value: "Somewhat Satisfied" },
            {
              label: "Neither satisfied nor dissatisfied",
              value: "Neither Satisfied nor Dissatisfied",
            },
            { label: "Somewhat dissatisfied", value: "Somewhat Dissatisfied" },
            { label: "Very dissatisfied", value: "Very Dissatisfied" },
          ]}
          error={errors?.jobSearchRating?.message}
          {...register("jobSearchRating")}
        />
      </Form>

      <ButtonArea>
        <Button label="Back" onClick={handleBackClick} />
        <Button primary label="Continue" onClick={handleNextClick} />
      </ButtonArea>
    </Card>
  );
}
