import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import FormGroup from "../common/FormGroup";
import phone from "../../assets/icons/phone.svg";
import email from "../../assets/icons/email.svg";
import facebook from "../../assets/icons/facebook.svg";
import twitter from "../../assets/icons/twitter.svg";
import linkedin from "../../assets/icons/in.svg";
import sent from "../../assets/icons/sent.svg";

function ContactForm({
  form,
  formErrors,
  onSubmit,
  onChange,
  onChangeNumber,
  onFocus,
  isSubmitting,
}) {
  return (
    <section className="Form">
      <div className="container">
        <div className="Form__inner ">
          <div className="Form__inner__left ">
            <p className="text--xxlarge title bold">Send us a message</p>
            <Form onSubmit={onSubmit} noValidate>
              <div className="input-group">
                <FormGroup
                  propsInput={{
                    name: "name",
                    placeholder: "Name",
                    onChange,
                    onFocus,
                    value: form.name,
                    disabled: isSubmitting,
                  }}
                  error={formErrors.name}
                  label="Name"
                />
                <FormGroup
                  propsInput={{
                    name: "email",
                    placeholder: "Email",
                    type: "email",
                    onChange,
                    onFocus,
                    value: form.email,
                    disabled: isSubmitting,
                  }}
                  error={formErrors.email}
                  label="Email"
                />
              </div>
              <div className="input-group">
                <FormGroup
                  propsInput={{
                    name: "phone",
                    placeholder: "Phone",
                    onChange: onChangeNumber,
                    onFocus,
                    value: form.phone,
                    disabled: isSubmitting,
                  }}
                  label="Phone"
                />
                <FormGroup
                  propsInput={{
                    name: "school",
                    placeholder: "School",
                    onChange,
                    onFocus,
                    value: form.school,
                    disabled: isSubmitting,
                  }}
                  label="School"
                />
              </div>
              <FormGroup
                propsInput={{
                  name: "message",
                  placeholder: "Description",
                  as: "textarea",
                  onChange,
                  onFocus,
                  value: form.message,
                  disabled: isSubmitting,
                }}
                error={formErrors.message}
                label="Description"
              />
              <button
                className="button--primary"
                type="submit"
                disabled={isSubmitting}
              >
                <img src={sent} alt="" />
              </button>
            </Form>
          </div>
          <div className="Form__inner__right">
            <div>
              <div className="infomation__item">
                <img src={phone} alt="" />
                <p className="text">888-701-6811</p>
              </div>
              <div className="infomation__item">
                <img src={email} alt="" />
                <p className="text">info@academysync.com</p>
              </div>
            </div>
            <div className="social">
              <a
                href="https://www.facebook.com/academysync"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="" />
              </a>
              <a
                href="https://twitter.com/academysync"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} alt="" />
              </a>
              <a
                href="https://www.linkedin.com/company/academysync"
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedin} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

ContactForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    school: PropTypes.string,
    message: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onChangeNumber: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

ContactForm.defaultProps = {
  form: {
    name: "",
    email: "",
    phone: "",
    school: "",
    message: "",
  },
  formErrors: {},
};

export default ContactForm;
