import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logoAcademy.svg";
import border from "../../assets/images/border-arrow.svg";
import { closeModalMessageIntegration } from "../../redux/actions/global";

function ModalMessageIntegrate() {
  const dispatch = useDispatch();
  const storeModalMessage = useSelector(
    (store) => store.global.modalMessageIntegration
  );

  const onCancel = () => {
    dispatch(closeModalMessageIntegration());
  };
  return (
    <Modal
      show={storeModalMessage.open}
      onHide={onCancel}
      backdropClassName="wrap-modal"
      dialogClassName="Modal Modal__message__integrate"
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="bootstrap">
        {storeModalMessage.data.logoIntegration && (
          <Modal.Header>
            <img src={logo} alt="" />
            <img src={border} alt="" />
            <img src={storeModalMessage.data.logoIntegration} alt="" />
          </Modal.Header>
        )}
        <Modal.Body>
          {storeModalMessage.data.logoIntegration ? (
            <p className="text--xlarge bold">{storeModalMessage.data.text}</p>
          ) : (
            <Modal.Title className="bold">
              {storeModalMessage.data.text}
            </Modal.Title>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button button--back"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="button"
            onClick={storeModalMessage.data.onContinue}
          >
            {storeModalMessage.data.textButton}
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default ModalMessageIntegrate;
