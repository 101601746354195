import { openMessage } from "../redux/actions/message";

export const getAuth = () => {
  const auth = window.localStorage.getItem("auth");
  return typeof auth === "string" ? JSON.parse(auth) : {};
};

export const setAuth = (dataAuth, isValidAuth = false, isStudent = false) => {
  if (isValidAuth) {
    return window.localStorage.setItem("auth", JSON.stringify(dataAuth));
  }
  const newDataAuth = {
    headers: dataAuth.headers,
    userData: {
      ...dataAuth.data.data,
      isStudent,
    },
  };
  window.localStorage.setItem("auth", JSON.stringify(newDataAuth));
};

export const removeAuth = () => {
  window.localStorage.removeItem("auth");
};

export const setUserData = (data) => {
  const auth = getAuth();
  const authData = {
    ...auth,
    userData: data,
  };
  window.localStorage.setItem("auth", JSON.stringify(authData));
};

export const getUserData = () => {
  const auth = getAuth();
  return auth.userData ? auth.userData : {};
};

export const getHeaders = () => {
  const auth = getAuth();
  return auth.headers
    ? {
        uid: auth.headers.uid,
        client: auth.headers.client,
        "access-token": auth.headers["access-token"],
      }
    : {
        uid: null,
        client: null,
        "access-token": null,
      };
};

export const isAuthenticated = () => {
  const auth = getAuth();
  return auth && auth.userData && auth.userData.email;
};

export const formatPhoneNumber = (entry = "") => {
  if (!entry) {
    return "";
  }
  const entryReplace = entry
    .replace(/\D/g, "")
    .replace(/^1/, "")
    .match(/([^\d]*\d[^\d]*){1,10}$/);
  if (!entryReplace) {
    return "";
  }
  const match = entryReplace[0];
  const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match;
  const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : "";
  const part3 = match.length > 6 ? ` ${match.substring(6, 10)}` : "";
  return `${part1}${part2}${part3}`;
};

export const getPhoneNumberOnlyDigits = (phone = "") =>
  phone.replace(/\)/g, "").replace(/\(/g, "").replace(/\s/g, "");

export const validatePassword = (password) => {
  const regex = /^(?=.*?[#?!@$%^&*-]).{8,}$/;
  return regex.test(password);
};

export const changeURLWithoutReload = (pathname, newURL) => {
  window.history.pushState({ urlPath: pathname }, "", newURL);
};

export const range = (min, max, step = 1) => {
  const arr = [];
  if (typeof min !== "number" || typeof max !== "number") {
    return arr;
  }

  for (let i = min; i < max; i += step) {
    arr.push(i);
  }

  return arr;
};

export const handleErrorsAfterCallingAPI = (
  err,
  defaultForm,
  setFormErrors,
  dispatch
) => {
  const resErrors = err.data && err.data.errors ? err.data.errors : {};
  const newErrors = {};
  let isErrorOutOfScope = true;
  Object.keys(resErrors).forEach((key) => {
    newErrors[key] = resErrors[key][0];
    if (Object.keys(defaultForm).includes(key)) {
      isErrorOutOfScope = false;
    }
  });
  setFormErrors(newErrors);
  // base errors
  if (newErrors.base) {
    return dispatch(
      openMessage({
        title: newErrors.base,
        type: "error",
      })
    );
  }
  if (isErrorOutOfScope) {
    dispatch(
      openMessage({
        title: "Something went wrong, please try again!",
        type: "error",
      })
    );
  }
};

export const convertToFormData = (data, formDataName) => {
  const formData = new FormData();
  Object.keys(data).forEach((formName) => {
    if (formName === "files") {
      data[formName].forEach((file) => {
        formData.append(`${formDataName}[${formName}][]`, file);
      });
    } else {
      formData.append(`${formDataName}[${formName}]`, data[formName]);
    }
  });
  return formData;
};

export const getParam = (name) => {
  if (!name) return "";
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

export const getSortedData = (data, prop, isAsc) =>
  data.sort((a, b) => {
    const string1 =
      typeof a[prop] === "string" || typeof a[prop] === "number"
        ? a[prop].toString()
        : "";
    const string2 =
      typeof b[prop] === "string" || typeof b[prop] === "number"
        ? b[prop].toString()
        : "";
    return (string1.localeCompare(string2) ? -1 : 1) * (isAsc ? 1 : -1);
  });

// https://github.com/Hacker0x01/react-datepicker/issues/1018#issuecomment-913629586
export const formatDateOfPicker = (inputDate) => {
  if (inputDate === undefined) return undefined;
  const date = typeof inputDate === "string" ? new Date(inputDate) : inputDate;
  return new Date(date);
};

export const replaceParamStudent = (param, value) => {
  const newParam = param.replace(":studentId", value);
  return newParam;
};

export const getHostUrl = () =>
  `${window.location.protocol}//${window.location.host}`;

export const isStudent = () => {
  const auth = getAuth();
  return auth && auth.userData && auth.userData.isStudent;
};
export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const convertSnakeCaseToTitleCase = (text) => {
  const words = text.split("_");
  if (words.length > 1) {
    return words.map((word) => capitalizeFirstLetter(word)).join(" ");
  }
  return words[0];
};
