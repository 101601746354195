import { requestClient } from "lib/api";
import qs from "query-string";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { useSurveyStore } from "../store";

type QueryParams = {
  token: string;
};

export function usePreviouslyUploadedFiles() {
  const location = useLocation();
  const { token } = qs.parse(location.search) as QueryParams;
  const [removePreviouslyUploadedFile] = useSurveyStore((store) => [
    store.removePreviouslyUploadedFile,
  ]);

  const mutation = useMutation(
    (id: number) =>
      requestClient().delete(
        `/surveys/graduate_follow_up/attachment?id=${id}&token=${token}`
      ),
    {
      onSuccess: (_, id: number) => {
        removePreviouslyUploadedFile(id);
      },
    }
  );

  const isLoading = mutation.isLoading;
  const isError = mutation.isError;

  return { isLoading, isError, deleteFile: mutation.mutate };
}
