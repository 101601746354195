import React from "react";
import { useHistory } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Edit } from "../../common/icons";
import { ROUTES } from "../../../utils/constants";

function General({
  storeInstitution = { data: [], loading: false, error: null },
}) {
  const dataInstitution = storeInstitution.data;

  const history = useHistory();
  const handleClickButton = () => {
    history.push(ROUTES.editInstitution);
  };

  return (
    <div className="General dashboard__item__inner ">
      {storeInstitution.loading ? (
        <div className="loading">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="General__inner">
          <div className="info">
            <div>
              <p className="text--small gray03">Logo (Avatar)</p>
              <p>
                <img src={dataInstitution.logo} alt="" />
              </p>
            </div>
            <div>
              <p className="text--small gray03">School Name</p>
              <p className="text--small bold">{dataInstitution.name}</p>
            </div>
            <div>
              <p className="text--small gray03">
                Accreditation and National Agencies
              </p>
              <p className="text--small bold">
                {dataInstitution.school_agencies &&
                  dataInstitution.school_agencies.map((item, index) =>
                    index === dataInstitution.school_agencies.length - 1
                      ? item.is_type
                      : `${item.is_type}, `
                  )}
              </p>
            </div>
            {/* <div>
              <p className="text--small gray03">
                Does your institution participate in federal financial aid
                programs under Title IV of the Federal Higher Education Act?
              </p>
              <p className="text--small bold">
                {dataInstitution.title_iv ? "Yes" : "No"}
              </p>
            </div> */}
            <div>
              <p className="text--small gray03">Verifying Official</p>
              <p className="text--small bold">
                {dataInstitution.verifying_official
                  ? `${dataInstitution.verifying_official.first_name} ${dataInstitution.verifying_official.last_name}`
                  : "None"}
              </p>
            </div>
          </div>
          <button
            className="button--icon"
            type="button"
            onClick={handleClickButton}
          >
            <Edit />
            Edit Details
          </button>
        </div>
      )}
    </div>
  );
}

export default General;
