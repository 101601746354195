import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { TableActions } from "components";
import { DAYS_OF_WEEK, DEGREE_TYPES, ROUTES } from "../../../utils/constants";
import {
  FormGroup,
  MultiSelectCheckbox,
  SingleSelectCheckbox,
} from "../../common";
import CollapseCodeItem from "./CollapseCodeItem";

function FormAddPrograms({
  form,
  setForm,
  handleClickAddCode,
  handleClickAddLicensure,
  handleCheckbox,
  licensing,
  formErrors,
  onSubmit,
  onChange,
  onFocus,
  handleChangeDegreeType,
  degreeType,
  isSubmitting,
  onDelete,
  onEdit,
  onDeleteSocCode,
  isEditing,
}) {
  const [show, setShow] = React.useState(false);
  const history = useHistory();

  const handleBack = () => {
    history.push(ROUTES.programs);
  };

  return (
    <div className="Add-programs  dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="Add-programs__text">
          <p className="text--xlarge bold">
            {isEditing ? "Update Program" : "Add Program"}
          </p>
        </div>
        <div className="Add-programs__inner">
          <FormGroup
            propsInput={{
              name: "name",
              placeholder: "Program Name",
              onChange,
              onFocus,
              value: form.name,
              disabled: isSubmitting,
            }}
            error={formErrors.name}
            label="Program Name"
          />
          <FormGroup
            propsInput={{
              name: "description",
              placeholder: "Description",
              as: "textarea",
              onChange,
              onFocus,
              value: form.description,
              disabled: isSubmitting,
            }}
            error={formErrors.description}
            label="Description"
          />
          <FormGroup
            propsInput={{
              name: "cost",
              placeholder: "Cost",
              onChange,
              onFocus,
              value: form.cost,
              type: "number",
              min: 0,
              disabled: isSubmitting,
            }}
            error={formErrors.cost}
            label="Cost"
          />
          <FormGroup
            propsInput={{
              name: "program_length",
              placeholder: "Program Length (in Days)",
              onChange,
              onFocus,
              value: form.program_length,
              type: "number",
              min: 0,
              disabled: isSubmitting,
            }}
            error={formErrors.program_length}
            label="Program Length"
          />
          <div className="select">
            <p>Type of Degree Awarded</p>
            <SingleSelectCheckbox
              onChange={handleChangeDegreeType}
              name="degree_type"
              options={DEGREE_TYPES}
              value={degreeType}
              error={formErrors.degree_type}
              disabled={isSubmitting}
            />
          </div>
          <MultiSelectCheckbox
            show={show}
            name="days_of_week"
            size="small"
            label="Days of week"
            onChange={(selectedItem) => () => {
              setForm((prev) => {
                if (prev.days_of_week.includes(selectedItem.value)) {
                  return {
                    ...prev,
                    days_of_week: prev.days_of_week.filter(
                      (item) => item !== selectedItem.value
                    ),
                  };
                }

                return {
                  ...prev,
                  days_of_week: [...prev.days_of_week, selectedItem.value],
                };
              });
            }}
            options={DAYS_OF_WEEK.map((item, index) => ({
              value: index,
              label: item,
            }))}
            value={form.days_of_week || []}
            onToggle={() => setShow((prev) => !prev)}
            placeholder="Days of week"
            disabled={isSubmitting}
            error={formErrors.days_of_week}
          />
          <FormGroup
            propsInput={{
              name: "frequency",
              placeholder: "Frequency (in weeks)",
              onChange,
              onFocus,
              value: form.frequency,
              type: "number",
              min: 1,
              disabled: isSubmitting,
            }}
            error={formErrors.frequency}
            label="Frequency (in weeks)"
          />
        </div>
        <div>
          <div className="add-code">
            <p className=" bold">SOC Code</p>
            <p className=" bold btn--add primary" onClick={handleClickAddCode}>
              + Add Code
            </p>{" "}
            {!form.soc_codes_attributes.length && (
              <div className="error-message">
                {formErrors.soc_codes_attributes}
              </div>
            )}
          </div>
          {form.soc_codes_attributes &&
            form.soc_codes_attributes.map((item) => (
              <CollapseCodeItem
                key={`selected-${item.SOCCode}`}
                data={item}
                checked
                onDelete={onDeleteSocCode(item)}
              />
            ))}

          <div className="checkbox-group">
            <p className=" bold">
              Does this educational program lead to an occupation that requires
              State licensing?
            </p>
            <div className="checkbox">
              Yes
              <FormGroup
                propsInput={{
                  type: "radio",
                  name: "licensing",
                  onChange: handleCheckbox,
                  checked: licensing === "YES",
                  value: "YES",
                }}
                type="radio"
              />
            </div>
            <div className="checkbox">
              No
              <FormGroup
                propsInput={{
                  type: "radio",
                  name: "licensing",
                  onChange: handleCheckbox,
                  checked: licensing === "NO",
                  value: "NO",
                }}
                type="radio"
              />
            </div>
          </div>
          {licensing === "YES" && (
            <div className="add-licensure">
              <p
                className=" bold btn--add primary"
                onClick={handleClickAddLicensure}
              >
                + Licensure Exam
              </p>
              <div className="list__add-licensure">
                {form.licensure_exams_attributes &&
                  form.licensure_exams_attributes.map((item, index) => (
                    <div className="add-licensure__item" key={item.name}>
                      <div className="add-licensure__item__left">
                        <div>
                          <p className=" gray04">Type</p>
                          <p>{item.is_type}</p>
                        </div>
                        <div>
                          <p className=" gray04">
                            Name of the licensing entity
                          </p>
                          <p>{item.licensing_entity}</p>
                        </div>
                      </div>
                      <div className="add-licensure__item__right">
                        {item.state && (
                          <div>
                            <p className=" gray04">State</p>
                            <p>{item.state}</p>
                          </div>
                        )}
                        <div>
                          <p className=" gray04">Name of Exam</p>
                          <p>{item.name}</p>
                        </div>
                      </div>
                      <TableActions
                        actions={[
                          {
                            label: "Edit",
                            icon: ["fas", "pen-to-square"],
                            onSelect: onEdit(index),
                          },
                          {
                            label: "Delete",
                            icon: ["fas", "trash"],
                            onSelect: onDelete(index),
                          },
                        ]}
                      />
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
        <div className="button-group">
          <button
            className="button button--back"
            type="submit"
            onClick={handleBack}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
}

FormAddPrograms.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    cost: PropTypes.number,
    program_length: PropTypes.number,
    // eslint-disable-next-line react/forbid-prop-types
    soc_codes_attributes: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    licensure_exams_attributes: PropTypes.array,
  }),
  setForm: PropTypes.func,
  licensing: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  handleCheckbox: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  handleClickAddCode: PropTypes.func.isRequired,
  handleClickAddLicensure: PropTypes.func.isRequired,
  handleChangeDegreeType: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  degreeType: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDeleteSocCode: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
};

FormAddPrograms.defaultProps = {
  form: {
    name: "",
    description: "",
    cost: undefined,
    program_length: undefined,
    soc_codes_attributes: [],
    licensure_exams_attributes: [],
  },
  formErrors: {},
  licensing: "",
  degreeType: {},
  isEditing: false,
};

export default FormAddPrograms;
