import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { openMessage } from "../redux/actions/message";
import { Form } from "../components/resetPassword";
import { putResetPassword } from "../redux/actions/resetPassword";
import { getParam, validatePassword } from "../utils/helpers";
import { ROUTES } from "../utils/constants";

const DEFAULT_FORM_RESET_PASSWORD = {
  password: "",
  password_confirmation: "",
};

export default function () {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form, setForm] = React.useState({ ...DEFAULT_FORM_RESET_PASSWORD });
  const [formErrors, setFormErrors] = React.useState({});

  const storeResetPassword = useSelector((store) => store.resetPassword);

  const callApiResetPassword = async () => {
    const role = getParam("role");
    try {
      const params = new URLSearchParams(history.location.search);
      const header = {
        "access-token": params.get("access-token"),
        uid: params.get("uid"),
        client: params.get("client"),
      };
      await dispatch(putResetPassword(form, header, role));
      dispatch(
        openMessage({
          title: "Password change. you can now sign in with your new password",
          type: "success",
        })
      );
      setForm({ ...DEFAULT_FORM_RESET_PASSWORD });
      history.push(ROUTES.login);
    } catch (error) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const errors = {};

    if (!(form.password && validatePassword(form.password))) {
      errors.password =
        "Password must be atleast 8 characters and include at least one special character (, e.g., ! @ # ?)";
    }

    if (
      !form.password_confirmation ||
      form.password_confirmation !== form.password
    ) {
      errors.password_confirmation = "Password confirmation does not match";
    }

    setFormErrors(errors);

    // call api
    if (!Object.keys(errors).length) {
      callApiResetPassword();
    }
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  return (
    <Form
      form={form}
      formErrors={formErrors}
      onSubmit={handleSubmit}
      onChange={handleChange}
      onFocus={handleFocus}
      isSubmitting={storeResetPassword.loading}
    />
  );
}
