import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  HeaderImportStudent,
  Step1,
  Step2,
} from "../../../../components/dashboard/institution/integrationHubSpotStep";
import {
  getIntegrations,
  createHubSpotAuthorize,
  getHubSpotMapping,
  createHubSpotSync,
  createHubSpotMapping,
  getHubSpotAttributes,
} from "../../../../redux/actions/integration";
import { openMessage } from "../../../../redux/actions/message";
import { ROUTES } from "../../../../utils/constants";
import { getParam } from "../../../../utils/helpers";

const DEFAULT_FORM_MAP_DATA_TO_PULL = {
  model: "Student",
  integration_model: "Contact",
  mapping: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    education_level: "",
  },
};

export default function () {
  const dispatch = useDispatch();
  const history = useHistory();
  const code = getParam("code");
  const [step, setStep] = React.useState(1);
  const [formPullErrors, setFormPullErrors] = React.useState({});

  const [formPull, setFormPull] = React.useState({
    ...DEFAULT_FORM_MAP_DATA_TO_PULL,
  });
  const [actionType, setActionType] = React.useState("");

  const storeIntegrations = useSelector(
    (store) => store.integration.integrations
  );
  const storeHubSpotAttributes = useSelector(
    (store) => store.integration.hubspot.attributes
  );
  const storeHubSpotMappings = useSelector(
    (store) => store.integration.hubspot.mappings
  );
  const storeHubSpotCreateMapping = useSelector(
    (store) => store.integration.hubspot.mapping
  );
  const storeHubSpotSync = useSelector(
    (store) => store.integration.hubspot.sync
  );

  const isSubmittingMappingPull =
    storeHubSpotCreateMapping.loading ||
    storeHubSpotSync.loading ||
    storeHubSpotAttributes.loading ||
    storeHubSpotMappings.loading;

  const isSubmittingMappingPush =
    storeHubSpotCreateMapping.loading ||
    storeHubSpotSync.loading ||
    storeHubSpotAttributes.loading;

  const isLoadingTable =
    storeHubSpotAttributes.loading ||
    storeHubSpotMappings.loading ||
    storeHubSpotCreateMapping.loading;

  const dataAttributes = storeHubSpotAttributes.data;

  React.useEffect(() => {
    dispatch(getIntegrations());
    dispatch(getHubSpotMapping());
  }, []);

  React.useEffect(() => {
    if (
      storeIntegrations.data &&
      storeIntegrations.data.length &&
      storeIntegrations.data.some((item) => item.is_type === "hubspot")
    )
      dispatch(getHubSpotAttributes());
  }, [storeIntegrations]);

  React.useEffect(() => {
    if (storeHubSpotMappings.data && storeHubSpotMappings.data.length) {
      const dataStudent = storeHubSpotMappings.data.filter(
        (item) => item.model === "Student"
      );

      setFormPull({
        ...formPull,
        integration_model: dataStudent[0].integration_model,
        mapping: dataStudent[0].mapping,
      });
    }
  }, [storeHubSpotMappings]);

  React.useEffect(async () => {
    if (code) {
      try {
        await dispatch(createHubSpotAuthorize(code));
        dispatch(getHubSpotAttributes());
      } catch (err) {
        dispatch(
          openMessage({
            title: "Something went wrong, please try again!",
            type: "error",
          })
        );
        history.push(ROUTES.integrations);
      }
    }
  }, [code]);

  const handleSubmitMapDataToPull = async (event) => {
    event.preventDefault();
    const errors = {};

    if (!formPull.mapping.first_name) {
      errors.first_name = "Field not found";
    }
    if (!formPull.mapping.last_name) {
      errors.last_name = "Field not found";
    }
    if (!formPull.mapping.email) {
      errors.email = "Field not found";
    }
    if (!formPull.mapping.phone) {
      errors.phone = "Field not found";
    }
    if (!formPull.mapping.address) {
      errors.address = "Field not found";
    }
    if (!formPull.mapping.city) {
      errors.city = "Field not found";
    }
    if (!formPull.mapping.state) {
      errors.state = "Field not found";
    }
    if (!formPull.mapping.zip_code) {
      errors.zip_code = "Field not found";
    }

    setFormPullErrors(errors);

    // call api
    if (Object.keys(errors).length) return null;
    try {
      await dispatch(createHubSpotMapping(formPull));
      setStep(step + 1);
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleSubmitMapDataToPush = async (event) => {
    event.preventDefault();
    const formPush = {
      integration_model: formPull.integration_model,
      model: formPull.model,
      action_type: actionType === "yes" ? "push" : "",
    };

    if (actionType === "yes") {
      try {
        await dispatch(createHubSpotMapping(formPush));
        setStep(step + 1);
      } catch (err) {
        dispatch(
          openMessage({
            title: "Something went wrong, please try again!",
            type: "error",
          })
        );
      }
    } else setStep(3);
  };

  const handleSubmitSync = async (event) => {
    event.preventDefault();
    try {
      const response = await dispatch(createHubSpotSync());
      history.push(ROUTES.dataSyncHubSpot, response);
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleBack = () => {
    setStep(step - 1);
    setFormPullErrors({});
  };

  const handleChangeDataToPull = (name) => (item) => {
    const selectedItem = item[0] || { value: "" };

    setFormPull({
      ...formPull,
      mapping: {
        ...formPull.mapping,
        [name]: selectedItem.value,
      },
    });
  };

  const handleChangeActionType = (e) => {
    setActionType(e.target.value);
  };

  return (
    <div className="Import-student">
      <HeaderImportStudent step={step} />
      <div className="Survey-steps__inner">
        {step === 1 && (
          <Step1
            onSubmit={handleSubmitMapDataToPull}
            form={formPull}
            handleBack={handleBack}
            onChange={handleChangeDataToPull}
            dataAttributes={dataAttributes}
            error={formPullErrors}
            isSubmitting={isSubmittingMappingPull}
            isLoadingTable={isLoadingTable}
            step={step}
          />
        )}
        {step === 2 && (
          <Step2
            onSubmit={handleSubmitMapDataToPush}
            handleBack={handleBack}
            isSubmitting={isSubmittingMappingPush}
            onChange={handleChangeActionType}
            actionType={actionType}
          />
        )}
        {step === 3 && (
          <Step1
            onSubmit={handleSubmitSync}
            handleBack={handleBack}
            form={formPull}
            onChange={handleChangeDataToPull}
            dataAttributes={dataAttributes}
            error={formPullErrors}
            isSubmitting={isSubmittingMappingPush}
            isLoadingTable={isLoadingTable}
            step={step}
          />
        )}
      </div>
    </div>
  );
}
