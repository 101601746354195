/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/default-param-last */
import * as types from "../constants";

const initialState = {
  isBadToken: false,
  reportingURL: "",
  reportName: "",
  modalMessageDelete: {
    open: false,
    data: {},
  },
  modalMessageIntegration: {
    open: false,
    data: {},
  },
  modalPayment: {
    open: false,
  },
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.UPDATE_IS_BAD_TOKEN:
      return {
        ...state,
        isBadToken: payload,
      };
    case types.UPDATE_REPORTING_URL:
      return {
        ...state,
        reportingURL: payload,
      };
    case types.UPDATE_REPORT_NAME:
      return {
        ...state,
        reportName: payload,
      };
    case types.OPEN_MODAL_MESSAGE_DELETE:
      return {
        ...state,
        modalMessageDelete: {
          ...state.modalMessageDelete,
          open: true,
          data: payload,
        },
      };
    case types.CLOSE_MODAL_MESSAGE_DELETE:
      return {
        ...state,
        modalMessageDelete: {
          ...state.modalMessageDelete,
          open: false,
        },
      };
    case types.OPEN_MODAL_MESSAGE_INTEGRATION:
      return {
        ...state,
        modalMessageIntegration: {
          ...state.modalMessageIntegration,
          open: true,
          data: payload,
        },
      };
    case types.CLOSE_MODAL_MESSAGE_INTEGRATION:
      return {
        ...state,
        modalMessageIntegration: {
          ...state.modalMessageIntegration,
          open: false,
        },
      };
    case types.OPEN_MODAL_PAYMENT:
      return {
        ...state,
        modalPayment: {
          open: true,
        },
      };
    case types.CLOSE_MODAL_PAYMENT:
      return {
        ...state,
        modalPayment: {
          open: false,
        },
      };
    default:
      return state;
  }
}
