import React from "react";
import { Switch, Route } from "react-router-dom";
import { Title } from "../../../components/dashboard";
import { ROUTES } from "../../../utils/constants";
import Root from "./Root";
import Form from "./Form";
import Detail from "./Detail";

export default function () {
  return (
    <div className="Campaigns dashboard__item">
      <Title title="Campaigns" />
      <Switch>
        <Route path={ROUTES.campaigns} component={Root} exact />
        <Route path={ROUTES.addCampaign} exact component={Form} />
        <Route path={ROUTES.showCampaign} exact component={Detail} />
      </Switch>
    </div>
  );
}
