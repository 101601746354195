import React from "react";

export default function () {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5488 8.03681L13.6668 7.17581C13.505 7.05449 13.3049 6.99559 13.1032 7.00993C12.9015 7.02426 12.7118 7.11087 12.5688 7.25384C12.4258 7.39682 12.3392 7.58657 12.3249 7.78827C12.3105 7.98996 12.3694 8.19005 12.4908 8.35181L13.3728 9.23381C13.9214 9.78241 14.2296 10.5265 14.2296 11.3023C14.2296 12.0782 13.9214 12.8222 13.3728 13.3708C12.8242 13.9194 12.0801 14.2276 11.3043 14.2276C10.5284 14.2276 9.78437 13.9194 9.23577 13.3708L8.35377 12.4888C8.19201 12.3675 7.99191 12.3086 7.79022 12.3229C7.58853 12.3373 7.39877 12.4239 7.2558 12.5668C7.11282 12.7098 7.02621 12.8996 7.01188 13.1013C6.99755 13.303 7.05645 13.5031 7.17777 13.6648L8.03877 14.5468C8.90205 15.4101 10.0729 15.8951 11.2938 15.8951C12.5146 15.8951 13.6855 15.4101 14.5488 14.5468C15.412 13.6835 15.897 12.5127 15.897 11.2918C15.897 10.071 15.412 8.90009 14.5488 8.03681Z"
        fill="#979797"
      />
      <path
        d="M3.33348 9.82248C3.40881 9.90278 3.49993 9.96661 3.60113 10.01C3.70232 10.0534 3.81139 10.0753 3.92148 10.0745C4.03139 10.0741 4.14009 10.0516 4.2411 10.0083C4.34212 9.96497 4.43339 9.90179 4.50948 9.82248C4.58823 9.74613 4.65084 9.65474 4.69359 9.55374C4.73635 9.45273 4.75838 9.34416 4.75838 9.23448C4.75838 9.1248 4.73635 9.01623 4.69359 8.91523C4.65084 8.81422 4.58823 8.72283 4.50948 8.64648L3.62748 7.76448C3.35892 7.49361 3.14647 7.17238 3.00232 6.81922C2.85818 6.46606 2.78517 6.08792 2.78748 5.70648C2.78182 5.32159 2.85346 4.93947 2.99815 4.58277C3.14283 4.22606 3.35763 3.90201 3.62982 3.62982C3.90201 3.35763 4.22606 3.14283 4.58277 2.99815C4.93947 2.85346 5.32159 2.78182 5.70648 2.78748C6.08792 2.78517 6.46606 2.85818 6.81922 3.00232C7.17238 3.14647 7.49361 3.35892 7.76448 3.62748L8.64648 4.50948C8.71869 4.60576 8.81075 4.6854 8.91641 4.74301C9.02208 4.80061 9.13889 4.83484 9.25894 4.84337C9.37898 4.8519 9.49946 4.83454 9.61221 4.79245C9.72496 4.75037 9.82735 4.68455 9.91245 4.59945C9.99755 4.51435 10.0634 4.41196 10.1055 4.29921C10.1475 4.18646 10.1649 4.06598 10.1564 3.94594C10.1478 3.82589 10.1136 3.70908 10.056 3.60341C9.9984 3.49775 9.91876 3.40569 9.82248 3.33348L8.96148 2.45148C8.53521 2.02273 8.02788 1.6831 7.46905 1.45235C6.91023 1.22161 6.31107 1.10438 5.70648 1.10748C4.48675 1.10748 3.31698 1.59202 2.4545 2.4545C1.59202 3.31698 1.10748 4.48675 1.10748 5.70648C1.10438 6.31107 1.22161 6.91022 1.45235 7.46905C1.6831 8.02788 2.02273 8.53521 2.45148 8.96148L3.33348 9.82248Z"
        fill="#979797"
      />
      <path
        d="M16.6281 4.00512C16.5772 3.90716 16.5074 3.8202 16.4228 3.74923C16.3382 3.67826 16.2405 3.62466 16.1351 3.5915C16.0298 3.55834 15.919 3.54628 15.809 3.55599C15.699 3.56571 15.592 3.59701 15.4941 3.64812L13.6671 4.59312C13.5681 4.64011 13.4795 4.7065 13.4066 4.78836C13.3337 4.87022 13.278 4.96588 13.2427 5.06967C13.2075 5.17346 13.1934 5.28328 13.2014 5.3926C13.2094 5.50193 13.2392 5.60854 13.2891 5.70612C13.3596 5.84593 13.4677 5.96326 13.6013 6.0449C13.7348 6.12653 13.8886 6.16921 14.0451 6.16812C14.176 6.17074 14.3056 6.14193 14.4231 6.08412L16.2501 5.13912C16.3499 5.09001 16.4389 5.02159 16.512 4.93782C16.5851 4.85404 16.6409 4.75659 16.6761 4.6511C16.7112 4.54561 16.7251 4.43419 16.7169 4.3233C16.7086 4.21241 16.6785 4.10426 16.6281 4.00512Z"
        fill="#979797"
      />
      <path
        d="M4.00707 16.6262C4.12455 16.684 4.25417 16.7128 4.38507 16.7102C4.54162 16.7113 4.69536 16.6686 4.82894 16.5869C4.96252 16.5053 5.07063 16.388 5.14107 16.2482L6.08607 14.4212C6.13642 14.322 6.16658 14.2139 6.17481 14.103C6.18304 13.9921 6.16917 13.8807 6.13401 13.7752C6.09885 13.6697 6.04309 13.5722 5.96998 13.4885C5.89686 13.4047 5.80784 13.3363 5.70807 13.2872C5.61049 13.2372 5.50388 13.2074 5.39455 13.1994C5.28523 13.1915 5.17542 13.2055 5.07163 13.2408C4.96783 13.276 4.87217 13.3317 4.79031 13.4046C4.70846 13.4775 4.64206 13.5661 4.59507 13.6652L3.65007 15.4922C3.59897 15.5901 3.56766 15.6971 3.55795 15.807C3.54823 15.917 3.5603 16.0279 3.59345 16.1332C3.62661 16.2385 3.68021 16.3363 3.75118 16.4209C3.82216 16.5055 3.90911 16.5752 4.00707 16.6262Z"
        fill="#979797"
      />
      <path
        d="M12.9943 0.372294C12.8951 0.321946 12.787 0.291783 12.6761 0.283554C12.5652 0.275324 12.4538 0.289193 12.3483 0.324356C12.2428 0.359519 12.1453 0.415275 12.0616 0.488392C11.9778 0.561508 11.9094 0.65053 11.8603 0.750294L10.9153 2.57729C10.8649 2.67644 10.8348 2.78459 10.8265 2.89548C10.8183 3.00637 10.8322 3.11779 10.8673 3.22327C10.9025 3.32876 10.9582 3.42622 11.0314 3.50999C11.1045 3.59377 11.1935 3.66219 11.2933 3.71129C11.4102 3.77075 11.5402 3.79964 11.6713 3.79529C11.8248 3.7949 11.9751 3.75138 12.105 3.66969C12.235 3.58801 12.3394 3.47144 12.4063 3.33329L13.3513 1.50629C13.4024 1.40841 13.4337 1.30141 13.4434 1.19142C13.4531 1.08143 13.441 0.9706 13.4079 0.865275C13.3747 0.75995 13.3211 0.662196 13.2502 0.577606C13.1792 0.493015 13.0922 0.423247 12.9943 0.372294Z"
        fill="#979797"
      />
      <path
        d="M1.12937 13.4563C1.26178 13.4508 1.39113 13.4149 1.50737 13.3513L3.33437 12.4063C3.43341 12.3593 3.522 12.2929 3.5949 12.211C3.6678 12.1292 3.72353 12.0335 3.75878 11.9297C3.79403 11.8259 3.80808 11.7161 3.80011 11.6068C3.79214 11.4975 3.76231 11.3908 3.71237 11.2933C3.66327 11.1935 3.59485 11.1045 3.51107 11.0314C3.4273 10.9582 3.32984 10.9025 3.22435 10.8673C3.11887 10.8322 3.00745 10.8183 2.89656 10.8265C2.78567 10.8348 2.67752 10.8649 2.57837 10.9153L0.751375 11.8603C0.57929 11.9436 0.440767 12.0831 0.358691 12.2557C0.276615 12.4284 0.255896 12.6239 0.299957 12.8099C0.344018 12.996 0.450224 13.1614 0.601028 13.2789C0.751832 13.3964 0.938211 13.459 1.12937 13.4563Z"
        fill="#979797"
      />
    </svg>
  );
}
