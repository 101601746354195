import React from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";

function TypeaheadComponent({ error = "", label = "", propsTypeahead = {} }) {
  const { labelKey, options, selected } = propsTypeahead;

  let selectedOption = [];
  const filterOption = options.filter(
    (item) => selected.length && item.value === selected[0].value
  );
  if (filterOption.length) {
    selectedOption = [filterOption[0]];
  }

  return (
    <Form.Group className="form-group__typeahead">
      {label && <Form.Label>{label}</Form.Label>}
      <Typeahead
        {...propsTypeahead}
        isInvalid={!!error}
        selected={selectedOption}
        options={options}
        labelKey={labelKey}
      />
      {error && (
        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      )}
    </Form.Group>
  );
}

export default TypeaheadComponent;
