import React from "react";
import { ReactComponent as IconCheck } from "../../../../assets/icons/check-solid.svg";
import { ReactComponent as IconPending } from "../../../../assets/icons/triangle-exclamation-solid.svg";
import { ReactComponent as IconPendingVerification } from "../../../../assets/icons/hourglass-half-solid.svg";
import Table from "../../../common/Table";

const STATUS_MAPPING = {
  verified: {
    icon: IconCheck,
    text: "Verified",
  },
  pending_verification: {
    icon: IconPendingVerification,
    text: "Pending Verification",
  },
  pending: {
    icon: IconPending,
    text: "Pending",
  },
};

function Card({ title = "", data = [], columns1 = [], columns2 = [] }) {
  const status = React.useMemo(() => {
    const changeRequest = data?.[0]?.survey_result?.change_requests?.sort(
      (a, b) => {
        const date1 = new Date(a.created_at);
        const date2 = new Date(b.created_at);
        return date2 - date1;
      }
    )[0];

    return changeRequest?.status || ""; // "pending" | "pending_verification" | "verified"
  }, [data]);

  const Icon = STATUS_MAPPING?.[status]?.icon;

  return (
    <div className="Student-card">
      <p>{title}</p>
      {status && (
        <div className={`Student-card__status Student-card__status--${status}`}>
          {Icon && <Icon />}
          <span>{STATUS_MAPPING?.[status]?.text}</span>
        </div>
      )}
      <div className="Student-card__inner">
        <div>
          <Table id="table1" columns={columns1} data={data} />
          <Table id="table2" columns={columns2} data={data} />
        </div>
      </div>
    </div>
  );
}

export default Card;
