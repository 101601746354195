import React from "react";

const withBootstrap = (Component) =>
  function (props) {
    return (
      <div className="bootstrap">
        <Component {...props} />
      </div>
    );
  };

export default withBootstrap;
