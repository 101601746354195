import React from "react";

export default function () {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.36572 0C7.81344 0 7.36572 0.447715 7.36572 1V6.99994H1.36572C0.813438 6.99994 0.365723 7.44765 0.365723 7.99994C0.365723 8.55222 0.813438 8.99994 1.36572 8.99994H7.36572V15C7.36572 15.5523 7.81344 16 8.36572 16C8.91801 16 9.36572 15.5523 9.36572 15V8.99994H15.3657C15.918 8.99994 16.3657 8.55222 16.3657 7.99994C16.3657 7.44765 15.918 6.99994 15.3657 6.99994H9.36572V1C9.36572 0.447715 8.91801 0 8.36572 0Z"
        fill="white"
      />
    </svg>
  );
}
