import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import {
  FormGroup,
  SingleDatePicker,
  SingleSelectCheckbox,
} from "../../common";
import { EDUCATION_LEVELS, OPTIONS_ETHNICITY } from "../../../utils/constants";

function AddStudent({
  form,
  formErrors,
  onSubmit,
  onChange,
  handleChangePhoneNumber,
  onFocus,
  handleCheckbox,
  handleChangeEducationLevel,
  educationLevel,
  ethnicity,
  handleChangeEthnicity,
  handleChangePlace,
  handleFocusPlace,
  handleChangeAddress,
  address,
  handleCallbackDob,
  handleBack,
  isSubmitting,
  textTitle,
  textButton,
}) {
  return (
    <div className="AddStudent form-student dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="form-student__text">
          <p className="text--xlarge bold">{textTitle || "Add Student"}</p>
        </div>
        <div className="AddStudent__inner form-student__inner">
          <div className="input__group">
            <FormGroup
              propsInput={{
                name: "first_name",
                placeholder: "First Name",
                onChange,
                onFocus,
                value: form.first_name,
                disabled: isSubmitting,
              }}
              error={formErrors.first_name}
              label="First Name"
            />
            <FormGroup
              propsInput={{
                name: "last_name",
                placeholder: "Last Name",
                onChange,
                onFocus,
                value: form.last_name,
                disabled: isSubmitting,
              }}
              error={formErrors.last_name}
              label="Last Name"
            />
            <div className="Date-picker">
              <p className="Date-picker__label">DOB</p>
              <SingleDatePicker
                placeholder="Start Date"
                value={form.dob}
                onChange={handleCallbackDob}
                disabled={isSubmitting}
              />
            </div>
            <FormGroup
              propsInput={{
                name: "social_security_number",
                placeholder: "Social Security Number",
                type: "number",
                min: 0,
                onChange,
                onFocus,
                value: form.social_security_number,
                disabled: isSubmitting,
              }}
              error={formErrors.social_security_number}
              label="Social Security Number"
            />
            <FormGroup
              propsInput={{
                name: "email",
                placeholder: "Email Address",
                type: "email",
                onChange,
                onFocus,
                value: form.email,
                disabled: isSubmitting,
              }}
              error={formErrors.email}
              label="Email Address"
            />
            <FormGroup
              propsInput={{
                name: "phone",
                placeholder: "Phone Number",
                onChange: handleChangePhoneNumber,
                onFocus,
                value: form.phone,
                disabled: isSubmitting,
              }}
              error={formErrors.phone}
              label="Phone Number"
            />
            <div className="select">
              <p>Education Level</p>
              <SingleSelectCheckbox
                onChange={handleChangeEducationLevel}
                name="education_level"
                options={EDUCATION_LEVELS}
                value={educationLevel}
                error={formErrors.education_level}
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div className="checkbox-group">
            <p className=" bold">Gender</p>
            <div>
              <div className="checkbox">
                Male
                <FormGroup
                  propsInput={{
                    type: "radio",
                    name: "gender",
                    onChange: handleCheckbox,
                    checked: form.gender === "male",
                    value: "male",
                  }}
                  type="radio"
                />
              </div>
              <div className="checkbox">
                Female
                <FormGroup
                  propsInput={{
                    type: "radio",
                    name: "gender",
                    onChange: handleCheckbox,
                    checked: form.gender === "female",
                    value: "female",
                  }}
                  type="radio"
                />
              </div>
              <div className="checkbox">
                Non-binary
                <FormGroup
                  propsInput={{
                    type: "radio",
                    name: "gender",
                    onChange: handleCheckbox,
                    checked: form.gender === "non-binary",
                    value: "non-binary",
                  }}
                  type="radio"
                />
              </div>
            </div>
            {!form.gender && (
              <div className="error-message">{formErrors.gender}</div>
            )}
          </div>
          <div className="input__group">
            <div className="select">
              <p>Ethnicity</p>
              <SingleSelectCheckbox
                onChange={handleChangeEthnicity}
                name="branch"
                options={OPTIONS_ETHNICITY}
                value={ethnicity}
                error={formErrors.ethnicity}
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div className="input__group">
            <div className="Place">
              <p className="Place__label">Street</p>
              <Autocomplete
                placeholder="Street"
                onPlaceSelected={handleChangePlace}
                types={["address"]}
                onFocus={handleFocusPlace}
                value={address}
                onChange={handleChangeAddress}
                disabled={isSubmitting}
              />
              {/* {(formErrors.city || formErrors.state || formErrors.zip_code) && (
                <div className="error-message">This field is invalid please type a new place</div>
              )} */}
            </div>
          </div>
          <div className="input__group">
            <FormGroup
              propsInput={{
                name: "city",
                placeholder: "City",
                onChange,
                onFocus,
                value: form.city,
                disabled: isSubmitting,
              }}
              error={formErrors.city}
              label="City"
            />

            <FormGroup
              propsInput={{
                name: "state",
                placeholder: "State",
                onChange,
                onFocus,
                value: form.state,
                disabled: isSubmitting,
              }}
              error={formErrors.state}
              label="State"
            />
            <FormGroup
              propsInput={{
                name: "zip_code",
                placeholder: "ZIP Code",
                onChange,
                onFocus,
                value: form.zip_code,
                disabled: isSubmitting,
              }}
              error={formErrors.zip_code}
              label="ZIP Code"
            />
          </div>
        </div>
        <div className="button-group">
          <button
            className="button button--back"
            type="submit"
            onClick={handleBack}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="submit"
            disabled={isSubmitting}
          >
            {textButton || "Add New Student"}
          </button>
        </div>
      </Form>
    </div>
  );
}

AddStudent.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    dob: PropTypes.any,
    social_security_number: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    education_level: PropTypes.string,
    gender: PropTypes.string,
    ethnicity: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip_code: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  handleChangePhoneNumber: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  handleChangeEducationLevel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  educationLevel: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  ethnicity: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  isSubmitting: PropTypes.bool,
  handleChangePlace: PropTypes.func.isRequired,
  handleFocusPlace: PropTypes.func.isRequired,
  handleChangeEthnicity: PropTypes.func.isRequired,
  handleChangeAddress: PropTypes.func.isRequired,
  address: PropTypes.string,
  handleCallbackDob: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  textTitle: PropTypes.string,
  textButton: PropTypes.string,
};

AddStudent.defaultProps = {
  form: {
    first_name: "",
    last_name: "",
    dob: new Date(),
    social_security_number: "",
    email: "",
    phone: "",
    education_level: "",
    gender: "",
    ethnicity: "",
    city: "",
    state: "",
    zip_code: "",
  },
  formErrors: {},
  educationLevel: {},
  ethnicity: {},
  isSubmitting: false,
  address: "",
  textTitle: "",
  textButton: "",
};

export default AddStudent;
