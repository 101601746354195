import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { SelectUserRole, FormGroup } from "components/common";
import logo from "../../assets/images/logo2.svg";
// import google from '../../assets/icons/google.svg';
// import outlook from '../../assets/icons/outlook.svg';
import { ROUTES } from "../../utils/constants";

function SignUpForm({
  form,
  formErrors,
  onSubmit,
  onChange,
  onFocus,
  isSubmitting,
  role,
  setRole,
  type,
}) {
  const renderFormContent = () => {
    if (role === "admin") {
      return (
        <>
          <FormGroup
            propsInput={{
              name: "first_name",
              placeholder: "First Name",
              onChange,
              onFocus,
              value: form.first_name,
              disabled: isSubmitting,
            }}
            error={formErrors.first_name}
            label="First Name"
          />
          <FormGroup
            propsInput={{
              name: "last_name",
              placeholder: "Last Name",
              onChange,
              onFocus,
              value: form.last_name,
              disabled: isSubmitting,
            }}
            error={formErrors.last_name}
            label="Last Name"
          />
          <FormGroup
            propsInput={{
              name: "email",
              placeholder: "Email",
              type: "email",
              onChange,
              onFocus,
              value: form.email,
              disabled: isSubmitting,
            }}
            error={formErrors.email}
            label="Email"
          />
          <FormGroup
            propsInput={{
              name: "password",
              placeholder: "Password",
              type: "password",
              onChange,
              onFocus,
              value: form.password,
              disabled: isSubmitting,
            }}
            error={formErrors.password}
            label="Password"
          />
          <FormGroup
            propsInput={{
              name: "password_confirmation",
              placeholder: "Repeat Password",
              type: "password",
              onChange,
              onFocus,
              value: form.password_confirmation,
              disabled: isSubmitting,
            }}
            error={formErrors.password_confirmation}
            label="Repeat Password"
          />
        </>
      );
    }
    if (role === "student" && type === "verifying") {
      return (
        <>
          <FormGroup
            propsInput={{
              name: "password",
              placeholder: "Password",
              type: "password",
              onChange,
              onFocus,
              value: form.password,
              disabled: isSubmitting,
            }}
            error={formErrors.password}
            label="Password"
          />
          <FormGroup
            propsInput={{
              name: "password_confirmation",
              placeholder: "Repeat Password",
              type: "password",
              onChange,
              onFocus,
              value: form.password_confirmation,
              disabled: isSubmitting,
            }}
            error={formErrors.password_confirmation}
            label="Repeat Password"
          />
        </>
      );
    }

    // role student
    return (
      <FormGroup
        propsInput={{
          name: "invitation_token",
          placeholder: "Invitation Token",
          onChange,
          onFocus,
          value: form.invitation_token,
          disabled: isSubmitting,
        }}
        error={formErrors.invitation_token}
        label="Invitation Token"
      />
    );
  };

  return (
    <section className="Sign-up bg-form">
      <Link to="/" className="logo-form">
        <img src={logo} alt="" />
      </Link>
      <div className="container">
        <div className="Sign-up__inner academy-form">
          <Form noValidate onSubmit={onSubmit}>
            <p className="text--xlarge bold">Sign Up for AcademySync</p>
            <p className="text--small">
              Welcome! Please fill out the form below to begin onboarding your
              school with AcademySync.
            </p>
            <SelectUserRole
              userRole={role}
              onChangeRole={(selectedRole) => setRole(selectedRole)}
              type={type}
              role={role}
            />
            <div className="form__inner">
              {renderFormContent()}
              <p className="text--xsmall gray03 ">
                By signing up for AcademySync, you agree to our{" "}
                <Link to="/privacy-policy">Privacy Policy</Link> and{" "}
                <Link to="/terms-of-service">Terms of Service</Link>.
              </p>
              <button
                className="button button--primary"
                type="submit"
                disabled={isSubmitting}
              >
                Sign Up Now
              </button>
            </div>
            {/* <div className="button-group">
              <button className="button button--secondary" type="button">
                <img src={google} alt="" />
                Google Sign Up
              </button>
              <button className="button button--secondary" type="button">
                <img src={outlook} alt="" />
                Outlook Sign up
              </button>
            </div> */}
            <p className="text--xsmall">
              <Link to={ROUTES.login}>Have an account? Sign In</Link>
            </p>
          </Form>
        </div>
      </div>
    </section>
  );
}

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    password_confirmation: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

SignUpForm.defaultProps = {
  form: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
  },
  formErrors: {},
};

export default SignUpForm;
