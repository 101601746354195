import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import { FormGroup, SingleSelectCheckbox, Dropzone } from "../../common";
import { AGENCIES_TYPES, ROUTES } from "../../../utils/constants";
import { Delete } from "../../common/icons";

function EditInstitution({
  form,
  formAgencies,
  formErrors,
  onSubmit,
  onChange,
  onFocus,
  dataInstitution,
  dataAdmins,
  handleChangeAgencies,
  handleChangeVerifyingAdmin,
  verifyingAdmin,
  newLogoSrc,
  handleDrop,
  isSubmitting,
}) {
  const history = useHistory();
  const handleBack = () => {
    history.push(ROUTES.institution);
  };

  const OPTIONS_ADMIN =
    dataAdmins?.map((item) => ({
      value: item.id,
      label: item.full_name,
    })) || [];

  return (
    <div className="Edit-institution  dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="Edit-institution__text">
          <p className="text--xlarge bold">Edit Institution</p>
        </div>
        <div className="Edit-institution__inner">
          <div className="logo">
            <p className="text--small bold">Logo</p>
            <div>
              <img src={newLogoSrc || dataInstitution.logo} alt="" />
              <button type="button" className="button__delete">
                <Delete />
              </button>
            </div>
          </div>

          <div className="input__group">
            <div className="add-document">
              <Dropzone
                handleDrop={handleDrop}
                accept=".jpg,.png"
                multiple={false}
              />
            </div>
          </div>
          <FormGroup
            propsInput={{
              name: "name",
              placeholder: "School Name",
              onChange,
              onFocus,
              value: form.name,
              disabled: isSubmitting,
            }}
            error={formErrors.name}
            label="School Name"
          />
          <FormGroup
            propsInput={{
              name: "website",
              placeholder: "Website",
              onChange,
              onFocus,
              value: form.website,
              disabled: isSubmitting,
            }}
            error={formErrors.website}
            label="Website"
          />
          <div className="checkbox-group">
            <p className=" bold">Accreditation and National Agencies</p>
            {AGENCIES_TYPES.map((agency) => (
              <div className="checkbox" key={agency}>
                {agency}
                <FormGroup
                  propsInput={{
                    type: "checkbox",
                    name: "agencies",
                    onChange: handleChangeAgencies(agency),
                    checked: formAgencies.includes(agency),
                    value: agency,
                    disabled: isSubmitting,
                  }}
                  type="checkbox"
                />
              </div>
            ))}
          </div>
          {/* <div className="checkbox-group">
            <p className=" bold">
              Does your institution participate in federal financial aid
              programs under Title IV of the Federal Higher Education Act?
            </p>
            <div>
              <div className="checkbox">
                Yes
                <FormGroup
                  propsInput={{
                    type: "radio",
                    name: "title_iv",
                    onChange,
                    checked: form.title_iv === "YES",
                    value: "YES",
                    disabled: isSubmitting,
                  }}
                  type="radio"
                />
              </div>
              <div className="checkbox">
                No
                <FormGroup
                  propsInput={{
                    type: "radio",
                    name: "title_iv",
                    onChange,
                    checked: form.title_iv === "NO",
                    value: "NO",
                    disabled: isSubmitting,
                  }}
                  type="radio"
                />
              </div>
            </div>
          </div> */}
          <div className="select">
            <p>Verifying Official</p>
            <SingleSelectCheckbox
              onChange={handleChangeVerifyingAdmin}
              name="verifying_admin"
              options={OPTIONS_ADMIN}
              value={verifyingAdmin}
              disabled={isSubmitting}
              placeholder="Verifying Official"
            />
          </div>
        </div>
        <div className="button-group">
          <button
            className="button button--back"
            type="submit"
            onClick={handleBack}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
}

EditInstitution.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    name: PropTypes.string,
    website: PropTypes.string,
    // title_iv: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    school_agencies_attributes: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    logo: PropTypes.array,
  }),
  formAgencies: PropTypes.arrayOf(PropTypes.string),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  dataInstitution: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  dataAdmins: PropTypes.arrayOf(PropTypes.object),
  handleChangeAgencies: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  newLogoSrc: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

EditInstitution.defaultProps = {
  form: {
    name: "",
    website: "",
    // title_iv: "NO",
    school_agencies_attributes: [],
  },
  formAgencies: [],
  formErrors: {},
  dataInstitution: {},
  newLogoSrc: "",
  isSubmitting: false,
};

export default EditInstitution;
