import React from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { ROUTES, ROUTES_MAP } from "../../utils/constants";
import { ArrowBreadcumb } from "./icons";

function Breadcrumb() {
  const location = useLocation();
  const { studentId } = useParams();
  const isStudentRoutes = Boolean(studentId);

  let newPath = "";
  const breadcrumbItems = [];
  const arrSplit = location.pathname.split("/");
  arrSplit.forEach((item, index) => {
    if (!item) return;

    newPath += `/${item}`;

    if (newPath === ROUTES.dashboard) return;

    breadcrumbItems.push(
      <NavLink
        key={newPath}
        to={{ pathname: newPath, state: location.state }}
        className="Breadcrumb__item"
        activeClassName="--is-active"
        exact
      >
        <p>
          {
            ROUTES_MAP[
              isStudentRoutes
                ? newPath.replace(studentId, ":studentId")
                : newPath
            ]?.name
          }
        </p>
        {index < arrSplit.length - 1 && <ArrowBreadcumb />}
      </NavLink>
    );
  });

  return (
    <div className="Breadcrumb">
      {breadcrumbItems.length > 1 ? breadcrumbItems : null}
    </div>
  );
}

export default Breadcrumb;
