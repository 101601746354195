/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { Table, Typeahead } from "../../../common";
import { Tick, Unlink, Warning } from "../../../common/icons";

function Step1({
  onSubmit,
  isSubmitting,
  handleBack,
  form,
  onChange,
  error,
  dataAttributes,
  isLoadingTable,
  step,
}) {
  const optionAttributes = dataAttributes?.length
    ? dataAttributes.map((item) => ({
        label: item.label,
        value: item.name,
      }))
    : [];

  const DATA = [
    {
      id: 2,
      name: "First Name",
      hubspot: {
        placeholder: "Select a Field",
        name: "first_name",
        options: optionAttributes,
      },
    },
    {
      id: 3,
      name: "Last Name",
      hubspot: {
        placeholder: "Select a Field",
        name: "last_name",
        options: optionAttributes,
      },
    },
    {
      id: 4,
      name: "Email",
      hubspot: {
        placeholder: "Select a Field",
        name: "email",
        options: optionAttributes,
      },
    },
    {
      id: 5,
      name: "Phone",
      hubspot: {
        placeholder: "Select a Field",
        name: "phone",
        options: optionAttributes,
      },
    },
    {
      id: 6,
      name: "Street",
      hubspot: {
        placeholder: "Select a Field",
        name: "address",
        options: optionAttributes,
      },
    },
    {
      id: 7,
      name: "City",
      hubspot: {
        placeholder: "Select a Field",
        name: "city",
        options: optionAttributes,
      },
    },
    {
      id: 8,
      name: "State",
      hubspot: {
        placeholder: "Select a Field",
        name: "state",
        options: optionAttributes,
      },
    },
    {
      id: 9,
      name: "Zip",
      hubspot: {
        placeholder: "Select a Field",
        name: "zip_code",
        options: optionAttributes,
      },
    },
    {
      id: 10,
      name: "Education Level",
      hubspot: {
        placeholder: "Select a Field",
        name: "education_level",
        options: optionAttributes,
      },
    },
  ];

  const columns = [
    {
      title: "AcademySync",
      dataIndex: "name",
      render: (row) => <div>{row.name}</div>,
    },
    {
      title: "HubSpot",
      dataIndex: "id",
      render: (row) => (
        <Typeahead
          propsTypeahead={{
            id: `map-pull-data-${row.id}`,
            labelKey: "label",
            options: row.hubspot.options,
            placeholder: row.hubspot.placeholder,
            onChange: onChange(row.hubspot.name),
            selected: [
              {
                value: form.mapping[row.hubspot.name],
              },
            ],
            disabled: step === 3,
          }}
          error={error[row.hubspot.name]}
        />
      ),
    },
    {
      title: "Mapped Status",
      dataIndex: "status",
      render: (row) => (
        <div>
          {form.mapping[row.hubspot.name] ? (
            // eslint-disable-next-line react/jsx-indent
            <div className="status mapped">
              <Tick />
              Mapped
            </div>
          ) : error[row.hubspot.name] ? (
            <div className="status invalid">
              <Warning />
              Invalid
            </div>
          ) : (
            <div className="status unmapped">
              <Unlink />
              Unmapped
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="pull-step2  form-student dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="form-student__text">
          <p className="text--large bold">
            {step === 1 ? "Map Data to Pull" : "Mapping Summary"}
          </p>
          {step === 1 && (
            <p className="text--small">
              Sync your student data in HubSpot with AcademySync by mapping your
              HubSpot Contact object and fields to an AcademySync Student
              object.
            </p>
          )}
        </div>
        <div className="pull-step2__inner form-student__inner">
          <Table loading={isLoadingTable} columns={columns} data={DATA} />
          <div className="button-group">
            {step === 3 && (
              <button
                className="button button--back"
                type="submit"
                onClick={handleBack}
                disabled={isSubmitting}
              >
                Back
              </button>
            )}

            <button
              className="button button--primary"
              type="submit"
              disabled={isSubmitting}
            >
              {step === 3 ? "Sync" : "Map"}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

Step1.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Step1.defaultProps = {
  isSubmitting: false,
};

export default Step1;
