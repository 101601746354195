/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/default-param-last */
import * as types from "../constants";

const initialState = {
  list: {
    data: [],
    meta: {
      total: 0,
      perPage: 10,
    },
    loading: false,
    error: null,
  },
  create: {
    data: {},
    loading: false,
    error: null,
  },
  delete: {
    data: {},
    loading: false,
    error: null,
  },
  update: {
    data: {},
    loading: false,
    error: null,
  },
  import: {
    importStudent: {
      data: {},
      loading: false,
      error: null,
    },
    fileRow: {
      data: {},
      loading: false,
      error: null,
    },
    fileStatus: {
      data: {},
      loading: false,
      error: null,
    },
  },
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_STUDENTS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case types.GET_STUDENTS_SUCCEED:
      return {
        ...state,
        list: {
          ...state.list,
          data: payload.data,
          meta: payload.meta,
          loading: false,
        },
      };
    case types.GET_STUDENTS_FAIL:
      return {
        ...state,
        list: {
          ...state.list,
          error: payload,
          loading: false,
        },
      };
    // create
    case types.CREATE_STUDENT:
      return {
        ...state,
        create: { ...state.create, loading: true, error: {} },
      };
    case types.CREATE_STUDENT_SUCCEED:
      return {
        ...state,
        create: { ...state.create, data: payload, loading: false },
      };
    case types.CREATE_STUDENT_FAIL:
      return {
        ...state,
        create: { ...state.create, error: payload, loading: false },
      };

    // update
    case types.UPDATE_STUDENT:
      return {
        ...state,
        update: {
          ...initialState.update,
          loading: true,
        },
      };
    case types.UPDATE_STUDENT_SUCCEED:
      return {
        ...state,
        update: {
          ...state.update,
          data: payload,
          loading: false,
        },
      };
    case types.UPDATE_STUDENT_FAIL:
      return {
        ...state,
        update: {
          ...state.update,
          error: payload,
          loading: false,
        },
      };
    // import student
    case types.IMPORT_STUDENT:
      return {
        ...state,
        import: {
          ...state.import,
          importStudent: {
            ...state.import.importStudent,
            loading: true,
          },
        },
      };
    case types.IMPORT_STUDENT_SUCCEED:
      return {
        ...state,
        import: {
          ...state.import,
          importStudent: {
            ...state.import.importStudent,
            data: payload,
            loading: false,
          },
        },
      };
    case types.IMPORT_STUDENT_FAIL:
      return {
        ...state,
        import: {
          ...state.import,
          importStudent: {
            ...state.import.importStudent,
            error: payload,
            loading: false,
          },
        },
      };

    // file row
    case types.GET_ROW:
      return {
        ...state,
        import: {
          ...state.import,
          fileRow: {
            ...state.import.fileRow,
            loading: true,
          },
        },
      };
    case types.GET_ROW_SUCCEED:
      return {
        ...state,
        import: {
          ...state.import,
          fileRow: {
            ...state.import.fileRow,
            data: payload,
            loading: false,
          },
        },
      };
    case types.GET_ROW_FAIL:
      return {
        ...state,
        import: {
          ...state.import,
          fileRow: {
            ...state.import.fileRow,
            error: payload,
            loading: false,
          },
        },
      };

    // file status
    case types.GET_FILE_STATUS:
      return {
        ...state,
        import: {
          ...state.import,
          fileStatus: {
            ...state.import.fileStatus,
            loading: true,
          },
        },
      };
    case types.GET_FILE_STATUS_SUCCEED:
      return {
        ...state,
        import: {
          ...state.import,
          fileStatus: {
            ...state.import.fileStatus,
            data: payload,
            loading: false,
          },
        },
      };
    case types.GET_FILE_STATUS_FAIL:
      return {
        ...state,
        import: {
          ...state.import,
          fileStatus: {
            ...state.import.fileStatus,
            error: payload,
            loading: false,
          },
        },
      };
    default:
      return state;
  }
}
