import React from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { replaceParamStudent } from "utils/helpers";
import { TableActions } from "components";
import { ROUTES } from "../../../../utils/constants";
import { Plus, Folder } from "../../../common/icons";
import Card from "./Card";

function Financial({
  storeFinancial = { data: [], loading: false, error: null },
  onEdit = () => {},
  onDelete = () => {},
}) {
  const history = useHistory();
  const { studentId } = useParams();

  const handleClick = () => {
    history.push(replaceParamStudent(ROUTES.addFinancial, studentId));
  };

  const columns1 = [
    {
      title: "Program",
      dataIndex: "program",
      render: (row) => (row.program ? row.program.name : "-"),
    },
    {
      title: "Reimbursement Date",
      dataIndex: "reimbursement_date",
      render: (row) => moment(row.disbursement_date).format("MM/DD/YYYY"),
    },
    {
      title: "Aid Amount",
      dataIndex: "amount",
      render: (row) => `$${row.amount}`,
    },
    {
      title: "Documentation",
      dataIndex: "files",
      render: (row) => {
        if (!row.files.length) return "-";
        return row.files.map((item) => (
          <a
            className="document"
            key={item.id || item.name}
            href={item.url}
            target="_blank"
            rel="noreferrer"
          >
            <Folder />
            {item.filename}
          </a>
        ));
      },
    },
    {
      title: "",
      dataIndex: "id",
      align: "right",
      render: (row) => (
        <TableActions
          actions={[
            {
              label: "Edit",
              icon: ["fas", "pen-to-square"],
              onSelect: onEdit(row),
            },
            {
              label: "Delete",
              icon: ["fas", "trash"],
              onSelect: onDelete(row),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <div className="Financial Student-tap dashboard__item__inner">
      <div className="button__add">
        <button type="submit" className="button--icon" onClick={handleClick}>
          <Plus />
          Financial Aid Program
        </button>
      </div>
      <div>
        {storeFinancial.data.map((item) => (
          <Card
            title={
              item.financial_aid_program ? item.financial_aid_program.name : ""
            }
            data={[item]}
            columns1={columns1}
            key={item.id}
          />
        ))}
      </div>
    </div>
  );
}

export default Financial;
