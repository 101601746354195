import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ROUTES } from "../../../../utils/constants";
import { AddFinancial } from "../../../../components/dashboard/students/student";
import {
  createFinancialAid,
  deleteDocument,
  getFinancialAidPrograms,
  getStudentPrograms,
  updateFinancialAid,
} from "../../../../redux/actions/student";
import { openMessage } from "../../../../redux/actions/message";
import {
  convertToFormData,
  formatDateOfPicker,
  handleErrorsAfterCallingAPI,
  replaceParamStudent,
} from "../../../../utils/helpers";
import { ModalDeleteDocument } from "../../../../components/common";

const DEFAULT_FORM_FINANCIAL = {
  program_id: undefined,
  financial_aid_program_id: undefined,
  disbursement_date: new Date(),
  amount: "",
  files: [],
};

export default function () {
  const history = useHistory();
  const location = useLocation();
  const [form, setForm] = React.useState({
    ...DEFAULT_FORM_FINANCIAL,
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [openModalDeleteDocument, setOpenModalDeleteDocument] =
    React.useState(false);
  const [document, setDocument] = React.useState({});
  const [documentIndex, setDocumentIndex] = React.useState(null);
  const dispatch = useDispatch();
  const financialAidData = location.state || {};
  const isEditing = !!Object.keys(financialAidData).length;
  const storeCreate = useSelector((store) => store.student.financialAid.create);
  const storeUpdate = useSelector((store) => store.student.financialAid.create);
  const storePrograms = useSelector((store) => store.student.programs);
  const storeFinancialAidPrograms = useSelector(
    (store) => store.student.financialAid.financialAidPrograms
  );
  const { studentId } = useParams();
  const isSubmitting = storeCreate.loading || storeUpdate.loading;

  React.useEffect(() => {
    dispatch(getStudentPrograms(studentId));
    dispatch(getFinancialAidPrograms());
  }, [studentId]);

  React.useEffect(() => {
    if (isEditing) {
      setForm({
        ...financialAidData,
        financial_aid_program_id: financialAidData.financial_aid_program.id,
        program_id: financialAidData.program.id,
      });
    }
  }, [financialAidData, isEditing]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.program_id) {
      errors.program_id = "Program is required";
    }
    if (!form.financial_aid_program_id) {
      errors.financial_aid_program_id = "Financial Aid Program is required";
    }
    if (!form.amount) {
      errors.amount = "Aid mount is required";
    }

    setFormErrors(errors);

    // call api
    if (!Object.keys(errors).length) {
      if (isEditing) {
        // edit
        try {
          setFormErrors({});
          const formData = {
            ...form,
            disbursement_date: formatDateOfPicker(form.disbursement_date),
          };
          formData.files = form.files.filter((item) => !item.id);
          await dispatch(
            updateFinancialAid(
              convertToFormData(formData, "student_financial_aid_program"),
              studentId,
              financialAidData.id
            )
          );
          dispatch(
            openMessage({
              title: "Financial aid has been updated.",
              type: "success",
            })
          );
          history.push(replaceParamStudent(ROUTES.financial, studentId));
        } catch (error) {
          handleErrorsAfterCallingAPI(
            error,
            DEFAULT_FORM_FINANCIAL,
            setFormErrors,
            dispatch
          );
        }
      } else {
        // create
        try {
          await dispatch(
            createFinancialAid(
              convertToFormData(form, "student_financial_aid_program"),
              studentId
            )
          );
          dispatch(
            openMessage({
              title: "Financial aid has been created.",
              type: "success",
            })
          );
          history.push(replaceParamStudent(ROUTES.financial, studentId));
        } catch (error) {
          handleErrorsAfterCallingAPI(
            error,
            DEFAULT_FORM_FINANCIAL,
            setFormErrors,
            dispatch
          );
        }
      }
    }
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  const handleChangeSelect = (selectedItem, name) => {
    setForm({
      ...form,
      [name]: selectedItem.value,
    });
  };

  const handleChangeDate = (date, name) => {
    setForm({ ...form, [name]: date });
  };
  const handleBack = () => {
    history.push(replaceParamStudent(ROUTES.financial, studentId));
  };

  const handleDropzone = (dropped) => {
    const newFiles = [...form.files, ...dropped];
    setForm({
      ...form,
      files: newFiles,
    });
  };

  const handleTogleModalDeleteDocument = (file, selectedIndex) => () => {
    setOpenModalDeleteDocument(!openModalDeleteDocument);
    setDocument(file);
    setDocumentIndex(selectedIndex);
  };

  const handleDeleteDocument = async () => {
    if (document.id) {
      try {
        setFormErrors({});
        await dispatch(deleteDocument(document.id));
        dispatch(
          openMessage({
            title: "This document has been deleted",
            type: "success",
          })
        );
        setForm({
          ...form,
          files: form.files.filter((item, index) => index !== documentIndex),
        });
        setOpenModalDeleteDocument(!openModalDeleteDocument);
      } catch (error) {
        dispatch(
          openMessage({
            title: "Something went wrong, please try again!",
            type: "error",
          })
        );
      }
    } else {
      setForm({
        ...form,
        files: form.files.filter((item, index) => index !== documentIndex),
      });
      setOpenModalDeleteDocument(!openModalDeleteDocument);
    }
  };

  const handleCancelDelete = () => {
    setOpenModalDeleteDocument(false);
  };

  return (
    <>
      <AddFinancial
        form={form}
        formErrors={formErrors}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onFocus={handleFocus}
        onChangeSelect={handleChangeSelect}
        handleChangeDate={handleChangeDate}
        dataPrograms={storePrograms.data}
        isSubmitting={isSubmitting}
        handleBack={handleBack}
        handleDrop={handleDropzone}
        dataFinancialAidPrograms={storeFinancialAidPrograms.data}
        onDeleteDocument={handleTogleModalDeleteDocument}
      />
      <ModalDeleteDocument
        isOpen={openModalDeleteDocument}
        onBack={handleCancelDelete}
        nameDocument={document.name || document.filename}
        onDelete={handleDeleteDocument}
      />
    </>
  );
}
