import React from "react";
import css from "./styles.module.scss";

type Props = {
  children: ReactNode;
};

export function Form(props: Props) {
  const { children } = props;
  return <form className={css.form}>{children}</form>;
}
