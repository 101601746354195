import React from "react";
import { Switch, Route, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import qs from "query-string";
import { getPlacements, getStudent } from "redux/actions/student";
import request from "utils/request";
import { openMessage } from "redux/actions/message";
import General from "./General";
import Enrollment from "./Enrollment";
import EditStudent from "./EditStudent";
import { HeaderStudent } from "../../../../components/dashboard/students/student";
import { ROUTES } from "../../../../utils/constants";
import AddEnrollment from "./AddEnrollment";
import Licensure from "./Licensure";
import AddLicensure from "./AddLicensure";
import Placement from "./Placement";
import AddPlacement from "./AddPlacement";
import Financial from "./Financial";
import StudentTasks from "./StudentTasks";
import AddFinancial from "./AddFinancial";
import ContactLog from "./ContactLog";
import AddContact from "./AddContact";
import ChangeLog from "./ChangeLog";
import Document from "./Document";

export default function () {
  const location = useLocation();
  const dispatch = useDispatch();
  const { studentId } = useParams();

  const dataStudent = useSelector((store) => store.student);
  const selectedStudent = dataStudent.student?.data || {};
  const loading = dataStudent.student.loading;

  React.useEffect(() => {
    dispatch(getStudent({}, studentId));
  }, [studentId]);

  React.useEffect(() => {
    const query = qs.parse(location.search);
    const isSigningComplete = query.event === "signing_complete";

    // Post complete for verify survey
    if (query.event) {
      if (isSigningComplete) {
        request()
          .post(
            `/surveys/graduate_follow_up/complete_verify?token=${query?.token}`
          )
          .then(() => {
            dispatch(
              openMessage({
                title: "Verification documents signed successfully",
                type: "success",
              })
            );
            dispatch(getPlacements({}, studentId));
          });
      } else {
        dispatch(
          openMessage({
            title: "Verification documents could not be signed",
            type: "success",
          })
        );
      }
    }
  }, [location]);

  return (
    <div className="Student dashboard__item">
      <HeaderStudent selectedStudent={selectedStudent} loading={loading} />
      <Switch>
        <Route
          path={ROUTES.student}
          render={() => <General selectedStudent={selectedStudent} />}
          exact
        />
        <Route path={ROUTES.enrollment} component={Enrollment} exact />
        <Route
          path={ROUTES.editStudent}
          render={() => <EditStudent selectedStudent={selectedStudent} />}
          exact
        />
        <Route path={ROUTES.addEnrollment} component={AddEnrollment} exact />
        <Route path={ROUTES.licensure} component={Licensure} exact />
        <Route path={ROUTES.addLicensure} component={AddLicensure} exact />
        <Route path={ROUTES.placement} component={Placement} exact />
        <Route path={ROUTES.addPlacement} component={AddPlacement} exact />
        <Route path={ROUTES.financial} component={Financial} exact />
        <Route path={ROUTES.studentTasks} component={StudentTasks} exact />
        <Route path={ROUTES.addFinancial} component={AddFinancial} exact />
        <Route path={ROUTES.contactLog} component={ContactLog} exact />
        <Route path={ROUTES.addContact} component={AddContact} exact />
        <Route path={ROUTES.changeLog} component={ChangeLog} exact />
        <Route path={ROUTES.documents} component={Document} exact />
      </Switch>
    </div>
  );
}
