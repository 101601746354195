import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { closeModalPayment } from "redux/actions/global";
import request from "utils/request";
import FormGroup from "./FormGroup";

function Component({ onCancelCallback, onSubmitCallback }) {
  const storeModalPayment = useSelector((store) => store.global.modalPayment);
  const dispatch = useDispatch();
  const [enrollments, setEnrollments] = React.useState();
  const [errorEnrollments, setErrorEnrollments] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleChangeEnrollments = (e) => {
    setEnrollments(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorEnrollments("");
    if (!enrollments || enrollments <= 0) {
      setErrorEnrollments("Enrollments is required");
      return;
    }
    try {
      setLoading(true);
      const res = await request().get(`/payment/checkout?count=${enrollments}`);
      setLoading(false);
      window.location.href = res?.data?.data;
    } catch (err) {
      setLoading(false);
      console.error("submit checkout failed: ", err);
    }
    if (onCancelCallback) {
      onSubmitCallback();
    }
  };

  const handleCancel = () => {
    dispatch(closeModalPayment());
    if (onCancelCallback) {
      onCancelCallback();
    }
  };

  return (
    <Modal
      show={storeModalPayment.open}
      onHide={handleCancel}
      backdropClassName="wrap-modal"
      dialogClassName="Modal Modal__Payment"
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="bootstrap">
        <Modal.Header className="flex flex-column">
          <div className="Modal__Payment__icon__warning">
            <span>!</span>
          </div>
          <Modal.Title>Payment Required</Modal.Title>
          <p>
            You do not have available enrollments to create this enrollment.
            Please purchase a new set of enrollments to continue.
          </p>
        </Modal.Header>
        <Modal.Body>
          <FormGroup
            propsInput={{
              name: "count",
              placeholder: "Enter number of student enrollments",
              onChange: handleChangeEnrollments,
              value: enrollments,
              disabled: loading,
            }}
            error={errorEnrollments}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button button--back"
            type="button"
            onClick={handleCancel}
            disabled={loading}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="button"
            onClick={handleSubmit}
            disabled={loading || !enrollments}
          >
            Checkout
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

Component.propTypes = {
  onCancelCallback: PropTypes.func,
  onSubmitCallback: PropTypes.func,
};

Component.defaultProps = {};

export default Component;
