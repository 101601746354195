import * as types from "../constants";
import request from "../../utils/request";

export function getSurveys(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_SURVEYS });
    return request()
      .get("/surveys", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_SURVEYS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            meta: {
              total: dataResponse.headers.total,
              perPage: dataResponse.headers["per-page"],
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_SURVEYS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function sendSurvey(data) {
  return (dispatch) => {
    dispatch({ type: types.SEND_SURVEY });
    return request()
      .post(`/surveys`, { survey: data })
      .then((dataResponse) => {
        dispatch({
          type: types.SEND_SURVEY_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.SEND_SURVEY_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
