import React from "react";
import {
  Root,
  Item,
  Header,
  Trigger,
  Content,
} from "@radix-ui/react-accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import css from "./styles.module.scss";

type Props = {
  summary: string;
  details: string;
};

export function Details(props: Props) {
  const { summary, details } = props;
  return (
    <Root collapsible type="single">
      <Item value="details">
        <Header>
          <Trigger className={css.summary}>
            {summary}
            <FontAwesomeIcon
              className={css.icon}
              icon={["fas", "chevron-down"]}
              size="1x"
            />
          </Trigger>
        </Header>
        <Content className={css.details}>{details}</Content>
      </Item>
    </Root>
  );
}
