import React from "react";
import { useHistory } from "react-router-dom";
import { TableActions } from "components";
import { ROUTES, SEARCH_NAME, SORT_NAME } from "../../../utils/constants";
import { FormGroup, Table } from "../../common";
import { Plus } from "../../common/icons";

function FinancialAidProgramsTable({
  data = { data: [], meta: {}, loading: false, error: null },
  onPageChange = () => null,
  params = {},
  onSort = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onEdit = () => {},
  loading = false,
}) {
  const history = useHistory();

  const handleClick = () => {
    history.push(ROUTES.addFinancialAidPrograms);
  };

  const columns = [
    {
      title: "Program Name",
      dataIndex: "name",
      sortAble: true,
    },
    {
      title: "State",
      dataIndex: "state",
      render: ({ state }) => state || "--",
      align: "center",
      sortAble: true,
    },
    {
      title: "Type",
      dataIndex: "is_type",
      render: ({ is_type }) =>
        is_type.charAt(0).toUpperCase() + is_type.slice(1),
      sortAble: true,
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (row) => (
        <TableActions
          actions={[
            {
              label: "Edit",
              icon: ["fas", "pen-to-square"],
              onSelect: onEdit(row),
            },
            {
              label: "Delete",
              icon: ["fas", "trash"],
              onSelect: onDelete(row),
            },
          ]}
        />
      ),
      align: "right",
    },
  ];

  return (
    <div className="FinancialAidPrograms__inner dashboard__item__inner">
      <div className="filter">
        <FormGroup
          propsInput={{
            name: "name",
            className: "inputSearch",
            placeholder: "What are you looking for?",
            onChange: onSearch,
            value: params[SEARCH_NAME] || "",
          }}
        />
        <button type="submit" className="button--icon" onClick={handleClick}>
          <Plus />
          Financial Aid Program
        </button>
      </div>

      <Table
        loading={loading}
        columns={columns}
        data={data.data}
        tableProps={{ striped: true }}
        sort={params[SORT_NAME] || ""}
        onSort={onSort}
        paginationProps={{
          itemsLength: data.meta.total,
          perPage: data.meta.perPage,
          onPageChange,
          activePage: params.page || 1,
        }}
      />
    </div>
  );
}

export default FinancialAidProgramsTable;
