import React from "react";
import { useLocation } from "react-router-dom";
import { Header, Footer } from "../components/layout";
import { ROUTES } from "../utils/constants";

const withMainLayout = (Component) =>
  function (props) {
    const location = useLocation();

    return (
      <div className="app">
        <Header
          isDarkHeader={
            location.pathname === ROUTES.contactUs ||
            location.pathname === ROUTES.pricing
          }
          isBoxshadow={location.pathname === ROUTES.pricing}
        />
        <Component {...props} />
        <Footer />
      </div>
    );
  };

export default withMainLayout;
