import { createMachine } from "xstate";
import { useMachine } from "@xstate/react";
import { useLocation } from "react-router-dom";
import qs from "query-string";
import { fixProgressPercentage } from "lib/math";
import { useSurveyStore } from "./store";

export type SurveyState =
  | "CurrentlyEmployedStep"
  | "CurrentlySeekingEmploymentStep"
  | "CurrentEmploymentTypeStep"
  | "EducationInstitutionInformationStep"
  | "EmploymentConditionsStep"
  | "EmploymentInformationStep"
  | "JobSearchRatingStep"
  | "MilitaryInformationStep"
  | "NameAndEmailStep"
  | "ReasonForNotSeekingEmploymentStep"
  | "RequiredDocumentsReviewStep"
  | "SelfEmploymentTypeStep"
  | "SignDocumentsStep"
  | "SocCodeInformationStep"
  | "StartPage"
  | "SurveyCompleteStep"
  | "UnavailabilityDateStep"
  | "UploadDocumentsStep";
type SurveyEvent = { type: "next" } | { type: "back" };

type QueryParams = {
  placement_id?: string;
  event?: string;
};

const surveyMachine = createMachine({
  tsTypes: {} as import("./machine.typegen").Typegen0,
  predictableActionArguments: true,
  id: "GraduateFollowUpSurvey",
  initial: "StartPage",
  schema: {
    events: {} as SurveyEvent,
  },
  states: {
    StartPage: {
      always: [
        {
          target: "SurveyCompleteStep",
          cond: "isSigningComplete",
        },
      ],
      on: {
        next: {
          target: "NameAndEmailStep",
        },
      },
    },
    NameAndEmailStep: {
      meta: {
        progress: 1 / 9,
      },
      on: {
        back: {
          target: "StartPage",
        },
        next: {
          target: "CurrentlyEmployedStep",
        },
      },
    },
    CurrentlyEmployedStep: {
      meta: {
        progress: 2 / 9,
      },
      on: {
        back: {
          target: "NameAndEmailStep",
        },
        next: [
          {
            cond: "isCurrentlyEmployed",
            target: "CurrentEmploymentTypeStep",
          },
          {
            target: "CurrentlySeekingEmploymentStep",
          },
        ],
      },
    },
    CurrentlySeekingEmploymentStep: {
      meta: {
        progress: 2 / 9,
      },
      on: {
        back: {
          target: "CurrentlyEmployedStep",
        },
        next: [
          {
            cond: "isCurrentlySeekingEmployment",
            target: "JobSearchRatingStep",
          },
          {
            target: "ReasonForNotSeekingEmploymentStep",
          },
        ],
      },
    },
    CurrentEmploymentTypeStep: {
      meta: {
        progress: 3 / 9,
      },
      on: {
        back: {
          target: "CurrentlyEmployedStep",
        },
        next: [
          {
            cond: "isEmployedInField",
            target: "SocCodeInformationStep",
          },
          {
            cond: "isEmployedOutOfField",
            target: "EmploymentConditionsStep",
          },
          {
            cond: "isSelfEmployed",
            target: "SelfEmploymentTypeStep",
          },
        ],
      },
    },
    ReasonForNotSeekingEmploymentStep: {
      meta: {
        progress: 3 / 9,
      },
      on: {
        back: {
          target: "CurrentlySeekingEmploymentStep",
        },
        next: [
          {
            cond: "isContinuiningEducation",
            target: "EducationInstitutionInformationStep",
          },
          {
            cond: "isInMilitary",
            target: "MilitaryInformationStep",
          },
          {
            cond: "isNotSeekingForPersonalReasons",
            target: "UnavailabilityDateStep",
          },
        ],
      },
    },
    JobSearchRatingStep: {
      meta: {
        progress: 2 / 9,
      },
      on: {
        back: {
          target: "CurrentlySeekingEmploymentStep",
        },
        next: {
          target: "RequiredDocumentsReviewStep",
        },
      },
    },
    EducationInstitutionInformationStep: {
      meta: {
        progress: 5 / 9,
      },
      on: {
        back: {
          target: "ReasonForNotSeekingEmploymentStep",
        },
        next: {
          target: "UnavailabilityDateStep",
        },
      },
    },
    MilitaryInformationStep: {
      meta: {
        progress: 5 / 9,
      },
      on: {
        back: {
          target: "ReasonForNotSeekingEmploymentStep",
        },
        next: {
          target: "UnavailabilityDateStep",
        },
      },
    },
    UnavailabilityDateStep: {
      meta: {
        progress: 6 / 9,
      },
      on: {
        back: [
          {
            cond: "isContinuiningEducation",
            target: "EducationInstitutionInformationStep",
          },
          {
            cond: "isInMilitary",
            target: "MilitaryInformationStep",
          },
          {
            cond: "isNotSeekingForPersonalReasons",
            target: "ReasonForNotSeekingEmploymentStep",
          },
        ],
        next: {
          target: "UploadDocumentsStep",
        },
      },
    },
    UploadDocumentsStep: {
      meta: {
        progress: 7 / 9,
      },
      on: {
        back: [
          {
            cond: "isCurrentlyEmployed",
            target: "EmploymentInformationStep",
          },
          {
            target: "UnavailabilityDateStep",
          },
        ],
        next: {
          target: "RequiredDocumentsReviewStep",
        },
      },
    },
    RequiredDocumentsReviewStep: {
      meta: {
        progress: 8 / 9,
      },
      on: {
        back: [
          {
            cond: "isCurrentlySeekingEmployment",
            target: "JobSearchRatingStep",
          },
          {
            target: "UploadDocumentsStep",
          },
        ],
        next: [
          {
            target: "SignDocumentsStep",
            cond: "hasDocumentsToSign",
          },
          {
            target: "SurveyCompleteStep",
          },
        ],
      },
    },
    SignDocumentsStep: {
      type: "final",
    },
    SurveyCompleteStep: {
      meta: {
        progress: 9 / 9,
      },
      type: "final",
    },
    SocCodeInformationStep: {
      meta: {
        progress: 4 / 9,
      },
      on: {
        next: {
          target: "EmploymentConditionsStep",
        },
        back: {
          target: "CurrentEmploymentTypeStep",
        },
      },
    },
    EmploymentConditionsStep: {
      meta: {
        progress: 5 / 9,
      },
      on: {
        next: {
          target: "EmploymentInformationStep",
        },
        back: [
          {
            cond: "isEmployedInField",
            target: "SocCodeInformationStep",
          },
          {
            target: "CurrentEmploymentTypeStep",
          },
        ],
      },
    },
    SelfEmploymentTypeStep: {
      meta: {
        progress: 5 / 9,
      },
      on: {
        next: {
          target: "EmploymentInformationStep",
        },
        back: {
          target: "CurrentEmploymentTypeStep",
        },
      },
    },
    EmploymentInformationStep: {
      meta: {
        progress: 6 / 9,
      },
      on: {
        back: [
          {
            cond: "isEmployedInField",
            target: "EmploymentConditionsStep",
          },
          {
            cond: "isEmployedOutOfField",
            target: "EmploymentConditionsStep",
          },
          {
            target: "SelfEmploymentTypeStep",
          },
        ],
        next: {
          target: "UploadDocumentsStep",
        },
      },
    },
  },
});

export function useSurveyMachine() {
  const location = useLocation();
  const [state, send] = useMachine(surveyMachine, {
    guards: {
      // We use direct access to useSurveyStore.getState() here because changes
      // to state require a component re-render, which won't happen in time for
      // the hook version to get latest state when a next event is sent
      isCurrentlyEmployed: () =>
        useSurveyStore.getState().currentlyEmployed === "YES",
      isCurrentlySeekingEmployment: () =>
        useSurveyStore.getState().currentlySeekingEmployment === "YES",
      isContinuiningEducation: () =>
        useSurveyStore.getState().reasonForNotSeekingEmployment ===
        "Continuing Education",
      isInMilitary: () =>
        useSurveyStore.getState().reasonForNotSeekingEmployment === "Military",
      isNotSeekingForPersonalReasons: () =>
        useSurveyStore.getState().reasonForNotSeekingEmployment ===
        "Not Seeking Employment for Personal Reasons",
      isEmployedInField: () =>
        useSurveyStore.getState().currentEmploymentType === "Employed In-Field",
      isEmployedOutOfField: () =>
        useSurveyStore.getState().currentEmploymentType ===
        "Employed Out of Field",
      isSelfEmployed: () =>
        useSurveyStore.getState().currentEmploymentType === "Self-Employed",
      hasDocumentsToSign: () =>
        useSurveyStore.getState().hasDocumentsToSign || false,
      isSigningComplete: () => {
        const { placement_id: placementId, event } = qs.parse(
          location.search
        ) as QueryParams;
        return Boolean(placementId && event === "signing_complete");
      },
    },
  });

  const step = state.value as SurveyState;
  const meta = state.meta[`GraduateFollowUpSurvey.${state.value}`];
  const progress = fixProgressPercentage(meta?.progress);

  const next = () => send("next");
  const back = () => send("back");

  return { step, progress, next, back };
}
