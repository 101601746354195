import React from "react";
import cn from "classnames/bind";
import { SchoolLogo } from "../SchoolLogo";
import css from "./styles.module.scss";

const cx = cn.bind(css);

type Props = {
  title?: string;
  subtitle?: string;
  name?: string;
  logo?: string;
  center?: boolean;
};

export function StepHeader(props: Props) {
  const title = props?.title;
  const subtitle = props?.subtitle;
  const name = props?.name;
  const logo = props?.logo;
  const center = props?.center;

  return (
    <header className={cx("header", { center })}>
      <SchoolLogo
        school={{
          name: "",
          logo:
            logo ||
            "https://api.academysync.com/rails/active_storage/representations/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdm9EIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--7770068300d7b89f19da93dbec892ed4b608c404/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCam9MY21WemFYcGxTU0lNTlRBd2VEVXdNQVk2QmtWVSIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--42bdb68c501b008630dd1071e8e0b4f039ce3efd/logo@2x.png",
        }}
      />
      <div className={css.wrapText}>
        <h1 className={css.title}>{title || "Student Registration Form"}</h1>
        <h1 className={css.subTitle}>{name || "AcademySync"}</h1>
        {subtitle && <h2 className={css.smallTitle}>{subtitle}</h2>}
      </div>
    </header>
  );
}
