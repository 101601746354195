import * as types from "../constants";
import request from "../../utils/request";

export function putResetPassword(data, headers, role) {
  return (dispatch) => {
    dispatch({ type: types.PUT_RESET_PASSWORD });
    return request({}, headers)
      .put(
        role === "student" ? "/student-auth/password" : "/auth/password",
        data
      )
      .then((dataResponse) => {
        dispatch({
          type: types.PUT_RESET_PASSWORD_SUCCEED,
          payload: dataResponse,
        });
        return dataResponse;
      })
      .catch((error) => {
        dispatch({
          type: types.PUT_RESET_PASSWORD_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
