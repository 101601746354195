import React, { forwardRef } from "react";
import type { ChangeEvent } from "react";
import cn from "classnames/bind";
import { normalizePhoneInput } from "lib/normalize";
import { useAutoComplete } from "./useAutoComplete";
import css from "./styles.module.scss";

const cx = cn.bind(css);

type Props = {
  label?: string;
  type?: "text" | "number" | "date" | "tel";
  autoComplete?: boolean;
  placeholder?: string;
  error?: string;
  "data-testid"?: string;
  onPlaceSelected?: (result: AutocompleteAddress) => void;
} & FormControlProps;

export const TextInput = forwardRef<HTMLInputElement, Props>(
  (props, parentRef) => {
    const {
      label,
      placeholder,
      name,
      error,
      "data-testid": testId,
      onPlaceSelected,
      onChange,
      onBlur,
      disabled = false,
    } = props;
    const type = props?.type ?? "text";
    const hasError = error !== undefined;
    const isAutoComplete = props?.autoComplete ?? false;

    const { inputRef } = useAutoComplete({ isAutoComplete, onPlaceSelected });

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (type === "tel") {
        e.target.value = normalizePhoneInput(e.target.value);
        onChange(e);
      } else {
        onChange(e);
      }
    };

    return (
      <>
        {(label || error) && (
          <div className={css.textArea}>
            <label className={css.label} htmlFor={name}>
              {label}
            </label>
            <div className={css.error}>{error}</div>
          </div>
        )}

        <input
          data-testid={testId}
          className={cx("input", { type, hasError, disabled })}
          placeholder={placeholder}
          type={type}
          ref={(ref) => {
            if (typeof parentRef === "function") {
              parentRef(ref);
            }
            inputRef.current = ref!;
          }}
          name={name}
          id={name}
          onChange={handleOnChange}
          onBlur={onBlur}
        />
      </>
    );
  }
);
