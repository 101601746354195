import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { RequiredStringSchema } from "lib/zod";
import type { SurveyInfo } from "../api";
import { useSurveyStore } from "../store";
import {
  Card,
  ButtonArea,
  Button,
  Details,
  Form,
  StepHeader,
  RadioGroup,
} from "../components";

type Props = {
  surveyInfo: SurveyInfo;
  progress?: string;
  onNextClick: () => void;
  onBackClick: () => void;
};

const schema = z.object({
  socCode: RequiredStringSchema,
});

type Inputs = z.infer<typeof schema>;

export function SocCodeInformationStep(props: Props) {
  const { surveyInfo, progress, onNextClick, onBackClick } = props;
  const [state, setState] = useSurveyStore((store) => [
    store.socCode,
    store.setSocCode,
  ]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: { socCode: state },
    resolver: zodResolver(schema),
  });

  const handleBackClick = () => {
    setState(getValues());
    onBackClick();
  };

  const handleNextClick = async () => {
    await handleSubmit((data) => {
      setState(data);
      onNextClick();
    })();
  };

  return (
    <Card progress={progress}>
      <StepHeader
        surveyInfo={surveyInfo}
        title="Which of the following descriptions most closely match the type of duties you do in your current training-related employment?"
      />

      <Form>
        <RadioGroup<Inputs["socCode"]>
          vertical
          options={surveyInfo.program.socCodes.map((socCode) => ({
            label: socCode.title,
            details: (
              <Details summary="Description" details={socCode.description} />
            ),
            value: socCode.code,
          }))}
          error={errors.socCode?.message}
          {...register("socCode")}
        />
      </Form>

      <ButtonArea>
        <Button label="Back" onClick={handleBackClick} />
        <Button primary label="Continue" onClick={handleNextClick} />
      </ButtonArea>
    </Card>
  );
}
