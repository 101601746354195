/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import FormGroup from "../common/FormGroup";
import Range from "./Range";
import { questionLegallyJob } from "../../utils/constants";

function Step4({
  formSurvey,
  onCheckbox,
  onSubmit,
  loading,
  handleBack,
  schoolData,
}) {
  return (
    <div className=" Step step3">
      <div className="Step__inner ">
        <div className="form_wrap">
          <Form noValidate onSubmit={onSubmit}>
            <div className="form__inner">
              <div className="Step__inner__header">
                <img
                  src={schoolData.logo}
                  alt=""
                  style={{ display: "inline" }}
                />
                <p>CIRR Student Intent Survey</p>
              </div>
              <div className="Step__inner__body">
                <p className="title">
                  Are you legally authorized to work in the country where you
                  will be job searching?
                </p>
                <div className="checkbox-group">
                  <div className="checkbox">
                    Yes
                    <FormGroup
                      propsInput={{
                        type: "radio",
                        name: questionLegallyJob,
                        onChange: onCheckbox,
                        checked:
                          formSurvey.survey_responses_attributes[
                            questionLegallyJob
                          ] === "Yes",
                        value: "Yes",
                      }}
                      type="radio"
                    />
                  </div>
                  <div className="checkbox">
                    No
                    <FormGroup
                      propsInput={{
                        type: "radio",
                        name: questionLegallyJob,
                        onChange: onCheckbox,
                        checked:
                          formSurvey.survey_responses_attributes[
                            questionLegallyJob
                          ] === "No",
                        value: "No",
                      }}
                      type="radio"
                    />
                  </div>
                </div>
              </div>
              <div className="button-group">
                <button
                  className="button button--back"
                  type="submit"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  className="button button--primary"
                  type="submit"
                  onSubmit={onSubmit}
                  disabled={
                    loading ||
                    !formSurvey.survey_responses_attributes[questionLegallyJob]
                  }
                >
                  Continue
                </button>
              </div>
            </div>
          </Form>
          <Range percent={48} />
        </div>
      </div>
    </div>
  );
}

Step4.propTypes = {
  onCheckbox: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Step4.defaultProps = {
  loading: false,
};

export default Step4;
