import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import type { SurveyInfo } from "../api";
import {
  Card,
  Button,
  ButtonArea,
  StepHeader,
  Form,
  RadioGroup,
} from "../components";
import { useSurveyStore } from "../store";

type Props = {
  surveyInfo: SurveyInfo;
  progress?: string;
  onNextClick: () => void;
  onBackClick: () => void;
};

const schema = z.object({
  currentEmploymentType: z.enum(
    ["Employed In-Field", "Employed Out of Field", "Self-Employed"],
    {
      invalid_type_error: "Required",
    }
  ),
});

type Inputs = z.infer<typeof schema>;

export function CurrentEmploymentTypeStep(props: Props) {
  const { surveyInfo, progress, onNextClick, onBackClick } = props;
  const [state, setState] = useSurveyStore((store) => [
    store.currentEmploymentType,
    store.setCurrentEmploymentType,
  ]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: { currentEmploymentType: state },
    resolver: zodResolver(schema),
  });

  const handleBackClick = () => {
    setState(getValues());
    onBackClick();
  };

  const handleNextClick = async () => {
    await handleSubmit((data) => {
      setState(data);
      onNextClick();
    })();
  };

  return (
    <Card progress={progress}>
      <StepHeader
        surveyInfo={surveyInfo}
        title="Which of the following describes your current employment status?"
      />

      <Form>
        <RadioGroup<Inputs["currentEmploymentType"]>
          vertical
          options={[
            {
              label: "Employed in a field related to my training",
              value: "Employed In-Field",
            },
            {
              label: "Employed in a field unrelated to my training",
              value: "Employed Out of Field",
            },
            {
              label: "Self-employed/freelance",
              value: "Self-Employed",
            },
          ]}
          error={errors?.currentEmploymentType?.message}
          {...register("currentEmploymentType")}
        />
      </Form>

      <ButtonArea>
        <Button label="Back" onClick={handleBackClick} />
        <Button primary label="Continue" onClick={handleNextClick} />
      </ButtonArea>
    </Card>
  );
}
