import * as types from "../constants";
import request from "../../utils/request";

export function getResponses(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_RESPONSES });
    return request()
      .get("/survey_results", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_RESPONSES_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            meta: {
              total: dataResponse.headers.total,
              perPage: dataResponse.headers["per-page"],
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_RESPONSES_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
