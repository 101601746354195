/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/default-param-last */
import * as types from "../constants";

const initialState = {
  list: {
    data: [],
    meta: {
      total: 0,
      perPage: 10,
    },
    loading: false,
    error: null,
  },
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_RESPONSES:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case types.GET_RESPONSES_SUCCEED:
      return {
        ...state,
        list: {
          ...state.list,
          data: payload.data,
          meta: payload.meta,
          loading: false,
        },
      };
    case types.GET_RESPONSES_FAIL:
      return {
        ...state,
        list: {
          ...state.list,
          error: payload,
          loading: false,
        },
      };

    default:
      return state;
  }
}
