/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
// import { SurveyResults } from "features/SurveyResults";
import { Header, Sidebar } from "../../components/dashboard";
import Root from "./Root";
import { ROUTES } from "../../utils/constants";
import Programs from "./programs/Programs";
import Cohorts from "./cohorts/Cohorts";
import Campaigns from "./campaigns/Campaigns";
import Responses from "./responses/Responses";
import Tasks from "./tasks/Tasks";
import Branches from "./branches/Branches";
import FinancialAidPrograms from "./financialAidPrograms/FinancialAidPrograms";
import Students from "./students/Students";
import Reporting from "./reporting/Reporting";
// import Surveys from "./surveys/Surveys";
import ProfileSetting from "./profileSetting/ProfileSetting";
import Institution from "./institution/Institution";
import { getAuth, isStudent } from "../../utils/helpers";
import { getAgencies } from "../../redux/actions/reporting";
import { SigningComplete } from "./SigninComplete";

export default function () {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = getAuth();
  const [sidebarMobile, setSidebarMobile] = React.useState(false);
  const storeAgencies = useSelector((store) => store.agencies);

  React.useEffect(() => {
    if (isStudent()) {
      history.push(ROUTES.dashboardStudent);
    }
  }, []);

  React.useEffect(() => {
    if (isStudent()) return;
    dispatch(getAgencies(auth.userData.school_id));
  }, []);

  const handleClickToggle = () => {
    setSidebarMobile(!sidebarMobile);
  };

  return (
    <div
      className={classNames("Dashboard", {
        "--toggle": sidebarMobile,
      })}
    >
      <div className="Dashboard__overlay" onClick={handleClickToggle}>
        <button type="button" onClick={handleClickToggle}>
          ✕
        </button>
      </div>
      <Sidebar data={storeAgencies.data} />
      <div className="Dashboard__inner">
        <Header handleClickToggle={handleClickToggle} />

        <div className="dashboard__wrap">
          <Switch>
            <Route path={ROUTES.dashboard} exact component={Root} />
            <Route
              path="/dashboard/reporting/:reportName"
              component={Reporting}
            />
            <Route path={ROUTES.programs} component={Programs} />
            <Route
              path={ROUTES.financialAidPrograms}
              component={FinancialAidPrograms}
            />
            <Route path={ROUTES.branches} component={Branches} />
            <Route path={ROUTES.cohorts} component={Cohorts} />
            <Route path={ROUTES.campaigns} component={Campaigns} />
            <Route path={ROUTES.responses} component={Responses} />
            <Route path={ROUTES.tasks} component={Tasks} />
            <Route path={ROUTES.institution} component={Institution} />
            <Route path={ROUTES.students} component={Students} />
            {/* <Route
                  path="/dashboard/surveys/:surveyId/survey_results"
                  component={SurveyResults}
                /> */}
            {/* <Route path={ROUTES.surveys} component={Surveys} /> */}
            <Route path={ROUTES.profile} component={ProfileSetting} />
            <Route path={ROUTES.signingComplete} component={SigningComplete} />
          </Switch>
        </div>
      </div>
    </div>
  );
}
