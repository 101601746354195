import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { changeURLWithoutReload } from "../../../utils/helpers";
import { RoleTable } from "../../../components/dashboard/institution";
import { deleteRole, getRoles } from "../../../redux/actions/institution";
import { ROUTES, SORT_NAME } from "../../../utils/constants";
import { openMessage } from "../../../redux/actions/message";
import {
  closeModalMessageDelete,
  openModalMessageDelete,
} from "../../../redux/actions/global";

export default function () {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [params, setParams] = React.useState({});
  const [isParsing, setIsParsing] = React.useState(true);
  const storeRoles = useSelector((store) => store.institution.roles);

  const handleGetRoles = () => {
    dispatch(getRoles(params));
  };
  // only run for the first time visit page
  React.useEffect(() => {
    setParams(queryString.parse(location.search));
    setIsParsing(false);
  }, [location.search]);

  React.useEffect(() => {
    if (isParsing) return;
    dispatch(getRoles(params));
  }, [params, isParsing]);

  const handleChangeURL = (newParams) => {
    changeURLWithoutReload(
      location.pathname,
      `${location.pathname}?${queryString.stringify(newParams)}`
    );
  };

  const handlePageChange = (e) => {
    const newParams = { ...params, page: e.selected + 1 };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleSort = (column, direction) => {
    const newParams = {
      ...params,
      [SORT_NAME]: `${column} ${direction}`,
      page: 1,
    };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleDelete = (rowData) => async () => {
    try {
      await dispatch(deleteRole(rowData.id));
      dispatch(
        openMessage({
          title: `${rowData.name} has been deleted.`,
          type: "success",
        })
      );
      dispatch(closeModalMessageDelete());
      if (storeRoles.data.length === 1 && params.page > 1) {
        setParams({ ...params, page: params.page - 1 });
      }
      handleGetRoles();
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleToggleModalDelete = (rowData) => () => {
    dispatch(
      openModalMessageDelete({
        onDelete: handleDelete(rowData),
      })
    );
  };

  const handleEdit = (selectedItem) => () => {
    history.push(ROUTES.addRole, selectedItem.id);
  };

  return (
    <RoleTable
      storeRoles={storeRoles}
      onPageChange={handlePageChange}
      params={params}
      onSort={handleSort}
      onDelete={handleToggleModalDelete}
      onEdit={handleEdit}
    />
  );
}
