import React from "react";
import { Switch, Route } from "react-router-dom";
import { Title } from "../../../components/dashboard";
import { ROUTES } from "../../../utils/constants";
import Root from "./Root";
import Form from "./Form";

export default function () {
  return (
    <div className="Programs dashboard__item">
      <Title title="Programs" />
      <Switch>
        <Route path={ROUTES.programs} component={Root} exact />
        <Route path={ROUTES.addPrograms} component={Form} exact />
      </Switch>
    </div>
  );
}
