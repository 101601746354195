import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { openMessage } from "redux/actions/message";
import { useDispatch } from "react-redux";
import { Button, Form, RadioGroup, TextInput } from "features-components";
import { DateInputSchema } from "lib/zod";
import { getUserData } from "utils/helpers";
import moment from "moment";
import { useAttendanceDetail, useMutationAttendance } from "./api";
import "./styles.scss";

export default function () {
  const userData = getUserData();
  const schema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    attended: z.string(),
    fullName: z.string().refine((val) => val === userData?.full_name, {
      message: `Full Name must be ${userData?.full_name}`,
    }),
    dateOfSignature: DateInputSchema,
    attendedAt: DateInputSchema,
  });
  type Inputs = z.infer<typeof schema>;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
  });
  const dispatch = useDispatch();
  const { mutate, isLoading: isLoadingAttendance } = useMutationAttendance();

  const { data, isLoading, refetch } = useAttendanceDetail();
  const attendanceData = data?.attendance;
  const taskData = data?.task;
  const isCompletedForm = ["completed", "rejected"].includes(
    taskData?.status || ""
  );

  useEffect(() => {
    setValue("dateOfSignature", moment().format("YYYY-MM-DD"));
  }, []);

  useEffect(() => {
    if (userData) {
      setValue("firstName", userData.first_name);
      setValue("lastName", userData.last_name);
    }
  }, [userData]);

  useEffect(() => {
    if (attendanceData?.attendedAt) {
      setValue(
        "attendedAt",
        moment(attendanceData.attendedAt).format("YYYY-MM-DD")
      );
    }

    if (attendanceData?.attended !== undefined) {
      setValue("attended", attendanceData.attended.toString());
    }
  }, [attendanceData]);

  useEffect(() => {
    if (isCompletedForm) {
      setValue("fullName", userData.full_name);
    }
  }, [isCompletedForm, userData]);

  const handleSubmitForm = async () => {
    await handleSubmit((formData) => {
      mutate(
        {
          isAttendance: formData.attended === "true",
        },
        {
          onSuccess: () => {
            refetch();
            dispatch(
              openMessage({
                title: "Attendance has been successfully updated.",
                type: "success",
              }) as any
            );
          },
          onError: () => {
            dispatch(
              openMessage({
                title: "Something went wrong. Please try again.",
                type: "error",
              }) as any
            );
          },
        }
      );
    })();
  };

  return (
    <div className="Attendance">
      <Form>
        <h5>Attendance Form</h5>
        <div className="Attendance__input-group">
          <div>
            <TextInput
              label="First Name"
              placeholder="First Name"
              disabled
              {...register("firstName")}
            />
          </div>
          <div>
            <TextInput
              label="Last Name"
              placeholder="Last Name"
              disabled
              {...register("lastName")}
            />
          </div>
        </div>
        <p className="text text--small">
          {`Please complete the attendance attestation form below to confirm your
          attendance for our class held on ${moment(
            attendanceData?.attendedAt
          ).format("MMMM Do, YYYY")} for the program 
          ${taskData?.program?.name}. Your submission helps our administrators
          keep accurate records of student attendance as required by state law.
          Failure to submit the student attendance attestation form may result
          in your attendance record being marked as 'absent' and can negatively
          impact your graduation status.`}
        </p>
        <p className="text text--small">
          To submit your attendance, please follow the instructions below:
        </p>
        <TextInput
          type="date"
          label="I attended class on this day"
          placeholder="YYYY-MM-DD"
          disabled
          error={errors?.attendedAt?.message}
          {...register("attendedAt")}
        />
        <div className="Attendance__radio">
          <RadioGroup<Inputs["attended"]>
            vertical
            align="left"
            label="Select one of the options below:"
            disabled={isLoading || isCompletedForm || isLoadingAttendance}
            options={[
              {
                label: "I confirm that I attended class today.",
                value: "true",
              },
              {
                label: "I confirm that I did not attend class today.",
                value: "false",
              },
            ]}
            error={errors?.attended?.message}
            {...register("attended")}
          />
        </div>
        <p className="text text--small">
          By typing my name and clicking the "COMPLETE" button below, I attest
          that this information is true, accurate and complete to the best of my
          knowledge and I understand that any falsification, omission or
          concealment of material fact may subject me to administrative action.
        </p>
        <TextInput
          label="TYPE YOUR FULL NAME TO ACCEPT"
          disabled={isLoading || isCompletedForm || isLoadingAttendance}
          error={errors?.fullName?.message}
          {...register("fullName")}
        />
        <br />
        <br />
        <TextInput
          type="date"
          label="DATE OF SIGNATURE"
          placeholder="YYYY-MM-DD"
          disabled
          error={errors?.attendedAt?.message}
          {...register("dateOfSignature")}
        />
        <br />
        <br />
        <div className="Attendance__button-group">
          <Button
            primary
            label={isCompletedForm ? "COMPLETED" : "COMPLETE"}
            onClick={handleSubmitForm}
            disabled={isLoading || isCompletedForm || isLoadingAttendance}
          />
        </div>
      </Form>
    </div>
  );
}
