import React from "react";
import { ModalTaskDetail } from "components/common";
import { useHistory } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { ITask, useListTasks } from "./api";
import "./styles.scss";

export default function () {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [selectedTask, setSelectedTask] = React.useState({});

  const { data } = useListTasks();

  const convertData = (inputData: ITask[]) => {
    const outputData: { [key: string]: ITask[] } = {
      pending: [],
      in_review: [],
      completed: [],
      rejected: [],
    };
    inputData.forEach((item) => {
      outputData[item.status].push(item);
    });

    return outputData;
  };

  const boardData = convertData(data || []);

  const renderButtonComplete = (taskData: ITask) => {
    if (taskData.status !== "pending") {
      return null;
    }
    if (taskData.taskType === "attendance") {
      return (
        <button
          type="button"
          onClick={() => {
            history.push(
              `${ROUTES.attendanceStudent}?attendanceId=${taskData.itemable.id}&taskId=${taskData.id}`
            );
          }}
        >
          Complete
        </button>
      );
    }
    if (taskData.taskableUrl) {
      return (
        <button type="button" onClick={() => window.open(taskData.taskableUrl)}>
          Complete
        </button>
      );
    }
    return null;
  };

  return (
    <>
      <div className="board-wrapper">
        {Object.keys(boardData).map((itemStatus) => (
          <div key={itemStatus} className="board-column">
            <div className="board-column__inner">
              <div className="board-column__header">
                <h6>{itemStatus.replace(/_/g, " ")}</h6>
              </div>
              <ul>
                {boardData[itemStatus].map((task) => (
                  <li key={`task-id-${task.id}`}>
                    <h6
                      onClick={() => {
                        setOpen(true);
                        setSelectedTask(task);
                      }}
                    >
                      {task.title}
                    </h6>
                    <p>{task.description}</p>
                    {renderButtonComplete(task)}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
      <ModalTaskDetail
        open={open}
        onClose={() => setOpen(false)}
        data={selectedTask}
        config={{
          showStudentName: true,
          showAdminActions: false,
          showStudentActions: false,
        }}
      />
    </>
  );
}
