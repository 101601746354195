/* eslint-disable no-shadow */
import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { ArrowLeft2 } from "../../common/icons";
import { ROUTES } from "../../../utils/constants";

function DataSync({ onSubmit, isSubmitting, dataSync }) {
  return (
    <div className="DataSync  ">
      <div className="DataSync__header --header">
        <NavLink
          to={ROUTES.integrations}
          className="text--small bold "
          activeClassName="isActive"
          exact
        >
          <ArrowLeft2 />
          Return to Integrations
        </NavLink>
        <h4>Data Sync</h4>
      </div>
      <div className="dataSync  dashboard__item__inner">
        <Form noValidate onSubmit={onSubmit}>
          <div className="form-student__text">
            <p className="text--large bold">Sync Summary</p>
          </div>
          <div className="dataSync__inner form-student__inner">
            <div className="dataSync__inner__status">
              <p className="text--large bold">Status</p>
              <div className="dataSync__inner__status__info">
                {dataSync === "Sync was successful" ? (
                  <p className="text--small">Complete</p>
                ) : (
                  <p className="text--small">
                    Complete, with <span className="alert02 bold">Errors</span>
                  </p>
                )}

                {/* <p className="text--small primary bold">
                  <File /> View and Download Summary #16
                </p> */}
              </div>
              <div className="dataSync__inner__status__progress">
                <span
                  style={
                    dataSync === "Sync was successful" && { width: "100%" }
                  }
                />
              </div>
              <div className="dataSync__inner__status__note">
                <div className="dataSync__inner__status__note__percent">
                  <div>
                    <span className="background-primary" />
                    <p className="text--small bold">100% Success</p>
                  </div>
                  {dataSync !== "Sync was successful" && (
                    <div>
                      <span className="background-alert02" />
                      <p className="text--small bold">10% Validation Errors</p>
                    </div>
                  )}
                </div>
                {/* <div className="dataSync__inner__status__note__date">
                  <p className="text--small">Last sync on 1/11/2021 at 10:38pm</p>
                </div> */}
              </div>
            </div>

            <div className="button-group">
              <button
                className="button button--back"
                type="submit"
                disabled={isSubmitting}
                onClick={onSubmit}
              >
                Resync Data
              </button>
              {/* <button className="button button--primary" type="submit" disabled={isSubmitting}>
                Save
              </button> */}
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

DataSync.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
};

DataSync.defaultProps = {
  isSubmitting: false,
};

export default DataSync;
