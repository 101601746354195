import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { RequiredStringSchema, PhoneNumberSchema } from "lib/zod";
import type { SurveyInfo } from "../api";
import { useSurveyStore } from "../store";
import {
  Card,
  ButtonArea,
  Button,
  Form,
  StepHeader,
  TextInput,
  SelectInput,
} from "../components";

type Props = {
  surveyInfo: SurveyInfo;
  progress?: string;
  onNextClick: () => void;
  onBackClick: () => void;
};

const schema = z.object({
  branchName: z.enum([
    "Air Force",
    "Army",
    "Coast Guard",
    "Marine Corps",
    "Navy",
    "Space Force",
  ]),
  recruitingOfficeAddress: RequiredStringSchema,
  recruitingOfficeCity: RequiredStringSchema,
  recruitingOfficeState: RequiredStringSchema,
  recruitingOfficeZipCode: RequiredStringSchema,
  recruitingOfficePhone: PhoneNumberSchema,
});

type Inputs = z.infer<typeof schema>;

export function MilitaryInformationStep(props: Props) {
  const { surveyInfo, progress, onNextClick, onBackClick } = props;
  const [state, setState] = useSurveyStore((store) => [
    store.militaryInformation,
    store.setMilitaryInformation,
  ]);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: state,
    resolver: zodResolver(schema),
  });

  const handleAddressAutocomplete = (result: AutocompleteAddress) => {
    if (!result) return;
    setValue("recruitingOfficeAddress", result.address);
    setValue("recruitingOfficeCity", result.city);
    setValue("recruitingOfficeState", result.state);
    setValue("recruitingOfficeZipCode", result.zip);
  };

  const handleBackClick = () => {
    setState(getValues());
    onBackClick();
  };

  const handleNextClick = async () => {
    await handleSubmit((data) => {
      setState(data);
      onNextClick();
    })();
  };

  return (
    <Card progress={progress}>
      <StepHeader surveyInfo={surveyInfo} />

      <Form>
        <SelectInput<Inputs, Inputs["branchName"]>
          name="branchName"
          control={control}
          label="Name of military branch"
          options={[
            { label: "Air Force", value: "Air Force" },
            { label: "Army", value: "Army" },
            { label: "Coast Guard", value: "Coast Guard" },
            { label: "Marine Corps", value: "Marine Corps" },
            { label: "Navy", value: "Navy" },
            { label: "Space Force", value: "Space Force" },
          ]}
        />
        <TextInput
          autoComplete
          onPlaceSelected={handleAddressAutocomplete}
          label="Address of the recruiting office"
          placeholder="Address"
          error={errors.recruitingOfficeAddress?.message}
          {...register("recruitingOfficeAddress")}
        />
        <TextInput
          label="City of the recruiting office"
          placeholder="City"
          error={errors.recruitingOfficeCity?.message}
          {...register("recruitingOfficeCity")}
        />
        <TextInput
          label="State of the recruiting office"
          placeholder="State"
          error={errors.recruitingOfficeState?.message}
          {...register("recruitingOfficeState")}
        />
        <TextInput
          label="Zip Code of the recruiting office"
          placeholder="Zip Code"
          error={errors.recruitingOfficeZipCode?.message}
          {...register("recruitingOfficeZipCode")}
        />
        <TextInput
          type="tel"
          label="Recruiting office telephone number"
          placeholder="Telephone Number"
          error={errors.recruitingOfficePhone?.message}
          {...register("recruitingOfficePhone")}
        />
      </Form>

      <ButtonArea>
        <Button label="Back" onClick={handleBackClick} />
        <Button primary label="Continue" onClick={handleNextClick} />
      </ButtonArea>
    </Card>
  );
}
