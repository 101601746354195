import { requestClient } from "lib/api";
import { DateTimeSchema, SocCodeSchema } from "lib/zod";
import qs from "query-string";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import { useSurveyStore } from "../store";

type QueryParams = {
  token: string;
  type?: string;
};

const SurveyInfoSchema = z.object({
  id: z.number(),
  agencies: z.array(z.string()),
  completedAt: DateTimeSchema.nullable(),
  createdAt: DateTimeSchema,
  updatedAt: DateTimeSchema,
  program: z.object({
    id: z.number(),
    name: z.string(),
    socCodes: z.array(SocCodeSchema),
  }),
  school: z.object({
    id: z.number(),
    logo: z.string().optional(),
    name: z.string(),
  }),
  student: z.object({
    id: z.number(),
    email: z.string().email(),
    firstName: z.string(),
    fullName: z.string(),
    lastName: z.string(),
  }),
});

export type SurveyInfo = z.infer<typeof SurveyInfoSchema>;

export function useSurveyInfo() {
  const location = useLocation();
  const { token, type } = qs.parse(location.search) as QueryParams;

  const [setPersonalInformation, setEducationProgramName] = useSurveyStore(
    (store) => [store.setPersonalInformation, store.setEducationProgramName]
  );

  const query = useQuery(["surveyInfo", token], async () => {
    if (type === "common") return undefined;

    const response = await requestClient().get(
      `/surveys/graduate_follow_up/info?token=${token}`
    );

    const data = SurveyInfoSchema.parse(response.data.data);

    setPersonalInformation({
      name: data.student.fullName,
      email: data.student.email,
    });

    setEducationProgramName({ educationProgramName: data?.program?.name });

    return data;
  });

  const isLoading = query.isLoading;
  const isError =
    !query.isLoading && (query.isError || query.data === undefined);
  const data = query.data;

  return { isLoading, isError, data };
}
