import React from "react";
import { useHistory } from "react-router-dom";
import { Edit } from "../../common/icons";
import { ROUTES } from "../../../utils/constants";
import { formatPhoneNumber } from "../../../utils/helpers";
import avatar from "../../../assets/images/userAvatar.svg";

function Profile({ storeProfile = {} }) {
  const history = useHistory();
  const dataProfile = storeProfile.data;

  const handleClickButton = () => {
    history.push(ROUTES.profileSetting);
  };

  const dataStudent = [
    {
      label: "Name",
      value: dataProfile.full_name,
    },
    {
      label: "Email",
      value: dataProfile.email,
    },
    {
      label: "Position",
      value: dataProfile.position,
    },
    {
      label: "Account Password",
      value: "************",
    },
    {
      label: "Avatar",
      value: <img src={dataProfile.avatar || avatar} alt="" />,
    },
    {
      label: "Phone",
      value: formatPhoneNumber(dataProfile.phone),
    },
  ];

  return (
    <div className="Profile dashboard__item__inner ">
      <div className="Profile__inner">
        <div className="info">
          {dataStudent.length &&
            dataStudent.map((item) => (
              <div key={item.label}>
                <p className="text--small gray03">{item.label}</p>
                <p className="text--small bold">{item.value}</p>
              </div>
            ))}
        </div>
        <button
          className="button--icon"
          type="button"
          onClick={handleClickButton}
        >
          <Edit />
          Edit Details
        </button>
      </div>
    </div>
  );
}

export default Profile;
