import React from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import logo from "../../assets/images/logo2.svg";
import { getUserData, removeAuth } from "../../utils/helpers";
import { ArrowRight } from "../common/icons";

function HeaderOnboarding() {
  const history = useHistory();
  const userData = getUserData();

  const handleClickLogout = () => {
    removeAuth();
    history.push("/");
  };

  return (
    <section className="Header-onboarding ">
      <div className="Header-onboarding__inner">
        <img src={logo} alt="" />
        <Dropdown className="Dropdown Dropdown--light Dropdown-small">
          <Dropdown.Toggle as={ButtonGroup}>
            {`${userData.first_name} ${userData.last_name}`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={handleClickLogout}>
              <ArrowRight />
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </section>
  );
}

export default HeaderOnboarding;
