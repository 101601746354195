import React from "react";

function ArrowDown() {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6977 3.29289C11.0882 2.90237 11.7214 2.90237 12.1119 3.29289C12.5024 3.68342 12.5024 4.31658 12.1119 4.70711L7.11189 9.70711C6.72137 10.0976 6.0882 10.0976 5.69768 9.70711L0.697678 4.70711C0.307154 4.31658 0.307154 3.68342 0.697678 3.29289C1.0882 2.90237 1.72137 2.90237 2.11189 3.29289L6.40479 7.58579L10.6977 3.29289Z"
        fill="white"
      />
    </svg>
  );
}

export default ArrowDown;
