import React from "react";

function DashboardIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M1 1L8 1V11H1L1 1ZM19 19H12V9H19V19ZM1 14H8V19H1L1 14ZM19 6H12V1H19V6Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
}

export default DashboardIcon;
