import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "../../../components/dashboard/branches";
import { componentForm, ROUTES } from "../../../utils/constants";
import { openMessage } from "../../../redux/actions/message";
import { createBranch, updateBranch } from "../../../redux/actions/branches";
import { handleErrorsAfterCallingAPI } from "../../../utils/helpers";

const DEFAULT_FORM_ADD_BRANCH = {
  name: "",
  address: "",
  city: "",
  state: "",
  zip_code: "",
  is_main_location: false,
};

export default function () {
  const [form, setForm] = React.useState({ ...DEFAULT_FORM_ADD_BRANCH });
  const [formErrors, setFormErrors] = React.useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const storeCreate = useSelector((store) => store.branches.create);
  const storeUpdate = useSelector((store) => store.branches.update);
  const isEditing = !!history.location.state;

  const isSubmitting = storeCreate.loading || storeUpdate.loading;

  React.useEffect(() => {
    const branchSelected = history.location.state;
    if (!branchSelected) return;
    setForm({
      name: branchSelected.name,
      address: branchSelected.address,
      city: branchSelected.city,
      state: branchSelected.state,
      zip_code: branchSelected.zip_code ? branchSelected.zip_code : "",
      is_main_location: branchSelected.is_main_location,
    });
  }, [history.location.state]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.name) {
      errors.name = "Name is required";
    }
    if (!form.city) {
      errors.city = "city is required";
    }
    if (!form.state) {
      errors.state = "state is required";
    }

    setFormErrors(errors);

    // call api
    if (Object.keys(errors).length) return null;

    // update branch
    if (isEditing) {
      try {
        await dispatch(updateBranch(form, history.location.state.id));
        dispatch(
          openMessage({
            title: "Your branch has been updated",
            type: "success",
          })
        );
        history.push(ROUTES.branches);
      } catch (err) {
        handleErrorsAfterCallingAPI(
          err,
          DEFAULT_FORM_ADD_BRANCH,
          setFormErrors,
          dispatch
        );
      }
    } else {
      // create branch
      try {
        await dispatch(createBranch(form));
        dispatch(
          openMessage({
            title: "Your branch has been created",
            type: "success",
          })
        );
        history.push(ROUTES.branches);
      } catch (err) {
        handleErrorsAfterCallingAPI(
          err,
          DEFAULT_FORM_ADD_BRANCH,
          setFormErrors,
          dispatch
        );
      }
    }
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  const handleChangePlace = (place) => {
    if (!place.address_components || !place.address_components.length) {
      return;
    }

    const newForm = {};
    let street = "";
    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    place.address_components.forEach((component) => {
      const addressType = component.types[0];
      switch (addressType) {
        case "postal_code":
          newForm.zip_code = component[componentForm[addressType]];
          break;
        case "administrative_area_level_1":
          newForm.state = component[componentForm[addressType]];
          break;
        case "locality":
          newForm.city = component[componentForm[addressType]];
          break;
        case "street_number":
          street = `${component[componentForm[addressType]]} ${street}`;
          break;
        case "route":
          street = `${street} ${component[componentForm[addressType]]}`;
          break;
        default:
          break;
      }
    });
    setForm({
      ...newForm,
      address: street,
      name: form.name,
      is_main_location: form.is_main_location,
      branch_programs_attributes: form.branch_programs_attributes,
    });
  };

  const handleFocusPlace = () => {
    setFormErrors({
      ...formErrors,
      city: "",
      state: "",
    });
  };

  const handleChangeAddress = (e) => {
    setForm((prev) => ({
      ...prev,
      address: e.target.value,
    }));
  };

  const handleCheckboxMainLocation = (event) => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };

  return (
    <Form
      form={form}
      formErrors={formErrors}
      onSubmit={handleSubmit}
      onChange={handleChange}
      onFocus={handleFocus}
      handleCheckbox={handleCheckboxMainLocation}
      handleChangePlace={handleChangePlace}
      handleFocusPlace={handleFocusPlace}
      handleChangeAddress={handleChangeAddress}
      isSubmitting={isSubmitting}
      isEditing={isEditing}
    />
  );
}
