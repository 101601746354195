import React from "react";
import collect from "../../assets/icons/icon-collect.png";
import track from "../../assets/icons/icon-track.png";
import report from "../../assets/icons/icon-report.png";

function HowItWorks() {
  return (
    <section className="How-It-works background-black">
      <div id="how-it-works" className="top-section" />
      <div className="container">
        <div className="How-It-works__inner ">
          <div className="white title text-center">
            <h4>How AcademySync Works</h4>
            <p className="text">
              With our automated student outreach campaigns, effortlessly
              collect student outcome data for your annual reports along with
              supporting documentation in the case of an audit by your state
              reporting agency.
            </p>
          </div>
          <div className="How-It-works__group">
            <div className="How-It-works__item">
              <img src={collect} alt="" />
              <p className="text--xlarge bold">Collect</p>
              <p className="text">
                Collect student outcome data seamlessly through automated
                scheduled outreach campaigns customizable to fit the needs of
                your school.
              </p>
            </div>
            <div className="How-It-works__item">
              <img src={track} alt="" />
              <p className="text--xlarge bold">Track</p>
              <p className="text">
                Track response rates for each of your campaigns to ensure the
                highest response rate from your students.
              </p>
            </div>
            <div className="How-It-works__item">
              <img src={report} alt="" />
              <p className="text--xlarge bold">Export</p>
              <p className="text">
                Export compliant student outcome data reports in csv and excel
                format ready to import directly into your state annual reporting
                system.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HowItWorks;
