import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { FormGroup, Dropzone } from "../../common";
import { Edit } from "../../common/icons";
import avatar from "../../../assets/images/userAvatar.svg";

function EditProfile({
  form,
  formErrors,
  onSubmit,
  onChange,
  handleDrop,
  handleChangePhoneNumber,
  onFocus,
  handleBack,
  isSubmitting,
  textButton,
  newAvatar,
  dataProfile,
}) {
  return (
    <div className="EditProfile form-student dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="EditProfile__text">
          <p className="text--xlarge bold">Edit Profile Settings</p>
        </div>
        <div className="EditProfile__inner form-student__inner">
          <div className="input__group">
            <FormGroup
              propsInput={{
                name: "first_name",
                placeholder: "First Name",
                onChange,
                onFocus,
                value: form.first_name,
                disabled: isSubmitting,
              }}
              error={formErrors.first_name}
              label="First Name"
            />
            <FormGroup
              propsInput={{
                name: "last_name",
                placeholder: "Last Name",
                onChange,
                onFocus,
                value: form.last_name,
                disabled: isSubmitting,
              }}
              error={formErrors.last_name}
              label="Last Name"
            />
            <FormGroup
              propsInput={{
                name: "email",
                placeholder: "Email Address",
                type: "email",
                onChange,
                onFocus,
                value: form.email,
                disabled: isSubmitting,
              }}
              error={formErrors.email}
              label="Email Address"
            />
            <FormGroup
              propsInput={{
                name: "position",
                placeholder: "Position",
                onChange,
                onFocus,
                value: form.position,
                disabled: isSubmitting,
              }}
              error={formErrors.position}
              label="Position"
            />
          </div>

          <div className="input__group">
            <FormGroup
              propsInput={{
                name: "password",
                placeholder: "New Password",
                type: "password",
                onChange,
                onFocus,
                value: form.new_password,
                disabled: isSubmitting,
              }}
              error={formErrors.new_password}
              label="New Password"
            />
            <FormGroup
              propsInput={{
                name: "confirm_new_password",
                placeholder: "Confirm New Password",
                type: "password",
                onChange,
                onFocus,
                value: form.confirm_new_password,
                disabled: isSubmitting,
              }}
              error={formErrors.confirm_new_password}
              label="Confirm New Password"
            />
          </div>
          <div className="add-avatar">
            <p className="text--small bold">Avatar</p>
            <div>
              <img
                src={newAvatar || dataProfile.avatar || avatar}
                alt=""
                className="avatar"
              />
              <div className="add_image">
                <Edit />
                <div>
                  <Dropzone
                    handleDrop={handleDrop}
                    accept=".jpg,.png"
                    multiple={false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="input__group">
            <FormGroup
              propsInput={{
                name: "phone",
                placeholder: "Phone Number",
                onChange: handleChangePhoneNumber,
                onFocus,
                value: form.phone,
                disabled: isSubmitting,
              }}
              error={formErrors.phone}
              label="Phone Number"
            />
          </div>
        </div>
        <div className="button-group">
          <button
            className="button button--back"
            type="submit"
            onClick={handleBack}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="submit"
            disabled={isSubmitting}
          >
            {textButton || "Update Profile"}
          </button>
        </div>
      </Form>
    </div>
  );
}

EditProfile.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    position: PropTypes.string,
    confirm_new_password: PropTypes.string,
    new_password: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  handleChangePhoneNumber: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  handleDrop: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  textButton: PropTypes.string,
  newAvatar: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  dataProfile: PropTypes.object,
};

EditProfile.defaultProps = {
  form: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    position: "",
    confirm_new_password: "",
    new_password: "",
  },
  formErrors: {},
  isSubmitting: false,
  textButton: "",
  newAvatar: "",
  dataProfile: {},
};

export default EditProfile;
