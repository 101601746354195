import React from "react";
import { useCompleteSurvey } from "../api";
import type { SurveyInfo } from "../api";
import { Card, StepHeader, Loading, Error } from "../components";
import css from "./SurveyCompleteStep.module.scss";

type Props = {
  surveyInfo: SurveyInfo;
  progress?: string;
};

export function SurveyCompleteStep(props: Props) {
  const { surveyInfo, progress } = props;
  const { isLoading, isError } = useCompleteSurvey();

  if (isLoading) return <Loading />;
  if (isError) return <Error />;

  return (
    <Card progress={progress}>
      <StepHeader
        surveyInfo={surveyInfo}
        title="Survey Completed - Thank you!"
      />
      <p className={css.content}>
        Thank you for taking the time to complete this survey. Your answers will
        help your school report on graduate employment. Have a great day.
      </p>
    </Card>
  );
}
