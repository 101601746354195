import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import css from "./styles.module.scss";

export function Error() {
  return (
    <div className={css.error}>
      <FontAwesomeIcon icon={["fas", "square-exclamation"]} size="2x" />
      <div>
        Oops! Something went wrong. Please contact your school for more help.
      </div>
    </div>
  );
}
