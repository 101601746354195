import React from "react";
import { Switch, Route } from "react-router-dom";
import { Title } from "../../../components/dashboard";
import { ROUTES } from "../../../utils/constants";
import Root from "./Root";
import Form from "./Form";

export default function () {
  return (
    <div className="Profile dashboard__item">
      <Title title="Profile Settings" breadcrumb={false} />
      <Switch>
        <Route path={ROUTES.profile} component={Root} exact />
        <Route path={ROUTES.profileSetting} exact component={Form} />
      </Switch>
    </div>
  );
}
