import * as types from "../constants";
import request from "../../utils/request";

export function getCohorts(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_COHORTS });
    return request()
      .get("/cohorts", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_COHORTS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            meta: {
              total: dataResponse.headers.total,
              perPage: dataResponse.headers["per-page"],
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_COHORTS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getAllCohorts(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_ALL_COHORTS });
    return request()
      .get("/cohorts/all", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_ALL_COHORTS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ALL_COHORTS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createCohort(data) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_COHORT });
    return request()
      .post("/cohorts", { cohort: data })
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_COHORT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_COHORT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function deleteCohort(cohortId) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_COHORT });
    return request()
      .delete(`/cohorts/${cohortId}`)
      .then((dataResponse) => {
        dispatch({
          type: types.DELETE_COHORT_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_COHORT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function updateCohort(data, cohortId) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_COHORT });
    return request()
      .put(`/cohorts/${cohortId}`, { cohort: data })
      .then((dataResponse) => {
        dispatch({
          type: types.UPDATE_COHORT_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_COHORT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
