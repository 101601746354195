import React from "react";
import PropTypes from "prop-types";
import { Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { FormGroup } from "../../common";
import { ROUTES } from "../../../utils/constants";
import AccessLevelTable from "./AccessLevelTable";

function AddRole({
  form,
  formErrors,
  onSubmit,
  onChange,
  onFocus,
  handleCheckbox,
  programPermissions,
  handleChangePermissionProgram,
  branchPermissions,
  handleChangePermissionBranch,
  handleCheckboxAll,
  isEditting,
}) {
  const history = useHistory();
  const storeCreate = useSelector((store) => store.institution.createRole);
  const storeUpdate = useSelector((store) => store.institution.updateRole);
  const storeBuild = useSelector((store) => store.institution.rolesBuild);
  const storeDetail = useSelector((store) => store.institution.roleDetail);
  const loading = storeCreate.loading || storeUpdate.loading;
  const loadingInitData = storeBuild.loading || storeDetail.loading;

  const handleBack = () => {
    history.push(ROUTES.roles);
  };

  const columnsProgram = [
    {
      title: "Programs",
      dataIndex: "name",
      render: (row) => row.program.name,
    },
    {
      title: (
        <div className="checkbox">
          Access All
          <FormGroup
            propsInput={{
              type: "checkbox",
              name: "access_programs",
              onChange: handleCheckboxAll,
              checked: form.access_programs,
            }}
            type="checkbox"
          />
        </div>
      ),
      dataIndex: "access",
      render: (row) => (
        <div className="checkbox">
          Access
          <FormGroup
            propsInput={{
              type: "checkbox",
              name: "access",
              onChange: handleChangePermissionProgram(row, "access"),
              checked: row.access,
            }}
            type="checkbox"
          />
        </div>
      ),
    },
    {
      title: (
        <div className="checkbox">
          Manage All
          <FormGroup
            propsInput={{
              type: "checkbox",
              name: "manage_programs",
              onChange: handleCheckboxAll,
              checked: form.manage_programs,
            }}
            type="checkbox"
          />
        </div>
      ),
      dataIndex: "manage",
      render: (row) => (
        <div className="checkbox">
          Manage
          <FormGroup
            propsInput={{
              type: "checkbox",
              name: "manage",
              onChange: handleChangePermissionProgram(row, "manage"),
              checked: row.manage,
            }}
            type="checkbox"
          />
        </div>
      ),
    },
  ];

  const columnsBranch = [
    {
      title: "Branches",
      dataIndex: "name",
      render: (row) => row.branch.name,
    },
    {
      title: (
        <div className="checkbox">
          Access All
          <FormGroup
            propsInput={{
              type: "checkbox",
              name: "access_branches",
              onChange: handleCheckboxAll,
              checked: form.access_branches,
            }}
            type="checkbox"
          />
        </div>
      ),
      dataIndex: "access",
      render: (row) => (
        <div className="checkbox">
          Access
          <FormGroup
            propsInput={{
              type: "checkbox",
              name: "access",
              onChange: handleChangePermissionBranch(row, "access"),
              checked: row.access,
            }}
            type="checkbox"
          />
        </div>
      ),
    },
    {
      title: (
        <div className="checkbox">
          Manage All
          <FormGroup
            propsInput={{
              type: "checkbox",
              name: "manage_branches",
              onChange: handleCheckboxAll,
              checked: form.manage_branches,
            }}
            type="checkbox"
          />
        </div>
      ),
      dataIndex: "manage",
      render: (row) => (
        <div className="checkbox">
          Manage
          <FormGroup
            propsInput={{
              type: "checkbox",
              name: "manage",
              onChange: handleChangePermissionBranch(row, "manage"),
              checked: row.manage,
            }}
            type="checkbox"
          />
        </div>
      ),
    },
  ];

  return (
    <div className="AddRole dashboard__item__inner">
      {loadingInitData ? (
        <div className="AddRole__loading">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <Form noValidate onSubmit={onSubmit}>
          <div className="AddRole__text">
            <p className="text--xlarge bold">
              {isEditting ? "Edit" : "Add"} Role
            </p>
          </div>
          <div className="AddRole__inner">
            <FormGroup
              propsInput={{
                name: "name",
                placeholder: "Role Name",
                onChange,
                onFocus,
                value: form.name,
              }}
              error={formErrors.name}
              label="Role Name"
            />
            <div className="checkbox-group institution">
              <div className="checkbox-group__inner">
                <p className="bold">Institution</p>
                <div>
                  <div className="checkbox">
                    Access
                    <FormGroup
                      propsInput={{
                        type: "checkbox",
                        name: "access_school",
                        onChange: handleCheckbox,
                        checked: form.access_school,
                      }}
                      type="checkbox"
                    />
                  </div>
                  <div className="checkbox">
                    Manage
                    <FormGroup
                      propsInput={{
                        type: "checkbox",
                        name: "manage_school",
                        onChange: handleCheckbox,
                        checked: form.manage_school,
                      }}
                      type="checkbox"
                    />
                  </div>
                </div>
              </div>
            </div>
            <AccessLevelTable
              columns={columnsProgram}
              data={programPermissions}
            />
            <AccessLevelTable
              columns={columnsBranch}
              data={branchPermissions}
            />
            <div className="checkbox-group">
              <div className="checkbox-group__inner">
                <p className="bold">Cohorts</p>
                <div>
                  <div className="checkbox">
                    Access
                    <FormGroup
                      propsInput={{
                        type: "checkbox",
                        name: "access_cohorts",
                        onChange: handleCheckbox,
                        checked: form.access_cohorts,
                      }}
                      type="checkbox"
                    />
                  </div>
                  <div className="checkbox">
                    Manage
                    <FormGroup
                      propsInput={{
                        type: "checkbox",
                        name: "manage_cohorts",
                        onChange: handleCheckbox,
                        checked: form.manage_cohorts,
                      }}
                      type="checkbox"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="checkbox-group">
              <div className="checkbox-group__inner">
                <p className="bold">Students</p>
                <div>
                  <div className="checkbox">
                    Access
                    <FormGroup
                      propsInput={{
                        type: "checkbox",
                        name: "access_students",
                        onChange: handleCheckbox,
                        checked: form.access_students,
                      }}
                      type="checkbox"
                    />
                  </div>
                  <div className="checkbox">
                    Manage
                    <FormGroup
                      propsInput={{
                        type: "checkbox",
                        name: "manage_students",
                        onChange: handleCheckbox,
                        checked: form.manage_students,
                      }}
                      type="checkbox"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="button-group">
            <button
              className="button button--back"
              type="submit"
              onClick={handleBack}
              disabled={loading}
            >
              Cancel
            </button>
            <button
              className="button button--primary"
              type="submit"
              disabled={loading}
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </div>
  );
}

AddRole.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    name: PropTypes.string,
    access_school: PropTypes.bool,
    manage_school: PropTypes.bool,
    access_cohorts: PropTypes.bool,
    manage_cohorts: PropTypes.bool,
    access_students: PropTypes.bool,
    manage_students: PropTypes.bool,
    access_branches: PropTypes.bool,
    manage_branches: PropTypes.bool,
    access_programs: PropTypes.bool,
    manage_programs: PropTypes.bool,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  programPermissions: PropTypes.array,
  handleChangePermissionProgram: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  branchPermissions: PropTypes.array,
  handleChangePermissionBranch: PropTypes.func.isRequired,
  handleCheckboxAll: PropTypes.func.isRequired,
  isEditting: PropTypes.bool,
};

AddRole.defaultProps = {
  form: {
    name: "",
    access_school: true,
    manage_school: true,
    access_cohorts: false,
    manage_cohorts: false,
    access_students: false,
    manage_students: false,
    access_branches: true,
    manage_branches: true,
    access_programs: true,
    manage_programs: true,
  },
  formErrors: {},
  programPermissions: [],
  branchPermissions: [],
  isEditting: false,
};

export default AddRole;
