import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { openMessage } from "redux/actions/message";
import queryString from "query-string";
import { approveTask, getTasks, rejectTask } from "redux/actions/tasks";
import TasksTable from "components/dashboard/tasks/TasksTable";
import { ModalTaskDetail } from "components/common";
import { changeURLWithoutReload } from "../../../utils/helpers";
import { SORT_NAME } from "../../../utils/constants";

function Root() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [params, setParams] = React.useState({});
  const [selectedTask, setSelectedTask] = React.useState({});
  const [isParsing, setIsParsing] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const storeTasks = useSelector((store) => store.tasks);

  // only run for the first time visit page
  React.useEffect(() => {
    setParams(queryString.parse(location.search));
    setIsParsing(false);
  }, [location.search]);

  React.useEffect(() => {
    if (isParsing) return;
    dispatch(getTasks(params));
  }, [params, isParsing]);

  const handleChangeURL = (newParams) => {
    changeURLWithoutReload(
      location.pathname,
      `${location.pathname}?${queryString.stringify(newParams)}`
    );
  };

  const handlePageChange = (e) => {
    const newParams = { ...params, page: e.selected + 1 };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleSort = (column, direction) => {
    const newParams = {
      ...params,
      [SORT_NAME]: `${column} ${direction}`,
      page: 1,
    };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleChangeFilter = (selectedItem, name) => {
    const newParams = {
      ...params,
      page: 1,
      [name]: selectedItem.value,
    };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleApprove = async () => {
    try {
      await dispatch(approveTask(selectedTask.student?.id, selectedTask.id));
      setOpen(false);
      dispatch(
        openMessage({
          title: "Approve task successfully!",
          type: "success",
        })
      );
      await dispatch(getTasks(params));
    } catch (error) {
      console.error("Approve has an error: ", error);
    }
  };

  const handleReject = async () => {
    try {
      await dispatch(rejectTask(selectedTask.student?.id, selectedTask.id));
      setOpen(false);
      dispatch(
        openMessage({
          title: "Reject task successfully!",
          type: "success",
        })
      );
      await dispatch(getTasks(params));
    } catch (error) {
      console.error("Approve has an error: ", error);
    }
  };

  return (
    <>
      <TasksTable
        store={storeTasks.list}
        onPageChange={handlePageChange}
        params={params}
        onSort={handleSort}
        onChangeFilter={handleChangeFilter}
        onClickStudentName={(dataRow) => {
          setOpen(true);
          setSelectedTask(dataRow);
        }}
      />
      <ModalTaskDetail
        open={open}
        onClose={() => setOpen(false)}
        data={selectedTask}
        config={{ showStudentName: true, showAdminActions: true }}
        onApprove={handleApprove}
        onReject={handleReject}
        loading={storeTasks.approve.loading || storeTasks.reject.loading}
      />
    </>
  );
}

export default Root;
