import React from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../utils/constants";
import { isAuthenticated } from "../utils/helpers";

const withRedirect = (Component) =>
  function (props) {
    const history = useHistory();
    const isAuth = isAuthenticated();

    if (isAuth) {
      history.push(ROUTES.dashboard);
    }

    return <Component {...props} />;
  };

export default withRedirect;
