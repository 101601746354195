import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Folder } from "components/common/icons";
import { SEARCH_NAME, SORT_NAME } from "../../../utils/constants";
import { FormGroup } from "../../common";
import Table from "../../common/Table";

function ResponsesTable({
  store = { data: [], meta: {}, loading: false, error: null },
  onPageChange = () => null,
  params = {},
  onSort = () => {},
  onSearch = () => {},
  onSendSurvey = () => {},
}) {
  const columns = [
    {
      title: "Student Name",
      dataIndex: "student",
      sortAble: true,
      render: (row) => (
        <Link
          className="responses__student__link"
          to={`/dashboard/students/${row.student?.id}/placement`}
        >
          {row.student?.full_name}
        </Link>
      ),
    },
    {
      title: "Program Name",
      dataIndex: "program_name",
      sortAble: true,
      render: (row) => row.program?.name,
    },
    {
      title: "Sent At",
      dataIndex: "created_at",
      sortAble: true,
      render: (row) => moment(row.created_at).utc().format("MM/DD/YYYY"),
    },
    {
      title: "Completed At",
      dataIndex: "completed_at",
      sortAble: true,
      render: (row) =>
        row.completed_at
          ? moment(row.completed_at).utc().format("MM/DD/YYYY")
          : "",
    },
    {
      title: "Status",
      dataIndex: "status",
      sortAble: true,
      render: (row) => (
        <span className={`responses__status responses__status--${row.status}`}>
          {row.status}
        </span>
      ),
    },
    {
      title: "Outcome",
      dataIndex: "outcome",
      render: (row) => row?.surveyable?.outcome,
    },
    {
      title: "Documentation",
      dataIndex: "surveyable",
      render: (row) => (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {row?.surveyable?.files.map((item) => (
            <a
              href={item?.url}
              key={item?.filename}
              target="_blank"
              rel="noreferrer"
              className="responses__link"
            >
              <div className="responses__tooltip">
                <span>{item?.filename}</span>
              </div>
              <Folder />
            </a>
          ))}
        </>
      ),
    },
  ];

  return (
    <div className="Responses__inner dashboard__item__inner">
      <div className="filter">
        <div className="filter__left">
          <FormGroup
            propsInput={{
              name: "name",
              className: "inputSearch",
              placeholder: "What are you looking for?",
              onChange: onSearch,
              value: params[SEARCH_NAME] || "",
            }}
          />
        </div>
        <button
          className="button--icon shrink-0"
          type="button"
          onClick={onSendSurvey}
        >
          <FontAwesomeIcon icon={["fas", "share"]} size="1x" />
          Share Survey
        </button>
      </div>
      <Table
        loading={store.loading}
        columns={columns}
        data={store.data}
        tableProps={{ striped: true }}
        sort={params[SORT_NAME] || ""}
        onSort={onSort}
        paginationProps={{
          itemsLength: store.meta.total,
          perPage: store.meta.perPage,
          onPageChange,
          activePage: params.page || 1,
        }}
      />
    </div>
  );
}

export default ResponsesTable;
