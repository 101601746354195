import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { getResponses } from "redux/actions/responses";
import ModalSendSurvey from "components/dashboard/responses/ModalSendSurvey";
import { openMessage } from "redux/actions/message";
import { sendSurvey } from "redux/actions/surveys";
import { getAllPrograms } from "redux/actions/programs";
import { getUsers } from "redux/actions/users";
import { getFilterCohorts } from "redux/actions/filters";
import { changeURLWithoutReload, getHostUrl } from "../../../utils/helpers";
import { ResponsesTable } from "../../../components/dashboard/responses";
import {
  GraduateFollowUp,
  ROUTES,
  SEARCH_NAME,
  SORT_NAME,
} from "../../../utils/constants";

const FORM_SURVEY = {
  is_type: GraduateFollowUp,
  cohort_id: "",
  program_id: "",
  user_id: "",
};

function Root() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [params, setParams] = React.useState({});
  const [isParsing, setIsParsing] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [formSurvey, setFormSurvey] = React.useState({ ...FORM_SURVEY });

  const storePrograms = useSelector((store) => store.programs.all);
  const storeCohorts = useSelector((store) => store.filters.cohorts);
  const storeSurvey = useSelector((store) => store.surveys.surveyStudent);
  const storeUsers = useSelector((store) => store.users);
  const storeResponses = useSelector((store) => store.responses.list);
  const isSubmitting = storeSurvey.loading;

  // only run for the first time visit page
  React.useEffect(() => {
    setParams(queryString.parse(location.search));
    setIsParsing(false);
  }, [location.search]);

  React.useEffect(() => {
    if (isParsing) return;
    dispatch(getResponses(params));
  }, [params, isParsing]);

  React.useEffect(() => {
    dispatch(getAllPrograms());
    dispatch(getUsers());
  }, []);

  React.useEffect(() => {
    dispatch(
      getFilterCohorts({
        program_ids: formSurvey.program_id ? [formSurvey.program_id] : [],
      })
    );
    // reset cohort when program change
    setFormSurvey((prev) => ({ ...prev, cohort_id: "" }));
  }, [formSurvey.program_id]);

  const handleChangeURL = (newParams) => {
    changeURLWithoutReload(
      location.pathname,
      `${location.pathname}?${queryString.stringify(newParams)}`
    );
  };

  const handlePageChange = (e) => {
    const newParams = { ...params, page: e.selected + 1 };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleSort = (column, direction) => {
    const newParams = {
      ...params,
      [SORT_NAME]: `${column} ${direction}`,
      page: 1,
    };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleSearch = (e) => {
    const newParams = {
      ...params,
      page: 1,
      [SEARCH_NAME]: e.target.value,
    };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleShareSurvey = async () => {
    try {
      const res = await dispatch(sendSurvey(formSurvey));
      navigator.clipboard.writeText(
        `${getHostUrl()}${ROUTES.surveySteps}?token=${res?.token}&type=common`
      );
      setOpenModal(false);
      dispatch(
        openMessage({
          title: "Survey Link Copied to Clipboard",
          type: "success",
        })
      );
      setFormSurvey({ ...FORM_SURVEY });
    } catch (error) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleChange = (selectedItem, name) => {
    setFormSurvey({ ...formSurvey, [name]: selectedItem.value });
  };

  return (
    <>
      <ResponsesTable
        store={storeResponses}
        onPageChange={handlePageChange}
        params={params}
        onSort={handleSort}
        onSearch={handleSearch}
        onSendSurvey={() => setOpenModal(true)}
      />
      <ModalSendSurvey
        open={openModal}
        onHide={() => setOpenModal(false)}
        onSend={handleShareSurvey}
        onChange={handleChange}
        formSurvey={formSurvey}
        dataPrograms={storePrograms.data}
        dataAdmins={storeUsers.data}
        isSubmitting={isSubmitting}
        dataCohorts={storeCohorts.data}
        isLoadingCohorts={storeCohorts.loading}
      />
    </>
  );
}

export default Root;
