import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import information from "../../../../assets/images/information-import.png";
import enrollment from "../../../../assets/images/enrollments-import.png";
import placement from "../../../../assets/images/placements-import.png";

function Step1({ onSubmit, onSelectType, form }) {
  return (
    <div className=" Step step1 dashboard__item__inner">
      <div className="step1__inner ">
        <p className="text--large bold">Select the Type of Import</p>
        <div className="type-of-import">
          <div
            onClick={onSelectType("general")}
            role="button"
            tabIndex="0"
            className={classNames({
              active: form.is_type === "general",
            })}
          >
            <img src={information} alt="" />
            <div>
              <p className="text bold title">General Information Import</p>
              <p className="text--small">
                Used to create new student records by uploading a csv file with
                general information fields.
              </p>
            </div>
          </div>
          <div
            onClick={onSelectType("enrollments")}
            role="button"
            tabIndex="0"
            className={classNames({
              active: form.is_type === "enrollments",
            })}
          >
            <img src={enrollment} alt="" />
            <div>
              <p className="text bold title">Enrollments Import</p>
              <p className="text--small">
                Used to add enrollment records to existing students by uploading
                a csv file with student email and enrollment fields.
              </p>
            </div>
          </div>
          <div
            onClick={onSelectType("placements")}
            role="button"
            tabIndex="0"
            className={classNames({
              active: form.is_type === "placements",
            })}
          >
            <img src={placement} alt="" />
            <div>
              <p className="text bold title">Placements Import</p>
              <p className="text--small">
                Used to add placement records to existing students by uploading
                a csv file with student email and placement fields.
              </p>
            </div>
          </div>
        </div>
        <div className="button__group">
          <button
            className="button button--primary"
            type="submit"
            onClick={onSubmit}
            disabled={!form.is_type}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

Step1.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSelectType: PropTypes.func.isRequired,
  form: PropTypes.shape({
    is_type: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    file: PropTypes.array,
  }),
};

Step1.defaultProps = {
  form: {
    is_type: "",
    file: [],
  },
};

export default Step1;
