/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

function ModalAddDocument({
  type,
  isOpen,
  onBack,
  onSubmit,
  count,
  isSubmitting,
}) {
  return (
    <Modal
      show={isOpen}
      onHide={onBack}
      backdropClassName="wrap-modal"
      dialogClassName="Modal Modal__delete__document"
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="bootstrap">
        <Modal.Header>
          <Modal.Title>
            {`You are attempting to import ${count} new ${
              type === "general"
                ? "student"
                : type === "enrollments"
                ? "enrollment"
                : "placement"
            } records.`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button
            className="button button--back"
            type="button"
            onClick={onBack}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="button"
            onClick={onSubmit}
            disabled={isSubmitting}
          >
            Confirm Import
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

ModalAddDocument.propTypes = {
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  count: PropTypes.number,
  isSubmitting: PropTypes.bool,
  type: PropTypes.string,
};

ModalAddDocument.defaultProps = {
  isOpen: false,
  count: 0,
  isSubmitting: false,
  type: "",
};

export default ModalAddDocument;
