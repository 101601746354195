import React from "react";
import type { MouseEventHandler } from "react";
import type { SurveyInfo, PlacementInfo } from "../api";
import { Card, ButtonArea, Button, SchoolLogo } from "../components";
import css from "./StartPage.module.scss";

type Props = {
  surveyInfo: SurveyInfo;
  placementInfo?: PlacementInfo;
  progress?: string;
  onNextClick: MouseEventHandler<HTMLButtonElement>;
};

export function StartPage(props: Props) {
  const { surveyInfo, placementInfo, progress, onNextClick } = props;
  const changeRequest = placementInfo?.surveyResult?.changeRequests?.find(
    (r) => r.status === "pending"
  );

  return (
    <Card progress={progress}>
      <header className={css.header}>
        <SchoolLogo school={surveyInfo.school} />
        <h1 className={css.schoolName}>{surveyInfo.school.name}</h1>
        <h2 className={css.surveyTitle}>Graduate Follow-Up Survey</h2>
      </header>

      <p className={css.surveyInformation}>
        Thank you for taking the time to complete this survey. The survey will
        ask you about your work-related experiences since graduation. Please
        provide as much information as possible. Your responses will help your
        school report on graduate employment.
      </p>

      {changeRequest && (
        <div className={css.changeRequest}>
          <h2>Change request notes:</h2>
          {changeRequest.notes}
        </div>
      )}

      <ButtonArea single>
        <Button primary label="Start Survey" onClick={onNextClick} />
      </ButtonArea>
    </Card>
  );
}
