import React from "react";
import { NavLink } from "react-router-dom";
import { ArrowLeft2 } from "../../../common/icons";
import { ROUTES } from "../../../../utils/constants";
import pullData from "../../../../assets/images/pull-data.svg";
import pullPushData from "../../../../assets/images/pull-push-data.svg";

function Instructions() {
  return (
    <div className="Instructions  ">
      <div className="Instructions__header --header">
        <NavLink
          to={ROUTES.integrations}
          className="text--small bold "
          activeClassName="isActive"
          exact
        >
          <ArrowLeft2 />
          Return
        </NavLink>
        <h4>Integration Instructions</h4>
        <p className="text--small">
          Paragraph about how the student import tool allows administrators to
          create new students and add enrollments and placements to existing
          students. Duis aute irure dolor in reprehenderit in voluptate velit
          esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
          cupidatat non proident.
        </p>
        <p className="text--small">
          Paragraph about CSV. Lorem ipsum dolor sit amet, consectetur
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
          ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute
          irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
          fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.
        </p>
        <p className="text--small --note">
          <span className="bold">NOTE:</span> Make sure to set up relevant
          branches, programs and cohorts before you run the import.
        </p>
      </div>
      <div className="list-types  dashboard__item__inner">
        <div className="select-type__inner ">
          <p className="text--large bold">Types of Imports</p>
          <div className="type-of-import">
            <div>
              <img src={pullData} alt="" />
              <div>
                <p className="text bold title">Pull Data</p>
                <p className="text--small">
                  Pull student data from Salesforce directly into AcademySync by
                  sed do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.
                </p>
                {/* <NavLink
                  to={ROUTES.pullingDataSalesforce}
                  className="text--small bold "
                  activeClassName="isActive"
                  exact
                >
                  View Import Instructions
                </NavLink> */}
              </div>
            </div>
            <div>
              <img src={pullPushData} alt="" />
              <div>
                <p className="text bold title">Pull & Push Data</p>
                <p className="text--small">
                  Pull student data from Salesforce and push enrollment,
                  completion, and placement data from AcademySync into
                  Salesforce incididunt ut labore et dolore magna.
                </p>
                {/* <NavLink
                  to={ROUTES.pullingAndPushingDataSalesforce}
                  className="text--small bold "
                  activeClassName="isActive"
                  exact
                >
                  View Import Instructions
                </NavLink> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Instructions;
