import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import { ROUTES } from "../../../utils/constants";
import { Loading } from "../../common";

function Header({ storeInstitution = {} }) {
  const location = useLocation();

  return (
    <div className=" Header-institution">
      <div className=" Header-institution__title">
        {storeInstitution.loading ? (
          <Loading />
        ) : (
          <div>
            <img src={storeInstitution.data.logo} alt="" />
            <h4>{storeInstitution.data.name}</h4>
          </div>
        )}
      </div>
      <div className="Header-institution__taps">
        <NavLink
          to={ROUTES.institution}
          className={classNames("text--small", {
            isActive: location.pathname === ROUTES.editInstitution,
          })}
          activeClassName="isActive"
          exact
        >
          General
        </NavLink>
        <NavLink
          to={ROUTES.account}
          className={classNames("text--small", {
            isActive: location.pathname === ROUTES.account,
          })}
          activeClassName="isActive"
        >
          Account
        </NavLink>
        <NavLink
          to={ROUTES.administrators}
          className={classNames("text--small", {
            isActive: location.pathname === ROUTES.addAdministrator,
          })}
          activeClassName="isActive"
          exact
        >
          Administrators
        </NavLink>
        <NavLink
          to={ROUTES.roles}
          className={classNames("text--small", {
            isActive: location.pathname === ROUTES.addRole,
          })}
          activeClassName="isActive"
        >
          Roles & Permissions
        </NavLink>
        <NavLink
          to={ROUTES.integrations}
          className={classNames("text--small", {
            isActive: location.pathname === ROUTES.integrations,
          })}
          activeClassName="isActive"
        >
          Integrations
        </NavLink>
      </div>
    </div>
  );
}

export default Header;
