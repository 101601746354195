import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  HeaderInstitution,
  PullingAndPushingData,
} from "../../../components/dashboard/institution";
import { ROUTES } from "../../../utils/constants";
import EditInstitution from "./EditInstitution";
import AdministratorsTable from "./AdministratorsTable";
import Root from "./Root";
import Account from "./Account";
import AddAdministrator from "./AddAdministrator";
import AddRole from "./AddRole";
import RoleTable from "./RoleTable";
import Integrations from "./Integrations";
import DataSync from "./salesforce/DataSync";
import Instructions from "./salesforce/Instructions";
import PullingData from "./salesforce/PullingData";
import { getInstitution } from "../../../redux/actions/institution";
import { getAuth } from "../../../utils/helpers";
import SalesforceStep from "./salesforce/SalesforceStep";
import HubSpotStep from "./hubspot/HubSpotStep";
import DataSyncHubSpot from "./hubspot/DataSyncHubSpot";

export default function () {
  const location = useLocation();
  const dispatch = useDispatch();
  const auth = getAuth();
  const storeInstitution = useSelector(
    (store) => store.institution.institution
  );

  React.useEffect(() => {
    if (auth && auth.userData && auth.userData.school_id)
      dispatch(getInstitution({}, auth.userData.school_id));
  }, []);

  return (
    <div className="Institution dashboard__item">
      {!location.pathname.includes("salesforce") &&
        !location.pathname.includes("hubspot") && (
          <HeaderInstitution storeInstitution={storeInstitution} />
        )}
      <Switch>
        <Route
          path={ROUTES.editInstitution}
          exact
          component={EditInstitution}
        />
        <Route
          path={ROUTES.administrators}
          component={AdministratorsTable}
          exact
        />
        <Route
          path={ROUTES.addAdministrator}
          exact
          component={AddAdministrator}
        />
        <Route path={ROUTES.account} exact component={Account} />
        <Route path={ROUTES.roles} component={RoleTable} exact />
        <Route path={ROUTES.addRole} exact component={AddRole} />
        <Route path={ROUTES.addRole} exact component={AddRole} />
        <Route path={ROUTES.integrations} exact component={Integrations} />
        <Route path={ROUTES.dataSyncSalesforce} exact component={DataSync} />
        <Route
          path={ROUTES.instructionSalesforce}
          exact
          component={Instructions}
        />
        <Route
          path={ROUTES.pullingDataSalesforce}
          exact
          component={PullingData}
        />
        <Route
          path={ROUTES.pullingAndPushingDataSalesforce}
          exact
          component={PullingAndPushingData}
        />
        <Route
          path={ROUTES.integrationsStepSalesforce}
          exact
          component={SalesforceStep}
        />
        <Route
          path={ROUTES.integrationsStepHubSpot}
          exact
          component={HubSpotStep}
        />
        <Route
          path={ROUTES.dataSyncHubSpot}
          exact
          component={DataSyncHubSpot}
        />
        <Route path={ROUTES.institution} component={Root} />
      </Switch>
    </div>
  );
}
