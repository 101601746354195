import * as types from "../constants";
import request from "../../utils/request";

export function getFilterBranches() {
  return (dispatch) => {
    dispatch({ type: types.GET_FILTER_BRANCHES });
    return request()
      .get("/dashboard/branches")
      .then((dataResponse) => {
        dispatch({
          type: types.GET_FILTER_BRANCHES_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_FILTER_BRANCHES_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getFilterPrograms() {
  return (dispatch) => {
    dispatch({ type: types.GET_FILTER_PROGRAMS });
    return request()
      .get("/dashboard/programs")
      .then((dataResponse) => {
        dispatch({
          type: types.GET_FILTER_PROGRAMS_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_FILTER_PROGRAMS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getFilterCohorts(data) {
  return (dispatch) => {
    dispatch({ type: types.GET_FILTER_COHORTS });
    return request()
      .get("/dashboard/cohorts", { params: data })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_FILTER_COHORTS_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_FILTER_COHORTS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function setSelectedFilter(data) {
  return (dispatch) => {
    dispatch({ type: types.SET_SELECTED_FILTER, payload: data });
  };
}
