import create from "zustand";
import { persist } from "zustand/middleware";

type OptionsYesNo = "YES" | "NO";
type OptionsReasonForNotSeekingEmployment =
  | "Continuing Education"
  | "Military"
  | "Not Seeking Employment for Personal Reasons";
type OptionsRating =
  | "Very Satisfied"
  | "Somewhat Satisfied"
  | "Neither Satisfied nor Dissatisfied"
  | "Somewhat Dissatisfied"
  | "Very Dissatisfied";
type OptionsEmploymentType =
  | "Employed In-Field"
  | "Employed Out of Field"
  | "Self-Employed";
type OptionsSelfEmploymentType = "Entrepreneur" | "Freelance";
type OptionsJobDuration = "Less than 3 months" | "3-6 months" | "6+ months";
type OptionsSalaryType = "Annual" | "Hourly" | "Weekly";
type OptionsEmploymentConcurrency = "Single" | "Concurrent";
type OptionsMilitaryBranch =
  | "Air Force"
  | "Army"
  | "Coast Guard"
  | "Marine Corps"
  | "Navy"
  | "Space Force";

export type SurveyState = {
  personalInformation: {
    name?: string;
    email?: string;
  };
  setPersonalInformation: (props: SurveyState["personalInformation"]) => void;

  currentlyEmployed?: OptionsYesNo;
  setCurrentlyEmployed: (props: {
    currentlyEmployed?: SurveyState["currentlyEmployed"];
  }) => void;

  currentlySeekingEmployment?: OptionsYesNo;
  setCurrentlySeekingEmployment: (props: {
    currentlySeekingEmployment?: SurveyState["currentlySeekingEmployment"];
  }) => void;

  reasonForNotSeekingEmployment?: OptionsReasonForNotSeekingEmployment;
  setReasonForNotSeekingEmployment: (props: {
    reasonForNotSeekingEmployment?: SurveyState["reasonForNotSeekingEmployment"];
  }) => void;

  jobSearchRating?: OptionsRating;
  setJobSearchRating: (props: {
    jobSearchRating?: SurveyState["jobSearchRating"];
  }) => void;

  currentEmploymentType?: OptionsEmploymentType;
  setCurrentEmploymentType: (props: {
    currentEmploymentType?: SurveyState["currentEmploymentType"];
  }) => void;

  selfEmploymentType?: OptionsSelfEmploymentType;
  setSelfEmploymentType: (props: {
    selfEmploymentType?: SurveyState["selfEmploymentType"];
  }) => void;

  employmentInformation: {
    jobTitle?: string;
    hoursPerWeek?: number;
    chosePartTime?: OptionsYesNo | null;
    duration?: OptionsJobDuration;
    longerThanFiveWeeks?: OptionsYesNo;
    offerDate?: string;
    startDate?: string;
    salaryType?: OptionsSalaryType;
    salary?: number;
    hiredBySchool?: OptionsYesNo;
    placedBySchool?: OptionsYesNo;
    employerName?: string;
    employerWebsite?: string;
    employerAddress?: string;
    employerCity?: string;
    employerState?: string;
    employerZipCode?: string;
    employerPhone?: string;
    employerContactName?: string;
    employerContactPhone?: string;
    employerContactEmail?: string;
  };
  setEmploymentInformation: (
    props: SurveyState["employmentInformation"]
  ) => void;

  socCode?: string;
  setSocCode: (props: { socCode?: SurveyState["socCode"] }) => void;

  employmentConditions: {
    employmentConcurrency?: OptionsEmploymentConcurrency;
    sameEmployer?: OptionsYesNo;
    employerPromotion?: OptionsYesNo;
    continuedEmploymentCondition?: OptionsYesNo;
  };
  setEmploymentConditions: (props: SurveyState["employmentConditions"]) => void;

  educationInstitutionInformation: {
    institutionName?: string;
    institutionAddress?: string;
    institutionCity?: string;
    institutionState?: string;
    institutionZipCode?: string;
  };
  setEducationInstitutionInformation: (
    props: SurveyState["educationInstitutionInformation"]
  ) => void;

  militaryInformation: {
    branchName?: OptionsMilitaryBranch;
    recruitingOfficeAddress?: string;
    recruitingOfficeCity?: string;
    recruitingOfficeState?: string;
    recruitingOfficeZipCode?: string;
    recruitingOfficePhone?: string;
  };
  setMilitaryInformation: (props: SurveyState["militaryInformation"]) => void;

  educationProgramName?: string;
  setEducationProgramName: (props: { educationProgramName?: string }) => void;

  unavailabilityDate?: string;
  setUnavailabilityDate: (props: { unavailabilityDate: string }) => void;

  uploadedFiles: File[];
  addUploadedFiles: (files: File[]) => void;
  removeUploadedFile: (index: number) => void;

  previouslyUploadedFiles?: {
    id: number;
    filename: string;
    url: string;
  }[];
  removePreviouslyUploadedFile: (id: number) => void;

  hasDocumentsToSign?: boolean;
  setHasDocumentsToSign: (hasDocumentsToSign: boolean) => void;

  placementId?: number;
  setPlacementId: (placementId: number) => void;
};

export const useSurveyStore = create<SurveyState>()(
  persist(
    (set) => ({
      personalInformation: {},
      setPersonalInformation: (props) =>
        set((state) => ({ ...state, personalInformation: { ...props } })),

      setCurrentlyEmployed: (props) => set((state) => ({ ...state, ...props })),

      setCurrentlySeekingEmployment: (props) =>
        set((state) => ({ ...state, ...props })),

      setReasonForNotSeekingEmployment: (props) =>
        set((state) => ({ ...state, ...props })),

      setJobSearchRating: (props) => set((state) => ({ ...state, ...props })),

      setCurrentEmploymentType: (props) =>
        set((state) => ({ ...state, ...props })),

      setSelfEmploymentType: (props) =>
        set((state) => ({ ...state, ...props })),

      employmentInformation: {},
      setEmploymentInformation: (props) =>
        set((state) => ({ ...state, employmentInformation: props })),

      setSocCode: (props) => set((state) => ({ ...state, ...props })),

      employmentConditions: {
        sameEmployer: "NO",
        employerPromotion: "NO",
        continuedEmploymentCondition: "NO",
      },
      setEmploymentConditions: (props) =>
        set((state) => ({ ...state, employmentConditions: props })),

      setUnavailabilityDate: (props) =>
        set((state) => ({ ...state, ...props })),

      educationInstitutionInformation: {},
      setEducationInstitutionInformation: (props) =>
        set((state) => ({ ...state, educationInstitutionInformation: props })),

      militaryInformation: {},
      setMilitaryInformation: (props) =>
        set((state) => ({ ...state, militaryInformation: props })),

      setEducationProgramName: (props) =>
        set((state) => ({ ...state, ...props })),

      uploadedFiles: [],
      addUploadedFiles: (files) =>
        set((state) => ({
          ...state,
          uploadedFiles: [...state.uploadedFiles, ...files],
        })),
      removeUploadedFile: (index) =>
        set((state) => ({
          ...state,
          uploadedFiles: state.uploadedFiles.filter((_, i) => i !== index),
        })),

      removePreviouslyUploadedFile: (id) =>
        set((state) => ({
          ...state,
          previouslyUploadedFiles: state.previouslyUploadedFiles?.filter(
            (f) => f.id !== id
          ),
        })),

      setHasDocumentsToSign: (hasDocumentsToSign) =>
        set((state) => ({ ...state, hasDocumentsToSign })),

      setPlacementId: (placementId) =>
        set((state) => ({ ...state, placementId })),
    }),
    {
      name: `academysync-survey-${
        new URLSearchParams(window.location.search).get("token") ?? "fallback"
      }`,
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            // Skip list of keys when serializing
            ([key]) => !["uploadedFiles"].includes(key)
          )
        ),
    }
  )
);
