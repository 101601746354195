import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "../utils/constants";
import { getUserData, removeAuth } from "../utils/helpers";

const withAuthStudent = (Component) =>
  function (props) {
    const history = useHistory();
    const location = useLocation();
    const userData = getUserData();
    const isStudent = userData.isStudent;

    if (!isStudent) {
      removeAuth();
      history.replace({
        pathname: ROUTES.login,
        state: { nextPathname: `${location.pathname}${location.search}` },
      });
      return null;
    }

    return <Component {...props} />;
  };

export default withAuthStudent;
