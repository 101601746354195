import React from "react";
import { Form } from "react-bootstrap";
import step3 from "../../assets/images/step3.png";

function Step4() {
  return (
    <div className="Step3 Step__item">
      <div className="Step__item__inner academy-form">
        <Form noValidate>
          <div className="form__inner">
            <img src={step3} alt="" />
            <p className="bold title">3. Pending Approval</p>
            <p className="text--small">
              Your request to join has been submitted and will be processed by
              our team within 24 hours. You will receive a confirmation email
              once your account is verified. Thank you!
            </p>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Step4;
