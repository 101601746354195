import React from "react";
import { Collapse } from "react-bootstrap";
import { FormGroup } from "../../common";
import { ArrowDown2, ArrowUp2, Delete } from "../../common/icons";

function CollapseCodeItem({
  data = {},
  onChange = () => {},
  checked = false,
  onDelete = () => {},
}) {
  const [open, setOpen] = React.useState(false);

  if (!Object.keys(data).length) return null;

  return (
    <div className="Code__item">
      <div className="checkbox">
        • {`${data.SOCCode} ${data.SOCTitle}`}
        <FormGroup
          propsInput={{
            type: "checkbox",
            name: "soc",
            onChange: onChange(data),
            value: checked,
            checked,
          }}
          type="checkbox"
        />
        <button type="button" onClick={onDelete}>
          <Delete />
        </button>
      </div>
      <div className="description">
        <div
          onClick={() => setOpen(!open)}
          role="button"
          tabIndex="0"
          className="btn-collape"
        >
          <p>{open ? "Hide Description" : "View Description"}</p>
          <div className="iconArrow">
            {open ? <ArrowUp2 /> : <ArrowDown2 />}
          </div>
        </div>
        <Collapse in={open} className="description__text">
          <div>
            <p>{data.SOCDefinition}</p>
          </div>
        </Collapse>
      </div>
    </div>
  );
}

export default CollapseCodeItem;
