import React from "react";
import { Table as RTable } from "react-bootstrap";

export default function Table({ columns = [], data = [], tableProps = {} }) {
  if (!columns || !columns.length) {
    return null;
  }

  const formatColumns = {};
  columns.forEach((col) => {
    formatColumns[col.dataIndex] = col;
  });

  return (
    <div className="Access-level_table">
      <div className="Access-level_table__inner">
        <RTable {...tableProps}>
          <thead>
            <tr>
              {columns.map((col) => (
                <th
                  key={col.dataIndex}
                  className={`text__${col.align || "left"}`}
                >
                  {col.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={row.id ? row.id : `row-${rowIndex}`}>
                {columns.map((col, index) => (
                  <td
                    // eslint-disable-next-line react/no-array-index-key
                    key={`td-${col.id}-${index}`}
                    className={`text__${
                      formatColumns[col.dataIndex].align || "left"
                    }`}
                  >
                    {formatColumns[col.dataIndex].render
                      ? formatColumns[col.dataIndex].render(row)
                      : row[col.dataIndex]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </RTable>
      </div>
    </div>
  );
}
