import React from "react";
import cn from "classnames/bind";
import {
  Root,
  Trigger,
  Portal,
  Overlay,
  Content,
  Title,
  Description,
  Cancel,
  Action,
} from "@radix-ui/react-alert-dialog";
import css from "./styles.module.scss";

const cx = cn.bind(css);

type Props = {
  title?: string;
  description: string;
  type: "accept" | "danger";
  children: ReactNode;
  cancelLabel?: string;
  onCancel?: () => void;
  actionLabel?: string;
  onAction?: () => void;
};

export function ConfirmationDialog(props: Props) {
  const { title, description, type, children } = props;
  const cancelLabel = props.cancelLabel ?? "Cancel";
  const onCancel = props.onCancel ?? (() => {});
  const actionLabel = props.actionLabel ?? "Confirm";
  const onAction = props.onAction ?? (() => {});

  const hasTitle = title !== undefined;

  return (
    <Root>
      <Trigger asChild>{children}</Trigger>
      <Portal>
        <Overlay className={css.overlay} />
        <Content className={css.content}>
          {title && <Title className={css.title}>{title}</Title>}
          <Description className={cx("description", { hasTitle })}>
            {description}
          </Description>
          <div className={css.buttonArea}>
            <Cancel className={css.button} onClick={onCancel}>
              {cancelLabel}
            </Cancel>
            <Action className={cx("button", type)} onClick={onAction}>
              {actionLabel}
            </Action>
          </div>
        </Content>
      </Portal>
    </Root>
  );
}
