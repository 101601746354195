import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { approveTask, rejectTask } from "redux/actions/tasks";
import { openMessage } from "redux/actions/message";
import { Loading, ModalTaskDetail } from "../../../../components/common";
import { getStudentTasks } from "../../../../redux/actions/student";

export default function () {
  const dispatch = useDispatch();
  const storeStudentTasks = useSelector(
    (store) => store.student.studentTasks.list
  );
  const loading = storeStudentTasks.loading;
  const { studentId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [selectedTask, setSelectedTask] = React.useState({});
  const storeAdminTasks = useSelector((store) => store.tasks);

  React.useEffect(() => {
    if (studentId) {
      dispatch(getStudentTasks({}, studentId));
    }
  }, [studentId]);

  const convertData = (inputData) => {
    const outputData = {
      pending: [],
      in_review: [],
      completed: [],
      rejected: [],
    };
    inputData.forEach((item) => {
      outputData[item.status].push(item);
    });

    return outputData;
  };

  const boardData = convertData(storeStudentTasks.data || []);

  const handleApprove = async () => {
    try {
      await dispatch(approveTask(selectedTask.student?.id, selectedTask.id));
      setOpen(false);
      dispatch(
        openMessage({
          title: "Approve task successfully!",
          type: "success",
        })
      );
      await dispatch(getStudentTasks({}, studentId));
    } catch (error) {
      console.error("Approve has an error: ", error);
    }
  };

  const handleReject = async () => {
    try {
      await dispatch(rejectTask(selectedTask.student?.id, selectedTask.id));
      setOpen(false);
      dispatch(
        openMessage({
          title: "Reject task successfully!",
          type: "success",
        })
      );
      await dispatch(getStudentTasks({}, studentId));
    } catch (error) {
      console.error("Approve has an error: ", error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="StudentTasks dashboard__item__inner">
      <div className="admin-board-wrapper">
        {Object.keys(boardData).map((itemStatus) => (
          <div key={itemStatus} className="admin-board-column">
            <div className="admin-board-column__inner">
              <div className="admin-board-column__header">
                <h6>{itemStatus.replace(/_/g, " ")}</h6>
              </div>
              <ul>
                {boardData[itemStatus].map((task) => (
                  <li key={`task-id-${task.id}`}>
                    <h6
                      onClick={() => {
                        setOpen(true);
                        setSelectedTask(task);
                      }}
                    >
                      {task.title}
                    </h6>
                    <p>{task.description}</p>
                    {itemStatus === "in_review" && (
                      <button
                        type="button"
                        onClick={() => window.open(task.itemable.logo)}
                      >
                        Complete
                      </button>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      <ModalTaskDetail
        open={open}
        onClose={() => setOpen(false)}
        data={selectedTask}
        config={{ showStudentName: false, showAdminActions: true }}
        onApprove={handleApprove}
        onReject={handleReject}
        loading={
          storeAdminTasks.approve.loading || storeAdminTasks.reject.loading
        }
      />
    </div>
  );
}
