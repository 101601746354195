import React from "react";
import { Modal } from "react-bootstrap";
import { FormGroup } from "../../../common";

function ModalSendSurvey({
  open = false,
  onHide,
  onSendChangeRequest,
  onChangeNotes,
  onFocusNotes,
  notes,
  error,
  isSubmitting,
}) {
  return (
    <Modal
      show={open}
      onHide={onHide}
      backdropClassName="wrap-modal"
      dialogClassName="Modal Modal__send__survey"
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="bootstrap">
        <Modal.Header>
          <Modal.Title>Request Changes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <FormGroup
              propsInput={{
                name: "notes",
                placeholder: "Notes for requested changes",
                as: "textarea",
                onChange: onChangeNotes,
                onFocus: onFocusNotes,
                value: notes,
                disabled: isSubmitting,
              }}
              error={error}
              label="Notes"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button button--back"
            type="button"
            onClick={onHide}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="button"
            onClick={onSendChangeRequest}
            disabled={isSubmitting}
          >
            Request Changes
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default ModalSendSurvey;
