import * as types from "../constants";
import request from "../../utils/request";

export function postSignUp(data) {
  return (dispatch) => {
    dispatch({ type: types.POST_SIGN_UP });
    return request()
      .post("/auth", { user: data })
      .then((dataResponse) => {
        dispatch({
          type: types.POST_SIGN_UP_SUCCEED,
          payload: dataResponse,
        });
        return dataResponse;
      })
      .catch((error) => {
        dispatch({
          type: types.POST_SIGN_UP_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function signUpStudent(data, token) {
  return (dispatch) => {
    dispatch({ type: types.SIGN_UP_STUDENT });
    return request()
      .put(`/student-auth/invitation?invitation_token=${token}`, data)
      .then((dataResponse) => {
        dispatch({
          type: types.SIGN_UP_STUDENT_SUCCEED,
          payload: dataResponse,
        });
        return dataResponse;
      })
      .catch((error) => {
        dispatch({
          type: types.SIGN_UP_STUDENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function acceptInvite(data, token) {
  return (dispatch) => {
    dispatch({ type: types.POST_SIGN_UP });
    return request()
      .put(`/auth/invitation?invitation_token=${token}`, data)
      .then((dataResponse) => {
        dispatch({
          type: types.POST_SIGN_UP_SUCCEED,
          payload: dataResponse,
        });
        return dataResponse;
      })
      .catch((error) => {
        dispatch({
          type: types.POST_SIGN_UP_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getStudentsRegistrationsInfo(token) {
  return request()
    .get(`/students/registrations/info?token=${token}`)
    .then((dataResponse) => dataResponse)
    .catch((error) => {
      throw error;
    });
}
