import React from "react";
import { Spinner, Table as RTable } from "react-bootstrap";
import classNames from "classnames";
import { ASC, DESC } from "../../utils/constants";
import { ArrowDown2, ArrowUp2 } from "./icons";
import Pagination from "./Pagination";

export default function Table({
  columns = [],
  data = [],
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  onSort = (column, direction) => {},
  tableProps = {},
  isPagination = true,
  paginationProps = {},
  loading = false,
  sort = "",
  id = "",
}) {
  const [sortData, setSortData] = React.useState({ column: "", direction: "" });

  React.useEffect(() => {
    const newSortData = { column: "", direction: "" };
    if (sort) {
      const arrSplit = sort.split(" ");
      newSortData.column = arrSplit[0] ? arrSplit[0] : "";
      newSortData.direction = arrSplit[1] ? arrSplit[1] : "";
    }
    setSortData(newSortData);
  }, [sort]);

  if (!columns || !columns.length) {
    return null;
  }

  const formatColumns = {};
  columns.forEach((col) => {
    formatColumns[col.dataIndex] = col;
  });

  const handleSort = (col) => () => {
    if (!col.sortAble) return;

    let sortDirection = ASC;
    if (col.dataIndex === sortData.column && sortData.direction !== DESC) {
      sortDirection = DESC;
    }

    onSort(col.dataIndex, sortDirection);
  };

  return (
    <div
      className={classNames("TableWrapper", {
        "TableWrapper--loading": loading,
      })}
    >
      <div className="Table">
        <div className="Table__inner">
          <RTable {...tableProps}>
            <thead>
              <tr>
                {columns.map((col, index) => (
                  <th
                    key={`th-${id || index}-${col.dataIndex}`}
                    className={`text__${col.align || "left"}`}
                    style={col.width && { width: col.width }}
                  >
                    {col.title}
                    {col.sortAble && (
                      <button
                        onClick={handleSort(col)}
                        type="button"
                        className={classNames("action__sort", {
                          "--is-active": col.dataIndex === sortData.column,
                        })}
                      >
                        <i
                          className={classNames("action__sort__icon", {
                            "--desc": sortData.direction === DESC,
                          })}
                        >
                          <ArrowUp2 />
                          <ArrowDown2 />
                        </i>
                      </button>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, i) => (
                <tr key={`tr-${id || i}-${row.id}`}>
                  {columns.map((col, index) => (
                    <td
                      // eslint-disable-next-line react/no-array-index-key
                      key={`tr-${id || i}-${row.id}-td-${col.id || index}`}
                      className={`text__${
                        formatColumns[col.dataIndex].align || "left"
                      }`}
                    >
                      {formatColumns[col.dataIndex].render
                        ? formatColumns[col.dataIndex].render(row)
                        : row[col.dataIndex]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </RTable>
        </div>
      </div>
      {isPagination && <Pagination {...paginationProps} />}
      {loading && (
        <div className="loading">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </div>
  );
}
