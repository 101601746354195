import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo2.svg";
import { Loading } from "../components/common";
import { StepComplete } from "../components/cirrSurveySteps";
import {
  createCIRRSurveyComplete,
  getCIRRSurveyInfo,
} from "../redux/actions/cirrIntendSurvey";
import { getParam } from "../utils/helpers";
import { openMessage } from "../redux/actions/message";

export default function () {
  const dispatch = useDispatch();
  const token = getParam("token");
  const event = getParam("event");
  const storeSurveyComplete = useSelector(
    (store) => store.cirrIntendSurvey.complete
  );
  const storeSurveyInfo = useSelector((store) => store.cirrIntendSurvey.info);
  const schoolData = storeSurveyInfo.data.school || {};

  const handleGetUserInfo = React.useCallback(async () => {
    try {
      await dispatch(getCIRRSurveyInfo({}, token));
    } catch (err) {
      dispatch(
        openMessage({
          title: "Your token is invalid!",
          type: "error",
        })
      );
    }
  }, [token]);

  React.useEffect(() => {
    handleGetUserInfo();
  }, [handleGetUserInfo]);

  React.useEffect(() => {
    if (event === "signing_complete") {
      dispatch(createCIRRSurveyComplete(token));
    }
  }, [event]);

  return (
    <div className="bg-form Survey-steps">
      <Link to="/" className="logo">
        <img src={logo} alt="" />
      </Link>
      <div className="Survey-steps__inner">
        {!storeSurveyComplete.loading ? (
          <StepComplete
            event={event}
            dataSurvey={storeSurveyComplete.data}
            schoolData={schoolData}
          />
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}
