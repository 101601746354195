import { parse } from "date-fns";
import { useSurveyStore } from "./store";

// Turns an object of keys/values into FormData placement
function formDataFor(fields: { [k: string]: string }, files?: File[]) {
  const form = new FormData();
  Object.entries(fields).forEach((entry) => form.append(...entry));

  if (files) {
    files.forEach((file) => {
      form.append("placement[files][]", file);
    });
  }

  return form;
}

export function mapStoreToFormData() {
  const state = useSurveyStore.getState();

  if (
    state.reasonForNotSeekingEmployment ===
    "Not Seeking Employment for Personal Reasons"
  ) {
    return formDataFor(
      {
        "placement[outcome]": state.reasonForNotSeekingEmployment!,

        "placement[unavailability_placement_attributes][unavailability_date]":
          parse(state.unavailabilityDate!, "yyyy-MM-dd", new Date()).toString(),
      },
      state.uploadedFiles
    );
  }

  if (state.currentlySeekingEmployment === "YES") {
    return formDataFor({
      "placement[outcome]": "Still Seeking Employment",
    });
  }

  if (state.currentEmploymentType === "Self-Employed") {
    return formDataFor(
      {
        "placement[outcome]": state.currentEmploymentType!,
        "placement[employment_placement_attributes][job_type]": "Single",
        "placement[employment_placement_attributes][same_employer]": "false",

        "placement[employment_placement_attributes][self_employed_type]":
          state.selfEmploymentType!,

        "placement[employment_placement_attributes][job_title]":
          state.employmentInformation.jobTitle!,

        "placement[employment_placement_attributes][hours_per_week]":
          state.employmentInformation.hoursPerWeek!.toString(),

        "placement[employment_placement_attributes][chose_part_time]":
          state.employmentInformation.chosePartTime === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][duration]":
          state.employmentInformation.duration!,

        "placement[employment_placement_attributes][longer_than_5_weeks]":
          state.employmentInformation.longerThanFiveWeeks === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][offer_date]": parse(
          state.employmentInformation.offerDate!,
          "yyyy-MM-dd",
          new Date()
        ).toString(),

        "placement[employment_placement_attributes][start_date]": parse(
          state.employmentInformation.startDate!,
          "yyyy-MM-dd",
          new Date()
        ).toString(),

        "placement[employment_placement_attributes][salary_type]":
          state.employmentInformation.salaryType!,

        "placement[employment_placement_attributes][salary]":
          state.employmentInformation.salary!.toString(),

        "placement[employment_placement_attributes][hired_by_school]":
          state.employmentInformation.hiredBySchool === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][placed_by_school]":
          state.employmentInformation.placedBySchool === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][employer_name]":
          state.employmentInformation.employerName!,

        "placement[employment_placement_attributes][employer_website]":
          state.employmentInformation.employerWebsite!,

        "placement[employment_placement_attributes][employer_address]":
          state.employmentInformation.employerAddress!,

        "placement[employment_placement_attributes][employer_city]":
          state.employmentInformation.employerCity!,

        "placement[employment_placement_attributes][employer_state]":
          state.employmentInformation.employerState!,

        "placement[employment_placement_attributes][employer_zip_code]":
          state.employmentInformation.employerZipCode!,

        "placement[employment_placement_attributes][employer_phone]":
          state.employmentInformation.employerPhone!,

        "placement[employment_placement_attributes][employer_contact_name]":
          state.employmentInformation.employerContactName!,

        "placement[employment_placement_attributes][employer_contact_phone]":
          state.employmentInformation.employerContactPhone!,

        "placement[employment_placement_attributes][employer_contact_email]":
          state.employmentInformation.employerContactEmail!,
      },
      state.uploadedFiles
    );
  }

  if (state.reasonForNotSeekingEmployment === "Continuing Education") {
    return formDataFor(
      {
        "placement[outcome]": state.reasonForNotSeekingEmployment!,

        "placement[education_placement_attributes][program_name]":
          state.educationProgramName!,

        "placement[education_placement_attributes][institution_name]":
          state.educationInstitutionInformation.institutionName!,

        "placement[education_placement_attributes][institution_address]":
          state.educationInstitutionInformation.institutionAddress!,

        "placement[education_placement_attributes][institution_city]":
          state.educationInstitutionInformation.institutionCity!,

        "placement[education_placement_attributes][institution_state]":
          state.educationInstitutionInformation.institutionState!,

        "placement[education_placement_attributes][institution_zip_code]":
          state.educationInstitutionInformation.institutionZipCode!,

        "placement[unavailability_placement_attributes][unavailability_date]":
          parse(state.unavailabilityDate!, "yyyy-MM-dd", new Date()).toString(),
      },
      state.uploadedFiles
    );
  }

  if (state.reasonForNotSeekingEmployment === "Military") {
    return formDataFor(
      {
        "placement[outcome]": state.reasonForNotSeekingEmployment!,

        "placement[military_placement_attributes][branch_name]":
          state.militaryInformation.branchName!,

        "placement[military_placement_attributes][recruiting_office_address]":
          state.militaryInformation.recruitingOfficeAddress!,

        "placement[military_placement_attributes][recruiting_office_city]":
          state.militaryInformation.recruitingOfficeCity!,

        "placement[military_placement_attributes][recruiting_office_state]":
          state.militaryInformation.recruitingOfficeState!,

        "placement[military_placement_attributes][recruiting_office_zip_code]":
          state.militaryInformation.recruitingOfficeZipCode!,

        "placement[military_placement_attributes][recruiting_office_phone]":
          state.militaryInformation.recruitingOfficePhone!,

        "placement[unavailability_placement_attributes][unavailability_date]":
          parse(state.unavailabilityDate!, "yyyy-MM-dd", new Date()).toString(),
      },
      state.uploadedFiles
    );
  }

  if (state.currentEmploymentType === "Employed In-Field") {
    return formDataFor(
      {
        "placement[outcome]": state.currentEmploymentType!,

        "placement[employment_placement_attributes][soc_code]": state.socCode!,

        "placement[employment_placement_attributes][job_type]":
          state.employmentConditions.employmentConcurrency!,

        "placement[employment_placement_attributes][same_employer]":
          state.employmentConditions.sameEmployer === "YES" ? "true" : "false",

        "placement[employment_placement_attributes][same_employer_condition]":
          state.employmentConditions.continuedEmploymentCondition === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][same_employer_promotion]":
          state.employmentConditions.employerPromotion === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][hired_by_school]":
          state.employmentInformation.hiredBySchool === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][longer_than_5_weeks]":
          state.employmentInformation.longerThanFiveWeeks === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][placed_by_school]":
          state.employmentInformation.placedBySchool === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][chose_part_time]":
          state.employmentInformation.chosePartTime === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][job_title]":
          state.employmentInformation.jobTitle!,

        "placement[employment_placement_attributes][offer_date]": parse(
          state.employmentInformation.offerDate!,
          "yyyy-MM-dd",
          new Date()
        ).toString(),

        "placement[employment_placement_attributes][start_date]": parse(
          state.employmentInformation.startDate!,
          "yyyy-MM-dd",
          new Date()
        ).toString(),

        "placement[employment_placement_attributes][hours_per_week]":
          state.employmentInformation.hoursPerWeek!.toString(),

        "placement[employment_placement_attributes][duration]":
          state.employmentInformation.duration!,

        "placement[employment_placement_attributes][salary_type]":
          state.employmentInformation.salaryType!,

        "placement[employment_placement_attributes][salary]":
          state.employmentInformation.salary!.toString(),

        "placement[employment_placement_attributes][employer_name]":
          state.employmentInformation.employerName!,

        "placement[employment_placement_attributes][employer_website]":
          state.employmentInformation.employerWebsite!,

        "placement[employment_placement_attributes][employer_address]":
          state.employmentInformation.employerAddress!,

        "placement[employment_placement_attributes][employer_city]":
          state.employmentInformation.employerCity!,

        "placement[employment_placement_attributes][employer_state]":
          state.employmentInformation.employerState!,

        "placement[employment_placement_attributes][employer_zip_code]":
          state.employmentInformation.employerZipCode!,

        "placement[employment_placement_attributes][employer_phone]":
          state.employmentInformation.employerPhone!,

        "placement[employment_placement_attributes][employer_contact_name]":
          state.employmentInformation.employerContactName!,

        "placement[employment_placement_attributes][employer_contact_phone]":
          state.employmentInformation.employerContactPhone!,

        "placement[employment_placement_attributes][employer_contact_email]":
          state.employmentInformation.employerContactEmail!,
      },
      state.uploadedFiles
    );
  }

  if (state.currentEmploymentType === "Employed Out of Field") {
    return formDataFor(
      {
        "placement[outcome]": state.currentEmploymentType!,

        "placement[employment_placement_attributes][soc_code]": "",

        "placement[employment_placement_attributes][job_type]":
          state.employmentConditions.employmentConcurrency!,

        "placement[employment_placement_attributes][same_employer]":
          state.employmentConditions.sameEmployer === "YES" ? "true" : "false",

        "placement[employment_placement_attributes][same_employer_condition]":
          state.employmentConditions.continuedEmploymentCondition === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][same_employer_promotion]":
          state.employmentConditions.employerPromotion === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][hired_by_school]":
          state.employmentInformation.hiredBySchool === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][longer_than_5_weeks]":
          state.employmentInformation.longerThanFiveWeeks === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][placed_by_school]":
          state.employmentInformation.placedBySchool === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][chose_part_time]":
          state.employmentInformation.chosePartTime === "YES"
            ? "true"
            : "false",

        "placement[employment_placement_attributes][job_title]":
          state.employmentInformation.jobTitle!,

        "placement[employment_placement_attributes][offer_date]": parse(
          state.employmentInformation.offerDate!,
          "yyyy-MM-dd",
          new Date()
        ).toString(),

        "placement[employment_placement_attributes][start_date]": parse(
          state.employmentInformation.startDate!,
          "yyyy-MM-dd",
          new Date()
        ).toString(),

        "placement[employment_placement_attributes][hours_per_week]":
          state.employmentInformation.hoursPerWeek!.toString(),

        "placement[employment_placement_attributes][duration]":
          state.employmentInformation.duration!,

        "placement[employment_placement_attributes][salary_type]":
          state.employmentInformation.salaryType!,

        "placement[employment_placement_attributes][salary]":
          state.employmentInformation.salary!.toString(),

        "placement[employment_placement_attributes][employer_name]":
          state.employmentInformation.employerName!,

        "placement[employment_placement_attributes][employer_website]":
          state.employmentInformation.employerWebsite!,

        "placement[employment_placement_attributes][employer_address]":
          state.employmentInformation.employerAddress!,

        "placement[employment_placement_attributes][employer_city]":
          state.employmentInformation.employerCity!,

        "placement[employment_placement_attributes][employer_state]":
          state.employmentInformation.employerState!,

        "placement[employment_placement_attributes][employer_zip_code]":
          state.employmentInformation.employerZipCode!,

        "placement[employment_placement_attributes][employer_phone]":
          state.employmentInformation.employerPhone!,

        "placement[employment_placement_attributes][employer_contact_name]":
          state.employmentInformation.employerContactName!,

        "placement[employment_placement_attributes][employer_contact_phone]":
          state.employmentInformation.employerContactPhone!,

        "placement[employment_placement_attributes][employer_contact_email]":
          state.employmentInformation.employerContactEmail!,
      },
      state.uploadedFiles
    );
  }

  throw new Error("Invalid Placement Result");
}
