/* eslint-disable react/jsx-indent */
/* eslint-disable no-nested-ternary */
import React from "react";
import { Collapse, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import classNames from "classnames";
import {
  CIRR_SALARY,
  reportingMapLabelsForTableItem,
  CIRR_HIRED_BY_SCHOOL,
  MAP_LABEL_90_180,
  CIRR_EMPLOYMENT_RESULTS,
  BPPE_EXAM_PASSAGE_RATE,
  BPPE_FINANCIAL_GRADUATION,
  BPPE_PLACEMENT,
  COE_ENROLMENT,
  COE_COMPLETION,
  COE_PLACEMENT,
  COE_RATES,
  ACCET_ENROLMENT,
  ACCET_COMPLETION,
  ACCET_PLACEMENT,
  DPOS_PLACEMENT,
} from "../../../utils/constants";
import { ArrowDown, ArrowUp } from "../../common/icons";

function CollapseReporting({ data = {}, title = "", dataCoversheet = {} }) {
  const Params = useParams();
  const reportName = Params.reportName;
  const [open, setOpen] = React.useState(true);

  let dataFilter = [];

  if (reportName === "cirr") {
    if (title === "Salary" && !Array.isArray(data)) {
      Object.keys(data).forEach((key) => {
        data[key].forEach((item, index) => {
          if (!dataFilter[index]) {
            dataFilter.push({
              label: "",
              value_90: "",
              value_180: "",
            });
          }
          dataFilter[index].label = item.label;
          dataFilter[index][MAP_LABEL_90_180[key]] = `${item.value}%`;
        });
      });
      dataFilter = [
        {
          label: "median_annual_base_salary",
          value_90:
            dataCoversheet.median_annual_base_salary &&
            dataCoversheet.median_annual_base_salary
              .median_annual_base_salary_90
              ? `$${dataCoversheet.median_annual_base_salary.median_annual_base_salary_90}`
              : 0,
          value_180:
            dataCoversheet.median_annual_base_salary &&
            dataCoversheet.median_annual_base_salary
              .median_annual_base_salary_180
              ? `$${dataCoversheet.median_annual_base_salary.median_annual_base_salary_180}`
              : 0,
        },
        ...dataFilter,
        {
          label: "reported_salaries",
          value_90: dataCoversheet.reported_salaries
            ? `${dataCoversheet.reported_salaries.reported_salaries_90}%`
            : "",
          value_180: dataCoversheet.reported_salaries
            ? `${dataCoversheet.reported_salaries.reported_salaries_180}%`
            : "",
        },
      ];
    }

    if (title === "Hired by School") {
      dataFilter = [
        {
          label: "hired_by_school",
          value_90: dataCoversheet.hired_by_school
            ? `${dataCoversheet.hired_by_school.hired_by_school_90}%`
            : "",
          value_180: dataCoversheet.hired_by_school
            ? `${dataCoversheet.hired_by_school.hired_by_school_180}%`
            : "",
        },
        ...dataFilter,
      ];
    }

    if (title === "Employment Results") {
      Object.keys(data).forEach((key) => {
        Object.keys(data[key]).forEach((item, index) => {
          if (!dataFilter[index]) {
            dataFilter.push({
              label: "",
              value_90: "",
              value_180: "",
            });
          }
          dataFilter[index].label = item;
          dataFilter[index][MAP_LABEL_90_180[key]] = data[key][item];
        });
      });
    }

    if (title === "Graduation Data" && reportName === "cirr") {
      data = {
        graduation_100: dataCoversheet.graduation_100,
        graduation_150: dataCoversheet.graduation_150,
      };
    }

    if (title === "Job Seekers") {
      data = {
        seek_in_field_employment: dataCoversheet.seek_in_field_employment,
        not_seek_in_field_employment:
          dataCoversheet.not_seek_in_field_employment,
      };
    }
  }

  let dataEmployment = [];

  if (reportName === "bppe") {
    if (title === "Exam Passage Rate") {
      data.forEach((item) => {
        Object.keys(item).forEach((key, index2) => {
          if (!dataFilter[index2]) {
            dataFilter.push({
              label: "",
              value_90: "",
              value_180: "",
            });
          }
          dataFilter[index2].label = key;
          dataFilter[index2].value_90 = data[0][key];
          dataFilter[index2].value_180 = data[1][key];
        });
      });
    }

    if (title === "Financial & Graduation") {
      data = {};
      Object.keys(dataCoversheet).forEach((key) => {
        if (BPPE_FINANCIAL_GRADUATION[key]) {
          data[key] = dataCoversheet[key];
        }
      });
    }

    if (title === "Placement") {
      data = {};
      Object.keys(dataCoversheet).forEach((key) => {
        if (BPPE_PLACEMENT[key]) {
          data[key] = dataCoversheet[key];
        }
      });
    }

    if (title === "Gainfully Employed Categories") {
      dataEmployment = [
        {
          label: "Part-Time vs Full-Time Employment",
          value: "",
        },
        {
          label: "Graduates Employed In-Field 20-29 hours/week",
          value: dataCoversheet.employed_full_time,
        },
        {
          label: "Graduates Employed In-Field at least 30 hours/week",
          value: dataCoversheet.employed_in_field,
        },
        {
          label:
            "Short-term contract, part-time position, freelance, or unknown length ",
          value: dataCoversheet.employed_part_time,
        },
        {
          label: "Single Position vs Concurrent Aggregated Position",
          value: "",
        },
        {
          label: "Graduates Employed In-Field Single Position",
          value: dataCoversheet.single_position,
        },
        {
          label: "Graduates Employed In-Field Concurrent Position",
          value: dataCoversheet.concurrent_position,
        },
        {
          label: "Self-Employed/Freelance Positions",
          value: "",
        },
        {
          label: "Graduates Employed who are Self-Employed",
          value: dataCoversheet.freelance_or_self_employed,
        },
        {
          label: "Institutional Employment",
          value: "",
        },
        {
          label: "Employed by the Institution or Employer",
          value: "",
        },
      ];
    }
  }

  if (reportName === "twc" && typeof data === "object") {
    if (title === "Enrollment") {
      data.enrollment_total = dataCoversheet.enrollment_total;
    }

    if (title === "Completion") {
      data.completion_rate = dataCoversheet.completion_rate;
    }
    if (title === "Placement") {
      data.placement_rate = dataCoversheet.placement_rate;
    }
  }

  let dataEnrollment = [];
  let dataAdmissions = [];
  let dataPlacement = [];
  if (reportName === "bpss") {
    if (title === "Enrollment") {
      const enrollmentResults = dataCoversheet.enrollment_results
        ? dataCoversheet.enrollment_results
        : {};
      dataEnrollment = [
        {
          label: "",
          newEnrollment: "New Enrollment",
          contiEnrollment: "Continued Enrollment",
        },
        {
          label: "HS Diploma/HSE (Full Time)",
          newEnrollment: enrollmentResults.new_enrollment_ft,
          contiEnrollment: enrollmentResults.continued_enrollment_ft,
        },
        {
          label: "HS Diploma/HSE (Part Time)",
          newEnrollment: enrollmentResults.new_enrollment_pt,
          contiEnrollment: enrollmentResults.continued_enrollment_pt,
        },
        {
          label: "Without HS Diploma or HSE (Full-Time)",
          newEnrollment: enrollmentResults.atb_new_enrollment_ft,
          contiEnrollment: enrollmentResults.atb_continued_enrollment_ft,
        },
        {
          label: "Without HS Diploma or HSE (Part-Time)",
          newEnrollment: enrollmentResults.atb_new_enrollment_pt,
          contiEnrollment: enrollmentResults.atb_continued_enrollment_pt,
        },
        {
          label: "Total Enrollments",
          newEnrollment: enrollmentResults.new_enrollment_total,
          contiEnrollment: enrollmentResults.continued_enrollment_total,
        },
      ];
    }
    if (title === "Admissions") {
      const admissionResults = dataCoversheet.admission_results;
      dataAdmissions = [
        {
          label: "",
          value1: "Applied",
          value2: "Accepted",
          value3: "Denied",
        },
        {
          label:
            "High School Diploma (HS)/ High School Equivalency (HSE) (Full Time)",
          value1: admissionResults.applied_ft || "-",
          value2: admissionResults.accepted_ft || "-",
          value3: admissionResults.denied_ft || "-",
        },
        {
          label: "HS Diploma/HSE (Part Time)",
          value1: admissionResults.applied_pt || "-",
          value2: admissionResults.accepted_pt || "-",
          value3: admissionResults.denied_pt || "-",
        },
        {
          label: "Without HS Diploma or HSE (Full-Time)",
          value1: admissionResults.atb_applied_ft || "-",
          value2: admissionResults.atb_accepted_ft || "-",
          value3: admissionResults.atb_denied_ft || "-",
        },
        {
          label: "Without HS Diploma or HSE (Part-Time)",
          value1: admissionResults.atb_applied_pt || "-",
          value2: admissionResults.atb_accepted_pt || "-",
          value3: admissionResults.atb_denied_pt || "-",
        },
        {
          label: "Total Applications",
          value1: admissionResults.applied_total || "-",
          value2: admissionResults.accepted_total || "-",
          value3: admissionResults.denied_total || "-",
        },
      ];
    }

    if (title === "Financial Assistance for Program") {
      dataAdmissions = [
        {
          label: "",
          value1: "Full TIme",
          value2: "Part Time",
          value3: "Total",
        },
        {
          label: "TAP (Tuition Assistance Program)",
          value1: dataCoversheet.financial_aid_results.tap_ft,
          value2: dataCoversheet.financial_aid_results.tap_pt,
          value3: dataCoversheet.financial_aid_results.tap_total,
        },
        {
          label: "GSL (Guaranteed Student Loan)",
          value1: dataCoversheet.financial_aid_results.gsl_ft,
          value2: dataCoversheet.financial_aid_results.gsl_pt,
          value3: dataCoversheet.financial_aid_results.gsl_total,
        },
        {
          label: "PELL (Basic Education Opportunity Grant)",
          value1: dataCoversheet.financial_aid_results.pell_ft,
          value2: dataCoversheet.financial_aid_results.pell_pt,
          value3: dataCoversheet.financial_aid_results.pell_total,
        },
        {
          label: "SEOG (Special Education Opportunity Grant)",
          value1: dataCoversheet.financial_aid_results.seog_ft,
          value2: dataCoversheet.financial_aid_results.seog_pt,
          value3: dataCoversheet.financial_aid_results.seog_total,
        },
        {
          label:
            "ACCES VR (Adult Career and Continuing Ed. Services Vocational Rehabilitation)",
          value1: dataCoversheet.financial_aid_results.access_vr_ft,
          value2: dataCoversheet.financial_aid_results.access_vr_pt,
          value3: dataCoversheet.financial_aid_results.access_vr_total,
        },
        {
          label: "WIA (Workforce Investment Act)",
          value1: dataCoversheet.financial_aid_results.wia_ft,
          value2: dataCoversheet.financial_aid_results.wia_pt,
          value3: dataCoversheet.financial_aid_results.wia_total,
        },
        {
          label: "Other Federal or State Subsidies",
          value1: dataCoversheet.financial_aid_results.other_ft,
          value2: dataCoversheet.financial_aid_results.other_pt,
          value3: dataCoversheet.financial_aid_results.other_total,
        },
        {
          label: "Private Student Loans",
          value1: dataCoversheet.financial_aid_results.lender_ft,
          value2: dataCoversheet.financial_aid_results.lender_pt,
          value3: dataCoversheet.financial_aid_results.lender_total,
        },
        {
          label: "School Issued Credit",
          value1: dataCoversheet.financial_aid_results.school_issued_ft,
          value2: dataCoversheet.financial_aid_results.school_issued_pt,
          value3: dataCoversheet.financial_aid_results.school_issued_total,
        },
        {
          label:
            "Unduplicated Total of Students Receiving Financial Assistance",
          value1: dataCoversheet.financial_aid_results.undup_ft,
          value2: dataCoversheet.financial_aid_results.undup_pt,
          value3: dataCoversheet.financial_aid_results.undup_total,
        },
        {
          label:
            "Self Funded (Did not use any of the above resources to pay for this curriculum)",
          value1: dataCoversheet.financial_aid_results.self_funded_ft,
          value2: dataCoversheet.financial_aid_results.self_funded_pt,
          value3: dataCoversheet.financial_aid_results.self_funded_total,
        },
      ];
    }
    if (title === "Completion") {
      const completionResults = dataCoversheet.completion_results
        ? dataCoversheet.completion_results
        : {};
      dataAdmissions = [
        {
          label: "",
          value1: "Graduate",
          value2: "Continue",
          value3: "Non complete",
        },
        {
          label:
            "High School Diploma (HS)/ High School Equivalency (HSE) (Full Time)",
          value1: completionResults.graduate_ft,
          value2: completionResults.continue_nxyr_ft,
          value3: completionResults.non_complete_ft,
        },
        {
          label: "HS Diploma/HSE (Part Time)",
          value1: completionResults.graduate_pt,
          value2: completionResults.continue_nxyr_pt,
          value3: completionResults.non_complete_pt,
        },
        {
          label: "Without HS Diploma or HSE (Full-Time)",
          value1: completionResults.atb_graduate_ft,
          value2: completionResults.atb_continue_nxyr_ft,
          value3: completionResults.atb_non_complete_ft,
        },
        {
          label: "Without HS Diploma or HSE (Part-Time)",
          value1: completionResults.atb_graduate_pt,
          value2: completionResults.atb_continue_nxyr_pt,
          value3: completionResults.continue_nxyr_pt,
        },
        {
          label: "Total Students",
          value1: completionResults.graduate_total,
          value2: completionResults.continue_nxyr_total,
          value3: completionResults.non_complete_total,
        },
      ];
    }
    if (title === "Placement") {
      const placementResults = dataCoversheet.placement_results || {};
      dataPlacement = [
        {
          label: "",
          occupation: "In Occupation",
          relatedField: "Related Field",
          unrelated: "Unrelated Field",
          seeking: "Seeking Employment",
          status: "Status Unknown",
          unavailable: "Unavailable for employment",
        },
        {
          label: "High School Diploma (HS)/ (HSE)(Full Time)",
          occupation: placementResults.in_occupation_ft,
          relatedField: placementResults.related_ft,
          unrelated: placementResults.unrelated_ft,
          seeking: placementResults.seeking_ft,
          status: placementResults.status_unknown_ft,
          unavailable: placementResults.unavail_ft,
        },
        {
          label: "HS Diploma/HSE (Part Time)",
          occupation: placementResults.in_occupation_pt,
          relatedField: placementResults.related_pt,
          unrelated: placementResults.unrelated_pt,
          seeking: placementResults.seeking_pt,
          status: placementResults.status_unknown_pt,
          unavailable: placementResults.unavail_pt,
        },
        {
          label: "Without HS Diploma or HSE (Full-Time)",
          occupation: placementResults.atb_in_occupation_ft,
          relatedField: placementResults.atb_related_ft,
          unrelated: placementResults.atb_unrelated_ft,
          seeking: placementResults.atb_seeking_ft,
          status: placementResults.atb_status_unknown_ft,
          unavailable: placementResults.atb_unavail_ft,
        },
        {
          label: "Without HS Diploma or HSE (Part-Time)",
          occupation: placementResults.atb_in_occupation_pt,
          relatedField: placementResults.atb_related_pt,
          unrelated: placementResults.atb_unrelated_pt,
          seeking: placementResults.atb_seeking_pt,
          status: placementResults.atb_status_unknown_pt,
          unavailable: placementResults.atb_unavail_pt,
        },
        {
          label: "Total Graduates",
          occupation: placementResults.in_occupation_total,
          relatedField: placementResults.related_total,
          unrelated: placementResults.unrelated_total,
          seeking: placementResults.seeking_total,
          status: placementResults.unknown_total,
          unavailable: placementResults.unavail_total,
        },
      ];
    }
  }

  if (reportName === "coe") {
    if (title === "Enrollment") {
      data = {};
      Object.keys(dataCoversheet).forEach((key) => {
        if (COE_ENROLMENT[key]) {
          data[key] = dataCoversheet[key];
        }
      });
    }
    if (title === "Completion") {
      data = {};
      Object.keys(dataCoversheet).forEach((key) => {
        if (COE_COMPLETION[key]) {
          data[key] = dataCoversheet[key];
        }
      });
    }
    if (title === "Placement") {
      data = {};
      Object.keys(dataCoversheet).forEach((key) => {
        if (COE_PLACEMENT[key]) {
          data[key] = dataCoversheet[key];
        }
      });
    }

    if (title === "Rates") {
      data = {};
      Object.keys(dataCoversheet).forEach((key) => {
        if (COE_RATES[key]) {
          data[key] = dataCoversheet[key];
        }
      });
    }
  }

  if (reportName === "accet") {
    if (title === "Enrollment") {
      data = {};
      Object.keys(dataCoversheet).forEach((key) => {
        if (ACCET_ENROLMENT[key]) {
          data[key] = dataCoversheet[key];
        }
      });
    }
    if (title === "Completion") {
      data = {};
      Object.keys(dataCoversheet).forEach((key) => {
        if (ACCET_COMPLETION[key]) {
          data[key] = dataCoversheet[key];
        }
      });
    }
    if (title === "Placement") {
      data = {};
      Object.keys(dataCoversheet).forEach((key) => {
        if (ACCET_PLACEMENT[key]) {
          data[key] = dataCoversheet[key];
        }
      });
    }
  }

  let dataStudentGraduate = [];
  let dataRace = [];
  if (reportName === "dpos") {
    if (title === "Placement Statistics") {
      data = {};
      Object.keys(dataCoversheet).forEach((key) => {
        if (DPOS_PLACEMENT[key]) {
          data[key] = dataCoversheet[key];
        }
      });
    }
    if (title === "Annual Student Graduate Data") {
      dataStudentGraduate = [
        {
          label: "Program/Course Name ",
          graduate: "Number of Graduates ",
          type: "Type of Award",
        },
        {
          label: dataCoversheet.program_name,
          graduate: dataCoversheet.graduates,
          type: dataCoversheet.degree_type,
        },
      ];
    }
    if (title === "Annual Student Graduate Race/Ethnicity & Gender Data") {
      const dataRace1 = [
        {
          label: "Race/Ethnicity",
          male: "Male",
          female: "Female",
          unknown: "Other/Unknown",
          total: "Total Number of Graduates",
        },
      ];
      const dataRace2 = Object.keys(dataCoversheet.demographics).map(
        (item) => ({
          label: item,
          male: dataCoversheet.demographics[item].male,
          female: dataCoversheet.demographics[item].female,
          unknown: dataCoversheet.demographics[item].unknown,
          total: dataCoversheet.demographics[item].total,
        })
      );

      dataRace = [...dataRace1, ...dataRace2];
    }
  }

  return (
    <div className="reporting-collape">
      <div
        onClick={() => setOpen(!open)}
        role="button"
        tabIndex="0"
        className="btn-collape"
      >
        <p className="text--xlarge bold">
          {reportName === "bpss" && title === "Placement"
            ? "Graduation Follow-Up"
            : title}
        </p>
        <div className="iconArrow">{open ? <ArrowUp /> : <ArrowDown />}</div>
      </div>
      <Collapse in={open}>
        <div className="Table__wrap">
          <div className="Table">
            <div className="Table__inner">
              <Table className="Table-dark">
                {!!dataFilter.length && title !== "Exam Passage Rate" && (
                  <thead>
                    <tr>
                      <th> </th>
                      <th>90 Days</th>
                      <th>180 Days</th>
                    </tr>
                  </thead>
                )}

                <tbody>
                  {title === "Salary" &&
                  reportName === "bppe" &&
                  !!data.length ? (
                    data.map((item) => (
                      <tr key={item.label}>
                        <td>{`$${item.label}`}</td>
                        <td>{item.value}</td>
                      </tr>
                    ))
                  ) : dataFilter.length &&
                    (title === "Salary" ||
                      title === "Hired by School" ||
                      title === "Employment Results" ||
                      title === "Exam Passage Rate") ? (
                    dataFilter.map((item) => (
                      <tr
                        key={item.label}
                        className={classNames({
                          "--tableFormat": title === "Exam Passage Rate",
                          "--tableEmployment": title === "Employment Results",
                          "--tableSalary":
                            title === "Salary" || title === "Hired by School",
                        })}
                      >
                        {title === "Salary" ? (
                          <td>{CIRR_SALARY[item.label]}</td>
                        ) : title === "Hired by School" ? (
                          <td>{CIRR_HIRED_BY_SCHOOL[item.label]}</td>
                        ) : title === "Exam Passage Rate" ? (
                          <td>{BPPE_EXAM_PASSAGE_RATE[item.label]}</td>
                        ) : (
                          <td>{CIRR_EMPLOYMENT_RESULTS[item.label]}</td>
                        )}

                        <td>{item.value_90}</td>
                        <td>{item.value_180}</td>
                      </tr>
                    ))
                  ) : dataEmployment.length ? (
                    dataEmployment.map((item) => (
                      <tr key={item.label} className="tableEmployment">
                        <td>{item.label}</td>
                        <td>{item.value}</td>
                      </tr>
                    ))
                  ) : dataEnrollment.length ? (
                    dataEnrollment.map((item) => (
                      <tr key={item.label} className="tableBPSS">
                        <td>{item.label}</td>
                        <td>{item.newEnrollment}</td>
                        <td>{item.contiEnrollment}</td>
                      </tr>
                    ))
                  ) : dataAdmissions.length ? (
                    dataAdmissions.map((item) => (
                      <tr key={item.label} className="tableBPSS">
                        <td>{item.label}</td>
                        <td>{item.value1}</td>
                        <td>{item.value2}</td>
                        <td>{item.value3}</td>
                      </tr>
                    ))
                  ) : dataPlacement.length ? (
                    dataPlacement.map((item) => (
                      <tr key={item.label} className="tableBPSSGraduation">
                        <td>{item.label}</td>
                        <td>{item.occupation}</td>
                        <td>{item.relatedField}</td>
                        <td>{item.unrelated}</td>
                        <td>{item.seeking}</td>
                        <td>{item.status}</td>
                        <td>{item.unavailable}</td>
                      </tr>
                    ))
                  ) : dataStudentGraduate.length ? (
                    dataStudentGraduate.map((item) => (
                      <tr key={item.label} className="tableDPOS">
                        <td>{item.label}</td>
                        <td>{item.graduate}</td>
                        <td>{item.type}</td>
                      </tr>
                    ))
                  ) : dataRace.length ? (
                    dataRace.map((item) => (
                      <tr key={item.label} className="tableDPOS">
                        <td>{item.label}</td>
                        <td>{item.male}</td>
                        <td>{item.female}</td>
                        <td>{item.unknown}</td>
                        <td>{item.total}</td>
                      </tr>
                    ))
                  ) : Object.keys(data).length ? (
                    Object.keys(data).map((key) => (
                      <tr
                        key={key}
                        className={classNames({
                          "--tableTitle":
                            (title === "Placement" && reportName !== "coe") ||
                            (title === "Enrollment" &&
                              reportName !== "accet") ||
                            title === "Completion",
                          "--tableFinancial":
                            title === "Financial & Graduation",
                        })}
                      >
                        {title === "Most Frequent Job Titles" ? (
                          <>
                            <td>{[key]}</td>
                            <td>{`${data[key]}`}</td>
                          </>
                        ) : (
                          <>
                            <td>{reportingMapLabelsForTableItem[key]}</td>
                            <td>{`${data[key]}`}</td>
                          </>
                        )}
                      </tr>
                    ))
                  ) : (
                    <p>No data</p>
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default CollapseReporting;
