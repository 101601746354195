import * as types from "../constants";
import request from "../../utils/request";
import store from "../store";

export function getStudent(params, studentId) {
  return (dispatch) => {
    dispatch({ type: types.GET_STUDENT });
    return request()
      .get(`/students/${studentId}`, { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_STUDENT_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_STUDENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function deleteStudent(studentId) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_STUDENT });
    return request()
      .delete(`/students/${studentId}`)
      .then((dataResponse) => {
        dispatch({
          type: types.DELETE_STUDENT_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_STUDENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getEnrollments(params, studentId) {
  return (dispatch) => {
    dispatch({ type: types.GET_ENROLLMENTS });
    return request()
      .get(`/students/${studentId}/enrollments`, { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_ENROLLMENTS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ENROLLMENTS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createEnrollment(data, studentId) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_ENROLLMENT });
    return request(
      {},
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .post(`/students/${studentId}/enrollments`, data)
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_ENROLLMENT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_ENROLLMENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function deleteEnrollment(studentId, enrollmentId) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_ENROLLMENT });
    return request()
      .delete(`/students/${studentId}/enrollments/${enrollmentId}`)
      .then((dataResponse) => {
        dispatch({
          type: types.DELETE_ENROLLMENT_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_ENROLLMENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function updateEnrollment(data, studentId, enrollmentId) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_ENROLLMENT });
    return request(
      {},
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .put(`/students/${studentId}/enrollments/${enrollmentId}`, data)
      .then((dataResponse) => {
        dispatch({
          type: types.UPDATE_ENROLLMENT_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_ENROLLMENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getPlacements(params, studentId) {
  return (dispatch) => {
    dispatch({ type: types.GET_PLACEMENTS });
    return request()
      .get(`/students/${studentId}/placements`, { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_PLACEMENTS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_PLACEMENTS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createPlacement(data, studentId) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_PLACEMENT });
    return request(
      {},
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .post(`/students/${studentId}/placements`, data)
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_PLACEMENT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_PLACEMENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function deletePlacement(studentId, placementId) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_PLACEMENT });
    return request()
      .delete(`/students/${studentId}/placements/${placementId}`)
      .then((dataResponse) => {
        dispatch({
          type: types.DELETE_PLACEMENT_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_PLACEMENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function updatePlacement(data, studentId, placementId) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_PLACEMENT });
    return request(
      {},
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .put(`/students/${studentId}/placements/${placementId}`, data)
      .then((dataResponse) => {
        dispatch({
          type: types.UPDATE_PLACEMENT_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_PLACEMENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getLicensureExams(params, studentId) {
  return (dispatch) => {
    dispatch({ type: types.GET_LICENSURE_EXAMS });
    return request()
      .get(`/students/${studentId}/student_licensure_exams`, { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_LICENSURE_EXAMS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_LICENSURE_EXAMS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getProgramLicensureExams(params, programId) {
  return (dispatch) => {
    dispatch({ type: types.GET_PROGRAMS_LICENSURE_EXAMS });
    return request()
      .get(`/programs/${programId}/licensure_exams`, { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_PROGRAMS_LICENSURE_EXAMS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_PROGRAMS_LICENSURE_EXAMS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createLicensureExam(data, studentId) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_LICENSURE_EXAM });
    return request(
      {},
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .post(`/students/${studentId}/student_licensure_exams`, data)
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_LICENSURE_EXAM_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_LICENSURE_EXAM_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function deleteLicensureExam(studentId, licensureExamId) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_LICENSURE_EXAM });
    return request()
      .delete(
        `/students/${studentId}/student_licensure_exams/${licensureExamId}`
      )
      .then((dataResponse) => {
        dispatch({
          type: types.DELETE_LICENSURE_EXAM_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_LICENSURE_EXAM_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function updateLicensureExam(data, studentId, licensureExamId) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_LICENSURE_EXAM });
    return request(
      {},
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .put(
        `/students/${studentId}/student_licensure_exams/${licensureExamId}`,
        data
      )
      .then((dataResponse) => {
        dispatch({
          type: types.UPDATE_LICENSURE_EXAM_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_LICENSURE_EXAM_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getFinancialAid(params, studentId) {
  return (dispatch) => {
    dispatch({ type: types.GET_STUDENT_FINANCIAL_AID });
    return request()
      .get(`/students/${studentId}/student_financial_aid_programs`, { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_STUDENT_FINANCIAL_AID_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_STUDENT_FINANCIAL_AID_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getStudentTasks(params, studentId) {
  return (dispatch) => {
    dispatch({ type: types.GET_STUDENT_TASKS });
    return request()
      .get(`/students/${studentId}/tasks`, { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_STUDENT_TASKS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_STUDENT_TASKS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getFinancialAidPrograms(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_STUDENT_FINANCIAL_AID_PROGRAMS });
    return request()
      .get(`/financial_aid_programs/all`, { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_STUDENT_FINANCIAL_AID_PROGRAMS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_STUDENT_FINANCIAL_AID_PROGRAMS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createFinancialAid(data, studentId) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_STUDENT_FINANCIAL_AID });
    return request(
      {},
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .post(`/students/${studentId}/student_financial_aid_programs`, data)
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_STUDENT_FINANCIAL_AID_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_STUDENT_FINANCIAL_AID_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function deleteFinancialAid(studentId, financialAidId) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_STUDENT_FINANCIAL_AID });
    return request()
      .delete(
        `/students/${studentId}/student_financial_aid_programs/${financialAidId}`
      )
      .then((dataResponse) => {
        dispatch({
          type: types.DELETE_STUDENT_FINANCIAL_AID_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_STUDENT_FINANCIAL_AID_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function updateFinancialAid(data, studentId, financialAidId) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_STUDENT_FINANCIAL_AID });
    return request(
      {},
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .put(
        `/students/${studentId}/student_financial_aid_programs/${financialAidId}`,
        data
      )
      .then((dataResponse) => {
        dispatch({
          type: types.UPDATE_STUDENT_FINANCIAL_AID_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_STUDENT_FINANCIAL_AID_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getContactLogs(params, studentId) {
  return (dispatch) => {
    dispatch({ type: types.GET_CONTACT_LOGS });
    return request()
      .get(`/students/${studentId}/contact_logs`, { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_CONTACT_LOGS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_CONTACT_LOGS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createContactLog(data, studentId) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_CONTACT_LOG });
    return request(
      {},
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .post(`/students/${studentId}/contact_logs`, data)
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_CONTACT_LOG_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_CONTACT_LOG_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function deleteContactLog(studentId, contactLogId) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_CONTACT_LOG });
    return request()
      .delete(`/students/${studentId}/contact_logs/${contactLogId}`)
      .then((dataResponse) => {
        dispatch({
          type: types.DELETE_CONTACT_LOG_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_CONTACT_LOG_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function updateContactLog(data, studentId, contactLogId) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_CONTACT_LOG });
    return request(
      {},
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .put(`/students/${studentId}/contact_logs/${contactLogId}`, data)
      .then((dataResponse) => {
        dispatch({
          type: types.UPDATE_CONTACT_LOG_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_CONTACT_LOG_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getStudentChangelog(params, studentId) {
  return (dispatch) => {
    dispatch({ type: types.GET_STUDENT_CHANGE_LOG });
    return request()
      .get(`/students/${studentId}/audits`, { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_STUDENT_CHANGE_LOG_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            page: params.page,
            perPage: parseFloat(dataResponse.headers?.["per-page"] || 20),
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_STUDENT_CHANGE_LOG_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getStudentDocuments(params, studentId) {
  return (dispatch) => {
    dispatch({ type: types.GET_STUDENT_DOCUMENTS });
    return request()
      .get(`/students/${studentId}/documents`, { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_STUDENT_DOCUMENTS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_STUDENT_DOCUMENTS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getStudentPrograms(studentId) {
  return (dispatch) => {
    dispatch({ type: types.GET_STUDENT_PROGRAMS });
    return request()
      .get(`/students/${studentId}/programs`)
      .then((dataResponse) => {
        dispatch({
          type: types.GET_STUDENT_PROGRAMS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_STUDENT_PROGRAMS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function deleteDocument(documentId) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_DOCUMENT });
    return request()
      .delete(`/attachments/${documentId}`)
      .then((dataResponse) => {
        dispatch({
          type: types.DELETE_DOCUMENT_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_DOCUMENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function setDataChangelog() {
  store.dispatch({
    type: types.SET_DATA_CHANG_LOG,
  });
}
