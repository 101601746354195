import { requestClient } from "lib/api";
import qs from "query-string";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import { useSurveyStore } from "../store";

type QueryParams = {
  token: string;
};

const SignDocumentsUrlSchema = z.string();

export function useSignDocumentsUrl() {
  const location = useLocation();
  const { token } = qs.parse(location.search) as QueryParams;
  const [placementId] = useSurveyStore((store) => [store.placementId]);

  const query = useQuery(["surveySign", token, placementId], async () => {
    const response = await requestClient().get(
      `/surveys/graduate_follow_up/sign?token=${token}&placement_id=${placementId}`
    );
    return SignDocumentsUrlSchema.parse(response.data.data);
  });

  const isLoading = query.isLoading;
  const isError =
    !query.isLoading && (query.isError || query.data === undefined);
  const data = query.data;

  return { isLoading, isError, data };
}
