import React from "react";

export default function () {
  return (
    <svg
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.90609 0.142578H1C0.447715 0.142578 0 0.590293 0 1.14258V9.14258C0 9.69486 0.447715 10.1426 1 10.1426H11C11.5523 10.1426 12 9.69486 12 9.14258V3.14258C12 2.59029 11.5523 2.14258 11 2.14258H6C5.69795 2.14258 5.41209 2.00606 5.22224 1.77114L3.90609 0.142578Z"
        fill="#0199F2"
      />
    </svg>
  );
}
