import React from "react";

export default function () {
  return (
    <section className="Terms TextPage">
      <div className="container">
        <div className="TextPage__inner ">
          <h3>Terms of Service</h3>
          <div>
            <p>
              These Terms of Service govern your use of the website located at{" "}
              <a href="https://academysync.com">https://academysync.com</a> and
              any related services provided by AcademySync LLC.{" "}
            </p>
            <p>
              By accessing{" "}
              <a href="https://academysync.com">https://academysync.com</a>, you
              agree to abide by these Terms of Service and to comply with all
              applicable laws and regulations. If you do not agree with these
              Terms of Service, you are prohibited from using or accessing this
              website or using any other services provided by AcademySync LLC.{" "}
            </p>
            <p>
              We, AcademySync LLC, reserve the right to review and amend any of
              these Terms of Service at our sole discretion. Upon doing so, we
              will update this page. Any changes to these Terms of Service will
              take effect immediately from the date of publication.{" "}
            </p>
            <p>These Terms of Service were last updated on 28 January 2022. </p>
            <h4>Limitations of Use</h4>
            <p>
              By using this website, you warrant on behalf of yourself, your
              users, and other parties you represent that you will not:{" "}
            </p>
            <ol>
              <li>
                modify, copy, prepare derivative works of, decompile, or reverse
                engineer any materials and software contained on this website;
              </li>
              <li>
                remove any copyright or other proprietary notations from any
                materials and software on this website;
              </li>
              <li>
                transfer the materials to another person or “mirror” the
                materials on any other server;
              </li>
              <li>
                knowingly or negligently use this website or any of its
                associated services in a way that abuses or disrupts our
                networks or any other service AcademySync LLC provides;
              </li>
              <li>
                use this website or its associated services to transmit or
                publish any harassing, indecent, obscene, fraudulent, or
                unlawful material;
              </li>
              <li>
                use this website or its associated services in violation of any
                applicable laws or regulations;
              </li>
              <li>
                use this website in conjunction with sending unauthorized
                advertising or spam;
              </li>
              <li>
                harvest, collect, or gather user data without the user’s
                consent; or
              </li>
              <li>
                use this website or its associated services in such a way that
                may infringe the privacy, intellectual property rights, or other
                rights of third parties.
              </li>
            </ol>
            <h4>Intellectual Property</h4>
            <p>
              The intellectual property in the materials contained in this
              website are owned by or licensed to AcademySync LLC and are
              protected by applicable copyright and trademark law. We grant our
              users permission to download one copy of the materials for
              personal, non-commercial transitory use.{" "}
            </p>
            <p>
              This constitutes the grant of a license, not a transfer of title.
              This license shall automatically terminate if you violate any of
              these restrictions or the Terms of Service, and may be terminated
              by AcademySync LLC at any time.{" "}
            </p>

            <h4> Definition of “Data” </h4>

            <p>
              Data include all Personally Identifiable Information (PII) and
              other non-public information. Data include, but are not limited
              to, student data, metadata, and user content.{" "}
            </p>
            <p>
              {" "}
              Data may not be used for any purpose other than the specific
              purpose(s) outlined in this Agreement.{" "}
            </p>

            <h4> Data De-Identification </h4>
            <p>
              AcademySync LLC may use deidentified Data for product development,
              research, or other purposes. De-identified Data will have all
              direct and indirect personal identifiers removed. This includes,
              but is not limited to, name, ID numbers, date of birth,
              demographic information, location information, and school ID.
              Furthermore, Provider agrees not to attempt to re-identify
              deidentified Data and not to transfer de-identified Data to any
              party unless that party agrees not to attempt reidentification.
            </p>

            <h4> Data Collection & Use </h4>

            <p>
              AcademySync LLC will only collect Data necessary to fulfill its
              duties as outlined in this Agreement. Student Data will not be
              used for advertising or marketing purposes.{" "}
            </p>

            <p>
              Data will be collected solely for the purpose of fulfilling its
              duties and providing services under this Agreement, and for
              improving services under this Agreement{" "}
            </p>

            <p>
              {" "}
              The Provider is prohibited from mining Data for any purposes other
              than those agreed to by the parties.{" "}
            </p>
            <p>
              {" "}
              Data cannot be shared with any additional parties without prior
              consent of the school.{" "}
            </p>

            <h4> Data Transfer or Destruction </h4>

            <p>
              {" "}
              AcademySync LLC will ensure that all Data in its possession and in
              the possession of any subcontractors, or agents to which the
              Provider may have transferred Data, are destroyed or transferred
              to the School under the direction of the School when the Data are
              no longer needed for their specified purpose, at the request of
              the School.{" "}
            </p>

            <h4> Rights and License in and to Data </h4>

            <p>
              {" "}
              Parties agree that all rights, including all intellectual property
              rights, shall remain the exclusive property of the School, and
              AcademySync LLC has a limited, nonexclusive license solely for the
              purpose of performing its obligations as outlined in the
              Agreement. This Agreement does not give AcademySync LLC any
              rights, implied or otherwise, to Data, content, or intellectual
              property, except as expressly stated in the Agreement. This
              includes the right to sell or trade Data.{" "}
            </p>

            <h4> Data Access </h4>

            <p>
              {" "}
              Any Data held by AcademySync LLC on its online services will be
              made available to the School upon request by the School.{" "}
            </p>

            <h4> Data Security </h4>
            <p>
              {" "}
              AcademySync LLC will store and process Data in accordance with
              industry best practices on all of its online services. This
              includes appropriate administrative, physical, and technical
              safeguards to secure Data from unauthorized access, disclosure,
              and use. Provider will conduct periodic risk assessments and
              remediate any identified security vulnerabilities in a timely
              manner. Provider will also have a written incident response plan,
              to include prompt notification of the School in the event of a
              security or privacy incident, as well as best practices for
              responding to a breach of PII. Provider agrees to share its
              incident response plan upon request.{" "}
            </p>

            <h4>User-Generated Content</h4>

            <p>
              You retain your intellectual property ownership rights over
              content you submit to us for publication on our website. We will
              never claim ownership of your content, but we do require a license
              from you in order to use it.{" "}
            </p>
            <p>
              When you use our website or its associated services to post,
              upload, share, or otherwise transmit content covered by
              intellectual property rights, you grant to us a non-exclusive,
              royalty-free, transferable, sub-licensable, worldwide license to
              use, distribute, modify, run, copy, publicly display, translate,
              or otherwise create derivative works of your content in a manner
              that is consistent with your privacy preferences and our Privacy
              Policy.{" "}
            </p>
            <p>
              The license you grant us can be terminated at any time by deleting
              your content or account. However, to the extent that we (or our
              partners) have used your content in connection with commercial or
              sponsored content, the license will continue until the relevant
              commercial or post has been discontinued by us.{" "}
            </p>
            <p>
              You give us permission to use your username and other identifying
              information associated with your account in a manner that is
              consistent with your privacy preferences, and our Privacy Policy.{" "}
            </p>
            <h4>Liability</h4>
            <p>
              Our website and the materials on our website are provided on an
              'as is' basis. To the extent permitted by law, AcademySync LLC
              makes no warranties, expressed or implied, and hereby disclaims
              and negates all other warranties including, without limitation,
              implied warranties or conditions of merchantability, fitness for a
              particular purpose, or non-infringement of intellectual property,
              or other violation of rights.{" "}
            </p>
            <p>
              In no event shall AcademySync LLC or its suppliers be liable for
              any consequential loss suffered or incurred by you or any third
              party arising from the use or inability to use this website or the
              materials on this website, even if AcademySync LLC or an
              authorized representative has been notified, orally or in writing,
              of the possibility of such damage.{" "}
            </p>
            <p>
              In the context of this agreement, &ldquo;consequential loss&rdquo;
              includes any consequential loss, indirect loss, real or
              anticipated loss of profit, loss of benefit, loss of revenue, loss
              of business, loss of goodwill, loss of opportunity, loss of
              savings, loss of reputation, loss of use and/or loss or corruption
              of data, whether under statute, contract, equity, tort (including
              negligence), indemnity, or otherwise.{" "}
            </p>
            <p>
              Because some jurisdictions do not allow limitations on implied
              warranties, or limitations of liability for consequential or
              incidental damages, these limitations may not apply to you.{" "}
            </p>
            <h4>Accuracy of Materials</h4>
            <p>
              The materials appearing on our website are not comprehensive and
              are for general information purposes only. AcademySync LLC does
              not warrant or make any representations concerning the accuracy,
              likely results, or reliability of the use of the materials on this
              website, or otherwise relating to such materials or on any
              resources linked to this website.{" "}
            </p>
            <h4>Links</h4>
            <p>
              AcademySync LLC has not reviewed all of the sites linked to its
              website and is not responsible for the contents of any such linked
              site. The inclusion of any link does not imply endorsement,
              approval, or control by AcademySync LLC of the site. Use of any
              such linked site is at your own risk and we strongly advise you
              make your own investigations with respect to the suitability of
              those sites.{" "}
            </p>
            <h4>Right to Terminate</h4>
            <p>
              We may suspend or terminate your right to use our website and
              terminate these Terms of Service immediately upon written notice
              to you for any breach of these Terms of Service.{" "}
            </p>
            <h4>Severance</h4>
            <p>
              Any term of these Terms of Service which is wholly or partially
              void or unenforceable is severed to the extent that it is void or
              unenforceable. The validity of the remainder of these Terms of
              Service is not affected.{" "}
            </p>
            <h4>Governing Law</h4>
            <p>
              These Terms of Service are governed by and construed in accordance
              with the laws of United States. You irrevocably submit to the
              exclusive jurisdiction of the courts in that State or location.{" "}
            </p>
            <h4>Modification of Terms of Service</h4>
            <p>
              AcademySync will not change how Data are collected, used, or
              shared under the terms of this Agreement in any way without
              advance notice to all schools with a subscription to the service.
            </p>
            <h4>Contact Us</h4>
            <p>
              For any questions or concerns regarding these terms and
              conditions, you may contact us using the following details:{" "}
            </p>

            <p>AcademySync LLC </p>

            <p className="mg-b-0"> Email: info@academysync.com </p>

            <p className="mg-b-0"> Phone: 888-701-6811 </p>
          </div>
        </div>
      </div>
    </section>
  );
}
