import React from "react";
import { Switch, Route } from "react-router-dom";
import { Title } from "../../../components/dashboard";
import { ROUTES } from "../../../utils/constants";
import Root from "./Root";

export default function () {
  return (
    <div className="Tasks dashboard__item">
      <Title title="Tasks" />
      <Switch>
        <Route path={ROUTES.tasks} component={Root} exact />
      </Switch>
    </div>
  );
}
