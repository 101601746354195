import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { serialize } from "object-to-formdata";
import {
  HeaderImportStudent,
  Step1,
  Step2,
  ModalAddDocument,
  Step3,
} from "../../../../components/dashboard/students/importStudent";
import {
  getImporterRows,
  getImporterStatus,
  importStudent,
} from "../../../../redux/actions/students";
import { handleErrorsAfterCallingAPI } from "../../../../utils/helpers";
import { openMessage } from "../../../../redux/actions/message";

export default function () {
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(1);
  const [form, setForm] = React.useState({
    is_type: "",
    file: [],
  });
  const [countDocument, setCountDocument] = React.useState(null);
  const [openModalAddDocument, setOpenModalAddDocument] = React.useState(false);
  const [dataSummary, setDataSummary] = React.useState({});
  const [errorFile, setErrorFile] = React.useState("");

  const storeFileRow = useSelector((store) => store.students.import.fileRow);
  const storeImportStudent = useSelector(
    (store) => store.students.import.importStudent
  );
  const storeFileStatus = useSelector(
    (store) => store.students.import.fileStatus
  );

  const isSubmittingStep2 = storeFileRow.loading;
  const isSubmittingImport =
    storeImportStudent.loading || storeFileStatus.loading;

  const handleSubmitStep1 = async (event) => {
    event.preventDefault();

    setStep(2);
  };

  const handleSubmitStep2 = async (event) => {
    event.preventDefault();
    try {
      const response = await dispatch(
        getImporterRows(serialize({ file: form.file[0] }))
      );
      if (response === 0) {
        setErrorFile("The file is empty now, please choose another file");
      } else {
        setCountDocument(response);
        setOpenModalAddDocument(!openModalAddDocument);
      }
    } catch (error) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleSubmitStep3 = async (event) => {
    event.preventDefault();
    setForm({
      ...form,
      file: [],
    });
    setStep(2);
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  const handleDropzone = (dropped) => {
    setForm({
      ...form,
      file: dropped,
    });
    setErrorFile("");
  };

  const handleCancelAdd = () => {
    setOpenModalAddDocument(false);
  };

  const callApiGetStatus = async (response) => {
    const status = await dispatch(getImporterStatus(response.job_id));
    setDataSummary(status);
    setOpenModalAddDocument(false);
    setStep(3);
    return status;
  };

  const handleAddDocument = async () => {
    const formData = { ...form, file: form.file[0] };
    try {
      const response = await dispatch(
        importStudent(serialize({ import: formData }))
      );
      try {
        const status = await callApiGetStatus(response);
        if (status.status !== "complete") {
          const timer = setInterval(async () => {
            const dataResponse = await callApiGetStatus(response);
            if (dataResponse.status === "complete") {
              clearInterval(timer);
            }
          }, 3000);
        }
      } catch (error) {
        handleErrorsAfterCallingAPI(error, form, {}, dispatch);
      }
    } catch (error) {
      dispatch(
        openMessage({
          title:
            "Wrong type file, please choose a correct template from the import instruction!",
          type: "error",
          timeout: 10000,
        })
      );
      setOpenModalAddDocument(false);
    }
  };

  const handleSelectType = (is_type) => () => {
    setForm({
      ...form,
      is_type,
    });
    setErrorFile("");
  };

  const handleDeleteDocument = (documentIndex) => () => {
    setForm({
      ...form,
      file: form.file.filter((item, index) => index !== documentIndex),
    });
  };

  return (
    <div className="Import-student">
      <HeaderImportStudent step={step} />
      <div className="Survey-steps__inner">
        {step === 1 && (
          <Step1
            onSubmit={handleSubmitStep1}
            onSelectType={handleSelectType}
            form={form}
          />
        )}
        {step === 2 && (
          <Step2
            form={form}
            onSubmit={handleSubmitStep2}
            handleBack={handleBack}
            handleDrop={handleDropzone}
            document={form.file}
            countDocument={countDocument}
            onDeleteDocument={handleDeleteDocument}
            isSubmitting={isSubmittingStep2}
            error={errorFile}
          />
        )}
        {step === 3 && (
          <Step3 onSubmit={handleSubmitStep3} dataSummary={dataSummary} />
        )}
        <ModalAddDocument
          type={form.is_type}
          isOpen={openModalAddDocument}
          onBack={handleCancelAdd}
          count={countDocument}
          onSubmit={handleAddDocument}
          isSubmitting={isSubmittingImport}
        />
      </div>
    </div>
  );
}
