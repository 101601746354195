import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { isEmail } from "validator";
import { serialize } from "object-to-formdata";
import logo from "../assets/images/logo2.svg";
import {
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step9,
} from "../components/cirrSurveySteps";
import {
  createCIRRSurvey,
  getCIRRSurveyInfo,
  getCIRRSurveySign,
} from "../redux/actions/cirrIntendSurvey";
import { openMessage } from "../redux/actions/message";
import {
  questionEmail,
  questionName,
  SURVEY_RESULT,
  questionLegallyStudy,
  questionLegallyJob,
  questionPrimaryIntentions,
  ROUTES,
} from "../utils/constants";
import { getParam, handleErrorsAfterCallingAPI } from "../utils/helpers";

const FORM_SURVEY_RESULT = {
  survey_responses_attributes: {
    [questionName]: "",
    [questionEmail]: "",
    [questionLegallyStudy]: "",
    [questionLegallyJob]: "",
    [questionPrimaryIntentions]: "",
  },
};

export default function () {
  const dispatch = useDispatch();
  const [step, setStep] = React.useState(1);
  const [formSurvey, setFormSurvey] = React.useState({ ...FORM_SURVEY_RESULT });
  const [formErrors, setFormErrors] = React.useState({});
  const history = useHistory();
  const storeSurveyInfo = useSelector((store) => store.cirrIntendSurvey.info);
  const storeCreate = useSelector((store) => store.cirrIntendSurvey.create);
  const storeSign = useSelector((store) => store.cirrIntendSurvey.sign);
  const schoolData = storeSurveyInfo?.data?.school || {};
  const status = storeSurveyInfo?.data?.status || "";

  const isLoading = storeCreate.loading || storeSign.loading;
  const token = getParam("token");

  const handleGetUserInfo = React.useCallback(async () => {
    try {
      await dispatch(getCIRRSurveyInfo({}, token));
    } catch (err) {
      dispatch(
        openMessage({
          title: "Your token is invalid!",
          type: "error",
        })
      );
      // history.push(ROUTES.home);
    }
  }, [token]);

  React.useEffect(() => {
    handleGetUserInfo();
  }, [handleGetUserInfo]);

  React.useEffect(() => {
    if (status === "completed") {
      history.push(
        `${ROUTES.cirrSurveyIntendComplete}?token=${token}&event=signing_complete`
      );
    }
  }, [status, token]);

  React.useEffect(() => {
    if (storeSurveyInfo.data) {
      setFormSurvey({
        ...formSurvey,
        survey_responses_attributes: {
          ...formSurvey.survey_responses_attributes,
          [questionName]: storeSurveyInfo.data.student
            ? storeSurveyInfo.data.student.full_name
            : "",
          [questionEmail]: storeSurveyInfo.data.student
            ? storeSurveyInfo.data.student.email
            : "",
        },
      });
    }
  }, [storeSurveyInfo.data]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {};
    if (step === 2) {
      if (
        !formSurvey.survey_responses_attributes[questionEmail] ||
        !isEmail(formSurvey.survey_responses_attributes[questionEmail])
      ) {
        errors[questionEmail] = "Email is invalid";
      }

      if (!formSurvey.survey_responses_attributes[questionName]) {
        errors[questionName] = "Name is required";
      }
    }

    setFormErrors(errors);

    if (Object.keys(errors).length) return;

    setStep(step + 1);
  };

  const handleChange = (event) => {
    if (SURVEY_RESULT.includes(event.target.name)) {
      setFormSurvey({
        ...formSurvey,
        survey_responses_attributes: {
          ...formSurvey.survey_responses_attributes,
          [event.target.name]: event.target.value,
        },
      });
    }
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  const handleCheckbox = (e) => {
    setFormSurvey({
      ...formSurvey,
      survey_responses_attributes: {
        ...formSurvey.survey_responses_attributes,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleReviewAndSign = async (event) => {
    event.preventDefault();

    const formData = {
      cirr_student_intent: {},
      survey_result: {
        survey_responses_attributes: [],
      },
    };

    formData.cirr_student_intent.q1 =
      formSurvey.survey_responses_attributes[
        questionLegallyStudy
      ]?.toLocaleLowerCase();
    formData.cirr_student_intent.q2 =
      formSurvey.survey_responses_attributes[
        questionLegallyJob
      ]?.toLocaleLowerCase();
    formData.cirr_student_intent.q3 =
      formSurvey.survey_responses_attributes[questionPrimaryIntentions];

    Object.keys(formSurvey.survey_responses_attributes).forEach((key) => {
      formData.survey_result.survey_responses_attributes.push({
        question: key,
        answer: formSurvey.survey_responses_attributes[key],
      });
    });

    try {
      await dispatch(createCIRRSurvey(serialize(formData), token));
      dispatch(
        openMessage({
          title:
            "Your form has been submitted, Please wait a moment to navigate to the signature page",
          type: "success",
        })
      );
      const docuSignUrl = await dispatch(getCIRRSurveySign({}, token));
      window.location.href = docuSignUrl;
    } catch (error) {
      handleErrorsAfterCallingAPI(error, formSurvey, setFormErrors, dispatch);
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  return (
    <div className="bg-form Survey-steps">
      <Link to="/" className="logo">
        <img src={logo} alt="" />
      </Link>
      <div className="Survey-steps__inner">
        {step === 1 && (
          <Step1 onSubmit={handleSubmit} schoolData={schoolData} />
        )}
        {step === 2 && (
          <Step2
            schoolData={schoolData}
            formSurvey={formSurvey}
            formErrors={formErrors}
            onChange={handleChange}
            onFocus={handleFocus}
            onSubmit={handleSubmit}
            handleBack={handleBack}
          />
        )}
        {step === 3 && (
          <Step3
            schoolData={schoolData}
            formSurvey={formSurvey}
            onSubmit={handleSubmit}
            handleBack={handleBack}
            onCheckbox={handleCheckbox}
          />
        )}
        {step === 4 && (
          <Step4
            schoolData={schoolData}
            formSurvey={formSurvey}
            formErrors={formErrors}
            onSubmit={handleSubmit}
            onCheckbox={handleCheckbox}
            handleBack={handleBack}
          />
        )}

        {step === 5 && (
          <Step5
            schoolData={schoolData}
            formSurvey={formSurvey}
            onSubmit={handleSubmit}
            onCheckbox={handleCheckbox}
            handleBack={handleBack}
          />
        )}
        {step === 6 && (
          <Step9
            schoolData={schoolData}
            onSubmit={handleReviewAndSign}
            handleBack={handleBack}
            isSubmitting={isLoading}
          />
        )}
      </div>
    </div>
  );
}
