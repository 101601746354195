import React from "react";

function Banner() {
  return (
    <section className="Banner">
      <div className="container">
        <div className="Banner__inner">
          <div className="Banner__inner__left">
            <h3>Contact Us</h3>
            <p className="text--xlarge">
              Have any questions? Please fill out the form below and we will be
              in touch shortly.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
