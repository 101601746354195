import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import type { SurveyInfo } from "../api";
import {
  Card,
  Button,
  ButtonArea,
  StepHeader,
  Form,
  RadioGroup,
} from "../components";
import { useSurveyStore } from "../store";

type Props = {
  surveyInfo: SurveyInfo;
  progress?: string;
  onNextClick: () => void;
  onBackClick: () => void;
};

const schema = z.object({
  reasonForNotSeekingEmployment: z.enum(
    [
      "Continuing Education",
      "Military",
      "Not Seeking Employment for Personal Reasons",
    ],
    {
      invalid_type_error: "Required",
    }
  ),
});

type Inputs = z.infer<typeof schema>;

export function ReasonForNotSeekingEmploymentStep(props: Props) {
  const { surveyInfo, progress, onNextClick, onBackClick } = props;
  const [state, setState] = useSurveyStore((store) => [
    store.reasonForNotSeekingEmployment,
    store.setReasonForNotSeekingEmployment,
  ]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: { reasonForNotSeekingEmployment: state },
    resolver: zodResolver(schema),
  });

  const handleBackClick = () => {
    setState(getValues());
    onBackClick();
  };

  const handleNextClick = async () => {
    await handleSubmit((data) => {
      setState(data);
      onNextClick();
    })();
  };

  return (
    <Card progress={progress}>
      <StepHeader
        surveyInfo={surveyInfo}
        title="Which of the following describes your reason for not seeking employment?"
      />

      <Form>
        <RadioGroup<Inputs["reasonForNotSeekingEmployment"]>
          vertical
          options={[
            {
              label: "I am continuing my education",
              value: "Continuing Education",
            },
            { label: "I am in the military", value: "Military" },
            {
              label: "Personal reasons",
              value: "Not Seeking Employment for Personal Reasons",
            },
          ]}
          error={errors?.reasonForNotSeekingEmployment?.message}
          {...register("reasonForNotSeekingEmployment")}
        />
      </Form>

      <ButtonArea>
        <Button label="Back" onClick={handleBackClick} />
        <Button primary label="Continue" onClick={handleNextClick} />
      </ButtonArea>
    </Card>
  );
}
