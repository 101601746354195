import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCampaignDetail } from "redux/actions/campaigns";
import moment from "moment";
import Table from "components/common/Table";

function Detail() {
  const dispatch = useDispatch();
  const storeCampaignDetail = useSelector((store) => store.campaigns.detail);
  const { id } = useParams();
  const [dataTouchPoints, setDataTouchPoints] = useState([]);
  const dataCampaign = storeCampaignDetail.data;

  // we only have 4 touchpoints
  const formatDataTouchPoints = (resDataTouchPoints) => {
    if (resDataTouchPoints?.length !== 4) return [];
    const item = {
      name: "",
      touchPoint1: 0,
      touchPoint2: 0,
      touchPoint3: 0,
      touchPoint4: 0,
    };
    const result = [
      { ...item, name: "Total Surveyed" },
      { ...item, name: "Total Responded" },
      { ...item, name: "Response Rate" },
      { ...item, name: "Total None Respondents" },
      { ...item, name: "Scheduled Date" },
    ];
    resDataTouchPoints.forEach((touchPointItem, index) => {
      const touchPointIndex = index + 1;
      result[0][`touchPoint${touchPointIndex}`] =
        touchPointItem?.total_students?.length;
      result[1][`touchPoint${touchPointIndex}`] =
        touchPointItem?.responded_students?.length;
      result[2][`touchPoint${touchPointIndex}`] = `${Math.round(
        ((touchPointItem.responded_students?.length || 0) /
          (touchPointItem.total_students?.length || 1)) *
          100
      )}%`;
      result[3][`touchPoint${touchPointIndex}`] =
        touchPointItem?.never_responded_students?.length;
      result[4][`touchPoint${touchPointIndex}`] =
        touchPointItem?.scheduled_date;
    });
    return result;
  };

  React.useEffect(() => {
    if (dataCampaign?.campaign_touchpoints?.length) {
      setDataTouchPoints(
        formatDataTouchPoints(dataCampaign?.campaign_touchpoints)
      );
    }
  }, [dataCampaign]);

  React.useEffect(() => {
    dispatch(getCampaignDetail(id));
  }, [id]);

  return (
    <div className="CampaignDetail dashboard__item__inner">
      <div className="CampaignDetail__info-row">
        <p className="text--small gray03">Campaign Name</p>
        <p className="text--small bold">{dataCampaign?.name}</p>
      </div>
      <div className="CampaignDetail__info-row">
        <p className="text--small gray03">Branch</p>
        <p className="text--small bold">{dataCampaign?.branch?.name}</p>
      </div>
      <div className="CampaignDetail__info-row">
        <p className="text--small gray03">Program</p>
        <p className="text--small bold">{dataCampaign?.program?.name}</p>
      </div>
      <div className="CampaignDetail__info-row">
        <p className="text--small gray03">Cohorts</p>
        <p className="text--small bold">
          {dataCampaign?.cohorts?.map((item) => item.name)?.join(", ")}
        </p>
      </div>
      <div className="CampaignDetail__info-row">
        <p className="text--small gray03">Status</p>
        <p className="text--small bold">{dataCampaign?.status}</p>
      </div>
      <div className="CampaignDetail__info-row">
        <p className="text--small gray03">Scheduled date</p>
        <p className="text--small bold">
          {dataCampaign?.scheduled_date
            ? moment(dataCampaign?.scheduled_date).utc().format("MM/DD/YYYY")
            : "-"}
        </p>
      </div>

      <Table
        loading={storeCampaignDetail.loading}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            render: (row) => row?.name,
          },
          {
            title: "TouchPoint #1",
            dataIndex: "touchPoint1",
            render: (row) => row?.touchPoint1,
            align: "right",
          },
          {
            title: "TouchPoint #2",
            dataIndex: "touchPoint2",
            render: (row) => row?.touchPoint2,
            align: "right",
          },
          {
            title: "TouchPoint #3",
            dataIndex: "touchPoint3",
            render: (row) => row?.touchPoint3,
            align: "right",
          },
          {
            title: "TouchPoint #4",
            dataIndex: "touchPoint4",
            render: (row) => row?.touchPoint4,
            align: "right",
          },
        ]}
        data={dataTouchPoints}
        tableProps={{ striped: true }}
      />
    </div>
  );
}

export default Detail;
