import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmail } from "validator";
import { Form } from "../components/login";
import { postLogin } from "../redux/actions/login";
import { openMessage } from "../redux/actions/message";
import { ROUTES } from "../utils/constants";
import { setAuth } from "../utils/helpers";

const DEFAULT_FORM_LOGIN = {
  email: "",
  password: "",
};

export default function () {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form, setForm] = React.useState({ ...DEFAULT_FORM_LOGIN });
  const [formErrors, setFormErrors] = React.useState({});
  const [role, setRole] = React.useState("admin");

  const storeLogin = useSelector((store) => store.login);

  const callApiLogin = async () => {
    try {
      const response = await dispatch(postLogin(form, role));
      setAuth(response, false, role === "student");
      setForm({ ...DEFAULT_FORM_LOGIN });
      if (history.location.state?.nextPathname) {
        history.push(history.location.state.nextPathname);
        return;
      }
      if (role === "student") {
        history.push(ROUTES.dashboardStudent);
        return;
      }
      history.push(
        response?.data?.data?.is_verified ? ROUTES.dashboard : ROUTES.onBoarding
      );
    } catch (error) {
      if (error.data && typeof error.data.errors === "object") {
        setFormErrors({
          email: "Wrong email or password.",
          password: "Wrong email or password.",
        });
      } else {
        dispatch(
          openMessage({
            title: "Something went wrong, please try again!",
            type: "error",
          })
        );
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.email || !isEmail(form.email)) {
      errors.email = "Email is invalid";
    }

    if (!form.password) {
      errors.password = "Password is required";
    }

    setFormErrors(errors);

    // call api
    if (!Object.keys(errors).length) {
      callApiLogin();
    }
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  return (
    <Form
      form={form}
      formErrors={formErrors}
      onSubmit={handleSubmit}
      onChange={handleChange}
      onFocus={handleFocus}
      isSubmitting={storeLogin.loading}
      role={role}
      setRole={setRole}
    />
  );
}
