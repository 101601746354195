import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Form,
  ModalAddCode,
  ModalAddLicensure,
} from "../../../components/dashboard/programs";
import { openMessage } from "../../../redux/actions/message";
import {
  createProgram,
  getSOC,
  updateProgram,
} from "../../../redux/actions/programs";
import { OPTION_STATE, ROUTES } from "../../../utils/constants";
import { handleErrorsAfterCallingAPI } from "../../../utils/helpers";

const DEFAULT_FORM_ADD_PROGRAM = {
  name: "",
  description: "",
  cost: undefined,
  program_length: undefined,
  degree_type: "",
  soc_codes_attributes: [],
  licensure_exams_attributes: [],
  days_of_week: [],
  frequency: 1,
};

export default function () {
  const [openModalAddCode, setOpenModalAddCode] = React.useState(false);
  const [openModalAddLicensure, setOpenModalAddLicensure] =
    React.useState(false);
  const [form, setForm] = React.useState({ ...DEFAULT_FORM_ADD_PROGRAM });
  const [formErrors, setFormErrors] = React.useState({});
  const [isLicensing, setIsLicensing] = React.useState("NO");
  const [degreeType, setDegreeType] = React.useState({});
  const [selectedSoc, setSelectedSoc] = React.useState({});
  const [licensure, setLicensure] = React.useState({});
  const [selectedIndexLicensure, setSelectedIndexLicensure] =
    React.useState(null);
  const [licensureErrors, setLicensureErrors] = React.useState({});
  const storePrograms = useSelector((store) => store.programs);
  const storeCreate = useSelector((store) => store.programs.create);
  const storeUpdate = useSelector((store) => store.programs.update);
  const { soc } = storePrograms;
  const dispatch = useDispatch();
  const history = useHistory();
  const isEditing = !!history.location.state;

  const isSubmitting = storeCreate.loading || storeUpdate.loading;

  React.useEffect(() => {
    if (!soc.data.length) {
      dispatch(getSOC());
    }
  }, []);

  React.useEffect(() => {
    const programSelected = history.location.state;
    if (!programSelected) return;
    setForm({
      name: programSelected.name,
      description: programSelected.description,
      cost: programSelected.cost,
      program_length: programSelected.program_length,
      degree_type: programSelected.degree_type,
      days_of_week: programSelected.days_of_week,
      frequency: programSelected.frequency,
      soc_codes_attributes: programSelected.soc_codes.map((item) => ({
        SOCCode: item.code,
        SOCTitle: item.title,
        SOCDefinition: item.description,
      })),
      licensure_exams_attributes: programSelected.licensure_exams,
    });
    setDegreeType({
      label: programSelected.degree_type,
      value: programSelected.degree_type,
    });
    setIsLicensing(
      programSelected.licensure_exams && programSelected.licensure_exams.length
        ? "YES"
        : "NO"
    );
    const newSelectedSoc = {};
    programSelected.soc_codes.forEach((item) => {
      newSelectedSoc[item.code] = {
        SOCCode: item.code,
        SOCTitle: item.title,
        SOCDefinition: item.description,
      };
    });
    setSelectedSoc(newSelectedSoc);
  }, [history.location.state]);

  const handleToggleModalAddCode = () => {
    setOpenModalAddCode(!openModalAddCode);
  };

  const handleToggleModalAddLicensure = () => {
    if (openModalAddLicensure) {
      setLicensure({});
      setLicensureErrors({});
    }

    setOpenModalAddLicensure(!openModalAddLicensure);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.name) {
      errors.name = "Name is required";
    }
    if (!form.cost) {
      errors.cost = "Cost is required";
    }
    if (!(Number(form.program_length) > 0)) {
      errors.program_length = "Program length is required";
    }

    if (!form.degree_type) {
      errors.degree_type = "Type of Degree Awarded is require";
    }

    if (!form.days_of_week?.length) {
      errors.days_of_week = "Days of week is require";
    }

    if (!form.soc_codes_attributes.length) {
      errors.soc_codes_attributes = "SOC codes are required";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length) return null;

    // update program
    if (isEditing) {
      try {
        const programSelected = history.location.state;
        const oldSocCodes = {};
        programSelected.soc_codes.forEach((item) => {
          oldSocCodes[item.code] = item.id;
        });
        const formData = { ...form };
        formData.soc_codes_attributes = formData.soc_codes_attributes.map(
          (item) => {
            const id = oldSocCodes[item.SOCCode]
              ? { id: oldSocCodes[item.SOCCode] }
              : {};
            if (id.id) delete oldSocCodes[item.SOCCode];
            return {
              code: item.SOCCode,
              title: item.SOCTitle,
              description: item.SOCDefinition,
              examples: [],
              ...id,
            };
          }
        );
        // deleted soc code
        Object.keys(oldSocCodes).forEach((code) => {
          formData.soc_codes_attributes.push({
            id: oldSocCodes[code],
            _destroy: "1",
          });
        });

        const oldLicensureIds = {};
        programSelected.licensure_exams.forEach((item) => {
          oldLicensureIds[item.id] = true;
        });
        formData.licensure_exams_attributes.forEach((item) => {
          if (item.id) {
            delete oldLicensureIds[item.id];
          }
        });
        // deleted licensure id
        Object.keys(oldLicensureIds).forEach((id) => {
          formData.licensure_exams_attributes.push({
            id,
            _destroy: "1",
          });
        });

        await dispatch(updateProgram(formData, history.location.state.id));
        dispatch(
          openMessage({
            title: "Your Program has been updated",
            type: "success",
          })
        );
        history.push(ROUTES.programs);
      } catch (err) {
        handleErrorsAfterCallingAPI(
          err,
          DEFAULT_FORM_ADD_PROGRAM,
          setFormErrors,
          dispatch
        );
      }
    } else {
      // create program
      try {
        const formData = { ...form };
        formData.soc_codes_attributes = formData.soc_codes_attributes.map(
          (item) => ({
            code: item.SOCCode,
            title: item.SOCTitle,
            description: item.SOCDefinition,
            examples: [],
          })
        );
        await dispatch(createProgram(formData));
        dispatch(
          openMessage({
            title: "Your Program has been created",
            type: "success",
          })
        );
        history.push(ROUTES.programs);
      } catch (err) {
        handleErrorsAfterCallingAPI(
          err,
          DEFAULT_FORM_ADD_PROGRAM,
          setFormErrors,
          dispatch
        );
      }
    }
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  const handleCheckboxLicensing = (event) => {
    setIsLicensing(event.target.value);
  };

  const handleChangeDegreeType = (selectedItem) => {
    setDegreeType(selectedItem);
    setForm({ ...form, degree_type: selectedItem.value });
  };

  const handleSelectSoc = (dataSoc) => () => {
    setSelectedSoc((prevState) => ({
      ...prevState,
      [dataSoc.SOCCode]: prevState[dataSoc.SOCCode] ? null : dataSoc,
    }));
  };

  const handleAddSoc = () => {
    setForm({
      ...form,
      soc_codes_attributes: Object.values(selectedSoc).filter((item) => !!item),
    });
    handleToggleModalAddCode();
  };

  const handleCancelAddCode = () => {
    const newSelectedSoc = {};
    form.soc_codes_attributes.forEach((item) => {
      newSelectedSoc[item.SOCCode] = item;
    });
    setSelectedSoc(newSelectedSoc);
    handleToggleModalAddCode();
  };

  const handleAddLicensure = () => {
    const errors = {};

    if (!licensure.is_type) {
      errors.is_type = "This field is required";
    }
    if (licensure.is_type === OPTION_STATE && !licensure.state) {
      errors.state = "This field is required";
    }
    if (!licensure.name) {
      errors.name = "This field is required";
    }
    if (!licensure.licensing_entity) {
      errors.licensing_entity = "This field is required";
    }

    setLicensureErrors(errors);

    if (!Object.keys(errors).length) {
      setForm({
        ...form,
        licensure_exams_attributes:
          selectedIndexLicensure !== null
            ? form.licensure_exams_attributes.map((item, index) =>
                index === selectedIndexLicensure ? licensure : item
              )
            : [...form.licensure_exams_attributes, licensure],
      });
      handleToggleModalAddLicensure();
    }
    setSelectedIndexLicensure(null);
  };

  const handleChangeLicensure = (e) => {
    setLicensure({ ...licensure, [e.target.name]: e.target.value });
  };

  const handleChangeLicensureSelect = (e, name) => {
    const newData = { ...licensure, [name]: e.value };
    if (name === "is_type") delete newData.state;
    setLicensure(newData);
  };

  const handleFocusLicensure = (event) => {
    setLicensureErrors({
      ...licensureErrors,
      [event.target.name]: "",
    });
  };

  const handleDeleteLicensure = (selectedIndex) => () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const licensure_exams_attributes = form.licensure_exams_attributes.filter(
      (item, index) => index !== selectedIndex
    );

    setForm({ ...form, licensure_exams_attributes });
  };

  const handleEditLicensure = (selectedItem, index) => () => {
    setSelectedIndexLicensure(index);
    setLicensure(selectedItem);
    setOpenModalAddLicensure(true);
  };

  const handleBackLicensure = () => {
    handleToggleModalAddLicensure();
    setSelectedIndexLicensure(null);
  };

  const handelDeleteSocCode = (dataSoc) => () => {
    setForm({
      ...form,
      soc_codes_attributes: form.soc_codes_attributes.filter(
        (item) => item !== dataSoc
      ),
    });
  };

  return (
    <>
      <Form
        handleClickAddCode={handleToggleModalAddCode}
        handleClickAddLicensure={handleToggleModalAddLicensure}
        form={form}
        setForm={setForm}
        formErrors={formErrors}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onFocus={handleFocus}
        licensing={isLicensing}
        handleCheckbox={handleCheckboxLicensing}
        handleChangeDegreeType={handleChangeDegreeType}
        degreeType={degreeType}
        isSubmitting={isSubmitting}
        onDelete={handleDeleteLicensure}
        onEdit={handleEditLicensure}
        onDeleteSocCode={handelDeleteSocCode}
        isEditing={isEditing}
      />
      <ModalAddCode
        isOpen={openModalAddCode}
        onBack={handleCancelAddCode}
        onAdd={handleAddSoc}
        soc={soc.data}
        onSelectSoc={handleSelectSoc}
        selectedSoc={selectedSoc}
      />
      <ModalAddLicensure
        isEditing={selectedIndexLicensure !== null}
        isOpen={openModalAddLicensure}
        onBack={handleBackLicensure}
        onAdd={handleAddLicensure}
        data={licensure}
        errors={licensureErrors}
        onChange={handleChangeLicensure}
        onFocus={handleFocusLicensure}
        onChangeSelect={handleChangeLicensureSelect}
      />
    </>
  );
}
