import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { StudentList } from "../../../components/dashboard/reporting";
import { SORT_NAME } from "../../../utils/constants";
import { Loading } from "../../../components/common";
import { getSortedData } from "../../../utils/helpers";
import { setSelectedFilterReport } from "../../../redux/actions/reporting";

const PAGE_SIZE = 10;

function StudentListComponent() {
  const dispatch = useDispatch();
  const storeReportingPreview = useSelector((store) => store.reporting.preview);
  const [params, setParams] = React.useState({});
  const [filter, setFilter] = React.useState({});
  const page = useSelector((store) => store.reporting.selected.page);
  const [dataHandled, setDataHandled] = React.useState([]);
  const [dataShowing, setDataShowing] = React.useState([]);

  const storeSelected = useSelector((store) => store.reporting.selected);
  const reportName = useParams().reportName;

  // first time to load data
  React.useEffect(() => {
    if (!storeReportingPreview.data || !storeReportingPreview.data.student_list)
      return;
    let newData = [];
    if (reportName === "accet") {
      if (
        storeSelected.month?.value &&
        storeReportingPreview.data.student_list[storeSelected.month.value]
      ) {
        newData = [
          ...storeReportingPreview.data.student_list[storeSelected.month.value],
        ];
      }
    } else {
      newData = [...storeReportingPreview.data.student_list];
    }

    setDataShowing([...newData].splice((page - 1) * PAGE_SIZE, PAGE_SIZE));
    setDataHandled([...newData]);
  }, [storeReportingPreview.data, reportName, storeSelected.month?.value]);

  // change filter
  React.useEffect(() => {
    if (!Object.keys(filter).length) return;
    let newData = [];
    if (reportName === "accet") {
      if (
        storeSelected.month?.value &&
        storeReportingPreview.data.student_list[storeSelected.month?.value]
      ) {
        newData = [
          ...storeReportingPreview.data.student_list[storeSelected.month.value],
        ];
      }
    } else {
      newData = [...storeReportingPreview.data.student_list];
    }

    if (filter.original_cohort) {
      newData = newData.filter(
        (item) => item.original_cohort === filter.original_cohort
      );
    }

    if (filter.final_cohort) {
      newData = newData.filter(
        (item) => item.final_cohort === filter.final_cohort
      );
    }

    newData = newData.filter((item) =>
      item.name.toLowerCase().includes(filter.name?.toLowerCase() || "")
    );

    if (filter.enrollment_status) {
      newData = newData.filter(
        (item) => item.enrollment_status === filter.enrollment_status
      );
    }

    if (filter.placement_status) {
      newData = newData.filter(
        (item) => item.placement_status === filter.placement_status
      );
    }

    if (filter.completion_status) {
      newData = newData.filter(
        (item) => item.completion_status === filter.completion_status
      );
    }

    setDataHandled([...newData]);
    setDataShowing([...newData].splice(0, PAGE_SIZE));
    dispatch(setSelectedFilterReport({ page: 1 }));
  }, [filter, reportName, storeSelected.month?.value]);

  // change sort
  React.useEffect(() => {
    if (!Object.keys(params).length) return;
    let newData = [];
    if (reportName === "accet") {
      if (
        storeSelected.month?.value &&
        storeReportingPreview.data.student_list[storeSelected.month?.value]
      ) {
        newData = [
          ...storeReportingPreview.data.student_list[storeSelected.month.value],
        ];
      }
    } else {
      newData = [...storeReportingPreview.data.student_list];
    }
    const sortKey = params[SORT_NAME];
    if (sortKey) {
      const splitSort = sortKey.split(" ");
      const fieldName = splitSort[0];
      const direction = splitSort[1];
      newData = getSortedData(newData, fieldName, direction === "asc");

      setDataHandled([...newData]);
      setDataShowing(newData.splice(0, PAGE_SIZE));

      dispatch(setSelectedFilterReport({ page: 1 }));
    }
  }, [params, reportName, storeSelected.month?.value]);

  const onPageChange = ({ selected }) => {
    const activePage = selected + 1;

    dispatch(setSelectedFilterReport({ page: selected + 1 }));
    setDataShowing(
      [...dataHandled].splice((activePage - 1) * PAGE_SIZE, PAGE_SIZE)
    );
  };

  const handleSearch = (e) => {
    const newFilter = { ...filter, [e.target.name]: e.target.value };
    setFilter(newFilter);
  };

  const handleSort = (column, direction) => {
    const newParams = {
      ...params,
      [SORT_NAME]: `${column} ${direction}`,
    };
    setParams(newParams);
  };

  const handleChangeFilter = (selectedItem, name) => {
    const newFilter = { ...filter, [name]: selectedItem.value };
    setFilter(newFilter);
  };

  if (storeReportingPreview.loading) {
    return <Loading />;
  }

  if (!storeReportingPreview.data || !storeReportingPreview.data.student_list) {
    return null;
  }

  let data = [];

  if (reportName === "accet") {
    if (
      storeSelected.month?.value &&
      storeReportingPreview.data?.student_list[storeSelected.month?.value]
    ) {
      data = [
        ...storeReportingPreview.data.student_list[storeSelected.month.value],
      ];
    }
  } else {
    data = [...storeReportingPreview.data.student_list];
  }

  return (
    <StudentList
      loading={storeReportingPreview.loading}
      data={data}
      dataShowing={dataShowing}
      params={params}
      onSearch={handleSearch}
      onSort={handleSort}
      filter={filter}
      onChangeFilter={handleChangeFilter}
      paginationProps={{
        itemsLength: dataHandled.length,
        perPage: PAGE_SIZE,
        onPageChange,
        activePage: page,
      }}
    />
  );
}

export default StudentListComponent;
