import * as types from "../constants";
import request from "../../utils/request";

export function getTasks(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_TASKS });
    return request()
      .get("/tasks", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_TASKS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            meta: {
              total: dataResponse.headers.total,
              perPage: dataResponse.headers["per-page"],
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_TASKS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function rejectTask(studentId, taskId) {
  return (dispatch) => {
    dispatch({ type: types.REJECT_TASK });
    return request()
      .post(`/students/${studentId}/tasks/${taskId}/reject`)
      .then((dataResponse) => {
        dispatch({
          type: types.REJECT_TASK_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.REJECT_TASK_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function approveTask(studentId, taskId) {
  return (dispatch) => {
    dispatch({ type: types.APPROVE_TASK });
    return request()
      .post(`/students/${studentId}/tasks/${taskId}/approve`)
      .then((dataResponse) => {
        dispatch({
          type: types.APPROVE_TASK_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.APPROVE_TASK_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
