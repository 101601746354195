import React from "react";
import { useSelector } from "react-redux";
import { General } from "../../../components/dashboard/institution";

function Root() {
  const storeInstitution = useSelector(
    (store) => store.institution.institution
  );

  return <General storeInstitution={storeInstitution} />;
}

export default Root;
