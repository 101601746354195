/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/default-param-last */
import * as types from "../constants";

const initialState = {
  list: {
    data: [],
    meta: {
      total: 0,
      perPage: 10,
    },
    loading: false,
    error: null,
  },
  create: {
    data: {},
    loading: false,
    error: null,
  },
  update: {
    data: {},
    loading: false,
    error: null,
  },
  detail: {
    data: {},
    loading: false,
    error: null,
  },
  delete: {
    data: {},
    loading: false,
    error: null,
  },
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_CAMPAIGNS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case types.GET_CAMPAIGNS_SUCCEED:
      return {
        ...state,
        list: {
          ...state.list,
          data: payload.data,
          meta: payload.meta,
          loading: false,
        },
      };
    case types.GET_CAMPAIGNS_FAIL:
      return {
        ...state,
        list: {
          ...state.list,
          error: payload,
          loading: false,
        },
      };

    // detail
    case types.GET_CAMPAIGN_DETAIL:
      return {
        ...state,
        detail: { ...state.detail, loading: true, error: {} },
      };
    case types.GET_CAMPAIGN_DETAIL_SUCCEED:
      return {
        ...state,
        detail: { ...state.detail, data: payload, loading: false },
      };
    case types.GET_CAMPAIGN_DETAIL_FAIL:
      return {
        ...state,
        detail: { ...state.detail, error: payload, loading: false },
      };

    // create
    case types.CREATE_CAMPAIGN:
      return {
        ...state,
        create: { ...state.create, loading: true, error: {} },
      };
    case types.CREATE_CAMPAIGN_SUCCEED:
      return {
        ...state,
        create: { ...state.create, data: payload, loading: false },
      };
    case types.CREATE_CAMPAIGN_FAIL:
      return {
        ...state,
        create: { ...state.create, error: payload, loading: false },
      };

    // update
    case types.UPDATE_CAMPAIGN:
      return {
        ...state,
        update: { ...state.update, loading: true, error: {} },
      };
    case types.UPDATE_CAMPAIGN_SUCCEED:
      return {
        ...state,
        update: { ...state.update, data: payload, loading: false },
      };
    case types.UPDATE_CAMPAIGN_FAIL:
      return {
        ...state,
        update: { ...state.update, error: payload, loading: false },
      };

    // delete
    case types.DELETE_CAMPAIGN:
      return {
        ...state,
        delete: {
          ...initialState.delete,
          loading: true,
        },
      };
    case types.DELETE_CAMPAIGN_SUCCEED:
      return {
        ...state,
        delete: {
          ...state.delete,
          data: payload,
          loading: false,
        },
      };
    case types.DELETE_CAMPAIGN_FAIL:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: payload,
          loading: false,
        },
      };

    default:
      return state;
  }
}
