import React from "react";
import { Loading, Error } from "../components";
import { useSignDocumentsUrl } from "../api";

export function SignDocumentsStep() {
  const { isLoading, isError, data: url } = useSignDocumentsUrl();

  if (isLoading) return <Loading />;
  if (isError) return <Error />;

  window.location.href = url!;
  return null;
}
