import React from "react";

export default function () {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66683 0.666992C8.88784 0.666992 9.09981 0.75479 9.25609 0.91107L13.4228 5.07774C13.579 5.23402 13.6668 5.44598 13.6668 5.66699V14.8337C13.6668 16.2144 12.5475 17.3337 11.1668 17.3337H2.8335C1.45278 17.3337 0.333496 16.2144 0.333496 14.8337V3.16699C0.333496 1.78628 1.45278 0.666992 2.8335 0.666992H8.66683ZM6.9993 2.33398H2.83347C2.37323 2.33398 2.00014 2.70708 2.00014 3.16732V14.834C2.00014 15.2942 2.37323 15.6673 2.83347 15.6673H11.1668C11.627 15.6673 12.0001 15.2942 12.0001 14.834V7.33398H7.83347C7.40611 7.33398 7.05388 7.01228 7.00574 6.59784L7.00014 6.50065L6.9993 2.33398ZM11.6551 5.66672L8.66598 2.67839L8.66681 5.66672H11.6551Z"
        fill="#0199F2"
      />
    </svg>
  );
}
