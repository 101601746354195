/* eslint-disable react/prop-types */
import React from "react";
import { NavLink } from "react-router-dom";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ArrowDown, Download } from "../../common/icons";
import { SingleSelectCheckbox } from "../../common";
import {
  FILTER_REPORTING_YEAR,
  FILTER_REPORTING_PERIOD,
  FILTER_PROGRAMS,
  FILTER_BRANCHES,
  OPTION_REPORTING_PERIOD,
  OPTION_REPORTING_YEAR,
  FILTER_MONTH,
  MONTH,
} from "../../../utils/constants";
import { getParam } from "../../../utils/helpers";
import { getAllPrograms } from "../../../redux/actions/programs";
import { getBranchesReporting } from "../../../redux/actions/branches";
import { setSelectedFilterReport } from "../../../redux/actions/reporting";

function HeaderReporting({
  reportName,
  onReport,
  onDocumentation,
  onSPFS,
  onCompleter,
  onGraduateIdentificationData,
}) {
  const [show, setShow] = React.useState({
    [FILTER_REPORTING_PERIOD]: false,
    [FILTER_PROGRAMS]: false,
    [FILTER_REPORTING_YEAR]: false,
    [FILTER_MONTH]: false,
  });
  const dispatch = useDispatch();
  const storePrograms = useSelector((store) => store.programs.all);
  const storeBranches = useSelector(
    (store) => store.branches.reportingBranches
  );
  const selected = useSelector((store) => store.reporting.selected);
  const currentYear = moment().get("year");

  React.useEffect(() => {
    dispatch(getAllPrograms());
  }, []);

  React.useEffect(() => {
    if (["cirr", "coe", "accet", "dpos"].includes(reportName))
      dispatch(getBranchesReporting(reportName.toUpperCase()));
  }, [reportName]);

  React.useEffect(() => {
    const newSelected = { ...selected };
    const reportingPeriod = parseFloat(getParam("reporting_period") || 1);
    newSelected.reporting_period = {
      value: reportingPeriod,
      label: "",
    };
    const reportingYear = parseFloat(getParam("reporting_year") || currentYear);
    newSelected.reporting_year = {
      value: reportingYear,
      label: "",
    };

    dispatch(setSelectedFilterReport(newSelected));
  }, [reportName]);

  React.useEffect(() => {
    if (!storePrograms.data.length) return;
    const newSelected = { ...selected };
    const programId = parseFloat(
      getParam("program_id") || storePrograms.data[0].id
    );
    newSelected.programs = {
      value: programId,
      label: "",
    };
    dispatch(setSelectedFilterReport(newSelected));
  }, [storePrograms.data, reportName]);

  React.useEffect(() => {
    if (!storeBranches.data.length) return;
    const newSelected = { ...selected };
    const branchID = parseFloat(
      getParam("branch_id") || storeBranches.data[0].id
    );
    if (reportName === "cirr") {
      newSelected.branches = {
        value: null,
        label: "",
      };
    } else {
      newSelected.branches = {
        value: branchID,
        label: "",
      };
    }

    dispatch(setSelectedFilterReport(newSelected));
  }, [storeBranches.data, reportName]);

  const handleChange = (selectedItem, name) => {
    const newSelected = { ...selected };
    newSelected[name] = selectedItem;
    dispatch(setSelectedFilterReport(newSelected));
  };

  const handleToggle = (name) => () => {
    setShow((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  return (
    <div className="HeaderReporting ">
      <div className="HeaderReporting__inner">
        <div className="title">
          <h4 className="">{reportName}</h4>
        </div>
        <div className="list-filter">
          <SingleSelectCheckbox
            show={show[FILTER_PROGRAMS]}
            name={FILTER_PROGRAMS}
            onChange={handleChange}
            options={storePrograms.data.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            value={selected[FILTER_PROGRAMS]}
            onToggle={handleToggle(FILTER_PROGRAMS)}
            placeholder="Program"
            loading={storePrograms.loading}
          />
          {(reportName === "cirr" ||
            reportName === "coe" ||
            reportName === "accet" ||
            reportName === "dpos") && (
            <SingleSelectCheckbox
              show={show[FILTER_BRANCHES]}
              name={FILTER_BRANCHES}
              onChange={handleChange}
              options={
                reportName === "cirr"
                  ? [
                      { value: "", label: "All branches" },
                      ...storeBranches.data.map((item) => ({
                        value: item.id,
                        label: item.name,
                      })),
                    ]
                  : storeBranches.data.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))
              }
              allowBlankValue
              value={selected[FILTER_BRANCHES]}
              onToggle={handleToggle(FILTER_BRANCHES)}
              placeholder="Branch"
              loading={storeBranches.loading}
            />
          )}
          {reportName === "cirr" && (
            <SingleSelectCheckbox
              show={show[FILTER_REPORTING_PERIOD]}
              name={FILTER_REPORTING_PERIOD}
              onChange={handleChange}
              options={OPTION_REPORTING_PERIOD}
              value={selected[FILTER_REPORTING_PERIOD]}
              onToggle={handleToggle(FILTER_REPORTING_PERIOD)}
              placeholder="Reporting Period"
            />
          )}
          <SingleSelectCheckbox
            show={show[FILTER_REPORTING_YEAR]}
            name={FILTER_REPORTING_YEAR}
            onChange={handleChange}
            options={OPTION_REPORTING_YEAR}
            value={selected[FILTER_REPORTING_YEAR]}
            onToggle={handleToggle(FILTER_REPORTING_YEAR)}
            placeholder="Reporting Year"
            loading={storePrograms.loading}
          />
          {reportName === "accet" && (
            <SingleSelectCheckbox
              show={show[FILTER_MONTH]}
              name={FILTER_MONTH}
              onChange={handleChange}
              options={MONTH}
              value={selected[FILTER_MONTH]}
              onToggle={handleToggle(FILTER_MONTH)}
              placeholder="Month"
            />
          )}
        </div>
        <div className="taps">
          <div className="taps__left">
            <NavLink
              to={{
                pathname: `/dashboard/reporting/${reportName}`,
                search: window.location.search,
              }}
              className="text--small"
              activeClassName="isActive"
              exact
            >
              Cover Sheet
            </NavLink>
            <NavLink
              to={{
                pathname: `/dashboard/reporting/${reportName}/student-list`,
                search: window.location.search,
              }}
              className="text--small"
              activeClassName="isActive"
            >
              Student List
            </NavLink>
            {/* <NavLink
              to={`/dashboard/reporting/${reportName}/change-log`}
              className="text--small"
              activeClassName="isActive"
            >
              Change Log
            </NavLink> */}
          </div>
          <div className="taps__right">
            <Dropdown className="Dropdown Dropdown--light ">
              <Dropdown.Toggle as={ButtonGroup}>
                <Download />
                <p className="text--small primary bold">Export</p>
                <ArrowDown />
              </Dropdown.Toggle>
              <Dropdown.Menu align="right">
                <Dropdown.Item onClick={onReport}>Report</Dropdown.Item>
                {reportName === "bppe" && (
                  <Dropdown.Item onClick={onGraduateIdentificationData}>
                    Graduate Identification Data
                  </Dropdown.Item>
                )}
                {reportName === "bppe" && (
                  <Dropdown.Item onClick={onSPFS}>SPFS Factsheet</Dropdown.Item>
                )}
                {reportName === "twc" && (
                  <Dropdown.Item onClick={onCompleter}>
                    Completer Sheet
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={onDocumentation}>
                  Documentation
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderReporting;
