import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../utils/constants";
import {
  FormGroup,
  MultiSelectCheckbox,
  SingleDatePicker,
  SingleSelectCheckbox,
} from "../../common";

function FormAddCampaign({
  form,
  formErrors,
  onSubmit,
  onChange,
  onFocus,
  handleChangeProgram,
  handleChangeBranch,
  handleChangeCohorts,
  handleCallbackStartDate,
  program,
  branch,
  // eslint-disable-next-line react/prop-types
  dataPrograms,
  // eslint-disable-next-line react/prop-types
  dataBranchs,
  // eslint-disable-next-line react/prop-types
  dataCohorts,
  isEditing,
  isSubmitting,
  showCohorts,
  handleToggleCohorts,
}) {
  // eslint-disable-next-line react/prop-types
  const OPTIONS_PROGRAM = dataPrograms.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  // eslint-disable-next-line react/prop-types
  const OPTIONS_BRANCH = dataBranchs.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  // eslint-disable-next-line react/prop-types
  const OPTIONS_COHORTS = dataCohorts.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const history = useHistory();
  const handleBack = () => {
    history.push(ROUTES.campaigns);
  };

  return (
    <div className="Add-Campaign dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="Add-Campaign__text">
          <p className="text--xlarge bold">
            {isEditing ? "Update Campaign" : "Add Campaign"}
          </p>
        </div>
        <div className="Add-Campaign__inner">
          <FormGroup
            propsInput={{
              name: "name",
              placeholder: "Campaign Name",
              onChange,
              onFocus,
              value: form.name,
              disabled: isSubmitting,
            }}
            error={formErrors.name}
            label="Campaign Name"
          />
          <div className="select">
            <p>Program</p>
            <SingleSelectCheckbox
              onChange={handleChangeProgram}
              name="program"
              options={OPTIONS_PROGRAM}
              value={program}
              error={formErrors.program_id}
              disabled={isSubmitting}
            />
          </div>
          <div className="select">
            <p>Branch</p>
            <SingleSelectCheckbox
              onChange={handleChangeBranch}
              name="branch"
              options={OPTIONS_BRANCH}
              value={branch}
              error={formErrors.branch_id}
              disabled={isSubmitting}
            />
          </div>
          <div className="select">
            <p>Cohorts</p>
            <MultiSelectCheckbox
              show={showCohorts}
              name="cohorts_attribute_ids"
              onChange={handleChangeCohorts}
              options={OPTIONS_COHORTS}
              value={OPTIONS_COHORTS.filter((item) =>
                form.cohorts_attribute_ids.includes(item.value)
              )}
              onToggle={handleToggleCohorts}
              placeholder="Cohorts"
              error={formErrors.cohorts_attribute_ids}
              disabled={isSubmitting}
            />
          </div>
          <div className="Date-picker">
            <p className="Date-picker__label">Start Date</p>
            <SingleDatePicker
              placeholder="Start Date"
              value={form.scheduled_date}
              onChange={handleCallbackStartDate}
              disabled={isSubmitting}
            />
          </div>
        </div>
        <div className="button-group">
          <button
            className="button button--back"
            type="submit"
            onClick={handleBack}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
}

FormAddCampaign.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    name: PropTypes.string,
    branch_id: PropTypes.number,
    program_id: PropTypes.number,
    is_type: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    scheduled_date: PropTypes.any,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  program: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  branch: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  handleChangeProgram: PropTypes.func.isRequired,
  handleChangeBranch: PropTypes.func.isRequired,
  handleCallbackStartDate: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

FormAddCampaign.defaultProps = {
  form: {
    name: "",
    branch_id: null,
    program_id: null,
    is_type: "",
    scheduled_date: "",
  },
  formErrors: {},
  program: {},
  branch: {},
  isEditing: false,
  isSubmitting: false,
};

export default FormAddCampaign;
