/* eslint-disable no-nested-ternary */
import React from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import {
  reportingMapLabels,
  reportingMapLabelsForTable,
} from "../../../utils/constants";
import CollapseReporting from "./CollapseReporting";

function CoverSheet({ dataInfo = {} }) {
  const Params = useParams();
  const reportName = Params.reportName;
  return (
    <div className="CoverSheet dashboard__item__inner">
      {dataInfo && Object.keys(dataInfo).length ? (
        <div className="CoverSheet__inner">
          <div className="info">
            <p className="text--xlarge bold title">{dataInfo.program_name}</p>
            {dataInfo.reporting_period && (
              <p className="text bold">
                {`${moment(dataInfo.reporting_period[0]).format(
                  "MMMM D, YYYY"
                )} - ${moment(dataInfo.reporting_period[1]).format(
                  "MMMM D, YYYY"
                )} `}
              </p>
            )}

            <div className="list__item">
              {Object.keys(dataInfo).map((key) => {
                if (
                  (typeof dataInfo[key] === "object" &&
                    key !== "reporting_period" &&
                    dataInfo[key] !== null) ||
                  !Object.keys(reportingMapLabels).includes(key)
                )
                  return null;
                return (
                  <div key={key}>
                    <p className="text--small gray03">
                      {reportingMapLabels[key]}
                    </p>
                    <p className="text--small bold">
                      {reportingMapLabels[key] === "Reporting Period"
                        ? `${moment(dataInfo[key][0]).format(
                            "M/D/YYYY"
                          )} - ${moment(dataInfo[key][1]).format("M/D/YYYY")} `
                        : dataInfo[key]
                        ? dataInfo[key]
                        : "-"}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
          {Object.keys(dataInfo).map((key) => {
            if (
              (typeof dataInfo[key] !== "object" &&
                key === "reporting_period") ||
              (key === "hired_by_school" && reportName === "bppe") ||
              !Object.keys(reportingMapLabelsForTable).includes(key)
            )
              return null;
            return (
              <CollapseReporting
                data={dataInfo[key]}
                dataCoversheet={dataInfo}
                title={reportingMapLabelsForTable[key]}
                key={key}
              />
            );
          })}
        </div>
      ) : (
        <p className="text--large __noData">NO DATA</p>
      )}
    </div>
  );
}

export default CoverSheet;
