import React from "react";
import { useDropzone } from "react-dropzone";
import cn from "classnames/bind";
import css from "./UploadDocumentsStep.module.scss";
import {
  Card,
  ButtonArea,
  Button,
  StepHeader,
  UploadedFile,
} from "../components";
import type { SurveyInfo } from "../api";
import { usePreviouslyUploadedFiles } from "../api";
import { useSurveyStore } from "../store";

const cx = cn.bind(css);

type Props = {
  surveyInfo: SurveyInfo;
  progress?: string;
  onNextClick: () => void;
  onBackClick: () => void;
};

export function UploadDocumentsStep(props: Props) {
  const { surveyInfo, progress, onNextClick, onBackClick } = props;

  const { deleteFile } = usePreviouslyUploadedFiles();
  const [files, addFiles, removeFile, previouslyUploadedFiles] = useSurveyStore(
    (store) => [
      store.uploadedFiles,
      store.addUploadedFiles,
      store.removeUploadedFile,
      store.previouslyUploadedFiles,
    ]
  );

  const handleDrop: OnDropHandler = (acceptedFiles) => {
    addFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  return (
    <Card progress={progress}>
      <StepHeader surveyInfo={surveyInfo} title="Upload relevant documents" />

      <section
        data-testid="dropzone"
        className={cx("area", { isDragActive })}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <div className={css.button}>Select file</div>
        <div className={css.text}>or drop file here</div>
      </section>

      <section className={css.files}>
        {files.map((file, index) => (
          <UploadedFile
            key={file.name}
            name={file.name}
            onDelete={() => removeFile(index)}
          />
        ))}
      </section>

      {previouslyUploadedFiles && (
        <section className={css.previouslyUploadedFiles}>
          <h2>Previously uploaded files</h2>
          {previouslyUploadedFiles.map((file) => (
            <UploadedFile
              key={file.id}
              name={file.filename}
              url={file.url}
              onDelete={() => deleteFile(file.id)}
            />
          ))}
        </section>
      )}

      <ButtonArea>
        <Button label="Back" onClick={onBackClick} />
        <Button primary label="Continue" onClick={onNextClick} />
      </ButtonArea>
    </Card>
  );
}
