import { useEffect, useRef } from "react";

type Props = {
  isAutoComplete: boolean;
  onPlaceSelected?: (result: AutocompleteAddress) => void;
};

export function useAutoComplete(props: Props) {
  const { isAutoComplete, onPlaceSelected } = props;
  const inputRef = useRef<HTMLInputElement>();
  const autocompleteRef = useRef<google.maps.places.Autocomplete>();

  useEffect(() => {
    if (!isAutoComplete) return;
    if (inputRef.current === undefined) return;

    autocompleteRef.current = new google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: ["address"],
        componentRestrictions: { country: "us" },
      }
    );
    autocompleteRef.current.setFields([
      "address_components",
      "formatted_address",
    ]);
    const listener = google.maps.event.addListener(
      autocompleteRef.current,
      "place_changed",
      () => {
        if (onPlaceSelected !== undefined) {
          const result = autocompleteRef.current?.getPlace();
          const components = result?.address_components ?? null;

          if (components === null) {
            onPlaceSelected(null);
            return;
          }

          const address = [
            components.find((r) => r.types.includes("street_number"))
              ?.long_name,
            components.find((r) => r.types.includes("route"))?.long_name,
          ].join(" ");
          const city =
            components.find((r) => r.types.includes("locality"))?.long_name ??
            "";
          const state =
            components.find((r) =>
              r.types.includes("administrative_area_level_1")
            )?.short_name ?? "";
          const zip =
            components.find((r) => r.types.includes("postal_code"))
              ?.long_name ?? "";

          onPlaceSelected({ address, city, state, zip });
        }
      }
    );

    return () => {
      google.maps.event.removeListener(listener);
      document
        .querySelectorAll(".pac-container")
        .forEach((container) => container.remove());
    };
  }, [isAutoComplete, autocompleteRef, inputRef]);

  return { inputRef };
}
