import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FormGroup, MultiSelectCheckbox } from "../../common";
import { ROUTES } from "../../../utils/constants";

function AddAdministrator({
  form,
  formErrors,
  onSubmit,
  onChange,
  onFocus,
  handleChangeRole,
  role,
  handleToggle,
  show,
  storeAllRoles,
  isSubmitting,
}) {
  const OPTIONS_ROLE =
    storeAllRoles.data &&
    storeAllRoles.data.map((item) => ({ value: item.id, label: item.name }));

  const history = useHistory();
  const handleBack = () => {
    history.push(ROUTES.administrators);
  };
  return (
    <div className="AddAdministrator  dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="AddAdministrator__text">
          <p className="text--xlarge bold">Invite Administrator</p>
        </div>
        <div className="AddAdministrator__inner">
          <FormGroup
            propsInput={{
              name: "first_name",
              placeholder: "First Name",
              onChange,
              onFocus,
              value: form.first_name,
              disabled: isSubmitting,
            }}
            error={formErrors.first_name}
            label="First Name"
          />
          <FormGroup
            propsInput={{
              name: "last_name",
              placeholder: "Last Name",
              onChange,
              onFocus,
              value: form.last_name,
              disabled: isSubmitting,
            }}
            error={formErrors.last_name}
            label="Last Name"
          />
          <FormGroup
            propsInput={{
              name: "email",
              placeholder: "Email",
              type: "email",
              onChange,
              onFocus,
              value: form.email,
              disabled: isSubmitting,
            }}
            error={formErrors.email}
            label="Email"
          />
          <div className="select">
            <p>Role</p>
            <MultiSelectCheckbox
              onChange={handleChangeRole}
              show={show}
              onToggle={handleToggle}
              name="branch"
              options={OPTIONS_ROLE}
              value={role}
              error={formErrors.role}
              disabled={isSubmitting}
            />
          </div>
        </div>
        <div className="button-group">
          <button
            className="button button--back"
            type="submit"
            onClick={handleBack}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
}

AddAdministrator.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  handleChangeRole: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  role: PropTypes.array,
  handleToggle: PropTypes.func.isRequired,
  show: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  storeAllRoles: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

AddAdministrator.defaultProps = {
  form: {
    first_name: "",
    last_name: "",
    email: "",
  },
  formErrors: {},
  role: [],
  show: false,
  storeAllRoles: {},
  isSubmitting: false,
};

export default AddAdministrator;
