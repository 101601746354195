import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { PieChart, Pie, Cell } from "recharts";
import noChart1 from "../../../assets/images/noChart1.png";
import noChart2 from "../../../assets/images/noChart2.png";

const COLORS = ["#244C7C", "#FFBC42", "#0199F2"];
const COLORS2 = ["#244C7C", "#0199F2"];

function Chart() {
  const storeDashboard = useSelector((store) => store.dashboard);
  const { data } = storeDashboard;
  const dataEnroll = data.enrollment || {};
  const dataCom = data.completion || {};

  const dataEnrollment = [
    {
      name: "Newly Enrolled",
      value: dataEnroll.newly_enrolled || 0,
    },
    {
      name: "Re-Enrolled",
      value: dataEnroll.re_enrolled || 0,
    },
    {
      name: "Transferred-in",
      value: dataEnroll.transfer || 0,
    },
  ];
  const dataEnrollmentFilter = dataEnrollment.filter(
    (item) => item.value !== 0
  );
  const totalEnrollment =
    dataEnrollment[0].value + dataEnrollment[1].value + dataEnrollment[2].value;

  const dataCompletion = [
    { name: "Complete", value: dataCom.complete || 0 },
    {
      name: "Non-Complete",
      value: dataCom.incomplete || 0,
    },
  ];
  const totalCompletion = dataCompletion[0].value + dataCompletion[1].value;
  const percentComplete = (
    (dataCompletion[0].value * 100) /
    totalCompletion
  ).toFixed(0);

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    name,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.13;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={name === "Newly Enrolled" ? "white" : "#16222F"}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        dx={percent === 1 ? 2 : 0}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  // static jsfiddleUrl = 'https://jsfiddle.net/alidingling/3Leoa7f4/';
  return (
    <div className="Chart ">
      <div className="Chart__inner">
        <div className="Chart__inner__left dashboard--children">
          <p className="text--title bold title">Enrollment</p>
          {dataEnroll.total_records ? (
            <div className="chart__item enrollment-chart">
              <PieChart width={180} height={180}>
                <Pie
                  data={dataEnrollmentFilter}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  innerRadius={45}
                  outerRadius={90}
                  fill="#8884d8"
                  paddingAngle={1}
                  dataKey="value"
                >
                  {dataEnrollmentFilter.map((item, index) => (
                    <Cell
                      key={`cell-enroll-${item.name}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
              <div>
                {dataEnrollmentFilter.map((item) => (
                  <div className="infoChart" key={`text-enroll-${item.name}`}>
                    <span
                      className={classNames({
                        newly: item.name === "Newly Enrolled",
                        "re-enrolled": item.name === "Re-Enrolled",
                        transferred: item.name === "Transferred-in",
                      })}
                    />
                    <p className="text--xsmall bold">
                      {`${((item.value * 100) / totalEnrollment).toFixed(0)}% ${
                        item.name
                      }`}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="Nodata__chart">
              <p className="--noData">
                There’s currently no enrollment data to display
              </p>
              <img src={noChart1} alt="" />
            </div>
          )}
        </div>
        <div className="Chart__inner__right dashboard--children">
          <p className="text--title bold title">Completion</p>
          {dataCom.total_records ? (
            <div className="chart__item complete-chart">
              <div className="complete-chart__left">
                <div className="percent-complete">
                  <h4>{`${percentComplete}%`}</h4>
                  <p className="text--title bold">Complete</p>
                </div>
                <PieChart width={180} height={180}>
                  <Pie
                    data={dataCompletion}
                    cx="50%"
                    cy="50%"
                    innerRadius={70}
                    outerRadius={90}
                    fill="#8884d8"
                    paddingAngle={
                      dataCompletion[0].value === 0 ||
                      dataCompletion[1].value === 0
                        ? 0
                        : 1
                    }
                    dataKey="value"
                  >
                    {dataCompletion.map((entry, index) => (
                      <Cell
                        key={`chart-${entry.name}`}
                        fill={COLORS2[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </div>

              <div>
                {dataCompletion.map((item) => (
                  <div className="infoChart" key={`text-${item.name}`}>
                    <span
                      className={classNames({
                        newly: item.name === "Complete",
                        transferred: item.name === "Non-Complete",
                      })}
                    />
                    <p className="text--xsmall bold">
                      {`${((item.value * 100) / totalCompletion).toFixed(0)}% ${
                        item.name
                      }`}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="Nodata__chart">
              <p className="--noData">
                There’s currently no completion data to display
              </p>
              <img src={noChart2} alt="" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Chart;
