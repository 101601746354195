import * as types from "../constants";
import request from "../../utils/request";

export function postForgotPassWord(data, role) {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { email, redirect_url } = data;
  return (dispatch) => {
    dispatch({ type: types.POST_FORGOT_PASSWORD });
    return request()
      .post(role === "admin" ? "/auth/password" : "/student-auth/password", {
        email,
        redirect_url,
      })
      .then((dataResponse) => {
        dispatch({
          type: types.POST_FORGOT_PASSWORD_SUCCEED,
          payload: dataResponse,
        });
        return dataResponse;
      })
      .catch((error) => {
        dispatch({
          type: types.POST_FORGOT_PASSWORD_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
