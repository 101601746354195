import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { serialize } from "object-to-formdata";
import { EditInstitution } from "../../../components/dashboard/institution";
import {
  getInstitution,
  updateInstitution,
} from "../../../redux/actions/institution";
import { getUsers } from "../../../redux/actions/users";
import { openMessage } from "../../../redux/actions/message";
import { AGENCIES_TYPES, ROUTES } from "../../../utils/constants";
import { getAuth } from "../../../utils/helpers";
import { getAgencies } from "../../../redux/actions/reporting";

const DEFAULT_FORM_EDIT_INSTITUTION = {
  name: "",
  title_iv: "NO",
  school_agencies_attributes: [],
  logo: [],
  verifying_official_id: null,
};

function Edit() {
  const [form, setForm] = React.useState({ ...DEFAULT_FORM_EDIT_INSTITUTION });
  const [formErrors, setFormErrors] = React.useState({});
  const [formAgencies, setFormAgencies] = React.useState([]);
  const [newLogoSrc, setNewLogoSrc] = React.useState("");
  const [verifyingAdmin, setVerifyingAdmin] = React.useState({});
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = getAuth();
  const storeInstitution = useSelector(
    (store) => store.institution.institution
  );
  const storeUpdateInstitution = useSelector(
    (store) => store.institution.updateInstitution
  );

  const storeAgencies = useSelector((store) => store.agencies);
  const storeUsers = useSelector((store) => store.users);

  const isSubmitting = storeUpdateInstitution.loading;
  React.useEffect(() => {
    dispatch(getUsers());
  }, []);
  React.useEffect(() => {
    dispatch(getInstitution({}, auth.userData.school_id));
  }, []);

  React.useEffect(() => {
    dispatch(getAgencies(auth.userData.school_id));
  }, []);

  const dataInstitution = storeInstitution.data;
  React.useEffect(() => {
    setForm({
      ...form,
      name: dataInstitution.name,
      website: dataInstitution.website,
      title_iv: dataInstitution.title_iv ? "YES" : "NO",
    });
    setFormAgencies(storeAgencies.data || []);

    if (dataInstitution.verifying_official) {
      setVerifyingAdmin({
        value: dataInstitution.verifying_official.id,
        label: `${dataInstitution.verifying_official.first_name} ${dataInstitution.verifying_official.last_name}`,
      });
    }
  }, [storeInstitution]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = {};

    if (!form.name) {
      errors.name = "Name is required";
    }

    setFormErrors(errors);

    // call api
    if (Object.keys(errors).length) return null;

    // update institution
    try {
      const formData = {
        name: form.name,
        website: form.website,
        title_iv: form.title_iv === "YES",
        school_agencies_attributes: form.school_agencies_attributes,
        logo: form.logo,
        verifying_official_id: form.verifying_official_id,
      };

      // old agencies
      const oldIAgencyIds = {};
      dataInstitution.school_agencies.forEach((item) => {
        oldIAgencyIds[item.is_type] = item.id;
      });

      // eslint-disable-next-line @typescript-eslint/naming-convention
      const school_agencies_attributes = [];
      AGENCIES_TYPES.forEach((agency) => {
        if (formAgencies.includes(agency)) {
          // keep old agency
          if (oldIAgencyIds[agency]) {
            school_agencies_attributes.push({
              is_type: agency,
              id: oldIAgencyIds[agency],
            });
          } else {
            // new agency
            school_agencies_attributes.push({ is_type: agency });
          }
        } else if (oldIAgencyIds[agency]) {
          school_agencies_attributes.push({
            _destroy: "1",
            id: oldIAgencyIds[agency],
          });
        }
      });
      formAgencies.forEach((agency) => {
        if (oldIAgencyIds[agency]) {
          delete oldIAgencyIds[agency];
        }
      });
      formData.school_agencies_attributes = school_agencies_attributes;
      if (formData.logo.length) {
        formData.logo = formData.logo[0];
      }
      await dispatch(
        updateInstitution(serialize({ school: formData }), dataInstitution.id)
      );

      dispatch(
        openMessage({
          title: "Your Institution has been updated",
          type: "success",
        })
      );
      dispatch(getAgencies(auth.userData.school_id));
      history.push(ROUTES.institution);
    } catch (err) {
      console.error("Update program has an error: ", err);
      const resErrors = err.data && err.data.errors ? err.data.errors : {};
      const newErrors = {};
      Object.keys(resErrors).forEach((key) => {
        newErrors[key] = resErrors[key][0];
      });
      setFormErrors(newErrors);
    }
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  const handleChangeAgencies = (selectedAgency) => () => {
    let newAgencies = [...formAgencies];
    if (newAgencies.includes(selectedAgency)) {
      newAgencies = newAgencies.filter((item) => item !== selectedAgency);
    } else {
      newAgencies.push(selectedAgency);
    }
    setFormAgencies(newAgencies);
  };

  const handleChangeVerifyingAdmin = (selectedItem) => {
    setVerifyingAdmin(selectedItem);
    setForm({ ...form, verifying_official_id: selectedItem.value });
  };

  const handleDropzone = (dropped) => {
    setForm({
      ...form,
      logo: dropped,
    });
  };

  if (form.logo && form.logo[0]) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        const src = reader.result;
        setNewLogoSrc(src);
      },
      false
    );
    reader.readAsDataURL(form.logo[0]);
  }

  return (
    <EditInstitution
      form={form}
      formAgencies={formAgencies}
      formErrors={formErrors}
      onSubmit={handleSubmit}
      onChange={handleChange}
      onFocus={handleFocus}
      dataInstitution={storeInstitution.data}
      dataAdmins={storeUsers.data}
      handleChangeAgencies={handleChangeAgencies}
      handleChangeVerifyingAdmin={handleChangeVerifyingAdmin}
      verifyingAdmin={verifyingAdmin}
      handleDrop={handleDropzone}
      newLogoSrc={newLogoSrc}
      isSubmitting={isSubmitting}
    />
  );
}

export default Edit;
