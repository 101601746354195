import React, { useEffect } from "react";
import qs from "query-string";
import { useLocation, useHistory } from "react-router";
import request from "../../utils/request";
import { Loading } from "../../components/common";
import { ROUTES } from "../../utils/constants";

export function SigningComplete() {
  const history = useHistory();
  const location = useLocation();
  const params = qs.parse(location.search);

  useEffect(() => {
    const handleSigningComplete = async () => {
      await request().post(
        `/surveys/graduate_follow_up/complete?token=${params.token}`
      );
      history.push(ROUTES.students);
    };
    handleSigningComplete();
  }, []);

  return (
    <div>
      <Loading />
    </div>
  );
}
