import { useMutation, useQuery } from "react-query";
import qs from "query-string";
import { api, client } from "lib/api";
import { useLocation } from "react-router-dom";

type Response = {
  id: number;
  name: string;
  isType: string;
  startDate: string;
  graduationDate: string;
  branch: {
    id: number;
    name: string;
    city: string;
    state: string;
    zip_code: string;
    is_main_location: boolean;
    school_id: number;
    created_at: string;
    updated_at: string;
    address: string;
    phone: number;
  };
  program: {
    id: number;
    name: string;
    description: string;
    cost: number;
    program_length: number;
    degree_type: string;
    school_id: number;
    created_at: string;
    updated_at: string;
    requires_licensing: string;
  };
  school: {
    name: string;
    logo: string;
  };
  token: string;
};

type QueryParams = {
  token: string;
};

export function useRegisterStudentsInfo() {
  const location = useLocation();

  const { token } = qs.parse(location.search) as QueryParams;

  const query = qs.stringify({
    token,
  });

  const res = useQuery(["registerStudents", token], () =>
    api.get<Response>(`/students/registrations/info?${query}`)
  );

  const isLoading = res.isLoading;
  const isError = res.isError;
  const data = res?.data?.data;

  return { isLoading, isError, data };
}

export function useMutationCompleteRegistration() {
  const location = useLocation();
  const { token } = qs.parse(location.search) as QueryParams;

  const mutation = useMutation({
    mutationFn: async (formData: any) => {
      if (!token) return;
      return client.post(
        `/students/registrations/create?token=${token}`,
        formData
      );
    },
    mutationKey: ["completeRegistration", token],
  });

  const isLoading = mutation.isLoading;
  const isError = mutation.isError;
  const isSuccess = mutation.isSuccess;
  const mutate = mutation.mutate;

  return { isLoading, isError, isSuccess, mutate };
}
