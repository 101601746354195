import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { openModalPayment } from "redux/actions/global";
import { ROUTES } from "../../../../utils/constants";
import { AddEnrollment } from "../../../../components/dashboard/students/student";
import { getAllPrograms } from "../../../../redux/actions/programs";
import { getAllBranches } from "../../../../redux/actions/branches";
import { getAllCohorts } from "../../../../redux/actions/cohorts";
import {
  createEnrollment,
  deleteDocument,
  updateEnrollment,
} from "../../../../redux/actions/student";
import {
  convertToFormData,
  formatDateOfPicker,
  handleErrorsAfterCallingAPI,
  replaceParamStudent,
} from "../../../../utils/helpers";
import { openMessage } from "../../../../redux/actions/message";
import {
  ModalDeleteDocument,
  ModalPayment,
} from "../../../../components/common";

const DEFAULT_FORM_ENROLLMENT = {
  program_id: undefined,
  branch_id: undefined,
  cohort_id: undefined,
  start_status: "",
  start_date: null,
  start_comments: "",
  end_status: "",
  end_date: null,
  end_comments: "",
  files: [],
};

export default function () {
  const history = useHistory();
  const location = useLocation();
  const enrollmentData = location.state || {};
  const { studentId } = useParams();

  const isEditing = !!Object.keys(enrollmentData).length;

  const [form, setForm] = React.useState({
    ...DEFAULT_FORM_ENROLLMENT,
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [openModalDeleteDocument, setOpenModalDeleteDocument] = useState(false);
  const [document, setDocument] = React.useState({});
  const [documentIndex, setDocumentIndex] = React.useState(null);
  const dispatch = useDispatch();
  const storeCreate = useSelector((store) => store.student.enrollment.create);
  const storeUpdate = useSelector((store) => store.student.enrollment.update);
  const storePrograms = useSelector((store) => store.programs.all);
  const storeBranches = useSelector((store) => store.branches.all);
  const storeCohorts = useSelector((store) => store.cohorts.all);

  const isSubmitting = storeCreate.loading || storeUpdate.loading;

  React.useEffect(() => {
    dispatch(getAllPrograms());
    dispatch(getAllBranches());
    dispatch(getAllCohorts());
  }, []);

  React.useEffect(() => {
    if (form.program_id && form.branch_id) {
      dispatch(
        getAllCohorts({
          program_id: form.program_id,
          branch_id: form.branch_id,
        })
      );
    }
  }, [form.program_id, form.branch_id]);

  React.useEffect(() => {
    if (isEditing) {
      setForm({
        ...enrollmentData,
        cohort_id: enrollmentData.cohort.id,
        program_id: enrollmentData.program.id,
        branch_id: enrollmentData.branch.id,
      });
    }
  }, [enrollmentData, isEditing]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.program_id) {
      errors.program_id = "Program  is required";
    }
    if (!form.branch_id) {
      errors.branch_id = "Branch  is required";
    }
    if (!form.cohort_id) {
      errors.cohort_id = "Cohort  is required";
    }
    if (!form.start_status) {
      errors.start_status = "Start Status is required";
    }
    setFormErrors(errors);

    // call api
    if (!Object.keys(errors).length) {
      if (isEditing) {
        // edit
        try {
          setFormErrors({});
          const formData = {
            ...form,
            start_date: formatDateOfPicker(form.start_date),
            end_date: formatDateOfPicker(form.end_date),
          };
          formData.files = form.files.filter((item) => !item.id);
          await dispatch(
            updateEnrollment(
              convertToFormData(formData, "enrollment"),
              studentId,
              enrollmentData.id
            )
          );
          dispatch(
            openMessage({
              title: "An enrollment has been updated.",
              type: "success",
            })
          );
          history.push(replaceParamStudent(ROUTES.enrollment, studentId));
        } catch (error) {
          handleErrorsAfterCallingAPI(
            error,
            DEFAULT_FORM_ENROLLMENT,
            setFormErrors,
            dispatch
          );
        }
      } else {
        // create
        try {
          setFormErrors({});
          await dispatch(
            createEnrollment(convertToFormData(form, "enrollment"), studentId)
          );
          dispatch(
            openMessage({
              title: "An enrollment has been created.",
              type: "success",
            })
          );
          history.push(replaceParamStudent(ROUTES.enrollment, studentId));
        } catch (error) {
          //  show modal payment
          if (error.status === 402) {
            dispatch(openModalPayment());
          }

          handleErrorsAfterCallingAPI(
            error,
            DEFAULT_FORM_ENROLLMENT,
            setFormErrors,
            dispatch
          );
        }
      }
    }
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  const handleChangeSelect = (selectedItem, name) => {
    setForm({
      ...form,
      [name]: selectedItem.value,
    });
  };

  const handleChangeDate = (date, name) => {
    setForm({ ...form, [name]: date });
  };

  const handleBack = () => {
    history.push(replaceParamStudent(ROUTES.enrollment, studentId));
  };

  const handleDropzone = (dropped) => {
    const newFiles = [...form.files, ...dropped];
    setForm({
      ...form,
      files: newFiles,
    });
  };

  const handleTogleModalDeleteDocument = (file, selectedIndex) => () => {
    setOpenModalDeleteDocument(!openModalDeleteDocument);
    setDocument(file);
    setDocumentIndex(selectedIndex);
  };

  const handleDeleteDocument = async () => {
    if (document.id) {
      try {
        setFormErrors({});
        await dispatch(deleteDocument(document.id));
        dispatch(
          openMessage({
            title: "This document has been deleted",
            type: "success",
          })
        );
        setForm({
          ...form,
          files: form.files.filter((item, index) => index !== documentIndex),
        });
        setOpenModalDeleteDocument(!openModalDeleteDocument);
      } catch (error) {
        dispatch(
          openMessage({
            title: "Something went wrong, please try again!",
            type: "error",
          })
        );
      }
    } else {
      setForm({
        ...form,
        files: form.files.filter((item, index) => index !== documentIndex),
      });
      setOpenModalDeleteDocument(!openModalDeleteDocument);
    }
  };

  const handleCancelDelete = () => {
    setOpenModalDeleteDocument(false);
  };

  return (
    <>
      <AddEnrollment
        form={form}
        formErrors={formErrors}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onChangeSelect={handleChangeSelect}
        onFocus={handleFocus}
        handleChangeDate={handleChangeDate}
        dataPrograms={storePrograms.data}
        dataBranches={storeBranches.data}
        dataCohorts={storeCohorts.data}
        isSubmitting={isSubmitting}
        handleBack={handleBack}
        handleDrop={handleDropzone}
        onDeleteDocument={handleTogleModalDeleteDocument}
        isLoadingCohorts={storeCohorts.loading}
      />
      <ModalDeleteDocument
        isOpen={openModalDeleteDocument}
        onBack={handleCancelDelete}
        nameDocument={document.name || document.filename}
        onDelete={handleDeleteDocument}
      />
      <ModalPayment />
    </>
  );
}
