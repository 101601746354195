import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import {
  Dropzone,
  FormGroup,
  SingleDatePicker,
  SingleSelectCheckbox,
} from "../../../common";
import { END_STATUSES, START_STATUSES } from "../../../../utils/constants";
import { Delete } from "../../../common/icons";

function AddEnrollment({
  form,
  formErrors,
  onSubmit,
  onChange,
  onChangeSelect,
  onFocus,
  handleChangeDate,
  handleBack,
  // eslint-disable-next-line react/prop-types
  dataPrograms,
  // eslint-disable-next-line react/prop-types
  dataBranches,
  // eslint-disable-next-line react/prop-types
  dataCohorts,
  handleDrop,
  isSubmitting,
  onDeleteDocument,
  isLoadingCohorts,
}) {
  // eslint-disable-next-line react/prop-types
  const OPTIONS_PROGRAM = dataPrograms.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  // eslint-disable-next-line react/prop-types
  const OPTIONS_BRANCH = dataBranches.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  // eslint-disable-next-line react/prop-types
  const OPTIONS_COHORT = dataCohorts.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  return (
    <div className="AddEnrollment  form-student dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="form-student__text">
          <p className="text--xlarge bold">New Enrollment</p>
        </div>
        <div className="AddEnrollment__inner form-student__inner">
          <div className="input__group">
            <div className="select">
              <p>Program</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="program_id"
                options={OPTIONS_PROGRAM}
                value={{ value: form.program_id }}
                error={formErrors.program_id}
                disabled={isSubmitting}
              />
            </div>
            <div className="select">
              <p>Branch</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="branch_id"
                options={OPTIONS_BRANCH}
                value={{ value: form.branch_id }}
                error={formErrors.branch_id}
                disabled={isSubmitting}
              />
            </div>
            <div className="select">
              <p>Cohort</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="cohort_id"
                options={OPTIONS_COHORT}
                value={{ value: form.cohort_id }}
                error={formErrors.cohort_id}
                disabled={isSubmitting || isLoadingCohorts}
                loading={isLoadingCohorts}
              />
            </div>
            <div className="select">
              <p>Start Status</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="start_status"
                options={START_STATUSES}
                value={{ value: form.start_status }}
                error={formErrors.start_status}
                disabled={isSubmitting}
              />
            </div>
            <div className="Date-picker">
              <p className="Date-picker__label">Start Date</p>
              <SingleDatePicker
                placeholder="Select Date"
                value={form.start_date}
                onChange={handleChangeDate}
                disabled={isSubmitting}
                name="start_date"
              />
            </div>
          </div>
          <div className="input__group">
            <FormGroup
              propsInput={{
                name: "start_comments",
                placeholder: "Description",
                as: "textarea",
                onChange,
                onFocus,
                value: form.start_comments,
                disabled: isSubmitting,
              }}
              error={formErrors.start_comments}
              label="Start Comment"
            />
          </div>
          <div className="input__group">
            <div className="select">
              <p>End Status</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="end_status"
                options={END_STATUSES}
                value={{ value: form.end_status }}
                error={formErrors.end_status}
                disabled={isSubmitting}
              />
            </div>
            <div className="Date-picker">
              <p className="Date-picker__label">End Date</p>
              <SingleDatePicker
                placeholder="Select Date"
                value={form.end_date}
                onChange={handleChangeDate}
                disabled={isSubmitting}
                name="end_date"
              />
            </div>
          </div>
          <div className="input__group">
            <FormGroup
              propsInput={{
                name: "end_comments",
                placeholder: "Description",
                as: "textarea",
                onChange,
                onFocus,
                value: form.end_comments,
                disabled: isSubmitting,
              }}
              error={formErrors.end_comments}
              label="End Comment"
            />
          </div>
          <div className="Documents">
            <p>Documentation</p>
            {form.files && !!form.files.length && (
              <div className="Documents__inner">
                {form.files.map((item, index) => (
                  <div className="Document--item" key={item.name || item.id}>
                    <p>{item.name || item.filename}</p>
                    <button
                      type="button"
                      className="Documents__delete"
                      onClick={onDeleteDocument(item, index)}
                    >
                      <Delete />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="input__group">
            <div className="add-document">
              <Dropzone handleDrop={handleDrop} />
            </div>
          </div>
        </div>
        <div className="button-group">
          <button
            className="button button--back"
            type="submit"
            onClick={handleBack}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
}

AddEnrollment.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    program_id: PropTypes.number,
    branch_id: PropTypes.number,
    cohort_id: PropTypes.number,
    start_status: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    start_date: PropTypes.any,
    start_comments: PropTypes.string,
    end_status: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    end_date: PropTypes.any,
    end_comments: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    files: PropTypes.array,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  onDeleteDocument: PropTypes.func.isRequired,
  isLoadingCohorts: PropTypes.bool.isRequired,
};

AddEnrollment.defaultProps = {
  form: {
    program_id: undefined,
    branch_id: undefined,
    cohort_id: undefined,
    start_status: "",
    start_date: "",
    start_comments: "",
    end_status: "",
    end_date: "",
    end_comments: "",
    files: [],
  },
  formErrors: {},
  isSubmitting: false,
};

export default AddEnrollment;
