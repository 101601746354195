import React from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { TableActions } from "components";
import { replaceParamStudent } from "utils/helpers";
import { ROUTES } from "../../../../utils/constants";
import { Plus, Folder } from "../../../common/icons";
import Card from "./Card";

function Licensure({
  storeLicensure = { data: [], loading: false, error: null },
  onEdit = () => {},
  onDelete = () => {},
}) {
  const history = useHistory();
  const { studentId } = useParams();

  const handleClick = () => {
    history.push(replaceParamStudent(ROUTES.addLicensure, studentId));
  };

  const columns1 = [
    {
      title: "Program",
      dataIndex: "program",
      render: (row) => (row.program ? row.program.name : "-"),
    },
    {
      title: "Licensed By",
      dataIndex: "licensed_by",
      render: (row) =>
        row.licensure_exam ? row.licensure_exam.licensing_entity : "-",
    },
    {
      title: "Exam Date",
      dataIndex: "exam_date",
      render: (row) => moment(row.exam_date).format("MM/DD/YYYY"),
    },
    {
      title: "Result",
      dataIndex: "result",
    },
    {
      title: "Documentation",
      dataIndex: "files",
      render: (row) => {
        if (!row.files.length) return "-";

        return row.files.map((item) => (
          <a
            className="document"
            key={item.id || item.name}
            href={item.url}
            target="_blank"
            rel="noreferrer"
          >
            <Folder />
            {item.filename}
          </a>
        ));
      },
    },
    {
      title: "",
      dataIndex: "id",
      align: "right",
      render: (row) => (
        <TableActions
          actions={[
            {
              label: "Edit",
              icon: ["fas", "pen-to-square"],
              onSelect: onEdit(row),
            },
            {
              label: "Delete",
              icon: ["fas", "trash"],
              onSelect: onDelete(row),
            },
          ]}
        />
      ),
    },
  ];

  return (
    <div className="Licensure Student-tap dashboard__item__inner">
      <div className="button__add">
        <button type="submit" className="button--icon" onClick={handleClick}>
          <Plus />
          Exam Result
        </button>
      </div>
      <div>
        {storeLicensure.data.map((item) => (
          <Card
            title={item.licensure_exam ? item.licensure_exam.name : ""}
            data={[item]}
            columns1={columns1}
            key={item.id}
          />
        ))}
      </div>
    </div>
  );
}

export default Licensure;
