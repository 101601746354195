import React from "react";

export default function () {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3657 14C15.918 14 16.3657 14.4477 16.3657 15C16.3657 15.5523 15.918 16 15.3657 16H1.36572C0.813438 16 0.365723 15.5523 0.365723 15C0.365723 14.4477 0.813438 14 1.36572 14H15.3657ZM4.65862 7.29289L11.6586 0.292893C12.0191 -0.0675907 12.5863 -0.0953203 12.9786 0.209705L13.0728 0.292893L16.0728 3.29289C16.4333 3.65338 16.461 4.22061 16.156 4.6129L16.0728 4.70711L9.07283 11.7071C8.91655 11.8634 8.71387 11.9626 8.49717 11.9913L8.36572 12H5.36572C4.85289 12 4.43021 11.614 4.37245 11.1166L4.36572 11V8C4.36572 7.77899 4.43889 7.56552 4.5718 7.39197L4.65862 7.29289L11.6586 0.292893L4.65862 7.29289ZM12.3657 2.41415L6.36572 8.41415V9.99993H7.95151L13.9515 3.99993L12.3657 2.41415Z"
        fill="white"
      />
    </svg>
  );
}
