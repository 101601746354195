/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/default-param-last */
import * as types from "../constants";

const initialState = {
  integrations: {
    data: {},
    loading: false,
    error: null,
  },
  delete: {
    data: {},
    loading: false,
    error: null,
  },
  salesforce: {
    active: {
      data: {},
      loading: false,
      error: null,
    },
    authorize: {
      data: {},
      loading: false,
      error: null,
    },
    objects: {
      data: {},
      loading: false,
      error: null,
    },
    attributes: {
      data: {},
      loading: false,
      error: null,
    },
    mapping: {
      data: {},
      loading: false,
      error: null,
    },
    bulkMapping: {
      data: {},
      loading: false,
      error: null,
    },
    sync: {
      data: {},
      loading: false,
      error: null,
    },
    mappings: {
      data: {},
      loading: false,
      error: null,
    },
    destroyAll: {
      data: {},
      loading: false,
      error: null,
    },
  },
  hubspot: {
    active: {
      data: {},
      loading: false,
      error: null,
    },
    authorize: {
      data: {},
      loading: false,
      error: null,
    },
    attributes: {
      data: [],
      loading: false,
      error: null,
    },
    mapping: {
      data: {},
      loading: false,
      error: null,
    },
    sync: {
      data: {},
      loading: false,
      error: null,
    },
    mappings: {
      data: {},
      loading: false,
      error: null,
    },
  },
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    // INTEGRATIONS
    case types.GET_INTEGRATIONS:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          loading: true,
        },
      };
    case types.GET_INTEGRATIONS_SUCCEED:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          data: payload.data,
          loading: false,
        },
      };
    case types.GET_INTEGRATIONS_FAIL:
      return {
        ...state,
        integrations: {
          ...state.integrations,
          error: payload,
          loading: false,
        },
      };

    // ACTIVATE
    case types.GET_SALESFORCE_ACTIVATE:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          active: {
            ...state.salesforce.active,
            loading: true,
          },
        },
      };
    case types.GET_SALESFORCE_ACTIVATE_SUCCEED:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          active: {
            ...state.salesforce.active,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_SALESFORCE_ACTIVATE_FAIL:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          active: {
            ...state.salesforce.active,
            error: payload,
            loading: false,
          },
        },
      };

    // OBJECTS
    case types.GET_SALESFORCE_OBJECTS:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          objects: {
            ...state.salesforce.objects,
            loading: true,
          },
        },
      };
    case types.GET_SALESFORCE_OBJECTS_SUCCEED:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          objects: {
            ...state.salesforce.objects,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_SALESFORCE_OBJECTS_FAIL:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          objects: {
            ...state.salesforce.objects,
            error: payload,
            loading: false,
          },
        },
      };

    // ATTRIBUTES
    case types.GET_SALESFORCE_ATTRIBUTES:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          attributes: {
            ...state.salesforce.attributes,
            loading: true,
          },
        },
      };
    case types.GET_SALESFORCE_ATTRIBUTES_SUCCEED:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          attributes: {
            ...state.salesforce.attributes,
            data: {
              ...state.salesforce.attributes.data,
              [payload.model]: payload.data,
            },
            loading: false,
          },
        },
      };
    case types.GET_SALESFORCE_ATTRIBUTES_FAIL:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          attributes: {
            ...state.salesforce.attributes,
            error: payload,
            loading: false,
          },
        },
      };

    // AUTHORIZE
    case types.CREATE_SALESFORCE_AUTHORIZE:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          authorize: {
            ...state.salesforce.authorize,
            loading: true,
          },
        },
      };
    case types.CREATE_SALESFORCE_AUTHORIZE_SUCCEED:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          authorize: {
            ...state.salesforce.authorize,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.CREATE_SALESFORCE_AUTHORIZE_FAIL:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          authorize: {
            ...state.salesforce.authorize,
            error: payload,
            loading: false,
          },
        },
      };

    // MAPPING
    case types.CREATE_SALESFORCE_MAPPING:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          mapping: {
            ...state.salesforce.mapping,
            loading: true,
          },
        },
      };
    case types.CREATE_SALESFORCE_MAPPING_SUCCEED:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          mapping: {
            ...state.salesforce.mapping,
            data: payload,
            loading: false,
          },
        },
      };
    case types.CREATE_SALESFORCE_MAPPING_FAIL:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          mapping: {
            ...state.salesforce.mapping,
            error: payload,
            loading: false,
          },
        },
      };

    // BULK MAPPING
    case types.CREATE_SALESFORCE_BULK_MAPPING:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          bulkMapping: {
            ...state.salesforce.bulkMapping,
            loading: true,
          },
        },
      };
    case types.CREATE_SALESFORCE_BULK_MAPPING_SUCCEED:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          bulkMapping: {
            ...state.salesforce.bulkMapping,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.CREATE_SALESFORCE_BULK_MAPPING_FAIL:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          bulkMapping: {
            ...state.salesforce.bulkMapping,
            error: payload,
            loading: false,
          },
        },
      };

    // CREATE

    // SYNC
    case types.CREATE_SALESFORCE_SYNC:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          sync: {
            ...state.salesforce.sync,
            loading: true,
          },
        },
      };
    case types.CREATE_SALESFORCE_SYNC_SUCCEED:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          sync: {
            ...state.salesforce.sync,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.CREATE_SALESFORCE_SYNC_FAIL:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          sync: {
            ...state.salesforce.sync,
            error: payload,
            loading: false,
          },
        },
      };

    // MAPPINGS
    case types.GET_SALESFORCE_MAPPINGS:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          mappings: {
            ...state.salesforce.mappings,
            loading: true,
          },
        },
      };
    case types.GET_SALESFORCE_MAPPINGS_SUCCEED:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          mappings: {
            ...state.salesforce.mappings,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_SALESFORCE_MAPPINGS_FAIL:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          mappings: {
            ...state.salesforce.mappings,
            error: payload,
            loading: false,
          },
        },
      };

    // DESTROY_ALL
    case types.GET_SALESFORCE_DESTROY_ALL:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          destroyAll: {
            ...state.salesforce.destroyAll,
            loading: true,
          },
        },
      };
    case types.GET_SALESFORCE_DESTROY_ALL_SUCCEED:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          destroyAll: {
            ...state.salesforce.destroyAll,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_SALESFORCE_DESTROY_ALL_FAIL:
      return {
        ...state,
        salesforce: {
          ...state.salesforce,
          destroyAll: {
            ...state.salesforce.destroyAll,
            error: payload,
            loading: false,
          },
        },
      };
    // DELETE INTEGRATIONS
    case types.DELETE_INTEGRATION:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: true,
        },
      };
    case types.DELETE_INTEGRATION_SUCCEED:
      return {
        ...state,
        delete: {
          ...state.delete,
          data: payload.data,
          loading: false,
        },
      };
    case types.DELETE_INTEGRATION_FAIL:
      return {
        ...state,
        delete: {
          ...state.delete,
          error: payload,
          loading: false,
        },
      };

    // HUBSPOT
    // ACTIVATE
    case types.GET_HUBSPOT_ACTIVATE:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          active: {
            ...state.hubspot.active,
            loading: true,
          },
        },
      };
    case types.GET_HUBSPOT_ACTIVATE_SUCCEED:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          active: {
            ...state.hubspot.active,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_HUBSPOT_ACTIVATE_FAIL:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          active: {
            ...state.hubspot.active,
            error: payload,
            loading: false,
          },
        },
      };
    // ATTRIBUTES
    case types.GET_HUBSPOT_ATTRIBUTES:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          attributes: {
            ...state.hubspot.attributes,
            loading: true,
          },
        },
      };
    case types.GET_HUBSPOT_ATTRIBUTES_SUCCEED:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          attributes: {
            ...state.hubspot.attributes,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_HUBSPOT_ATTRIBUTES_FAIL:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          attributes: {
            ...state.hubspot.attributes,
            error: payload,
            loading: false,
          },
        },
      };

    // AUTHORIZE
    case types.CREATE_HUBSPOT_AUTHORIZE:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          authorize: {
            ...state.hubspot.authorize,
            loading: true,
          },
        },
      };
    case types.CREATE_HUBSPOT_AUTHORIZE_SUCCEED:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          authorize: {
            ...state.hubspot.authorize,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.CREATE_HUBSPOT_AUTHORIZE_FAIL:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          authorize: {
            ...state.hubspot.authorize,
            error: payload,
            loading: false,
          },
        },
      };

    // MAPPING
    case types.CREATE_HUBSPOT_MAPPING:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          mapping: {
            ...state.hubspot.mapping,
            loading: true,
          },
        },
      };
    case types.CREATE_HUBSPOT_MAPPING_SUCCEED:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          mapping: {
            ...state.hubspot.mapping,
            data: payload,
            loading: false,
          },
        },
      };
    case types.CREATE_HUBSPOT_MAPPING_FAIL:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          mapping: {
            ...state.hubspot.mapping,
            error: payload,
            loading: false,
          },
        },
      };

    // SYNC
    case types.CREATE_HUBSPOT_SYNC:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          sync: {
            ...state.hubspot.sync,
            loading: true,
          },
        },
      };
    case types.CREATE_HUBSPOT_SYNC_SUCCEED:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          sync: {
            ...state.hubspot.sync,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.CREATE_HUBSPOT_SYNC_FAIL:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          sync: {
            ...state.hubspot.sync,
            error: payload,
            loading: false,
          },
        },
      };

    // MAPPINGS
    case types.GET_HUBSPOT_MAPPINGS:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          mappings: {
            ...state.hubspot.mappings,
            loading: true,
          },
        },
      };
    case types.GET_HUBSPOT_MAPPINGS_SUCCEED:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          mappings: {
            ...state.hubspot.mappings,
            data: payload.data,
            loading: false,
          },
        },
      };
    case types.GET_HUBSPOT_MAPPINGS_FAIL:
      return {
        ...state,
        hubspot: {
          ...state.hubspot,
          mappings: {
            ...state.hubspot.mappings,
            error: payload,
            loading: false,
          },
        },
      };

    default:
      return state;
  }
}
