import { useMutation, useQueries } from "react-query";
import qs from "query-string";
import { api, client } from "lib/api";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openMessage } from "redux/actions/message";
import { ROUTES } from "utils/constants";

export type IAttendance = {
  id: number;
  attended: boolean;
  attendedAt: string;
  confirmedAt: string;
  submittedAt: string;
};

export interface ITask {
  id: number;
  title: string;
  description: any;
  itemableId: number;
  itemableType: string;
  taskType: string;
  status: string;
  note: any;
  createdAt: string;
  updatedAt: string;
  student: Student;
  taskableUrl: string;
  program: Program;
  itemable: Itemable;
}
interface Student {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  dob: string;
  gender: string;
  education_level: string;
  address: string;
  city: string;
  state: string;
  zip_code: any;
  school_id: number;
  created_at: string;
  updated_at: string;
  ethnicity: string;
  full_name: string;
  encrypted_ssn: any;
  encrypted_ssn_iv: any;
  provider: string;
  uid: string;
  allow_password_change: boolean;
  ssn: any;
}

interface Program {
  id: number;
  name: string;
  description: any;
  cost: number;
  program_length: number;
  degree_type: string;
  days_of_week: number[];
  frequency: number;
  licensure_exams: LicensureExam[];
  soc_codes: SocCode[];
}

interface LicensureExam {
  id: number;
  is_type: string;
  state: string;
  licensing_entity: string;
  name: string;
  program_id: number;
  created_at: string;
  updated_at: string;
}

interface SocCode {
  id: number;
  code: string;
  title: string;
  description: string;
  examples: any[];
  program_id: number;
  created_at: string;
  updated_at: string;
}

interface Itemable {
  id: number;
  logo: string;
  attended: boolean;
  attended_at: string;
  confirmed_at: any;
  submitted_at: string;
  school: School;
}

interface School {
  id: number;
  name: string;
  website: string;
  title_iv: boolean;
  automate_survey: boolean;
  frequency: number;
  time_interval_start: number;
  time_interval_end: number;
  logo: string;
  verifying_official: any;
  branches: Branch[];
  school_agencies: SchoolAgency[];
  branch_agencies: BranchAgency[];
  purchased_enrollments: number;
  available_enrollments: number;
}

interface Branch {
  id: number;
  name: string;
  city: string;
  state: string;
  zip_code: string;
  is_main_location: boolean;
  school_id: number;
  created_at: string;
  updated_at: string;
  address?: string;
  phone: any;
}

interface SchoolAgency {
  id: number;
  school_id: number;
  created_at: string;
  updated_at: string;
  is_type: string;
}

interface BranchAgency {
  id: number;
  branch_id: number;
  created_at: string;
  updated_at: string;
  is_type: string;
}

type QueryParams = {
  attendanceId: string;
  taskId: string;
};

export function useAttendanceDetail() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { attendanceId, taskId } = qs.parse(location.search) as QueryParams;

  const handleError = () => {
    dispatch(
      openMessage({
        title: "Something went wrong, please try again.",
        type: "error",
      }) as any
    );
    history.push(ROUTES.dashboardStudent);
  };

  if (!attendanceId || !taskId) {
    handleError();
    return {
      isLoading: false,
      isError: true,
      data: undefined,
      refetch: () => {},
    };
  }

  const res = useQueries([
    {
      queryKey: ["attendanceDetail", attendanceId],
      queryFn: () =>
        api.get<IAttendance>(
          `/students/attendance_confirmations/${attendanceId}`
        ),
      onError: () => {
        handleError();
      },
    },
    {
      queryKey: ["taskDetail", taskId],
      queryFn: () => api.get<ITask>(`/students/tasks/${taskId}`),
      onError: () => {
        handleError();
      },
    },
  ]);

  const isLoading = res[0].isLoading || res[1].isLoading;
  const isError = res[0].isError || res[1].isError;
  const data = {
    attendance: res[0].data?.data,
    task: res[1].data?.data,
  };
  const refetch = () => {
    res[0].refetch();
    res[1].refetch();
  };

  return { isLoading, isError, data, refetch };
}

export function useMutationAttendance() {
  const location = useLocation();
  const { attendanceId } = qs.parse(location.search) as QueryParams;

  const mutation = useMutation(
    async (params: { isAttendance: boolean }) =>
      client.put(`/students/attendance_confirmations/${attendanceId}`, {
        attended: params.isAttendance,
      }),
    {
      mutationKey: ["attendance", attendanceId],
    }
  );

  const isLoading = mutation.isLoading;
  const isError = mutation.isError;
  const isSuccess = mutation.isSuccess;
  const mutate = mutation.mutate;

  return { isLoading, isError, isSuccess, mutate };
}
