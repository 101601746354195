import React from "react";
import { isEmail } from "validator";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { serialize } from "object-to-formdata";
import {
  formatPhoneNumber,
  handleErrorsAfterCallingAPI,
} from "../../../utils/helpers";
import { ROUTES } from "../../../utils/constants";
import { openMessage } from "../../../redux/actions/message";
import EditProfile from "../../../components/dashboard/profileSetting/EditProfile";
import {
  getProfile,
  updateProfile,
} from "../../../redux/actions/profileSetting";

const DEFAULT_FORM_STUDENT = {
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  position: "",
  avatar: [],
  password: "",
  confirm_new_password: "",
};

export default function () {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form, setForm] = React.useState({
    ...DEFAULT_FORM_STUDENT,
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [newAvatar, setNewAvatar] = React.useState("");
  const storeProfile = useSelector((store) => store.profileSetting.profile);
  const storeUpdate = useSelector((store) => store.profileSetting.update);
  const dataProfile = storeProfile.data;
  const isSubmitting = storeUpdate.loading;

  React.useEffect(() => {
    dispatch(getProfile());
  }, []);

  React.useEffect(() => {
    setForm({
      ...form,
      first_name: dataProfile.first_name,
      last_name: dataProfile.last_name,
      email: dataProfile.email,
      phone: formatPhoneNumber(dataProfile.phone),
      position: dataProfile.position,
    });
  }, [storeProfile]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.first_name) {
      errors.first_name = "First Name is required";
    }
    if (!form.last_name) {
      errors.last_name = "Last Name is required";
    }
    if (!form.email || !isEmail(form.email)) {
      errors.email = "Email is required";
    }
    if (form.password !== form.confirm_new_password) {
      errors.confirm_new_password = "Password confirmation does not match";
    }
    setFormErrors(errors);
    // call api
    if (Object.keys(errors).length) return null;

    try {
      const newForm = { ...form };
      if (form.avatar && form.avatar.length) {
        newForm.avatar = form.avatar[0];
      }
      if (!form.password) {
        delete newForm.password;
      }
      delete newForm.confirm_new_password;
      await dispatch(updateProfile(serialize({ user: newForm })));
      dispatch(
        openMessage({
          title: "Your profile has been updated",
          type: "success",
        })
      );
      history.push(ROUTES.profile);
    } catch (err) {
      handleErrorsAfterCallingAPI(
        err,
        DEFAULT_FORM_STUDENT,
        setFormErrors,
        dispatch
      );
    }
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangePhoneNumber = (e) => {
    const phone = e.target.value;
    if (phone.length > 14) {
      return;
    }
    if (phone.length === 4) {
      return setForm({ ...form, phone });
    }
    setForm({ ...form, phone: formatPhoneNumber(phone) });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  const handleCheckboxGender = (e) => {
    setForm({ ...form, gender: e.target.value });
  };

  const handleBack = () => {
    history.push(ROUTES.profile);
  };

  const handleDropzone = (dropped) => {
    setForm({
      ...form,
      avatar: dropped,
    });
  };

  React.useEffect(() => {
    if (form.avatar && form.avatar[0]) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          const src = reader.result;
          setNewAvatar(src);
        },
        false
      );
      reader.readAsDataURL(form.avatar[0]);
    }
  }, [form.avatar]);

  return (
    <EditProfile
      form={form}
      formErrors={formErrors}
      onSubmit={handleSubmit}
      onChange={handleChange}
      handleChangePhoneNumber={handleChangePhoneNumber}
      onFocus={handleFocus}
      isSubmitting={isSubmitting}
      handleCheckbox={handleCheckboxGender}
      handleBack={handleBack}
      handleDrop={handleDropzone}
      newAvatar={newAvatar}
      dataProfile={dataProfile}
    />
  );
}
