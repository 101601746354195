import React from "react";

export default function () {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8657 0.5C12.5561 0.5 13.1157 1.05964 13.1157 1.75V3H16.8657C17.5561 3 18.1157 3.55964 18.1157 4.25C18.1157 4.94036 17.5561 5.5 16.8657 5.5H15.6157V11.75C15.6157 13.8211 14.2166 15.5 12.4907 15.5H6.24072C4.51483 15.5 3.11572 13.8211 3.11572 11.75V5.5H1.86572C1.17537 5.5 0.615723 4.94036 0.615723 4.25C0.615723 3.55964 1.17537 3 1.86572 3H5.61572V1.75C5.61572 1.05964 6.17537 0.5 6.86572 0.5H11.8657ZM6.86572 5.5C6.22468 5.5 5.69634 5.98255 5.62413 6.60422L5.61572 6.75V11.75C5.61572 12.4404 6.17537 13 6.86572 13C7.50677 13 8.03511 12.5174 8.10731 11.8958L8.11572 11.75V6.75C8.11572 6.05964 7.55608 5.5 6.86572 5.5ZM11.8657 5.5C11.2247 5.5 10.6963 5.98255 10.6241 6.60422L10.6157 6.75V11.75C10.6157 12.4404 11.1754 13 11.8657 13C12.5068 13 13.0351 12.5174 13.1073 11.8958L13.1157 11.75V6.75C13.1157 6.05964 12.5561 5.5 11.8657 5.5Z"
        fill="white"
      />
    </svg>
  );
}
