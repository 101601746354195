import { useQuery } from "react-query";
import { api } from "lib/api";

export type ITask = {
  id: number;
  title: string;
  description?: any;
  itemableId: number;
  itemableType: string;
  taskType: string;
  status: string;
  note?: any;
  createdAt: string;
  updatedAt: string;
  student: Student;
  taskableUrl?: any;
  itemable: Itemable;
};
interface Itemable {
  id: number;
  logo: string;
  files: any[];
}
interface Student {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  dob: string;
  gender: string;
  education_level: string;
  address: string;
  city: string;
  state: string;
  zip_code?: any;
  school_id: number;
  created_at: string;
  updated_at: string;
  ethnicity: string;
  full_name: string;
  encrypted_ssn?: any;
  encrypted_ssn_iv?: any;
  provider: string;
  uid: string;
  allow_password_change: boolean;
  ssn?: any;
}

export function useListTasks() {
  const res = useQuery(["studentListTasks"], () =>
    api.get<ITask[]>("/students/tasks")
  );

  const isLoading = res.isLoading;
  const isError = res.isError;
  const data = res?.data?.data;
  const refetch = res.refetch;

  return { isLoading, isError, data, refetch };
}
