/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import FormGroup from "../common/FormGroup";
import Range from "./Range";
import {
  ContinueEducation,
  NotIntend,
  questionPrimaryIntentions,
  RemainWithMyCurrent,
  StartNewInFieldJob,
} from "../../utils/constants";

const optionSeeking = [
  {
    name: "I intend to start a new job in-field job within 180 days of graduation",
    value: StartNewInFieldJob,
  },
  {
    name: "I intend to remain with my current employer upon graduation",
    value: RemainWithMyCurrent,
  },
  {
    name: "I am attending the program to learn new skills for self-enrichment and do not intend to pursue an in-field job upon graduation",
    value: NotIntend,
  },
  {
    name: "I intend to continue education at an accredited post-secondary institution and do not intend to pursue an in-field job upon graduation",
    value: ContinueEducation,
  },
];

function Step5({
  formSurvey,
  onCheckbox,
  onSubmit,
  loading,
  handleBack,
  schoolData,
}) {
  return (
    <div className=" Step step6">
      <div className="Step__inner ">
        <div className="form_wrap">
          <Form noValidate onSubmit={onSubmit}>
            <div className="form__inner">
              <div className="Step__inner__header">
                <img
                  src={schoolData.logo}
                  alt=""
                  style={{ display: "inline" }}
                />
                <p>CIRR Student Intent Survey</p>
              </div>
              <div className="Step__inner__body">
                <p className="title">
                  What are your primary intentions for enrolling in this
                  program?
                </p>
                <div className="checkbox-group">
                  <div>
                    {optionSeeking.map((item) => (
                      <div className="checkbox" key={item.value}>
                        {item.name}
                        <FormGroup
                          propsInput={{
                            type: "radio",
                            name: [questionPrimaryIntentions],
                            onChange: onCheckbox,
                            checked:
                              formSurvey.survey_responses_attributes[
                                questionPrimaryIntentions
                              ] === item.value,
                            value: item.value,
                          }}
                          type="radio"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="button-group">
                <button
                  className="button button--back"
                  type="submit"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  className="button button--primary"
                  type="submit"
                  onSubmit={onSubmit}
                  disabled={
                    loading ||
                    !formSurvey.survey_responses_attributes[
                      questionPrimaryIntentions
                    ]
                  }
                >
                  Continue
                </button>
              </div>
            </div>
          </Form>
          <Range percent={64} />
        </div>
      </div>
    </div>
  );
}

Step5.propTypes = {
  onCheckbox: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Step5.defaultProps = {
  loading: false,
};

export default Step5;
