import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  Root,
  Trigger,
  Portal,
  Content,
  Arrow,
  Item,
} from "@radix-ui/react-dropdown-menu";
import * as Tooltip from "@radix-ui/react-tooltip";
import css from "./styles.module.scss";

type Props = {
  icon?: IconProp;
  actions: {
    label: string;
    icon?: IconProp;
    hidden?: boolean;
    onSelect: (event: Event) => void;
  }[];
};

export function TableActions(props: Props) {
  const { actions } = props;
  const icon = props.icon || ["fas", "ellipsis-vertical"];

  return (
    <Tooltip.Provider>
      <Root>
        <Tooltip.Root>
          <Tooltip.Trigger asChild>
            <Trigger className={css.trigger}>
              <FontAwesomeIcon icon={icon} size="1x" />
            </Trigger>
          </Tooltip.Trigger>
          <Tooltip.Content
            side="bottom"
            className={css.tooltipContent}
            align="end"
            sideOffset={4}
            alignOffset={-4}
          >
            <Tooltip.Arrow />
            <span>More options</span>
          </Tooltip.Content>
        </Tooltip.Root>

        <Portal>
          <Content className={css.content} side="bottom" sideOffset={4}>
            <Arrow className={css.arrow} width={12} height={6} />
            {actions
              .filter((action) => !action.hidden)
              .map((action) => (
                <Item
                  key={action.label}
                  className={css.action}
                  onSelect={action.onSelect}
                >
                  {action.icon ? (
                    <FontAwesomeIcon icon={action.icon} size="1x" />
                  ) : (
                    <div />
                  )}
                  <div className={css.label}>{action.label}</div>
                </Item>
              ))}
          </Content>
        </Portal>
      </Root>
    </Tooltip.Provider>
  );
}
