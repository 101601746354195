import React from "react";

function ArrowRight() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.2097 4.3871L9.29289 4.29289C9.65338 3.93241 10.2206 3.90468 10.6129 4.2097L10.7071 4.29289L13.7071 7.29289L13.7485 7.33685L13.8037 7.40469L13.8753 7.51594L13.9288 7.62866L13.9642 7.73401L13.9931 7.8819L14 8L13.9972 8.07524L13.9798 8.20073L13.9503 8.31214L13.9063 8.42322L13.854 8.52071L13.7803 8.62545L13.7071 8.70711L10.7071 11.7071C10.3166 12.0976 9.68342 12.0976 9.29289 11.7071C8.93241 11.3466 8.90468 10.7794 9.2097 10.3871L9.29289 10.2929L10.585 9H3C2.44772 9 2 8.55228 2 8C2 7.44772 2.44772 7 3 7H10.585L9.29289 5.70711C8.93241 5.34662 8.90468 4.77939 9.2097 4.3871L9.29289 4.29289L9.2097 4.3871Z"
        fill="#16222F"
      />
    </svg>
  );
}

export default ArrowRight;
