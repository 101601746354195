import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  CIRRStudentIntent,
  SEND_SURVEY_OPTIONS_TYPE,
} from "../../../utils/constants";
import { SingleSelectCheckbox } from "../../common";

function ModalSendSurvey({
  open = false,
  onHide,
  onSend,
  isSubmitting,
  onChange,
  dataPrograms,
  dataAdmins,
  dataCohorts,
  isLoadingCohorts,
  formSurvey,
}) {
  const storeAgencies = useSelector((store) => store.agencies);
  const [optionsType, setOptionsType] = useState([...SEND_SURVEY_OPTIONS_TYPE]);

  useEffect(() => {
    let options = [...SEND_SURVEY_OPTIONS_TYPE];
    if (!storeAgencies.data?.includes("CIRR")) {
      options = options.filter((item) => item.value !== CIRRStudentIntent);
    }
    setOptionsType(options);
  }, [storeAgencies]);

  const OPTIONS_PROGRAM =
    dataPrograms?.map((item) => ({
      value: item.id,
      label: item.name,
    })) || [];

  const OPTIONS_ADMIN =
    dataAdmins?.map((item) => ({
      value: item.id,
      label: item.full_name,
    })) || [];

  return (
    <Modal
      show={open}
      onHide={onHide}
      backdropClassName="wrap-modal"
      dialogClassName="Modal Modal__send__survey"
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="bootstrap">
        <Modal.Header>
          <Modal.Title>Share Survey</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="select">
            <p>Type</p>
            <SingleSelectCheckbox
              onChange={onChange}
              name="is_type"
              options={optionsType}
              value={formSurvey.is_type}
              disabled={isSubmitting}
              placeholder="Type"
            />
          </div>
          <div className="select">
            <p>Program</p>
            <SingleSelectCheckbox
              onChange={onChange}
              name="program_id"
              options={OPTIONS_PROGRAM}
              value={formSurvey.program_id}
              disabled={isSubmitting}
              placeholder="Program"
            />
          </div>
          <div className="select">
            <p>Cohort</p>
            <SingleSelectCheckbox
              onChange={onChange}
              name="cohort_id"
              options={
                dataCohorts?.map((item) => ({
                  value: item.id,
                  label: item.name,
                })) || []
              }
              value={formSurvey.cohort_id}
              disabled={isSubmitting || isLoadingCohorts}
              placeholder="Cohort"
            />
          </div>
          <div className="select">
            <p>Verifying Administrator</p>
            <SingleSelectCheckbox
              onChange={onChange}
              name="user_id"
              options={OPTIONS_ADMIN}
              value={formSurvey.user_id}
              disabled={isSubmitting}
              placeholder="Verifying Administrator"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button button--back"
            type="button"
            onClick={onHide}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="button"
            onClick={onSend}
            disabled={
              !formSurvey.program_id ||
              !formSurvey.is_type ||
              !formSurvey.cohort_id ||
              !formSurvey.user_id ||
              isSubmitting
            }
          >
            Share
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default ModalSendSurvey;
