import React from "react";
import { Form } from "react-bootstrap";
import classNames from "classnames";
import eye from "../../assets/icons/hide.svg";

function FormGroup({
  propsFormGroup = {},
  propsInput = {},
  propsIcon = {},
  error = "",
  variant = "Input",
  label = "",
  type = "",
}) {
  const handleChange = (e) => {
    if (propsInput.type === "number") {
      if (e.target.value < 0) {
        return;
      }
    }
    if (propsInput.onChange) {
      propsInput.onChange(e);
    }
  };

  return (
    <Form.Group
      error={error}
      className="form-group form-group--custom"
      {...propsFormGroup}
    >
      {label && <Form.Label>{label}</Form.Label>}
      {(variant === "Input" || variant === "Input-password") && (
        <Form.Control
          {...propsInput}
          onChange={handleChange}
          isInvalid={!!error}
        />
      )}
      {variant === "Check" && <Form.Check {...propsInput} />}
      {type === "checkbox" && (
        <span className="checkmark checkmark--checkbox" />
      )}
      {type === "radio" && (
        <span
          className={classNames("checkmark checkmark--radio", {
            "checkmark--checked": !!propsInput.checked,
          })}
        />
      )}
      {!!error && <span className="invalid-feedback">{error}</span>}
      {variant === "Input-password" && (
        <span className="hide-password" {...propsIcon}>
          <img src={eye} alt="" />
        </span>
      )}
    </Form.Group>
  );
}

export default FormGroup;
