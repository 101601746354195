import React from "react";
import classNames from "classnames";
import { Students, Institution } from "./icons";

function SelectUserRole({ userRole, onChangeRole, type, role }) {
  const isVerifyingStudent = type === "verifying" && role === "student";
  return (
    <>
      <p className="text--small">I am</p>
      <div className="flex justify-center items-center w-full">
        <button
          type="button"
          className={classNames(
            "flex flex-col justify-center items-center bg-[#16222f] hover:bg-[#0199f2] w-[60px] h-[60px] mx-1 cursor-pointer duration-150",
            {
              "bg-[#0199f2]": userRole === "admin",
              "pointer-events-none": isVerifyingStudent,
            }
          )}
          onClick={() => {
            if (isVerifyingStudent) {
              return;
            }
            onChangeRole("admin");
          }}
        >
          <Institution />
          <p className="text-[10px] text-[#fff] m-0 mt-1">Admin</p>
        </button>
        <button
          type="button"
          className={classNames(
            "flex flex-col justify-center items-center bg-[#16222f] hover:bg-[#0199f2] w-[60px] h-[60px] mx-1 cursor-pointer duration-150",
            { "bg-[#0199f2]": userRole === "student" }
          )}
          onClick={() => {
            if (isVerifyingStudent) {
              return;
            }
            onChangeRole("student");
          }}
        >
          <Students />
          <p className="text-[10px] text-[#fff] m-0 mt-1">Student</p>
        </button>
      </div>
    </>
  );
}

export default SelectUserRole;
