import React from "react";
import type { MouseEventHandler } from "react";
import cn from "classnames/bind";
import css from "./styles.module.scss";

const cx = cn.bind(css);

type Props = {
  label: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  primary?: boolean;
};

export function Button(props: Props) {
  const { label, onClick } = props;
  const primary = props.primary ?? false;

  return (
    <button
      type="button"
      className={cx("button", { primary })}
      onClick={onClick}
    >
      {label}
    </button>
  );
}
