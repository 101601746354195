import { combineReducers } from "redux";

import contactUs from "./contactUs";
import global from "./global";
import message from "./message";
import signUp from "./signUp";
import login from "./login";
import forgotPassword from "./forgotPassword";
import resetPassword from "./resetPassword";
import onBoarding from "./onBoarding";
import dashboard from "./dashboard";
import reporting from "./reporting";
import filters from "./filters";
import programs from "./programs";
import financialAidPrograms from "./financialAidPrograms";
import cohorts from "./cohorts";
import branches from "./branches";
import institution from "./institution";
import students from "./students";
import student from "./student";
import surveys from "./surveys";
import users from "./users";
import integration from "./integration";
import profileSetting from "./profileSetting";
import agencies from "./agencies";
import cirrIntendSurvey from "./cirrIntendSurvey";
import campaigns from "./campaigns";
import responses from "./responses";
import tasks from "./tasks";

export default combineReducers({
  contactUs,
  global,
  message,
  signUp,
  login,
  forgotPassword,
  resetPassword,
  onBoarding,
  dashboard,
  reporting,
  filters,
  programs,
  financialAidPrograms,
  cohorts,
  branches,
  institution,
  students,
  surveys,
  student,
  users,
  integration,
  profileSetting,
  agencies,
  cirrIntendSurvey,
  campaigns,
  responses,
  tasks,
});
