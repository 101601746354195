import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

function ModalDeleteDocument({ isOpen, onBack, nameDocument, onDelete }) {
  return (
    <Modal
      show={isOpen}
      onHide={onBack}
      backdropClassName="wrap-modal"
      dialogClassName="Modal Modal__delete__document"
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="bootstrap">
        <Modal.Header>
          <Modal.Title>
            Are you sure you want to delete
            <span className="primary"> '{nameDocument}'</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button
            className="button button--back"
            type="button"
            onClick={onBack}
          >
            Cancel
          </button>
          <button
            className="button button--delete"
            type="button"
            onClick={onDelete}
          >
            Delete
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

ModalDeleteDocument.propTypes = {
  onBack: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  nameDocument: PropTypes.string,
};

ModalDeleteDocument.defaultProps = {
  isOpen: false,
  nameDocument: "",
};

export default ModalDeleteDocument;
