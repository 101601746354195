import React from "react";

export default function () {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 9.99994C15.5128 9.99994 15.9355 10.386 15.9933 10.8833L16 10.9999V12.9999C16 14.5976 14.7511 15.9036 13.1763 15.9948L13 15.9999H3C1.40232 15.9999 0.0963391 14.751 0.00509269 13.1762L0 12.9999V10.9999C0 10.4477 0.447715 9.99994 1 9.99994C1.51284 9.99994 1.93551 10.386 1.99327 10.8833L2 10.9999V12.9999C2 13.5128 2.38604 13.9354 2.88338 13.9932L3 13.9999H13C13.5128 13.9999 13.9355 13.6139 13.9933 13.1166L14 12.9999V10.9999C14 10.4477 14.4477 9.99994 15 9.99994ZM8 -6.10352e-05C8.55228 -6.10352e-05 9 0.447654 9 0.999939V8.58494L10.2929 7.29283C10.6534 6.93235 11.2206 6.90462 11.6129 7.20964L11.7071 7.29283C12.0676 7.65332 12.0953 8.22055 11.7903 8.61284L11.7071 8.70705L8.70711 11.707C8.34662 12.0675 7.77939 12.0953 7.3871 11.7902L7.29289 11.707L4.29289 8.70705C3.90237 8.31652 3.90237 7.68336 4.29289 7.29283C4.65338 6.93235 5.22061 6.90462 5.6129 7.20964L5.70711 7.29283L7 8.58494V0.999939C7 0.447654 7.44772 -6.10352e-05 8 -6.10352e-05Z"
        fill="#0199F2"
      />
    </svg>
  );
}
