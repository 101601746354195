/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { Dropzone } from "../../../common";
import { Delete, Download } from "../../../common/icons";

function Step2({
  form,
  onSubmit,
  handleBack,
  handleDrop,
  document,
  isSubmitting,
  onDeleteDocument,
  error,
}) {
  const general = "Download the General Information Import CSV Template";
  const enrollments = "Download the Enrollments Import CSV Template";
  const placements = "Download the Placements Import CSV Template";
  return (
    <div className="step2  form-student dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="form-student__text">
          <p className="text--large bold">
            {`Import ${
              form.is_type === "general"
                ? "Students"
                : form.is_type === "enrollments"
                ? "Enrollments"
                : "Placements"
            }`}
          </p>
        </div>
        <div className="step2__inner form-student__inner">
          <div className="step2__inner__item">
            <p className="text--xlarge bold">
              {`1. ${
                form.is_type === "general"
                  ? general
                  : form.is_type === "enrollments"
                  ? enrollments
                  : placements
              }`}
            </p>
            <p className="text--small">
              {`The ${
                form.is_type === "general"
                  ? "General Information"
                  : form.is_type === "enrollments"
                  ? "Enrollments"
                  : "Placements"
              }  Import CSV Template provides a ready-made csv file in our accepted format for you to edit with your student ${
                form.is_type === "general"
                  ? ""
                  : form.is_type === "enrollments"
                  ? "enrollments"
                  : "placements"
              } data. You can download the template below:`}
            </p>
            <a
              className="text--small primary bold"
              href={
                form.is_type === "general"
                  ? "https://academysync-static-assets.s3.amazonaws.com/students-general-import-template.csv"
                  : form.is_type === "enrollments"
                  ? "https://academysync-static-assets.s3.amazonaws.com/students-enrollments-import-template.csv"
                  : "https://academysync-static-assets.s3.amazonaws.com/students-placements-import-template-1.csv"
              }
              target="_blank"
              rel="noreferrer"
            >
              <Download />
              {form.is_type === "general"
                ? general
                : form.is_type === "enrollments"
                ? enrollments
                : placements}
            </a>
          </div>
          <div className="step2__inner__item">
            <p className="text--xlarge bold">
              {`2. Edit the CSV Template and Add Your ${
                form.is_type === "general"
                  ? "Student"
                  : form.is_type === "enrollments"
                  ? "Enrollment"
                  : "Placement"
              } List`}
            </p>
            <p className="text--small">
              {`Edit the provided template with your student data with each row representing the ${
                form.is_type === "general" ? "general information" : ""
              } fields for each new ${
                form.is_type === "general"
                  ? "student"
                  : form.is_type === "enrollments"
                  ? "enrollment"
                  : "placement"
              }. 
`}
            </p>
            <p className="text--small">
              Note: Please ensure that the column headers in your edited file
              are present and in the same order as in the template file.
            </p>
          </div>
          <div className="step2__inner__item">
            <p className="text--xlarge bold">3. Upload Your File</p>
            <p className="text--small">
              Select your edited csv file and click 'Continue' to start the
              import. Please ensure that your uploaded CSV file matches the
              formatting in the template file for the upload to run
              successfully.
            </p>
            <div className="Documents">
              {document && !!document.length && (
                <div className="Documents__inner">
                  {document.map((item, index) => (
                    <div className="Document--item" key={item.name}>
                      <p>{item.name}</p>
                      <button type="button" onClick={onDeleteDocument(index)}>
                        <Delete />
                      </button>
                    </div>
                  ))}
                  <div className="error-message">{error}</div>
                </div>
              )}
            </div>
            <div className="add-document">
              <Dropzone
                handleDrop={handleDrop}
                accept=".csv"
                multiple={false}
              />
            </div>
            <div className="button-group">
              <button
                className="button button--back"
                type="submit"
                onClick={handleBack}
                disabled={isSubmitting}
              >
                Back
              </button>
              <button
                className="button button--primary"
                type="submit"
                disabled={!document.length || isSubmitting}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

Step2.propTypes = {
  form: PropTypes.shape({
    is_type: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    file: PropTypes.array,
  }),
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  document: PropTypes.array,
  onDeleteDocument: PropTypes.func.isRequired,
  error: PropTypes.string,
};

Step2.defaultProps = {
  form: {
    is_type: "",
    file: [],
  },
  isSubmitting: false,
  document: [],
  error: "",
};

export default Step2;
