import React from "react";
import css from "./styles.module.scss";

type Props = {
  school: {
    logo?: string;
    name: string;
  };
};

export function SchoolLogo(props: Props) {
  const { school } = props;
  const { logo, name } = school;

  return (
    <div>
      <img className={css.logo} src={logo} alt={`${name} Logo`} />
      {name && <h1 className={css.name}>{name}</h1>}
    </div>
  );
}
