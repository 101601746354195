import { requestClient } from "lib/api";
import qs from "query-string";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import { useSurveyStore } from "../store";
import { mapStoreToFormData } from "../mapStoreToFormData";

type QueryParams = {
  token: string;
  placement_id?: string;
};

const CreatePlacementSchema = z.object({
  id: z.number(),
});

export function useCreateOrUpdateSurveyPlacement() {
  const location = useLocation();
  const { token, placement_id: placementId } = qs.parse(
    location.search
  ) as QueryParams;
  const isUpdate = placementId !== undefined;
  const [setPlacementId] = useSurveyStore((store) => [store.setPlacementId]);

  const mutation = useMutation(async () => {
    const data = mapStoreToFormData();

    let response;
    if (isUpdate) {
      response = await requestClient().put(
        `/surveys/graduate_follow_up/update?token=${token}`,
        data
      );
    } else {
      response = await requestClient().post(
        `/surveys/graduate_follow_up/create?token=${token}`,
        data
      );
    }

    const placement = CreatePlacementSchema.parse(response.data.data);
    setPlacementId(placement.id);
  });

  const isLoading = mutation.isLoading;
  const isError = mutation.isError;

  // Use mutateAsync so we can `await` this when we call it before continuing
  // onto the next step
  const createOrUpdate = mutation.mutateAsync;

  return { isLoading, isError, createOrUpdate };
}
