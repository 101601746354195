import React from "react";

function ArrowUp() {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6977 8.70711C11.0882 9.09763 11.7214 9.09763 12.1119 8.70711C12.5024 8.31658 12.5024 7.68342 12.1119 7.29289L7.11189 2.29289C6.72137 1.90237 6.0882 1.90237 5.69768 2.29289L0.697678 7.29289C0.307154 7.68342 0.307154 8.31658 0.697678 8.70711C1.0882 9.09763 1.72137 9.09763 2.11189 8.70711L6.40479 4.41421L10.6977 8.70711Z"
        fill="white"
      />
    </svg>
  );
}

export default ArrowUp;
