import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AddRole } from "../../../components/dashboard/institution";
import {
  createRole,
  getRolesBuild,
  getRoleDetail,
  updateRole,
} from "../../../redux/actions/institution";
import { openMessage } from "../../../redux/actions/message";
import { ALL, ROUTES } from "../../../utils/constants";
import { handleErrorsAfterCallingAPI } from "../../../utils/helpers";

const DEFAULT_FORM_ADD_ROLE = {
  name: "",
  access_school: false,
  manage_school: false,
  access_cohorts: false,
  manage_cohorts: false,
  access_students: false,
  manage_students: false,
  access_branches: false,
  manage_branches: false,
  access_programs: false,
  manage_programs: false,
};

export default function () {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form, setForm] = React.useState({
    ...DEFAULT_FORM_ADD_ROLE,
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [programPermissions, setProgramPermissions] = React.useState([]);
  const [branchPermissions, setBranchPermissions] = React.useState([]);
  const [accessLevel, setAccessLevel] = React.useState(ALL);
  const storeRolesBuild = useSelector((store) => store.institution.rolesBuild);
  const storeRoleDetail = useSelector((store) => store.institution.roleDetail);
  const selectedRoleId = history.location.state;
  const dataRole = selectedRoleId ? storeRoleDetail.data : storeRolesBuild.data;

  React.useEffect(() => {
    if (selectedRoleId) {
      dispatch(getRoleDetail(selectedRoleId));
    } else {
      dispatch(getRolesBuild());
    }
  }, [selectedRoleId]);

  React.useEffect(() => {
    if (!dataRole || !Object.keys(dataRole).length) return;
    const newForm = {
      ...dataRole,
      name: dataRole.name ? dataRole.name : "",
    };
    delete newForm.branch_permissions;
    delete newForm.program_permissions;
    delete newForm.description;
    setForm(newForm);
  }, [dataRole]);

  React.useEffect(() => {
    if (!dataRole.program_permissions) return;
    const newData = dataRole.program_permissions.map((item) => ({
      ...item,
      program_id: item.program.id,
    }));
    setProgramPermissions(newData);
  }, [dataRole]);

  React.useEffect(() => {
    if (!dataRole.branch_permissions) return;
    const newData = dataRole.branch_permissions.map((item) => ({
      ...item,
      branch_id: item.branch.id,
    }));
    setBranchPermissions(newData);
  }, [dataRole]);

  const handleSubmitAddRole = async (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.name) {
      errors.name = "Role Name is required";
    }
    setFormErrors(errors);

    // call api
    if (!Object.keys(errors).length) {
      const formData = {
        ...form,
      };

      if (!(formData.access_programs && formData.manage_programs)) {
        formData.program_permissions_attributes = programPermissions;
      }
      if (!(formData.access_branches && formData.manage_branches)) {
        formData.branch_permissions_attributes = branchPermissions;
      }
      try {
        if (selectedRoleId) {
          await dispatch(updateRole(formData, selectedRoleId));
        } else {
          await dispatch(createRole(formData));
        }
        dispatch(
          openMessage({
            title: selectedRoleId
              ? "A role has been updated"
              : "A new role has been created",
            type: "success",
          })
        );
        history.push(ROUTES.roles);
      } catch (err) {
        handleErrorsAfterCallingAPI(
          err,
          DEFAULT_FORM_ADD_ROLE,
          setFormErrors,
          dispatch
        );
      }
    }
  };

  const handleChangeAddRole = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleFocusAddRole = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  const handleCheckboxRole = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.checked,
    });
  };

  const handleChangePermissionProgram = (rowData, name) => () => {
    const newData = programPermissions.map((item) =>
      item.program_id === rowData.program_id
        ? { ...item, [name]: !item[name] }
        : item
    );
    setProgramPermissions(newData);
    setForm((prevState) => ({ ...prevState, [`$_programs`]: false }));
  };

  const handleChangePermissionBranch = (rowData, name) => () => {
    const newData = branchPermissions.map((item) =>
      item.branch_id === rowData.branch_id
        ? { ...item, [name]: !item[name] }
        : item
    );
    setBranchPermissions(newData);
    setForm((prevState) => ({ ...prevState, [`${name}_branches`]: false }));
  };

  const handleChangeAccessLevel = (e) => {
    setAccessLevel(e.target.value);
  };

  const handleCheckboxAll = (e) => {
    const { name } = e.target;
    const checked = !form[name];
    const arrSplit = name.split("_");
    const role = arrSplit[0];
    const roleName = arrSplit[1];

    setForm((prevState) => ({ ...prevState, [name]: checked }));
    if (checked) {
      if (roleName === "programs") {
        setProgramPermissions((prevState) =>
          prevState.map((item) => ({ ...item, [role]: false }))
        );
      } else if (roleName === "branches") {
        setBranchPermissions((prevState) =>
          prevState.map((item) => ({ ...item, [role]: false }))
        );
      }
    }
  };

  return (
    <AddRole
      form={form}
      formErrors={formErrors}
      onSubmit={handleSubmitAddRole}
      onChange={handleChangeAddRole}
      onFocus={handleFocusAddRole}
      handleCheckbox={handleCheckboxRole}
      programPermissions={programPermissions}
      handleChangePermissionProgram={handleChangePermissionProgram}
      handleChangePermissionBranch={handleChangePermissionBranch}
      branchPermissions={branchPermissions}
      handleChangeAccessLevel={handleChangeAccessLevel}
      accessLevel={accessLevel}
      handleCheckboxAll={handleCheckboxAll}
      isEditting={!!selectedRoleId}
    />
  );
}
