import React from "react";

export default function () {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C12 2.48716 12.386 2.06449 12.8834 2.00673L13 2H15C16.5977 2 17.9037 3.24892 17.9949 4.82373L18 5V15C18 16.5977 16.7511 17.9037 15.1763 17.9949L15 18H13C12.4477 18 12 17.5523 12 17C12 16.4872 12.386 16.0645 12.8834 16.0067L13 16H15C15.5128 16 15.9355 15.614 15.9933 15.1166L16 15V5C16 4.48716 15.614 4.06449 15.1166 4.00673L15 4H13C12.4477 4 12 3.55229 12 3ZM2 10C2 9.44771 2.44772 9 3 9H10.585L9.29289 7.70711C8.93241 7.34662 8.90468 6.77939 9.2097 6.3871L9.29289 6.29289C9.65338 5.93241 10.2206 5.90468 10.6129 6.2097L10.7071 6.29289L13.7071 9.29289C14.0676 9.65338 14.0953 10.2206 13.7903 10.6129L13.7071 10.7071L10.7071 13.7071C10.3166 14.0976 9.68342 14.0976 9.29289 13.7071C8.93241 13.3466 8.90468 12.7794 9.2097 12.3871L9.29289 12.2929L10.585 11H3C2.44772 11 2 10.5523 2 10Z"
        fill="#0199F2"
      />
    </svg>
  );
}
