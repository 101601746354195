import React from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { replaceParamStudent } from "utils/helpers";
import { TableActions } from "components";
import { ROUTES } from "../../../../utils/constants";
import { Plus, Folder } from "../../../common/icons";
import Card from "./Card";

function ContactLog({
  storeContactLog = { data: [], loading: false, error: null },
  onEdit = () => {},
  onDelete = () => {},
}) {
  const history = useHistory();
  const { studentId } = useParams();

  const handleClick = () => {
    history.push(replaceParamStudent(ROUTES.addContact, studentId));
  };

  const columns1 = [
    {
      title: "Program",
      dataIndex: "program_id",
      render: (row) => (row.program ? row.program.name : "-"),
    },
    {
      title: "Contacted By",
      dataIndex: "contacted_by_id",
      render: (row) =>
        row.contacted_by
          ? `${row.contacted_by.first_name} ${row.contacted_by.last_name}`
          : "-",
    },
    {
      title: "Contact Type",
      dataIndex: "contact_method",
    },
    {
      title: "Log Date",
      dataIndex: "contacted_at",
      render: (row) => moment(row.contacted_at).format("MM/DD/YYYY"),
    },
    {
      title: "Documentation",
      dataIndex: "files",
      render: (row) => {
        if (!row.files) return "-";

        return row.files.map((item) => (
          <a
            className="document"
            key={item.id || item.name}
            href={item.url}
            target="_blank"
            rel="noreferrer"
          >
            <Folder />
            {item.filename}
          </a>
        ));
      },
    },
    {
      title: "",
      dataIndex: "id",
      align: "right",
      render: (row) => (
        <TableActions
          actions={[
            {
              label: "Edit",
              icon: ["fas", "pen-to-square"],
              onSelect: onEdit(row),
            },
            {
              label: "Delete",
              icon: ["fas", "trash"],
              onSelect: onDelete(row),
            },
          ]}
        />
      ),
    },
  ];

  const columns2 = [
    {
      title: "Contact Person",
      dataIndex: "contact_relation",
    },
    {
      title: "Comments",
      dataIndex: "comments",
    },
    {
      title: "",
      dataIndex: "id",
      align: "right",
      render: () => "",
    },
  ];

  return (
    <div className="ContactLog Student-tap dashboard__item__inner">
      <div className="button__add">
        <button type="submit" className="button--icon" onClick={handleClick}>
          <Plus />
          Contact Entry
        </button>
      </div>
      <div>
        {storeContactLog.data.map((item) => (
          <Card
            title={item.contact_name ? item.contact_name : ""}
            data={[item]}
            columns1={columns1}
            columns2={columns2}
            key={item.id}
          />
        ))}
      </div>
    </div>
  );
}

export default ContactLog;
