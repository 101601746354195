import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TableActions } from "components";
import { getAllBranches } from "../../../redux/actions/branches";
import { getAllCohorts } from "../../../redux/actions/cohorts";
import { getAllPrograms } from "../../../redux/actions/programs";
import {
  END_STATUSES,
  OUTCOMES,
  ROUTES,
  SORT_NAME,
  START_STATUSES,
  STUDENTS_SEARCH_NAME,
} from "../../../utils/constants";
import { FormGroup, SingleSelectCheckbox } from "../../common";
import { Import, Plus } from "../../common/icons";
import Table from "../../common/Table";

function StudentsTable({
  storeStudent = { data: [], meta: {}, loading: false, error: null },
  onPageChange = () => null,
  params = {},
  onSort = () => {},
  onSearch = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onChangeFilter = () => {},
  handleClickStudent = () => {},
  onSendSurvey = () => {},
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const allBranches = useSelector((store) => store.branches.all);
  const allPrograms = useSelector((store) => store.programs.all);
  const allCohorts = useSelector((store) => store.cohorts.all);
  const loading =
    allBranches.loading || allPrograms.loading || allCohorts.loading;

  React.useEffect(() => {
    dispatch(getAllBranches());
    dispatch(getAllCohorts());
    dispatch(getAllPrograms());
  }, []);

  const handleClickAddStudent = () => {
    history.push(ROUTES.addStudent);
  };

  const handleClickImportStudent = () => {
    history.push(ROUTES.importStudent);
  };

  const columns = [
    {
      title: "Name ",
      dataIndex: "last_name",
      sortAble: true,
      render: (row) => (
        <div className="name-with-notifications">
          <div className="notifications">
            {row.has_change_request_pending_verification && <span>&bull;</span>}
          </div>
          <div onClick={handleClickStudent(row)} role="button" tabIndex="0">
            {`${row.first_name} ${row.last_name}`}
          </div>
        </div>
      ),
    },
    {
      title: "Cohort",
      dataIndex: "enrollments_cohort_name",
      width: 90,
      sortAble: true,
      render: (row) => (row.cohort && row.cohort.name ? row.cohort.name : "-"),
    },
    {
      title: "Email",
      dataIndex: "email",
      sortAble: true,
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
    },
    {
      title: "Invitation Status",
      dataIndex: "invitation_status",
      width: 120,
    },
    {
      title: "Start Date",
      dataIndex: "cohort_start_date",
      width: 90,
      render: (row) =>
        row.cohort && row.cohort.start_date ? row.cohort.start_date : "-",
    },
    {
      title: "Graduation Date",
      dataIndex: "cohort_graduation_date",
      render: (row) =>
        row.cohort && row.cohort.graduation_date
          ? row.cohort.graduation_date
          : "-",
    },
    {
      title: "Enrollment Status",
      sortAble: true,
      dataIndex: "enrollments_start_status",
      render: (row) => (row.enrollment_status ? row.enrollment_status : "-"),
    },
    {
      title: "Completion Status",
      sortAble: true,
      dataIndex: "enrollments_end_status",
      render: (row) => (row.completion_status ? row.completion_status : "-"),
    },
    {
      title: "Placement Status",
      sortAble: true,
      dataIndex: "placements_outcome",
      render: (row) => (row.placement_status ? row.placement_status : "-"),
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (row) => (
        <TableActions
          actions={[
            {
              label: "Send Survey",
              icon: ["fas", "share"],
              onSelect: onSendSurvey(row),
            },
            {
              label: "Edit",
              icon: ["fas", "pen-to-square"],
              onSelect: onEdit(row),
            },
            {
              label: "Delete",
              icon: ["fas", "trash"],
              onSelect: onDelete(row),
            },
          ]}
        />
      ),
      align: "right",
    },
  ];

  return (
    <div className="Students__inner dashboard__item__inner">
      <div className="filter">
        <div className="filter-1">
          <div className="filter-1__left">
            <FormGroup
              propsInput={{
                name: "name",
                className: "inputSearch",
                placeholder: "Search by name or email",
                onChange: onSearch,
                value: params[STUDENTS_SEARCH_NAME] || "",
              }}
            />
          </div>
          <div className="filter-1__right">
            <button
              type="submit"
              className="button--icon"
              onClick={handleClickAddStudent}
            >
              <Plus />
              Add a Student
            </button>
            <button
              type="submit"
              className="button--icon"
              onClick={handleClickImportStudent}
            >
              <Import />
              Import Students
            </button>
          </div>
        </div>
        <div className="filter-2">
          <SingleSelectCheckbox
            onChange={onChangeFilter}
            name="q[enrollments_cohort_name_eq]"
            options={[
              { value: undefined, label: "All" },
              ...allCohorts.data.map((item) => ({
                value: item.name,
                label: item.name,
              })),
            ]}
            value={{ value: params["q[enrollments_cohort_name_eq]"] }}
            placeholder="Cohort"
            size="small"
            disabled={loading}
          />
          <SingleSelectCheckbox
            onChange={onChangeFilter}
            name="q[enrollments_program_name_eq]"
            options={[
              { value: undefined, label: "All" },
              ...allPrograms.data.map((item) => ({
                value: item.name,
                label: item.name,
              })),
            ]}
            value={{ value: params["q[enrollments_program_name_eq]"] }}
            placeholder="Program"
            size="small"
            disabled={loading}
          />
          <SingleSelectCheckbox
            onChange={onChangeFilter}
            name="q[enrollments_branch_name_eq]"
            options={[
              { value: undefined, label: "All" },
              ...allBranches.data.map((item) => ({
                value: item.name,
                label: item.name,
              })),
            ]}
            value={{ value: params["q[enrollments_branch_name_eq]"] }}
            placeholder="Branch"
            size="small"
            disabled={loading}
          />
          <SingleSelectCheckbox
            onChange={onChangeFilter}
            name="q[enrollments_start_status_eq]"
            options={[{ value: undefined, label: "All" }, ...START_STATUSES]}
            value={{ value: params["q[enrollments_start_status_eq]"] }}
            placeholder="Enrol. Status"
            size="small"
          />
          <SingleSelectCheckbox
            onChange={onChangeFilter}
            name="q[enrollments_end_status_eq]"
            options={[{ value: undefined, label: "All" }, ...END_STATUSES]}
            value={{ value: params["q[enrollments_end_status_eq]"] }}
            placeholder="Compl. Status"
            size="small"
          />
          <SingleSelectCheckbox
            onChange={onChangeFilter}
            name="q[placements_outcome_eq]"
            options={[
              { value: undefined, label: "All" },
              ...OUTCOMES.map((item) => ({ value: item, label: item })),
            ]}
            value={{ value: params["q[placements_outcome_eq]"] }}
            placeholder="Placem. Status"
            size="small"
          />
        </div>
      </div>

      <Table
        loading={storeStudent.loading}
        columns={columns}
        data={storeStudent.data}
        tableProps={{ striped: true }}
        sort={params[SORT_NAME] || ""}
        onSort={onSort}
        paginationProps={{
          itemsLength: storeStudent.meta.total,
          perPage: storeStudent.meta.perPage,
          onPageChange,
          activePage: params.page || 1,
        }}
      />
    </div>
  );
}

export default StudentsTable;
