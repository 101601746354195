import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmail } from "validator";
import { Form } from "../components/forgotPassword";
import { postForgotPassWord } from "../redux/actions/forgotPassword";
import { openMessage } from "../redux/actions/message";

const DEFAULT_FORM_FORGOT_PASSWORD = {
  email: "",
};

export default function () {
  const dispatch = useDispatch();
  const [form, setForm] = React.useState({ ...DEFAULT_FORM_FORGOT_PASSWORD });
  const [formErrors, setFormErrors] = React.useState({});
  const [message, setMessage] = React.useState(false);
  const [role, setRole] = React.useState("admin");

  const storeForgotPassword = useSelector((store) => store.forgotPassword);

  const callApiRForgotPassword = async () => {
    const formatFormData = {
      email: form.email,
      redirect_url: `${window.location.origin}/reset-password?role=${role}`,
    };
    try {
      await dispatch(postForgotPassWord(formatFormData, role));
      setMessage(true);
      setForm({ ...DEFAULT_FORM_FORGOT_PASSWORD });
    } catch (error) {
      if (error.data && typeof error.data.errors === "object") {
        setFormErrors({
          email: error.data.errors.map((item) => item),
        });
      } else {
        dispatch(
          openMessage({
            title: "Something went wrong, please try again!",
            type: "error",
          })
        );
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.email || !isEmail(form.email)) {
      errors.email = "Email is invalid";
    }

    setFormErrors(errors);

    // call api
    if (!Object.keys(errors).length) {
      callApiRForgotPassword();
    }
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };
  return (
    <Form
      form={form}
      formErrors={formErrors}
      onSubmit={handleSubmit}
      onChange={handleChange}
      onFocus={handleFocus}
      isSubmitting={storeForgotPassword.loading}
      showMessage={message}
      role={role}
      setRole={setRole}
    />
  );
}
