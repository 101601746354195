import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import {
  Dropzone,
  FormGroup,
  SingleDatePicker,
  SingleSelectCheckbox,
} from "../../../common";
import { Delete } from "../../../common/icons";
import {
  CONTACT_TYPE,
  OPTION_RELATIONS,
  RESULTS,
} from "../../../../utils/constants";

function AddContact({
  form,
  formErrors,
  onChange,
  onFocus,
  onSubmit,
  onChangeSelect,
  handleChangeDate,
  handleBack,
  // eslint-disable-next-line react/prop-types
  dataPrograms,
  // eslint-disable-next-line react/prop-types
  dataUsers,
  handleDrop,
  onDeleteDocument,
  isSubmitting,
}) {
  // eslint-disable-next-line react/prop-types
  const OPTIONS_PROGRAM = dataPrograms.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  // eslint-disable-next-line react/prop-types
  const OPTIONS_USERS = dataUsers.map((item) => ({
    value: item.id,
    label: `${item.first_name} ${item.last_name}`,
  }));

  return (
    <div className="AddContact  form-student dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="form-student__text">
          <p className="text--xlarge bold">New Contact Entry</p>
        </div>
        <div className="AddContact__inner form-student__inner">
          <div className="input__group">
            <div className="select">
              <p>Program</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="program_id"
                options={OPTIONS_PROGRAM}
                value={{ value: form.program_id }}
                error={formErrors.program_id}
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div className="input__group">
            <div className="select">
              <p>Contacted By</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="contacted_by_id"
                options={OPTIONS_USERS}
                value={{ value: form.contacted_by_id }}
                error={formErrors.contacted_by_id}
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div className="input__group">
            <div className="select">
              <p>Contact Type</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="contact_method"
                options={CONTACT_TYPE}
                value={{ value: form.contact_method }}
                error={formErrors.contact_method}
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div className="input__group">
            <div className="Date-picker">
              <p className="Date-picker__label">Log Date</p>
              <SingleDatePicker
                placeholder="Select Date"
                value={form.contacted_at}
                onChange={handleChangeDate}
                disabled={isSubmitting}
                name="contacted_at"
              />
            </div>
          </div>
          <div className="input__group">
            <div className="select">
              <p>Result</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="result"
                options={RESULTS}
                value={{ value: form.result }}
                error={formErrors.result}
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div className="input__group">
            <div className="select">
              <p>Contact Person</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="contact_relation"
                options={OPTION_RELATIONS}
                value={{ value: form.contact_relation }}
                error={formErrors.contact_relation}
                disabled={isSubmitting}
              />
            </div>
          </div>
          {form.contact_relation !== "Student" && (
            <div className="input__group">
              <FormGroup
                propsInput={{
                  name: "contact_name",
                  placeholder: "Contact Name",
                  onChange,
                  onFocus,
                  value: form.contact_name,
                  disabled: isSubmitting,
                }}
                error={formErrors.contact_name}
                label="Contact Name"
              />
            </div>
          )}
          <div className="input__group">
            <FormGroup
              propsInput={{
                name: "comments",
                placeholder: "Type a Comment",
                as: "textarea",
                onChange,
                onFocus,
                value: form.comments,
                disabled: isSubmitting,
              }}
              error={formErrors.comments}
              label="Comment"
            />
          </div>
          <div className="Documents">
            <p>Documentation</p>
            {form.files && !!form.files.length && (
              <div className="Documents__inner">
                {form.files.map((item, index) => (
                  <div className="Document--item" key={item.name || item.id}>
                    <p>{item.name || item.filename}</p>
                    <button
                      type="button"
                      className="Documents__delete"
                      onClick={onDeleteDocument(index)}
                    >
                      <Delete />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="input__group">
            <div className="add-document">
              <Dropzone handleDrop={handleDrop} />
            </div>
          </div>
        </div>
        <div className="button-group">
          <button
            className="button button--back"
            type="submit"
            onClick={handleBack}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
}

AddContact.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    program_id: PropTypes.number,
    contacted_by_id: PropTypes.number,
    contact_method: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    contacted_at: PropTypes.any,
    result: PropTypes.string,
    contact_relation: PropTypes.string,
    contact_name: PropTypes.string,
    comments: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    files: PropTypes.array,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,

  isSubmitting: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  onDeleteDocument: PropTypes.func.isRequired,
};

AddContact.defaultProps = {
  form: {
    program_id: undefined,
    contacted_by_id: undefined,
    contact_method: "",
    contacted_at: "",
    result: "",
    contact_relation: "",
    comments: "",
    contact_name: "",
    files: [],
  },
  formErrors: {},
  isSubmitting: false,
};

export default AddContact;
