import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/constants";

function ContactUs() {
  return (
    <section className="Contact-us">
      <div className="container">
        <div className="Contact-us__inner background-black radius-l text-center">
          <div className="text-center title white">
            <h4>Let’s get in touch to discuss your next hassle-free report</h4>
            <p className="text">
              Get ready for extra time, less frustration, and the peace of mind
              you’ll always be audit-ready.
            </p>
          </div>
          <Link to={ROUTES.contactUs}>
            <button
              className="button button--primary button--large"
              type="button"
            >
              contact us
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default ContactUs;
