import React from "react";
import { Loading } from "../../../common";
import { Folder } from "../../../common/icons";

function Document({
  storeDocuments = { data: [], loading: false, error: null },
}) {
  const data = storeDocuments.data;
  return (
    <div className="Document Student-tap dashboard__item__inner">
      {storeDocuments.loading ? (
        <Loading />
      ) : (
        <div className="Document__inner">
          {data.enrollments &&
            !!data.enrollments.length &&
            data.enrollments.map((item) => (
              <a
                className="Document__item"
                href={item.url}
                target="_blank"
                rel="noreferrer"
                key={item.id}
              >
                <Folder />
                <p className="primary headline">{item.filename}</p>
                <p className="info">Enrollment & Completion</p>
              </a>
            ))}
          {data.financial_aid_programs &&
            !!data.financial_aid_programs.length &&
            data.financial_aid_programs.map((item) => (
              <a
                className="Document__item"
                href={item.url}
                target="_blank"
                rel="noreferrer"
                key={item.id}
              >
                <Folder />
                <p className="primary headline">{item.filename}</p>
                <p className="info">Financial Assistance</p>
              </a>
            ))}
          {data.placements &&
            !!data.placements.length &&
            data.placements.map((item) => (
              <a
                className="Document__item"
                href={item.url}
                target="_blank"
                rel="noreferrer"
                key={item.id}
              >
                <Folder />
                <p className="primary headline">{item.filename}</p>
                <p className="info">Placement</p>
              </a>
            ))}
          {data.licensure_exams &&
            !!data.licensure_exams.length &&
            data.licensure_exams.map((item) => (
              <a
                className="Document__item"
                href={item.url}
                target="_blank"
                rel="noreferrer"
                key={item.id}
              >
                <Folder />
                <p className="primary headline">{item.filename}</p>
                <p className="info">Licensure</p>
              </a>
            ))}
        </div>
      )}
    </div>
  );
}

export default Document;
