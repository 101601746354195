import React from "react";
import PropTypes from "prop-types";
import { Modal, Collapse } from "react-bootstrap";
import { FormGroup } from "../../common";
import { ArrowDown, ArrowRight2 } from "../../common/icons";
import CollapseCodeItem from "./CollapseCodeItem";

const GROUP_MAJOR = "Major";
const GROUP_MINOR = "Minor";
const GROUP_BROAD = "Broad";
const GROUP_DETAILED = "Detailed";

function ModalAddCode({
  isOpen,
  onBack,
  soc,
  onAdd,
  onSelectSoc,
  selectedSoc,
}) {
  const [open, setOpen] = React.useState({});
  const [data, setData] = React.useState([]);
  const [searchKey, setSearchKey] = React.useState("");
  const [dataGroup, setDataGroup] = React.useState({});
  const [dataGroupFiltered, setDataGroupFiltered] = React.useState({});

  React.useEffect(() => {
    setData(soc);
  }, [soc]);

  React.useEffect(() => {
    const newData = {};
    let majorCode = "";
    let minorCode = "";
    let broadCode = "";
    data.forEach((item) => {
      switch (item.SOCGroup) {
        case GROUP_MAJOR:
          majorCode = item.SOCCode;
          newData[majorCode] = { ...item, subSocs: {} };
          break;

        case GROUP_MINOR:
          minorCode = item.SOCCode;
          newData[majorCode].subSocs[minorCode] = { ...item, subSocs: {} };
          break;

        case GROUP_BROAD:
          broadCode = item.SOCCode;
          newData[majorCode].subSocs[minorCode].subSocs[broadCode] = {
            ...item,
            subSocs: {},
          };
          break;

        case GROUP_DETAILED:
          newData[majorCode].subSocs[minorCode].subSocs[broadCode].subSocs[
            item.SOCCode
          ] = item;
          break;

        default:
          break;
      }
    });
    setDataGroup(newData);
  }, [data]);

  const filterData = (searchText, dataSoc) => {
    if (
      dataSoc.SOCCode.toLowerCase().includes(searchText.toLowerCase()) ||
      dataSoc.SOCTitle.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return dataSoc;
    }

    if (!dataSoc.subSocs || !Object.keys(dataSoc.subSocs).length) {
      return null;
    }

    let newData = { subSocs: {} };
    Object.values(dataSoc.subSocs).forEach((item) => {
      const filteredData = filterData(searchText, item);
      if (filteredData) {
        newData = { ...dataSoc, subSocs: newData.subSocs };
        newData.subSocs[item.SOCCode] = filteredData;
      }
    });

    if (newData.SOCCode) {
      return newData;
    }

    return null;
  };

  React.useEffect(() => {
    const newData = {};
    Object.values(dataGroup).forEach((item) => {
      const filteredData = filterData(searchKey, item);
      if (filteredData) {
        newData[item.SOCCode] = filteredData;
      }
    });

    setDataGroupFiltered(newData);
  }, [dataGroup, searchKey]);

  const handleOpen = (code) => () => {
    setOpen((prevState) => ({ ...prevState, [code]: !open[code] }));
  };

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
  };

  return (
    <Modal
      show={isOpen}
      onHide={onBack}
      backdropClassName="wrap-modal"
      dialogClassName="Modal Modal__add-code"
      backdrop="static"
      keyboard={false}
    >
      <div className="bootstrap">
        <Modal.Header>
          <Modal.Title>
            2018 Standard Occupational Classification System
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input__search">
            <FormGroup
              propsInput={{
                name: "search",
                className: "inputSearch",
                placeholder: "Search",
                onChange: handleSearch,
                value: searchKey,
              }}
            />
          </div>
          <div className="list-code">
            {Object.values(dataGroupFiltered).map((major) => (
              <div className="Code--collapse" key={major.SOCCode}>
                <div
                  onClick={handleOpen(major.SOCCode)}
                  role="button"
                  tabIndex="0"
                  className="btn-collape"
                >
                  <div className="iconArrow">
                    {open[major.SOCCode] ? <ArrowDown /> : <ArrowRight2 />}
                  </div>
                  <p>{`${major.SOCCode} ${major.SOCTitle}`}</p>
                </div>
                <Collapse in={open[major.SOCCode]} className="collapse__parent">
                  <div>
                    {Object.values(major.subSocs).map((minor) => (
                      <div key={minor.SOCCode}>
                        <div
                          onClick={handleOpen(minor.SOCCode)}
                          role="button"
                          tabIndex="0"
                          className="btn-collape"
                        >
                          <div className="iconArrow">
                            {open[minor.SOCCode] ? (
                              <ArrowDown />
                            ) : (
                              <ArrowRight2 />
                            )}
                          </div>
                          <p className="text--xlarge">{`${minor.SOCCode} ${minor.SOCTitle}`}</p>
                        </div>
                        <Collapse
                          in={open[minor.SOCCode]}
                          className="collapse__children"
                        >
                          <div>
                            {Object.values(minor.subSocs).map((broad) => (
                              <div key={broad.SOCCode}>
                                <p className="text--title">
                                  • {`${broad.SOCCode} ${broad.SOCTitle}`}
                                </p>
                                {Object.values(broad.subSocs).map((detail) => (
                                  <CollapseCodeItem
                                    key={detail.SOCCode}
                                    data={detail}
                                    onChange={onSelectSoc}
                                    checked={!!selectedSoc[detail.SOCCode]}
                                  />
                                ))}
                              </div>
                            ))}
                          </div>
                        </Collapse>
                      </div>
                    ))}
                  </div>
                </Collapse>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button button--back"
            type="button"
            onClick={onBack}
          >
            Back
          </button>
          <button
            className="button button--primary"
            type="button"
            onClick={onAdd}
          >
            Add
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

ModalAddCode.propTypes = {
  onBack: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSelectSoc: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedSoc: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  soc: PropTypes.arrayOf(
    PropTypes.shape({
      SOCGroup: PropTypes.string,
      SOCCode: PropTypes.string,
      SOCTitle: PropTypes.string,
      SOCDefinition: PropTypes.string,
    })
  ),
};

ModalAddCode.defaultProps = {
  isOpen: false,
  soc: [],
};

export default ModalAddCode;
