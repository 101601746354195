import * as types from "../constants";
import request from "../../utils/request";

export function getIntegrations() {
  return (dispatch) => {
    dispatch({ type: types.GET_INTEGRATIONS });
    return request()
      .get("integrations")
      .then((dataResponse) => {
        dispatch({
          type: types.GET_INTEGRATIONS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_INTEGRATIONS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getSalesforceActivate() {
  return (dispatch) => {
    dispatch({ type: types.GET_SALESFORCE_ACTIVATE });
    return request()
      .get("integrations/salesforce/activate")
      .then((dataResponse) => {
        dispatch({
          type: types.GET_SALESFORCE_ACTIVATE_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_SALESFORCE_ACTIVATE_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getSalesforceObjects() {
  return (dispatch) => {
    dispatch({ type: types.GET_SALESFORCE_OBJECTS });
    return request()
      .get("integrations/salesforce/objects")
      .then((dataResponse) => {
        dispatch({
          type: types.GET_SALESFORCE_OBJECTS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_SALESFORCE_OBJECTS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getSalesforceAttributes(object, model) {
  return (dispatch) => {
    dispatch({ type: types.GET_SALESFORCE_ATTRIBUTES });
    return request()
      .get(`integrations/salesforce/attributes?object=${object}`)
      .then((dataResponse) => {
        dispatch({
          type: types.GET_SALESFORCE_ATTRIBUTES_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            model,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_SALESFORCE_ATTRIBUTES_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createSalesforceAuthorize(data) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_SALESFORCE_AUTHORIZE });
    return request()
      .post("/integrations/salesforce/authorize", { code: data })
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_SALESFORCE_AUTHORIZE_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_SALESFORCE_AUTHORIZE_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createSalesforceMapping(data) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_SALESFORCE_MAPPING });
    return request()
      .post("/integrations/salesforce/mapping", { integration_mapping: data })
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_SALESFORCE_MAPPING_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_SALESFORCE_MAPPING_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createSalesforceBulkMapping(data) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_SALESFORCE_BULK_MAPPING });
    return request()
      .post("/integrations/salesforce/bulk_mapping", {
        bulk_integration_mapping: data,
      })
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_SALESFORCE_BULK_MAPPING_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_SALESFORCE_BULK_MAPPING_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createSalesforceSync() {
  return (dispatch) => {
    dispatch({ type: types.CREATE_SALESFORCE_SYNC });
    return request()
      .post("/integrations/salesforce/sync")
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_SALESFORCE_SYNC_SUCCEED,
          payload: dataResponse.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_SALESFORCE_SYNC_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getSalesforceMapping() {
  return (dispatch) => {
    dispatch({ type: types.GET_SALESFORCE_MAPPINGS });
    return request()
      .get("integrations/salesforce/mappings")
      .then((dataResponse) => {
        dispatch({
          type: types.GET_SALESFORCE_MAPPINGS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_SALESFORCE_MAPPINGS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getSalesforceDestroyAll() {
  return (dispatch) => {
    dispatch({ type: types.GET_SALESFORCE_DESTROY_ALL });
    return request()
      .get("integrations/salesforce/destroy")
      .then((dataResponse) => {
        dispatch({
          type: types.GET_SALESFORCE_DESTROY_ALL_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_SALESFORCE_DESTROY_ALL_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function deleteIntegration(integrationId) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_INTEGRATION });
    return request()
      .delete(`/integrations/${integrationId}`)
      .then((dataResponse) => {
        dispatch({
          type: types.DELETE_INTEGRATION_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_INTEGRATION_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getHubSpotActivate() {
  return (dispatch) => {
    dispatch({ type: types.GET_HUBSPOT_ACTIVATE });
    return request()
      .get("integrations/hubspot/activate")
      .then((dataResponse) => {
        dispatch({
          type: types.GET_HUBSPOT_ACTIVATE_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_HUBSPOT_ACTIVATE_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createHubSpotAuthorize(data) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_HUBSPOT_AUTHORIZE });
    return request()
      .post("/integrations/hubspot/authorize", { code: data })
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_HUBSPOT_AUTHORIZE_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_HUBSPOT_AUTHORIZE_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getHubSpotAttributes() {
  return (dispatch) => {
    dispatch({ type: types.GET_HUBSPOT_ATTRIBUTES });
    return request()
      .get(`integrations/hubspot/attributes`)
      .then((dataResponse) => {
        dispatch({
          type: types.GET_HUBSPOT_ATTRIBUTES_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_HUBSPOT_ATTRIBUTES_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createHubSpotMapping(data) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_HUBSPOT_MAPPING });
    return request()
      .post("/integrations/hubspot/mapping", { integration_mapping: data })
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_HUBSPOT_MAPPING_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_HUBSPOT_MAPPING_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createHubSpotSync() {
  return (dispatch) => {
    dispatch({ type: types.CREATE_HUBSPOT_SYNC });
    return request()
      .post("/integrations/hubspot/sync")
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_HUBSPOT_SYNC_SUCCEED,
          payload: dataResponse.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_HUBSPOT_SYNC_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getHubSpotMapping() {
  return (dispatch) => {
    dispatch({ type: types.GET_HUBSPOT_MAPPINGS });
    return request()
      .get("integrations/hubspot/mappings")
      .then((dataResponse) => {
        dispatch({
          type: types.GET_HUBSPOT_MAPPINGS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_HUBSPOT_MAPPINGS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
