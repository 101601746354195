import React, { forwardRef, Fragment } from "react";
import type { ForwardedRef } from "react";
import cn from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import css from "./styles.module.scss";

const cx = cn.bind(css);

type Props<T extends string> = {
  label?: string;
  options: {
    label: string;
    details?: ReactNode;
    value: T;
  }[];
  vertical?: boolean;
  align?: "center" | "left";
  error?: string;
} & FormControlProps;

export const RadioGroup = forwardRef(
  <T extends string>(props: Props<T>, ref: ForwardedRef<HTMLInputElement>) => {
    const { label, options, name, error, onChange, onBlur } = props;
    const hasError = error !== undefined;
    const vertical = props?.vertical ?? false;
    const align = props?.align ?? "center";

    return (
      <fieldset className={css.control}>
        {(label || error) && (
          <div className={css.textArea}>
            <legend className={css.label}>{label}</legend>
            <div className={css.error}>{error}</div>
          </div>
        )}
        <div className={cx("radios", align, { vertical, hasError })}>
          {options.map((option) => (
            <Fragment key={`${name}-${option.value}`}>
              <input
                type="radio"
                name={name}
                id={`${name}-${option.value}`}
                ref={ref}
                value={option.value}
                onChange={onChange}
                onBlur={onBlur}
              />
              <label htmlFor={`${name}-${option.value}`}>
                <div className={css.icons}>
                  <FontAwesomeIcon
                    className={css.unchecked}
                    icon={["fas", "circle"]}
                    size="1x"
                  />
                  <FontAwesomeIcon
                    className={css.checked}
                    icon={["fas", "circle-dot"]}
                    size="1x"
                  />
                </div>
                <div className={css.text}>
                  <div>{option.label}</div>
                  {option.details && (
                    <div className={css.details}>{option.details}</div>
                  )}
                </div>
              </label>
            </Fragment>
          ))}
        </div>
      </fieldset>
    );
  }
);
