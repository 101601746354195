import * as types from "../constants";
import request from "../../utils/request";

export function postContactUs(data) {
  return (dispatch) => {
    dispatch({ type: types.POST_CONTACT_US });
    return request()
      .post("/inquiries", { inquiry: data })
      .then((dataResponse) => {
        dispatch({
          type: types.POST_CONTACT_US_SUCCEED,
          payload: dataResponse,
        });
        return dataResponse;
      })
      .catch((error) => {
        dispatch({
          type: types.POST_CONTACT_US_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
