import React from "react";

function Institution() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
    >
      <mask id="path-1-institution-1" fill="white">
        <rect y="0.0644531" width="16" height="20" rx="1" />
      </mask>
      <rect
        y="0.0644531"
        width="16"
        height="20"
        rx="1"
        stroke="white"
        strokeWidth="4"
        mask="url(#path-1-institution-1)"
      />
      <mask id="path-2-institution-2" fill="white">
        <rect x="14" y="7.06445" width="8" height="13" rx="1" />
      </mask>
      <rect
        x="14"
        y="7.06445"
        width="8"
        height="13"
        rx="1"
        stroke="white"
        strokeWidth="4"
        mask="url(#path-2-institution-2)"
      />
      <mask id="path-3-institution-3" fill="white">
        <rect x="4" y="13.0645" width="8" height="7" rx="1" />
      </mask>
      <rect
        x="4"
        y="13.0645"
        width="8"
        height="7"
        rx="1"
        stroke="white"
        strokeWidth="4"
        mask="url(#path-3-institution-3)"
      />
      <rect x="4" y="3.06445" width="2" height="3" rx="1" fill="white" />
      <rect x="4" y="8.06445" width="2" height="3" rx="1" fill="white" />
      <rect x="7" y="3.06445" width="2" height="3" rx="1" fill="white" />
      <rect x="7" y="8.06445" width="2" height="3" rx="1" fill="white" />
      <rect x="10" y="3.06445" width="2" height="3" rx="1" fill="white" />
      <rect x="18" y="3.06445" width="2" height="5" rx="1" fill="white" />
      <rect x="10" y="8.06445" width="2" height="3" rx="1" fill="white" />
    </svg>
  );
}

export default Institution;
