import React from "react";
import triangle from "../../assets/images/triangles2.png";
import about from "../../assets/images/illustration.png";

function AboutUs() {
  return (
    <section className="About-us">
      <div id="about-us" className="top-section" />
      <div className="container">
        <div className="About-us__inner">
          <div className="About-us__inner__left">
            <img src={about} alt="" />
          </div>
          <div className="About-us__inner__right">
            <h4 className="title">
              Reporting student outcomes can be frustrating and time-consuming.{" "}
            </h4>
            <p className="text">
              With AcademySync’s Reporting Tool, collect student outcome data
              through an automated, synchronized system, and export
              ready-to-submit reports for state and accreditation agencies --
              all in one easy-to-use, intuitive program.{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="triangle__wrap">
        <img src={triangle} alt="" className="triangle" />
      </div>
    </section>
  );
}

export default AboutUs;
