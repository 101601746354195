import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import type { SurveyInfo } from "../api";
import {
  Card,
  Button,
  ButtonArea,
  StepHeader,
  Form,
  RadioGroup,
} from "../components";
import { useSurveyStore } from "../store";

type Props = {
  surveyInfo: SurveyInfo;
  progress?: string;
  onNextClick: () => void;
  onBackClick: () => void;
};

const schema = z.object({
  selfEmploymentType: z.enum(["Entrepreneur", "Freelance"], {
    invalid_type_error: "Required",
  }),
});

type Inputs = z.infer<typeof schema>;

export function SelfEmploymentTypeStep(props: Props) {
  const { surveyInfo, progress, onNextClick, onBackClick } = props;
  const [state, setState] = useSurveyStore((store) => [
    store.selfEmploymentType,
    store.setSelfEmploymentType,
  ]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: { selfEmploymentType: state },
    resolver: zodResolver(schema),
  });

  const handleBackClick = () => {
    setState(getValues());
    onBackClick();
  };

  const handleNextClick = async () => {
    await handleSubmit((data) => {
      setState(data);
      onNextClick();
    })();
  };

  return (
    <Card progress={progress}>
      <StepHeader
        surveyInfo={surveyInfo}
        title="Which of the following describes the nature of your self-employment?"
      />

      <Form>
        <RadioGroup<Inputs["selfEmploymentType"]>
          vertical
          options={[
            {
              label:
                "Entrepreneur/Running my own business (business owner or partner; for-profit or non-profit)",
              value: "Entrepreneur",
            },
            {
              label: "Freelance work",
              value: "Freelance",
            },
          ]}
          error={errors?.selfEmploymentType?.message}
          {...register("selfEmploymentType")}
        />
      </Form>

      <ButtonArea>
        <Button label="Back" onClick={handleBackClick} />
        <Button primary label="Continue" onClick={handleNextClick} />
      </ButtonArea>
    </Card>
  );
}
