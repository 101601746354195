import React from "react";
import moment from "moment";
import { SORT_NAME } from "../../../utils/constants";
import { SingleSelectCheckbox } from "../../common";
import Table from "../../common/Table";

const OPTIONS_STATUS = [
  {
    label: "All",
    value: undefined,
  },
  {
    label: "Pending",
    value: "pending",
  },
  {
    label: "In Review",
    value: "in_review",
  },
  {
    label: "Completed",
    value: "completed",
  },
  {
    label: "Rejected",
    value: "rejected",
  },
];

const OPTIONS_TYPES = [
  {
    label: "All",
    value: undefined,
  },
  {
    label: "Attendance",
    value: "attendance",
  },
  {
    label: "Enrollment",
    value: "enrollment",
  },
  {
    label: "Placement",
    value: "placement",
  },
];

function TasksTable({
  store = { data: [], meta: {}, loading: false, error: null },
  onPageChange = () => null,
  params = {},
  onSort = () => {},
  onChangeFilter = () => {},
  onClickStudentName = () => {},
}) {
  const columns = [
    {
      title: "Student Name",
      dataIndex: "student",
      sortAble: true,
      render: (row) => (
        <p
          className="tasks__student__name"
          onClick={() => onClickStudentName(row)}
        >
          {`${row.student.first_name} ${row.student.last_name}`}
        </p>
      ),
    },
    {
      title: "Task Type",
      dataIndex: "task_type",
      sortAble: true,
      render: (row) => <span className="capitalize">{row.task_type}</span>,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      sortAble: true,
      render: (row) => moment(row.created_at).utc().format("MM/DD/YYYY"),
    },
    {
      title: "Status",
      dataIndex: "status",
      sortAble: true,
      render: (row) => (
        <span className={`tasks__status tasks__status--${row.status}`}>
          {row.status}
        </span>
      ),
    },
  ];

  return (
    <div className="Responses__inner dashboard__item__inner">
      <div className="filter">
        <div className="filter__left">
          <SingleSelectCheckbox
            onChange={onChangeFilter}
            name="q[status_eq]"
            options={OPTIONS_STATUS}
            value={{ value: params["q[status_eq]"] }}
            placeholder="Status"
            size="small"
            disabled={store.loading}
          />
          <SingleSelectCheckbox
            onChange={onChangeFilter}
            name="q[task_type_eq]"
            options={OPTIONS_TYPES}
            value={{ value: params["q[task_type_eq]"] }}
            placeholder="Task type"
            size="small"
            disabled={store.loading}
          />
        </div>
      </div>
      <Table
        loading={store.loading}
        columns={columns}
        data={store.data}
        tableProps={{ striped: true }}
        sort={params[SORT_NAME] || ""}
        onSort={onSort}
        paginationProps={{
          itemsLength: store.meta.total,
          perPage: store.meta.perPage,
          onPageChange,
          activePage: params.page || 1,
        }}
      />
    </div>
  );
}

export default TasksTable;
