import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import border from "../../assets/images/border-step.svg";

function Steps({ step }) {
  return (
    <div className="Steps ">
      <div className="Steps__inner">
        <div
          className={classNames("Step_item", {
            isActive: step === 1,
            complete: step > 1,
          })}
        >
          <div className="number bold">
            <p>1</p>
          </div>
          <p className="text bold">Add Institutions</p>
        </div>
        <img src={border} alt="" />
        <div
          className={classNames("Step_item", {
            isActive: step === 2,
            complete: step > 2,
          })}
        >
          <div className="number bold">
            <p>2</p>
          </div>
          <p className="text bold">Confirm Agencies</p>
        </div>
        <img src={border} alt="" />
        <div
          className={classNames("Step_item", {
            isActive: step === 3,
            complete: step > 3,
          })}
        >
          <div className="number bold">
            <p>3</p>
          </div>
          <p className="text bold">Pending Approval</p>
        </div>
      </div>
    </div>
  );
}

Steps.propTypes = {
  step: PropTypes.number.isRequired,
};

export default Steps;
