import React from "react";
import { Navbar, Nav, Button } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import logo from "../../assets/images/logo.svg";
import logo2 from "../../assets/images/logo2.svg";
import { ROUTES } from "../../utils/constants";

function Header({ isDarkHeader, isBoxshadow }) {
  return (
    <header className={classNames("header", { "-isBoxshadow": isBoxshadow })}>
      <Navbar
        expand="lg"
        sticky="top"
        className={classNames({ "-isDarkHeader": isDarkHeader })}
      >
        <Navbar.Brand>
          <Link to="/" className="navbar-brand">
            {isDarkHeader ? (
              <img src={logo2} alt="logo" />
            ) : (
              <img src={logo} alt="logo" />
            )}
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            {/* <Scrollspy
              items={['home', 'about-us', 'how-it-works', 'agencies']}
              currentClassName="--is-current"
              className="Scrollspy-wrapper"
            >
              <li>
                <NavLink className="nav-link" to="/#home">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/#about-us">
                  About us
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/#how-it-works">
                  How It Works
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to="/#agencies">
                  Agencies
                </NavLink>
              </li>
            </Scrollspy> */}
            <Nav.Link>
              <NavLink
                exact
                className="nav-link"
                to={ROUTES.home}
                activeClassName="--is-active"
              >
                Home
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink
                exact
                className="nav-link"
                to={ROUTES.pricing}
                activeClassName="--is-active"
              >
                Pricing
              </NavLink>
            </Nav.Link>
            <Nav.Link>
              <NavLink
                exact
                className="nav-link"
                to={ROUTES.contactUs}
                activeClassName="--is-active"
              >
                Contact Us
              </NavLink>
            </Nav.Link>
            <Nav.Link
              role="link"
              href="https://calendly.com/academysync/30min?back=1&month=2022-12"
            >
              <div className="nav-link">Request A Demo</div>
            </Nav.Link>
            <Nav.Link>
              <NavLink
                exact
                className="nav-link"
                to={ROUTES.login}
                activeClassName="--is-active"
              >
                Login
              </NavLink>
            </Nav.Link>
            <Navbar.Text>
              <Link to={ROUTES.signup} className="button-register">
                <Button className="button button--primary">Get Started</Button>
              </Link>
            </Navbar.Text>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

Header.propTypes = {
  isDarkHeader: PropTypes.bool,
  isBoxshadow: PropTypes.bool,
};
Header.defaultProps = {
  isDarkHeader: false,
  isBoxshadow: false,
};

export default Header;
