import React from "react";
import classNames from "classnames";
import step6 from "../../../../assets/images/pulling-data-step6.svg";
import step5 from "../../../../assets/images/pulling-data-step5.svg";

function PullingData() {
  const [step, setStep] = React.useState(6);

  return (
    <div className="PullingData">
      <div className="PullingData__header --header">
        {/* <NavLink
          to={ROUTES.instructionSalesforce}
          className="text--small bold "
          activeClassName="isActive"
          exact
        >
          <ArrowLeft2 />
          Return to Last Step
        </NavLink> */}
        <h4>Pulling & Pushing Data</h4>
        <p className="text--small">
          Salesforce Push lets you “pull” student data from Salesforce to
          AcademySync and “push” enrollment, placement, and completion
          information back into Salesforce from our tool.
        </p>
      </div>
      <div className="Step-by-step dashboard__item__inner">
        <div className="Step-by-step__inner ">
          <p className="text--large bold title">Step By Step Instructions</p>
          <div>
            <div className="Step-by-step__left">
              <div
                className={classNames("step__item", {
                  "--active": step >= 1,
                })}
                onClick={() => {
                  setStep(1);
                }}
                role="button"
                tabIndex="0"
              >
                <span>1</span>
                <p className="text">Go to the Students Page</p>
              </div>
              <div className="border-dashed" />
              <div
                className={classNames("step__item", {
                  "--active": step >= 2,
                })}
                onClick={() => {
                  setStep(2);
                }}
                role="button"
                tabIndex="0"
              >
                <span>2</span>
                <p className="text">Go to the Integrations section</p>
              </div>
              <div className="border-dashed" />
              <div
                className={classNames("step__item", {
                  "--active": step >= 3,
                })}
                onClick={() => {
                  setStep(3);
                }}
                role="button"
                tabIndex="0"
              >
                <span>3</span>
                <p className="text">Install Salesforce integration</p>
              </div>
              <div className="border-dashed" />
              <div
                className={classNames("step__item", {
                  "--active": step >= 4,
                })}
                onClick={() => {
                  setStep(4);
                }}
                role="button"
                tabIndex="0"
              >
                <span>4</span>
                <p className="text">Log in to your Salesforce account</p>
              </div>
              <div className="border-dashed" />
              <div
                className={classNames("step__item", {
                  "--active": step >= 5,
                })}
                onClick={() => {
                  setStep(5);
                }}
                role="button"
                tabIndex="0"
              >
                <span>5</span>
                <p className="text">Select Pull & Push Data import</p>
              </div>
              <div className="border-dashed" />
              <div
                className={classNames("step__item", {
                  "--active": step >= 6,
                })}
                onClick={() => {
                  setStep(6);
                }}
                role="button"
                tabIndex="0"
              >
                <span>6</span>
                <p className="text">Map fields to Pull from Salesforce</p>
              </div>
              <div className="border-dashed" />
              <div
                className={classNames("step__item", {
                  "--active": step >= 7,
                })}
                onClick={() => {
                  setStep(7);
                }}
                role="button"
                tabIndex="0"
              >
                <span>7</span>
                <p className="text">Map Fields to Push into Salesforce</p>
              </div>
              <div className="border-dashed" />
              <div
                className={classNames("step__item", {
                  "--active": step >= 8,
                })}
                onClick={() => {
                  setStep(8);
                }}
                role="button"
                tabIndex="0"
              >
                <span>8</span>
                <p className="text">Sync Data</p>
              </div>
              <div className="border-dashed" />
              <div
                className={classNames("step__item", {
                  "--active": step >= 9,
                })}
                onClick={() => {
                  setStep(9);
                }}
                role="button"
                tabIndex="0"
              >
                <span>9</span>
                <p className="text">View Summary / Remap Data</p>
              </div>
            </div>
            {step === 5 && (
              <div className="Step-by-step__right">
                <img src={step5} alt="" />
                <p className="text--small">
                  Select the ‘Pull & Push Data’ mapping type in order to pull
                  student data from Salesforce and to push enrollment,
                  completion, and placement data to Salesforce from AcademySync.
                </p>
              </div>
            )}
            {step === 6 && (
              <div className="Step-by-step__right">
                <img src={step6} alt="" />
                <p className="text--small">
                  Use the drop down to select a field in Salesforce that matches
                  the appropriate field in the AcademySync table.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="PullingData__text --header">
        <p className="text--xlarge bold title">What can be synced?</p>
        <p className="text--small">
          The AcademySync/Salesforce integration can sync any data that lives
          inside a Salesforce object. You can select the desired SF fields, and
          they will be pulled into the AcademySync Account or Visitor object.
          You will need to have a key on both the AcademySync side and
          Salesforce side that matches one-to-one to connect the two data
          sources (such as a "salesforce ID" or a matching "Student ID").
        </p>
        <p className="text--small --note">
          <span className="bold">Note:</span> Make sure to set up relevant
          branches, programs and cohorts before running an import for the first
          time. For the integration to work properly it is critical that there
          are no duplicates of the key field chosen to link AcademySync and
          Salesforce.{" "}
        </p>
        <p className="text--xlarge bold title">How often is the data synced?</p>
        <p className="text--small">
          Data is synced once per evening. You can also manually sync the data
          at any point on the integrations page under Institutions.
        </p>
      </div>
    </div>
  );
}

export default PullingData;
