import * as types from "../constants";
import store from "../store";

export function updateIsBadToken(isBadToken) {
  store.dispatch({
    type: types.UPDATE_IS_BAD_TOKEN,
    payload: isBadToken,
  });
}

export function updateReportingURL(url) {
  store.dispatch({
    type: types.UPDATE_REPORTING_URL,
    payload: url,
  });
}

export function updateReportName(reportName) {
  store.dispatch({
    type: types.UPDATE_REPORT_NAME,
    payload: reportName,
  });
}

export function openModalMessageDelete(data) {
  const defaultData = { onDelete: () => {} };
  return (dispatch) => {
    dispatch({
      type: types.OPEN_MODAL_MESSAGE_DELETE,
      payload: { ...defaultData, ...data },
    });
  };
}

export function closeModalMessageDelete() {
  return (dispatch) => {
    dispatch({
      type: types.CLOSE_MODAL_MESSAGE_DELETE,
    });
  };
}

export function openModalMessageIntegration(data) {
  const defaultData = { onContinue: () => {} };
  return (dispatch) => {
    dispatch({
      type: types.OPEN_MODAL_MESSAGE_INTEGRATION,
      payload: { ...defaultData, ...data },
    });
  };
}

export function closeModalMessageIntegration() {
  return (dispatch) => {
    dispatch({
      type: types.CLOSE_MODAL_MESSAGE_INTEGRATION,
    });
  };
}

export function openModalPayment() {
  return (dispatch) => {
    dispatch({
      type: types.OPEN_MODAL_PAYMENT,
    });
  };
}

export function closeModalPayment() {
  return (dispatch) => {
    dispatch({
      type: types.CLOSE_MODAL_PAYMENT,
    });
  };
}
