import React from "react";
import { Switch, Route } from "react-router-dom";
import { Title } from "../../../components/dashboard";
import { ROUTES } from "../../../utils/constants";
import Root from "./Root";
import Form from "./Form";

export default function () {
  return (
    <div className="Branches dashboard__item">
      <Title title="Branches" />
      <Switch>
        <Route path={ROUTES.branches} component={Root} exact />
        <Route path={ROUTES.addBranch} exact component={Form} />
      </Switch>
    </div>
  );
}
