import React from "react";

export default function () {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.918726 10.2933C0.527727 10.6842 0.527727 11.3162 0.918726 11.7072C1.30973 12.0982 1.94173 12.0982 2.33273 11.7072L7.33273 6.70725C7.72373 6.31625 7.72373 5.68425 7.33273 5.29325L2.33273 0.29325C1.94173 -0.09775 1.30973 -0.09775 0.918726 0.29325C0.527727 0.68425 0.527727 1.31625 0.918726 1.70725L5.21173 6.00025L0.918726 10.2933Z"
        fill="#0199F2"
      />
    </svg>
  );
}
