import React from "react";
import { Table } from "react-bootstrap";
import classNames from "classnames";
import moment from "moment";
import {
  AUDIT_ACTIONS,
  AUDIT_TYPES,
  AUDIT_TYPE_STUDENT,
} from "../../../utils/constants";
import { DateRangePicker, Loading, SingleSelectCheckbox } from "../../common";
import { Delete, Edit, Plus } from "../../common/icons";

function ChangeLog({
  onChangeSelect = () => {},
  params = {},
  data = [],
  dataUsers = [],
  onCallbackDateRange = () => {},
  loading = false,
  handleSeeMore = () => {},
  isOutOfData = false,
}) {
  const COLOR = ["#FFBC42", "#244C7C", "#76CB93", "#D89EA8", "#6FB5E0"];

  const COLORTAG = ["#FFBC42", "#0199F2", "#76CB93"];

  const filteredData = {};
  data.forEach((item) => {
    const date = moment(item.created_at).format("MM/DD/YYYY");
    if (!filteredData[date]) {
      filteredData[date] = [item];
    } else filteredData[date].push(item);
  });

  return (
    <div className="ChangeLog dashboard__item__inner">
      <div className="ChangeLog__inner">
        <div className="filter">
          <SingleSelectCheckbox
            onChange={onChangeSelect}
            placeholder="Select Administrator"
            name="q[user_id_eq]"
            options={[
              { value: undefined, label: "None" },
              ...dataUsers.map((item) => ({
                value: item.id,
                label: `${item.first_name} ${item.last_name}`,
              })),
            ]}
            value={{ value: params["q[user_id_eq]"] }}
            size="small"
          />

          <DateRangePicker
            placeholder="Start Date - End Date"
            onCallback={onCallbackDateRange}
          />

          <SingleSelectCheckbox
            onChange={onChangeSelect}
            placeholder="Sort By"
            name="q[s]"
            options={[
              { value: undefined, label: "None" },
              { value: "created_at asc", label: "Sort Oldest to Newest" },
              { value: "created_at desc", label: "Sort Newest to Oldest" },
            ]}
            value={{ value: params["q[s]"] }}
            size="small"
          />
        </div>
        <div>
          {Object.keys(filteredData).map((date) => (
            <div className="list_change" key={date}>
              <div
                className={classNames("tag bold", {
                  today: date === moment(new Date()).format("MM/DD/YYYY"),
                })}
                style={{
                  background:
                    COLORTAG[Math.floor(Math.random() * COLORTAG.length)],
                }}
              >
                {date === moment(new Date()).format("MM/DD/YYYY")
                  ? "Today"
                  : moment(date).format("MMM DD")}
              </div>
              <div className="wrap__table">
                <div>
                  <Table>
                    <tbody>
                      {filteredData[date].map((item) => (
                        <tr key={item.id}>
                          <td>
                            {item.action === "update" && <Edit />}
                            {item.action === "create" && <Plus />}
                            {item.action === "destroy" && <Delete />}
                          </td>
                          <td className="name">
                            <p>
                              <span
                                style={{
                                  background:
                                    COLOR[
                                      Math.floor(Math.random() * COLOR.length)
                                    ],
                                }}
                              >
                                xx
                              </span>
                              {item.user ? item.user.full_name : "AcademySync"}
                            </p>
                          </td>
                          <td>
                            {`${AUDIT_ACTIONS[item.action]} ${
                              AUDIT_TYPES[item.auditable_type].text
                            } ${
                              item.auditable_type === AUDIT_TYPE_STUDENT &&
                              item.auditable
                                ? `${item.auditable.first_name} ${item.auditable.last_name}`
                                : ""
                            }
                        ${
                          item.auditable_type !== AUDIT_TYPE_STUDENT &&
                          item.associated
                            ? `${item.associated.first_name} ${item.associated.last_name}`
                            : ""
                        }`}
                          </td>
                          <td>{AUDIT_TYPES[item.auditable_type].label}</td>
                          <td className="gray04">
                            {moment(item.created_at).format(
                              "MM/DD/YY [at] h:mma"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          ))}
          {loading ? (
            <Loading />
          ) : (
            !isOutOfData && (
              <div className="button__see-more">
                <button
                  className="button button--primary"
                  type="button"
                  onClick={handleSeeMore}
                >
                  Load more
                </button>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default ChangeLog;
