import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "../../../components/dashboard/campaigns";
import { ROUTES } from "../../../utils/constants";
import { getAllPrograms } from "../../../redux/actions/programs";
import { getAllBranches } from "../../../redux/actions/branches";
import { getAllCohorts } from "../../../redux/actions/cohorts";
import { openMessage } from "../../../redux/actions/message";
import {
  formatDateOfPicker,
  handleErrorsAfterCallingAPI,
} from "../../../utils/helpers";
import {
  createCampaign,
  updateCampaign,
} from "../../../redux/actions/campaigns";

const DEFAULT_FORM_ADD_COHORT = {
  name: "",
  branch_id: null,
  program_id: null,
  cohorts_attribute_ids: [],
  scheduled_date: new Date(),
};

export default function () {
  const [form, setForm] = React.useState({ ...DEFAULT_FORM_ADD_COHORT });
  const [formErrors, setFormErrors] = React.useState({});
  const [program, setProgram] = React.useState({});
  const [branch, setBranch] = React.useState({});
  const [showCohorts, setShowCohorts] = React.useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const storePrograms = useSelector((store) => store.programs.all);
  const storeBranchs = useSelector((store) => store.branches.all);
  const storeCohorts = useSelector((store) => store.cohorts.all);
  const storeCreate = useSelector((store) => store.cohorts.create);
  const storeUpdate = useSelector((store) => store.cohorts.update);
  const isEditing =
    !!history.location.state && history.location.state.id !== null;

  const isSubmitting = storeCreate.loading || storeUpdate.loading;

  React.useEffect(() => {
    const campaignSelected = history.location.state;
    if (!campaignSelected) return;
    setForm({
      name: campaignSelected.name,
      branch_id: campaignSelected.branch.id,
      program_id: campaignSelected.program.id,
      scheduled_date: campaignSelected.scheduled_date,
      cohorts_attribute_ids: campaignSelected.cohorts.map((item) => item.id),
    });
    setProgram({
      value: campaignSelected.program.id,
      label: campaignSelected.program.name,
    });
    setBranch({
      value: campaignSelected.branch.id,
      label: campaignSelected.branch.name,
    });
  }, [history.location.state]);

  React.useEffect(() => {
    dispatch(getAllPrograms());
    dispatch(getAllBranches());
    dispatch(getAllCohorts());
  }, []);

  const formatFormData = (formData) => ({
    ...formData,
    scheduled_date: formatDateOfPicker(formData.scheduled_date),
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.name) {
      errors.name = "Name is required";
    }
    if (!form.program_id) {
      errors.program_id = "Program is required";
    }
    if (!form.branch_id) {
      errors.branch_id = "Branch is required";
    }
    if (!form.cohorts_attribute_ids || !form.cohorts_attribute_ids.length) {
      errors.cohorts_attribute_ids = "Cohort is required";
    }

    setFormErrors(errors);

    // call api
    if (Object.keys(errors).length) return null;

    // update campaign
    if (isEditing) {
      try {
        await dispatch(
          updateCampaign(formatFormData(form), history.location.state.id)
        );
        dispatch(
          openMessage({
            title: "Your campaign has been updated",
            type: "success",
          })
        );
        history.push(ROUTES.campaigns);
      } catch (err) {
        handleErrorsAfterCallingAPI(
          err,
          DEFAULT_FORM_ADD_COHORT,
          setFormErrors,
          dispatch
        );
      }
    } else {
      // create campaign
      try {
        await dispatch(createCampaign(formatFormData(form)));
        dispatch(
          openMessage({
            title: "Your campaign has been created",
            type: "success",
          })
        );
        history.push(ROUTES.campaigns);
      } catch (err) {
        handleErrorsAfterCallingAPI(
          err,
          DEFAULT_FORM_ADD_COHORT,
          setFormErrors,
          dispatch
        );
      }
    }
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  const handleChangeProgram = (selectedItem) => {
    setProgram(selectedItem);
    setForm({ ...form, program_id: selectedItem.value });
    if (form.branch_id) {
      dispatch(
        getAllCohorts({
          program_id: form.program_id,
          branch_id: form.branch_id,
        })
      );
      setForm((prev) => ({ ...prev, cohorts_attribute_ids: [] }));
    }
  };

  const handleChangeBranch = (selectedItem) => {
    setBranch(selectedItem);
    setForm({ ...form, branch_id: selectedItem.value });
    if (form.program_id) {
      dispatch(
        getAllCohorts({
          program_id: form.program_id,
          branch_id: form.branch_id,
        })
      );
      setForm((prev) => ({ ...prev, cohorts_attribute_ids: [] }));
    }
  };

  const handleChangeCohorts = (selectedItem) => () => {
    setForm((prev) => {
      let newCohorts = [];
      if (prev.cohorts_attribute_ids.includes(selectedItem.value)) {
        newCohorts = prev.cohorts_attribute_ids.filter(
          (item) => item !== selectedItem.value
        );
      } else {
        newCohorts = [...prev.cohorts_attribute_ids, selectedItem.value];
      }
      return { ...prev, cohorts_attribute_ids: newCohorts };
    });
  };

  const handleCallbackStartDate = (start) => {
    setForm({ ...form, scheduled_date: start });
  };

  const handleToggleCohorts = () => {
    setShowCohorts((prev) => !prev);
  };

  return (
    <Form
      form={form}
      formErrors={formErrors}
      onSubmit={handleSubmit}
      onChange={handleChange}
      onFocus={handleFocus}
      handleChangeProgram={handleChangeProgram}
      program={program}
      handleChangeBranch={handleChangeBranch}
      branch={branch}
      handleChangeCohorts={handleChangeCohorts}
      handleCallbackStartDate={handleCallbackStartDate}
      dataPrograms={storePrograms.data}
      dataBranchs={storeBranchs.data}
      dataCohorts={storeCohorts.data}
      isEditing={isEditing}
      isSubmitting={isSubmitting}
      showCohorts={showCohorts}
      handleToggleCohorts={handleToggleCohorts}
    />
  );
}
