import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames/bind";
import css from "./styles.module.scss";

const cx = cn.bind(css);

type Props = {
  children: ReactNode;
  progress?: string;
};

export function Card(props: Props) {
  const { children, progress } = props;
  const complete = progress === "100";

  return (
    <>
      <div className={css.card}>
        <div className={css.inner}>{children}</div>
      </div>

      {progress && (
        <section
          data-testid="survey-progress"
          data-percent={progress}
          data-complete={complete}
          className={css.progress}
        >
          <div className={css.bar}>
            <div className={css.filled} style={{ width: `${progress}%` }} />
          </div>
          <FontAwesomeIcon
            className={cx("icon", { complete })}
            icon={["fas", "circle-check"]}
            size="1x"
          />
        </section>
      )}
    </>
  );
}
