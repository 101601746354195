import React from "react";
import PropTypes from "prop-types";
import { Form, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import FormGroup from "../common/FormGroup";
import { NOT_SUPPORTED } from "../../utils/constants";

function Step2({
  form,
  handleback,
  onSubmit,
  handleCheckbox,
  nationalAgencies,
}) {
  const storeOnBoarding = useSelector((store) => store.onBoarding);
  const storeAgencies = storeOnBoarding.agencies;
  const loading = storeOnBoarding.create.loading;
  const branchAgencies = storeAgencies.data.branch_agencies;

  return (
    <div className="Step2 Step__item">
      <div className="Step__item__inner academy-form">
        <Form noValidate onSubmit={onSubmit}>
          <div className="form__inner">
            <p className="bold title">2. Confirm Agencies</p>
            <div className="state-agencies">
              <p className="info bold">State Agencies</p>
              <p className="text--small">
                Please confirm your branches and their respective assigned state
                agency.
              </p>
              <Table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Location</th>
                    <th>Agency</th>
                  </tr>
                </thead>
                <tbody>
                  {form.branches_attributes.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={index}>
                      <td>
                        {item.is_main_location ? (
                          <>
                            {item.name}
                            <span className="main-branch">(main branch)</span>
                          </>
                        ) : (
                          item.name
                        )}
                      </td>
                      <td>{`${item.city}, ${item.state}`}</td>
                      {branchAgencies &&
                      branchAgencies[item.name] === NOT_SUPPORTED ? (
                        <td className="notSupported">Not Supported</td>
                      ) : (
                        <td>{branchAgencies[item.name]}</td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div>
              <p className="info bold">National Agencies</p>
              <p className="text--small">
                Please add any national or accreditation agencies associated
                with your branches.
              </p>
              {storeAgencies.data &&
                storeAgencies.data.school_agencies &&
                storeAgencies.data.school_agencies.map((item) => (
                  <div className="checkbox" key={item}>
                    {item}
                    <FormGroup
                      propsInput={{
                        type: "checkbox",
                        name: item,
                        onChange: handleCheckbox,
                        checked: nationalAgencies[item],
                      }}
                      type="checkbox"
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className="button-group">
            <button
              className="button button--back"
              type="submit"
              onClick={handleback}
              loading={loading}
            >
              Back
            </button>
            <button
              className="button button--primary"
              type="submit"
              onSubmit={onSubmit}
              loading={loading}
            >
              Continue
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
}

Step2.propTypes = {
  form: PropTypes.shape({
    name: PropTypes.string,
    branches_attributes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip_code: PropTypes.string,
        is_main_location: PropTypes.bool,
      })
    ),
  }),
  nationalAgencies: PropTypes.arrayOf(PropTypes.string),
  handleback: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
};

Step2.defaultProps = {
  form: {
    name: "",
    branches_attributes: [
      {
        name: "",
        city: "",
        state: "",
        zip_code: "",
        is_main_location: false,
      },
    ],
  },
  nationalAgencies: [],
};

export default Step2;
