import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  changeURLWithoutReload,
  replaceParamStudent,
} from "../../../utils/helpers";
import { getUsers } from "../../../redux/actions/users";
import { getStudents } from "../../../redux/actions/students";
import {
  GraduateFollowUp,
  ROUTES,
  SORT_NAME,
  STUDENTS_SEARCH_NAME,
} from "../../../utils/constants";
import {
  ModalSendSurvey,
  StudentsTable,
} from "../../../components/dashboard/students";
import { openMessage } from "../../../redux/actions/message";
import {
  deleteStudent,
  getStudentPrograms,
} from "../../../redux/actions/student";
import {
  closeModalMessageDelete,
  openModalMessageDelete,
} from "../../../redux/actions/global";
import { sendSurvey } from "../../../redux/actions/surveys";

const FORM_SURVEY = {
  is_type: "",
  student_id: "",
  program_id: "",
  user_id: "",
};

function Root() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [params, setParams] = React.useState({});
  const [program, setProgram] = React.useState({});
  const [verifyingAdmin, setVerifyingAdmin] = React.useState({});
  const [type, setType] = React.useState({
    value: GraduateFollowUp,
    label: GraduateFollowUp,
  });
  const [formSurvey, setFormSurvey] = React.useState({ ...FORM_SURVEY });
  const [isParsing, setIsParsing] = React.useState(true);
  const [openModalSendSurvey, setOpenModalSendSurvey] = React.useState(false);
  const storeStudent = useSelector((store) => store.students.list);
  const storeStudentPrograms = useSelector((store) => store.student.programs);

  const storeSurvey = useSelector((store) => store.surveys.surveyStudent);
  const storeUsers = useSelector((store) => store.users);

  const isSubmitting = storeSurvey.loading;

  // only run for the first time visit page
  React.useEffect(() => {
    const firstParams = queryString.parse(location.search);
    setParams(firstParams);
    setIsParsing(false);
  }, [location.search]);

  React.useEffect(() => {
    if (isParsing) return;
    dispatch(getStudents(params));
  }, [params, isParsing]);

  const handleChangeURL = (newParams) => {
    changeURLWithoutReload(
      location.pathname,
      `${location.pathname}?${queryString.stringify(newParams)}`
    );
  };

  const updateParamsAndURL = (newParams) => {
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handlePageChange = (e) => {
    const newParams = { ...params, page: e.selected + 1 };
    updateParamsAndURL(newParams);
  };

  const handleSort = (column, direction) => {
    const newParams = {
      ...params,
      [SORT_NAME]: `${column} ${direction}`,
      page: 1,
    };
    updateParamsAndURL(newParams);
  };

  const handleSearch = (e) => {
    const newParams = {
      ...params,
      page: 1,
      [STUDENTS_SEARCH_NAME]: e.target.value,
    };
    updateParamsAndURL(newParams);
  };

  const handleChangeFilter = (selectedItem, name) => {
    const newParams = {
      ...params,
      page: 1,
      [name]: selectedItem.value,
    };
    updateParamsAndURL(newParams);
  };

  const handleEdit = (selectedItem) => () => {
    history.push(replaceParamStudent(ROUTES.editStudent, selectedItem.id));
  };

  const handleClickStudent = (selectedItem) => () => {
    history.push(replaceParamStudent(ROUTES.student, selectedItem.id));
  };

  const handleDeleteStudent = (student) => async () => {
    if (student.id) {
      try {
        await dispatch(deleteStudent(student.id));
        dispatch(
          openMessage({
            title: `${student.first_name} ${student.last_name} has been deleted`,
            type: "success",
          })
        );
        dispatch(closeModalMessageDelete());
        if (storeStudent.data.length === 1 && params.page > 1) {
          setParams({ ...params, page: params.page - 1 });
        }
        dispatch(getStudents(params));
      } catch (error) {
        dispatch(
          openMessage({
            title: "Something went wrong, please try again!",
            type: "error",
          })
        );
      }
    }
  };

  const handleToggleModalDeleteStudent = (rowData) => () => {
    dispatch(
      openModalMessageDelete({
        onDelete: handleDeleteStudent(rowData),
      })
    );
  };

  const handleChangeProgram = (selectedItem) => {
    setProgram(selectedItem);
    setFormSurvey({ ...formSurvey, program_id: selectedItem.value });
  };

  const handleChangeType = (selectedItem) => {
    setType(selectedItem);
    setFormSurvey({ ...formSurvey, is_type: selectedItem.value });
  };

  const handleChangeVerifyingAdmin = (selectedItem) => {
    setVerifyingAdmin(selectedItem);
    setFormSurvey({ ...formSurvey, user_id: selectedItem.value });
  };

  const handleHideModal = () => {
    setOpenModalSendSurvey(false);
    setFormSurvey({ ...FORM_SURVEY });
    setProgram({});
    setType({ type });
  };

  const handleSendSurvey = (rowData) => () => {
    setFormSurvey({
      ...formSurvey,
      student_id: rowData.id,
      is_type: type.value,
    });
    dispatch(getUsers());
    dispatch(getStudentPrograms(rowData.id));
    setOpenModalSendSurvey(true);
  };

  const handleCreateSurvey = async () => {
    try {
      await dispatch(sendSurvey(formSurvey));

      setOpenModalSendSurvey(false);
      dispatch(
        openMessage({
          title: "Survey has been sent",
          type: "success",
        })
      );
      setFormSurvey({ ...FORM_SURVEY });
      setProgram({});
    } catch (error) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  // Used to set the default verifying admin to the current user
  React.useEffect(() => {
    if (storeUsers.data.length === 0) return;

    const currentUser = storeUsers.data.find((user) => user.current_user);
    handleChangeVerifyingAdmin({
      value: currentUser.id,
      label: currentUser.full_name,
    });
  }, [storeUsers]);

  return (
    <>
      <StudentsTable
        storeStudent={storeStudent}
        onPageChange={handlePageChange}
        params={params}
        onSort={handleSort}
        onSearch={handleSearch}
        onEdit={handleEdit}
        onSendSurvey={handleSendSurvey}
        handleClickStudent={handleClickStudent}
        onChangeFilter={handleChangeFilter}
        onDelete={handleToggleModalDeleteStudent}
      />

      <ModalSendSurvey
        open={openModalSendSurvey}
        onHide={handleHideModal}
        onSend={handleCreateSurvey}
        onChangeType={handleChangeType}
        formSurvey={formSurvey}
        type={type}
        onChangeProgram={handleChangeProgram}
        program={program}
        onChangeVerifyingAdmin={handleChangeVerifyingAdmin}
        verifyingAdmin={verifyingAdmin}
        dataPrograms={storeStudentPrograms.data}
        dataAdmins={storeUsers.data}
        isSubmitting={isSubmitting}
      />
    </>
  );
}

export default Root;
