import React from "react";
import { Switch, Route, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import queryString from "query-string";
import { changeURLWithoutReload, getAuth } from "../../../utils/helpers";
import {
  getReporting,
  getReportingBPPEPreview,
  getReportingBPPEReport,
  getReportingBPPEDocument,
  getReportingBPSSPreview,
  getReportingBPSSReport,
  getReportingCIRRDocument,
  getReportingCIRRPreview,
  getReportingCIRRReport,
  getReportingTWCPreview,
  getReportingTWCReport,
  getReportingBPSSDocument,
  getReportingTWCDocument,
  getReportingDPOSPreview,
  getReportingCOEPreview,
  getReportingACCETPreview,
  getReportingCOEReport,
  getReportingCOEDocument,
  getReportingACCETReport,
  getReportingACCETDocument,
  getReportingBPPE_SPFS,
  getReportingTWCCompleter,
  getReportingDPOSReport,
  getReportingDPOSDocument,
  getExportIdentificationData,
} from "../../../redux/actions/reporting";
import { HeaderReporting } from "../../../components/dashboard/reporting";
import ChangeLog from "../students/student/ChangeLog";
import Root from "./Root";
import StudentList from "./StudentList";
import { openMessage } from "../../../redux/actions/message";
import { Loading } from "../../../components/common";

export default function () {
  const dispatch = useDispatch();
  const location = useLocation();
  const Params = useParams();
  const auth = getAuth();
  const [params, setParams] = React.useState({
    school_id: auth.userData.school_id,
  });
  const [isParsing, setIsParsing] = React.useState(true);
  const selected = useSelector((store) => store.reporting.selected);
  const storeReportLink = useSelector((store) => store.reporting.report);
  const storeDocument = useSelector((store) => store.reporting.documentation);
  const storeSPFS = useSelector((store) => store.reporting.spfs);
  const storeExportIdentificationData = useSelector(
    (store) => store.reporting.exportIdentificationData
  );
  const storeCompleter = useSelector((store) => store.reporting.completer);

  const reportName = Params.reportName;

  const isLoadingGetLink =
    storeReportLink.loading ||
    storeDocument.loading ||
    storeSPFS.loading ||
    storeExportIdentificationData.loading ||
    storeCompleter.loading;

  React.useEffect(() => {
    dispatch(getReporting());
  }, []);

  React.useEffect(() => {
    setParams(queryString.parse(location.search));
    setIsParsing(false);
  }, [location.search]);

  React.useEffect(() => {
    if (isParsing) return;
    if (params.program_id && params.reporting_year) {
      if (reportName === "cirr" && params.reporting_period) {
        dispatch(getReportingCIRRPreview(params));
      }
      if (reportName === "bppe") {
        dispatch(getReportingBPPEPreview(params));
      }
      if (reportName === "bpss") {
        dispatch(getReportingBPSSPreview(params));
      }
      if (reportName === "twc") {
        dispatch(getReportingTWCPreview(params));
      }
      if (reportName === "coe" && params.branch_id) {
        dispatch(getReportingCOEPreview(params));
      }
      if (reportName === "accet" && params.branch_id) {
        dispatch(getReportingACCETPreview(params));
      }
      if (reportName === "dpos" && params.branch_id) {
        dispatch(getReportingDPOSPreview(params));
      }
    }
  }, [
    params.program_id,
    params.branch_id,
    params.reporting_year,
    params.reporting_period,
    isParsing,
    reportName,
    params.month,
  ]);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const branch_id = selected.branches.value;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const program_id = selected.programs.value;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const reporting_period = selected.reporting_period.value;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const reporting_year = selected.reporting_year.value;
    const page = selected.page;
    const newParams = {
      ...params,
      branch_id,
      program_id,
      reporting_period,
      reporting_year,
      page,
    };
    changeURLWithoutReload(
      location.pathname,
      `${location.pathname}?${queryString.stringify(newParams)}`
    );
    setParams(newParams);
  }, [selected]);

  const handleReport = async () => {
    try {
      let data;
      if (params.program_id && params.reporting_year) {
        if (reportName === "bppe") {
          data = await dispatch(getReportingBPPEReport(params));
        } else if (reportName === "cirr" && params.reporting_period) {
          data = await dispatch(getReportingCIRRReport(params));
        } else if (reportName === "bpss") {
          data = await dispatch(getReportingBPSSReport(params));
        } else if (reportName === "twc") {
          data = await dispatch(getReportingTWCReport(params));
        }
        if (params.branch_id && reportName === "coe") {
          data = await dispatch(getReportingCOEReport(params));
        }
        if (params.branch_id && reportName === "accet") {
          data = await dispatch(getReportingACCETReport(params));
        }
        if (params.branch_id && reportName === "dpos") {
          data = await dispatch(getReportingDPOSReport(params));
        }
      }
      if (data.includes("http")) window.open(data, "_blank");
      else {
        dispatch(
          openMessage({
            title: data,
            type: "error",
          })
        );
      }
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleDocumentation = async () => {
    try {
      let data;
      if (params.program_id && params.reporting_year) {
        if (reportName === "bppe") {
          data = await dispatch(getReportingBPPEDocument(params));
        } else if (reportName === "cirr" && params.reporting_period) {
          data = await dispatch(getReportingCIRRDocument(params));
        } else if (reportName === "bpss") {
          data = await dispatch(getReportingBPSSDocument(params));
        } else if (reportName === "twc") {
          data = await dispatch(getReportingTWCDocument(params));
        } else if (reportName === "coe") {
          data = await dispatch(getReportingCOEDocument(params));
        }
        if (reportName === "accet" && params.branch_id) {
          data = await dispatch(getReportingACCETDocument(params));
        }
        if (reportName === "dpos" && params.branch_id) {
          data = await dispatch(getReportingDPOSDocument(params));
        }
      }

      if (data.includes("http")) window.open(data, "_blank");
      else {
        dispatch(
          openMessage({
            title: data,
            type: "success",
          })
        );
      }
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleSPFS = async () => {
    try {
      let data;
      if (params.program_id && params.reporting_year) {
        data = await dispatch(getReportingBPPE_SPFS(params));
      }

      if (data.includes("http")) window.open(data, "_blank");
      else {
        dispatch(
          openMessage({
            title: data,
            type: "error",
          })
        );
      }
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleGraduateIdentificationData = async () => {
    try {
      let data;
      if (
        params.program_id &&
        params.reporting_year &&
        params.school_id &&
        params.reporting_period
      ) {
        data = await dispatch(getExportIdentificationData(params));
      }

      if (data.includes("http")) window.open(data, "_blank");
      else {
        dispatch(
          openMessage({
            title: data,
            type: "success",
          })
        );
      }
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleCompleter = async () => {
    try {
      let data;
      if (params.program_id && params.reporting_year) {
        data = await dispatch(getReportingTWCCompleter(params));
      }

      if (data.includes("http")) window.open(data, "_blank");
      else {
        dispatch(
          openMessage({
            title: data,
            type: "error",
          })
        );
      }
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  return (
    <div
      className={classNames("reporting dashboard__item", {
        "is-loading": isLoadingGetLink,
      })}
    >
      {isLoadingGetLink && (
        <div className="is-loading__overlay">
          <Loading />
        </div>
      )}
      <HeaderReporting
        reportName={Params.reportName}
        onReport={handleReport}
        onDocumentation={handleDocumentation}
        onSPFS={handleSPFS}
        onGraduateIdentificationData={handleGraduateIdentificationData}
        onCompleter={handleCompleter}
      />
      <Switch>
        <Route path="/dashboard/reporting/:reportName" component={Root} exact />
        <Route
          path="/dashboard/reporting/:reportName/student-list"
          component={StudentList}
          exact
        />
        <Route
          path="/dashboard/reporting/:reportName/change-log"
          component={ChangeLog}
          exact
        />
      </Switch>
    </div>
  );
}
