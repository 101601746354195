import { requestClient } from "lib/api";
import qs from "query-string";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

type QueryParams = {
  token: string;
  placement_id?: string;
  event?: string;
};

export function useCompleteSurvey() {
  const location = useLocation();
  const {
    token,
    placement_id: placementId,
    event,
  } = qs.parse(location.search) as QueryParams;

  const query = useQuery(
    ["completeSurvey", token, placementId, event],
    async () => {
      if (!token || !placementId || event !== "signing_complete") return;
      return requestClient().post(
        `/surveys/graduate_follow_up/complete?token=${token}&placement_id=${placementId}`
      );
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const isLoading = query.isLoading;
  const isError = query.isError;

  return { isLoading, isError };
}
