import React from "react";
import PropTypes from "prop-types";
import { Collapse, Form } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import { useSelector } from "react-redux";
import FormGroup from "../common/FormGroup";
import down from "../../assets/icons/down2.svg";

function Step1({
  form,
  errorsBranch,
  errorName,
  open,
  multiBranch,
  handleAddBranch,
  handleDeleteBranch,
  handleClick,
  handleCheckbox,
  handleChangeBranchName,
  handleFocusBranch,
  handleCheckboxMainBranch,
  handleChangeSchoolName,
  handleFocusSchoolName,
  handleChangePlace,
  handleFocusPlace,
  handleChangeAddress,
  address,
  onSubmit,
  isValidate,
}) {
  const storeOnBoarding = useSelector((store) => store.onBoarding);
  const loading =
    storeOnBoarding.validate.loading || storeOnBoarding.agencies.loading;

  return (
    <div className="Step1 Step__item">
      <div className="Step__item__inner academy-form">
        <Form noValidate onSubmit={onSubmit}>
          <div className="form__inner">
            <p className="bold title">1. Add Institutions</p>
            <div className="Institution">
              <FormGroup
                propsInput={{
                  name: "name",
                  placeholder: "Institution name",
                  onChange: handleChangeSchoolName,
                  onFocus: handleFocusSchoolName,
                  value: form.name,
                  disabled: loading,
                }}
                error={errorName}
                label="Institution name"
              />
            </div>
            <div>
              <p className="info bold">
                Are you an administrator for multiple branches?
              </p>
              <div className="checkbox-group">
                <div className="checkbox">
                  Yes
                  <FormGroup
                    propsInput={{
                      type: "radio",
                      name: "multiBranch",
                      onChange: handleCheckbox,
                      checked: multiBranch === "YES",
                      value: "YES",
                      disabled: loading,
                    }}
                    type="radio"
                  />
                </div>
                <div className="checkbox">
                  No
                  <FormGroup
                    propsInput={{
                      type: "radio",
                      name: "multiBranch",
                      onChange: handleCheckbox,
                      checked: multiBranch === "NO",
                      value: "NO",
                      disabled: loading,
                    }}
                    type="radio"
                  />
                </div>
              </div>
            </div>
            <div>
              <p className="info bold">Add your institution branch:</p>
              {multiBranch === "YES" ? (
                <div>
                  {form.branches_attributes.map((item, index) => (
                    <div
                      className="branch"
                      // eslint-disable-next-line react/no-array-index-key
                      key={`branch-${index}`}
                    >
                      {isValidate[index] && (
                        <div
                          className="btn-collapse"
                          onClick={handleClick(index)}
                          role="button"
                          tabIndex="0"
                        >
                          <p className="branch-name">
                            {item.name}
                            <span className="bold main-branch">
                              {item.is_main_location ? "(Main Branch)" : ""}
                            </span>
                          </p>
                          {open[index] ? (
                            <img src={down} alt="" className="icon-up" />
                          ) : (
                            <img src={down} alt="" />
                          )}
                        </div>
                      )}
                      <Collapse in={open[index]}>
                        <div className="branch__inner">
                          <FormGroup
                            propsInput={{
                              name: "name",
                              placeholder: "Branch Name",
                              onChange: handleChangeBranchName(index),
                              value: item.name,
                              onFocus: handleFocusBranch(index),
                              disabled: loading,
                            }}
                            error={errorsBranch[`error-name${index}`]}
                            label="Branch Name"
                          />
                          <div className="Onboarding-Place">
                            <p className="Onboarding-Place__label">Street</p>
                            <Autocomplete
                              placeholder="Street"
                              onPlaceSelected={handleChangePlace(index)}
                              types={["address"]}
                              disabled={loading}
                              onFocus={handleFocusPlace(index)}
                              value={address[index]}
                              onChange={handleChangeAddress(index)}
                            />
                            {(errorsBranch[`error-state${index}`] ||
                              errorsBranch[`error-city${index}`]) && (
                              <div className="Onboarding-Place__error">
                                This field is invalid please type a new place
                              </div>
                            )}
                          </div>
                          <div className="City-state">
                            <FormGroup
                              propsInput={{
                                name: "city",
                                placeholder: "City",
                                onChange: handleChangeBranchName(index),
                                value: item.city,
                                onFocus: handleFocusBranch(index),
                                disabled: loading,
                              }}
                              error={errorsBranch[`error-city${index}`]}
                              label="City"
                            />
                            <FormGroup
                              propsInput={{
                                name: "state",
                                placeholder: " State",
                                onChange: handleChangeBranchName(index),
                                value: item.state,
                                onFocus: handleFocusBranch(index),
                                disabled: loading,
                              }}
                              error={errorsBranch[`error-state${index}`]}
                              label="State"
                            />
                            <FormGroup
                              propsInput={{
                                name: "zip_code",
                                placeholder: "Zip code",
                                onChange: handleChangeBranchName(index),
                                value: item.zip_code,
                                onFocus: handleFocusBranch(index),
                                disabled: loading,
                              }}
                              error={errorsBranch[`error-zip_code${index}`]}
                              label="Zip code"
                            />
                          </div>
                          <div className="branch__actions">
                            <div className="checkbox">
                              Main Branch
                              <FormGroup
                                propsInput={{
                                  type: "radio",
                                  onChange: handleCheckboxMainBranch(index),
                                  checked: item.is_main_location,
                                  disabled: loading,
                                }}
                                type="radio"
                              />
                            </div>
                            {form.branches_attributes.length > 1 &&
                              !form.branches_attributes[index]
                                .is_main_location && (
                                <p
                                  className="delete alert01 bold "
                                  onClick={handleDeleteBranch(index)}
                                >
                                  Delete Branch
                                </p>
                              )}
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  ))}
                  <p className="Add primary bold " onClick={handleAddBranch}>
                    + Add Branch
                  </p>
                </div>
              ) : (
                <div>
                  <FormGroup
                    propsInput={{
                      name: "name",
                      placeholder: "Branch Name",
                      onChange: handleChangeBranchName(0),
                      value: form.branches_attributes[0].name,
                      onFocus: handleFocusBranch(0),
                      disabled: loading,
                    }}
                    error={errorsBranch[`error-name${0}`]}
                    label="Branch Name"
                  />
                  <div className="Onboarding-Place">
                    <p className="Onboarding-Place__label">Street</p>
                    <Autocomplete
                      placeholder="Street"
                      onPlaceSelected={handleChangePlace(0)}
                      types={["address"]}
                      disabled={loading}
                      onFocus={handleFocusPlace(0)}
                      value={address[0]}
                      onChange={handleChangeAddress(0)}
                    />
                    {(errorsBranch[`error-state${0}`] ||
                      errorsBranch[`error-city${0}`]) && (
                      <div className="Onboarding-Place__error">
                        This field is invalid please type a new place
                      </div>
                    )}
                  </div>
                  <div className="City-state">
                    <FormGroup
                      propsInput={{
                        name: "city",
                        placeholder: "City",
                        onChange: handleChangeBranchName(0),
                        value: form.branches_attributes[0].city,
                        onFocus: handleFocusBranch(0),
                        disabled: loading,
                      }}
                      error={errorsBranch[`error-city${0}`]}
                      label="City"
                    />
                    <FormGroup
                      propsInput={{
                        name: "state",
                        placeholder: " State",
                        onChange: handleChangeBranchName(0),
                        value: form.branches_attributes[0].state,
                        onFocus: handleFocusBranch(0),
                        disabled: loading,
                      }}
                      error={errorsBranch[`error-state${0}`]}
                      label="State"
                    />
                    <FormGroup
                      propsInput={{
                        name: "zip_code",
                        placeholder: "Zip code",
                        onChange: handleChangeBranchName(0),
                        value: form.branches_attributes[0].zip_code,
                        onFocus: handleFocusBranch(0),
                        disabled: loading,
                      }}
                      error={errorsBranch[`error-zip_code${0}`]}
                      label="Zip code"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="button-group">
              <button className="button button--back" type="submit" disabled>
                Back
              </button>
              <button
                className="button button--primary"
                type="submit"
                onSubmit={onSubmit}
                disabled={loading}
              >
                Continue
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

Step1.propTypes = {
  open: PropTypes.arrayOf(PropTypes.bool),
  multiBranch: PropTypes.string,
  form: PropTypes.shape({
    name: PropTypes.string,
    branches_attributes: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip_code: PropTypes.string,
        is_main_location: PropTypes.bool,
      })
    ),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  errorsBranch: PropTypes.object,
  errorName: PropTypes.string,
  handleAddBranch: PropTypes.func.isRequired,
  handleDeleteBranch: PropTypes.func.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleChangeBranchName: PropTypes.func.isRequired,
  handleFocusBranch: PropTypes.func.isRequired,
  handleCheckboxMainBranch: PropTypes.func.isRequired,
  handleChangeSchoolName: PropTypes.func.isRequired,
  handleFocusSchoolName: PropTypes.func.isRequired,
  handleChangePlace: PropTypes.func.isRequired,
  handleFocusPlace: PropTypes.func.isRequired,
  handleChangeAddress: PropTypes.func.isRequired,
  address: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func.isRequired,
  isValidate: PropTypes.arrayOf(PropTypes.bool).isRequired,
};

Step1.defaultProps = {
  errorsBranch: {},
  errorName: "",
  form: {
    name: "",
    branches_attributes: [
      {
        name: "",
        city: "",
        state: "",
        zip_code: "",
        is_main_location: false,
      },
    ],
  },
  multiBranch: "",
  open: [],
  address: [],
};

export default Step1;
