/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import FormGroup from "../common/FormGroup";
import Range from "./Range";
import { questionLegallyStudy } from "../../utils/constants";

function Step3({
  formSurvey,
  onCheckbox,
  onSubmit,
  handleBack,
  loading,
  schoolData,
}) {
  return (
    <div className="Step step3">
      <div className="Step__inner ">
        <div className="form_wrap">
          <Form noValidate onSubmit={onSubmit}>
            <div className="form__inner">
              <div className="Step__inner__header">
                <img src={schoolData.logo} alt="" />
                <p>CIRR Student Intent Survey</p>
              </div>
              <div className="Step__inner__body">
                <p className="title">
                  Are you legally authorized to work in the country where you
                  are studying?
                </p>
                <div className="checkbox-group">
                  <div className="checkbox">
                    Yes
                    <FormGroup
                      propsInput={{
                        type: "radio",
                        name: questionLegallyStudy,
                        onChange: onCheckbox,
                        checked:
                          formSurvey.survey_responses_attributes[
                            questionLegallyStudy
                          ] === "Yes",
                        value: "Yes",
                      }}
                      type="radio"
                    />
                  </div>
                  <div className="checkbox">
                    No
                    <FormGroup
                      propsInput={{
                        type: "radio",
                        name: questionLegallyStudy,
                        onChange: onCheckbox,
                        checked:
                          formSurvey.survey_responses_attributes[
                            questionLegallyStudy
                          ] === "No",
                        value: "No",
                      }}
                      type="radio"
                    />
                  </div>
                </div>
              </div>
              <div className="button-group">
                <button
                  className="button button--back"
                  type="submit"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  className="button button--primary"
                  type="submit"
                  onSubmit={onSubmit}
                  disabled={
                    loading ||
                    !formSurvey.survey_responses_attributes[
                      questionLegallyStudy
                    ]
                  }
                >
                  Continue
                </button>
              </div>
            </div>
          </Form>
          <Range percent={32} />
        </div>
      </div>
    </div>
  );
}

Step3.propTypes = {
  formSurvey: PropTypes.shape({
    survey_responses_attributes: PropTypes.shape({
      [questionLegallyStudy]: PropTypes.string,
    }),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  schoolData: PropTypes.object.isRequired,
  onCheckbox: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Step3.defaultProps = {
  formSurvey: {
    survey_responses_attributes: {
      [questionLegallyStudy]: "",
    },
  },
  loading: false,
};

export default Step3;
