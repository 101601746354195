/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import pullData from "../../../../assets/images/pull-data.svg";
import pullPushData from "../../../../assets/images/pull-push-data.svg";

function Step1({ type, onSubmit, onSelectTypePull, onSelectTypePullAndPush }) {
  return (
    <div className="pull-step1 dashboard__item__inner list-types">
      <div className="pull-step1__inner ">
        <p className="text--large bold">Select Mapping Type</p>
        <div className="type-of-import">
          <div
            onClick={onSelectTypePull}
            role="button"
            tabIndex="0"
            className={classNames({
              active: type === "pull",
            })}
          >
            <img src={pullData} alt="" />
            <div>
              <p className="text bold title">Pull Data</p>
              <p className="text--small">
                Pull student data directly from Salesforce into AcademySync.
              </p>
              {/* <NavLink
                to={ROUTES.pullingDataSalesforce}
                className="text--small bold "
                activeClassName="isActive"
                exact
              >
                View Import Instructions
              </NavLink> */}
            </div>
          </div>
          <div
            onClick={onSelectTypePullAndPush}
            role="button"
            tabIndex="0"
            className={classNames({
              active: type === "pull and push",
            })}
          >
            <img src={pullPushData} alt="" />
            <div>
              <p className="text bold title">Pull & Push Data</p>
              <p className="text--small">
                Pull student data directly from Salesforce into AcademySync and
                push enrollment, completion, and placement data from AcademySync
                into Salesforce.
              </p>
              {/* <NavLink
                to={ROUTES.pullingAndPushingDataSalesforce}
                className="text--small bold "
                activeClassName="isActive"
                exact
              >
                View Import Instructions
              </NavLink> */}
            </div>
          </div>
        </div>
        <div className="button__group">
          <button
            className="button button--primary"
            type="submit"
            onClick={onSubmit}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

Step1.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSelectTypePull: PropTypes.func.isRequired,
  onSelectTypePullAndPush: PropTypes.func.isRequired,
};

export default Step1;
