import { z } from "zod";

export const DateTimeSchema = z.preprocess((arg) => {
  if (typeof arg === "string" || arg instanceof Date) return new Date(arg);
}, z.date());

export const SocCodeSchema = z.object({
  id: z.number(),
  code: z.string(),
  title: z.string(),
  description: z.string(),
  programId: z.number(),
  createdAt: DateTimeSchema,
  updatedAt: DateTimeSchema,
});

export const ProgramSchema = z.object({
  id: z.number(),
  name: z.string(),
  socCodes: z.array(SocCodeSchema),
});

export const RequiredStringSchema = z.string().min(1, { message: "Required" });

export const NumberSchema = z.number({
  required_error: "Required",
  invalid_type_error: "Must be a number",
});

export const YesNoSchema = z.enum(["YES", "NO"], {
  invalid_type_error: "Required",
});

export const DateInputSchema = z
  .string()
  .min(1, { message: "Required" })
  .regex(/^\d{4}-\d{2}-\d{2}$/, {
    message: "Must be in YYYY-MM-DD format",
  });

export const PhoneNumberSchema = z
  .string()
  .min(1, { message: "Required" })
  .regex(/^\(\d{3}\)\s\d{3}\s\d{4}$/, {
    message: "Must be in (555) 555 5555 format",
  });

export const EmailSchema = z.string().email({ message: "Required" });

export const UrlSchema = z.union([
  z.literal(""),
  z.string().url({ message: "URL is invalid" }),
]);
