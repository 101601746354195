import React from "react";

function Cohorts() {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3" y="11" width="20" height="2" rx="1" fill="white" />
      <rect
        x="12"
        y="19"
        width="11"
        height="2"
        rx="1"
        transform="rotate(-90 12 19)"
        fill="white"
      />
      <rect
        x="3"
        y="17"
        width="6"
        height="2"
        rx="1"
        transform="rotate(-90 3 17)"
        fill="white"
      />
      <rect
        x="21"
        y="17"
        width="6"
        height="2"
        rx="1"
        transform="rotate(-90 21 17)"
        fill="white"
      />
      <circle cx="13" cy="6" r="3" stroke="white" strokeWidth="2" />
      <circle cx="13" cy="20" r="2" stroke="white" strokeWidth="2" />
      <circle cx="4" cy="18" r="2" stroke="white" strokeWidth="2" />
      <circle cx="22" cy="18" r="2" stroke="white" strokeWidth="2" />
    </svg>
  );
}

export default Cohorts;
