import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { replaceParamStudent } from "utils/helpers";
import { Loading } from "../../../../components/common";
import { Financial } from "../../../../components/dashboard/students/student";
import { openMessage } from "../../../../redux/actions/message";
import {
  deleteFinancialAid,
  getFinancialAid,
} from "../../../../redux/actions/student";
import { ROUTES } from "../../../../utils/constants";

export default function () {
  const dispatch = useDispatch();
  const history = useHistory();
  const storeFinancial = useSelector(
    (store) => store.student.financialAid.list
  );
  const storeDelete = useSelector((store) => store.student.financialAid.delete);
  const loading = storeDelete.loading || storeFinancial.loading;
  const { studentId } = useParams();

  React.useEffect(() => {
    if (studentId) {
      dispatch(getFinancialAid({}, studentId));
    }
  }, [studentId]);

  const handleEdit = (rowData) => async () => {
    history.push(replaceParamStudent(ROUTES.addFinancial, studentId), rowData);
  };

  const handleDelete = (rowData) => async () => {
    try {
      await dispatch(deleteFinancialAid(studentId, rowData.id));
      dispatch(
        openMessage({
          title: "Financial aid has been deleted.",
          type: "success",
        })
      );
      dispatch(getFinancialAid({}, studentId));
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Financial
      storeFinancial={storeFinancial}
      onEdit={handleEdit}
      onDelete={handleDelete}
    />
  );
}
