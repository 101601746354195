import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import {
  DateInputSchema,
  EmailSchema,
  PhoneNumberSchema,
  RequiredStringSchema,
} from "lib/zod";
import { EDUCATION_LEVELS, GENDER, OPTIONS_ETHNICITY } from "utils/constants";
import {
  Layout,
  Card,
  StepHeader,
  ButtonArea,
  SelectInput,
  Error,
  Loading,
} from "./components";
import { Form, TextInput, Button, RadioGroup } from "../../features-components";
import {
  useMutationCompleteRegistration,
  useRegisterStudentsInfo,
} from "./api";
import css from "./styles.module.scss";

const schema = z.object({
  firstName: RequiredStringSchema,
  lastName: RequiredStringSchema,
  dob: DateInputSchema,
  email: EmailSchema,
  phone: PhoneNumberSchema,
  educationLevel: z.enum(
    // @ts-ignore
    EDUCATION_LEVELS.map((item) => item.value),
    {
      invalid_type_error: "Required",
    }
  ),
  gender: z.enum(
    // @ts-ignore
    GENDER.map((item) => item.value),
    {
      invalid_type_error: "Required",
    }
  ),
  ethnicity: z.enum(
    // @ts-ignore
    OPTIONS_ETHNICITY.map((item) => item.value),
    {
      invalid_type_error: "Required",
    }
  ),
  street: RequiredStringSchema,
  city: RequiredStringSchema,
  state: RequiredStringSchema,
  zipCode: RequiredStringSchema,
});

type Inputs = z.infer<typeof schema>;

export function StudentsRegister() {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: zodResolver(schema),
  });

  const { data, isError, isLoading } = useRegisterStudentsInfo();
  const {
    mutate,
    isError: isErrorCompleteRegistration,
    isLoading: isLoadingCompleteRegistration,
    isSuccess,
  } = useMutationCompleteRegistration();

  const convertToCorrectPayload = (input: any): any => ({
    student: {
      first_name: input.firstName,
      last_name: input.lastName,
      dob: input.dob,
      email: input.email,
      phone: input.phone,
      education_level: input.educationLevel,
      gender: input.gender,
      ethnicity: input.ethnicity,
      address: input.street,
      city: input.city,
      state: input.state,
      zip_code: input.zip_code,
    },
  });

  const handleRegisterClick = async () => {
    await handleSubmit((formData) => {
      mutate(convertToCorrectPayload(formData));
    })();
  };

  const handleAddressAutocomplete = (result: AutocompleteAddress) => {
    if (!result) return;
    setValue("street", result.address);
    setValue("city", result.city);
    setValue("state", result.state);
    setValue("zipCode", result.zip);
  };

  if (isLoading || isLoadingCompleteRegistration) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }

  if (isError || isErrorCompleteRegistration) {
    return (
      <Layout>
        <Error />
      </Layout>
    );
  }

  if (isSuccess) {
    return (
      <Layout>
        <Card>
          <StepHeader
            title="Invitation Sent!"
            name={data?.school?.name}
            logo={data?.school?.logo}
            center
          />
          <p>
            Please accept the invitation sent to your email to complete
            registration.
          </p>
          <br />
        </Card>
      </Layout>
    );
  }

  return (
    <Layout>
      <Card>
        <StepHeader
          subtitle={`Please fill out the form below to complete your enrollment in our ${
            data?.program?.name
          } program. Your class will begin on ${
            data?.startDate &&
            format(new Date(data?.startDate), "MMMM do, yyyy")
          } until ${
            data?.graduationDate &&
            format(new Date(data?.graduationDate), "MMMM do, yyyy")
          }`}
          name={data?.school?.name}
          logo={data?.school?.logo}
        />
        <Form>
          <div className={css.formGroup}>
            <h2>Student Information</h2>
            <div className={css.inputGroup3}>
              <div>
                <TextInput
                  label="First Name"
                  placeholder="First Name"
                  error={errors?.firstName?.message}
                  {...register("firstName")}
                />
              </div>
              <div>
                <TextInput
                  label="Last Name"
                  placeholder="Last Name"
                  error={errors?.lastName?.message}
                  {...register("lastName")}
                />
              </div>
              <div>
                <TextInput
                  type="date"
                  label="DOB"
                  placeholder="YYYY-MM-DD"
                  error={errors?.dob?.message}
                  {...register("dob")}
                />
              </div>
            </div>
            <div className={css.inputGroup2}>
              <div>
                <SelectInput<Inputs, Inputs["educationLevel"]>
                  name="educationLevel"
                  control={control}
                  label="Education Level"
                  options={EDUCATION_LEVELS}
                />
              </div>
              <div>
                <SelectInput<Inputs, Inputs["ethnicity"]>
                  name="ethnicity"
                  control={control}
                  label="Ethnicity"
                  options={OPTIONS_ETHNICITY}
                />
              </div>
            </div>
            <RadioGroup<Inputs["gender"]>
              vertical
              align="left"
              label="Gender"
              options={GENDER}
              error={errors?.gender?.message}
              {...register("gender")}
            />
          </div>
          <div className={css.formGroup}>
            <h2>Contact Information</h2>
            <div className={css.inputGroup2}>
              <div>
                <TextInput
                  label="Email Address"
                  placeholder="Email Address"
                  error={errors?.email?.message}
                  {...register("email")}
                />
              </div>
              <div>
                <TextInput
                  type="tel"
                  label="Phone Number"
                  placeholder="Phone Number"
                  error={errors?.phone?.message}
                  {...register("phone")}
                />
              </div>
            </div>
          </div>
          <div className={css.formGroup}>
            <h2>Address Information</h2>
            <div className={css.inputGroup2}>
              <div>
                <TextInput
                  autoComplete
                  onPlaceSelected={handleAddressAutocomplete}
                  label="Street"
                  placeholder="Street"
                  error={errors.street?.message}
                  {...register("street")}
                />
              </div>
              <div>
                <TextInput
                  label="City"
                  placeholder="City"
                  error={errors.city?.message}
                  {...register("city")}
                />
              </div>
              <div>
                <TextInput
                  label="State"
                  placeholder="State"
                  error={errors.state?.message}
                  {...register("state")}
                />
              </div>
              <div>
                <TextInput
                  label="Zip Code"
                  placeholder="Zip Code"
                  error={errors.zipCode?.message}
                  {...register("zipCode")}
                />
              </div>
            </div>
          </div>
        </Form>

        <ButtonArea single>
          <Button primary label="Register" onClick={handleRegisterClick} />
        </ButtonArea>
      </Card>
    </Layout>
  );
}
