import React from "react";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { getUserData, isStudent } from "../../utils/helpers";
import { User, ArrowRight, Menu, Help } from "../common/icons";
import { ROUTES } from "../../utils/constants";

function HeaderDashboard({ handleClickToggle }) {
  const history = useHistory();
  const userData = getUserData();
  const isStudentRole = isStudent();

  const handleClickLogout = () => {
    history.push(ROUTES.logout);
  };

  return (
    <div className="Header-dashboard ">
      <div className="Header-dashboard__inner">
        <div
          className="Header-dashboard__inner__left"
          onClick={handleClickToggle}
          role="button"
          tabIndex="0"
        >
          <Menu />
        </div>
        <div className="Header-dashboard__inner__right">
          {/* <div className="bt-icon">
            <Mail />
          </div>
          <div className="bt-icon">
            <Alert />
          </div> */}
          <Dropdown className="Dropdown Dropdown-small">
            <Dropdown.Toggle as={ButtonGroup}>
              {`${userData.first_name} ${userData.last_name}`}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {!isStudentRole && (
                <Dropdown.Item href={ROUTES.profile}>
                  <User />
                  Profile
                </Dropdown.Item>
              )}
              <Dropdown.Item
                href="https://academysyncllc.zendesk.com/hc/en-us/requests/new"
                target="_blank"
              >
                <Help />
                Help
              </Dropdown.Item>
              <Dropdown.Item onClick={handleClickLogout}>
                <ArrowRight />
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}

HeaderDashboard.propTypes = {
  handleClickToggle: PropTypes.func.isRequired,
};

export default HeaderDashboard;
