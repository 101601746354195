import React from "react";
import { NavLink } from "react-router-dom";
import information from "../../../../assets/images/information-import.png";
import enrollment from "../../../../assets/images/enrollments-import.png";
import placement from "../../../../assets/images/placements-import.png";
import { ROUTES } from "../../../../utils/constants";
import { ArrowLeft2 } from "../../../common/icons";

function ImportInstructions() {
  return (
    <div className="Import-instruction">
      <div className="Import-instruction__header">
        <NavLink
          to={ROUTES.importStudent}
          className="text--small bold "
          activeClassName="isActive"
          exact
        >
          <ArrowLeft2 />
          Return
        </NavLink>
        <h4>Student Import Instructions</h4>
        <p className="text--xlarge bold">About Student Import</p>
        <p className="text--small">
          The student importer allows administrators to import student general,
          enrollment, and placement information in bulk. There are three main
          types of import: General Information Import, Enrollments Import, and
          Placements Import.
        </p>
        <p className="text--small">
          Each import type has different fields and requirements which are
          outlined in the instructions section for that import. Please find
          specific instructions for each import below.
        </p>
        <p className="text--small">
          ALERT: Make sure to set up relevant branches, programs and cohorts
          before you run the import.
        </p>
      </div>
      <div className="select-type dashboard__item__inner">
        <div className="select-type__inner ">
          <p className="text--large bold">Types of Imports</p>
          <div className="type-of-import">
            <div>
              <img src={information} alt="" />
              <div>
                <p className="text bold title">General Information Import</p>
                <NavLink
                  to="/dashboard/students/import-instruction/general-information"
                  className="text--small bold "
                  activeClassName="isActive"
                  exact
                >
                  View Import Instructions
                </NavLink>
              </div>
            </div>
            <div>
              <img src={enrollment} alt="" />
              <div>
                <p className="text bold title">Enrollments Import</p>
                <NavLink
                  to="/dashboard/students/import-instruction/enrollments"
                  className="text--small bold "
                  activeClassName="isActive"
                  exact
                >
                  View Import Instructions
                </NavLink>
              </div>
            </div>
            <div>
              <img src={placement} alt="" />
              <div>
                <p className="text bold title">Placements Import</p>
                <NavLink
                  to="/dashboard/students/import-instruction/placements"
                  className="text--small bold "
                  activeClassName="isActive"
                  exact
                >
                  View Import Instructions
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportInstructions;
