import React from "react";
import { useHistory } from "react-router-dom";
import { updateIsBadToken } from "../redux/actions/global";
import { ROUTES } from "../utils/constants";
import { removeAuth } from "../utils/helpers";

export default () => {
  const history = useHistory();

  React.useEffect(() => {
    removeAuth();
    // wait 1.5s to make sure all apis were called success
    setTimeout(() => {
      updateIsBadToken(false);
    }, 1500);
    history.push(ROUTES.home);
  }, []);

  return null;
};
