// https://stackoverflow.com/a/55989037/479732
export function normalizePhoneInput(value: string) {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");

  if (currentValue.length < 4) return currentValue;

  if (currentValue.length < 7)
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;

  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6
  )} ${currentValue.slice(6, 10)}`;
}
