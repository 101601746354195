/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/default-param-last */
import * as types from "../constants";

const initialState = {
  institution: {
    data: {},
    loading: false,
    error: null,
  },
  users: {
    data: [],
    meta: {
      total: 0,
      perPage: 10,
    },
    loading: false,
    error: null,
  },
  roles: {
    data: [],
    meta: {
      total: 0,
      perPage: 10,
    },
    loading: false,
    error: null,
  },
  allRoles: {
    data: [],
    loading: false,
    error: null,
  },
  rolesBuild: {
    data: {},
    loading: false,
    error: null,
  },
  roleDetail: {
    data: {},
    loading: false,
    error: null,
  },
  deleteRoles: {
    data: {},
    loading: false,
    error: null,
  },
  updateInstitution: {
    data: {},
    loading: false,
    error: null,
  },
  createAdministrator: {
    data: {},
    loading: false,
    error: null,
  },
  updateAdministrator: {
    data: {},
    loading: false,
    error: null,
  },
  deleteAdministrator: {
    data: {},
    loading: false,
    error: null,
  },
  updateRole: {
    data: {},
    loading: false,
    error: null,
  },
  createRole: {
    data: {},
    loading: false,
    error: null,
  },
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_INSTITUTION:
      return {
        ...initialState,
        institution: {
          ...initialState.institution,
          loading: true,
        },
      };
    case types.GET_INSTITUTION_SUCCEED:
      return {
        ...state,
        institution: {
          ...state.institution,
          data: payload.data,
          loading: false,
        },
      };
    case types.GET_INSTITUTION_FAIL:
      return {
        ...state,
        institution: {
          ...state.institution,
          error: payload,
          loading: false,
        },
      };
    case types.GET_ADMINISTRATOR:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true,
        },
      };
    case types.GET_ADMINISTRATOR_SUCCEED:
      return {
        ...state,
        users: {
          ...state.users,
          data: payload.data,
          meta: payload.meta,
          loading: false,
        },
      };
    case types.GET_ADMINISTRATOR_FAIL:
      return {
        ...state,
        users: {
          ...state.users,
          error: payload,
          loading: false,
        },
      };
    case types.GET_ROLES:
      return {
        ...state,
        roles: {
          ...state.roles,
          loading: true,
        },
      };
    case types.GET_ROLES_SUCCEED:
      return {
        ...state,
        roles: {
          ...state.roles,
          data: payload.data,
          meta: payload.meta,
          loading: false,
        },
      };
    case types.GET_ROLES_FAIL:
      return {
        ...state,
        roles: {
          ...state.roles,
          error: payload,
          loading: false,
        },
      };

    // get all roles
    case types.GET_ALL_ROLES:
      return {
        ...state,
        allRoles: {
          ...state.allRoles,
          loading: true,
        },
      };
    case types.GET_ALL_ROLES_SUCCEED:
      return {
        ...state,
        allRoles: {
          ...state.allRoles,
          data: payload.data,
          loading: false,
        },
      };
    case types.GET_ALL_ROLES_FAIL:
      return {
        ...state,
        allRoles: {
          ...state.allRoles,
          error: payload,
          loading: false,
        },
      };

    // delete roles
    case types.DELETE_ROLE:
      return {
        ...state,
        deleteRoles: {
          ...initialState.deleteRoles,
          loading: true,
        },
      };
    case types.DELETE_ROLE_SUCCEED:
      return {
        ...state,
        deleteRoles: {
          ...state.deleteRoles,
          data: payload,
          loading: false,
        },
      };
    case types.DELETE_ROLE_FAIL:
      return {
        ...state,
        deleteRoles: {
          ...state.deleteRoles,
          error: payload,
          loading: false,
        },
      };

    // get roles build
    case types.GET_ROLES_BUILD:
      return {
        ...state,
        rolesBuild: {
          ...state.rolesBuild,
          loading: true,
        },
      };
    case types.GET_ROLES_BUILD_SUCCEED:
      return {
        ...state,
        rolesBuild: {
          ...state.rolesBuild,
          data: payload.data,
          loading: false,
        },
      };
    case types.GET_ROLES_BUILD_FAIL:
      return {
        ...state,
        rolesBuild: {
          ...state.rolesBuild,
          error: payload,
          loading: false,
        },
      };

    // get role detail
    case types.GET_ROLE_DETAIL:
      return {
        ...state,
        roleDetail: {
          ...state.roleDetail,
          loading: true,
        },
      };
    case types.GET_ROLE_DETAIL_SUCCEED:
      return {
        ...state,
        roleDetail: {
          ...state.roleDetail,
          data: payload.data,
          loading: false,
        },
      };
    case types.GET_ROLE_DETAIL_FAIL:
      return {
        ...state,
        roleDetail: {
          ...state.roleDetail,
          error: payload,
          loading: false,
        },
      };

    // update institution
    case types.UPDATE_INSTITUTION:
      return {
        ...state,
        updateInstitution: {
          ...initialState.updateInstitution,
          loading: true,
        },
      };
    case types.UPDATE_INSTITUTION_SUCCEED:
      return {
        ...state,
        institution: {
          ...state.updateInstitution,
          data: payload,
          loading: false,
        },
        updateInstitution: {
          ...state.updateInstitution,
          data: payload,
          loading: false,
        },
      };
    case types.UPDATE_INSTITUTION_FAIL:
      return {
        ...state,
        updateInstitution: {
          ...state.updateInstitution,
          error: payload,
          loading: false,
        },
      };

    // update administrator
    case types.UPDATE_ADMINISTRATOR:
      return {
        ...state,
        updateAdministrator: {
          ...initialState.updateAdministrator,
          loading: true,
        },
      };
    case types.UPDATE_ADMINISTRATOR_SUCCEED:
      return {
        ...state,
        updateAdministrator: {
          ...state.updateAdministrator,
          data: payload,
          loading: false,
        },
      };
    case types.UPDATE_ADMINISTRATOR_FAIL:
      return {
        ...state,
        updateAdministrator: {
          ...state.updateAdministrator,
          error: payload,
          loading: false,
        },
      };

    // delete Administrator
    case types.DELETE_ADMINISTRATOR:
      return {
        ...state,
        deleteAdministrator: {
          ...initialState.deleteAdministrator,
          loading: true,
        },
      };
    case types.DELETE_ADMINISTRATOR_SUCCEED:
      return {
        ...state,
        deleteAdministrator: {
          ...state.deleteAdministrator,
          data: payload,
          loading: false,
        },
      };
    case types.DELETE_ADMINISTRATOR_FAIL:
      return {
        ...state,
        deleteAdministrator: {
          ...state.deleteAdministrator,
          error: payload,
          loading: false,
        },
      };

    // update role
    case types.UPDATE_ROLE:
      return {
        ...state,
        updateRole: {
          ...initialState.updateRole,
          loading: true,
        },
      };
    case types.UPDATE_ROLE_SUCCEED:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          data: payload,
          loading: false,
        },
      };
    case types.UPDATE_ROLE_FAIL:
      return {
        ...state,
        updateRole: {
          ...state.updateRole,
          error: payload,
          loading: false,
        },
      };

    // create administrator
    case types.CREATE_ADMINISTRATOR:
      return {
        ...state,
        createAdministrator: {
          ...state.createAdministrator,
          loading: true,
          error: {},
        },
      };
    case types.CREATE_ADMINISTRATOR_SUCCEED:
      return {
        ...state,
        createAdministrator: {
          ...state.createAdministrator,
          data: payload,
          loading: false,
        },
      };
    case types.CREATE_ADMINISTRATOR_FAIL:
      return {
        ...state,
        createAdministrator: {
          ...state.createAdministrator,
          error: payload,
          loading: false,
        },
      };

    // create role
    case types.CREATE_ROLE:
      return {
        ...state,
        createRole: { ...state.createRole, loading: true, error: {} },
      };
    case types.CREATE_ROLE_SUCCEED:
      return {
        ...state,
        createRole: { ...state.createRole, data: payload, loading: false },
      };
    case types.CREATE_ROLE_FAIL:
      return {
        ...state,
        createRole: { ...state.createRole, error: payload, loading: false },
      };

    default:
      return state;
  }
}
