import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../utils/constants";
import { FormGroup, SingleSelectCheckbox } from "../../common";

function FormAddFinancialAidPrograms({
  form,
  type,
  state,
  showState,
  formErrors,
  onSubmit,
  onChange,
  onFocus,
  handleChangeType,
  handleChangeState,
  isSubmitting,
  isEditing,
}) {
  const history = useHistory();

  const handleBack = () => {
    history.push(ROUTES.financialAidPrograms);
  };

  const OPTIONS_TYPE = [
    { value: "federal", label: "Federal" },
    { value: "state", label: "State" },
    { value: "other", label: "Other" },
  ];

  const OPTIONS_STATE = [
    { value: "AL", label: "AL" },
    { value: "AK", label: "AK" },
    { value: "AZ", label: "AZ" },
    { value: "AR", label: "AR" },
    { value: "CA", label: "CA" },
    { value: "CO", label: "CO" },
    { value: "CT", label: "CT" },
    { value: "DE", label: "DE" },
    { value: "FL", label: "FL" },
    { value: "GA", label: "GA" },
    { value: "HI", label: "HI" },
    { value: "ID", label: "ID" },
    { value: "IL", label: "IL" },
    { value: "IN", label: "IN" },
    { value: "IA", label: "IA" },
    { value: "KS", label: "KS" },
    { value: "KY", label: "KY" },
    { value: "LA", label: "LA" },
    { value: "ME", label: "ME" },
    { value: "MD", label: "MD" },
    { value: "MA", label: "MA" },
    { value: "MI", label: "MI" },
    { value: "MN", label: "MN" },
    { value: "MS", label: "MS" },
    { value: "MO", label: "MO" },
    { value: "MT", label: "MT" },
    { value: "NE", label: "NE" },
    { value: "NV", label: "NV" },
    { value: "NH", label: "NH" },
    { value: "NJ", label: "NJ" },
    { value: "NM", label: "NM" },
    { value: "NY", label: "NY" },
    { value: "NC", label: "NC" },
    { value: "ND", label: "ND" },
    { value: "OH", label: "OH" },
    { value: "OK", label: "OK" },
    { value: "OR", label: "OR" },
    { value: "PA", label: "PA" },
    { value: "RI", label: "RI" },
    { value: "SC", label: "SC" },
    { value: "SD", label: "SD" },
    { value: "TN", label: "TN" },
    { value: "TX", label: "TX" },
    { value: "UT", label: "UT" },
    { value: "VT", label: "VT" },
    { value: "VA", label: "VA" },
    { value: "WA", label: "WA" },
    { value: "WV", label: "WV" },
    { value: "WI", label: "WI" },
    { value: "WY", label: "WY" },
  ];

  return (
    <div className="Add-FinancialAidPrograms  dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="Add-FinancialAidPrograms__text">
          <p className="text--xlarge bold">
            {isEditing
              ? "Update Financial Aid Program"
              : "Add Financial Aid Program"}
          </p>
        </div>
        <div className="Add-FinancialAidPrograms__inner">
          <FormGroup
            propsInput={{
              name: "name",
              placeholder: "Program Name",
              onChange,
              onFocus,
              value: form.name,
              disabled: isSubmitting,
            }}
            error={formErrors.name}
            label="Program Name"
          />
          <div className="select">
            <p>Type</p>
            <SingleSelectCheckbox
              onChange={handleChangeType}
              name="is_type"
              options={OPTIONS_TYPE}
              value={type}
              error={formErrors.is_type}
              disabled={isSubmitting}
            />
          </div>
          {showState && (
            <div className="select">
              <p>State</p>
              <SingleSelectCheckbox
                onChange={handleChangeState}
                name="state"
                options={OPTIONS_STATE}
                value={state}
                error={formErrors.state}
                disabled={isSubmitting}
              />
            </div>
          )}
        </div>
        <div className="button-group">
          <button
            className="button button--back"
            type="submit"
            onClick={handleBack}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
}

FormAddFinancialAidPrograms.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    name: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  type: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object,
  showState: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  handleChangeType: PropTypes.func.isRequired,
  handleChangeState: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool,
};

FormAddFinancialAidPrograms.defaultProps = {
  form: {
    name: "",
  },
  type: {},
  state: {},
  formErrors: {},
  isEditing: false,
};

export default FormAddFinancialAidPrograms;
