import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Title from "../../../components/dashboard/Title";
import { ROUTES } from "../../../utils/constants";
import AddStudent from "./AddStudent";
import Student from "./student/Student";
import Root from "./Root";
import ImportStudent from "./importStudent/ImportStudent";
import ImportInstructions from "./importStudent/ImportInstructions";
import ImportInstructionsDetail from "./importStudent/ImportInstructionsDetail";

export default function () {
  const location = useLocation();

  return (
    <div className="Students dashboard__item">
      {!location.pathname.includes(ROUTES.student) &&
        !location.pathname.includes("import") && <Title title="Students" />}
      <Switch>
        <Route path={ROUTES.students} component={Root} exact />
        <Route path={ROUTES.addStudent} component={AddStudent} exact />
        <Route path={ROUTES.importStudent} component={ImportStudent} exact />
        <Route
          path={ROUTES.importInstructions}
          component={ImportInstructions}
          exact
        />
        <Route
          path={ROUTES.importInstructionDetail}
          component={ImportInstructionsDetail}
          exact
        />
        <Route path={ROUTES.student} component={Student} />
      </Switch>
    </div>
  );
}
