import React from "react";

function Students() {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3" y="2" width="20" height="2" rx="1" fill="white" />
      <rect
        x="19"
        y="8"
        width="5"
        height="2"
        rx="1"
        transform="rotate(-90 19 8)"
        fill="white"
      />
      <path
        d="M20.9381 21H5.06189C5.55399 17.0537 8.92038 14 13 14C17.0796 14 20.446 17.0537 20.9381 21Z"
        stroke="white"
        strokeWidth="2"
      />
      <circle cx="13" cy="7" r="3" stroke="white" strokeWidth="2" />
    </svg>
  );
}

export default Students;
