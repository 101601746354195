import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { replaceParamStudent } from "utils/helpers";
import {
  deleteLicensureExam,
  getLicensureExams,
} from "../../../../redux/actions/student";
import { Licensure } from "../../../../components/dashboard/students/student";
import { ROUTES } from "../../../../utils/constants";
import { openMessage } from "../../../../redux/actions/message";
import { Loading } from "../../../../components/common";

export default function () {
  const dispatch = useDispatch();
  const history = useHistory();
  const storeLicensure = useSelector(
    (store) => store.student.licensureExam.list
  );
  const storeDelete = useSelector(
    (store) => store.student.licensureExam.delete
  );
  const { studentId } = useParams();

  const loading = storeDelete.loading || storeLicensure.loading;

  React.useEffect(() => {
    if (studentId) {
      dispatch(getLicensureExams({}, studentId));
    }
  }, [studentId]);

  const handleEdit = (rowData) => async () => {
    history.push(replaceParamStudent(ROUTES.addLicensure, studentId), {
      licensureData: rowData,
    });
  };

  const handleDelete = (rowData) => async () => {
    try {
      await dispatch(deleteLicensureExam(studentId, rowData.id));
      dispatch(
        openMessage({
          title: "Licensure exam has been deleted.",
          type: "success",
        })
      );
      dispatch(getLicensureExams({}, studentId));
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <Licensure
      storeLicensure={storeLicensure}
      onEdit={handleEdit}
      onDelete={handleDelete}
    />
  );
}
