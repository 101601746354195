/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { Table, Typeahead } from "../../../common";
import { Tick, Unlink, Warning } from "../../../common/icons";

function Step2({
  onSubmit,
  handleBack,
  isSubmitting,
  form,
  onChange,
  error,
  dataObjects,
  dataAttributes,
  isLoadingTable,
  step,
}) {
  const optionAttributes =
    dataAttributes.student && dataAttributes.student.length
      ? dataAttributes.student.map((item) => ({
          label: item.label,
          value: item.name,
        }))
      : [];

  const DATA = [
    {
      id: 1,
      name: "Student",
      salesforce: {
        placeholder: "Select a Field",
        name: "integration_model",
        options: dataObjects,
      },
    },

    {
      id: 2,
      name: "First Name",
      salesforce: {
        placeholder: "Select a Field",
        name: "first_name",
        options: optionAttributes,
      },
    },
    {
      id: 3,
      name: "Last Name",
      salesforce: {
        placeholder: "Select a Field",
        name: "last_name",
        options: optionAttributes,
      },
    },
    {
      id: 4,
      name: "Email",
      salesforce: {
        placeholder: "Select a Field",
        name: "email",
        options: optionAttributes,
      },
    },
    {
      id: 5,
      name: "Phone",
      salesforce: {
        placeholder: "Select a Field",
        name: "phone",
        options: optionAttributes,
      },
    },
    {
      id: 6,
      name: "Street",
      salesforce: {
        placeholder: "Select a Field",
        name: "address",
        options: optionAttributes,
      },
    },
    {
      id: 7,
      name: "City",
      salesforce: {
        placeholder: "Select a Field",
        name: "city",
        options: optionAttributes,
      },
    },
    {
      id: 8,
      name: "State",
      salesforce: {
        placeholder: "Select a Field",
        name: "state",
        options: optionAttributes,
      },
    },
    {
      id: 9,
      name: "Zip",
      salesforce: {
        placeholder: "Select a Field",
        name: "zip_code",
        options: optionAttributes,
      },
    },
    {
      id: 10,
      name: "Education Level",
      salesforce: {
        placeholder: "Select a Field",
        name: "education_level",
        options: optionAttributes,
      },
    },
  ];

  const columns = [
    {
      title: "AcademySync",
      dataIndex: "name",
      render: (row) => <div>{row.name}</div>,
    },
    {
      title: "Salesforce",
      dataIndex: "id",
      render: (row) => (
        <Typeahead
          propsTypeahead={{
            id: `map-pull-data-${row.id}`,
            labelKey: "label",
            options: row.salesforce.options,
            placeholder: row.salesforce.placeholder,
            onChange: onChange(row.salesforce.name),
            selected: [
              {
                value:
                  row.salesforce.name === "integration_model"
                    ? form[row.salesforce.name]
                    : form.mapping[row.salesforce.name],
              },
            ],
            disabled:
              step === 3 ||
              (row.salesforce.name !== "integration_model" &&
                !form.integration_model),
          }}
          error={error[row.salesforce.name]}
        />
      ),
    },
    {
      title: "Mapped Status",
      dataIndex: "status",
      render: (row) => (
        <div>
          {(row.salesforce.name === "integration_model" &&
            form[row.salesforce.name]) ||
          (row.salesforce.name !== "integration_model" &&
            form.mapping[row.salesforce.name]) ? (
            // eslint-disable-next-line react/jsx-indent
            <div className="status mapped">
              <Tick />
              Mapped
            </div>
          ) : error[row.salesforce.name] ? (
            <div className="status invalid">
              <Warning />
              Invalid
            </div>
          ) : (
            <div className="status unmapped">
              <Unlink />
              Unmapped
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="pull-step2  form-student dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="form-student__text">
          <p className="text--large bold">
            {step === 2 ? "Map Data to Pull" : "Mapping Summary"}
          </p>
          {step === 2 && (
            <p className="text--small">
              Sync your student data in Salesforce with AcademySync by mapping
              your Salesforce object and fields to an AcademySync Student
              object.
            </p>
          )}
        </div>
        <div className="pull-step2__inner form-student__inner">
          <Table loading={isLoadingTable} columns={columns} data={DATA} />
          <div className="button-group">
            <button
              className="button button--back"
              type="submit"
              onClick={handleBack}
              disabled={isSubmitting}
            >
              Back
            </button>
            <button
              className="button button--primary"
              type="submit"
              disabled={isSubmitting}
            >
              {step === 3 ? "Sync" : "Map"}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

Step2.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

Step2.defaultProps = {
  isSubmitting: false,
};

export default Step2;
