import axios from "axios";
import { updateIsBadToken } from "../redux/actions/global";
import { getHeaders } from "./helpers";

const defaultHeader = { "Content-Type": "application/json" };

export const request = (opts = {}, optsHeader = defaultHeader) => {
  /*
  |--------------------------------------------------
  | Custom axios api
  |--------------------------------------------------
  */

  const defaultOptions = {
    ...opts,
    headers: {
      ...defaultHeader,
      ...getHeaders(),
      ...optsHeader,
    },
  };

  const axiosApi = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    ...defaultOptions,
  });

  // error will be showed in catch block instead of appeared in then
  axiosApi.interceptors.response.use(
    (response) => response,
    (error) => {
      // Handle logout when the token is wrong
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors[0] ===
          "You need to sign in or sign up before continuing."
      ) {
        updateIsBadToken(true);
      }

      const errorResponse =
        error && error.response
          ? error.response
          : { data: { status: "SOMETHING_WENT_WRONG" } };
      return Promise.reject(errorResponse);
    }
  );

  return axiosApi;
};

export default request;
