import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { isEmail } from "validator";
import {
  componentForm,
  ContinuingEducation,
  EmployedInField,
  EmployedInRelatedField,
  EMPLOYMENT_OUTCOMES,
  Military,
  ROUTES,
  UNAVAILABILITY_OUTCOMES,
} from "../../../../utils/constants";
import { AddPlacement } from "../../../../components/dashboard/students/student";
import { getProgramIndex } from "../../../../redux/actions/programs";
import {
  createPlacement,
  deleteDocument,
  getStudentPrograms,
  updatePlacement,
} from "../../../../redux/actions/student";
import {
  formatDateOfPicker,
  formatPhoneNumber,
  handleErrorsAfterCallingAPI,
  replaceParamStudent,
} from "../../../../utils/helpers";
import { ModalDeleteDocument } from "../../../../components/common";
import { openMessage } from "../../../../redux/actions/message";

const DEFAULT_FORM_PLACEMENT = {
  program_id: undefined,
  outcome: "",
  employment_placement_attributes: {
    soc_code: "",
    job_type: "Single",
    same_employer: "NO",
    same_employer_condition: "NO",
    same_employer_promotion: "NO",
    hired_by_school: "NO",
    longer_than_5_weeks: "NO",
    placed_by_school: "NO",
    chose_part_time: "NO",
    job_title: "",
    offer_date: new Date(),
    start_date: new Date(),
    end_date: new Date(),
    hours_per_week: "",
    duration: "3-6 months",
    salary_type: "Annual",
    salary: "",
    employer_address: "",
    employer_name: "",
    employer_city: "",
    employer_state: "",
    employer_zip_code: "",
    employer_phone: "",
    employer_contact_name: "",
    employer_contact_phone: "",
    employer_contact_email: "",
  },
  military_placement_attributes: {
    branch_name: "",
    recruiting_office_address: "",
    recruiting_office_city: "",
    recruiting_office_state: "",
    recruiting_office_zip_code: "",
    recruiting_office_phone: "",
  },
  education_placement_attributes: {
    program_name: "",
    institution_name: "",
    institution_city: "",
    institution_state: "",
    institution_zip_code: "",
  },
  unavailability_placement_attributes: {
    unavailability_date: new Date(),
  },
  files: [],
};

export default function () {
  const history = useHistory();
  const location = useLocation();
  const [form, setForm] = useState({
    ...DEFAULT_FORM_PLACEMENT,
  });
  const [formErrors, setFormErrors] = useState({});
  const [address, setAddress] = useState("");
  const [openModalDeleteDocument, setOpenModalDeleteDocument] = useState(false);
  const [document, setDocument] = useState({});
  const [documentIndex, setDocumentIndex] = useState(null);
  const dispatch = useDispatch();
  const placementData = location.state || {};
  const isEditing = !!Object.keys(placementData).length;

  const storeCreate = useSelector((store) => store.student.placement.create);
  const storeUpdate = useSelector((store) => store.student.placement.update);
  const storePrograms = useSelector((store) => store.student.programs);
  const storeProgramIndex = useSelector((store) => store.programs.programIndex);
  const { studentId } = useParams();
  const isSubmitting = storeCreate.loading || storeUpdate.loading;

  React.useEffect(() => {
    dispatch(getStudentPrograms(studentId));
  }, [studentId]);

  React.useEffect(() => {
    if (form.program_id) {
      dispatch(getProgramIndex({}, form.program_id));
    }
  }, [form.program_id]);

  React.useEffect(() => {
    if (isEditing) {
      setForm({
        program_id: placementData.program.id,
        outcome: placementData.outcome,
        employment_placement_attributes: placementData.employment_placement
          ? {
              ...placementData.employment_placement,
              same_employer: placementData.employment_placement.same_employer
                ? "YES"
                : "NO",
              hired_by_school: placementData.employment_placement
                .hired_by_school
                ? "YES"
                : "NO",
              same_employer_condition: placementData.employment_placement
                .same_employer_condition
                ? "YES"
                : "NO",
              same_employer_promotion: placementData.employment_placement
                .same_employer_promotion
                ? "YES"
                : "NO",
              longer_than_5_weeks: placementData.employment_placement
                .longer_than_5_weeks
                ? "YES"
                : "NO",
              chose_part_time: placementData.employment_placement
                .longer_than_5_weeks
                ? "YES"
                : "NO",
              hours_per_week:
                placementData.employment_placement.hours_per_week.toString(),
              salary: placementData.employment_placement.salary.toString(),
            }
          : {},
        military_placement_attributes: placementData.military_placement,
        education_placement_attributes: placementData.education_placement,
        unavailability_placement_attributes: {
          unavailability_date: placementData.military_placement
            ? placementData.military_placement.created_at
            : new Date(),
        },
        files: placementData.files,
      });
      if (placementData.employment_placement) {
        setAddress(placementData.employment_placement.employer_address);
      } else if (placementData.military_placement) {
        setAddress(placementData.military_placement.recruiting_office_address);
      }
    }
  }, [placementData, isEditing]);

  const convertToFormData = (data, formDataName) => {
    const formData = new FormData();
    Object.keys(data).forEach((formName) => {
      if (
        (formName === "employment_placement_attributes" &&
          EMPLOYMENT_OUTCOMES.includes(form.outcome)) ||
        (formName === "military_placement_attributes" &&
          form.outcome === Military) ||
        (formName === "education_placement_attributes" &&
          form.outcome === ContinuingEducation) ||
        (formName === "unavailability_placement_attributes" &&
          UNAVAILABILITY_OUTCOMES.includes(form.outcome))
      ) {
        Object.keys(data[formName]).forEach((formNameChild) => {
          formData.append(
            `${formDataName}[${formName}][${formNameChild}]`,
            data[formName][formNameChild]
          );
        });
      } else if (formName === "files") {
        data[formName].forEach((file) => {
          formData.append(`${formDataName}[${formName}][]`, file);
        });
      } else {
        formData.append(`${formDataName}[${formName}]`, data[formName]);
      }
    });
    return formData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.program_id) {
      errors.program_id = "Program is required";
    }
    if (!form.outcome) {
      errors.outcome = "Outcome is required";
    }
    if (EMPLOYMENT_OUTCOMES.includes(form.outcome)) {
      // if (!form.employment_placement_attributes.job_title) {
      //   errors.job_title = "Job Title is required";
      // }
      // if (!form.employment_placement_attributes.salary) {
      //   errors.salary = "Salary is required";
      // }
      // if (!form.employment_placement_attributes.hours_per_week) {
      //   errors.hours_per_week = "Hours per week is required";
      // }
      // if (!form.employment_placement_attributes.employer_name) {
      //   errors.employer_name = " Name is required";
      // }
      // if (!form.employment_placement_attributes.employer_city) {
      //   errors.employer_city = "City is required";
      // }
      // if (!form.employment_placement_attributes.employer_state) {
      //   errors.employer_state = "State is required";
      // }
      // if (!form.employment_placement_attributes.employer_zip_code) {
      //   errors.employer_zip_code = "Zip Code is required";
      // }
      // if (!form.employment_placement_attributes.employer_phone) {
      //   errors.employer_phone = "Phone is required";
      // }
      // if (!form.employment_placement_attributes.employer_contact_phone) {
      //   errors.employer_contact_phone = "Supervisor’s Phone is required";
      // }
      // if (!form.employment_placement_attributes.employer_contact_name) {
      //   errors.employer_contact_name = "Supervisor’s Full Name is required";
      // }
      // if (
      //   !form.employment_placement_attributes.employer_contact_email ||
      //   !isEmail(form.employment_placement_attributes.employer_contact_email)
      // ) {
      //   errors.employer_contact_email = "Supervisor’s Email is required";
      // }

      // validate email if users type it
      if (
        form.employment_placement_attributes.employer_contact_email &&
        !isEmail(form.employment_placement_attributes.employer_contact_email)
      ) {
        errors.employer_contact_email = "Supervisor’s Email is invalid";
      }
    }

    if (
      (form.outcome === EmployedInField ||
        form.outcome === EmployedInRelatedField) &&
      storeProgramIndex.data &&
      storeProgramIndex.data.soc_codes &&
      storeProgramIndex.data.soc_codes.length
    )
      if (!form.employment_placement_attributes.soc_code) {
        errors.soc_code = "Please selected at least one of options";
      }

    // if (form.outcome === Military) {
    //   if (!form.military_placement_attributes.branch_name) {
    //     errors.branch_name = "Branch is required";
    //   }
    //   if (!form.military_placement_attributes.recruiting_office_city) {
    //     errors.recruiting_office_city = "City is required";
    //   }
    //   if (!form.military_placement_attributes.recruiting_office_state) {
    //     errors.recruiting_office_state = "State is required";
    //   }
    //   if (!form.military_placement_attributes.recruiting_office_zip_code) {
    //     errors.recruiting_office_zip_code = "Zip Code is required";
    //   }
    //   if (!form.military_placement_attributes.recruiting_office_phone) {
    //     errors.recruiting_office_phone =
    //       "Recruiting office’s Phone is required";
    //   }
    // }

    // if (form.outcome === ContinuingEducation) {
    //   if (!form.education_placement_attributes.institution_name) {
    //     errors.institution_name = "Institution Name is required";
    //   }
    //   if (!form.education_placement_attributes.program_name) {
    //     errors.program_name = "Program Name is required";
    //   }
    //   if (!form.education_placement_attributes.institution_city) {
    //     errors.institution_city = "City is required";
    //   }
    //   if (!form.education_placement_attributes.institution_state) {
    //     errors.institution_state = "State is required";
    //   }
    //   if (!form.education_placement_attributes.institution_zip_code) {
    //     errors.institution_zip_code = "Zip Code is required";
    //   }
    // }

    setFormErrors(errors);

    // call api
    if (!Object.keys(errors).length) {
      const formData = {
        ...form,
        employment_placement_attributes: {
          ...form.employment_placement_attributes,
          offer_date: formatDateOfPicker(
            form.employment_placement_attributes.offer_date
          ),
          start_date: formatDateOfPicker(
            form.employment_placement_attributes.start_date
          ),
          end_date: formatDateOfPicker(
            form.employment_placement_attributes.end_date
          ),
        },
        unavailability_placement_attributes: {
          ...form.unavailability_placement_attributes,
          unavailability_date: formatDateOfPicker(
            form.unavailability_placement_attributes.unavailability_date
          ),
        },
      };
      formData.employment_placement_attributes.same_employer =
        formData.employment_placement_attributes.same_employer !== "NO";
      formData.employment_placement_attributes.hired_by_school =
        formData.employment_placement_attributes.hired_by_school !== "NO";
      formData.employment_placement_attributes.same_employer_promotion =
        formData.employment_placement_attributes.same_employer_promotion !==
        "NO";
      formData.employment_placement_attributes.same_employer_condition =
        formData.employment_placement_attributes.same_employer_condition !==
        "NO";
      formData.employment_placement_attributes.longer_than_5_weeks =
        formData.employment_placement_attributes.longer_than_5_weeks !== "NO";
      formData.employment_placement_attributes.chose_part_time =
        formData.employment_placement_attributes.chose_part_time !== "NO";
      formData.employment_placement_attributes.placed_by_school =
        formData.employment_placement_attributes.placed_by_school !== "NO";

      if (isEditing) {
        // edit
        try {
          setFormErrors({});
          formData.files = form.files.filter((item) => !item.id);
          if (UNAVAILABILITY_OUTCOMES.includes(form.outcome)) {
            formData.unavailability_placement_attributes.id =
              placementData.unavailability_placement.id;
            delete formData.employment_placement_attributes;
            delete formData.education_placement_attributes;
          }
          await dispatch(
            updatePlacement(
              convertToFormData(formData, "placement"),
              studentId,
              placementData.id
            )
          );
          dispatch(
            openMessage({
              title: "Placement has been updated.",
              type: "success",
            })
          );
          history.push(replaceParamStudent(ROUTES.placement, studentId));
        } catch (error) {
          handleErrorsAfterCallingAPI(
            error,
            DEFAULT_FORM_PLACEMENT,
            setFormErrors,
            dispatch
          );
        }
      } else {
        // create

        try {
          await dispatch(
            createPlacement(convertToFormData(form, "placement"), studentId)
          );
          dispatch(
            openMessage({
              title: "Placement has been created.",
              type: "success",
            })
          );
          history.push(replaceParamStudent(ROUTES.placement, studentId));
        } catch (error) {
          handleErrorsAfterCallingAPI(
            error,
            DEFAULT_FORM_PLACEMENT,
            setFormErrors,
            dispatch
          );
        }
      }
    }
  };

  const handleChangePlace = (place) => {
    if (!place.address_components || !place.address_components.length) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const employment_placement_attributes = {
      ...form.employment_placement_attributes,
    };
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const military_placement_attributes = {
      ...form.military_placement_attributes,
    };
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const education_placement_attributes = {
      ...form.education_placement_attributes,
    };
    let street = "";
    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    place.address_components.forEach((component) => {
      const addressType = component.types[0];
      switch (addressType) {
        case "postal_code":
          employment_placement_attributes.employer_zip_code =
            component[componentForm[addressType]];
          military_placement_attributes.recruiting_office_zip_code =
            component[componentForm[addressType]];
          education_placement_attributes.institution_zip_code =
            component[componentForm[addressType]];
          break;
        case "administrative_area_level_1":
          employment_placement_attributes.employer_state =
            component[componentForm[addressType]];
          military_placement_attributes.recruiting_office_state =
            component[componentForm[addressType]];
          education_placement_attributes.institution_state =
            component[componentForm[addressType]];
          break;
        case "locality":
          employment_placement_attributes.employer_city =
            component[componentForm[addressType]];
          military_placement_attributes.recruiting_office_city =
            component[componentForm[addressType]];
          education_placement_attributes.institution_city =
            component[componentForm[addressType]];
          break;
        case "street_number":
          street = `${component[componentForm[addressType]]} ${street}`;
          break;
        case "route":
          street = `${street} ${component[componentForm[addressType]]}`;
          employment_placement_attributes.employer_address =
            component[componentForm[addressType]];
          military_placement_attributes.recruiting_office_address =
            component[componentForm[addressType]];
          break;
        default:
          break;
      }
    });
    setAddress(street);
    setForm({
      ...form,
      employment_placement_attributes,
      military_placement_attributes,
      education_placement_attributes,
    });
  };

  const handleFocusPlace = () => {
    setFormErrors({
      ...formErrors,
      employer_city: "",
      employer_state: "",
      employer_zip_code: "",
      recruiting_office_city: "",
      recruiting_office_state: "",
      recruiting_office_zip_code: "",
    });
  };

  const handleChange = (event) => {
    if (
      event.target.name === "institution_name" ||
      event.target.name === "program_name"
    ) {
      setForm({
        ...form,
        education_placement_attributes: {
          ...form.education_placement_attributes,
          [event.target.name]: event.target.value,
        },
      });
    } else
      setForm({
        ...form,
        employment_placement_attributes: {
          ...form.employment_placement_attributes,
          [event.target.name]: event.target.value,
        },
      });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  const handleChangeDate = (date, name) => {
    if (name === "unavailability_date") {
      setForm({
        ...form,
        unavailability_placement_attributes: {
          ...form.unavailability_placement_attributes,
          [name]: date,
        },
      });
    } else {
      setForm({
        ...form,
        employment_placement_attributes: {
          ...form.employment_placement_attributes,
          [name]: date,
        },
      });
    }
  };

  const handleBack = () => {
    history.push(replaceParamStudent(ROUTES.placement, studentId));
  };

  const handleCheckboxResult = (e) => {
    setForm({
      ...form,
      employment_placement_attributes: {
        ...form.employment_placement_attributes,
        [e.target.name]: e.target.value,
      },
    });
  };

  const handleChangeCode = (e) => {
    setForm({
      ...form,
      employment_placement_attributes: {
        ...form.employment_placement_attributes,
        soc_code: e.target.value,
      },
    });
  };

  const handleChangeSelect = (selectedItem, name) => {
    if (name === "branch_name") {
      setForm({
        ...form,
        military_placement_attributes: {
          ...form.military_placement_attributes,
          branch_name: selectedItem.value,
        },
      });
    } else {
      setForm({
        ...form,
        [name]: selectedItem.value,
      });
    }
  };

  const handleChangeAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleChangeNumber = (e) => {
    const phoneNumber = e.target.value;
    if (phoneNumber.length > 14) {
      return;
    }
    if (phoneNumber.length === 4) {
      return setForm({
        ...form,
        employment_placement_attributes: {
          ...form.employment_placement_attributes,
          [e.target.name]: phoneNumber,
        },
      });
    }
    if (e.target.name === "recruiting_office_phone") {
      setForm({
        ...form,
        military_placement_attributes: {
          ...form.military_placement_attributes,
          [e.target.name]: formatPhoneNumber(phoneNumber),
        },
      });
    } else {
      setForm({
        ...form,
        employment_placement_attributes: {
          ...form.employment_placement_attributes,
          [e.target.name]: formatPhoneNumber(phoneNumber),
        },
      });
    }
  };

  const handleDropzone = (dropped) => {
    const newFiles = [...form.files, ...dropped];
    setForm({
      ...form,
      files: newFiles,
    });
  };

  const handleToggleModalDeleteDocument = (file, selectedIndex) => () => {
    setOpenModalDeleteDocument(!openModalDeleteDocument);
    setDocument(file);
    setDocumentIndex(selectedIndex);
  };

  const handleDeleteDocument = async () => {
    if (document.id) {
      try {
        setFormErrors({});
        await dispatch(deleteDocument(document.id));
        dispatch(
          openMessage({
            title: "This document has been deleted",
            type: "success",
          })
        );
        setForm({
          ...form,
          files: form.files.filter((item, index) => index !== documentIndex),
        });
        setOpenModalDeleteDocument(!openModalDeleteDocument);
      } catch (error) {
        dispatch(
          openMessage({
            title: "Something went wrong, please try again!",
            type: "error",
          })
        );
      }
    } else {
      setForm({
        ...form,
        files: form.files.filter((item, index) => index !== documentIndex),
      });
      setOpenModalDeleteDocument(!openModalDeleteDocument);
    }
  };

  const handleCancelDelete = () => {
    setOpenModalDeleteDocument(false);
  };

  return (
    <>
      <AddPlacement
        form={form}
        formErrors={formErrors}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onFocus={handleFocus}
        onChangeSelect={handleChangeSelect}
        handleChangeDate={handleChangeDate}
        handleChangeCode={handleChangeCode}
        dataPrograms={storePrograms.data}
        dataProgramIndex={storeProgramIndex.data}
        isSubmitting={isSubmitting}
        handleBack={handleBack}
        handleDrop={handleDropzone}
        handleCheckbox={handleCheckboxResult}
        handleChangePlace={handleChangePlace}
        handleFocusPlace={handleFocusPlace}
        handleChangeAddress={handleChangeAddress}
        address={address}
        onDeleteDocument={handleToggleModalDeleteDocument}
        onChangeNumber={handleChangeNumber}
      />
      <ModalDeleteDocument
        isOpen={openModalDeleteDocument}
        onBack={handleCancelDelete}
        nameDocument={document.name || document.filename}
        onDelete={handleDeleteDocument}
      />
    </>
  );
}
