import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import {
  Filter,
  Chart,
  Placement,
  Survey,
} from "../../components/dashboard/dashboard";
import { getDashboard } from "../../redux/actions/dashboard";
import {
  getFilterBranches,
  getFilterCohorts,
  getFilterPrograms,
} from "../../redux/actions/filters";
import { getInstitution } from "../../redux/actions/institution";
import { getAuth } from "../../utils/helpers";

let prevSelected = "";

export default function () {
  const dispatch = useDispatch();
  const auth = getAuth();
  const storeDashboard = useSelector((store) => store.dashboard);
  const storeInstitution = useSelector(
    (store) => store.institution.institution
  );
  const storeFilters = useSelector((store) => store.filters);
  const { branches, programs, cohorts, selected } = storeFilters;

  React.useEffect(() => {
    if (!branches.data.length && !branches.loading) {
      dispatch(getFilterBranches());
    }
    if (!programs.data.length && !programs.loading) {
      dispatch(getFilterPrograms());
    }
  }, []);

  React.useEffect(() => {
    dispatch(getInstitution({}, auth.userData.school_id));
  }, []);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const branch_ids = selected.branches.map((item) => item.value);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const program_ids = selected.programs.map((item) => item.value);
    dispatch(getFilterCohorts({ branch_ids, program_ids }));
  }, [selected.branches, selected.programs]);

  React.useEffect(() => {
    const newSelected = JSON.stringify(selected);
    if (cohorts.loading || newSelected === prevSelected) {
      return;
    }
    prevSelected = newSelected;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const branch_ids = selected.branches.map((item) => item.value);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const program_ids = selected.programs.map((item) => item.value);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const cohort_ids = selected.cohorts.map((item) => item.value);
    dispatch(getDashboard({ branch_ids, program_ids, cohort_ids }));
  }, [selected, cohorts]);

  return (
    <div className="dashboard">
      <Filter storeInstitution={storeInstitution} />
      {storeDashboard.loading ? (
        <div className="loading">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <>
          <Chart />
          <Placement />
          <Survey />
        </>
      )}
    </div>
  );
}
