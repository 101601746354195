/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/default-param-last */
import * as types from "../constants";

const initialState = {
  profile: {
    data: {},
    loading: false,
    error: null,
  },
  update: {
    data: {},
    loading: false,
    error: null,
  },
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          loading: true,
        },
      };
    case types.GET_PROFILE_SUCCEED:
      return {
        ...state,
        profile: {
          ...state.profile,
          data: payload.data,
          loading: false,
        },
      };
    case types.GET_PROFILE_FAIL:
      return {
        ...state,
        profile: {
          ...state.profile,
          error: payload,
          loading: false,
        },
      };

    case types.UPDATE_PROFILE:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
        },
      };
    case types.UPDATE_PROFILE_SUCCEED:
      return {
        ...state,
        update: {
          ...state.update,
          data: payload.data,
          loading: false,
        },
      };
    case types.UPDATE_PROFILE_FAIL:
      return {
        ...state,
        update: {
          ...state.update,
          error: payload,
          loading: false,
        },
      };
    default:
      return state;
  }
}
