/* eslint-disable no-nested-ternary */
import React from "react";
import classNames from "classnames";
import { NavLink, useParams } from "react-router-dom";
import {
  ROUTES,
  TABLE_GENERAL_INFORMATION,
  TABLE_ENROLMENT,
  TABLE_PLACEMENT,
} from "../../../../utils/constants";
import { ArrowLeft2, Download } from "../../../common/icons";
import { Loading, Table } from "../../../common";

const columns = [
  {
    title: "Field Name",
    dataIndex: "name",
    render: (row) => row.name,
  },
  {
    title: "Description and requirements",
    dataIndex: "description",
    render: (row) => (
      <div>
        {row.description}{" "}
        <span className="alert01">{row.required ? " ( Required )" : ""}</span>
      </div>
    ),
  },
];

function ImportInstructionsDetail() {
  const [step, setStep] = React.useState(1);
  const Params = useParams();
  const typeName = Params.typeName;

  const formatType = {
    "general-information": "General Information",
    enrollments: "Enrollments",
    placements: "Placements",
  };

  return (
    <div className="General-information">
      <div className="Import-instruction__header">
        <NavLink
          to={ROUTES.importInstructions}
          className="text--small bold "
          activeClassName="isActive"
          exact
        >
          <ArrowLeft2 />
          Back
        </NavLink>
        <h4>{`About ${formatType[typeName]} Import`}</h4>
        <p className="text--small">
          {`The ${
            formatType[typeName]
          } Import allows administrators to create new ${
            formatType[typeName] === "General Information"
              ? "student"
              : formatType[typeName]
          } records ${
            formatType[typeName] !== "General Information"
              ? "for existing students"
              : ""
          }  in bulk by uploading a populated csv with fields for each new ${
            typeName === "general-information"
              ? "student"
              : typeName === "enrollments"
              ? "enrollment"
              : "placement"
          }.`}
        </p>
        <p className="text--small">
          {`Please note that the ${
            formatType[typeName]
          } Import is only designed to create new student
          records into the system, and will not update any existing ${
            typeName === "general-information"
              ? "student"
              : typeName === "enrollments"
              ? "enrollment"
              : "placement"
          } records.`}
        </p>
        <p className="text--small">{`Download the ${formatType[typeName]} import template using the link below:`}</p>
        <a
          className="text--small bold primary download"
          href={
            formatType[typeName] === "General Information"
              ? "https://academysync-static-assets.s3.amazonaws.com/students-general-import-template.csv"
              : formatType[typeName] === "Enrollments"
              ? "https://academysync-static-assets.s3.amazonaws.com/students-enrollments-import-template.csv"
              : "https://academysync-static-assets.s3.amazonaws.com/students-placements-import-template-1.csv"
          }
          target="_blank"
          rel="noreferrer"
        >
          <Download />{" "}
          {`Download the ${formatType[typeName]} Import CSV Template`}
        </a>
        <p className="text--small">
          ALERT: Make sure to set up relevant branches, programs and cohorts
          before you run the import.
        </p>
      </div>
      <div className="Step-by-step dashboard__item__inner">
        <div className="Step-by-step__inner ">
          <p className="text--large bold title">Step By Step Instructions</p>
          <div>
            <div className="Step-by-step__left">
              <div
                className={classNames("step__item", {
                  "--active": step >= 1,
                })}
                onClick={() => {
                  setStep(1);
                }}
                role="button"
                tabIndex="0"
              >
                <span>1</span>
                <p className="text">Go to the Students page</p>
              </div>
              <div className="border-dashed" />
              <div
                className={classNames("step__item", {
                  "--active": step >= 2,
                })}
                onClick={() => {
                  setStep(2);
                }}
                role="button"
                tabIndex="0"
              >
                <span>2</span>
                <p className="text">Click on Import Students</p>
              </div>
              <div className="border-dashed" />
              <div
                className={classNames("step__item", {
                  "--active": step >= 3,
                })}
                onClick={() => {
                  setStep(3);
                }}
                role="button"
                tabIndex="0"
              >
                <span>3</span>
                <p className="text">{`Choose ${formatType[typeName]} import and click Continue`}</p>
              </div>
              <div className="border-dashed" />
              <div
                className={classNames("step__item", {
                  "--active": step >= 4,
                })}
                onClick={() => {
                  setStep(4);
                }}
                role="button"
                tabIndex="0"
              >
                <span>4</span>
                <p className="text">{`Download the ${formatType[typeName]} Import CSV Template`}</p>
              </div>
              <div className="border-dashed" />
              <div
                className={classNames("step__item", {
                  "--active": step >= 5,
                })}
                onClick={() => {
                  setStep(5);
                }}
                role="button"
                tabIndex="0"
              >
                <span>5</span>
                <p className="text">Edit the Template with Your Student Data</p>
              </div>
              <div className="border-dashed" />
              <div
                className={classNames("step__item", {
                  "--active": step >= 6,
                })}
                onClick={() => {
                  setStep(6);
                }}
                role="button"
                tabIndex="0"
              >
                <span>6</span>
                <p className="text">Upload your edited CSV file</p>
              </div>
              <div className="border-dashed" />
              <div
                className={classNames("step__item", {
                  "--active": step >= 7,
                })}
                onClick={() => {
                  setStep(7);
                }}
                role="button"
                tabIndex="0"
              >
                <span>7</span>
                <p className="text">Confirm the Import</p>
              </div>
              <div className="border-dashed" />
              <div
                className={classNames("step__item", {
                  "--active": step >= 8,
                })}
                onClick={() => {
                  setStep(8);
                }}
                role="button"
                tabIndex="0"
              >
                <span>8</span>
                <p className="text">Review the Import Summary</p>
              </div>
            </div>
            <div className="Step-by-step__right">
              <Loading />
              {step === 1 && (
                <img
                  src="https://academysync-static-assets.s3.amazonaws.com/student-importer/step-1.png"
                  alt=""
                />
              )}
              {step === 2 && (
                <img
                  src="https://academysync-static-assets.s3.amazonaws.com/student-importer/step-2.png"
                  alt=""
                />
              )}
              {step === 3 && (
                <img
                  src={
                    formatType[typeName] === "General Information"
                      ? "https://academysync-static-assets.s3.amazonaws.com/student-importer/general-1.png"
                      : formatType[typeName] === "Enrollments"
                      ? "https://academysync-static-assets.s3.amazonaws.com/student-importer/enrollments-1.png"
                      : "https://academysync-static-assets.s3.amazonaws.com/student-importer/placements-1.png"
                  }
                  alt=""
                />
              )}
              {step === 4 && (
                <img
                  src={
                    formatType[typeName] === "General Information"
                      ? "https://academysync-static-assets.s3.amazonaws.com/student-importer/general-2.png"
                      : formatType[typeName] === "Enrollments"
                      ? "https://academysync-static-assets.s3.amazonaws.com/student-importer/enrollments-2.png"
                      : "https://academysync-static-assets.s3.amazonaws.com/student-importer/placements-2.png"
                  }
                  alt=""
                />
              )}
              {step === 5 && (
                <img
                  src={
                    formatType[typeName] === "General Information"
                      ? "https://academysync-static-assets.s3.amazonaws.com/student-importer/general-3.png"
                      : formatType[typeName] === "Enrollments"
                      ? "https://academysync-static-assets.s3.amazonaws.com/student-importer/enrollments-3.png"
                      : "https://academysync-static-assets.s3.amazonaws.com/student-importer/placements-3.png"
                  }
                  alt=""
                />
              )}
              {step === 6 && (
                <img
                  src={
                    formatType[typeName] === "General Information"
                      ? "https://academysync-static-assets.s3.amazonaws.com/student-importer/general-4.png"
                      : formatType[typeName] === "Enrollments"
                      ? "https://academysync-static-assets.s3.amazonaws.com/student-importer/enrollments-4.png"
                      : "https://academysync-static-assets.s3.amazonaws.com/student-importer/placements-4.png"
                  }
                  alt=""
                />
              )}
              {step === 7 && (
                <img
                  src={
                    formatType[typeName] === "General Information"
                      ? "https://academysync-static-assets.s3.amazonaws.com/student-importer/general-5.png"
                      : formatType[typeName] === "Enrollments"
                      ? "https://academysync-static-assets.s3.amazonaws.com/student-importer/enrollments-5.png"
                      : "https://academysync-static-assets.s3.amazonaws.com/student-importer/placements-5.png.png"
                  }
                  alt=""
                />
              )}
              {step === 8 && (
                <img
                  src="https://academysync-static-assets.s3.amazonaws.com/student-importer/summary.png"
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="Import-instruction__header fields-and-requirement">
        <p className="text--large bold title">Fields and Requirements</p>
        <Table
          columns={columns}
          data={
            formatType[typeName] === "General Information"
              ? TABLE_GENERAL_INFORMATION
              : formatType[typeName] === "Enrollments"
              ? TABLE_ENROLMENT
              : TABLE_PLACEMENT
          }
        />
      </div>
    </div>
  );
}

export default ImportInstructionsDetail;
