import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import {
  closeModalMessageDelete,
  openModalMessageDelete,
} from "redux/actions/global";
import { openMessage } from "redux/actions/message";
import { changeURLWithoutReload } from "../../../utils/helpers";
import { AdministratorsTable } from "../../../components/dashboard/institution";
import {
  deleteAdministrator,
  getAdministrator,
} from "../../../redux/actions/institution";
import { ROUTES, SORT_NAME } from "../../../utils/constants";

export default function () {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [params, setParams] = React.useState({});
  const [isParsing, setIsParsing] = React.useState(true);
  const storeAdministrator = useSelector((store) => store.institution.users);

  // only run for the first time visit page
  React.useEffect(() => {
    setParams(queryString.parse(location.search));
    setIsParsing(false);
  }, [location.search]);

  React.useEffect(() => {
    if (isParsing) return;
    dispatch(getAdministrator(params));
  }, [params, isParsing]);

  const handleChangeURL = (newParams) => {
    changeURLWithoutReload(
      location.pathname,
      `${location.pathname}?${queryString.stringify(newParams)}`
    );
  };

  const handlePageChange = (e) => {
    const newParams = { ...params, page: e.selected + 1 };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleSort = (column, direction) => {
    const newParams = {
      ...params,
      [SORT_NAME]: `${column} ${direction}`,
      page: 1,
    };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleEdit = (selectedItem) => () => {
    history.push(ROUTES.addAdministrator, selectedItem);
  };

  const handleDeleteUser = (admin) => async () => {
    if (!admin.id) {
      return;
    }
    try {
      await dispatch(deleteAdministrator(admin.id));
      dispatch(
        openMessage({
          title: `${admin.first_name} ${admin.last_name} has been deleted`,
          type: "success",
        })
      );
      dispatch(closeModalMessageDelete());
      if (storeAdministrator.data.length === 1 && params.page > 1) {
        setParams({ ...params, page: params.page - 1 });
      }
      dispatch(getAdministrator(params));
    } catch (error) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  return (
    <AdministratorsTable
      storeAdministrator={storeAdministrator}
      onPageChange={handlePageChange}
      params={params}
      onSort={handleSort}
      onEdit={handleEdit}
      onDelete={(rowData) => () => {
        dispatch(
          openModalMessageDelete({
            onDelete: handleDeleteUser(rowData),
          })
        );
      }}
    />
  );
}
