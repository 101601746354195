import React from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import border from "../../../../assets/images/border-step2.svg";
import { ROUTES } from "../../../../utils/constants";

function Header({ step = 1 }) {
  return (
    <div className=" Header-import">
      <NavLink
        to={ROUTES.importInstructions}
        className="text--small bold "
        activeClassName="isActive"
        exact
      >
        View Import Instructions
      </NavLink>
      <div className="Header-import__steps ">
        <div className="Header-import__steps__inner">
          <div
            className={classNames("Header-import__steps__item", {
              isActive: step === 1,
              complete: step === 2 || 3,
            })}
          >
            <div className="number bold">
              <p>1</p>
            </div>
            <p className="name-step bold">Choose Type</p>
          </div>
          <img src={border} alt="" />
          <span>----</span>
          <div
            className={classNames("Header-import__steps__item", {
              isActive: step === 2,
              complete: step === 3,
            })}
          >
            <div className="number bold">
              <p>2</p>
            </div>
            <p className="name-step bold">Import</p>
          </div>
          <img src={border} alt="" />
          <span>----</span>
          <div
            className={classNames("Header-import__steps__item", {
              isActive: step === 3,
            })}
          >
            <div className="number bold">
              <p>3</p>
            </div>
            <p className="name-step bold">Summary</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
