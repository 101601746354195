import React from "react";
import { PricingType, Questions } from "../components/pricing";

export default function () {
  return (
    <div className="Pricing">
      <PricingType />
      <Questions />
    </div>
  );
}
