import React from "react";
import { Collapse } from "react-bootstrap";
import classNames from "classnames";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isStudent } from "utils/helpers";
import {
  Cohorts,
  DashboardIcon,
  ReportingIcon,
  Students,
  Surveys,
  Programs,
  FinancialAidPrograms,
  Branches,
  Institution,
  ArrowUp,
  ArrowDown,
  Logo,
  Results,
} from "../common/icons";
import { ROUTES } from "../../utils/constants";

function Sidebar({ data = [] }) {
  const location = useLocation();
  const pathname = location.pathname;

  const [open, setOpen] = React.useState(false);

  const renderStudentLinks = () => (
    <NavLink
      className="sidebar__item "
      activeClassName="isActive"
      to={ROUTES.dashboardStudent}
      exact
    >
      <div className="dashboard-icon">
        <DashboardIcon />
      </div>
      <p>Tasks</p>
    </NavLink>
  );

  const renderAdminLinks = () => (
    <>
      <NavLink
        className="sidebar__item "
        activeClassName="isActive"
        to={ROUTES.dashboard}
        exact
      >
        <div className="dashboard-icon">
          <DashboardIcon />
        </div>
        <p>Dashboard</p>
      </NavLink>
      <div className="Reporting ">
        <div
          className={classNames("sidebar__item", {
            isActive: pathname.includes("/dashboard/reporting"),
          })}
          onClick={() => setOpen(!open)}
          role="button"
          tabIndex="0"
        >
          <div className="title">
            <div className="reporting-icon">
              <ReportingIcon />
            </div>
            <p>Reporting</p>
          </div>
          <div className="iconArrow">{open ? <ArrowUp /> : <ArrowDown />}</div>
        </div>
        <Collapse in={open}>
          <div className="list-reporting">
            {data.length > 0 &&
              data.map((item) => (
                <NavLink
                  key={item}
                  activeClassName="isActiveItem"
                  to={`${ROUTES[item.toLowerCase()]}`}
                >
                  {item}
                </NavLink>
              ))}
          </div>
        </Collapse>
      </div>
      {/* <NavLink
        className="sidebar__item "
        activeClassName="isActive"
        to={ROUTES.surveys}
        exact
      >
        <div className="survey-icon">
          <Surveys />
        </div>

        <p>Surveys</p>
      </NavLink> */}
      <NavLink
        className="sidebar__item "
        activeClassName="isActive"
        to={ROUTES.campaigns}
      >
        <div className="survey-icon">
          <Surveys />
        </div>
        <p>Campaigns</p>
      </NavLink>
      <NavLink
        className="sidebar__item "
        activeClassName="isActive"
        to={ROUTES.responses}
      >
        <div className="survey-icon">
          <Results />
        </div>
        <p>Responses</p>
      </NavLink>
      <NavLink
        className="sidebar__item "
        activeClassName="isActive"
        to={ROUTES.students}
      >
        <div className="student-icon">
          <Students />
        </div>
        <p>Students</p>
      </NavLink>
      <NavLink
        className="sidebar__item "
        activeClassName="isActive"
        to={ROUTES.cohorts}
      >
        <div className="cohort-icon">
          <Cohorts />
        </div>
        <p>Cohorts</p>
      </NavLink>
      <NavLink
        className="sidebar__item "
        activeClassName="isActive"
        to={ROUTES.programs}
      >
        <div className="program-icon">
          <Programs />
        </div>
        <p>Programs</p>
      </NavLink>
      <NavLink
        className="sidebar__item "
        activeClassName="isActive"
        to={ROUTES.tasks}
      >
        <div className="tasks-icon">
          <FontAwesomeIcon icon={["fas", "list-check"]} />
        </div>
        <p>Tasks</p>
      </NavLink>
      <NavLink
        className="sidebar__item "
        activeClassName="isActive"
        to={ROUTES.financialAidPrograms}
      >
        <div className="financial-aid-programs-icon">
          <FinancialAidPrograms />
        </div>
        <p>Financial Aid</p>
      </NavLink>
      <NavLink
        className="sidebar__item "
        activeClassName="isActive"
        to={ROUTES.branches}
      >
        <div className="branch-icon">
          <Branches />
        </div>
        <p>Branches</p>
      </NavLink>
      <NavLink
        className="sidebar__item "
        activeClassName="isActive"
        to={ROUTES.institution}
      >
        <div className="stitution-icon">
          <Institution />
        </div>
        <p>Institution</p>
      </NavLink>
    </>
  );

  return (
    <div className="Sidebar">
      <div className="Sidebar__inner ">
        <Link to="/" className="logo">
          <Logo />
        </Link>
        {isStudent() ? renderStudentLinks() : renderAdminLinks()}
      </div>
    </div>
  );
}

export default Sidebar;
