import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faPaperclip, faFileLines } from "@fortawesome/free-solid-svg-icons";
import {
  capitalizeFirstLetter,
  convertSnakeCaseToTitleCase,
  isStudent,
} from "utils/helpers";

function ModalTaskDetail({
  open,
  onClose,
  data,
  config = {
    showStudentName: false,
    showAdminActions: false,
    showStudentActions: false,
  },
  onApprove = () => {},
  onReject = () => {},
  loading = false,
}) {
  const renderItem = (label, value, isShowingTime) => {
    if (!value) return null;
    if (isShowingTime) {
      return (
        <p className="text--small">
          <span className="bold">{label}: </span>
          {moment(value).format("MM/DD/YYYY")}
        </p>
      );
    }
    return (
      <p className="text--small">
        <span className="bold ">{label}: </span>
        {capitalizeFirstLetter(value.toString())}
      </p>
    );
  };
  const renderItemAble = (item) => {
    if (!item) return null;

    return (
      <>
        {renderItem("School Name", item?.school?.name)}
        {renderItem("Program Name", item?.program?.name)}
        {renderItem("Branch Name", item?.branch?.name)}
        {renderItem("Cohort Name", item?.cohort?.name)}
        {renderItem("Start Date", item?.start_date)}
        {renderItem("Start Status", item?.start_status)}
        {renderItem("Start Comment", item?.start_comments)}
        {renderItem("End Date", item?.end_date)}
        {renderItem("End Status", item?.end_status)}
        {renderItem("End Comments", item?.end_comments)}
        {renderItem("End Comments", item?.end_comments)}
        {renderItem("Submitted At", item?.submitted_at, true)}
        {renderItem("Completed At", item?.completed_at, true)}
        {renderItem("Created At", item?.created_at, true)}
        {renderItem("Updated At", item?.updated_at, true)}
        {renderItem("Outcome", item?.surveyable?.outcome)}
        {item?.surveyable?.employment_placement && (
          <div className="placement">
            <p className="bold">Placement</p>
            <div className="placement-details">
              {Object.keys(item?.surveyable?.employment_placement).map(
                (placement) => {
                  if (
                    placement === "id" ||
                    !item?.surveyable?.employment_placement[placement]
                  )
                    return null;

                  return (
                    <>
                      {renderItem(
                        convertSnakeCaseToTitleCase(placement),
                        item?.surveyable?.employment_placement[placement],
                        !!(
                          placement === "created_at" ||
                          placement === "updated_at" ||
                          placement === "start_date" ||
                          placement === "offer_date"
                        )
                      )}
                    </>
                  );
                }
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <Modal
      show={open}
      onHide={onClose}
      backdropClassName="wrap-modal"
      dialogClassName="Modal Modal__task-detail"
      keyboard={false}
      centered
    >
      <div className="bootstrap">
        <Modal.Header closeButton closeVariant="white" />
        <Modal.Body>
          <div className="section-1">
            <p className="title">
              <FontAwesomeIcon icon={["fas", "list-check"]} />
              <span className="title-text bold">{data?.title}</span>
            </p>
            <div className="section-content section-1--items">
              <div className="section-1--item">
                <p className="title--item text bold">STUDENT</p>
                <Link
                  to={`/dashboard/students/${data?.student?.id}`}
                  className="avatar-wrapper"
                  onClick={(e) => {
                    if (isStudent()) {
                      e.preventDefault();
                    }
                  }}
                >
                  <div className="avatar">
                    {data?.student?.full_name.charAt(0).toUpperCase()}
                  </div>
                  <span>{data?.student?.full_name}</span>
                </Link>
              </div>
              <div className="section-1--item">
                <p className="title--item text bold">STATUS</p>
                <p className={`text status--${data?.status} capitalize`}>
                  {data?.status}
                </p>
              </div>
              {data?.task_type === "attendance" && data?.itemable?.attended_at && (
                <div className="section-1--item">
                  <p className="title--item text bold">DUE DATE</p>
                  <p className="text">
                    {moment(data?.itemable?.attended_at).format("MM/DD/YYYY")}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="section-2 description">
            <p className="title">
              <FontAwesomeIcon icon={faFileLines} />
              <span className="title-text bold">Description</span>
            </p>
            <div className="section-content">
              <p className="text">{data?.description}</p>
              {renderItemAble(data?.itemable)}
            </div>
          </div>
          <div className="section-3 attachments">
            <p className="title">
              <FontAwesomeIcon icon={faPaperclip} />
              <span className="title-text bold">Attachments</span>
            </p>
            <div className="section-content">
              {(
                data?.itemable?.files || data?.itemable?.surveyable?.files
              )?.map((item) => (
                <a
                  href={item.url}
                  target="_blank"
                  className="attachments--item"
                  key={`file-${item.id}`}
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={["fa", "folder"]} />
                  <span className="text--small">{item.filename}</span>
                </a>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {config.showAdminActions && data?.status === "in_review" && (
            <>
              <button
                className="button button--delete"
                type="button"
                onClick={onReject}
                disabled={loading}
              >
                Reject
              </button>
              <button
                className="button button--primary"
                type="button"
                onClick={onApprove}
                disabled={loading}
              >
                Approve
              </button>
            </>
          )}
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default ModalTaskDetail;
