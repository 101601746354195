/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import { Typeahead } from "../../../common";

import { Tick, Unlink, Warning } from "../../../common/icons";
import CollapseMapping from "./CollapseMapping";

function Step3({
  onSubmit,
  handleBack,
  isSubmitting,
  formPull,
  formPush,
  onChange,
  error,
  dataObjects,
  dataAttributes,
  isLoadingTable,
  step,
}) {
  const optionStudent =
    dataAttributes.student && dataAttributes.student.length
      ? dataAttributes.student.map((item) => ({
          label: item.label,
          value: item.name,
        }))
      : [];

  const optionPrograms =
    dataAttributes.program && dataAttributes.program.length
      ? dataAttributes.program.map((item) => ({
          label: item.label,
          value: item.name,
        }))
      : [];

  const optionBranches =
    dataAttributes.branch && dataAttributes.branch.length
      ? dataAttributes.branch.map((item) => ({
          label: item.label,
          value: item.name,
        }))
      : [];

  const optionCohort =
    dataAttributes.cohort && dataAttributes.cohort.length
      ? dataAttributes.cohort.map((item) => ({
          label: item.label,
          value: item.name,
        }))
      : [];

  const optionEnrollment =
    dataAttributes.enrollment && dataAttributes.enrollment.length
      ? dataAttributes.enrollment.map((item) => ({
          label: item.label,
          value: item.name,
        }))
      : [];

  const optionPlacement =
    dataAttributes.placement && dataAttributes.placement.length
      ? dataAttributes.placement.map((item) => ({
          label: item.label,
          value: item.name,
        }))
      : [];

  const DATA = [
    {
      title: "Student (Pull)",
      data: [
        {
          id: 1,
          model: "student",
          name: "Student",
          salesforce: {
            placeholder: "Select a Field",
            name: "integration_model",
            options: dataObjects,
          },
        },
        {
          id: 2,
          model: "student",
          name: "First Name",
          salesforce: {
            placeholder: "Select a Field",
            name: "first_name",
            options: optionStudent,
          },
        },
        {
          id: 3,
          model: "student",
          name: "Last Name",
          salesforce: {
            placeholder: "Select a Field",
            name: "last_name",
            options: optionStudent,
          },
        },
        {
          id: 4,
          model: "student",
          name: "Email",
          salesforce: {
            placeholder: "Select a Field",
            name: "email",
            options: optionStudent,
          },
        },
        {
          id: 5,
          model: "student",
          name: "Phone",
          salesforce: {
            placeholder: "Select a Field",
            name: "phone",
            options: optionStudent,
          },
        },
        {
          id: 6,
          name: "Street",
          model: "student",
          salesforce: {
            placeholder: "Select a Field",
            name: "address",
            options: optionStudent,
          },
        },
        {
          id: 7,
          name: "City",
          model: "student",
          salesforce: {
            placeholder: "Select a Field",
            name: "city",
            options: optionStudent,
          },
        },
        {
          id: 8,
          model: "student",
          name: "State",
          salesforce: {
            placeholder: "Select a Field",
            name: "state",
            options: optionStudent,
          },
        },
        {
          id: 9,
          model: "student",
          name: "Zip",
          salesforce: {
            placeholder: "Select a Field",
            name: "zip_code",
            options: optionStudent,
          },
        },
        {
          id: 38,
          model: "student",
          name: "Education Level",
          salesforce: {
            placeholder: "Select a Field",
            name: "education_level",
            options: optionStudent,
          },
        },
      ],
    },
    {
      title: "Program (Push)",
      data: [
        {
          id: 10,
          model: "program",
          name: "Program",
          salesforce: {
            placeholder: "Select a Field",
            name: "integration_model",
            options: dataObjects,
          },
        },
        {
          id: 11,
          model: "program",
          name: "ID",
          salesforce: {
            placeholder: "Select a Field",
            name: "id",
            options: optionPrograms,
          },
        },
        {
          id: 12,
          model: "program",
          name: "Name",
          salesforce: {
            placeholder: "Select a Field",
            name: "name",
            options: optionPrograms,
          },
        },
      ],
    },
    {
      title: "Branch (Push)",
      data: [
        {
          id: 13,
          model: "branch",
          name: "Branch",
          salesforce: {
            placeholder: "Select a Field",
            name: "integration_model",
            options: dataObjects,
          },
        },
        {
          id: 14,
          model: "branch",
          name: "ID",
          salesforce: {
            placeholder: "Select a Field",
            name: "id",
            options: optionBranches,
          },
        },
        {
          id: 15,
          model: "branch",
          name: "Name",
          salesforce: {
            placeholder: "Select a Field",
            name: "name",
            options: optionBranches,
          },
        },
        {
          id: 16,
          model: "branch",
          name: "City",
          salesforce: {
            placeholder: "Select a Field",
            name: "city",
            options: optionBranches,
          },
        },
        {
          id: 17,
          model: "branch",
          name: "State",
          salesforce: {
            placeholder: "Select a Field",
            name: "state",
            options: optionBranches,
          },
        },
      ],
    },
    {
      title: "Cohort (Push)",
      data: [
        {
          id: 17,
          model: "cohort",
          name: "Cohort",
          salesforce: {
            placeholder: "Select a Field",
            name: "integration_model",
            options: dataObjects,
          },
        },
        {
          id: 19,
          model: "cohort",
          name: "ID",
          salesforce: {
            placeholder: "Select a Field",
            name: "id",
            options: optionCohort,
          },
        },
        {
          id: 20,
          model: "cohort",
          name: "Name",
          salesforce: {
            placeholder: "Select a Field",
            name: "name",
            options: optionCohort,
          },
        },
        {
          id: 21,
          model: "cohort",
          name: "Start Date",
          salesforce: {
            placeholder: "Select a Field",
            name: "start_date",
            options: optionCohort,
          },
        },
        {
          id: 22,
          model: "cohort",
          name: "Graduation Date",
          salesforce: {
            placeholder: "Select a Field",
            name: "graduation_date",
            options: optionCohort,
          },
        },
        {
          id: 23,
          model: "cohort",
          name: "Program ID",
          salesforce: {
            placeholder: "Select a Field",
            name: "program_id",
            options: optionCohort,
          },
        },
        {
          id: 24,
          model: "cohort",
          name: "Branch ID",
          salesforce: {
            placeholder: "Select a Field",
            name: "branch_id",
            options: optionCohort,
          },
        },
      ],
    },
    {
      title: "Enrollment (Push)",
      data: [
        {
          id: 25,
          model: "enrollment",
          name: "Enrollment",
          salesforce: {
            placeholder: "Select a Field",
            name: "integration_model",
            options: dataObjects,
          },
        },
        {
          id: 26,
          model: "enrollment",
          name: "ID",
          salesforce: {
            placeholder: "Select a Field",
            name: "id",
            options: optionEnrollment,
          },
        },
        {
          id: 27,
          model: "enrollment",
          name: "Start Status",
          salesforce: {
            placeholder: "Select a Field",
            name: "start_status",
            options: optionEnrollment,
          },
        },
        {
          id: 28,
          model: "enrollment",
          name: "End Status",
          salesforce: {
            placeholder: "Select a Field",
            name: "end_status",
            options: optionEnrollment,
          },
        },
        {
          id: 29,
          model: "enrollment",
          name: "Start Comments",
          salesforce: {
            placeholder: "Select a Field",
            name: "start_comments",
            options: optionEnrollment,
          },
        },
        {
          id: 30,
          model: "enrollment",
          name: "End Comments",
          salesforce: {
            placeholder: "Select a Field",
            name: "end_comments",
            options: optionEnrollment,
          },
        },
        {
          id: 31,
          model: "enrollment",
          name: "Cohort ID",
          salesforce: {
            placeholder: "Select a Field",
            name: "cohort_id",
            options: optionEnrollment,
          },
        },
        {
          id: 32,
          model: "enrollment",
          name: "Student ID",
          salesforce: {
            placeholder: "Select a Field",
            name: "student_id",
            options: optionEnrollment,
          },
        },
      ],
    },
    {
      title: "Placement (Push)",
      data: [
        {
          id: 33,
          model: "placement",
          name: "Placement",
          salesforce: {
            placeholder: "Select a Field",
            name: "integration_model",
            options: dataObjects,
          },
        },
        {
          id: 34,
          model: "placement",
          name: "ID",
          salesforce: {
            placeholder: "Select a Field",
            name: "id",
            options: optionPlacement,
          },
        },
        {
          id: 35,
          model: "placement",
          name: "Outcome",
          salesforce: {
            placeholder: "Select a Field",
            name: "outcome",
            options: optionPlacement,
          },
        },
        {
          id: 36,
          model: "placement",
          name: "Program ID",
          salesforce: {
            placeholder: "Select a Field",
            name: "program_id",
            options: optionPlacement,
          },
        },
        {
          id: 37,
          model: "placement",
          name: "Student ID",
          salesforce: {
            placeholder: "Select a Field",
            name: "student_id",
            options: optionPlacement,
          },
        },
      ],
    },
  ];

  const columns = [
    {
      title: "",
      dataIndex: "name",
      render: (row) => <div>{row.name}</div>,
    },
    {
      title: "",
      dataIndex: "id",
      render: (row) => (
        <Typeahead
          propsTypeahead={{
            id: `map-pull-data-${row.id}`,
            labelKey: "label",
            options: row.salesforce.options,
            placeholder: row.salesforce.placeholder,
            onChange: onChange(row.salesforce.name, row.model),
            selected:
              row.model === "student"
                ? [
                    {
                      value:
                        row.salesforce.name === "integration_model"
                          ? formPull[row.salesforce.name]
                          : formPull.mapping[row.salesforce.name],
                    },
                  ]
                : [
                    {
                      value:
                        row.salesforce.name === "integration_model"
                          ? formPush[row.model][row.salesforce.name]
                          : formPush[row.model].mapping[row.salesforce.name],
                    },
                  ],
            disabled: step === 4,
          }}
          error={
            error && error[row.model] && error[row.model][row.salesforce.name]
              ? error[row.model][row.salesforce.name]
              : ""
          }
        />
        // <>
        //   <SingleSelectCheckbox
        //     onChange={onChange(row.salesforce.name, row.model)}
        //     name={`${row.model}-${row.salesforce.name}`}
        //     options={row.salesforce.options}
        //     value={
        //       row.model === 'student' && row.salesforce.name === 'integration_model'
        //         ? { label: formPull[row.salesforce.name], value: formPull[row.salesforce.name] }
        //         : row.model === 'student' && row.salesforce.name !== 'integration_model'
        //         ? {
        //             label: formPull.mapping[row.salesforce.name],
        //             value: formPull.mapping[row.salesforce.name]
        //           }
        //         : row.model !== 'student' && row.salesforce.name === 'integration_model'
        //         ? {
        //             label: formPush[row.model][row.salesforce.name],
        //             value: formPush[row.model][row.salesforce.name]
        //           }
        //         : {
        //             label: formPush[row.model].mapping[row.salesforce.name],
        //             value: formPush[row.model].mapping[row.salesforce.name]
        //           }
        //     }
        //     placeholder={row.salesforce.placeholder}
        //     size="small"
        //     disabled={step === 4}
        //     error={
        //       error && error[row.model] && error[row.model][row.salesforce.name]
        //         ? error[row.model][row.salesforce.name]
        //         : ''
        //     }
        //   />
        // </>
      ),
    },
    {
      title: "",
      dataIndex: "status",
      render: (row) => (
        <div>
          {(row.salesforce.name === "integration_model" &&
            ((row.model === "student" && formPull[row.salesforce.name]) ||
              (row.model !== "student" &&
                formPush[row.model][row.salesforce.name]))) ||
          (row.salesforce.name !== "integration_model" &&
            ((row.model === "student" &&
              formPull.mapping[row.salesforce.name]) ||
              (row.model !== "student" &&
                formPush[row.model].mapping[row.salesforce.name]))) ? (
            // eslint-disable-next-line react/jsx-indent
            <div className="status mapped">
              <Tick />
              Mapped
            </div>
          ) : error[row.model] && error[row.model][row.salesforce.name] ? (
            <div className="status invalid">
              <Warning />
              Invalid
            </div>
          ) : (
            <div className="status unmapped">
              <Unlink />
              Unmapped
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="pull-step3  form-student dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="form-student__text">
          <p className="text--large bold">
            {step === 3 ? "Map Data to Push" : "Mapping Summary"}
          </p>
          {step === 3 && (
            <p className="text--small">
              Keep your student data up to date. Push enrollment, completion,
              and placement information directly from AcademySync into
              Salesforce.
            </p>
          )}
        </div>
        <div className="pull-step3__inner form-student__inner">
          <div className="TableWrapper">
            <div className="Table">
              <div className="Table__inner">
                <div>
                  <div className="Table__inner__header">
                    <p>AcademySync</p>
                    <p>Salesforce </p>
                    <p>Mapped Status</p>
                  </div>

                  {DATA.map((item) => (
                    <CollapseMapping
                      item={item}
                      key={item.title}
                      columns={columns}
                      step={step}
                      isLoadingTable={isLoadingTable}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="button-group">
            <button
              className="button button--back"
              type="submit"
              onClick={handleBack}
              disabled={isSubmitting}
            >
              Back
            </button>
            <button
              className="button button--primary"
              type="submit"
              disabled={isSubmitting}
            >
              {step === 3 ? "Map" : "Sync"}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

Step3.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

Step3.defaultProps = {
  isSubmitting: false,
};

export default Step3;
