/* eslint-disable no-nested-ternary */
import React from "react";
import moment from "moment";
import { useHistory, useLocation, useParams } from "react-router-dom";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { FormGroup, SingleSelectCheckbox } from "../../common";
import { Edit } from "../../common/icons";
import Table from "../../common/Table";
import { ROUTES, SORT_NAME } from "../../../utils/constants";
import { formatPhoneNumber, replaceParamStudent } from "../../../utils/helpers";
import { getStudent } from "../../../redux/actions/student";
import {
  updateReportingURL,
  updateReportName,
} from "../../../redux/actions/global";

function StudentList({
  data = [],
  dataShowing = [],
  params = {},
  onSearch = () => {},
  onSort = () => {},
  loading = false,
  filter = {},
  onChangeFilter = () => {},
  paginationProps = {},
}) {
  const Params = useParams();
  const reportName = Params.reportName;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleEditStudent = (row) => async () => {
    const url = `${location.pathname}${location.search}`;
    updateReportingURL(url);
    updateReportName(reportName);
    await dispatch(getStudent({}, row.id));
    history.push(replaceParamStudent(ROUTES.student, row.id));
  };

  const columnsCIRR = [
    {
      title: "Name",
      dataIndex: "name",
      sortAble: true,
    },
    {
      title: "Original Cohort",
      dataIndex: "original_cohort",
      sortAble: true,
    },
    {
      title: "Transferred To",
      dataIndex: "transferred_to",
      render: (row) => (row.transferred_to ? row.transferred_to : "-"),
      sortAble: true,
    },
    {
      title: "Failed to meet graduation requirements?",
      dataIndex: "failed_to_meet_requirements",
      render: (row) =>
        row.failed_to_meet_requirements ? row.failed_to_meet_requirements : "-",
      sortAble: true,
    },
    {
      title: "Original Start Date",
      dataIndex: "original_start_date",
      render: (row) => moment(row.original_start_date).format("MM/DD/YYYY"),
      sortAble: true,
    },
    {
      title: "Original Graduation Date",
      dataIndex: "original_graduation_date",
      render: (row) =>
        moment(row.original_graduation_date).format("MM/DD/YYYY"),
      sortAble: true,
    },
    {
      title: "Final Cohort",
      dataIndex: "final_cohort",
      sortAble: true,
    },
    {
      title: "Effective Graduation Date",
      dataIndex: "effective_graduation_date",
      render: (row) =>
        moment(row.effective_graduation_date).format("MM/DD/YYYY"),
      sortAble: true,
    },
    {
      title: "Included in Graduation Rate?",
      dataIndex: "included_in_graduation_rate",
      sortAble: true,
    },
    {
      title: "Time to Graduation",
      dataIndex: "time_to_graduation",
      render: (row) => (row.time_to_graduation ? row.time_to_graduation : "-"),
      sortAble: true,
    },
    {
      title: "Study Country Work Authorization",
      dataIndex: "study_country_work_auth",
      sortAble: true,
    },
    {
      title: "Job Search Country Work Authorization",
      dataIndex: "job_search_country_work_auth",
      sortAble: true,
    },
    {
      title: "Intention",
      dataIndex: "intention",
      sortAble: true,
    },
    {
      title: "Included in placement data?",
      dataIndex: "included_in_placement_data",
      sortAble: true,
    },
    {
      title: "Outcome code",
      dataIndex: "outcome_code",
      sortAble: true,
    },
    {
      title: "Primary code",
      dataIndex: "primary_code",
      sortAble: true,
    },
    {
      title: "Hired by School",
      dataIndex: "hired_by_school",
      sortAble: true,
    },
    {
      title: "Effective Date",
      dataIndex: "effective_date",
      sortAble: true,
    },
    {
      title: "Days to Offer",
      dataIndex: "days_to_offer",
      sortAble: true,
    },
    {
      title: "Code as of 90 days",
      dataIndex: "code_as_of_90",
      sortAble: true,
    },
    {
      title: "Code as of 180 days",
      dataIndex: "code_as_of_180",
      sortAble: true,
    },
    {
      title: "Type of Documentation Required",
      dataIndex: "type_of_documentation_required",
      sortAble: true,
    },
    {
      title: "Link to documentation",
      dataIndex: "link_to_doc",
      render: (row) =>
        row.link_to_doc ? (
          <a href={row.link_to_doc} target="_blank" rel="noreferrer">
            Link
          </a>
        ) : (
          ""
        ),
      sortAble: true,
    },
    {
      title: "Type of Offer Documentation",
      dataIndex: "offer_doc_type",
      sortAble: true,
    },
    {
      title: "States effective date?",
      dataIndex: "states_effective_date",
      sortAble: true,
    },
    {
      title: "States payment?",
      dataIndex: "states_payment",
      sortAble: true,
    },
    {
      title: "States offer was accepted?",
      dataIndex: "states_accepted",
      sortAble: true,
    },
    {
      title: "Documents in-field?",
      dataIndex: "documents_in_field",
      sortAble: true,
    },
    {
      title: "In-field documentation type?",
      dataIndex: "in_field_doc_type",
      sortAble: true,
    },
    {
      title: "Documents full-time?",
      dataIndex: "documents_full_time",
      sortAble: true,
    },
    {
      title: "Documented term?",
      dataIndex: "documented_term",
      sortAble: true,
    },
    {
      title: "Payment Information",
      dataIndex: "payment_information",
      sortAble: true,
    },
    {
      title: "Payment value",
      dataIndex: "payment_value",
      sortAble: true,
    },
    {
      title: "Annualized salary(90)",
      dataIndex: "annualized_salary_90",
      sortAble: true,
    },
    {
      title: "Annualized salary(180)",
      dataIndex: "annualized_salary_180",
      sortAble: true,
    },
    {
      title: "Job Title",
      dataIndex: "job_title",
      sortAble: true,
    },
    {
      title: "",
      dataIndex: "id",
      render: (row) => (
        <button
          className="button-edit-student"
          onClick={handleEditStudent(row)}
          type="button"
          tabIndex="0"
        >
          <Edit />
        </button>
      ),
      align: "right",
    },
  ];

  const columnsBPPE = [
    {
      title: "Name",
      dataIndex: "name",
      sortAble: true,
    },
    {
      title: "Original Cohort",
      dataIndex: "original_cohort",
      sortAble: true,
    },
    {
      title: "Transferred To",
      dataIndex: "transferred_to",
      render: (row) => (row.transferred_to ? row.transferred_to : "-"),
      sortAble: true,
    },
    {
      title: "Final Cohort",
      dataIndex: "final_cohort",
      sortAble: true,
    },
    {
      title: "Original Start Date",
      dataIndex: "original_start_date",
      render: (row) => moment(row.original_start_date).format("MM/DD/YYYY"),
      sortAble: true,
    },
    {
      title: "Final End Date",
      dataIndex: "final_end_date",
      render: (row) => moment(row.final_end_date).format("MM/DD/YYYY"),
      sortAble: true,
    },
    {
      title: "Received Federal Loans",
      dataIndex: "recieved_federal_loans",
      render: (row) => (row.recieved_federal_loans === true ? "Yes" : "No"),
      sortAble: true,
    },
    {
      title: "Began Program",
      dataIndex: "began_program",
      render: (row) => (row.began_program === true ? "Yes" : "No"),
      sortAble: true,
    },
    {
      title: "Available for Graduation",
      dataIndex: "available_for_graduation",
      render: (row) => (row.available_for_graduation === true ? "Yes" : "No"),
      sortAble: true,
    },
    {
      title: "Completed",
      dataIndex: "completed",
      render: (row) => (row.completed === true ? "Yes" : "No"),
      sortAble: true,
    },
    {
      title: "Completed On-Time",
      dataIndex: "completed_within_100",
      render: (row) => (row.completed_within_100 === true ? "Yes" : "No"),
      sortAble: true,
    },
    {
      title: "Completed within 150%",
      dataIndex: "completed_within_150",
      sortAble: true,
    },
    {
      title: "Available for Employment",
      dataIndex: "available_for_employment",
      sortAble: true,
    },
    {
      title: "Employed In-Field",
      dataIndex: "employed_in_field",
      sortAble: true,
    },
    {
      title: "Documentation",
      dataIndex: "documentation",
      render: (row) =>
        row.documentation ? (
          <a href={row.documentation} target="_blank" rel="noreferrer">
            Link
          </a>
        ) : (
          ""
        ),
      sortAble: true,
    },
    {
      title: "Part-time OR Full-time",
      dataIndex: "part_time_or_full_time",
      sortAble: true,
    },
    {
      title: "Single OR Concurrent Position",
      dataIndex: "single_or_concurrent_position",
      sortAble: true,
    },
    {
      title: "Self-Employed/Freelance",
      dataIndex: "self_employed_or_freelance",
      sortAble: true,
    },
    {
      title: "Hired by School",
      dataIndex: "hired_by_school",
      sortAble: true,
    },
    {
      title: "Salary",
      dataIndex: "salary",
      sortAble: true,
    },
    {
      title: "Licensure Exam Result",
      dataIndex: "passed_exam",
      sortAble: true,
    },
    {
      title: "",
      dataIndex: "id",
      render: (row) => (
        <button
          className="button-edit-student"
          onClick={handleEditStudent(row)}
          type="button"
          tabIndex="0"
        >
          <Edit />
        </button>
      ),
      align: "right",
    },
  ];

  const columnsBPSS = [
    {
      title: "Name",
      dataIndex: "name",
      sortAble: true,
    },
    {
      title: "Program Start Date",
      dataIndex: "program_start_date",
      render: (row) => moment(row.program_start_date).format("MM/DD/YYYY"),
      sortAble: true,
    },
    {
      title: "Program Graduation End",
      dataIndex: "program_graduation_date",
      render: (row) => moment(row.program_graduation_date).format("MM/DD/YYYY"),
      sortAble: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (row) => (row.status ? row.status : "-"),
      sortAble: true,
    },
    {
      title: "HS Diploma / HSE",
      dataIndex: "hs_diploma_or_hse",
      render: (row) =>
        row.hs_diploma_or_hse?.toLowerCase() === "yes" ? "Yes" : "No",
    },
    {
      title: "Enrollment Status",
      dataIndex: "enrollment_status",
      render: (row) => (row.enrollment_status ? row.enrollment_status : "-"),
      sortAble: true,
    },
    {
      title: "Completion Status",
      dataIndex: "completion_status",
      render: (row) => (row.completion_status ? row.completion_status : "-"),
      sortAble: true,
    },
    {
      title: "Placement Status",
      dataIndex: "placement_status",
      render: (row) => (row.placement_status ? row.placement_status : "-"),
      sortAble: true,
    },
    {
      title: "Received Financial Aid",
      dataIndex: "received_aid",
      render: (row) => (row.received_aid === true ? "Yes" : "No"),
    },
    {
      title: "Financial Aid Programs",
      dataIndex: "received_aid_list",
      render: (row) =>
        row.received_aid_list && row.received_aid_list.length
          ? row.received_aid_list.map((item) => item)
          : "-",
    },
    {
      title: "",
      dataIndex: "id",
      render: (row) => (
        <button
          className="button-edit-student"
          onClick={handleEditStudent(row)}
          type="button"
          tabIndex="0"
        >
          <Edit />
        </button>
      ),
      align: "right",
    },
  ];

  const columnsTWC = [
    {
      title: "Name",
      dataIndex: "name",
      sortAble: true,
    },
    {
      title: "SSN",
      dataIndex: "ssn",
      render: (row) => (row.ssn ? row.ssn : "-"),
      sortAble: true,
    },
    {
      title: "Program Start Date",
      dataIndex: "program_start_date",
      render: (row) => moment(row.program_start_date).format("MM/DD/YYYY"),
      sortAble: true,
    },
    {
      title: "Program Graduation Date",
      dataIndex: "program_graduation_date",
      render: (row) => moment(row.program_graduation_date).format("MM/DD/YYYY"),
      sortAble: true,
    },
    {
      title: "Enrollment Status",
      dataIndex: "enrollment_status",
      render: (row) => (row.enrollment_status ? row.enrollment_status : "-"),
      sortAble: true,
    },
    {
      title: "Completion Status",
      dataIndex: "completion_status",
      render: (row) => (row.completion_status ? row.completion_status : "-"),
      sortAble: true,
    },
    {
      title: "Placement Status",
      dataIndex: "placement_status",
      render: (row) => (row.placement_status ? row.placement_status : "-"),
      sortAble: true,
    },
    {
      title: "Student Phone Number",
      dataIndex: "student_phone",
      render: (row) =>
        row.student_phone ? formatPhoneNumber(row.student_phone) : "-",
    },
    {
      title: "Student Email Address",
      dataIndex: "student_email",
      sortAble: true,
    },
    {
      title: "Student Street Address",
      dataIndex: "student_street_address",
      sortAble: true,
    },
    {
      title: "Student City",
      dataIndex: "student_city",
      sortAble: true,
    },
    {
      title: "Student State",
      dataIndex: "student_state",
      sortAble: true,
    },
    {
      title: "Student ZIP code",
      dataIndex: "student_zip",
      sortAble: true,
    },
    {
      title: "Starting Wage per Hour",
      dataIndex: "hourly_wage",
      sortAble: true,
    },
    {
      title: "",
      dataIndex: "id",
      render: (row) => (
        <button
          className="button-edit-student"
          onClick={handleEditStudent(row)}
          type="button"
          tabIndex="0"
        >
          <Edit />
        </button>
      ),
      align: "right",
    },
  ];

  const columnsCOE = [
    {
      title: "Name",
      dataIndex: "name",
      sortAble: true,
    },
    {
      title: "Allowable Subtraction",
      dataIndex: "allowable_substraction",
      render: (row) => (row.allowable_substraction === true ? "Yes" : "No"),
      sortAble: true,
    },
    {
      title: "Awaiting Licensure Result",
      dataIndex: "awaiting_licensure_result",
      render: (row) =>
        row.awaiting_licensure_result ? row.awaiting_licensure_result : "-",
      sortAble: true,
    },
    {
      title: "Program Start Date",
      dataIndex: "program_start_date",
      render: (row) => moment(row.program_start_date).format("MM/DD/YYYY"),
      sortAble: true,
    },
    {
      title: "Program Graduation Date",
      dataIndex: "program_graduation_date",
      render: (row) => moment(row.program_graduation_date).format("MM/DD/YYYY"),
      sortAble: true,
    },
    {
      title: "Enrollment Status",
      dataIndex: "enrollment_status",
      render: (row) => (row.enrollment_status ? row.enrollment_status : "-"),
      sortAble: true,
    },
    {
      title: "Completion Status",
      dataIndex: "completion_status",
      render: (row) => (row.completion_status ? row.completion_status : "-"),
      sortAble: true,
    },
    {
      title: "Placement Status",
      dataIndex: "placement_status",
      render: (row) => (row.placement_status ? row.placement_status : "-"),
      sortAble: true,
    },
    {
      title: "Licensure Status",
      dataIndex: "licensure_status",
      render: (row) => (row.licensure_status ? row.licensure_status : "-"),
      sortAble: true,
    },
    {
      title: "",
      dataIndex: "id",
      render: (row) => (
        <button
          className="button-edit-student"
          onClick={handleEditStudent(row)}
          type="button"
          tabIndex="0"
        >
          <Edit />
        </button>
      ),
      align: "right",
    },
  ];

  const columnsACCET = [
    {
      title: "Name",
      dataIndex: "name",
      sortAble: true,
    },
    {
      title: "Enrollment Status",
      dataIndex: "enrollment_status",
      render: (row) => (row.enrollment_status ? row.enrollment_status : "-"),
      sortAble: true,
    },
    {
      title: "Completion Status",
      dataIndex: "completion_status",
      render: (row) => (row.completion_status ? row.completion_status : "-"),
      sortAble: true,
    },
    {
      title: "Employed In Field",
      dataIndex: "employed_in_field",
      render: (row) => (row.employed_in_field ? row.employed_in_field : "-"),
      sortAble: true,
    },
    {
      title: "",
      dataIndex: "id",
      render: (row) => (
        <button
          className="button-edit-student"
          onClick={handleEditStudent(row)}
          type="button"
          tabIndex="0"
        >
          <Edit />
        </button>
      ),
      align: "right",
    },
  ];

  const columnsDPOS = [
    {
      title: "Name",
      dataIndex: "name",
      sortAble: true,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      render: (row) => (row.gender ? row.gender : "-"),
      sortAble: true,
    },
    {
      title: "Ethnicity",
      dataIndex: "ethnicity",
      render: (row) => (row.ethnicity ? row.ethnicity : "-"),
      sortAble: true,
    },
    {
      title: "Completion Status",
      dataIndex: "completion_status",
      render: (row) => (row.completion_status ? row.completion_status : "-"),
      sortAble: true,
    },
    {
      title: "Placement Status",
      dataIndex: "placement_status",
      render: (row) => (row.placement_status ? row.placement_status : "-"),
      sortAble: true,
    },
    {
      title: "",
      dataIndex: "id",
      render: (row) => (
        <button
          className="button-edit-student"
          onClick={handleEditStudent(row)}
          type="button"
          tabIndex="0"
        >
          <Edit />
        </button>
      ),
      align: "right",
    },
  ];

  const originCohorts = [];
  data.forEach((item) => {
    if (!originCohorts.includes(item.original_cohort)) {
      originCohorts.push(item.original_cohort);
    }
  });

  const finalCohorts = [];
  data.forEach((item) => {
    if (!finalCohorts.includes(item.final_cohort)) {
      finalCohorts.push(item.final_cohort);
    }
  });

  const enrollmentStatus = [];
  data.forEach((item) => {
    if (!enrollmentStatus.includes(item.enrollment_status)) {
      enrollmentStatus.push(item.enrollment_status);
    }
  });

  const placementStatus = [];
  data.forEach((item) => {
    if (!placementStatus.includes(item.placement_status)) {
      placementStatus.push(item.placement_status);
    }
  });

  const completionStatus = [];
  data.forEach((item) => {
    if (!completionStatus.includes(item.completion_status)) {
      completionStatus.push(item.completion_status);
    }
  });

  return (
    <div className="StudentList dashboard__item__inner">
      <div className="StudentList__inner">
        <div className="filter">
          <FormGroup
            propsInput={{
              name: "name",
              className: "inputSearch",
              onChange: onSearch,
              value: filter.name,
              placeholder: "What are you looking for?",
            }}
          />
          {reportName === "bpss" ||
          reportName === "twc" ||
          reportName === "coe" ||
          reportName === "accet" ||
          reportName === "dpos" ? (
            <>
              {reportName !== "dpos" && (
                <SingleSelectCheckbox
                  onChange={onChangeFilter}
                  name="enrollment_status"
                  options={[
                    { value: undefined, label: "All" },
                    ...enrollmentStatus.map((item) => ({
                      value: item,
                      label: item,
                    })),
                  ]}
                  value={{
                    value: filter.enrollment_status,
                    label: filter.enrollment_status,
                  }}
                  size="small"
                  placeholder="Enrollment status"
                />
              )}
              {reportName === "dpos" && (
                <SingleSelectCheckbox
                  onChange={onChangeFilter}
                  name="completion_status"
                  options={[
                    { value: undefined, label: "All" },
                    ...completionStatus.map((item) => ({
                      value: item,
                      label: item,
                    })),
                  ]}
                  value={{
                    value: filter.completion_status,
                    label: filter.completion_status,
                  }}
                  size="small"
                  placeholder="Completion status"
                />
              )}
              {reportName === "accet" ? (
                <SingleSelectCheckbox
                  onChange={onChangeFilter}
                  name="completion_status"
                  options={[
                    { value: undefined, label: "All" },
                    ...completionStatus.map((item) => ({
                      value: item,
                      label: item,
                    })),
                  ]}
                  value={{
                    value: filter.completion_status,
                    label: filter.completion_status,
                  }}
                  size="small"
                  placeholder="Completion status"
                />
              ) : (
                <SingleSelectCheckbox
                  onChange={onChangeFilter}
                  name="placement_status"
                  options={[
                    { value: undefined, label: "All" },
                    ...placementStatus.map((item) => ({
                      value: item,
                      label: item,
                    })),
                  ]}
                  value={{
                    value: filter.placement_status,
                    label: filter.placement_status,
                  }}
                  size="small"
                  placeholder="Placement status"
                />
              )}
            </>
          ) : (
            <>
              <SingleSelectCheckbox
                onChange={onChangeFilter}
                name="original_cohort"
                options={[
                  { value: undefined, label: "All" },
                  ...originCohorts.map((item) => ({
                    value: item,
                    label: item,
                  })),
                ]}
                value={{
                  value: filter.original_cohort,
                  label: filter.original_cohort,
                }}
                size="small"
                placeholder="Original Cohort"
              />
              <SingleSelectCheckbox
                onChange={onChangeFilter}
                name="final_cohort"
                options={[
                  { value: undefined, label: "All" },
                  ...finalCohorts.map((item) => ({
                    value: item,
                    label: item,
                  })),
                ]}
                value={{
                  value: filter.final_cohort,
                  label: filter.final_cohort,
                }}
                size="small"
                placeholder="Final Cohort"
              />
            </>
          )}
        </div>
        <div
          className={classNames({
            "--nowrap": reportName === "twc",
          })}
        >
          <Table
            loading={loading}
            columns={
              reportName === "bppe"
                ? columnsBPPE
                : reportName === "bpss"
                ? columnsBPSS
                : reportName === "twc"
                ? columnsTWC
                : reportName === "cirr"
                ? columnsCIRR
                : reportName === "coe"
                ? columnsCOE
                : reportName === "accet"
                ? columnsACCET
                : columnsDPOS
            }
            data={dataShowing}
            dataShowing={dataShowing}
            tableProps={{ striped: true }}
            sort={params[SORT_NAME] || ""}
            onSort={onSort}
            paginationProps={paginationProps}
          />
        </div>
      </div>
    </div>
  );
}

export default StudentList;
