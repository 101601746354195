import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmail } from "validator";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router-dom";
import { Form } from "../components/signUp";
import {
  acceptInvite,
  getStudentsRegistrationsInfo,
  postSignUp,
  signUpStudent,
} from "../redux/actions/signUp";
import { openMessage } from "../redux/actions/message";
import { getParam, setAuth, validatePassword } from "../utils/helpers";
import { ROUTES } from "../utils/constants";

const DEFAULT_FORM_SIGN_UP = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  password_confirmation: "",
};

export default function () {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form, setForm] = React.useState({ ...DEFAULT_FORM_SIGN_UP });
  const [formErrors, setFormErrors] = React.useState({});
  const [role, setRole] = React.useState("admin");
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const type = getParam("type");

  const storeSignUp = useSelector((store) => store.signUp);

  React.useEffect(() => {
    const params = queryString.parse(location.search);
    setForm({
      ...form,
      email: params.email,
      first_name: params.first_name,
      last_name: params.last_name,
      invitation_token: params.invitation_token,
    });
    if (params.role) {
      setRole(params.role);
    }
  }, [location]);

  const callApiSignUp = async () => {
    try {
      if (role === "admin") {
        const response = form.invitation_token
          ? await dispatch(acceptInvite(form, form.invitation_token))
          : await dispatch(postSignUp(form));
        setAuth(response);
        history.push(ROUTES.onBoarding);
      } else {
        // role student
        const response = await dispatch(
          signUpStudent(form, form.invitation_token)
        );
        setAuth(response, false, true);
        history.push(ROUTES.dashboardStudent);
      }
    } catch (error) {
      const newErrors = {};
      if (error.data && typeof error.data.errors === "object") {
        if (
          error.data.errors.length &&
          error.data.errors[0] === "Invalid token."
        ) {
          return dispatch(
            openMessage({
              title: "Invalid token, please try again!",
              type: "error",
            })
          );
        }
        Object.keys(error.data.errors).forEach((key) => {
          newErrors[key] = error.data.errors[key][0];
        });
        setFormErrors(newErrors);
      } else {
        dispatch(
          openMessage({
            title: "Something went wrong, please try again!",
            type: "error",
          })
        );
      }
    }
  };

  const validateInvitationToken = async (token) => {
    setLoading(true);
    try {
      await getStudentsRegistrationsInfo(token);
      setLoading(false);
      return true;
    } catch (err) {
      console.error("Can not validate invitation token: ", err);
      setFormErrors((prev) => ({
        ...prev,
        invitation_token: "Invitation token is invalid",
      }));
      setLoading(false);
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (role === "student" && type !== "verifying") {
      const isValidToken = await validateInvitationToken(form.invitation_token);
      if (isValidToken) {
        return history.push(
          `${ROUTES.studentsRegister}?token=${form.invitation_token}`
        );
      }
      return;
    }

    const errors = {};

    if (role === "admin") {
      if (!form.first_name) {
        errors.first_name = "First Name is required";
      }

      if (!form.last_name) {
        errors.last_name = "Last Name is required";
      }

      if (!form.email || !isEmail(form.email)) {
        errors.email = "Email is invalid";
      }
    }

    if (!(form.password && validatePassword(form.password))) {
      errors.password =
        "Password must be at least 8 characters and include at least one special character (, e.g., ! @ # ?)";
    }

    if (
      !form.password_confirmation ||
      form.password_confirmation !== form.password
    ) {
      errors.password_confirmation = "Password confirmation does not match";
    }

    setFormErrors(errors);

    // call api
    if (!Object.keys(errors).length) {
      callApiSignUp();
    }
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  return (
    <Form
      form={form}
      formErrors={formErrors}
      onSubmit={handleSubmit}
      onChange={handleChange}
      onFocus={handleFocus}
      isSubmitting={storeSignUp.loading || loading}
      role={role}
      type={type}
      setRole={setRole}
    />
  );
}
