import React from "react";
import { Link } from "react-router-dom";
import triangle from "../../assets/images/triangles1.png";
import banner from "../../assets/images/header-image.png";
import { ROUTES } from "../../utils/constants";

function GetStart() {
  return (
    <section className="get-start">
      <div id="home" className="top-section" />
      <div className="container">
        <div className="get-start__inner">
          <div className="get-start__inner__left">
            <h3>Student Outcomes Reporting Made Easy</h3>
            <p className="text--xlarge">
              We automate student outcomes reporting for Postsecondary Career
              Schools and Bootcamps.
            </p>
            <Link to={ROUTES.signup}>
              <button
                className="button button--primary button--large"
                type="button"
              >
                get started
              </button>
            </Link>
          </div>

          <img src={triangle} alt="" className="triangle" />
        </div>
      </div>
      <div className="get-start__image">
        <img src={banner} alt="" className="header-image" />
      </div>
    </section>
  );
}

export default GetStart;
