import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import type { SurveyInfo } from "../api";
import { useSurveyStore } from "../store";
import {
  Card,
  ButtonArea,
  Button,
  Form,
  StepHeader,
  TextInput,
} from "../components";

type Props = {
  surveyInfo: SurveyInfo;
  progress?: string;
  onNextClick: () => void;
  onBackClick: () => void;
};

const schema = z.object({
  name: z.string().min(1, { message: "Required" }),
  email: z.string().email({ message: "Required" }),
});

type Inputs = z.infer<typeof schema>;

export function NameAndEmailStep(props: Props) {
  const { surveyInfo, progress, onNextClick, onBackClick } = props;
  const [state, setState] = useSurveyStore((store) => [
    store.personalInformation,
    store.setPersonalInformation,
  ]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: state,
    resolver: zodResolver(schema),
  });

  const handleBackClick = () => {
    setState(getValues());
    onBackClick();
  };

  const handleNextClick = async () => {
    await handleSubmit((data) => {
      setState(data);
      onNextClick();
    })();
  };

  return (
    <Card progress={progress}>
      <StepHeader
        surveyInfo={surveyInfo}
        title="What is your name and email?"
      />

      <Form>
        <TextInput
          label="Your Name"
          placeholder="Your Name"
          error={errors?.name?.message}
          {...register("name")}
        />
        <TextInput
          label="Email Address"
          placeholder="Email Address"
          error={errors?.email?.message}
          {...register("email")}
        />
      </Form>

      <ButtonArea>
        <Button label="Back" onClick={handleBackClick} />
        <Button primary label="Continue" onClick={handleNextClick} />
      </ButtonArea>
    </Card>
  );
}
