import { requestClient } from "lib/api";
import qs from "query-string";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import { mapPlacementToStore } from "../mapPlacementToStore";

type QueryParams = {
  token: string;
  placement_id?: string;
};

const PlacementInfoSchema = z.object({
  id: z.number(),
  isSigned: z.boolean(),
  outcome: z.enum([
    "Employed In-Field",
    "Employed In Related Field",
    "Employed Out of Field",
    "Self-Employed",
    "Continuing Education",
    "Military",
    "Not Seeking Employment for Personal Reasons",
    "Still Seeking Employment",
  ]),
  files: z
    .array(
      z.object({
        id: z.number(),
        filename: z.string(),
        url: z.string().url(),
      })
    )
    .optional(),
  employmentPlacement: z
    .object({
      chosePartTime: z.boolean().nullable(),
      duration: z.enum(["Less than 3 months", "3-6 months", "6+ months"]),
      employerName: z.string(),
      employerWebsite: z.string(),
      employerAddress: z.string(),
      employerCity: z.string(),
      employerState: z.string(),
      employerZipCode: z.string(),
      employerPhone: z.string(),
      employerContactName: z.string(),
      employerContactEmail: z.string(),
      employerContactPhone: z.string(),
      hiredBySchool: z.boolean(),
      hoursPerWeek: z.number(),
      jobTitle: z.string(),
      jobType: z.enum(["Single", "Concurrent"]),
      longerThan5Weeks: z.boolean(),
      offerDate: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
      placedBySchool: z.boolean(),
      salary: z.number(),
      salaryType: z.enum(["Annual", "Hourly", "Weekly"]),
      sameEmployer: z.boolean().nullable(),
      sameEmployerCondition: z.boolean().nullable(),
      sameEmployerPromotion: z.boolean().nullable(),
      selfEmployedType: z.enum(["Entrepreneur", "Freelance"]).nullable(),
      socCode: z.string().nullable(),
      startDate: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
    })
    .optional(),
  militaryPlacement: z
    .object({
      branchName: z.enum([
        "Air Force",
        "Army",
        "Coast Guard",
        "Marine Corps",
        "Navy",
        "Space Force",
      ]),
      recruitingOfficeAddress: z.string(),
      recruitingOfficeCity: z.string(),
      recruitingOfficeState: z.string(),
      recruitingOfficeZipCode: z.string(),
      recruitingOfficePhone: z.string(),
    })
    .optional(),
  educationPlacement: z
    .object({
      programName: z.string(),
      institutionName: z.string(),
      institutionAddress: z.string(),
      institutionCity: z.string(),
      institutionState: z.string(),
      institutionZipCode: z.string(),
    })
    .optional(),
  unavailabilityPlacement: z
    .object({
      unavailabilityDate: z.string().regex(/^\d{4}-\d{2}-\d{2}$/),
    })
    .optional(),
  surveyResult: z.object({
    changeRequests: z
      .array(
        z.object({
          id: z.number(),
          notes: z.string(),
          status: z.enum(["pending", "pending_verification", "verified"]),
        })
      )
      .optional(),
  }),
});

export type PlacementInfo = z.infer<typeof PlacementInfoSchema>;

export function usePlacementInfo() {
  const location = useLocation();
  const { token, placement_id: placementId } = qs.parse(
    location.search
  ) as QueryParams;

  const query = useQuery(
    ["placement", token],
    async () => {
      if (!placementId) return undefined;

      const response = await requestClient().get(
        `/surveys/graduate_follow_up/show_placement?token=${token}`
      );
      if (response.data.data === null) return undefined;

      const data = PlacementInfoSchema.parse(response.data.data);
      mapPlacementToStore(data);

      return data;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 1000 * 60 * 60 * 24,
    }
  );

  const isLoading = query.isLoading;
  const isError = query.isError;
  const data = query.data;

  return { isLoading, isError, data };
}
