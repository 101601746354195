import React from "react";
import ReactPaginate from "react-paginate";
import { ArrowLeft2, ArrowRight2 } from "./icons";

function Pagination({
  itemsLength = 0,
  perPage = 10,
  activePage = 1,
  ...props
}) {
  const [windownWidth, setWindownWidth] = React.useState(null);

  React.useEffect(() => {
    const slides = () => {
      setWindownWidth(window.innerWidth);
    };

    window.addEventListener("resize", slides);
    slides();
    return () => window.removeEventListener("resize", slides);
  }, []);

  const pageCount = Math.ceil(itemsLength / perPage);

  if (pageCount === 0) return null;

  return (
    <div className="Pagination">
      <div className="count-page">
        Page {activePage} of {pageCount} -{" "}
        {Number(itemsLength).toLocaleString()} items
      </div>
      <ReactPaginate
        previousLabel={
          <p>
            <ArrowLeft2 /> Back
          </p>
        }
        nextLabel={
          <p>
            Next <ArrowRight2 />
          </p>
        }
        breakLabel="..."
        breakClassName="break-me"
        pageCount={pageCount}
        marginPagesDisplayed={0}
        pageRangeDisplayed={windownWidth > 900 ? 4 : 2}
        containerClassName="pagination"
        subContainerClassName="pages pagination"
        activeClassName="active"
        forcePage={Number(activePage) - 1}
        {...props}
      />
    </div>
  );
}

export default Pagination;
