import axios from "axios";
import applyCaseMiddleware from "axios-case-converter";
import { getHeaders } from "utils/helpers";

export const requestClient = () =>
  applyCaseMiddleware(
    axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        "Content-Type": "application/json",
        ...getHeaders(), // TODO: Need to redo auth
      },
    }),
    {
      caseFunctions: {
        camel: (input) =>
          (input.charAt(0).toLowerCase() + input.slice(1)).replace(
            /[-_](.)/g,
            (_, group1) => group1.toUpperCase()
          ),
      },
    }
  );

export const client = requestClient();

// TODO: Handle broken token redirect back to login

export const api = {
  async get<T>(url: string) {
    const response = await requestClient().get(url);
    return {
      data: response?.data?.data as T,
      headers: response?.headers,
    };
  },
};
