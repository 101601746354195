/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/default-param-last */
import * as types from "../constants";

const initialState = {
  validate: {
    data: {},
    loading: false,
    error: null,
  },
  agencies: {
    data: {},
    loading: false,
    error: null,
  },
  create: {
    data: {},
    loading: false,
    error: null,
  },
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.POST_ONBOARDING_VALIDATE:
      return {
        ...state,
        validate: { ...state.validate, loading: true, error: {} },
      };
    case types.POST_ONBOARDING_VALIDATE_SUCCEED:
      return {
        ...state,
        validate: { ...state.validate, data: payload, loading: false },
      };
    case types.POST_ONBOARDING_VALIDATE_FAIL:
      return {
        ...state,
        validate: { ...state.validate, error: payload, loading: false },
      };
    case types.POST_ONBOARDING_AGENCIES:
      return {
        ...state,
        agencies: { ...state.agencies, loading: true, error: {} },
      };
    case types.POST_ONBOARDING_AGENCIES_SUCCEED:
      return {
        ...state,
        agencies: { ...state.agencies, data: payload, loading: false },
      };
    case types.POST_ONBOARDING_AGENCIES_FAIL:
      return {
        ...state,
        agencies: { ...state.agencies, error: payload, loading: false },
      };
    case types.CREATE_ONBOARDING:
      return {
        ...state,
        create: { ...state.create, loading: true, error: {} },
      };
    case types.CREATE_ONBOARDING_SUCCEED:
      return {
        ...state,
        create: { ...state.create, data: payload, loading: false },
      };
    case types.CREATE_ONBOARDING_FAIL:
      return {
        ...state,
        create: { ...state.create, error: payload, loading: false },
      };
    default:
      return state;
  }
}
