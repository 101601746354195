import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { closeModalMessageDelete } from "../../redux/actions/global";

function ModalMessageDelete() {
  const dispatch = useDispatch();
  const storeModalMessage = useSelector(
    (store) => store.global.modalMessageDelete
  );

  const onCancel = () => {
    dispatch(closeModalMessageDelete());
  };

  return (
    <Modal
      show={storeModalMessage.open}
      onHide={onCancel}
      backdropClassName="wrap-modal"
      dialogClassName="Modal Modal__delete__document"
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="bootstrap">
        <Modal.Header>
          <Modal.Title>Are you sure you wish to delete this item?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <button
            className="button button--back"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="button button--delete"
            type="button"
            onClick={storeModalMessage.data.onDelete}
          >
            Delete
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default ModalMessageDelete;
