import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import FormGroup from "../common/FormGroup";
import Range from "./Range";
import { questionEmail, questionName } from "../../utils/constants";

function Step2({
  formSurvey,
  formErrors,
  onChange,
  onFocus,
  onSubmit,
  handleBack,
  loading,
  schoolData,
}) {
  return (
    <div className=" Step">
      <div className="Step__inner ">
        <div className="form_wrap">
          <Form noValidate onSubmit={onSubmit}>
            <div className="form__inner">
              <div className="Step__inner__header">
                <img
                  src={schoolData.logo}
                  alt=""
                  style={{ display: "inline" }}
                />
                <p>CIRR Student Intent Survey</p>
              </div>
              <div className="Step__inner__body">
                <p className="title">What is your name and email?</p>
                <FormGroup
                  propsInput={{
                    name: questionName,
                    placeholder: "Your Name",
                    onChange,
                    onFocus,
                    value: formSurvey.survey_responses_attributes[questionName],
                    disabled: loading,
                  }}
                  error={formErrors[questionName]}
                  label="Your Name"
                />
                <FormGroup
                  propsInput={{
                    name: questionEmail,
                    placeholder: "Email",
                    onChange,
                    onFocus,
                    value:
                      formSurvey.survey_responses_attributes[questionEmail],
                    disabled: loading,
                  }}
                  error={formErrors[questionEmail]}
                  label="Email"
                />
              </div>
              <div className="button-group">
                <button
                  className="button button--back"
                  type="submit"
                  onClick={handleBack}
                >
                  Back
                </button>
                <button
                  className="button button--primary"
                  type="submit"
                  onSubmit={onSubmit}
                  disabled={loading}
                >
                  Continue
                </button>
              </div>
            </div>
          </Form>
          <Range percent={16} />
        </div>
      </div>
    </div>
  );
}

Step2.propTypes = {
  formSurvey: PropTypes.shape({
    survey_responses_attributes: PropTypes.shape({
      [questionName]: PropTypes.string,
      [questionEmail]: PropTypes.string,
    }),
  }),
  // eslint-disable-next-line react/forbid-prop-types
  schoolData: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

Step2.defaultProps = {
  formErrors: {},
  formSurvey: {
    survey_responses_attributes: {
      [questionName]: "",
      [questionEmail]: "",
    },
  },
  loading: false,
};

export default Step2;
