import React from "react";

export default function () {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.50078 0.300781C14.5818 0.300781 18.7008 4.41976 18.7008 9.50078C18.7008 14.5818 14.5818 18.7008 9.50078 18.7008C4.41976 18.7008 0.300781 14.5818 0.300781 9.50078C0.300781 4.41976 4.41976 0.300781 9.50078 0.300781ZM9.50098 13.0008C8.67255 13.0008 8.00098 13.6724 8.00098 14.5008C8.00098 15.3292 8.67255 16.0008 9.50098 16.0008C10.3294 16.0008 11.001 15.3292 11.001 14.5008C11.001 13.6724 10.3294 13.0008 9.50098 13.0008ZM9.50098 3.00079C8.73172 3.00079 8.09772 3.51551 8.01107 4.17863L8.00098 4.33413V9.66746C8.00098 10.4038 8.67255 11.0008 9.50098 11.0008C10.2702 11.0008 10.9042 10.4861 10.9909 9.82296L11.001 9.66746V4.33413C11.001 3.59775 10.3294 3.00079 9.50098 3.00079Z"
        fill="#F7553F"
      />
    </svg>
  );
}
