import React from "react";
import {
  Error,
  Card,
  ButtonArea,
  Button,
  StepHeader,
  Loading,
} from "../components";
import { useRequiredDocuments, useCreateOrUpdateSurveyPlacement } from "../api";
import { useSurveyStore } from "../store";
import type { SurveyInfo } from "../api";
import css from "./RequiredDocumentsReviewStep.module.scss";

type Props = {
  surveyInfo: SurveyInfo;
  progress?: string;
  onNextClick: () => void;
  onBackClick: () => void;
};

export function RequiredDocumentsReviewStep(props: Props) {
  const { surveyInfo, progress, onNextClick, onBackClick } = props;
  const documents = useRequiredDocuments();
  const survey = useCreateOrUpdateSurveyPlacement();
  const [hasDocumentsToSign] = useSurveyStore((store) => [
    store.hasDocumentsToSign,
  ]);

  if (documents.isLoading || survey.isLoading) return <Loading />;
  if (documents.isError || survey.isError) return <Error />;

  const handleNextClick = async () => {
    await survey.createOrUpdate();
    onNextClick();
  };

  return (
    <Card progress={progress}>
      <StepHeader surveyInfo={surveyInfo} title="You're almost done!" />

      {hasDocumentsToSign && (
        <div>
          <p className={css.notice}>
            Based on your responses, we'd like you to sign the following
            documents:
          </p>

          <ul className={css.list}>
            {documents.data?.map((document) => (
              <li key={document}>{document}</li>
            ))}
          </ul>
        </div>
      )}

      <ButtonArea>
        <Button label="Back" onClick={onBackClick} />
        <Button
          primary
          label={hasDocumentsToSign ? "Review & Sign" : "Complete Survey"}
          onClick={handleNextClick}
        />
      </ButtonArea>
    </Card>
  );
}
