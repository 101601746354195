import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  HeaderImportStudent,
  Step1,
  Step2,
  Step3,
} from "../../../../components/dashboard/institution/integrationSalesforceStep";
import {
  createSalesforceAuthorize,
  createSalesforceMapping,
  getIntegrations,
  getSalesforceAttributes,
  getSalesforceObjects,
  createSalesforceSync,
  createSalesforceBulkMapping,
  getSalesforceMapping,
} from "../../../../redux/actions/integration";
import { openMessage } from "../../../../redux/actions/message";
import { ROUTES } from "../../../../utils/constants";
import { getParam } from "../../../../utils/helpers";

const DEFAULT_FORM_MAP_DATA_TO_PULL = {
  model: "Student",
  integration_model: "",
  mapping: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    education_level: "",
  },
};

const DEFAULT_FORM_MAP_DATA_TO_PUSH = {
  program: {
    model: "Program",
    integration_model: "",
    mapping: {
      id: "",
      name: "",
    },
  },
  branch: {
    model: "Branch",
    integration_model: "",
    mapping: {
      id: "",
      name: "",
      city: "",
      state: "",
    },
  },
  cohort: {
    model: "Cohort",
    integration_model: "",
    mapping: {
      id: "",
      name: "",
      start_date: "",
      graduation_date: "",
      program_id: "",
      branch_id: "",
    },
  },
  enrollment: {
    model: "Enrollment",
    integration_model: "",
    mapping: {
      id: "",
      start_status: "",
      end_status: "",
      start_comments: "",
      end_comments: "",
      cohort_id: "",
      student_id: "",
    },
  },
  placement: {
    model: "Placement",
    integration_model: "",
    mapping: {
      id: "",
      outcome: "",
      program_id: "",
      student_id: "",
    },
  },
};

export default function () {
  const dispatch = useDispatch();
  const history = useHistory();
  const code = getParam("code");
  const [step, setStep] = React.useState(1);
  const [formPullErrors, setFormPullErrors] = React.useState({});
  const [formPushErrors, setFormPushErrors] = React.useState({
    program: {},
    branch: {},
    cohort: {},
    enrollment: {},
    placement: {},
  });
  const [type, setType] = React.useState("pull");
  const [formPull, setFormPull] = React.useState({
    ...DEFAULT_FORM_MAP_DATA_TO_PULL,
    mapping: { ...DEFAULT_FORM_MAP_DATA_TO_PULL.mapping },
  });
  const [formPush, setFormPush] = React.useState({
    ...DEFAULT_FORM_MAP_DATA_TO_PUSH,
  });

  const storeIntegrations = useSelector(
    (store) => store.integration.integrations
  );
  const storeSalesforceObjects = useSelector(
    (store) => store.integration.salesforce.objects
  );
  const storeSalesforceAttributes = useSelector(
    (store) => store.integration.salesforce.attributes
  );
  const storeSalesforceMappings = useSelector(
    (store) => store.integration.salesforce.mappings
  );
  const storeSalesforceBulkMappings = useSelector(
    (store) => store.integration.salesforce.bulkMapping
  );
  const storeSalesforceCreateMapping = useSelector(
    (store) => store.integration.salesforce.mapping
  );
  const storeSalesforceSync = useSelector(
    (store) => store.integration.salesforce.sync
  );

  const isSubmittingMappingPull =
    storeSalesforceCreateMapping.loading ||
    storeSalesforceSync.loading ||
    storeSalesforceAttributes.loading ||
    storeSalesforceMappings.loading;

  const isSubmittingMappingPush =
    storeSalesforceCreateMapping.loading ||
    storeSalesforceSync.loading ||
    storeSalesforceAttributes.loading ||
    storeSalesforceBulkMappings.loading;

  const isLoadingTable =
    storeSalesforceAttributes.loading ||
    storeSalesforceMappings.loading ||
    storeSalesforceCreateMapping.loading;

  const dataObjects = storeSalesforceObjects.data.length
    ? storeSalesforceObjects.data.map((item) => ({
        label: item.label,
        value: item.name,
      }))
    : [];

  const dataAttributes = storeSalesforceAttributes.data;

  React.useEffect(() => {
    dispatch(getIntegrations());
    dispatch(getSalesforceMapping());
  }, []);

  React.useEffect(() => {
    if (storeSalesforceMappings.data && storeSalesforceMappings.data.length) {
      const dataStudent = storeSalesforceMappings.data.filter(
        (item) => item.model === "Student"
      );
      const dataProgram = storeSalesforceMappings.data.filter(
        (item) => item.model === "Program"
      );
      const dataBranch = storeSalesforceMappings.data.filter(
        (item) => item.model === "Branch"
      );
      const dataCohort = storeSalesforceMappings.data.filter(
        (item) => item.model === "Cohort"
      );
      const dataEnrollment = storeSalesforceMappings.data.filter(
        (item) => item.model === "Enrollment"
      );
      const dataPlacement = storeSalesforceMappings.data.filter(
        (item) => item.model === "Placement"
      );
      setFormPull({
        ...formPull,
        integration_model: dataStudent[0].integration_model,
        mapping: dataStudent[0].mapping,
      });
      if (
        dataProgram?.length &&
        dataBranch?.length &&
        dataCohort?.length &&
        dataEnrollment?.length &&
        dataPlacement?.length
      ) {
        setFormPush({
          ...formPush,
          program: {
            model: "Program",
            integration_model: dataProgram[0].integration_model,
            mapping: dataProgram[0].mapping,
          },
          branch: {
            model: "Branch",
            integration_model: dataBranch[0].integration_model,
            mapping: dataBranch[0].mapping,
          },
          cohort: {
            model: "Cohort",
            integration_model: dataCohort[0].integration_model,
            mapping: dataCohort[0].mapping,
          },
          enrollment: {
            model: "Enrollment",
            integration_model: dataEnrollment[0].integration_model,
            mapping: dataEnrollment[0].mapping,
          },
          placement: {
            model: "Placement",
            integration_model: dataPlacement[0].integration_model,
            mapping: dataPlacement[0].mapping,
          },
        });
      }
    }
  }, [storeSalesforceMappings]);

  React.useEffect(async () => {
    if (code) {
      try {
        await dispatch(createSalesforceAuthorize(code));
        dispatch(getSalesforceObjects());
      } catch (err) {
        dispatch(
          openMessage({
            title: "Something went wrong, please try again!",
            type: "error",
          })
        );
        history.push(ROUTES.integrations);
      }
    }
  }, [code]);

  React.useEffect(() => {
    if (formPull.integration_model)
      dispatch(getSalesforceAttributes(formPull.integration_model, "student"));
  }, [formPull.integration_model]);

  React.useEffect(() => {
    if (formPush.program.integration_model)
      dispatch(
        getSalesforceAttributes(formPush.program.integration_model, "program")
      );
    if (formPush.branch.integration_model)
      dispatch(
        getSalesforceAttributes(formPush.branch.integration_model, "branch")
      );
    if (formPush.cohort.integration_model)
      dispatch(
        getSalesforceAttributes(formPush.cohort.integration_model, "cohort")
      );
    if (formPush.enrollment.integration_model)
      dispatch(
        getSalesforceAttributes(
          formPush.enrollment.integration_model,
          "enrollment"
        )
      );
    if (formPush.placement.integration_model)
      dispatch(
        getSalesforceAttributes(
          formPush.placement.integration_model,
          "placement"
        )
      );
  }, [
    formPush.program.integration_model,
    formPush.branch.integration_model,
    formPush.cohort.integration_model,
    formPush.enrollment.integration_model,
    formPush.placement.integration_model,
  ]);

  React.useEffect(() => {
    if (
      storeIntegrations.data &&
      storeIntegrations.data.length &&
      storeIntegrations.data.some((item) => item.is_type === "salesforce")
    )
      dispatch(getSalesforceObjects());
  }, [storeIntegrations]);

  const handleSubmitMapDataToPull = async (event) => {
    event.preventDefault();
    const errors = {};
    if (!formPull.integration_model) {
      errors.integration_model = "Field not found";
    }
    if (!formPull.mapping.first_name) {
      errors.first_name = "Field not found";
    }
    if (!formPull.mapping.last_name) {
      errors.last_name = "Field not found";
    }
    if (!formPull.mapping.email) {
      errors.email = "Field not found";
    }
    if (!formPull.mapping.phone) {
      errors.phone = "Field not found";
    }
    if (!formPull.mapping.address) {
      errors.address = "Field not found";
    }
    if (!formPull.mapping.city) {
      errors.city = "Field not found";
    }
    if (!formPull.mapping.state) {
      errors.state = "Field not found";
    }
    if (!formPull.mapping.zip_code) {
      errors.zip_code = "Field not found";
    }

    setFormPullErrors(errors);

    // call api
    if (Object.keys(errors).length) return null;
    try {
      await dispatch(createSalesforceMapping(formPull));
      setStep(step + 1);
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleSubmitMapDataToPush = async (event) => {
    event.preventDefault();
    const errors = {
      program: {},
      branch: {},
      cohort: {},
      enrollment: {},
      placement: {},
    };
    if (!formPush.program.integration_model) {
      errors.program.integration_model = "Field not found";
    }
    if (!formPush.branch.integration_model) {
      errors.branch.integration_model = "Field not found";
    }
    if (!formPush.branch.mapping.city) {
      errors.branch.city = "Field not found";
    }
    if (!formPush.branch.mapping.state) {
      errors.branch.state = "Field not found";
    }
    if (!formPush.cohort.integration_model) {
      errors.cohort.integration_model = "Field not found";
    }
    if (!formPush.enrollment.integration_model) {
      errors.enrollment.integration_model = "Field not found";
    }
    if (!formPush.placement.integration_model) {
      errors.placement.integration_model = "Field not found";
    }

    setFormPushErrors(errors);
    // call api
    if (
      Object.keys(errors.program).length ||
      Object.keys(errors.branch).length ||
      Object.keys(errors.cohort).length ||
      Object.keys(errors.enrollment).length ||
      Object.keys(errors.placement).length
    )
      return null;
    try {
      await dispatch(createSalesforceBulkMapping(formPush));
      setStep(step + 1);
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleSubmitSync = async (event) => {
    event.preventDefault();
    try {
      const response = await dispatch(createSalesforceSync());
      history.push(ROUTES.dataSyncSalesforce, response);
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setStep(step + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
    setFormPullErrors({});
    setFormPushErrors({});
  };

  const handleChangeDataToPull = (name) => (item) => {
    const selectedItem = item[0] || { value: "" };
    if (name === "integration_model") {
      return setFormPull((prev) => {
        if (prev.integration_model !== selectedItem.value) {
          return {
            ...prev,
            mapping: { ...DEFAULT_FORM_MAP_DATA_TO_PULL.mapping },
            [name]: selectedItem.value,
          };
        }
        return {
          ...prev,
          [name]: selectedItem.value,
        };
      });
    }
    setFormPull({
      ...formPull,
      mapping: {
        ...formPull.mapping,
        [name]: selectedItem.value,
      },
    });
  };

  const handleChangeDataToPush = (name, model) => (item) => {
    const selectedItem = item[0] || { value: "" };
    if (name === "integration_model") {
      setFormPush({
        ...formPush,
        [model]: {
          ...formPush[model],
          [name]: selectedItem.value,
        },
      });
    } else
      setFormPush({
        ...formPush,
        [model]: {
          ...formPush[model],
          mapping: {
            ...formPush[model].mapping,
            [name]: selectedItem.value,
          },
        },
      });
  };

  const handleSelectTypePull = () => {
    setType("pull");
  };

  const handleSelectTypePullAndPush = () => {
    setType("pull and push");
  };

  return (
    <div className="Import-student">
      <HeaderImportStudent step={step} type={type} />
      <div className="Survey-steps__inner">
        {step === 1 && (
          <Step1
            onSubmit={handleSubmit}
            type={type}
            onSelectTypePull={handleSelectTypePull}
            onSelectTypePullAndPush={handleSelectTypePullAndPush}
          />
        )}
        {step === 2 && (
          <Step2
            onSubmit={handleSubmitMapDataToPull}
            handleBack={handleBack}
            form={formPull}
            onChange={handleChangeDataToPull}
            dataObjects={dataObjects}
            dataAttributes={dataAttributes}
            error={formPullErrors}
            isSubmitting={isSubmittingMappingPull}
            isLoadingTable={isLoadingTable}
            step={step}
          />
        )}
        {step === 3 && type === "pull" ? (
          <Step2
            onSubmit={handleSubmitSync}
            handleBack={handleBack}
            form={formPull}
            onChange={handleChangeDataToPull}
            error={formPullErrors}
            dataObjects={dataObjects}
            dataAttributes={dataAttributes}
            isSubmitting={isSubmittingMappingPull}
            isLoadingTable={isLoadingTable}
            step={step}
          />
        ) : (
          step === 3 && (
            <Step3
              onSubmit={handleSubmitMapDataToPush}
              handleBack={handleBack}
              formPull={formPull}
              formPush={formPush}
              onChange={handleChangeDataToPush}
              error={formPushErrors}
              dataObjects={dataObjects}
              dataAttributes={dataAttributes}
              isLoadingTable={isLoadingTable}
              isSubmitting={isSubmittingMappingPush}
              step={step}
            />
          )
        )}
        {step === 4 && (
          <Step3
            onSubmit={handleSubmitSync}
            handleBack={handleBack}
            formPull={formPull}
            formPush={formPush}
            onChange={handleChangeDataToPush}
            error={formPushErrors}
            dataObjects={dataObjects}
            dataAttributes={dataAttributes}
            isSubmitting={isSubmittingMappingPush}
            isLoadingTable={isLoadingTable}
            step={step}
          />
        )}
      </div>
    </div>
  );
}
