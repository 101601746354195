import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../utils/constants";
import {
  FormGroup,
  SingleDatePicker,
  SingleSelectCheckbox,
} from "../../common";

function FormAddCohorts({
  form,
  formErrors,
  onSubmit,
  onChange,
  onFocus,
  handleChangeProgram,
  handleChangeBranch,
  handleChangeType,
  handleCallbackStartDate,
  handleCallbackGraduationtDate,
  program,
  branch,
  type,
  // eslint-disable-next-line react/prop-types
  dataPrograms,
  // eslint-disable-next-line react/prop-types
  dataBranchs,
  isEditing,
  isSubmitting,
}) {
  // eslint-disable-next-line react/prop-types
  const OPTIONS_PROGRAM = dataPrograms.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  // eslint-disable-next-line react/prop-types
  const OPTIONS_BRANCH = dataBranchs.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const OPTIONS_TYPE = [
    { value: "Full-time", label: "Full time" },
    { value: "Part-time", label: "Part time" },
  ];

  const history = useHistory();
  const handleBack = () => {
    history.push(ROUTES.cohorts);
  };

  return (
    <div className="Add-Cohort  dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="Add-Cohort__text">
          <p className="text--xlarge bold">
            {isEditing ? "Update Cohort" : "Add Cohort"}
          </p>
        </div>
        <div className="Add-Cohort__inner">
          <FormGroup
            propsInput={{
              name: "name",
              placeholder: "Cohort Name",
              onChange,
              onFocus,
              value: form.name,
              disabled: isSubmitting,
            }}
            error={formErrors.name}
            label="Cohort Name"
          />
          <div className="select">
            <p>Program</p>
            <SingleSelectCheckbox
              onChange={handleChangeProgram}
              name="program"
              options={OPTIONS_PROGRAM}
              value={program}
              error={formErrors.program_id}
              disabled={isSubmitting}
            />
          </div>
          <div className="select">
            <p>Branch</p>
            <SingleSelectCheckbox
              onChange={handleChangeBranch}
              name="branch"
              options={OPTIONS_BRANCH}
              value={branch}
              error={formErrors.branch_id}
              disabled={isSubmitting}
            />
          </div>
          <div className="select">
            <p>Type</p>
            <SingleSelectCheckbox
              onChange={handleChangeType}
              name="type"
              options={OPTIONS_TYPE}
              value={type}
              error={formErrors.is_type}
              disabled={isSubmitting}
            />
          </div>

          <div className="Date-picker">
            <p className="Date-picker__label">Start Date</p>
            <SingleDatePicker
              placeholder="Start Date"
              value={form.start_date}
              onChange={handleCallbackStartDate}
              disabled={isSubmitting}
            />
          </div>
          <div className="Date-picker">
            <p className="Date-picker__label">Graduation Date</p>
            <SingleDatePicker
              placeholder="Graduation Date"
              value={form.graduation_date}
              onChange={handleCallbackGraduationtDate}
              minDate={form.start_date}
              showDisabledMonthNavigation
              disabled={isSubmitting}
            />
          </div>
        </div>
        <div className="button-group">
          <button
            className="button button--back"
            type="submit"
            onClick={handleBack}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
}

FormAddCohorts.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    name: PropTypes.string,
    branch_id: PropTypes.number,
    program_id: PropTypes.number,
    is_type: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    start_date: PropTypes.any,
    // eslint-disable-next-line react/forbid-prop-types
    graduation_date: PropTypes.any,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  program: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  branch: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  type: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  handleChangeProgram: PropTypes.func.isRequired,
  handleChangeBranch: PropTypes.func.isRequired,
  handleChangeType: PropTypes.func.isRequired,
  handleCallbackStartDate: PropTypes.func.isRequired,
  handleCallbackGraduationtDate: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

FormAddCohorts.defaultProps = {
  form: {
    name: "",
    branch_id: null,
    program_id: null,
    is_type: "",
    start_date: "",
    graduation_date: "",
  },
  formErrors: {},
  program: {},
  branch: {},
  type: {},
  isEditing: false,
  isSubmitting: false,
};

export default FormAddCohorts;
