import React from "react";
import CollapseQuestion from "./CollapseQuestion";

const data = [
  {
    question: "Who has access to the data in the tool?",
    answer:
      "All data in the tool is private. Only authorized personnel at the school have access to data in our system. In our roles and permissions section, schools can choose to build an even more granular access control system for the student data in the application.",
  },
  {
    question:
      "How will I transfer data to the tool if I’m currently using a CRM or another student information system?",
    answer:
      "We will be offering a host of integrations with popular services that career schools use as their CRM such as Salesforce, Hubspot, and more. If your CRM is not supported, we offer a bulk import tool that will help schools easily transfer their existing data.",
  },
  {
    question:
      "Is there also an option manually send out Graduate Follow-Up surveys to an individual student?",
    answer:
      "Yes! We know there might be cases where a student has reached out to an administrator on the phone or via email and the staff member would need to document that information immediately. With the click of a button, administrators can send the Graduate Follow-Up survey to an individual student and collect outcome information instantly.",
  },
  {
    question:
      "Would your tool be able to export reports based on various agency standards?",
    answer:
      "Yes! For each of our exported reports, we use the published standards and measures of that particular state and accreditation agency. That is the true power of AcademySync's Reporting Tool: we are able to collect uniform data and generate custom reports based on each agency's standards.",
  },
  {
    question: "What state and accreditation agencies are on your roadmap?",
    answer:
      "It is in our roadmap to support each private postsecondary state regulatory agency in the country, with our first version supporting Texas (TWC), California (BPPE), New York (BPSS), and Colorado (DPOS). It is also on our roadmap to support each of the major accreditation agencies that work with Career Schools and Bootcamps, such as ACCET, COE, and others.",
  },
];

function Questions() {
  return (
    <section className="Questions">
      <div className="container">
        <div className="Questions__inner ">
          <h4>Frequently Asked Questions</h4>
          <div className="list-question">
            {data.map((item) => (
              <CollapseQuestion item={item} key={item.question} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Questions;
