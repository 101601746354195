import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { changeURLWithoutReload } from "../../../utils/helpers";
import { ProgramsTable } from "../../../components/dashboard/programs";
import { deleteProgram, getPrograms } from "../../../redux/actions/programs";
import { ROUTES, SEARCH_NAME, SORT_NAME } from "../../../utils/constants";
import { openMessage } from "../../../redux/actions/message";
import {
  closeModalMessageDelete,
  openModalMessageDelete,
} from "../../../redux/actions/global";

function Root() {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [params, setParams] = React.useState({});
  const [isParsing, setIsParsing] = React.useState(true);
  const storePrograms = useSelector((store) => store.programs);
  const storeDelete = storePrograms.delete;
  const storeList = storePrograms.list;

  const handleGetPrograms = () => {
    dispatch(getPrograms(params));
  };

  // only run for the first time visit page
  React.useEffect(() => {
    setParams(queryString.parse(location.search));
    setIsParsing(false);
  }, [location.search]);

  React.useEffect(() => {
    if (isParsing) return;
    handleGetPrograms();
  }, [params, isParsing]);

  const handleChangeURL = (newParams) => {
    changeURLWithoutReload(
      location.pathname,
      `${location.pathname}?${queryString.stringify(newParams)}`
    );
  };

  const handlePageChange = (e) => {
    const newParams = { ...params, page: e.selected + 1 };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleSort = (column, direction) => {
    const newParams = {
      ...params,
      [SORT_NAME]: `${column} ${direction}`,
      page: 1,
    };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleSearch = (e) => {
    const newParams = {
      ...params,
      page: 1,
      [SEARCH_NAME]: e.target.value,
    };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleDelete = (rowData) => async () => {
    try {
      await dispatch(deleteProgram(rowData.id));
      dispatch(
        openMessage({
          title: `${rowData.name} has been deleted.`,
          type: "success",
        })
      );
      dispatch(closeModalMessageDelete());
      if (storeList.data.length === 1 && params.page > 1) {
        setParams({ ...params, page: params.page - 1 });
      }
      handleGetPrograms();
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleToggleModalDelete = (rowData) => () => {
    dispatch(
      openModalMessageDelete({
        onDelete: handleDelete(rowData),
      })
    );
  };

  const handleEdit = (selectedItem) => () => {
    history.push(ROUTES.addPrograms, selectedItem);
  };

  return (
    <ProgramsTable
      data={storeList}
      loading={storeList.loading || storeDelete.loading}
      onPageChange={handlePageChange}
      params={params}
      onSort={handleSort}
      onSearch={handleSearch}
      onDelete={handleToggleModalDelete}
      onEdit={handleEdit}
    />
  );
}

export default Root;
