import React from "react";
import { Collapse } from "react-bootstrap";
import { ArrowDown, ArrowUp } from "../common/icons";

function CollapseQuestion({ item = {} }) {
  const [open, setOpen] = React.useState(false);
  return (
    <div className="CollapseQuestion">
      <div
        onClick={() => setOpen(!open)}
        role="button"
        tabIndex="0"
        className="CollapseQuestion__button"
      >
        <p className="text--xlarge bold">{item.question}</p>
        <div className="iconArrow">{open ? <ArrowUp /> : <ArrowDown />}</div>
      </div>
      <Collapse in={open}>
        <div className="CollapseQuestion__answer">
          <p className="text">{item.answer}</p>
        </div>
      </Collapse>
    </div>
  );
}

export default CollapseQuestion;
