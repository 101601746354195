import React from "react";

export default function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      viewBox="0 0 576 512"
    >
      <path
        d="M551.1 312.1c-31.13-26.36-69.26-16.12-88.39-1.75l-60.38 45.47h-3.375C399.7 317.9 369.3 288 330.7 288H186.7C158.3 288 130.3 297.4 108.1 314.4l-28.38 21.74H24C10.8 336.1 0 346.9 0 360.1c0 13.19 10.8 23.99 24 23.99l72-.0596l41.26-31.42c14-10.74 31.5-16.49 49.51-16.49h144c27.88 0 29 40.23-1.125 40.23H269.8c-7.5 0-13.75 6.123-13.75 13.74c0 7.619 6.252 13.87 13.75 13.87h134.6c9.711 0 19.16-3.158 26.92-8.996l61.26-46.1c8.252-6.121 20.5-6.621 28.38 0c10.12 8.496 9.375 23.11-.877 30.73l-100.6 75.46c-7.75 5.746-17.13 8.994-26.88 8.994H24C10.8 464 0 474.8 0 488C0 501.2 10.8 512 24 512h368.8c20 0 39.38-6.496 55.26-18.49l100.8-75.83c16.63-12.49 26.5-31.48 27.13-51.97C576.7 345.2 567.8 325.6 551.1 312.1zM279.3 175C271.7 173.9 261.7 170.3 252.9 167.1L248 165.4C235.5 161 221.8 167.5 217.4 179.1C212.1 192.5 219.5 206.2 231.1 210.6l4.655 1.656c8.469 3.057 17.9 6.093 27.39 8.312V232c0 13.25 10.75 24 23.98 24c13.24 0 24-10.75 24-24V221.6c25.26-5.715 42.88-21.84 46.1-45.72c8.682-50.05-38.91-63.66-64.42-70.95L288.4 103.1C263 95.64 263.6 92.42 264.3 88.31c1.154-6.766 15.35-10.08 32.21-7.391c4.932 .7813 11.38 2.547 19.65 5.422c12.55 4.344 26.18-2.328 30.52-14.84c4.309-12.53-2.318-26.19-14.84-30.53c-7.607-2.631-13.91-4.356-19.82-5.721V24c0-13.25-10.76-24-24-24c-13.24 0-23.98 10.75-23.98 24v10.52C238.8 40.23 221.1 56.26 216.1 80.13C208.4 129.6 256.7 143.8 274.9 149.2l6.498 1.875c31.63 9.062 31.15 11.89 30.34 16.64C310.6 174.4 296.4 177.7 279.3 175z"
        fill="white"
      />
    </svg>
  );
}
