import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getAllRoles } from "../../../../redux/actions/institution";
import { AddStudent } from "../../../../components/dashboard/students";
import {
  formatDateOfPicker,
  formatPhoneNumber,
  handleErrorsAfterCallingAPI,
  replaceParamStudent,
} from "../../../../utils/helpers";
import { componentForm, ROUTES } from "../../../../utils/constants";
import { updateStudent } from "../../../../redux/actions/students";
import { openMessage } from "../../../../redux/actions/message";

const DEFAULT_FORM_STUDENT = {
  first_name: "",
  last_name: "",
  dob: new Date(),
  social_security_number: "",
  email: "",
  phone: "",
  education_level: "",
  gender: "",
  ethnicity: "",
  city: "",
  state: "",
  zip_code: "",
};

export default function ({ selectedStudent }) {
  const history = useHistory();

  const [form, setForm] = React.useState({
    ...DEFAULT_FORM_STUDENT,
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [educationLevel, setEducationLevel] = React.useState({});
  const [ethnicity, setEthnicity] = React.useState({});
  const [address, setAddress] = React.useState("");
  const dispatch = useDispatch();
  const storeCreate = useSelector((store) => store.students.create);
  const storeUpdate = useSelector((store) => store.students.update);
  const { studentId } = useParams();

  const isSubmitting = storeCreate.loading || storeUpdate.loading;

  React.useEffect(() => {
    dispatch(getAllRoles());
  }, []);

  React.useEffect(() => {
    if (!selectedStudent) return;
    setForm({
      ...selectedStudent,
      dob: selectedStudent.dob ? selectedStudent.dob : new Date(),
    });
    setAddress(selectedStudent.address);
    if (selectedStudent.education_level) {
      setEducationLevel({
        value: selectedStudent.education_level,
        label: selectedStudent.education_level,
      });
    }
    if (selectedStudent.ethnicity) {
      setEthnicity({
        value: selectedStudent.ethnicity,
        label: selectedStudent.ethnicity,
      });
    }
  }, [selectedStudent]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.first_name) {
      errors.first_name = "First Name is required";
    }
    if (!form.last_name) {
      errors.last_name = "Last Name is required";
    }
    // if (!form.phone) {
    //   errors.phone = 'Phone is required';
    // }
    // if (!form.email || !isEmail(form.email)) {
    //   errors.email = "Email is required";
    // }
    // if (!form.city) {
    //   errors.city = 'City is required';
    // }
    // if (!form.state) {
    //   errors.state = 'State is required';
    // }
    setFormErrors(errors);

    // call api
    if (Object.keys(errors).length) return null;

    try {
      const res = await dispatch(
        updateStudent(
          { ...form, dob: formatDateOfPicker(form.dob) },
          selectedStudent.id
        )
      );
      dispatch(
        openMessage({
          title: "Student has been updated",
        })
      );
      history.push(
        replaceParamStudent(ROUTES.student, selectedStudent.id),
        res?.data?.data
      );
    } catch (err) {
      handleErrorsAfterCallingAPI(
        err,
        DEFAULT_FORM_STUDENT,
        setFormErrors,
        dispatch
      );
    }
  };

  const handleChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangePhoneNumber = (e) => {
    const phone = e.target.value;
    if (phone.length > 14) {
      return;
    }
    if (phone.length === 4) {
      return setForm({ ...form, phone });
    }
    setForm({ ...form, phone: formatPhoneNumber(phone) });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  const handleCallbackDob = (date) => {
    setForm({ ...form, dob: date });
  };

  const handleChangeEducationLevel = (selectedItem) => {
    setEducationLevel(selectedItem);
    setForm({
      ...form,
      education_level: selectedItem.value,
    });
  };

  const handleChangeEthnicity = (selectedItem) => {
    setEthnicity(selectedItem);
    setForm({
      ...form,
      ethnicity: selectedItem.value,
    });
  };

  const handleChangePlace = (place) => {
    if (!place.address_components || !place.address_components.length) {
      return;
    }

    const newForm = { ...form };
    let street = "";
    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    place.address_components.forEach((component) => {
      const addressType = component.types[0];
      switch (addressType) {
        case "postal_code":
          newForm.zip_code = component[componentForm[addressType]];
          break;
        case "administrative_area_level_1":
          newForm.state = component[componentForm[addressType]];
          break;
        case "locality":
          newForm.city = component[componentForm[addressType]];
          break;
        case "street_number":
          street = `${component[componentForm[addressType]]} ${street}`;
          newForm.address = component[componentForm[addressType]];
          break;
        case "route":
          street = `${street} ${component[componentForm[addressType]]}`;
          break;
        default:
          break;
      }
    });
    setAddress(street);
    setForm({
      ...newForm,
    });
  };

  const handleFocusPlace = () => {
    setFormErrors({
      ...formErrors,
      city: "",
      state: "",
    });
  };

  const handleChangeAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleCheckboxGender = (e) => {
    setForm({ ...form, gender: e.target.value });
  };

  const handleBack = () => {
    history.push(replaceParamStudent(ROUTES.student, studentId));
  };

  return (
    <AddStudent
      form={form}
      formErrors={formErrors}
      onSubmit={handleSubmit}
      onChange={handleChange}
      handleChangePhoneNumber={handleChangePhoneNumber}
      onFocus={handleFocus}
      handleCallbackDob={handleCallbackDob}
      handleChangeEducationLevel={handleChangeEducationLevel}
      handleChangeEthnicity={handleChangeEthnicity}
      educationLevel={educationLevel}
      ethnicity={ethnicity}
      isSubmitting={isSubmitting}
      handleChangePlace={handleChangePlace}
      handleFocusPlace={handleFocusPlace}
      handleChangeAddress={handleChangeAddress}
      address={address}
      handleCheckbox={handleCheckboxGender}
      handleBack={handleBack}
      textTitle="Edit Student Information"
      textButton="Save"
    />
  );
}
