import React from "react";

export default function () {
  return (
    <svg
      width="10"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.49999 0.599609L8.09999 5.39961H0.899994L4.49999 0.599609Z"
        fill="#0199F2"
      />
    </svg>
  );
}
