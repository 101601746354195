import React from "react";
import cn from "classnames/bind";
import css from "./styles.module.scss";

const cx = cn.bind(css);

type Props = {
  children: ReactNode;
  single?: boolean;
};

export function ButtonArea(props: Props) {
  const { children } = props;
  const single = props.single ?? false;

  return <div className={cx("container", { single })}>{children}</div>;
}
