import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Form } from "../../../components/dashboard/financialAidPrograms";
import { openMessage } from "../../../redux/actions/message";
import {
  createFinancialAidProgram,
  updateFinancialAidProgram,
} from "../../../redux/actions/financialAidPrograms";
import { ROUTES } from "../../../utils/constants";
import { handleErrorsAfterCallingAPI } from "../../../utils/helpers";

const DEFAULT_FORM_ADD_FINANCIAL_AID_PROGRAM = {
  name: "",
};

export default function () {
  const [form, setForm] = React.useState({
    ...DEFAULT_FORM_ADD_FINANCIAL_AID_PROGRAM,
  });
  const [formErrors, setFormErrors] = React.useState({});
  const [type, setType] = React.useState({});
  const [state, setState] = React.useState({});
  const [showState, setShowState] = React.useState(false);
  const storeCreate = useSelector((store) => store.financialAidPrograms.create);
  const storeUpdate = useSelector((store) => store.financialAidPrograms.update);
  const dispatch = useDispatch();
  const history = useHistory();
  const isEditing = !!history.location.state;

  const isSubmitting = storeCreate.loading || storeUpdate.loading;

  React.useEffect(() => {
    const programSelected = history.location.state;
    if (!programSelected) return;
    setForm({
      name: programSelected.name,
      is_type: programSelected.is_type,
      state: programSelected.state,
    });
    setType({ value: programSelected.is_type, label: programSelected.is_type });
    setState({ value: programSelected.state, label: programSelected.state });
    setShowState(programSelected.is_type === "state");
  }, [history.location.state]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.name) {
      errors.name = "Name is required";
    }

    if (!form.is_type) {
      errors.is_type = "Type is required";
    }

    if (form.is_type === "state" && form.state === undefined) {
      errors.state = "State is required";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length) return null;

    // update program
    if (isEditing) {
      try {
        const formData = { ...form };

        await dispatch(
          updateFinancialAidProgram(formData, history.location.state.id)
        );
        dispatch(
          openMessage({
            title: "Your Financial Aid Program has been updated",
            type: "success",
          })
        );
        history.push(ROUTES.financialAidPrograms);
      } catch (err) {
        handleErrorsAfterCallingAPI(
          err,
          DEFAULT_FORM_ADD_FINANCIAL_AID_PROGRAM,
          setFormErrors,
          dispatch
        );
      }
    } else {
      // create program
      try {
        const formData = { ...form };

        await dispatch(createFinancialAidProgram(formData));
        dispatch(
          openMessage({
            title: "Your Financial Aid Program has been created",
            type: "success",
          })
        );
        history.push(ROUTES.financialAidPrograms);
      } catch (err) {
        handleErrorsAfterCallingAPI(
          err,
          DEFAULT_FORM_ADD_FINANCIAL_AID_PROGRAM,
          setFormErrors,
          dispatch
        );
      }
    }
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleChangeType = (selectedItem) => {
    setType(selectedItem);

    if (selectedItem.value === "state") {
      setShowState(true);
      setForm({ ...form, is_type: selectedItem.value });
    } else {
      setShowState(false);

      // Also reset state selection if type is not state
      setState({ value: null, label: null });
      setForm({ ...form, is_type: selectedItem.value, state: null });
    }
  };

  const handleChangeState = (selectedItem) => {
    setState(selectedItem);
    setForm({ ...form, state: selectedItem.value });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  return (
    <Form
      form={form}
      type={type}
      state={state}
      showState={showState}
      formErrors={formErrors}
      onSubmit={handleSubmit}
      onChange={handleChange}
      onFocus={handleFocus}
      handleChangeType={handleChangeType}
      handleChangeState={handleChangeState}
      isSubmitting={isSubmitting}
      isEditing={isEditing}
    />
  );
}
