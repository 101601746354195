import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { changeURLWithoutReload, getHostUrl } from "../../../utils/helpers";
import {
  CohortsTable,
  ModalSendSurvey,
} from "../../../components/dashboard/cohorts";
import { getUsers } from "../../../redux/actions/users";
import { deleteCohort, getCohorts } from "../../../redux/actions/cohorts";
import {
  GraduateFollowUp,
  ROUTES,
  SEARCH_NAME,
  SORT_NAME,
} from "../../../utils/constants";
import { openMessage } from "../../../redux/actions/message";
import {
  closeModalMessageDelete,
  openModalMessageDelete,
} from "../../../redux/actions/global";
import { sendSurvey } from "../../../redux/actions/surveys";

const FORM_SURVEY = {
  is_type: "",
  cohort_id: "",
  user_id: "",
};

function Root() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [params, setParams] = React.useState({});
  const [isParsing, setIsParsing] = React.useState(true);
  const [openModalSendSurvey, setOpenModalSendSurvey] = React.useState(false);
  const [type, setType] = React.useState({
    value: GraduateFollowUp,
    label: GraduateFollowUp,
  });
  const [verifyingAdmin, setVerifyingAdmin] = React.useState({});
  const storeCohorts = useSelector((store) => store.cohorts.list);
  const [formSurvey, setFormSurvey] = React.useState({ ...FORM_SURVEY });
  const history = useHistory();
  const storeSurvey = useSelector((store) => store.surveys.surveyStudent);
  const storeUsers = useSelector((store) => store.users);

  const isSubmitting = storeSurvey.loading;

  const handleGetCohorts = () => {
    dispatch(getCohorts(params));
  };

  // only run for the first time visit page
  React.useEffect(() => {
    setParams(queryString.parse(location.search));
    setIsParsing(false);
  }, [location.search]);

  React.useEffect(() => {
    if (isParsing) return;
    dispatch(getCohorts(params));
  }, [params, isParsing]);

  const handleChangeURL = (newParams) => {
    changeURLWithoutReload(
      location.pathname,
      `${location.pathname}?${queryString.stringify(newParams)}`
    );
  };

  const handlePageChange = (e) => {
    const newParams = { ...params, page: e.selected + 1 };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleSort = (column, direction) => {
    const newParams = {
      ...params,
      [SORT_NAME]: `${column} ${direction}`,
      page: 1,
    };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleSearch = (e) => {
    const newParams = {
      ...params,
      page: 1,
      [SEARCH_NAME]: e.target.value,
    };
    setParams(newParams);
    handleChangeURL(newParams);
  };

  const handleDelete = (rowData) => async () => {
    try {
      await dispatch(deleteCohort(rowData.id));
      dispatch(
        openMessage({
          title: `${rowData.name} has been deleted.`,
          type: "success",
        })
      );
      dispatch(closeModalMessageDelete());
      if (storeCohorts.data.length === 1 && params.page > 1) {
        setParams({ ...params, page: params.page - 1 });
      }
      handleGetCohorts();
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleToggleModalDelete = (rowData) => () => {
    dispatch(
      openModalMessageDelete({
        onDelete: handleDelete(rowData),
      })
    );
  };

  const handleEdit = (selectedItem) => () => {
    history.push(ROUTES.addCohorts, selectedItem);
  };

  const handleClone = (selectedItem) => async () => {
    const newCohort = {
      ...selectedItem,
      id: null,
      name: `${selectedItem.name} - Clone`,
    };
    history.push(ROUTES.addCohorts, newCohort);
  };

  const handleChangeType = (selectedItem) => {
    setType(selectedItem);
    setFormSurvey({ ...formSurvey, is_type: selectedItem.value });
  };

  const handleChangeVerifyingAdmin = (selectedItem) => {
    setVerifyingAdmin(selectedItem);
    setFormSurvey({ ...formSurvey, user_id: selectedItem.value });
  };

  const handleHideModal = () => {
    setOpenModalSendSurvey(false);
    setFormSurvey({ ...FORM_SURVEY });
    setType({});
  };

  const handleSendSurvey = (rowData) => () => {
    setFormSurvey({
      ...formSurvey,
      cohort_id: rowData.id,
      is_type: type?.value,
    });
    dispatch(getUsers());
    setOpenModalSendSurvey(true);
  };

  const handleSendStudentRegisterForm = (rowData) => () => {
    navigator.clipboard.writeText(
      `${getHostUrl()}${ROUTES.studentsRegister}?token=${rowData.token} `
    );
    dispatch(
      openMessage({
        title: "Student Registration Link Copied to Clipboard",
        type: "success",
      })
    );
  };

  const handleCopyInvitationToken = (rowData) => () => {
    navigator.clipboard.writeText(rowData.token);
    dispatch(
      openMessage({
        title: "Invitation Token Copied to Clipboard",
        type: "success",
      })
    );
  };

  const handleCreateSurvey = async () => {
    try {
      await dispatch(sendSurvey(formSurvey));

      setOpenModalSendSurvey(false);
      dispatch(
        openMessage({
          title: "Survey has been sent",
          type: "success",
        })
      );
      setFormSurvey({ ...FORM_SURVEY });
    } catch (error) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  // Used to set the default verifying admin to the current user
  React.useEffect(() => {
    if (storeUsers.data.length === 0) return;

    const currentUser = storeUsers.data.find((user) => user.current_user);
    handleChangeVerifyingAdmin({
      value: currentUser.id,
      label: currentUser.full_name,
    });
  }, [storeUsers]);

  return (
    <>
      <CohortsTable
        storeCohorts={storeCohorts}
        onPageChange={handlePageChange}
        params={params}
        onSort={handleSort}
        onSearch={handleSearch}
        onDelete={handleToggleModalDelete}
        onEdit={handleEdit}
        onClone={handleClone}
        onSendSurvey={handleSendSurvey}
        onSendStudentRegisterForm={handleSendStudentRegisterForm}
        onCopyInvitationToken={handleCopyInvitationToken}
      />

      <ModalSendSurvey
        open={openModalSendSurvey}
        onHide={handleHideModal}
        onSend={handleCreateSurvey}
        onChangeType={handleChangeType}
        onChangeVerifyingAdmin={handleChangeVerifyingAdmin}
        verifyingAdmin={verifyingAdmin}
        dataAdmins={storeUsers.data}
        formSurvey={formSurvey}
        type={type}
        isSubmitting={isSubmitting}
      />
    </>
  );
}

export default Root;
