import React from "react";
import { Form } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../utils/constants";
import { FormGroup } from "../../common";

function FormAddBranch({
  form,
  handleCheckbox,
  formErrors,
  onSubmit,
  onChange,
  onFocus,
  handleChangePlace,
  handleFocusPlace,
  handleChangeAddress,
  isEditing,
  isSubmitting,
}) {
  const history = useHistory();

  const handleBack = () => {
    history.push(ROUTES.branches);
  };

  return (
    <div className="Add-branch  dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="Add-branch__text">
          <p className="text--xlarge bold">
            {isEditing ? "Update Branch" : "Add Branch"}
          </p>
        </div>
        <div className="Add-branch__inner">
          <FormGroup
            propsInput={{
              name: "name",
              placeholder: "Branch Name",
              onChange,
              onFocus,
              value: form.name,
              disabled: isSubmitting,
            }}
            error={formErrors.name}
            label="Branch Name"
          />
          <div className="Place">
            <p className="Place__label">Street</p>
            <Autocomplete
              placeholder="Street"
              onPlaceSelected={handleChangePlace}
              types={["address"]}
              onFocus={handleFocusPlace}
              value={form.address}
              onChange={handleChangeAddress}
              disabled={isSubmitting}
            />
            {(formErrors.city || formErrors.state) && (
              <div className="error-message">
                This field is invalid please type a new place
              </div>
            )}
          </div>
          <FormGroup
            propsInput={{
              name: "city",
              placeholder: "City",
              onChange,
              onFocus,
              value: form.city,
              disabled: isSubmitting,
            }}
            error={formErrors.city}
            label="City"
          />
          <FormGroup
            propsInput={{
              name: "state",
              placeholder: " State",
              onChange,
              onFocus,
              value: form.state,
              disabled: isSubmitting,
            }}
            error={formErrors.state}
            label="State"
          />
          <FormGroup
            propsInput={{
              name: "zip_code",
              placeholder: "Zip code",
              onChange,
              onFocus,
              value: form.zip_code,
              disabled: isSubmitting,
            }}
            error={formErrors.zip_code}
            label="Zip code"
          />
        </div>
        <div className="checkbox">
          Main Location Status
          <FormGroup
            propsInput={{
              type: "checkbox",
              name: "is_main_location",
              onChange: handleCheckbox,
              checked: form.is_main_location,
            }}
            type="checkbox"
            error={formErrors.is_main_location}
          />
        </div>
        <div className="button-group">
          <button
            className="button button--back"
            type="submit"
            onClick={handleBack}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
}
FormAddBranch.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip_code: PropTypes.string,
    is_main_location: PropTypes.bool,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  handleCheckbox: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  handleChangePlace: PropTypes.func.isRequired,
  handleFocusPlace: PropTypes.func.isRequired,
  handleChangeAddress: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

FormAddBranch.defaultProps = {
  form: {
    name: "",
    address: "",
    city: "",
    state: "",
    zip_code: "",
    is_main_location: false,
  },
  formErrors: {},
  isEditing: false,
  isSubmitting: false,
};

export default FormAddBranch;
