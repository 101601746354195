import React from "react";
import css from "./styles.module.scss";

type Props = {
  school: {
    logo?: string;
    name: string;
  };
};

export function SchoolLogo(props: Props) {
  const { school } = props;
  const { logo, name } = school;

  if (logo === undefined) return <h1 className={css.name}>{name}</h1>;

  return <img className={css.logo} src={logo} alt={`${name} Logo`} />;
}
