import * as types from "../constants";
import request from "../../utils/request";

export function getUsers(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_USERS });
    return request()
      .get("/users/all", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_USERS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_USERS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
