import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  CIRRStudentIntent,
  SEND_SURVEY_OPTIONS_TYPE,
} from "../../../utils/constants";
import { SingleSelectCheckbox } from "../../common";

function ModalSendSurvey({
  open = false,
  onHide,
  onSend,
  onChangeType,
  type,
  isSubmitting,
  program,
  onChangeProgram,
  verifyingAdmin,
  onChangeVerifyingAdmin,
  dataPrograms,
  dataAdmins,
}) {
  const storeAgencies = useSelector((store) => store.agencies);
  const [optionsType, setOptionsType] = useState([...SEND_SURVEY_OPTIONS_TYPE]);

  useEffect(() => {
    let options = [...SEND_SURVEY_OPTIONS_TYPE];
    if (!storeAgencies.data?.includes("CIRR")) {
      options = options.filter((item) => item.value !== CIRRStudentIntent);
    }
    setOptionsType(options);
  }, [storeAgencies]);

  // eslint-disable-next-line react/prop-types
  const OPTIONS_PROGRAM =
    dataPrograms?.map((item) => ({
      value: item.id,
      label: item.name,
    })) || [];

  const OPTIONS_ADMIN =
    dataAdmins?.map((item) => ({
      value: item.id,
      label: item.full_name,
    })) || [];

  return (
    <Modal
      show={open}
      onHide={onHide}
      backdropClassName="wrap-modal"
      dialogClassName="Modal Modal__send__survey"
      backdrop="static"
      keyboard={false}
      centered
    >
      <div className="bootstrap">
        <Modal.Header>
          <Modal.Title>Send Survey</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="select">
            <p>Type</p>
            <SingleSelectCheckbox
              onChange={onChangeType}
              name="type"
              options={optionsType}
              value={type}
              disabled={isSubmitting}
              placeholder="Type"
            />
          </div>
          <div className="select">
            <p>Program</p>
            <SingleSelectCheckbox
              onChange={onChangeProgram}
              name="program"
              options={OPTIONS_PROGRAM}
              value={program}
              disabled={isSubmitting}
              placeholder="Program"
            />
          </div>
          <div className="select">
            <p>Verifying Administrator</p>
            <SingleSelectCheckbox
              onChange={onChangeVerifyingAdmin}
              name="verifying_admin"
              options={OPTIONS_ADMIN}
              value={verifyingAdmin}
              disabled={isSubmitting}
              placeholder="Verifying Administrator"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="button button--back"
            type="button"
            onClick={onHide}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="button"
            onClick={onSend}
            disabled={
              !program.value ||
              !type.value ||
              !verifyingAdmin.value ||
              isSubmitting
            }
          >
            Send
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
}

export default ModalSendSurvey;
