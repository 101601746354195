import { useFullScreenHeight } from "hooks/useFullScreenHeight";
import React from "react";
import { useSurveyInfo, usePlacementInfo, useCommonSurveyInfo } from "./api";
import { useSurveyMachine } from "./machine";
import type { SurveyState } from "./machine";
import { Layout, Error, Loading } from "./components";
import { CurrentEmploymentTypeStep } from "./steps/CurrentEmploymentTypeStep";
import { CurrentlyEmployedStep } from "./steps/CurrentlyEmployedStep";
import { CurrentlySeekingEmploymentStep } from "./steps/CurrentlySeekingEmploymentStep";
import { EducationInstitutionInformationStep } from "./steps/EducationInstitutionInformationStep";
import { EmploymentConditionsStep } from "./steps/EmploymentConditionsStep";
import { EmploymentInformationStep } from "./steps/EmploymentInformationStep";
import { JobSearchRatingStep } from "./steps/JobSearchRatingStep";
import { MilitaryInformationStep } from "./steps/MilitaryInformationStep";
import { NameAndEmailStep } from "./steps/NameAndEmailStep";
import { ReasonForNotSeekingEmploymentStep } from "./steps/ReasonForNotSeekingEmploymentStep";
import { RequiredDocumentsReviewStep } from "./steps/RequiredDocumentsReviewStep";
import { SelfEmploymentTypeStep } from "./steps/SelfEmploymentTypeStep";
import { SignDocumentsStep } from "./steps/SignDocumentsStep";
import { SocCodeInformationStep } from "./steps/SocCodeInformationStep";
import { StartPage } from "./steps/StartPage";
import { SurveyCompleteStep } from "./steps/SurveyCompleteStep";
import { UnavailabilityDateStep } from "./steps/UnavailabilityDateStep";
import { UploadDocumentsStep } from "./steps/UploadDocumentsStep";

export function GraduateFollowUpSurvey() {
  useFullScreenHeight();

  const commonSurveyInfo = useCommonSurveyInfo();

  const surveyInfo = useSurveyInfo();
  const placementInfo = usePlacementInfo();
  const { step, progress, next, back } = useSurveyMachine();

  const isLoading =
    surveyInfo.isLoading ||
    placementInfo.isLoading ||
    commonSurveyInfo.isLoading;
  const isError = surveyInfo.isError || placementInfo.isError;
  const data = surveyInfo.data;

  if (isLoading) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }

  if (isError || data === undefined) {
    return (
      <Layout>
        <Error />
      </Layout>
    );
  }

  const steps: { [key in SurveyState]: ReactNode } = {
    CurrentlyEmployedStep: (
      <CurrentlyEmployedStep
        surveyInfo={data}
        progress={progress}
        onNextClick={next}
        onBackClick={back}
      />
    ),
    CurrentlySeekingEmploymentStep: (
      <CurrentlySeekingEmploymentStep
        surveyInfo={data}
        progress={progress}
        onNextClick={next}
        onBackClick={back}
      />
    ),
    CurrentEmploymentTypeStep: (
      <CurrentEmploymentTypeStep
        surveyInfo={data}
        progress={progress}
        onNextClick={next}
        onBackClick={back}
      />
    ),
    EducationInstitutionInformationStep: (
      <EducationInstitutionInformationStep
        surveyInfo={data}
        progress={progress}
        onNextClick={next}
        onBackClick={back}
      />
    ),
    EmploymentConditionsStep: (
      <EmploymentConditionsStep
        surveyInfo={data}
        progress={progress}
        onNextClick={next}
        onBackClick={back}
      />
    ),
    EmploymentInformationStep: (
      <EmploymentInformationStep
        surveyInfo={data}
        progress={progress}
        onNextClick={next}
        onBackClick={back}
      />
    ),
    JobSearchRatingStep: (
      <JobSearchRatingStep
        surveyInfo={data}
        progress={progress}
        onNextClick={next}
        onBackClick={back}
      />
    ),
    MilitaryInformationStep: (
      <MilitaryInformationStep
        surveyInfo={data}
        progress={progress}
        onNextClick={next}
        onBackClick={back}
      />
    ),
    NameAndEmailStep: (
      <NameAndEmailStep
        surveyInfo={data}
        progress={progress}
        onNextClick={next}
        onBackClick={back}
      />
    ),
    ReasonForNotSeekingEmploymentStep: (
      <ReasonForNotSeekingEmploymentStep
        surveyInfo={data}
        progress={progress}
        onNextClick={next}
        onBackClick={back}
      />
    ),
    RequiredDocumentsReviewStep: (
      <RequiredDocumentsReviewStep
        surveyInfo={data}
        progress={progress}
        onNextClick={next}
        onBackClick={back}
      />
    ),
    SelfEmploymentTypeStep: (
      <SelfEmploymentTypeStep
        surveyInfo={data}
        progress={progress}
        onNextClick={next}
        onBackClick={back}
      />
    ),
    SignDocumentsStep: <SignDocumentsStep />,
    SocCodeInformationStep: (
      <SocCodeInformationStep
        surveyInfo={data}
        progress={progress}
        onNextClick={next}
        onBackClick={back}
      />
    ),
    StartPage: (
      <StartPage
        surveyInfo={data}
        placementInfo={placementInfo.data}
        progress={progress}
        onNextClick={next}
      />
    ),
    SurveyCompleteStep: (
      <SurveyCompleteStep surveyInfo={data} progress={progress} />
    ),
    UnavailabilityDateStep: (
      <UnavailabilityDateStep
        surveyInfo={data}
        progress={progress}
        onNextClick={next}
        onBackClick={back}
      />
    ),
    UploadDocumentsStep: (
      <UploadDocumentsStep
        surveyInfo={data}
        progress={progress}
        onNextClick={next}
        onBackClick={back}
      />
    ),
  };

  return <Layout>{steps[step]}</Layout>;
}
