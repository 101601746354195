import * as types from "../constants";
import request from "../../utils/request";

export function getReporting() {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING });
    return request()
      .get("/reports")
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getAgencies(schoolId) {
  return (dispatch) => {
    dispatch({ type: types.GET_AGENCIES });
    return request()
      .get(`/schools/${schoolId}/agencies`)
      .then((dataResponse) => {
        dispatch({
          type: types.GET_AGENCIES_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_AGENCIES_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingBPPEPreview(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_BPPE_PREVIEW });
    return request()
      .get("/reporting/bppe/preview", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_BPPE_PREVIEW_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_BPPE_PREVIEW_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingBPPEReport(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_BPPE_REPORT });
    return request()
      .get("/reporting/bppe/report", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_BPPE_REPORT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_BPPE_REPORT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingBPPEDocument(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_BPPE_DOCUMENT });
    return request()
      .get("/reporting/bppe/documentation", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_BPPE_DOCUMENT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })

      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_BPPE_DOCUMENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function getReportingBPPE_SPFS(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_BPPE_SPFS });
    return request()
      .get("/reporting/bppe/spfs", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_BPPE_SPFS_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_BPPE_SPFS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getExportIdentificationData(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_EXPORT_IDENTIFICATION_DATA });
    return request()
      .get("/reporting/bppe/graduate_id_data", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_EXPORT_IDENTIFICATION_DATA_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_EXPORT_IDENTIFICATION_DATA_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingCIRRPreview(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_CIRR_PREVIEW });
    return request()
      .get("/reporting/cirr/preview", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_CIRR_PREVIEW_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_CIRR_PREVIEW_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingCIRRReport(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_CIRR_REPORT });
    return request()
      .get("/reporting/cirr/report", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_CIRR_REPORT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_CIRR_REPORT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingCIRRDocument(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_CIRR_DOCUMENT });
    return request()
      .get("/reporting/cirr/documentation", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_CIRR_DOCUMENT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_CIRR_DOCUMENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingBPSSPreview(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_BPSS_PREVIEW });
    return request()
      .get("/reporting/bpss/preview", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_BPSS_PREVIEW_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_BPSS_PREVIEW_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingBPSSReport(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_BPSS_REPORT });
    return request()
      .get("/reporting/bpss/report", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_BPSS_REPORT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_BPSS_REPORT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingBPSSDocument(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_BPSS_DOCUMENT });
    return request()
      .get("/reporting/bpss/documentation", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_BPSS_DOCUMENT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_BPSS_DOCUMENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingTWCPreview(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_TWC_PREVIEW });
    return request()
      .get("/reporting/twc/preview", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_TWC_PREVIEW_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_TWC_PREVIEW_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingTWCReport(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_TWC_REPORT });
    return request()
      .get("/reporting/twc/report", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_TWC_REPORT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_TWC_REPORT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingTWCDocument(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_TWC_DOCUMENT });
    return request()
      .get("/reporting/twc/documentation", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_TWC_DOCUMENT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_TWC_DOCUMENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingTWCCompleter(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_TWC_COMPLETER });
    return request()
      .get("/reporting/twc/completer_data", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_TWC_COMPLETER_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_TWC_COMPLETER_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingDPOSPreview(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_DPOS_PREVIEW });
    return request()
      .get("/reporting/dpos/preview", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_DPOS_PREVIEW_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_DPOS_PREVIEW_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingDPOSReport(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_DPOS_REPORT });
    return request()
      .get("/reporting/dpos/report", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_DPOS_REPORT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_DPOS_REPORT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingDPOSDocument(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_DPOS_DOCUMENT });
    return request()
      .get("/reporting/dpos/documentation", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_DPOS_DOCUMENT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_DPOS_DOCUMENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingCOEPreview(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_COE_PREVIEW });
    return request()
      .get("/reporting/coe/preview", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_COE_PREVIEW_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_COE_PREVIEW_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingCOEReport(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_COE_REPORT });
    return request()
      .get("/reporting/coe/report", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_COE_REPORT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_COE_REPORT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingCOEDocument(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_COE_DOCUMENT });
    return request()
      .get("/reporting/coe/documentation", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_COE_DOCUMENT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_COE_DOCUMENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingACCETPreview(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_ACCET_PREVIEW });
    return request()
      .get("/reporting/accet/preview", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_ACCET_PREVIEW_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_ACCET_PREVIEW_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingACCETReport(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_ACCET_REPORT });
    return request()
      .get("/reporting/accet/report", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_ACCET_REPORT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_ACCET_REPORT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getReportingACCETDocument(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_REPORTING_ACCET_DOCUMENT });
    return request()
      .get("/reporting/accet/documentation", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_REPORTING_ACCET_DOCUMENT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_REPORTING_ACCET_DOCUMENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function setSelectedFilterReport(data) {
  return (dispatch) => {
    dispatch({ type: types.SET_SELECTED_FILTER_REPORT, payload: data });
  };
}
