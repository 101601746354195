import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedFilter } from "../../../redux/actions/filters";
import {
  FILTER_BRANCHES,
  FILTER_COHORTS,
  FILTER_PROGRAMS,
} from "../../../utils/constants";
import { Loading, MultiSelectCheckbox } from "../../common";

function Filter({ storeInstitution = {} }) {
  const [show, setShow] = React.useState({
    [FILTER_BRANCHES]: false,
    [FILTER_PROGRAMS]: false,
    [FILTER_COHORTS]: false,
  });
  const dispatch = useDispatch();
  const storeDashboard = useSelector((store) => store.dashboard);
  const { data } = storeDashboard;
  const storeFilters = useSelector((store) => store.filters);
  const { branches, programs, cohorts, selected } = storeFilters;

  const handleChange = (selectedItem) => (e) => {
    const name = e.target.name;
    const selectedFilter = selected[name];
    const newSelected = { ...selected };
    if (selectedFilter.some((item) => item.value === selectedItem.value)) {
      newSelected[name] = selectedFilter.filter(
        (item) => item.value !== selectedItem.value
      );
    } else {
      newSelected[name] = [...selectedFilter, selectedItem];
    }
    dispatch(setSelectedFilter(newSelected));
  };

  const handleToggle = (name) => () => {
    setShow((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  return (
    <div className="Filter ">
      <div className="Filter__inner">
        {storeInstitution.loading ? (
          <Loading />
        ) : (
          <div className="title">
            <img src={storeInstitution.data.logo} alt="" />
            <h4 className="">{storeInstitution.data.name}</h4>
          </div>
        )}

        <div className="list-filter">
          <MultiSelectCheckbox
            show={show[FILTER_BRANCHES]}
            name={FILTER_BRANCHES}
            onChange={handleChange}
            options={branches.data.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            value={selected[FILTER_BRANCHES]}
            onToggle={handleToggle(FILTER_BRANCHES)}
            placeholder="Branches"
            loading={branches.loading}
            theme="dark"
          />
          <MultiSelectCheckbox
            show={show[FILTER_PROGRAMS]}
            name={FILTER_PROGRAMS}
            onChange={handleChange}
            options={programs.data.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            value={selected[FILTER_PROGRAMS]}
            onToggle={handleToggle(FILTER_PROGRAMS)}
            placeholder="Programs"
            loading={programs.loading}
            theme="dark"
          />
          <MultiSelectCheckbox
            show={show[FILTER_COHORTS]}
            name={FILTER_COHORTS}
            onChange={handleChange}
            options={cohorts.data.map((item) => ({
              value: item.id,
              label: item.name,
            }))}
            value={selected[FILTER_COHORTS]}
            onToggle={handleToggle(FILTER_COHORTS)}
            placeholder="Cohorts"
            loading={cohorts.loading}
            theme="dark"
          />
        </div>
        <div className="stats">
          <div className="stats__item">
            <div>
              <p className="text--title bold">Total Enrollments</p>
              {data.kpi && data.kpi.total_enrollments ? (
                <h4>{Number(data.kpi.total_enrollments).toLocaleString()}</h4>
              ) : (
                <p className="--noData">No data to display</p>
              )}
            </div>
            <div>
              <p className="text--title  bold">Employed In-field</p>
              {data.kpi && data.kpi.total_enrollments ? (
                <h4>{Number(data.kpi.employed_in_field).toLocaleString()}</h4>
              ) : (
                <p className="--noData">No data to display</p>
              )}
            </div>
          </div>
          <div className="stats__item">
            <div>
              <p className="text--title bold">Seeking Employment</p>
              {data.kpi && data.kpi.total_enrollments ? (
                <h4>{Number(data.kpi.still_seeking).toLocaleString()}</h4>
              ) : (
                <p className="--noData">No data to display</p>
              )}
            </div>
            <div>
              <p className="text--title bold">Status Unknown</p>
              {data.kpi && data.kpi.total_enrollments ? (
                <h4>{Number(data.kpi.unknown).toLocaleString()}</h4>
              ) : (
                <p className="--noData">No data to display</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Filter;
