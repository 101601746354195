import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ROUTES } from "../../../../utils/constants";
import { AddLicensure } from "../../../../components/dashboard/students/student";
import {
  createLicensureExam,
  deleteDocument,
  getProgramLicensureExams,
  getStudentPrograms,
  updateLicensureExam,
} from "../../../../redux/actions/student";
import { openMessage } from "../../../../redux/actions/message";
import {
  convertToFormData,
  formatDateOfPicker,
  handleErrorsAfterCallingAPI,
  replaceParamStudent,
} from "../../../../utils/helpers";
import { getUsers } from "../../../../redux/actions/users";
import { ModalDeleteDocument } from "../../../../components/common";

const DEFAULT_FORM_LICENSURE = {
  program_id: undefined,
  licensure_exam_id: undefined,
  exam_date: new Date(),
  result: "",
  files: [],
};

export default function () {
  const history = useHistory();
  const location = useLocation();
  const [form, setForm] = React.useState({
    ...DEFAULT_FORM_LICENSURE,
  });
  const { studentId } = useParams();

  const [formErrors, setFormErrors] = React.useState({});
  const [openModalDeleteDocument, setOpenModalDeleteDocument] = useState(false);
  const [document, setDocument] = React.useState({});
  const [documentIndex, setDocumentIndex] = React.useState(null);
  const dispatch = useDispatch();
  const licensureData = location.state || {};
  const isEditing = !!Object.keys(licensureData).length;
  const storeCreate = useSelector(
    (store) => store.student.licensureExam.create
  );
  const storeUpdate = useSelector(
    (store) => store.student.licensureExam.update
  );
  const storePrograms = useSelector((store) => store.student.programs);
  const storeProgramLicensureExams = useSelector(
    (store) => store.student.licensureExam.listLicensureExams
  );

  const isSubmitting = storeCreate.loading || storeUpdate.loading;

  React.useEffect(() => {
    dispatch(getStudentPrograms(studentId));
    dispatch(getUsers());
  }, [studentId]);

  React.useEffect(() => {
    if (form.program_id) {
      dispatch(getProgramLicensureExams({}, form.program_id));
    }
  }, [form.program_id]);

  React.useEffect(() => {
    if (isEditing) {
      setForm({
        ...licensureData,
        program_id: licensureData.program.id,
        licensure_exam_id: licensureData.licensure_exam.id,
      });
    }
  }, [licensureData, isEditing]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.program_id) {
      errors.program_id = "Program is required";
    }
    if (!form.licensure_exam_id) {
      errors.licensure_exam_id = "Exam Name is required";
    }
    if (!form.result) {
      errors.result = "Result is required";
    }

    setFormErrors(errors);

    // call api
    if (!Object.keys(errors).length) {
      if (isEditing) {
        // edit
        try {
          setFormErrors({});
          const formData = {
            ...form,
            exam_date: formatDateOfPicker(form.exam_date),
          };
          formData.files = form.files.filter((item) => !item.id);
          await dispatch(
            updateLicensureExam(
              convertToFormData(formData, "student_licensure_exam"),
              studentId,
              licensureData.id
            )
          );
          dispatch(
            openMessage({
              title: "Licensure Exam has been updated.",
              type: "success",
            })
          );
          history.push(replaceParamStudent(ROUTES.licensure, studentId));
        } catch (error) {
          handleErrorsAfterCallingAPI(
            error,
            DEFAULT_FORM_LICENSURE,
            setFormErrors,
            dispatch
          );
        }
      } else {
        // create
        try {
          await dispatch(
            createLicensureExam(
              convertToFormData(form, "student_licensure_exam"),
              studentId
            )
          );
          dispatch(
            openMessage({
              title: "Licensure Exam has been created",
              type: "success",
            })
          );
          history.push(replaceParamStudent(ROUTES.licensure, studentId));
        } catch (err) {
          handleErrorsAfterCallingAPI(
            err,
            DEFAULT_FORM_LICENSURE,
            setFormErrors,
            dispatch
          );
        }
      }
    }
  };

  const handleChangeDate = (date, name) => {
    setForm({ ...form, [name]: date });
  };
  const handleBack = () => {
    history.push(replaceParamStudent(ROUTES.licensure, studentId));
  };

  const handleChangeSelect = (selectedItem, name) => {
    setForm({
      ...form,
      [name]: selectedItem.value,
    });
  };

  const handleDropzone = (dropped) => {
    const newFiles = [...form.files, ...dropped];
    setForm({
      ...form,
      files: newFiles,
    });
  };

  const handleCheckboxResult = (e) => {
    setForm({ ...form, result: e.target.value });
  };

  const handleTogleModalDeleteDocument = (file, selectedIndex) => () => {
    setOpenModalDeleteDocument(!openModalDeleteDocument);
    setDocument(file);
    setDocumentIndex(selectedIndex);
  };

  const handleDeleteDocument = async () => {
    if (document.id) {
      try {
        setFormErrors({});
        await dispatch(deleteDocument(document.id));
        dispatch(
          openMessage({
            title: "This document has been deleted",
            type: "success",
          })
        );
        setForm({
          ...form,
          files: form.files.filter((item, index) => index !== documentIndex),
        });
        setOpenModalDeleteDocument(!openModalDeleteDocument);
      } catch (error) {
        dispatch(
          openMessage({
            title: "Something went wrong, please try again!",
            type: "error",
          })
        );
      }
    } else {
      setForm({
        ...form,
        files: form.files.filter((item, index) => index !== documentIndex),
      });
      setOpenModalDeleteDocument(!openModalDeleteDocument);
    }
  };

  const handleCancelDelete = () => {
    setOpenModalDeleteDocument(false);
  };

  return (
    <>
      <AddLicensure
        form={form}
        formErrors={formErrors}
        onSubmit={handleSubmit}
        onChangeSelect={handleChangeSelect}
        dataProgramLicensureExams={storeProgramLicensureExams.data}
        handleChangeDate={handleChangeDate}
        dataPrograms={storePrograms.data}
        isSubmitting={isSubmitting}
        handleBack={handleBack}
        handleDrop={handleDropzone}
        handleCheckbox={handleCheckboxResult}
        onDeleteDocument={handleTogleModalDeleteDocument}
      />
      <ModalDeleteDocument
        isOpen={openModalDeleteDocument}
        onBack={handleCancelDelete}
        nameDocument={document.name || document.filename}
        onDelete={handleDeleteDocument}
      />
    </>
  );
}
