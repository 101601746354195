import * as types from "../constants";
import request from "../../utils/request";

export function getBranches(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_BRANCHES });
    return request()
      .get("/branches", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_BRANCHES_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            meta: {
              total: dataResponse.headers.total,
              perPage: dataResponse.headers["per-page"],
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_BRANCHES_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getAllBranches(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_ALL_BRANCHES });
    return request()
      .get("/branches/all", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_ALL_BRANCHES_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ALL_BRANCHES_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getBranchesReporting(agency) {
  return (dispatch) => {
    dispatch({ type: types.GET_BRANCHES_REPORTING });
    return request()
      .get(`/branches/all?agency=${agency}`)
      .then((dataResponse) => {
        dispatch({
          type: types.GET_BRANCHES_REPORTING_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_BRANCHES_REPORTING_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createBranch(data) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_BRANCH });
    return request()
      .post("/branches", { branch: data })
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_BRANCH_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_BRANCH_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function deleteBranch(branchId) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_BRANCH });
    return request()
      .delete(`/branches/${branchId}`)
      .then((dataResponse) => {
        dispatch({
          type: types.DELETE_BRANCH_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_BRANCH_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function updateBranch(data, branchId) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_BRANCH });
    return request()
      .put(`/branches/${branchId}`, { branch: data })
      .then((dataResponse) => {
        dispatch({
          type: types.UPDATE_BRANCH_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_BRANCH_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
