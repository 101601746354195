import * as types from "../constants";
import request from "../../utils/request";

export function getInstitution(params, schoolId) {
  return (dispatch) => {
    dispatch({ type: types.GET_INSTITUTION });
    return request()
      .get(`/schools/${schoolId}`, { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_INSTITUTION_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_INSTITUTION_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function updateInstitution(data, institutionId) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_INSTITUTION });
    return request()
      .put(`/schools/${institutionId}`, data)
      .then((dataResponse) => {
        dispatch({
          type: types.UPDATE_INSTITUTION_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_INSTITUTION_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getAdministrator(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_ADMINISTRATOR });
    return request()
      .get("/users", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_ADMINISTRATOR_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            meta: {
              total: dataResponse.headers.total,
              perPage: dataResponse.headers["per-page"],
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ADMINISTRATOR_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getRoles(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_ROLES });
    return request()
      .get("/roles", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_ROLES_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            meta: {
              total: dataResponse.headers.total,
              perPage: dataResponse.headers["per-page"],
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ROLES_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getAllRoles(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_ALL_ROLES });
    return request()
      .get("/roles/all", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_ALL_ROLES_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ALL_ROLES_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function updateAdministrator(data, administratorId) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_ADMINISTRATOR });
    return request()
      .put(`/users/${administratorId}`, { user: data })
      .then((dataResponse) => {
        dispatch({
          type: types.UPDATE_ADMINISTRATOR_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_ADMINISTRATOR_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function updateRole(data, roleId) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_ROLE });
    return request()
      .put(`/roles/${roleId}`, { role: data })
      .then((dataResponse) => {
        dispatch({
          type: types.UPDATE_ROLE_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_ROLE_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createAdministrator(data) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_ADMINISTRATOR });
    return request()
      .post("/auth/invitation", { user: data })
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_ADMINISTRATOR_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_ADMINISTRATOR_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function deleteAdministrator(id) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_ADMINISTRATOR });
    return request()
      .delete(`/users/${id}`)
      .then((dataResponse) => {
        dispatch({
          type: types.DELETE_ADMINISTRATOR_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_ADMINISTRATOR_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createRole(data) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_ROLE });
    return request()
      .post("/roles", { role: data })
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_ROLE_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_ROLE_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getRolesBuild(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_ROLES_BUILD });
    return request()
      .get("/roles/build", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_ROLES_BUILD_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ROLES_BUILD_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getRoleDetail(roleId) {
  return (dispatch) => {
    dispatch({ type: types.GET_ROLE_DETAIL });
    return request()
      .get(`/roles/${roleId}`)
      .then((dataResponse) => {
        dispatch({
          type: types.GET_ROLE_DETAIL_SUCCEED,
          payload: {
            data: dataResponse.data.data,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ROLE_DETAIL_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function deleteRole(roleId) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_ROLE });
    return request()
      .delete(`/roles/${roleId}`)
      .then((dataResponse) => {
        dispatch({
          type: types.DELETE_ROLE_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_ROLE_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
