import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Form } from "../../../components/dashboard/cohorts";
import { ROUTES } from "../../../utils/constants";
import { getAllPrograms } from "../../../redux/actions/programs";
import { getAllBranches } from "../../../redux/actions/branches";
import { createCohort, updateCohort } from "../../../redux/actions/cohorts";
import { openMessage } from "../../../redux/actions/message";
import {
  formatDateOfPicker,
  handleErrorsAfterCallingAPI,
} from "../../../utils/helpers";

const DEFAULT_FORM_ADD_COHORT = {
  name: "",
  branch_id: null,
  program_id: null,
  is_type: "",
  start_date: moment().startOf("day").toDate(),
  graduation_date: moment().startOf("day").toDate(),
};

export default function () {
  const [form, setForm] = React.useState({ ...DEFAULT_FORM_ADD_COHORT });
  const [formErrors, setFormErrors] = React.useState({});
  const [program, setProgram] = React.useState({});
  const [branch, setBranch] = React.useState({});
  const [type, setType] = React.useState({});

  const history = useHistory();
  const dispatch = useDispatch();
  const storePrograms = useSelector((store) => store.programs.all);
  const storeBranchs = useSelector((store) => store.branches.all);
  const storeCreate = useSelector((store) => store.cohorts.create);
  const storeUpdate = useSelector((store) => store.cohorts.update);
  const isEditing =
    !!history.location.state && history.location.state.id !== null;

  const isSubmitting = storeCreate.loading || storeUpdate.loading;

  React.useEffect(() => {
    const cohortSelected = history.location.state;
    if (!cohortSelected) return;
    setForm({
      name: cohortSelected.name,
      branch_id: cohortSelected.branch.id,
      program_id: cohortSelected.program.id,
      is_type: cohortSelected.is_type,
      start_date: moment(cohortSelected.start_date, "YYYY-MM-DD").toDate(),
      graduation_date: moment(
        cohortSelected.graduation_date,
        "YYYY-MM-DD"
      ).toDate(),
    });
    setProgram({
      value: cohortSelected.program.id,
      label: cohortSelected.program.name,
    });
    setBranch({
      value: cohortSelected.branch.id,
      label: cohortSelected.branch.name,
    });
    setType({ value: cohortSelected.is_type, label: cohortSelected.is_type });
  }, [history.location.state]);

  React.useEffect(() => {
    dispatch(getAllPrograms());
    dispatch(getAllBranches());
  }, []);

  const formatFormData = (formData) => ({
    ...formData,
    start_date: formatDateOfPicker(formData.start_date),
    graduation_date: formatDateOfPicker(formData.graduation_date),
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.name) {
      errors.name = "Name is required";
    }
    if (!form.program_id) {
      errors.program_id = "Program is required";
    }
    if (!form.branch_id) {
      errors.branch_id = "Branch is required";
    }

    if (!form.is_type) {
      errors.is_type = "Type is required";
    }

    setFormErrors(errors);

    // call api
    if (Object.keys(errors).length) return null;

    // update branch
    if (isEditing) {
      try {
        await dispatch(
          updateCohort(formatFormData(form), history.location.state.id)
        );
        dispatch(
          openMessage({
            title: "Your branch has been updated",
            type: "success",
          })
        );
        history.push(ROUTES.cohorts);
      } catch (err) {
        handleErrorsAfterCallingAPI(
          err,
          DEFAULT_FORM_ADD_COHORT,
          setFormErrors,
          dispatch
        );
      }
    } else {
      // create branch
      try {
        await dispatch(createCohort(formatFormData(form)));
        dispatch(
          openMessage({
            title: "Your cohort has been created",
            type: "success",
          })
        );
        history.push(ROUTES.cohorts);
      } catch (err) {
        handleErrorsAfterCallingAPI(
          err,
          DEFAULT_FORM_ADD_COHORT,
          setFormErrors,
          dispatch
        );
      }
    }
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  const handleChangeProgram = (selectedItem) => {
    setProgram(selectedItem);
    setForm({ ...form, program_id: selectedItem.value });
  };

  const handleChangeBranch = (selectedItem) => {
    setBranch(selectedItem);
    setForm({ ...form, branch_id: selectedItem.value });
  };

  const handleChangeType = (selectedItem) => {
    setType(selectedItem);
    setForm({ ...form, is_type: selectedItem.value });
  };

  const handleCallbackStartDate = (start) => {
    setForm({ ...form, start_date: start });
  };

  const handleCallbackGraduationtDate = (start) => {
    setForm({ ...form, graduation_date: start });
  };

  return (
    <Form
      form={form}
      formErrors={formErrors}
      onSubmit={handleSubmit}
      onChange={handleChange}
      onFocus={handleFocus}
      handleChangeProgram={handleChangeProgram}
      program={program}
      handleChangeBranch={handleChangeBranch}
      branch={branch}
      handleChangeType={handleChangeType}
      type={type}
      handleCallbackStartDate={handleCallbackStartDate}
      handleCallbackGraduationtDate={handleCallbackGraduationtDate}
      dataPrograms={storePrograms.data}
      dataBranchs={storeBranchs.data}
      isEditing={isEditing}
      isSubmitting={isSubmitting}
    />
  );
}
