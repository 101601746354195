import { config, library } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import {
  faCheck,
  faChevronDown,
  faCircle,
  faCircleCheck,
  faCircleDot,
  faClone,
  faClose,
  faComment,
  faEllipsisV,
  faPenToSquare,
  faShare,
  faTrash,
  faBan,
  faUserGraduate,
  faListCheck,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";

// https://markoskon.com/fix-oversized-icons-from-react-fontawesome/
config.autoAddCss = false;
library.add(
  faCheck,
  faChevronDown,
  faCircle,
  faCircleCheck,
  faCircleDot,
  faClone,
  faClose,
  faComment,
  faEllipsisV,
  faPenToSquare,
  faShare,
  faTrash,
  faBan,
  faUserGraduate,
  faListCheck,
  faFolder
);
