import React from "react";

export default function () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 2.40625C17.5523 2.40625 18 2.84099 18 3.37726V4.34828H19C20.6569 4.34828 22 5.65249 22 7.26132V18.9135C22 20.5223 20.6569 21.8265 19 21.8265H5C3.34315 21.8265 2 20.5223 2 18.9135V7.26132C2 5.65249 3.34315 4.34828 5 4.34828H6V3.37726C6 2.84099 6.44772 2.40625 7 2.40625C7.55228 2.40625 8 2.84099 8 3.37726V4.34828H16V3.37726C16 2.84099 16.4477 2.40625 17 2.40625ZM20 12.1164H4V18.9135C4 19.4498 4.44772 19.8845 5 19.8845H19C19.5523 19.8845 20 19.4498 20 18.9135V12.1164ZM17 14.0584C17.5523 14.0584 18 14.4932 18 15.0294V16.9715C18 17.5077 17.5523 17.9425 17 17.9425H15C14.4477 17.9425 14 17.5077 14 16.9715V15.0294C14 14.4932 14.4477 14.0584 15 14.0584H17ZM6 6.29031H5C4.44772 6.29031 4 6.72505 4 7.26132V10.1744H20V7.26132C20 6.72505 19.5523 6.29031 19 6.29031H18V7.26132C18 7.7976 17.5523 8.23234 17 8.23234C16.4477 8.23234 16 7.7976 16 7.26132V6.29031H8V7.26132C8 7.7976 7.55228 8.23234 7 8.23234C6.44772 8.23234 6 7.7976 6 7.26132V6.29031Z"
        fill="#16222F"
      />
    </svg>
  );
}
