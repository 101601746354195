import * as types from "../constants";
import request from "../../utils/request";

export function getPrograms(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_PROGRAMS });
    return request()
      .get("/programs", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_PROGRAMS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            meta: {
              total: dataResponse.headers.total,
              perPage: dataResponse.headers["per-page"],
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_PROGRAMS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getAllPrograms(params) {
  return (dispatch) => {
    dispatch({ type: types.GET_ALL_PROGRAMS });
    return request()
      .get("/programs/all", { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_ALL_PROGRAMS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            meta: {
              total: dataResponse.headers.total,
              perPage: dataResponse.headers["per-page"],
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ALL_PROGRAMS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getSOC() {
  return (dispatch) => {
    dispatch({ type: types.GET_SOC });
    return request()
      .get("/programs/soc")
      .then((dataResponse) => {
        dispatch({
          type: types.GET_SOC_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_SOC_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function deleteProgram(programId) {
  return (dispatch) => {
    dispatch({ type: types.DELETE_PROGRAM });
    return request()
      .delete(`/programs/${programId}`)
      .then((dataResponse) => {
        dispatch({
          type: types.DELETE_PROGRAM_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_PROGRAM_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createProgram(data) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_PROGRAM });
    return request()
      .post("/programs", { program: data })
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_PROGRAM_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_PROGRAM_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function updateProgram(data, programId) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_PROGRAM });
    return request()
      .put(`/programs/${programId}`, { program: data })
      .then((dataResponse) => {
        dispatch({
          type: types.UPDATE_PROGRAM_SUCCEED,
          payload: dataResponse.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_PROGRAM_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getProgramIndex(params, programId) {
  return (dispatch) => {
    dispatch({ type: types.GET_PROGRAM_INDEX });
    return request()
      .get(`/programs/${programId}`, { params })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_PROGRAM_INDEX_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            meta: {
              total: dataResponse.headers.total,
              perPage: dataResponse.headers["per-page"],
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_PROGRAM_INDEX_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
