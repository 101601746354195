import React from "react";
import PropTypes from "prop-types";
import { Breadcrumb } from "../common";

function Title({ title, breadcrumb = true }) {
  return (
    <div className="Title">
      <h4>{title}</h4>
      {breadcrumb && <Breadcrumb />}
    </div>
  );
}

Title.propTypes = {
  title: PropTypes.string,
  breadcrumb: PropTypes.bool,
};

Title.defaultProps = {
  title: "",
  breadcrumb: true,
};
export default Title;
