import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProfile } from "redux/actions/profileSetting";
import {
  HeaderOnboarding,
  Step1,
  Step2,
  Step3,
  Steps,
} from "../components/onBoarding";
import {
  createOnboarding,
  postOnboardingAgencies,
  postOnboardingValidate,
} from "../redux/actions/onBoarding";
import { openMessage } from "../redux/actions/message";
import { getAuth, setAuth } from "../utils/helpers";
import { componentForm, ROUTES } from "../utils/constants";

const FORM_DATA_ITEMS = {
  name: "",
  branches_attributes: [
    {
      name: "",
      city: "",
      state: "",
      zip_code: "",
      is_main_location: true,
    },
  ],
};

export default function () {
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = React.useState(1);
  const [open, setOpen] = React.useState([true]);
  const [isValidate, setIsValidate] = React.useState([false]);
  const [form, setForm] = React.useState({ ...FORM_DATA_ITEMS });
  const [multiBranch, setMultiBranch] = React.useState("NO");
  const [errorsBranch, setErrorsBranch] = React.useState({});
  const [errorName, setErrorName] = React.useState("");
  const [nationalAgencies, setNationalAgencies] = React.useState([]);
  const [address, setAddress] = React.useState([]);
  const storeProfile = useSelector((store) => store.profileSetting.profile);
  const userData = storeProfile.data;

  React.useEffect(() => {
    dispatch(getProfile());
  }, []);

  React.useEffect(() => {
    if (userData.school_id) {
      if (!userData.is_verified) {
        setStep(3);
      } else {
        history.push(ROUTES.dashboard);
      }
    }
  }, [userData]);

  // Reset all data if users quick onboarding page
  React.useEffect(
    () => () => {
      setStep(1);
      setForm({ ...FORM_DATA_ITEMS });
      setMultiBranch("NO");
      setErrorsBranch({});
      setErrorName("");
      setNationalAgencies([]);
      setAddress([]);
      setIsValidate([false]);
    },
    []
  );

  const callApiOnboardingValidate = async () => {
    try {
      await dispatch(postOnboardingValidate(form));
      await dispatch(postOnboardingAgencies(form));
      setStep(2);
    } catch (errorAPI) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  // step1
  const validate = () => {
    const errors = {};
    const newIsValidate = [...isValidate];
    form.branches_attributes.forEach((item, index) => {
      if (!item.name) {
        errors[`error-name${index}`] = "Name is required";
      }
      if (!item.city) {
        errors[`error-city${index}`] = "City is required";
      }
      if (!item.state) {
        errors[`error-state${index}`] = "State is required";
      }
      if (!item.zip_code) {
        errors[`error-zip_code${index}`] = "Zip code is required";
      }
      if (!item.name || !item.city || !item.state) {
        newIsValidate[index] = false;
      } else {
        newIsValidate[index] = true;
      }
    });
    setIsValidate(newIsValidate);
    setErrorsBranch(errors);
    return errors;
  };

  const handleAddBranch = (e) => {
    e.preventDefault();
    const errors = validate();
    // TODO: translate keys when fixing API
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const branches_attributes = [
      ...form.branches_attributes,
      {
        name: "",
        city: "",
        state: "",
        zip_code: "",
        is_main_location: false,
      },
    ];
    if (!Object.keys(errors).length) {
      setForm({ ...form, branches_attributes });
      setOpen([...open.map(() => false), true]);
      setIsValidate((prevState) => [...prevState, false]);
    }
  };

  const handleStep1 = () => {
    if (step !== 3) {
      setStep(1);
    }
  };

  const handleSubmitStep1 = (e) => {
    e.preventDefault();
    let newErrorName = "";
    if (!form.name) {
      newErrorName = "Name is required";
    }

    setErrorName(newErrorName);
    const errors = validate();
    // call api
    if (!Object.keys(errors).length && !newErrorName) {
      callApiOnboardingValidate();
    }
  };

  const handleDeleteBranch = (index) => (e) => {
    e.preventDefault();

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const branches_attributes = form.branches_attributes;
    branches_attributes.splice(index, 1);
    setForm({ ...form, branches_attributes });

    const newAddress = [...address];
    newAddress.splice(index, 1);
    setAddress([...newAddress]);

    const newOpen = [...open];
    newOpen.splice(index, 1);
    setOpen([...newOpen]);

    const newIsValidate = [...isValidate];
    newIsValidate.splice(index, 1);
    setIsValidate([...newIsValidate]);

    const newErrorsBranch = { ...errorsBranch };
    delete newErrorsBranch[`error-name${index}`];
    delete newErrorsBranch[`error-city${index}`];
    delete newErrorsBranch[`error-state${index}`];
    delete newErrorsBranch[`error-zip_code${index}`];
    setErrorsBranch(newErrorsBranch);
  };

  const handleClickCollapse = (index) => () => {
    const newOpen = [...open];
    newOpen[index] = !open[index];
    setOpen(newOpen);
  };

  const handleCheckboxMultiBranch = (event) => {
    setMultiBranch(event.target.value);
  };

  const handleChangeBranchName = (index) => (e) => {
    e.preventDefault();
    const branchData = [...form.branches_attributes].splice(index, 1)[0];

    branchData[e.target.name] = e.target.value;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const branches_attributes = form.branches_attributes.map(
      (item, itemIndex) => (itemIndex === index ? branchData : item)
    );

    setForm({ ...form, branches_attributes });
  };

  const handleCheckboxMainBranch = (index) => (e) => {
    e.preventDefault();
    const newBranchesAttributes = form.branches_attributes.map((item) => ({
      ...item,
      is_main_location: false,
    }));

    newBranchesAttributes[index].is_main_location = true;
    const newForm = {
      ...form,
      branches_attributes: [...newBranchesAttributes],
    };
    setForm(newForm);
  };

  const handleFocusBranch = (index) => (e) => {
    setErrorsBranch({
      ...errorsBranch,
      [`error-${e.target.name}${index}`]: "",
    });
  };

  const handleChangeSchoolName = (event) => {
    setForm({ ...form, name: event.target.value });
  };

  const handleFocusSchoolName = () => {
    setErrorName("");
  };

  const handleChangePlace = (index) => (place) => {
    if (!place.address_components || !place.address_components.length) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const branches_attributes = [...form.branches_attributes];
    branches_attributes[index] = {
      ...FORM_DATA_ITEMS.branches_attributes[0],
      is_main_location: branches_attributes[index].is_main_location,
      name: branches_attributes[index].name,
    };
    const newAddress = [...address];
    let street = "";
    // Get each component of the address from the place details,
    // and then fill-in the corresponding field on the form.
    place.address_components.forEach((component) => {
      const addressType = component.types[0];
      switch (addressType) {
        case "postal_code":
          branches_attributes[index].zip_code =
            component[componentForm[addressType]];
          break;
        case "administrative_area_level_1":
          branches_attributes[index].state =
            component[componentForm[addressType]];
          break;
        case "locality":
          branches_attributes[index].city =
            component[componentForm[addressType]];
          break;
        case "street_number":
          street = `${component[componentForm[addressType]]} ${street}`;
          break;
        case "route":
          street = `${street} ${component[componentForm[addressType]]}`;
          break;
        default:
          break;
      }
    });
    newAddress[index] = street;
    setAddress(newAddress);
    setForm({ ...form, branches_attributes });
  };

  const handleFocusPlace = (index) => () => {
    setErrorsBranch({
      ...errorsBranch,
      [`error-city${index}`]: "",
      [`error-state${index}`]: "",
      [`error-zip_code${index}`]: "",
    });
  };

  const handleChangeAddress = (index) => (e) => {
    const newAddress = [...address];
    newAddress[index] = e.target.value;
    setAddress(newAddress);
  };

  // step2
  const handleSubmitStep2 = async (e) => {
    e.preventDefault();
    try {
      await dispatch(
        createOnboarding({
          ...form,
          school_agencies_attributes: nationalAgencies.map((item) => ({
            is_type: item,
          })),
        })
      );
      setStep(3);
      const auth = getAuth();
      auth.userData.school_id = "TEMP_ID";
      setAuth(auth, true);
    } catch (errorAPI) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleCheckNationalAgency = (event) => {
    if (event.target.checked) {
      setNationalAgencies([...nationalAgencies, event.target.name]);
    } else {
      setNationalAgencies(
        nationalAgencies.filter((item) => item !== event.target.name)
      );
    }
  };

  return (
    <div className="bg-form">
      <HeaderOnboarding />
      <div className="Onboarding-body">
        <Steps step={step} />
        {step === 1 && (
          <Step1
            open={open}
            form={form}
            errorsBranch={errorsBranch}
            errorName={errorName}
            handleAddBranch={handleAddBranch}
            handleDeleteBranch={handleDeleteBranch}
            handleClick={handleClickCollapse}
            multiBranch={multiBranch}
            handleCheckbox={handleCheckboxMultiBranch}
            handleChangeBranchName={handleChangeBranchName}
            handleCheckboxMainBranch={handleCheckboxMainBranch}
            handleFocusBranch={handleFocusBranch}
            handleChangeSchoolName={handleChangeSchoolName}
            handleFocusSchoolName={handleFocusSchoolName}
            handleChangePlace={handleChangePlace}
            handleFocusPlace={handleFocusPlace}
            handleChangeAddress={handleChangeAddress}
            onSubmit={handleSubmitStep1}
            address={address}
            isValidate={isValidate}
          />
        )}
        {step === 2 && (
          <Step2
            form={form}
            handleback={handleStep1}
            onSubmit={handleSubmitStep2}
            nationalAgencies={nationalAgencies}
            handleCheckbox={handleCheckNationalAgency}
          />
        )}
        {step === 3 && <Step3 />}
      </div>
    </div>
  );
}
