import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Document } from "../../../../components/dashboard/students/student";
import { getStudentDocuments } from "../../../../redux/actions/student";

function Documents() {
  const dispatch = useDispatch();
  const { studentId } = useParams();
  const storeDocuments = useSelector((store) => store.student.documents.list);

  React.useEffect(() => {
    if (studentId) {
      dispatch(getStudentDocuments({}, studentId));
    }
  }, [studentId]);

  return <Document storeDocuments={storeDocuments} />;
}

export default Documents;
