import React from "react";
import { NavLink } from "react-router-dom";
import phone from "../../assets/icons/phone.svg";
import email from "../../assets/icons/email.svg";
import { ROUTES } from "../../utils/constants";

export default function () {
  return (
    <footer className="footer">
      <div className="footer__inner">
        <div className="footer__inner__left">
          <p className="bold">2023 AcademySync LLC All rights reserved</p>
          <p className="footer__link_one_line">
            <img src={phone} alt="phone" />
            888-701-6811
          </p>
          <p className="footer__link_one_line">
            <img src={email} alt="email" />
            info@academysync.com
          </p>
        </div>
        <div className="footer__inner__right">
          <NavLink
            exact
            className="bold"
            to={ROUTES.privacy}
            activeClassName="--is-active"
          >
            Privacy Policy
          </NavLink>
          <NavLink
            exact
            className="bold"
            to={ROUTES.tos}
            activeClassName="--is-active"
          >
            Terms of Service
          </NavLink>
          <NavLink
            exact
            className="bold"
            to={ROUTES.cookiePolicy}
            activeClassName="--is-active"
          >
            Cookie Policy
          </NavLink>
          <NavLink
            exact
            className="bold"
            to={ROUTES.userPolicy}
            activeClassName="--is-active"
          >
            Acceptable User Policy
          </NavLink>
        </div>
      </div>
    </footer>
  );
}
