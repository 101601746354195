import { normalizePhoneInput } from "lib/normalize";
import type { PlacementInfo } from "./api/usePlacementInfo";
import { useSurveyStore } from "./store";

function yesNo(value: boolean) {
  return value ? "YES" : "NO";
}

function nullableYesNo(value: boolean | null) {
  if (value === null) return null;
  return value ? "YES" : "NO";
}

function setEmploymentInformation(data: PlacementInfo["employmentPlacement"]) {
  if (data === undefined) throw new Error("Invalid Placement");

  useSurveyStore.setState({
    ...useSurveyStore.getState(),
    employmentInformation: {
      jobTitle: data.jobTitle,
      hoursPerWeek: data.hoursPerWeek,
      chosePartTime: nullableYesNo(data.chosePartTime),
      duration: data.duration,
      longerThanFiveWeeks: yesNo(data.longerThan5Weeks),
      offerDate: data.offerDate,
      startDate: data.startDate,
      salaryType: data.salaryType,
      salary: data.salary,
      hiredBySchool: yesNo(data.hiredBySchool),
      placedBySchool: yesNo(data.placedBySchool),
      employerName: data.employerName,
      employerWebsite: data.employerWebsite,
      employerAddress: data.employerAddress,
      employerCity: data.employerCity,
      employerState: data.employerState,
      employerZipCode: data.employerZipCode,
      employerPhone: normalizePhoneInput(data.employerPhone),
      employerContactName: data.employerContactName,
      employerContactPhone: normalizePhoneInput(data.employerContactPhone),
      employerContactEmail: data.employerContactEmail,
    },
  });
}

function setEmploymentConditions(data: PlacementInfo["employmentPlacement"]) {
  if (data === undefined) throw new Error("Invalid Placement");

  useSurveyStore.setState({
    employmentConditions: {
      employmentConcurrency: data.jobType,
      sameEmployer: yesNo(data.sameEmployer!),
      employerPromotion: yesNo(data.sameEmployerPromotion!),
      continuedEmploymentCondition: yesNo(data.sameEmployerCondition!),
    },
  });
}

function setEmployedInField(placement: PlacementInfo) {
  if (placement.employmentPlacement === undefined)
    throw new Error("Invalid Placement");

  const { employmentPlacement } = placement;

  useSurveyStore.setState({
    ...useSurveyStore.getState(),
    currentlyEmployed: "YES",
    currentEmploymentType: "Employed In-Field",
    socCode: employmentPlacement.socCode!,
  });

  setEmploymentInformation(employmentPlacement);
  setEmploymentConditions(employmentPlacement);
}

function setEmployedOutOfField(placement: PlacementInfo) {
  if (placement.employmentPlacement === undefined)
    throw new Error("Invalid Placement");

  const { employmentPlacement } = placement;

  useSurveyStore.setState({
    ...useSurveyStore.getState(),
    currentlyEmployed: "YES",
    currentEmploymentType: "Employed Out of Field",
  });

  setEmploymentInformation(employmentPlacement);
  setEmploymentConditions(employmentPlacement);
}

function setMilitaryOutcome(placement: PlacementInfo) {
  if (
    placement.militaryPlacement === undefined ||
    placement.unavailabilityPlacement === undefined
  )
    throw new Error("Invalid Placement");

  const { militaryPlacement } = placement;

  useSurveyStore.setState({
    ...useSurveyStore.getState(),
    currentlyEmployed: "NO",
    currentlySeekingEmployment: "NO",
    reasonForNotSeekingEmployment: "Military",
    unavailabilityDate: placement.unavailabilityPlacement.unavailabilityDate,
    militaryInformation: {
      branchName: militaryPlacement.branchName,
      recruitingOfficeAddress: militaryPlacement.recruitingOfficeAddress,
      recruitingOfficeCity: militaryPlacement.recruitingOfficeCity,
      recruitingOfficeState: militaryPlacement.recruitingOfficeState,
      recruitingOfficeZipCode: militaryPlacement.recruitingOfficeZipCode,
      recruitingOfficePhone: normalizePhoneInput(
        militaryPlacement.recruitingOfficePhone
      ),
    },
  });
}

function setContinuingEducationOutcome(placement: PlacementInfo) {
  if (
    placement.educationPlacement === undefined ||
    placement.unavailabilityPlacement === undefined
  )
    throw new Error("Invalid Placement");

  const { educationPlacement } = placement;

  useSurveyStore.setState({
    ...useSurveyStore.getState(),
    currentlyEmployed: "NO",
    currentlySeekingEmployment: "NO",
    reasonForNotSeekingEmployment: "Continuing Education",
    unavailabilityDate: placement.unavailabilityPlacement.unavailabilityDate,
    educationInstitutionInformation: {
      institutionName: educationPlacement.institutionName,
      institutionAddress: educationPlacement.institutionAddress,
      institutionCity: educationPlacement.institutionCity,
      institutionState: educationPlacement.institutionState,
      institutionZipCode: educationPlacement.institutionZipCode,
    },
    educationProgramName: educationPlacement.programName,
  });
}

function setSelfEmployedOutcome(placement: PlacementInfo) {
  if (placement.employmentPlacement === undefined)
    throw new Error("Invalid Placement");

  const { employmentPlacement } = placement;

  useSurveyStore.setState({
    ...useSurveyStore.getState(),
    currentlyEmployed: "YES",
    currentEmploymentType: "Self-Employed",
    selfEmploymentType: employmentPlacement.selfEmployedType!,
  });

  setEmploymentInformation(employmentPlacement);
}

export function mapPlacementToStore(placement: PlacementInfo) {
  switch (placement.outcome) {
    case "Continuing Education":
      setContinuingEducationOutcome(placement);
      break;
    case "Employed In-Field":
      setEmployedInField(placement);
      break;
    case "Employed Out of Field":
      setEmployedOutOfField(placement);
      break;
    case "Military":
      setMilitaryOutcome(placement);
      break;
    case "Self-Employed":
      setSelfEmployedOutcome(placement);
      break;
    default:
      break;
    // throw new Error("Unimplemented Placement Outcome");
  }

  if (placement.files !== undefined) {
    useSurveyStore.setState({
      ...useSurveyStore.getState(),
      previouslyUploadedFiles: placement.files,
    });
  }
}
