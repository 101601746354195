import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../utils/constants";

function PricingType() {
  return (
    <section className="PricingType">
      <div className="container">
        <div className="PricingType__inner white">
          <div className="PricingType__inner__text">
            <h3>Pricing to Fit Your Needs</h3>
            <p className="text--large">
              Our pricing is tailored to the size of your school
            </p>
            <Link to={ROUTES.signup}>
              <button className="button button--primary" type="button">
                GET STARTED
              </button>
            </Link>
          </div>
          <div className="PricingType__inner__type">
            <div>
              <p className="text--xxlarge bold name">Starter</p>
              <h4 className="bold">$20</h4>
              <p className="text--large bold">per enrollment</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PricingType;
