import React from "react";
import { Toast } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import success from "../../assets/icons/success.svg";
import error from "../../assets/icons/error.svg";
import { closeMessage } from "../../redux/actions/message";

function Message() {
  const dispatch = useDispatch();
  const storeMessage = useSelector((store) => store.message);

  React.useEffect(() => {
    if (storeMessage.open && storeMessage.data.timeout) {
      const timer = setTimeout(() => {
        dispatch(closeMessage());
      }, storeMessage.data.timeout);
      return () => clearTimeout(timer);
    }
  }, [storeMessage, dispatch]);

  return (
    <div className="bootstrap">
      {storeMessage.data.type === "success" && (
        <Toast
          show={storeMessage.open}
          onClose={closeMessage}
          className=" message message-success"
        >
          <Toast.Header>
            <img src={success} alt="" />
            <p className="mr-auto text--small">{storeMessage.data.title}</p>
          </Toast.Header>
        </Toast>
      )}
      {storeMessage.data.type === "error" && (
        <Toast
          show={storeMessage.open}
          onClose={closeMessage}
          className=" message message-error"
        >
          <Toast.Header>
            <img src={error} alt="" />
            <p className="mr-auto text--small">{storeMessage.data.title}</p>
          </Toast.Header>
        </Toast>
      )}
    </div>
  );
}

export default Message;
