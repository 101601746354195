/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";
import {
  CollapseCheckbox,
  Dropzone,
  FormGroup,
  SingleDatePicker,
  SingleSelectCheckbox,
} from "../../../common";
import { Delete } from "../../../common/icons";
import {
  EmployedInField,
  EmployedInRelatedField,
  EmployedOutOfField,
  EMPLOYMENT_OUTCOMES,
  OUTCOMES,
  SelfEmployed,
  Military,
  ContinuingEducation,
  UNAVAILABILITY_OUTCOMES,
  OPTION_MILITARY_BRANCH,
} from "../../../../utils/constants";

function AddPlacement({
  form,
  formErrors,
  onChange,
  onFocus,
  onSubmit,
  onChangeSelect,
  handleChangeDate,
  handleCheckbox,
  handleBack,
  dataPrograms,
  dataProgramIndex = {},
  handleDrop,
  handleChangePlace,
  handleFocusPlace,
  handleChangeAddress,
  address,
  isSubmitting,
  onDeleteDocument,
  handleChangeCode,
  onChangeNumber,
}) {
  const OPTIONS_PROGRAM = dataPrograms.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const typeOfDuties =
    dataProgramIndex.soc_codes && dataProgramIndex.soc_codes.length
      ? dataProgramIndex.soc_codes.map((item) => ({
          title: item.title,
          text1: item.description,
          soc_code: item.code,
        }))
      : [];

  function renderInField() {
    if (!typeOfDuties.length) return null;

    return (
      <>
        {(form.outcome === EmployedInField ||
          form.outcome === EmployedInRelatedField) && (
          <div className="checkbox-group">
            <p className=" bold">
              Which of the following descriptions most closely match the type of
              duties the student will do in their training-related employment?
            </p>
            {typeOfDuties.map((item) => (
              <CollapseCheckbox
                key={`selected-${item.title}`}
                data={item}
                onChange={handleChangeCode}
                value={item.soc_code}
                checked={
                  form.employment_placement_attributes.soc_code ===
                  item.soc_code
                }
              />
            ))}
            {!form.employment_placement_attributes.soc_code && (
              <div className="error-message">{formErrors.soc_code}</div>
            )}
          </div>
        )}
        <div className="checkbox-group">
          <p className=" bold">
            Which of the following best describes the student's current
            employment?
          </p>
          <div>
            <div className="checkbox">
              One job/position
              <FormGroup
                propsInput={{
                  type: "radio",
                  name: "job_type",
                  onChange: handleCheckbox,
                  checked:
                    form.employment_placement_attributes.job_type === "Single",
                  value: "Single",
                }}
                type="radio"
              />
            </div>
            <div className="checkbox">
              More than one job/position at the same time
              <FormGroup
                propsInput={{
                  type: "radio",
                  name: "job_type",
                  onChange: handleCheckbox,
                  checked:
                    form.employment_placement_attributes.job_type ===
                    "Concurrent",
                  value: "Concurrent",
                }}
                type="radio"
              />
            </div>
          </div>
        </div>

        <div className="checkbox-group">
          <p className=" bold">
            Is the student’s current position with the same employer that they
            were employed with before they enrolled in Front-End Development?
          </p>
          <div>
            <div className="checkbox">
              Yes
              <FormGroup
                propsInput={{
                  type: "radio",
                  name: "same_employer",
                  onChange: handleCheckbox,
                  checked:
                    form.employment_placement_attributes.same_employer ===
                    "YES",
                  value: "YES",
                }}
                type="radio"
              />
            </div>
            <div className="checkbox">
              No
              <FormGroup
                propsInput={{
                  type: "radio",
                  name: "same_employer",
                  onChange: handleCheckbox,
                  checked:
                    form.employment_placement_attributes.same_employer === "NO",
                  value: "NO",
                }}
                type="radio"
              />
            </div>
          </div>
        </div>
        <div className="checkbox-group">
          <p className=" bold">
            Was the student’s employment after graduation a promotion (with
            increased pay) due (at least in part) to graduating from/completing
            the program?
          </p>
          <div>
            <div className="checkbox">
              Yes
              <FormGroup
                propsInput={{
                  type: "radio",
                  name: "same_employer_promotion",
                  onChange: handleCheckbox,
                  checked:
                    form.employment_placement_attributes
                      .same_employer_promotion === "YES",
                  value: "YES",
                }}
                type="radio"
              />
            </div>
            <div className="checkbox">
              No
              <FormGroup
                propsInput={{
                  type: "radio",
                  name: "same_employer_promotion",
                  onChange: handleCheckbox,
                  checked:
                    form.employment_placement_attributes
                      .same_employer_promotion === "NO",
                  value: "NO",
                }}
                type="radio"
              />
            </div>
          </div>
        </div>
        <div className="checkbox-group">
          <p className=" bold">
            Was graduating from/completing the program a condition for continued
            employment with this employer?
          </p>
          <div>
            <div className="checkbox">
              Yes
              <FormGroup
                propsInput={{
                  type: "radio",
                  name: "same_employer_condition",
                  onChange: handleCheckbox,
                  checked:
                    form.employment_placement_attributes
                      .same_employer_condition === "YES",
                  value: "YES",
                }}
                type="radio"
              />
            </div>
            <div className="checkbox">
              No
              <FormGroup
                propsInput={{
                  type: "radio",
                  name: "same_employer_condition",
                  onChange: handleCheckbox,
                  checked:
                    form.employment_placement_attributes
                      .same_employer_condition === "NO",
                  value: "NO",
                }}
                type="radio"
              />
            </div>
          </div>
        </div>
      </>
    );
  }

  function renderSelfEmployed() {
    return (
      <div className="checkbox-group">
        <p className=" bold">
          Which of the following describe the nature of your self-employment?
        </p>
        <div>
          <div className="checkbox">
            Entrepreneur/Running my own business (business owner or partner;
            for-profit or non-profit)
            <FormGroup
              propsInput={{
                type: "radio",
                name: "self_employed_type",
                onChange: handleCheckbox,
                checked:
                  form.employment_placement_attributes.self_employed_type ===
                  "Entrepreneur",
                value: "Entrepreneur",
              }}
              type="radio"
            />
          </div>
          <div className="checkbox">
            Freelance work
            <FormGroup
              propsInput={{
                type: "radio",
                name: "self_employed_type",
                onChange: handleCheckbox,
                checked:
                  form.employment_placement_attributes.self_employed_type ===
                  "Freelance",
                value: "Freelance",
              }}
              type="radio"
            />
          </div>
        </div>
      </div>
    );
  }

  const renderCommonEmploymentForm = () => (
    <>
      <div className="input__group">
        <FormGroup
          propsInput={{
            name: "job_title",
            placeholder: "Job Title",
            onChange,
            onFocus,
            value: form.employment_placement_attributes.job_title,
            disabled: isSubmitting,
          }}
          error={formErrors.job_title}
          label="What is the student's current job title?"
        />
      </div>
      <div className="input__group employment-attribute">
        <FormGroup
          propsInput={{
            name: "hours_per_week",
            placeholder: "Hours per week",
            type: "number",
            onChange,
            onFocus,
            value: form.employment_placement_attributes.hours_per_week,
            disabled: isSubmitting,
          }}
          error={formErrors.hours_per_week}
          label="Hours Per Week "
        />
      </div>
      {form.employment_placement_attributes.hours_per_week >= "20" &&
        form.employment_placement_attributes.hours_per_week <= "29" && (
          <div className="checkbox-group">
            <p className=" bold">
              Did the student specifically choose to seek part-time employment
              rather than full time employment after graduation?
            </p>
            <div>
              <div className="checkbox">
                Yes
                <FormGroup
                  propsInput={{
                    type: "radio",
                    name: "chose_part_time",
                    onChange: handleCheckbox,
                    checked:
                      form.employment_placement_attributes.chose_part_time ===
                      "YES",
                    value: "YES",
                  }}
                  type="radio"
                />
              </div>
              <div className="checkbox">
                No
                <FormGroup
                  propsInput={{
                    type: "radio",
                    name: "chose_part_time",
                    onChange: handleCheckbox,
                    checked:
                      form.employment_placement_attributes.chose_part_time ===
                      "NO",
                    value: "NO",
                  }}
                  type="radio"
                />
              </div>
            </div>
          </div>
        )}

      <div className="checkbox-group">
        <div>
          <div className="checkbox">
            Less than 3 months
            <FormGroup
              propsInput={{
                type: "radio",
                name: "duration",
                onChange: handleCheckbox,
                checked:
                  form.employment_placement_attributes.duration ===
                  "Less than 3 months",
                value: "Less than 3 months",
              }}
              type="radio"
              label="What is the expected duration of this job?"
            />
          </div>
          <div className="checkbox">
            3-6 months
            <FormGroup
              propsInput={{
                type: "radio",
                name: "duration",
                onChange: handleCheckbox,
                checked:
                  form.employment_placement_attributes.duration ===
                  "3-6 months",
                value: "3-6 months",
              }}
              type="radio"
            />
          </div>

          <div className="checkbox">
            6+ months
            <FormGroup
              propsInput={{
                type: "radio",
                name: "duration",
                onChange: handleCheckbox,
                checked:
                  form.employment_placement_attributes.duration === "6+ months",
                value: "6+ months",
              }}
              type="radio"
            />
          </div>
        </div>
      </div>
      <div className="checkbox-group">
        <p className=" bold">
          Do you expect the student’s job to last longer than 5 weeks?
        </p>
        <div>
          <div className="checkbox">
            Yes
            <FormGroup
              propsInput={{
                type: "radio",
                name: "longer_than_5_weeks",
                onChange: handleCheckbox,
                checked:
                  form.employment_placement_attributes.longer_than_5_weeks ===
                  "YES",
                value: "YES",
              }}
              type="radio"
            />
          </div>
          <div className="checkbox">
            No
            <FormGroup
              propsInput={{
                type: "radio",
                name: "longer_than_5_weeks",
                onChange: handleCheckbox,
                checked:
                  form.employment_placement_attributes.longer_than_5_weeks ===
                  "NO",
                value: "NO",
              }}
              type="radio"
            />
          </div>
        </div>
      </div>
      <div className="input__group">
        <div className="Date-picker">
          <p className="Date-picker__label">
            When did the student sign their offer letter?
          </p>
          <SingleDatePicker
            placeholder="Select Date"
            value={form.employment_placement_attributes.offer_date}
            onChange={handleChangeDate}
            disabled={isSubmitting}
            name="offer_date"
          />
        </div>
      </div>
      <div className="input__group">
        <div className="Date-picker">
          <p className="Date-picker__label">
            What is/was the student’s start date?
          </p>
          <SingleDatePicker
            placeholder="Select Date"
            value={form.employment_placement_attributes.start_date}
            onChange={handleChangeDate}
            disabled={isSubmitting}
            name="start_date"
          />
        </div>
      </div>
      <div className="input__group">
        <div className="Date-picker">
          <p className="Date-picker__label">
            What is/was the student’s end date?
          </p>
          <SingleDatePicker
            placeholder="Select Date"
            value={form.employment_placement_attributes.end_date}
            onChange={handleChangeDate}
            disabled={isSubmitting}
            name="end_date"
          />
        </div>
      </div>
      <div className="checkbox-group">
        <p className=" bold">
          Which of the following best describes how the student will get paid?
        </p>
        <div>
          <div className="checkbox">
            Annual Salary
            <FormGroup
              propsInput={{
                type: "radio",
                name: "salary_type",
                onChange: handleCheckbox,
                checked:
                  form.employment_placement_attributes.salary_type === "Annual",
                value: "Annual",
              }}
              type="radio"
            />
          </div>
          <div className="checkbox">
            Hourly Wage
            <FormGroup
              propsInput={{
                type: "radio",
                name: "salary_type",
                onChange: handleCheckbox,
                checked:
                  form.employment_placement_attributes.salary_type === "Hourly",
                value: "Hourly",
              }}
              type="radio"
            />
          </div>
          <div className="checkbox">
            Weekly Compensation
            <FormGroup
              propsInput={{
                type: "radio",
                name: "salary_type",
                onChange: handleCheckbox,
                checked:
                  form.employment_placement_attributes.salary_type === "Weekly",
                value: "Weekly",
              }}
              type="radio"
            />
          </div>
        </div>
      </div>
      <div className="input__group employment-attribute">
        <FormGroup
          propsInput={{
            name: "salary",
            placeholder: "Salary",
            type: "number",
            onChange,
            onFocus,
            value: form.employment_placement_attributes.salary,
            disabled: isSubmitting,
          }}
          error={formErrors.salary}
          label="What is the student’s salary? (please exclude dollar signs and commas: for example, 70000)"
        />
      </div>
      <div className="checkbox-group">
        <p className=" bold">
          For this position, was the student hired by your school, by an
          employer owned by the school, or by an employer who shares ownership
          with the school?
        </p>
        <div>
          <div className="checkbox">
            Yes
            <FormGroup
              propsInput={{
                type: "radio",
                name: "hired_by_school",
                onChange: handleCheckbox,
                checked:
                  form.employment_placement_attributes.hired_by_school ===
                  "YES",
                value: "YES",
              }}
              type="radio"
            />
          </div>
          <div className="checkbox">
            No
            <FormGroup
              propsInput={{
                type: "radio",
                name: "hired_by_school",
                onChange: handleCheckbox,
                checked:
                  form.employment_placement_attributes.hired_by_school === "NO",
                value: "NO",
              }}
              type="radio"
            />
          </div>
        </div>
      </div>
      <div className="checkbox-group">
        <p className=" bold">
          For this position, was the student actively assisted in obtaining
          employment by the institution?
        </p>
        <div>
          <div className="checkbox">
            Yes
            <FormGroup
              propsInput={{
                type: "radio",
                name: "placed_by_school",
                onChange: handleCheckbox,
                checked:
                  form.employment_placement_attributes.placed_by_school ===
                  "YES",
                value: "YES",
              }}
              type="radio"
            />
          </div>
          <div className="checkbox">
            No
            <FormGroup
              propsInput={{
                type: "radio",
                name: "placed_by_school",
                onChange: handleCheckbox,
                checked:
                  form.employment_placement_attributes.placed_by_school ===
                  "NO",
                value: "NO",
              }}
              type="radio"
            />
          </div>
        </div>
      </div>
      <div className="input__group employment-attribute">
        <FormGroup
          propsInput={{
            name: "employer_name",
            placeholder: "Name",
            onChange,
            onFocus,
            value: form.employment_placement_attributes.employer_name,
            disabled: isSubmitting,
          }}
          error={formErrors.employer_name}
          label="Name of the firm/company "
        />
      </div>
      <div className="input__group employment-attribute">
        <div className="Place">
          <p className="Place__label">Address of the firm/company</p>
          <Autocomplete
            placeholder="Address"
            onPlaceSelected={handleChangePlace}
            types={["address"]}
            onFocus={handleFocusPlace}
            value={address}
            onChange={handleChangeAddress}
            disabled={isSubmitting}
          />
          {(formErrors.employer_city || formErrors.employer_state) && (
            <div className="error-message">
              This field is invalid please type a new place
            </div>
          )}
        </div>
      </div>
      <div className="input__group employment-attribute">
        <FormGroup
          propsInput={{
            name: "employer_city",
            placeholder: "City",
            onChange,
            onFocus,
            value: form.employment_placement_attributes.employer_city,
            disabled: isSubmitting,
          }}
          error={formErrors.employer_city}
          label="City of the firm/company"
        />
      </div>
      <div className="input__group employment-attribute">
        <FormGroup
          propsInput={{
            name: "employer_state",
            placeholder: "State",
            onChange,
            onFocus,
            value: form.employment_placement_attributes.employer_state,
            disabled: isSubmitting,
          }}
          error={formErrors.employer_state}
          label="State of the firm/company"
        />
      </div>
      <div className="input__group employment-attribute">
        <FormGroup
          propsInput={{
            name: "employer_zip_code",
            placeholder: "Zip code",
            onChange,
            onFocus,
            value: form.employment_placement_attributes.employer_zip_code,
            disabled: isSubmitting,
          }}
          error={formErrors.employer_zip_code}
          label="Zip Code of the firm/company"
        />
      </div>
      <div className="input__group employment-attribute">
        <FormGroup
          propsInput={{
            name: "employer_phone",
            placeholder: "Phone",
            onChange: onChangeNumber,
            onFocus,
            value: form.employment_placement_attributes.employer_phone,
            disabled: isSubmitting,
          }}
          error={formErrors.employer_phone}
          label="Telephone number of the firm/company"
        />
      </div>
      <div className="input__group employment-attribute">
        <FormGroup
          propsInput={{
            name: "employer_contact_name",
            placeholder: "Supervisor’s Full Name",
            onChange,
            onFocus,
            value: form.employment_placement_attributes.employer_contact_name,
            disabled: isSubmitting,
          }}
          error={formErrors.employer_contact_name}
          label="Immediate Supervisor’s Full Name"
        />
      </div>
      <div className="input__group employment-attribute">
        <FormGroup
          propsInput={{
            name: "employer_contact_phone",
            placeholder: "Supervisor’s Phone",
            onChange: onChangeNumber,
            onFocus,
            value: form.employment_placement_attributes.employer_contact_phone,
            disabled: isSubmitting,
          }}
          error={formErrors.employer_contact_phone}
          label="Immediate Supervisor’s Phone"
        />
      </div>
      <div className="input__group employment-attribute">
        <FormGroup
          propsInput={{
            name: "employer_contact_email",
            placeholder: "Supervisor’s Email",
            onChange,
            onFocus,
            value: form.employment_placement_attributes.employer_contact_email,
            disabled: isSubmitting,
          }}
          error={formErrors.employer_contact_email}
          label="Immediate Supervisor’s Email"
        />
      </div>
    </>
  );

  function renderMilitary() {
    return (
      <>
        <div className="input__group">
          <div className="select">
            <p>Name of Military Branch</p>
            <SingleSelectCheckbox
              onChange={onChangeSelect}
              name="branch_name"
              options={OPTION_MILITARY_BRANCH}
              value={{ value: form.military_placement_attributes.branch_name }}
              error={formErrors.branch_name}
              disabled={isSubmitting}
            />
          </div>
        </div>
        <div className="input__group employment-attribute">
          <div className="Place">
            <p className="Place__label">Address of the recruiting office</p>
            <Autocomplete
              placeholder="Address"
              onPlaceSelected={handleChangePlace}
              types={["address"]}
              onFocus={handleFocusPlace}
              value={address}
              onChange={handleChangeAddress}
              disabled={isSubmitting}
            />
            {(formErrors.recruiting_office_city ||
              formErrors.recruiting_office_state) && (
              <div className="error-message">
                This field is invalid please type a new place
              </div>
            )}
          </div>
        </div>
        <div className="input__group employment-attribute">
          <FormGroup
            propsInput={{
              name: "recruiting_office_city",
              placeholder: "City",
              onChange,
              onFocus,
              value: form.military_placement_attributes.recruiting_office_city,
              disabled: isSubmitting,
            }}
            error={formErrors.recruiting_office_city}
            label="City of the recruiting office"
          />
        </div>
        <div className="input__group employment-attribute">
          <FormGroup
            propsInput={{
              name: "recruiting_office_state",
              placeholder: "State",
              onChange,
              onFocus,
              value: form.military_placement_attributes.recruiting_office_state,
              disabled: isSubmitting,
            }}
            error={formErrors.recruiting_office_state}
            label="State of the recruiting office"
          />
        </div>
        <div className="input__group employment-attribute">
          <FormGroup
            propsInput={{
              name: "recruiting_office_zip_code",
              placeholder: "Zip code",
              onChange,
              onFocus,
              value:
                form.military_placement_attributes.recruiting_office_zip_code,
              disabled: isSubmitting,
            }}
            error={formErrors.recruiting_office_zip_code}
            label="Zip Code of the recruiting office"
          />
        </div>
        <div className="input__group employment-attribute">
          <FormGroup
            propsInput={{
              name: "recruiting_office_phone",
              placeholder: "Phone",
              onChange: onChangeNumber,
              onFocus,
              value: form.military_placement_attributes.recruiting_office_phone,
              disabled: isSubmitting,
            }}
            error={formErrors.recruiting_office_phone}
            label="Recruiting office phone number"
          />
        </div>
      </>
    );
  }

  function renderContinuingEducation() {
    return (
      <>
        <div className="input__group employment-attribute">
          <FormGroup
            propsInput={{
              name: "institution_name",
              placeholder: "Institution Name",
              onChange,
              onFocus,
              value: form.education_placement_attributes.institution_name,
              disabled: isSubmitting,
            }}
            error={formErrors.institution_name}
            label="Name of post secondary education institution"
          />
        </div>
        <div className="input__group employment-attribute">
          <FormGroup
            propsInput={{
              name: "program_name",
              placeholder: "Name of Program or Major",
              onChange,
              onFocus,
              value: form.education_placement_attributes.program_name,
              disabled: isSubmitting,
            }}
            error={formErrors.program_name}
            label="Name of Program or Major"
          />
        </div>
        <div className="input__group employment-attribute">
          <div className="Place">
            <p className="Place__label">Address of the institution</p>
            <Autocomplete
              placeholder="Address"
              onPlaceSelected={handleChangePlace}
              types={["address"]}
              onFocus={handleFocusPlace}
              value={address}
              onChange={handleChangeAddress}
              disabled={isSubmitting}
            />
            {(formErrors.institution_city || formErrors.institution_state) && (
              <div className="error-message">
                This field is invalid please type a new place
              </div>
            )}
          </div>
        </div>
        <div className="input__group employment-attribute">
          <FormGroup
            propsInput={{
              name: "institution_city",
              placeholder: "City",
              onChange,
              onFocus,
              value: form.education_placement_attributes.institution_city,
              disabled: isSubmitting,
            }}
            error={formErrors.institution_city}
            label="City of the institution"
          />
        </div>
        <div className="input__group employment-attribute">
          <FormGroup
            propsInput={{
              name: "institution_state",
              placeholder: "State",
              onChange,
              onFocus,
              value: form.education_placement_attributes.institution_state,
              disabled: isSubmitting,
            }}
            error={formErrors.institution_state}
            label="State of the institution"
          />
        </div>
        <div className="input__group employment-attribute">
          <FormGroup
            propsInput={{
              name: "institution_zip_code",
              placeholder: "Zip code",
              onChange,
              onFocus,
              value: form.education_placement_attributes.institution_zip_code,
              disabled: isSubmitting,
            }}
            error={formErrors.institution_zip_code}
            label="Zip Code of the institution"
          />
        </div>
      </>
    );
  }

  const renderForm = () => {
    switch (form.outcome) {
      case EmployedInField:
      case EmployedInRelatedField:
      case EmployedOutOfField:
        return renderInField();

      case SelfEmployed:
        return renderSelfEmployed();
      case Military:
        return renderMilitary();
      case ContinuingEducation:
        return renderContinuingEducation();
      default:
        break;
    }
  };

  return (
    <div className="AddPlacement  form-student dashboard__item__inner">
      <Form noValidate onSubmit={onSubmit}>
        <div className="form-student__text">
          <p className="text--xlarge bold">New Placement</p>
        </div>
        <div className="AddPlacement__inner form-student__inner">
          <div className="input__group">
            <div className="select">
              <p>Program</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="program_id"
                options={OPTIONS_PROGRAM}
                value={{ value: form.program_id }}
                error={formErrors.program_id}
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div className="input__group">
            <div className="select">
              <p>Outcome</p>
              <SingleSelectCheckbox
                onChange={onChangeSelect}
                name="outcome"
                options={OUTCOMES.map((item) => ({ value: item, label: item }))}
                value={{ value: form.outcome }}
                error={formErrors.outcome}
                disabled={isSubmitting}
              />
            </div>
          </div>
          {form.outcome && renderForm()}
          {form.outcome &&
            EMPLOYMENT_OUTCOMES.includes(form.outcome) &&
            renderCommonEmploymentForm()}
          {form.outcome && UNAVAILABILITY_OUTCOMES.includes(form.outcome) && (
            <div className="input__group">
              <div className="Date-picker">
                <p className="Date-picker__label">
                  What is unavailability date?
                </p>
                <SingleDatePicker
                  placeholder="Select Date"
                  value={
                    form.unavailability_placement_attributes.unavailability_date
                  }
                  onChange={handleChangeDate}
                  disabled={isSubmitting}
                  name="unavailability_date"
                />
              </div>
            </div>
          )}
          {form.outcome && (
            <>
              <div className="Documents">
                <p>Documentation</p>
                {form.files && !!form.files.length && (
                  <div className="Documents__inner">
                    {form.files.map((item, index) => (
                      <div
                        className="Document--item"
                        key={item.name || item.id}
                      >
                        <p>{item.name || item.filename}</p>
                        <button
                          type="button"
                          className="Documents__delete"
                          onClick={onDeleteDocument(item, index)}
                        >
                          <Delete />
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="input__group">
                <div className="add-document">
                  <Dropzone handleDrop={handleDrop} />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="button-group">
          <button
            className="button button--back"
            type="submit"
            onClick={handleBack}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            className="button button--primary"
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </button>
        </div>
      </Form>
    </div>
  );
}

AddPlacement.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    program_id: PropTypes.number,
    outcome: PropTypes.string,
    employment_placement_attributes: PropTypes.shape({
      soc_code: PropTypes.string,
      job_type: PropTypes.string,
      same_employer: PropTypes.string,
      hired_by_school: PropTypes.string,
      same_employer_promotion: PropTypes.string,
      same_employer_condition: PropTypes.string,
      longer_than_5_weeks: PropTypes.string,
      placed_by_school: PropTypes.string,
      chose_part_time: PropTypes.string,
      self_employed_type: PropTypes.string,
      job_title: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      start_date: PropTypes.any,
      // eslint-disable-next-line react/forbid-prop-types
      offer_date: PropTypes.any,
      // eslint-disable-next-line react/forbid-prop-types
      end_date: PropTypes.any,
      hours_per_week: PropTypes.string,
      duration: PropTypes.string,
      salary_type: PropTypes.string,
      salary: PropTypes.string,
      employer_name: PropTypes.string,
      employer_city: PropTypes.string,
      employer_state: PropTypes.string,
      employer_zip_code: PropTypes.string,
      employer_phone: PropTypes.string,
      employer_contact_name: PropTypes.string,
      employer_contact_phone: PropTypes.string,
      employer_contact_email: PropTypes.string,
    }),
    military_placement_attributes: PropTypes.shape({
      branch_name: PropTypes.string,
      recruiting_office_address: PropTypes.string,
      recruiting_office_city: PropTypes.string,
      recruiting_office_state: PropTypes.string,
      recruiting_office_zip_code: PropTypes.string,
      recruiting_office_phone: PropTypes.string,
    }),
    education_placement_attributes: PropTypes.shape({
      program_name: PropTypes.string,
      institution_name: PropTypes.string,
      institution_city: PropTypes.string,
      institution_state: PropTypes.string,
      institution_zip_code: PropTypes.string,
    }),
    unavailability_placement_attributes: PropTypes.shape({
      // eslint-disable-next-line react/forbid-prop-types
      unavailability_date: PropTypes.any,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    files: PropTypes.array,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  handleBack: PropTypes.func.isRequired,
  handleDrop: PropTypes.func.isRequired,
  handleCheckbox: PropTypes.func.isRequired,
  handleChangePlace: PropTypes.func.isRequired,
  handleFocusPlace: PropTypes.func.isRequired,
  handleChangeAddress: PropTypes.func.isRequired,
  address: PropTypes.string,
  onDeleteDocument: PropTypes.func.isRequired,
  handleChangeCode: PropTypes.func.isRequired,
  onChangeNumber: PropTypes.func.isRequired,
};

AddPlacement.defaultProps = {
  form: {
    program_id: undefined,
    outcome: "",
    employment_placement_attributes: {
      soc_code: "",
      job_type: "single",
      same_employer: "NO",
      same_employer_promotion: "NO",
      same_employer_condition: "NO",
      hired_by_school: "NO",
      longer_than_5_weeks: "NO",
      placed_by_school: "NO",
      chose_part_time: "NO",
      job_title: "",
      self_employed_type: "",
      offer_date: new Date(),
      start_date: new Date(),
      end_date: new Date(),
      hours_per_week: "",
      duration: "less than 1 month",
      salary_type: "Annual Salary",
      salary: "",
      employer_name: "",
      employer_city: "",
      employer_state: "",
      employer_zip_code: "",
      employer_phone: "",
      employer_contact_name: "",
      employer_contact_phone: "",
      employer_contact_email: "",
    },
    military_placement_attributes: {
      branch_name: "",
      recruiting_office_address: "",
      recruiting_office_city: "",
      recruiting_office_state: "",
      recruiting_office_zip_code: "",
      recruiting_office_phone: "",
    },
    education_placement_attributes: {
      program_name: "",
      institution_name: "",
      institution_city: "",
      institution_state: "",
      institution_zip_code: "",
    },
    unavailability_placement_attributes: {
      unavailability_date: new Date(),
    },
    files: [],
  },
  formErrors: {},
  address: "",
  isSubmitting: false,
};

export default AddPlacement;
