import React from "react";
import { useSelector } from "react-redux";
import { EMPLOYMENT_SALARY } from "../../../utils/constants";
import { Calendar, Lock, Wallet } from "../../common/icons";
import noPlacement from "../../../assets/images/noPlacement.png";
import noEmployment2 from "../../../assets/images/noEmployment2.png";
import noEmployment3 from "../../../assets/images/noEmployment3.png";

function Placement() {
  const storeDashboard = useSelector((store) => store.dashboard);
  const { data } = storeDashboard;
  const dataPlacement = data.placement || {};
  const dataEmploymentHour =
    data.employment && data.employment.employment_data_hours
      ? data.employment.employment_data_hours
      : {};
  const dataEmploymentDuration =
    data.employment && data.employment.employment_data_duration
      ? data.employment.employment_data_duration
      : {};
  const dataEmploymentSalary =
    data.employment && data.employment.employment_data_salary
      ? data.employment.employment_data_salary
      : [];
  const dataEmploymentSalaryAverage = dataEmploymentSalary.filter(
    (item) => item.label === "Average"
  );

  const placement = [
    {
      id: 1,
      text: "Employed In-Field",
      value: dataPlacement.employed_in_field || 0,
    },
    {
      id: 2,
      text: "Employed Out of Field",
      value: dataPlacement.employed_out_of_field || 0,
    },
    {
      id: 3,
      text: "Self-Employed",
      value: dataPlacement.self_employed || 0,
    },
    {
      id: 4,
      text: "Still Seeking Employment In-Field",
      value: dataPlacement.still_seeking || 0,
    },
    {
      id: 5,
      text: "Continuing Education",
      value: dataPlacement.continuing_education || 0,
    },
    {
      id: 6,
      text: "Military",
      value: dataPlacement.military || 0,
    },
    {
      id: 7,
      text: "Not Seeking Employment",
      value: dataPlacement.not_seeking || 0,
    },
    {
      id: 8,
      text: "Status Unknown",
      value: dataPlacement.unknown || 0,
    },
  ];

  let maxPlacement = 0;
  placement.forEach((item) => {
    if (item.value > maxPlacement) maxPlacement = item.value;
  });

  return (
    <div className="Placement ">
      <div className="Placement__inner">
        <div className="Placement__inner__left dashboard--children">
          <p className="text--title bold title">Placement</p>
          {!dataPlacement.total_records ? (
            <div className="Nodata">
              <p className="--noData">
                There’s currently no placement data to display
              </p>
              <img src={noPlacement} alt="" />
            </div>
          ) : (
            placement.map((item) => (
              <div className="placement--item" key={item.text}>
                <p className="text--xsmall ">{item.text}</p>
                <div>
                  <span
                    className="range"
                    style={{ width: `${(item.value * 100) / maxPlacement}%` }}
                  />
                  <p className="text--xsmall bold">{item.value}</p>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="Placement__inner__right">
          <div>
            <div className="weekly--item dashboard--children">
              <p className="text--title bold title">Weekly Employment Avg.</p>
              {!dataEmploymentHour.total_records ? (
                <div className="Nodata">
                  <p className="--noData">
                    There’s currently no employment data to display
                  </p>
                  <img src={noEmployment2} alt="" />
                </div>
              ) : (
                <div>
                  <div className="info">
                    <Lock />
                    <p className="text--large bold">
                      {Math.round(Number(dataEmploymentHour.average) * 100) /
                        100}{" "}
                      hrs/week
                    </p>
                  </div>
                  <div className="percent">
                    <span>{`${dataEmploymentHour.full_time}%`}</span>
                    <p className="text--xsmall">Full time</p>
                  </div>
                  <div className="percent">
                    <span>{`${dataEmploymentHour.part_time}%`}</span>
                    <p className="text--xsmall">Part time</p>
                  </div>
                </div>
              )}
            </div>

            <div className="weekly--item dashboard--children">
              <p className="text--title bold title">Employment Duration Avg.</p>
              {!dataEmploymentDuration.total_records ? (
                <div className="Nodata">
                  <p className="--noData">
                    There’s currently no employment data to display
                  </p>
                  <img src={noEmployment3} alt="" />
                </div>
              ) : (
                <div>
                  <div className="info">
                    <Calendar />
                    <p className="text--large bold">
                      {dataEmploymentDuration.average}
                    </p>
                  </div>
                  <div className="percent">
                    <span>{`${dataEmploymentDuration.less_than_3_months}%`}</span>
                    <p className="text--xsmall">Less than 3 mon.</p>
                  </div>
                  <div className="percent">
                    <span>{`${dataEmploymentDuration.three_to_six_months}%`}</span>
                    <p className="text--xsmall">3 - 6 mon.</p>
                  </div>
                  <div className="percent">
                    <span>{`${dataEmploymentDuration.less_than_3_months}%`}</span>
                    <p className="text--xsmall">more than 6 mon.</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="weekly--item salary dashboard--children">
            <p className="text--title bold title">Salary Avg.</p>
            {dataEmploymentSalary.some(
              (item) => !item.value && item.label === "Total Records"
            ) ? (
              <div className="Nodata">
                <p className="--noData">
                  There’s currently no salary data to display
                </p>
                <div className="img_group">
                  <img src={noEmployment3} alt="" />
                  <img src={noEmployment3} alt="" />
                </div>
              </div>
            ) : (
              <div>
                <div className="info">
                  <Wallet />
                  <p className="text--large bold">
                    {dataEmploymentSalaryAverage.length
                      ? `$${Number(
                          Math.ceil(dataEmploymentSalaryAverage[0].value)
                        ).toLocaleString()}`
                      : 0}
                  </p>
                </div>
                <div className="salary__inner">
                  {dataEmploymentSalary
                    .filter(
                      (item) =>
                        item.label !== "Average" &&
                        item.label !== "Total Records"
                    )
                    .map((item) => (
                      <div className="percent" key={item.label}>
                        <span>{`${item.value}%`}</span>
                        <p className="text--xsmall">
                          {EMPLOYMENT_SALARY[item.label]}
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Placement;
