/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/default-param-last */
import * as types from "../constants";

const initialState = {
  reporting: {
    data: {},
    loading: false,
    error: null,
  },
  preview: {
    data: {},
    loading: false,
    error: null,
  },
  report: {
    data: "",
    loading: false,
    error: null,
  },
  documentation: {
    data: "",
    loading: false,
    error: null,
  },
  spfs: {
    data: "",
    loading: false,
    error: null,
  },
  exportIdentificationData: {
    data: "",
    loading: false,
    error: null,
  },
  completer: {
    data: "",
    loading: false,
    error: null,
  },
  selected: {
    reporting_period: {},
    programs: {},
    branches: {},
    reporting_year: {},
    page: 1,
    month: { value: 1, label: "January" },
  },
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_REPORTING:
      return {
        ...state,
        reporting: {
          ...initialState.reporting,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_SUCCEED:
      return {
        ...state,
        reporting: {
          ...state.reporting,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_FAIL:
      return {
        ...state,
        reporting: {
          ...state.reporting,
          error: payload,
          loading: false,
        },
      };

    // get PPPE
    case types.GET_REPORTING_BPPE_PREVIEW:
      return {
        ...state,
        preview: {
          ...initialState.preview,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_BPPE_PREVIEW_SUCCEED:
      return {
        ...state,
        preview: {
          ...state.preview,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_BPPE_PREVIEW_FAIL:
      return {
        ...state,
        preview: {
          ...state.preview,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_BPPE_REPORT:
      return {
        ...state,
        report: {
          ...initialState.report,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_BPPE_REPORT_SUCCEED:
      return {
        ...state,
        report: {
          ...state.report,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_BPPE_REPORT_FAIL:
      return {
        ...state,
        report: {
          ...state.report,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_BPPE_DOCUMENT:
      return {
        ...state,
        documentation: {
          ...initialState.documentation,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_BPPE_DOCUMENT_SUCCEED:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_BPPE_DOCUMENT_FAIL:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_BPPE_SPFS:
      return {
        ...state,
        spfs: {
          ...initialState.spfs,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_BPPE_SPFS_SUCCEED:
      return {
        ...state,
        spfs: {
          ...state.spfs,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_BPPE_SPFS_FAIL:
      return {
        ...state,
        spfs: {
          ...state.spfs,
          error: payload,
          loading: false,
        },
      };

    case types.GET_EXPORT_IDENTIFICATION_DATA:
      return {
        ...state,
        exportIdentificationData: {
          ...initialState.exportIdentificationData,
          loading: true,
          error: {},
        },
      };
    case types.GET_EXPORT_IDENTIFICATION_DATA_SUCCEED:
      return {
        ...state,
        exportIdentificationData: {
          ...state.exportIdentificationData,
          data: payload,
          loading: false,
        },
      };
    case types.GET_EXPORT_IDENTIFICATION_DATA_FAIL:
      return {
        ...state,
        exportIdentificationData: {
          ...state.exportIdentificationData,
          error: payload,
          loading: false,
        },
      };

    // get CIRR
    case types.GET_REPORTING_CIRR_PREVIEW:
      return {
        ...state,
        preview: {
          ...initialState.preview,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_CIRR_PREVIEW_SUCCEED:
      return {
        ...state,
        preview: {
          ...state.preview,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_CIRR_PREVIEW_FAIL:
      return {
        ...state,
        preview: {
          ...state.preview,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_CIRR_REPORT:
      return {
        ...state,
        report: {
          ...initialState.report,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_CIRR_REPORT_SUCCEED:
      return {
        ...state,
        report: {
          ...state.report,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_CIRR_REPORT_FAIL:
      return {
        ...state,
        report: {
          ...state.report,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_CIRR_DOCUMENT:
      return {
        ...state,
        documentation: {
          ...initialState.documentation,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_CIRR_DOCUMENT_SUCCEED:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_CIRR_DOCUMENT_FAIL:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          error: payload,
          loading: false,
        },
      };

    // get BPSS
    case types.GET_REPORTING_BPSS_PREVIEW:
      return {
        ...state,
        preview: {
          ...initialState.preview,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_BPSS_PREVIEW_SUCCEED:
      return {
        ...state,
        preview: {
          ...state.preview,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_BPSS_PREVIEW_FAIL:
      return {
        ...state,
        preview: {
          ...state.preview,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_BPSS_REPORT:
      return {
        ...state,
        report: {
          ...initialState.report,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_BPSS_REPORT_SUCCEED:
      return {
        ...state,
        report: {
          ...state.report,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_BPSS_REPORT_FAIL:
      return {
        ...state,
        report: {
          ...state.report,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_BPSS_DOCUMENT:
      return {
        ...state,
        documentation: {
          ...initialState.documentation,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_BPSS_DOCUMENT_SUCCEED:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_BPSS_DOCUMENT_FAIL:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          error: payload,
          loading: false,
        },
      };

    // get TWC
    case types.GET_REPORTING_TWC_PREVIEW:
      return {
        ...state,
        preview: {
          ...initialState.preview,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_TWC_PREVIEW_SUCCEED:
      return {
        ...state,
        preview: {
          ...state.preview,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_TWC_PREVIEW_FAIL:
      return {
        ...state,
        preview: {
          ...state.preview,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_TWC_REPORT:
      return {
        ...state,
        report: {
          ...initialState.report,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_TWC_REPORT_SUCCEED:
      return {
        ...state,
        report: {
          ...state.report,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_TWC_REPORT_FAIL:
      return {
        ...state,
        report: {
          ...state.report,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_TWC_DOCUMENT:
      return {
        ...state,
        documentation: {
          ...initialState.documentation,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_TWC_DOCUMENT_SUCCEED:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_TWC_DOCUMENT_FAIL:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_TWC_COMPLETER:
      return {
        ...state,
        completer: {
          ...initialState.completer,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_TWC_COMPLETER_SUCCEED:
      return {
        ...state,
        completer: {
          ...state.completer,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_TWC_COMPLETER_FAIL:
      return {
        ...state,
        completer: {
          ...state.completer,
          error: payload,
          loading: false,
        },
      };

    // get DPOS
    case types.GET_REPORTING_DPOS_PREVIEW:
      return {
        ...state,
        preview: {
          ...initialState.preview,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_DPOS_PREVIEW_SUCCEED:
      return {
        ...state,
        preview: {
          ...state.preview,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_DPOS_PREVIEW_FAIL:
      return {
        ...state,
        preview: {
          ...state.preview,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_DPOS_REPORT:
      return {
        ...state,
        report: {
          ...initialState.report,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_DPOS_REPORT_SUCCEED:
      return {
        ...state,
        report: {
          ...state.report,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_DPOS_REPORT_FAIL:
      return {
        ...state,
        report: {
          ...state.report,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_DPOS_DOCUMENT:
      return {
        ...state,
        documentation: {
          ...initialState.documentation,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_DPOS_DOCUMENT_SUCCEED:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_DPOS_DOCUMENT_FAIL:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          error: payload,
          loading: false,
        },
      };

    // get COE
    case types.GET_REPORTING_COE_PREVIEW:
      return {
        ...state,
        preview: {
          ...initialState.preview,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_COE_PREVIEW_SUCCEED:
      return {
        ...state,
        preview: {
          ...state.preview,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_COE_PREVIEW_FAIL:
      return {
        ...state,
        preview: {
          ...state.preview,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_COE_REPORT:
      return {
        ...state,
        report: {
          ...initialState.report,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_COE_REPORT_SUCCEED:
      return {
        ...state,
        report: {
          ...state.report,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_COE_REPORT_FAIL:
      return {
        ...state,
        report: {
          ...state.report,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_COE_DOCUMENT:
      return {
        ...state,
        documentation: {
          ...initialState.documentation,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_COE_DOCUMENT_SUCCEED:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_COE_DOCUMENT_FAIL:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          error: payload,
          loading: false,
        },
      };

    // get ACCET
    case types.GET_REPORTING_ACCET_PREVIEW:
      return {
        ...state,
        preview: {
          ...initialState.preview,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_ACCET_PREVIEW_SUCCEED:
      return {
        ...state,
        preview: {
          ...state.preview,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_ACCET_PREVIEW_FAIL:
      return {
        ...state,
        preview: {
          ...state.preview,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_ACCET_REPORT:
      return {
        ...state,
        report: {
          ...initialState.report,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_ACCET_REPORT_SUCCEED:
      return {
        ...state,
        report: {
          ...state.report,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_ACCET_REPORT_FAIL:
      return {
        ...state,
        report: {
          ...state.report,
          error: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_ACCET_DOCUMENT:
      return {
        ...state,
        documentation: {
          ...initialState.documentation,
          loading: true,
          error: {},
        },
      };
    case types.GET_REPORTING_ACCET_DOCUMENT_SUCCEED:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          data: payload,
          loading: false,
        },
      };
    case types.GET_REPORTING_ACCET_DOCUMENT_FAIL:
      return {
        ...state,
        documentation: {
          ...state.documentation,
          error: payload,
          loading: false,
        },
      };

    case types.SET_SELECTED_FILTER_REPORT:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...payload,
        },
      };
    default:
      return state;
  }
}
