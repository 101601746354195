import React from "react";
import { Controller } from "react-hook-form";
import type { FieldValues, Path, Control } from "react-hook-form";
import cn from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Root,
  Trigger,
  Value,
  Icon,
  Portal,
  Content,
  Viewport,
  Item,
  ItemText,
  ScrollDownButton,
} from "@radix-ui/react-select";
import css from "./styles.module.scss";

const cx = cn.bind(css);

type Props<I extends FieldValues, T extends string | undefined> = {
  name: Path<I>;
  control: Control<I>;
  label?: string;
  placeholder?: string;
  options: {
    label: string;
    value: T;
  }[];
};

export function SelectInput<
  I extends FieldValues,
  T extends string | undefined
>(props: Props<I, T>) {
  const { name, control, label, options } = props;
  const placeholder = props?.placeholder ?? "Select";

  return (
    <Controller
      name={name}
      control={control}
      render={(render) => {
        const { value, onChange } = render.field;
        const error = render.fieldState.error?.message;
        const hasError = error !== undefined;
        return (
          <fieldset>
            {(label || error) && (
              <div className={css.textArea}>
                <label className={css.label} htmlFor={name}>
                  {label}
                </label>
                <div className={css.error}>{error}</div>
              </div>
            )}
            <Root name={name} value={value as string} onValueChange={onChange}>
              <Trigger id={name} className={cx("trigger", { hasError })}>
                <Value placeholder={placeholder} />
                <Icon>
                  <FontAwesomeIcon icon={["fas", "chevron-down"]} size="1x" />
                </Icon>
              </Trigger>

              <Portal>
                <Content className={css.content}>
                  <Viewport>
                    {options.map((option) => (
                      <Item
                        key={option.value}
                        className={css.item}
                        value={option.value!}
                      >
                        <ItemText>{option.label}</ItemText>
                      </Item>
                    ))}
                  </Viewport>
                  <ScrollDownButton />
                </Content>
              </Portal>
            </Root>
          </fieldset>
        );
      }}
    />
  );
}
