import React from "react";

export default function () {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4H17C18.5977 4 19.9037 5.24892 19.9949 6.82373L20 7L20.0009 8.17102C21.1656 8.58312 22 9.69412 22 11V13C22 14.3059 21.1656 15.4169 20.0009 15.829L20 17C20 18.5977 18.7511 19.9037 17.1763 19.9949L17 20H5C3.40232 20 2.09634 18.7511 2.00509 17.1763L2 17V7C2 5.40232 3.24892 4.09634 4.82373 4.00509L5 4H17H5ZM17 6H5C4.48716 6 4.06449 6.38604 4.00673 6.88338L4 7V17C4 17.5128 4.38604 17.9355 4.88338 17.9933L5 18H17C17.5128 18 17.9355 17.614 17.9933 17.1166L18 17V16H17C15.3431 16 14 14.6569 14 13V11C14 9.34315 15.3431 8 17 8H18V7C18 6.48716 17.614 6.06449 17.1166 6.00673L17 6ZM19 10H17C16.4477 10 16 10.4477 16 11V13C16 13.5523 16.4477 14 17 14H19C19.5523 14 20 13.5523 20 13V11C20 10.4477 19.5523 10 19 10Z"
        fill="#16222F"
      />
    </svg>
  );
}
