import React from "react";
import { Collapse } from "react-bootstrap";
import FormGroup from "./FormGroup";
import { ArrowDown2, ArrowUp2 } from "./icons";

function CollapseCheckbox({
  data = {},
  onChange = () => {},
  checked = false,
  value = "",
}) {
  const [open, setOpen] = React.useState(false);

  if (!Object.keys(data).length) return null;

  return (
    <div className="Code__item">
      <div className="checkbox">
        • {data.title}
        <FormGroup
          propsInput={{
            type: "checkbox",
            name: "description",
            onChange,
            value,
            checked,
          }}
          type="radio"
        />
      </div>
      <div className="description">
        <div
          onClick={() => setOpen(!open)}
          role="button"
          tabIndex="0"
          className="btn-collape"
        >
          <p>{open ? "Hide Description" : "View Description"}</p>
          <div className="iconArrow">
            {open ? <ArrowUp2 /> : <ArrowDown2 />}
          </div>
        </div>
        <Collapse in={open} className="description__text">
          <div>
            <p>{data.text1}</p>
            <p>{data.text2}</p>
          </div>
        </Collapse>
      </div>
    </div>
  );
}

export default CollapseCheckbox;
