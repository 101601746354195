import React from "react";
import { ReactComponent as Logo } from "assets/images/survey-logo.svg";
import css from "./styles.module.scss";

type Props = {
  children: ReactNode;
};

export function Layout(props: Props) {
  const { children } = props;

  return (
    <section className={css.layout}>
      <header className={css.header}>
        <Logo className={css.logo} />
      </header>
      <div className={css.content}>{children}</div>
    </section>
  );
}
