/* eslint-disable default-param-last */
/* eslint-disable @typescript-eslint/default-param-last */
import * as types from "../constants";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case types.GET_AGENCIES:
      return { ...initialState, loading: true };
    case types.GET_AGENCIES_SUCCEED:
      return {
        ...state,
        data: payload,
        loading: false,
      };
    case types.GET_AGENCIES_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    default:
      return state;
  }
}
