import React from "react";
import Dropzone from "react-dropzone";
import PropTypes from "prop-types";

function DropFile({ handleDrop, accept, multiple }) {
  return (
    <div className="Dropzone">
      <Dropzone
        onDrop={handleDrop}
        style={{ width: "300px", height: "300px" }}
        accept={accept}
        multiple={multiple}
      >
        {({ getRootProps, getInputProps }) => (
          <div>
            <div {...getRootProps({ className: "dropzone" })}>
              <button
                className="button button--primary button--small"
                type="button"
              >
                Select file
              </button>
              <input {...getInputProps()} className="input_file" />
              <p>or drop file here</p>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
}

DropFile.propTypes = {
  handleDrop: PropTypes.func.isRequired,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
};

DropFile.defaultProps = {
  accept: "",
  multiple: true,
};

export default DropFile;
