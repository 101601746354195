import React from "react";
import cn from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfirmationDialog } from "../ConfirmationDialog";
import css from "./styles.module.scss";

const cx = cn.bind(css);

type Props = {
  name: string;
  url?: string;
  onDelete: () => void;
};

export function UploadedFile(props: Props) {
  const { name, url, onDelete } = props;

  return (
    <div className={css.file} key={name}>
      {url ? (
        <a className={cx("filename", "link")} href={url}>
          {name}
        </a>
      ) : (
        <span className={css.filename}>{name}</span>
      )}
      <ConfirmationDialog
        type="danger"
        description={`Are you sure you want to delete '${name}'?`}
        actionLabel="Delete"
        onAction={() => onDelete()}
      >
        <button
          data-testid="delete-document-button"
          type="button"
          className={css.fileDelete}
        >
          <FontAwesomeIcon icon={["fas", "close"]} size="1x" />
        </button>
      </ConfirmationDialog>
    </div>
  );
}
