import * as types from "../constants";

const initialState = {
  list: {
    data: [],
    meta: {
      total: 0,
      perPage: 10,
    },
    loading: false,
    error: null,
  },
  info: {
    data: {},
    loading: false,
    error: null,
  },
  surveyStudent: {
    data: {},
    loading: false,
    error: null,
  },
};

/* eslint-disable @typescript-eslint/default-param-last */
export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    // get survey
    case types.GET_SURVEYS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case types.GET_SURVEYS_SUCCEED:
      return {
        ...state,
        list: {
          ...state.list,
          data: payload.data,
          meta: payload.meta,
          loading: false,
        },
      };
    case types.GET_SURVEYS_FAIL:
      return {
        ...state,
        list: {
          ...state.list,
          error: payload,
          loading: false,
        },
      };

    // get survey info
    case types.GET_SURVEY_INFO:
      return {
        ...state,
        info: {
          ...state.info,
          loading: true,
        },
      };
    case types.GET_SURVEY_INFO_SUCCEED:
      return {
        ...state,
        info: {
          ...state.info,
          data: payload.data,
          loading: false,
        },
      };
    case types.GET_SURVEY_INFO_FAIL:
      return {
        ...state,
        info: {
          ...state.info,
          error: payload,
          loading: false,
        },
      };

    case types.SEND_SURVEY:
      return {
        ...state,
        surveyStudent: {
          ...state.surveyStudent,
          loading: true,
        },
      };
    case types.SEND_SURVEY_SUCCEED:
      return {
        ...state,
        surveyStudent: {
          ...state.surveyStudent,
          data: payload.data,
          loading: false,
        },
      };
    case types.SEND_SURVEY_FAIL:
      return {
        ...state,
        surveyStudent: {
          ...state.surveyStudent,
          error: payload,
          loading: false,
        },
      };

    default:
      return state;
  }
}
