import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { replaceParamStudent } from "utils/helpers";
import { TableActions } from "components";
import { Modal } from "react-bootstrap";
import {
  ContinuingEducation,
  EmployedInField,
  EmployedInRelatedField,
  EmployedOutOfField,
  EMPLOYMENT_OUTCOMES,
  Military,
  ROUTES,
  SelfEmployed,
} from "../../../../utils/constants";
import { Plus, Folder } from "../../../common/icons";
import Card from "./Card";

const renderItem = (label, value) => {
  if (!value) return null;
  return (
    <p className="text--small">
      <span className="bold ">{label}: </span> {value}
    </p>
  );
};

function Placement({
  storePlacement = { data: [], loading: false, error: null },
  onEdit = () => {},
  onDelete = () => {},
  onRequestChange = () => {},
  onVerify = () => {},
  documents = {},
}) {
  const history = useHistory();
  const { studentId } = useParams();
  const [form, setForm] = useState({});
  const [openPlacementDetails, setOpenPlacementDetails] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [address, setAddress] = useState(null);

  const handleClick = () => {
    history.push(replaceParamStudent(ROUTES.addPlacement, studentId));
  };

  React.useEffect(() => {
    if (!storePlacement?.data || !selectedId) return;

    storePlacement?.data.forEach((item) => {
      if (item.id === selectedId) {
        const newForm = {
          program_id: item?.program?.id,
          program_name: item?.program?.name,
          outcome: item?.outcome,
          employment_placement_attributes: item?.employment_placement
            ? {
                ...item?.employment_placement,
                same_employer: item?.employment_placement?.same_employer
                  ? "YES"
                  : "NO",
                hired_by_school: item?.employment_placement?.hired_by_school
                  ? "YES"
                  : "NO",
                same_employer_condition: item?.employment_placement
                  ?.same_employer_condition
                  ? "YES"
                  : "NO",
                same_employer_promotion: item?.employment_placement
                  .same_employer_promotion
                  ? "YES"
                  : "NO",
                longer_than_5_weeks: item?.employment_placement
                  ?.longer_than_5_weeks
                  ? "YES"
                  : "NO",
                chose_part_time: item?.employment_placement?.longer_than_5_weeks
                  ? "YES"
                  : "NO",
                hours_per_week: (
                  item?.employment_placement?.hours_per_week || 0
                ).toString(),
                salary: (item?.employment_placement?.salary || 0).toString(),
              }
            : {},
          military_placement_attributes: item?.military_placement,
          education_placement_attributes: item?.education_placement,
          unavailability_placement_attributes: {
            unavailability_date: item?.military_placement
              ? item?.military_placement.created_at
              : new Date(),
          },
          files: item?.files,
        };

        setForm(newForm);

        if (item?.employment_placement) {
          setAddress(item?.employment_placement?.employer_address);
        } else if (item?.military_placement) {
          setAddress(item?.military_placement?.recruiting_office_address);
        }
      }
    });
  }, [storePlacement?.data, selectedId]);

  const columns1 = [
    {
      title: "Program",
      dataIndex: "program",
      render: (row) => (row.program ? row.program.name : "-"),
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (row) => (row.is_type ? row.is_type : "-"),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (row) =>
        // eslint-disable-next-line no-nested-ternary
        row.employment_placement && row.employment_placement.start_date
          ? moment(row.employment_placement.start_date).format("MM/DD/YYYY")
          : // eslint-disable-next-line no-nested-ternary
          row.military_placement && row.military_placement.created_at
          ? moment(row.military_placement.created_at).format("MM/DD/YYYY")
          : row.education_placement && row.education_placement.created_at
          ? moment(row.education_placement.created_at).format("MM/DD/YYYY")
          : "-",
    },
    {
      title: "Documentation",
      dataIndex: "files",
      render: (row) => {
        if (!row.files.length) return "-";

        return row.files.map((item) => (
          <a
            className="document"
            key={item.id || item.name}
            href={item.url}
            target="_blank"
            rel="noreferrer"
          >
            <Folder />
            {item.filename}
          </a>
        ));
      },
    },
    {
      title: "",
      dataIndex: "id",
      align: "right",
      render: (row) => {
        const changeRequest = row?.survey_result?.change_requests?.sort(
          (a, b) => {
            const date1 = new Date(a.created_at);
            const date2 = new Date(b.created_at);
            return date2 - date1;
          }
        )[0];

        const statusPending = changeRequest?.status === "pending";
        const statusPendingVerification =
          changeRequest?.status === "pending_verification";

        return (
          <div className="actions-with-notifications">
            <div className="notifications">
              {statusPendingVerification && <span>&bull;</span>}
            </div>
            <TableActions
              actions={[
                {
                  hidden: statusPending,
                  label: statusPendingVerification
                    ? "Re-Request Changes"
                    : "Request Change",
                  icon: ["fas", "comment"],
                  onSelect: onRequestChange(row),
                },
                {
                  hidden: statusPending || !documents[row.id],
                  label: statusPendingVerification
                    ? "Verify - Admin to Sign"
                    : "Verify",
                  icon: ["fas", "check"],
                  onSelect: onVerify(row),
                },
                // {
                //   hidden: !statusPending,
                //   label: "Cancel Change Request",
                //   icon: ["fas", "ban"],
                //   onSelect: onRequestChange(row),
                // },
                {
                  label: "Edit",
                  icon: ["fas", "pen-to-square"],
                  onSelect: onEdit(row),
                },
                {
                  label: "Delete",
                  icon: ["fas", "trash"],
                  onSelect: onDelete(row),
                },
              ]}
            />
          </div>
        );
      },
    },
  ];
  const onClose = () => {
    setOpenPlacementDetails(false);
  };
  const renderInField = () => (
    <>
      {renderItem(
        "Which of the following descriptions most closely match the type of duties the student will do in their training-related employment?",
        form?.employment_placement_attributes?.soc_codee
      )}
      {renderItem(
        "Which of the following best describes the student's current employment?",
        form?.employment_placement_attributes?.job_type
      )}
      {renderItem(
        " More than one job/position at the same time",
        form?.employment_placement_attributes?.job_type
      )}
      {renderItem(
        "Is the student’s current position with the same employer that they were employed with before they enrolled in Front-End Development?",
        form?.employment_placement_attributes?.same_employer
      )}
      {renderItem(
        "Was the student’s employment after graduation a promotion (with increased pay) due (at least in part) to graduating from/completing the program?",
        form?.employment_placement_attributes?.same_employer_promotion
      )}
      {renderItem(
        "Was graduating from/completing the program a condition for continued employment with this employer?",
        form?.employment_placement_attributes?.same_employer_condition
      )}
      {renderItem(
        "Was the student hired by the school?",
        form?.employment_placement_attributes?.hired_by_school
      )}
    </>
  );
  const renderSelfEmployed = () => (
    <>
      {renderItem(
        "Which of the following describe the nature of your self-employment?",
        form.employment_placement_attributes.self_employed_type ===
          "Entrepreneur"
          ? " Entrepreneur/Running my own business (business owner or partner; for-profit or non-profit)"
          : "Freelance work"
      )}
    </>
  );
  const renderMilitary = () => (
    <>
      {renderItem(
        "Name of Military Branch",
        form?.military_placement_attributes?.branch_name
      )}
      {renderItem("Address of the recruiting office", address)}
      {renderItem(
        "City of the recruiting office",
        form?.military_placement_attributes?.recruiting_office_city
      )}
      {renderItem(
        "State of the recruiting office",
        form?.military_placement_attributes?.recruiting_office_state
      )}
      {renderItem(
        "Zip Code of the recruiting office",
        form?.military_placement_attributes?.recruiting_office_zip_code
      )}
      {renderItem(
        "Recruiting office phone number",
        form?.military_placement_attributes?.recruiting_office_phone
      )}
    </>
  );

  const renderContinuingEducation = () => (
    <>
      {renderItem(
        "Name of post secondary education institution",
        form?.education_placement_attributes?.institution_name
      )}
      {renderItem(
        "Name of Program or Major",
        form?.education_placement_attributes?.program_name
      )}
      {renderItem("Address of the institution", address)}
      {renderItem(
        "City of the institution",
        form?.education_placement_attributes?.institution_city
      )}
      {renderItem(
        "State of the institution",
        form?.education_placement_attributes?.institution_state
      )}
      {renderItem(
        "Zip Code of the institution",
        form?.education_placement_attributes?.institution_zip_code
      )}
    </>
  );

  const renderDetails = () => {
    switch (form.outcome) {
      case EmployedInField:
      case EmployedInRelatedField:
      case EmployedOutOfField:
        return renderInField();

      case SelfEmployed:
        return renderSelfEmployed();
      case Military:
        return renderMilitary();
      case ContinuingEducation:
        return renderContinuingEducation();
      default:
        break;
    }
  };

  return (
    <div className="Placement Student-tap dashboard__item__inner">
      <div className="button__add">
        <button type="submit" className="button--icon" onClick={handleClick}>
          <Plus />
          Placement
        </button>
      </div>
      <div>
        {storePlacement.data.map((item) => (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            className="Placement__item"
            onClick={() => {
              setOpenPlacementDetails(true);
              setSelectedId(item.id);
            }}
          >
            <Card
              title={item.outcome ? item.outcome : ""}
              data={[item]}
              columns1={columns1}
              key={item.id}
            />
          </div>
        ))}
      </div>
      <Modal
        show={openPlacementDetails}
        onHide={onClose}
        backdropClassName="wrap-modal"
        dialogClassName="Modal Modal__placement-detail"
        keyboard={false}
        centered
      >
        <div className="bootstrap">
          <Modal.Header closeButton>
            <Modal.Title>Placement Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {renderItem("Program", form?.program_name)}
            {renderItem("Outcome", form?.outcome)}
            {form.outcome && renderDetails()}

            {form?.outcome && EMPLOYMENT_OUTCOMES.includes(form.outcome) && (
              <>
                {renderItem(
                  "What is the student's current job title?",
                  form?.employment_placement_attributes?.job_title
                )}
                {renderItem(
                  "Hours Per Week ",
                  form?.employment_placement_attributes?.hours_per_week
                )}
                {form?.employment_placement_attributes?.hours_per_week >=
                  "20" &&
                  form?.employment_placement_attributes?.hours_per_week <=
                    "29" &&
                  renderItem(
                    "Did the student specifically choose to seek part-time employment rather than full time employment after graduation?",
                    form.employment_placement_attributes?.chose_part_time
                  )}
                {renderItem(
                  "What is the expected duration of this job?",
                  form?.employment_placement_attributes?.duration
                )}
                {renderItem(
                  "Do you expect the student’s job to last longer than 5 weeks?",
                  form?.employment_placement_attributes?.longer_than_5_weeks
                )}
                {renderItem(
                  "When did the student sign their offer letter?",
                  form?.employment_placement_attributes?.offer_date
                )}
                {renderItem(
                  "What is/was the student’s start date?",
                  form?.employment_placement_attributes?.start_date
                )}
                {renderItem(
                  "What is/was the student’s end date?",
                  form?.employment_placement_attributes?.end_date
                )}
                {renderItem(
                  "Which of the following best describes how the student will get paid?",
                  form?.employment_placement_attributes?.salary_type
                )}
                {renderItem(
                  "What is the student’s salary? (please exclude dollar signs and commas: for example, 70000)",
                  form?.employment_placement_attributes?.salary
                )}
                {renderItem(
                  "For this position, was the student hired by your school, by an employer owned by the school, or by an employer who shares ownership with the school?",
                  form?.employment_placement_attributes?.hired_by_school
                )}
                {renderItem(
                  "For this position, was the student actively assisted in obtaining employment by the institution?",
                  form?.employment_placement_attributes?.placed_by_school
                )}
                {renderItem(
                  "Name of the firm/company ",
                  form?.employment_placement_attributes?.employer_name
                )}
                {renderItem("Address of the firm/company", address)}
                {renderItem(
                  "City of the firm/company",
                  form?.employment_placement_attributes?.employer_city
                )}
                {renderItem(
                  "State of the firm/company",
                  form?.employment_placement_attributes?.employer_state
                )}
                {renderItem(
                  "Zip Code of the firm/company",
                  form?.employment_placement_attributes?.employer_zip_code
                )}
                {renderItem(
                  "Telephone number of the firm/company",
                  form?.employment_placement_attributes?.employer_phone
                )}
                {renderItem(
                  "Immediate Supervisor’s Full Name",
                  form?.employment_placement_attributes?.employer_contact_name
                )}
                {renderItem(
                  "Immediate Supervisor’s Phone",
                  form?.employment_placement_attributes?.employer_contact_phone
                )}
                {renderItem(
                  "Immediate Supervisor’s Email",
                  form?.employment_placement_attributes?.employer_contact_email
                )}
              </>
            )}
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
}

export default Placement;
