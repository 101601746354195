import React from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment";

function DatePicker({
  onCallback,
  singleDatePicker,
  minYear,
  initialSettingsProps = {},
  placeholder,
  pickerRef,
  disabled,
  onReset,
  ...props
}) {
  return (
    <div className="DateRangePicker">
      <DateRangePicker
        initialSettings={{
          singleDatePicker,
          showDropdowns: true,
          minYear,
          maxYear: parseInt(moment().format("YYYY")) + 10,
          opens: "left",
          ...initialSettingsProps,
        }}
        onCallback={onCallback}
        className="sigleCalendar"
        {...props}
        ref={pickerRef}
      >
        <input
          type="text"
          className={classNames("Date-picker__input", {
            "Date-picker__input__disabled": disabled,
          })}
          placeholder={placeholder}
        />
      </DateRangePicker>

      {onReset && (
        <button
          className="DateRangePicker__reset"
          onClick={onReset}
          type="button"
        >
          ×
        </button>
      )}
    </div>
  );
}

DatePicker.propTypes = {
  onCallback: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  onReset: PropTypes.func,
  singleDatePicker: PropTypes.bool,
  minYear: PropTypes.number,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  initialSettingsProps: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  pickerRef: PropTypes.any,
  disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  singleDatePicker: false,
  minYear: 1950,
  initialSettingsProps: {},
  placeholder: "",
  pickerRef: null,
  disabled: false,
};

export default DatePicker;
