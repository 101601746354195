import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Tick } from "../common/icons";

function Range({ percent, iconActive }) {
  return (
    <div
      className={classNames("Range", {
        "--iconActive": iconActive,
      })}
    >
      <div>
        <span style={{ width: `${percent}%` }} />
      </div>
      <Tick />
    </div>
  );
}

Range.propTypes = {
  percent: PropTypes.number,
  iconActive: PropTypes.bool,
};

Range.defaultProps = {
  percent: 11.11,
  iconActive: false,
};

export default Range;
