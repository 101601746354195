import React, { useRef } from "react";
import { NavLink, useLocation, useHistory, useParams } from "react-router-dom";
import classNames from "classnames";
import { Loading } from "features/GraduateFollowUpSurvey/components";
import { useSelector } from "react-redux";
import moment from "moment";
import { replaceParamStudent } from "utils/helpers";
import { ROUTES } from "../../../../utils/constants";
import { Breadcrumb } from "../../../common";
import { Back } from "../../../common/icons";
import {
  updateReportingURL,
  updateReportName,
} from "../../../../redux/actions/global";

function Header({ selectedStudent, loading }) {
  const location = useLocation();
  const history = useHistory();
  const studentPageRef = useRef();
  const { studentId } = useParams();

  const reportingURL = useSelector((store) => store.global.reportingURL);
  const reportName = useSelector((store) => store.global.reportName);
  const StoreCoverSheet = useSelector(
    (store) => store.reporting.preview.data.cover_sheet
  );
  const storeSelected = useSelector((store) => store.reporting.selected);

  let dataCoverSheet = {};
  if (reportName === "accet" && storeSelected.month) {
    dataCoverSheet = StoreCoverSheet[storeSelected.month.value];
  } else dataCoverSheet = StoreCoverSheet;

  React.useEffect(
    () => () => {
      updateReportingURL("");
      updateReportName("");
    },
    []
  );

  React.useEffect(() => {
    setTimeout(() => {
      if (studentPageRef.current) {
        studentPageRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "end",
        });
      }
    }, 500);
  }, [studentPageRef]);

  return (
    <div className="Header-student" ref={studentPageRef}>
      <div className="Header-student__title">
        {loading ? (
          <Loading />
        ) : (
          <h4>{`${selectedStudent.first_name} ${selectedStudent.last_name}`}</h4>
        )}
        <div>
          {reportingURL && (
            <button
              className="button button--icon button--small button-back"
              type="button"
              onClick={() => history.push(reportingURL)}
            >
              <Back />
              Back to{" "}
              <span className="report-name">&nbsp;{`${reportName}`}&nbsp;</span>
              {`( ${moment(dataCoverSheet.reporting_period[0]).format(
                "MM/DD/YYYY"
              )} - ${moment(dataCoverSheet.reporting_period[1]).format(
                "MM/DD/YYYY"
              )} )`}
            </button>
          )}

          <Breadcrumb />
        </div>
      </div>
      <div className="Header-student__taps">
        <NavLink
          to={replaceParamStudent(ROUTES.student, studentId)}
          className={classNames("text--small", {
            isActive: location.pathname === ROUTES.editStudent,
          })}
          activeClassName="isActive"
          exact
        >
          General
        </NavLink>
        <NavLink
          to={replaceParamStudent(ROUTES.enrollment, studentId)}
          className={classNames("text--small", {
            isActive: location.pathname === ROUTES.addEnrollment,
          })}
          activeClassName="isActive"
          exact
        >
          Enrollment & Completion
        </NavLink>
        <NavLink
          to={replaceParamStudent(ROUTES.licensure, studentId)}
          className={classNames("text--small", {
            isActive: location.pathname === ROUTES.addLicensure,
          })}
          activeClassName="isActive"
        >
          Licensure
        </NavLink>
        <NavLink
          to={replaceParamStudent(ROUTES.placement, studentId)}
          className={classNames("text--small", {
            isActive: location.pathname === ROUTES.addPlacement,
          })}
          activeClassName="isActive"
        >
          {selectedStudent.has_change_request_pending_verification && (
            <span className="notification">&bull;</span>
          )}
          Placement
        </NavLink>
        <NavLink
          to={replaceParamStudent(ROUTES.financial, studentId)}
          className={classNames("text--small", {
            isActive: location.pathname === ROUTES.addFinancial,
          })}
          activeClassName="isActive"
        >
          Financial Assistance
        </NavLink>
        <NavLink
          to={replaceParamStudent(ROUTES.studentTasks, studentId)}
          className={classNames("text--small")}
          activeClassName="isActive"
        >
          Tasks
        </NavLink>
        <NavLink
          to={replaceParamStudent(ROUTES.contactLog, studentId)}
          className={classNames("text--small", {
            isActive: location.pathname === ROUTES.addContact,
          })}
          activeClassName="isActive"
        >
          Contact Log
        </NavLink>
        <NavLink
          to={replaceParamStudent(ROUTES.changeLog, studentId)}
          className="text--small"
          activeClassName="isActive"
        >
          Change Log
        </NavLink>
        <NavLink
          to={replaceParamStudent(ROUTES.documents, studentId)}
          className="text--small"
          activeClassName="isActive"
        >
          Documents
        </NavLink>
      </div>
    </div>
  );
}

export default Header;
