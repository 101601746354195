import * as types from "../constants";
import request from "../../utils/request";

export function getStudents(params) {
  const newParams = { ...params };
  if (
    newParams["q[enrollments_end_status_eq]"] ||
    newParams["q[enrollments_cohort_name_eq]"] ||
    newParams["q[enrollments_program_name_eq]"] ||
    newParams["q[enrollments_branch_name_eq]"] ||
    newParams["q[enrollments_start_status_eq]"] ||
    (newParams["q[sorts]"] &&
      (newParams["q[sorts]"].includes("enrollments_cohort_name") ||
        newParams["q[sorts]"].includes("enrollments_end_status") ||
        newParams["q[sorts]"].includes("enrollments_start_status")))
  ) {
    newParams["q[enrollments_latest_eq]"] = true;
  }
  if (
    newParams["q[placements_outcome_eq]"] ||
    (newParams["q[sorts]"] &&
      newParams["q[sorts]"].includes("placements_outcome"))
  ) {
    newParams["q[placements_latest_eq]"] = true;
  }
  return (dispatch) => {
    dispatch({ type: types.GET_STUDENTS });
    return request()
      .get("/students", {
        params: newParams,
      })
      .then((dataResponse) => {
        dispatch({
          type: types.GET_STUDENTS_SUCCEED,
          payload: {
            data: dataResponse.data.data,
            meta: {
              total: dataResponse.headers.total,
              perPage: dataResponse.headers["per-page"],
            },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_STUDENTS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function createStudent(data) {
  return (dispatch) => {
    dispatch({ type: types.CREATE_STUDENT });
    return request()
      .post("/students", { student: data })
      .then((dataResponse) => {
        dispatch({
          type: types.CREATE_STUDENT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.CREATE_STUDENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function updateStudent(data, studentId) {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_STUDENT });
    return request()
      .put(`/students/${studentId}`, { student: data })
      .then((dataResponse) => {
        dispatch({
          type: types.UPDATE_STUDENT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse;
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_STUDENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function importStudent(data) {
  return (dispatch) => {
    dispatch({ type: types.IMPORT_STUDENT });
    return request()
      .post("/importer/students/import", data)
      .then((dataResponse) => {
        dispatch({
          type: types.IMPORT_STUDENT_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.IMPORT_STUDENT_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getImporterRows(data) {
  return (dispatch) => {
    dispatch({ type: types.GET_ROW });
    return request()
      .post("/importer/students/rows", data)
      .then((dataResponse) => {
        dispatch({
          type: types.GET_ROW_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_ROW_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}

export function getImporterStatus(jobID) {
  return (dispatch) => {
    dispatch({ type: types.GET_FILE_STATUS });
    return request()
      .get(`/importer/students/status?job_id=${jobID}`)
      .then((dataResponse) => {
        dispatch({
          type: types.GET_FILE_STATUS_SUCCEED,
          payload: dataResponse.data.data,
        });
        return dataResponse.data.data;
      })
      .catch((error) => {
        dispatch({
          type: types.GET_FILE_STATUS_FAIL,
          payload: error,
        });
        throw error;
      });
  };
}
