export const POST_CONTACT_US = "POST_CONTACT_US";
export const POST_CONTACT_US_SUCCEED = "POST_CONTACT_US_SUCCEED";
export const POST_CONTACT_US_FAIL = "POST_CONTACT_US_FAIL";

export const UPDATE_IS_BAD_TOKEN = "UPDATE_IS_BAD_TOKEN";

export const UPDATE_REPORTING_URL = "UPDATE_REPORTING_URL";

export const UPDATE_REPORT_NAME = "UPDATE_REPORTING_NAME";

export const OPEN_MESSAGE = "OPEN_MESSAGE";
export const CLOSE_MESSAGE = "CLOSE_MESSAGE";

export const POST_SIGN_UP = "POST_SIGN_UP";
export const POST_SIGN_UP_SUCCEED = "POST_SIGN_UP_SUCCEED";
export const POST_SIGN_UP_FAIL = "POST_SIGN_UP_FAIL";

export const SIGN_UP_STUDENT = "SIGN_UP_STUDENT";
export const SIGN_UP_STUDENT_SUCCEED = "SIGN_UP_STUDENT_SUCCEED";
export const SIGN_UP_STUDENT_FAIL = "SIGN_UP_STUDENT_FAIL";

export const POST_LOGIN = "POST_LOGIN";
export const POST_LOGIN_SUCCEED = "POST_LOGIN_SUCCEED";
export const POST_LOGIN_FAIL = "POST_LOGIN_FAIL";

export const POST_FORGOT_PASSWORD = "POST_FORGOT_PASSWORD";
export const POST_FORGOT_PASSWORD_SUCCEED = "POST_FORGOT_PASSWORD_SUCCEED";
export const POST_FORGOT_PASSWORD_FAIL = "POST_FORGOT_PASSWORD_FAIL";

export const PUT_RESET_PASSWORD = "PUT_RESET_PASSWORD";
export const PUT_RESET_PASSWORD_SUCCEED = "PUT_RESET_PASSWORD_SUCCEED";
export const PUT_RESET_PASSWORD_FAIL = "PUT_RESET_PASSWORD_FAIL";

export const POST_ONBOARDING_VALIDATE = "POST_ONBOARDING_VALIDATE";
export const POST_ONBOARDING_VALIDATE_SUCCEED =
  "POST_ONBOARDING_VALIDATE_SUCCEED";
export const POST_ONBOARDING_VALIDATE_FAIL = "POST_ONBOARDING_VALIDATE_FAIL";

export const POST_ONBOARDING_AGENCIES = "POST_ONBOARDING_AGENCIES";
export const POST_ONBOARDING_AGENCIES_SUCCEED =
  "POST_ONBOARDING_AGENCIES_SUCCEED";
export const POST_ONBOARDING_AGENCIES_FAIL = "POST_ONBOARDING_AGENCIES_FAIL";

export const CREATE_ONBOARDING = "CREATE_ONBOARDING";
export const CREATE_ONBOARDING_SUCCEED = "CREATE_ONBOARDING_SUCCEED";
export const CREATE_ONBOARDING_FAIL = "CREATE_ONBOARDING_FAIL";

export const GET_FILTER_BRANCHES = "GET_FILTER_BRANCHES";
export const GET_FILTER_BRANCHES_SUCCEED = "GET_FILTER_BRANCHES_SUCCEED";
export const GET_FILTER_BRANCHES_FAIL = "GET_FILTER_BRANCHES_FAIL";

export const GET_FILTER_PROGRAMS = "GET_FILTER_PROGRAMS";
export const GET_FILTER_PROGRAMS_SUCCEED = "GET_FILTER_PROGRAMS_SUCCEED";
export const GET_FILTER_PROGRAMS_FAIL = "GET_FILTER_PROGRAMS_FAIL";

export const GET_FILTER_COHORTS = "GET_FILTER_COHORTS";
export const GET_FILTER_COHORTS_SUCCEED = "GET_FILTER_COHORTS_SUCCEED";
export const GET_FILTER_COHORTS_FAIL = "GET_FILTER_COHORTS_FAIL";

export const SET_SELECTED_FILTER = "SET_SELECTED_FILTER";

export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_SUCCEED = "GET_DASHBOARD_SUCCEED";
export const GET_DASHBOARD_FAIL = "GET_DASHBOARD_FAIL";

export const GET_REPORTING = "GET_REPORTING";
export const GET_REPORTING_SUCCEED = "GET_REPORTING_SUCCEED";
export const GET_REPORTING_FAIL = "GET_REPORTING_FAIL";

export const GET_REPORTING_BPPE_PREVIEW = "GET_REPORTING_BPPE_PREVIEW";
export const GET_REPORTING_BPPE_PREVIEW_SUCCEED =
  "GET_REPORTING_BPPE_PREVIEW_SUCCEED";
export const GET_REPORTING_BPPE_PREVIEW_FAIL =
  "GET_REPORTING_BPPE_PREVIEW_FAIL";

export const GET_REPORTING_BPPE_REPORT = "GET_REPORTING_BPPE_REPORT";
export const GET_REPORTING_BPPE_REPORT_SUCCEED =
  "GET_REPORTING_BPPE_REPORT_SUCCEED";
export const GET_REPORTING_BPPE_REPORT_FAIL = "GET_REPORTING_BPPE_REPORT_FAIL";

export const GET_REPORTING_BPPE_DOCUMENT = "GET_REPORTING_BPPE_DOCUMENT";
export const GET_REPORTING_BPPE_DOCUMENT_SUCCEED =
  "GET_REPORTING_BPPE_DOCUMENT_SUCCEED";
export const GET_REPORTING_BPPE_DOCUMENT_FAIL =
  "GET_REPORTING_BPPE_DOCUMENT_FAIL";

export const GET_EXPORT_IDENTIFICATION_DATA = "GET_EXPORT_IDENTIFICATION_DATA";
export const GET_EXPORT_IDENTIFICATION_DATA_SUCCEED =
  "GET_EXPORT_IDENTIFICATION_DATA_SUCCEED";
export const GET_EXPORT_IDENTIFICATION_DATA_FAIL =
  "GET_EXPORT_IDENTIFICATION_DATA_FAIL";

export const GET_REPORTING_BPPE_SPFS = "GET_REPORTING_BPPE_SPFS";
export const GET_REPORTING_BPPE_SPFS_SUCCEED =
  "GET_REPORTING_BPPE_SPFS_SUCCEED";
export const GET_REPORTING_BPPE_SPFS_FAIL = "GET_REPORTING_BPPE_SPFS_FAIL";

export const GET_REPORTING_CIRR_PREVIEW = "GET_REPORTING_CIRR_PREVIEW";
export const GET_REPORTING_CIRR_PREVIEW_SUCCEED =
  "GET_REPORTING_CIRR_PREVIEW_SUCCEED";
export const GET_REPORTING_CIRR_PREVIEW_FAIL =
  "GET_REPORTING_CIRR_PREVIEW_FAIL";

export const GET_REPORTING_CIRR_REPORT = "GET_REPORTING_CIRR_REPORT";
export const GET_REPORTING_CIRR_REPORT_SUCCEED =
  "GET_REPORTING_CIRR_REPORT_SUCCEED";
export const GET_REPORTING_CIRR_REPORT_FAIL = "GET_REPORTING_CIRR_REPORT_FAIL";

export const GET_REPORTING_CIRR_DOCUMENT = "GET_REPORTING_CIRR_DOCUMENT";
export const GET_REPORTING_CIRR_DOCUMENT_SUCCEED =
  "GET_REPORTING_CIRR_DOCUMENT_SUCCEED";
export const GET_REPORTING_CIRR_DOCUMENT_FAIL =
  "GET_REPORTING_CIRR_DOCUMENT_FAIL";

export const GET_REPORTING_BPSS_PREVIEW = "GET_REPORTING_BPSS_PREVIEW";
export const GET_REPORTING_BPSS_PREVIEW_SUCCEED =
  "GET_REPORTING_BPSS_PREVIEW_SUCCEED";
export const GET_REPORTING_BPSS_PREVIEW_FAIL =
  "GET_REPORTING_BPSS_PREVIEW_FAIL";

export const GET_REPORTING_BPSS_REPORT = "GET_REPORTING_BPSS_REPORT";
export const GET_REPORTING_BPSS_REPORT_SUCCEED =
  "GET_REPORTING_BPSS_REPORT_SUCCEED";
export const GET_REPORTING_BPSS_REPORT_FAIL = "GET_REPORTING_BPSS_REPORT_FAIL";

export const GET_REPORTING_BPSS_DOCUMENT = "GET_REPORTING_BPSS_DOCUMENT";
export const GET_REPORTING_BPSS_DOCUMENT_SUCCEED =
  "GET_REPORTING_BPSS_DOCUMENT_SUCCEED";
export const GET_REPORTING_BPSS_DOCUMENT_FAIL =
  "GET_REPORTING_BPSS_DOCUMENT_FAIL";

export const GET_REPORTING_TWC_PREVIEW = "GET_REPORTING_TWC_PREVIEW";
export const GET_REPORTING_TWC_PREVIEW_SUCCEED =
  "GET_REPORTING_TWC_PREVIEW_SUCCEED";
export const GET_REPORTING_TWC_PREVIEW_FAIL = "GET_REPORTING_TWC_PREVIEW_FAIL";

export const GET_REPORTING_TWC_REPORT = "GET_REPORTING_TWC_REPORT";
export const GET_REPORTING_TWC_REPORT_SUCCEED =
  "GET_REPORTING_TWC_REPORT_SUCCEED";
export const GET_REPORTING_TWC_REPORT_FAIL = "GET_REPORTING_TWC_REPORT_FAIL";

export const GET_REPORTING_TWC_DOCUMENT = "GET_REPORTING_TWC_DOCUMENT";
export const GET_REPORTING_TWC_DOCUMENT_SUCCEED =
  "GET_REPORTING_TWC_DOCUMENT_SUCCEED";
export const GET_REPORTING_TWC_DOCUMENT_FAIL =
  "GET_REPORTING_TWC_DOCUMENT_FAIL";

export const GET_REPORTING_TWC_COMPLETER = "GET_REPORTING_TWC_COMPLETER";
export const GET_REPORTING_TWC_COMPLETER_SUCCEED =
  "GET_REPORTING_TWC_COMPLETER_SUCCEED";
export const GET_REPORTING_TWC_COMPLETER_FAIL =
  "GET_REPORTING_TWC_COMPLETER_FAIL";

export const GET_REPORTING_DPOS_PREVIEW = "GET_REPORTING_DPOS_PREVIEW";
export const GET_REPORTING_DPOS_PREVIEW_SUCCEED =
  "GET_REPORTING_DPOS_PREVIEW_SUCCEED";
export const GET_REPORTING_DPOS_PREVIEW_FAIL =
  "GET_REPORTING_DPOS_PREVIEW_FAIL";

export const GET_REPORTING_DPOS_REPORT = "GET_REPORTING_DPOS_REPORT";
export const GET_REPORTING_DPOS_REPORT_SUCCEED =
  "GET_REPORTING_DPOS_REPORT_SUCCEED";
export const GET_REPORTING_DPOS_REPORT_FAIL = "GET_REPORTING_DPOS_REPORT_FAIL";

export const GET_REPORTING_DPOS_DOCUMENT = "GET_REPORTING_DPOS_DOCUMENT";
export const GET_REPORTING_DPOS_DOCUMENT_SUCCEED =
  "GET_REPORTING_DPOS_DOCUMENT_SUCCEED";
export const GET_REPORTING_DPOS_DOCUMENT_FAIL =
  "GET_REPORTING_DPOS_DOCUMENT_FAIL";

export const GET_REPORTING_COE_PREVIEW = "GET_REPORTING_COE_PREVIEW";
export const GET_REPORTING_COE_PREVIEW_SUCCEED =
  "GET_REPORTING_COE_PREVIEW_SUCCEED";
export const GET_REPORTING_COE_PREVIEW_FAIL = "GET_REPORTING_COE_PREVIEW_FAIL";

export const GET_REPORTING_COE_REPORT = "GET_REPORTING_COE_REPORT";
export const GET_REPORTING_COE_REPORT_SUCCEED =
  "GET_REPORTING_COE_REPORT_SUCCEED";
export const GET_REPORTING_COE_REPORT_FAIL = "GET_REPORTING_COE_REPORT_FAIL";

export const GET_REPORTING_COE_DOCUMENT = "GET_REPORTING_COE_DOCUMENT";
export const GET_REPORTING_COE_DOCUMENT_SUCCEED =
  "GET_REPORTING_COE_DOCUMENT_SUCCEED";
export const GET_REPORTING_COE_DOCUMENT_FAIL =
  "GET_REPORTING_COE_DOCUMENT_FAIL";

export const GET_REPORTING_ACCET_PREVIEW = "GET_REPORTING_ACCET_PREVIEW";
export const GET_REPORTING_ACCET_PREVIEW_SUCCEED =
  "GET_REPORTING_ACCET_PREVIEW_SUCCEED";
export const GET_REPORTING_ACCET_PREVIEW_FAIL =
  "GET_REPORTING_ACCET_PREVIEW_FAIL";

export const GET_REPORTING_ACCET_REPORT = "GET_REPORTING_ACCET_REPORT";
export const GET_REPORTING_ACCET_REPORT_SUCCEED =
  "GET_REPORTING_ACCET_REPORT_SUCCEED";
export const GET_REPORTING_ACCET_REPORT_FAIL =
  "GET_REPORTING_ACCET_REPORT_FAIL";

export const GET_REPORTING_ACCET_DOCUMENT = "GET_REPORTING_ACCET_DOCUMENT";
export const GET_REPORTING_ACCET_DOCUMENT_SUCCEED =
  "GET_REPORTING_ACCET_DOCUMENT_SUCCEED";
export const GET_REPORTING_ACCET_DOCUMENT_FAIL =
  "GET_REPORTING_ACCET_DOCUMENT_FAIL";

export const GET_PROGRAMS = "GET_PROGRAMS";
export const GET_PROGRAMS_SUCCEED = "GET_PROGRAMS_SUCCEED";
export const GET_PROGRAMS_FAIL = "GET_PROGRAMS_FAIL";

export const GET_ALL_PROGRAMS = "GET_ALL_PROGRAMS";
export const GET_ALL_PROGRAMS_SUCCEED = "GET_ALL_PROGRAMS_SUCCEED";
export const GET_ALL_PROGRAMS_FAIL = "GET_ALL_PROGRAMS_FAIL";

export const GET_PROGRAM_INDEX = "GET_PROGRAM_INDEX";
export const GET_PROGRAM_INDEX_SUCCEED = "GET_PROGRAM_INDEX_SUCCEED";
export const GET_PROGRAM_INDEX_FAIL = "GET_PROGRAM_INDEX_FAIL";

export const DELETE_PROGRAM = "DELETE_PROGRAM";
export const DELETE_PROGRAM_SUCCEED = "DELETE_PROGRAM_SUCCEED";
export const DELETE_PROGRAM_FAIL = "DELETE_PROGRAM_FAIL";

export const CREATE_PROGRAM = "CREATE_PROGRAM";
export const CREATE_PROGRAM_SUCCEED = "CREATE_PROGRAM_SUCCEED";
export const CREATE_PROGRAM_FAIL = "CREATE_PROGRAM_FAIL";

export const UPDATE_PROGRAM = "UPDATE_PROGRAM";
export const UPDATE_PROGRAM_SUCCEED = "UPDATE_PROGRAM_SUCCEED";
export const UPDATE_PROGRAM_FAIL = "UPDATE_PROGRAM_FAIL";

export const GET_FINANCIAL_AID_PROGRAMS = "GET_FINANCIAL_AID_PROGRAMS";
export const GET_FINANCIAL_AID_PROGRAMS_SUCCEED =
  "GET_FINANCIAL_AID_PROGRAMS_SUCCEED";
export const GET_FINANCIAL_AID_PROGRAMS_FAIL =
  "GET_FINANCIAL_AID_PROGRAMS_FAIL";

export const GET_ALL_FINANCIAL_AID_PROGRAMS = "GET_ALL_FINANCIAL_AID_PROGRAMS";
export const GET_ALL_FINANCIAL_AID_PROGRAMS_SUCCEED =
  "GET_ALL_FINANCIAL_AID_PROGRAMS_SUCCEED";
export const GET_ALL_FINANCIAL_AID_PROGRAMS_FAIL =
  "GET_ALL_FINANCIAL_AID_PROGRAMS_FAIL";

// export const GET_FINANCIAL_AID_PROGRAM_INDEX = 'GET_FINANCIAL_AID_PROGRAM_INDEX';
// export const GET_FINANCIAL_AID_PROGRAM_INDEX_SUCCEED = 'GET_FINANCIAL_AID_PROGRAM_INDEX_SUCCEED';
// export const GET_FINANCIAL_AID_PROGRAM_INDEX_FAIL = 'GET_FINANCIAL_AID_PROGRAM_INDEX_FAIL';

export const DELETE_FINANCIAL_AID_PROGRAM = "DELETE_FINANCIAL_AID_PROGRAM";
export const DELETE_FINANCIAL_AID_PROGRAM_SUCCEED =
  "DELETE_FINANCIAL_AID_PROGRAM_SUCCEED";
export const DELETE_FINANCIAL_AID_PROGRAM_FAIL =
  "DELETE_FINANCIAL_AID_PROGRAM_FAIL";

export const CREATE_FINANCIAL_AID_PROGRAM = "CREATE_FINANCIAL_AID_PROGRAM";
export const CREATE_FINANCIAL_AID_PROGRAM_SUCCEED =
  "CREATE_FINANCIAL_AID_PROGRAM_SUCCEED";
export const CREATE_FINANCIAL_AID_PROGRAM_FAIL =
  "CREATE_FINANCIAL_AID_PROGRAM_FAIL";

export const UPDATE_FINANCIAL_AID_PROGRAM = "UPDATE_FINANCIAL_AID_PROGRAM";
export const UPDATE_FINANCIAL_AID_PROGRAM_SUCCEED =
  "UPDATE_FINANCIAL_AID_PROGRAM_SUCCEED";
export const UPDATE_FINANCIAL_AID_PROGRAM_FAIL =
  "UPDATE_FINANCIAL_AID_PROGRAM_FAIL";

export const GET_COHORTS = "GET_COHORTS";
export const GET_COHORTS_SUCCEED = "GET_COHORTS_SUCCEED";
export const GET_COHORTS_FAIL = "GET_COHORTS_FAIL";

export const GET_ALL_COHORTS = "GET_ALL_COHORTS";
export const GET_ALL_COHORTS_SUCCEED = "GET_ALL_COHORTS_SUCCEED";
export const GET_ALL_COHORTS_FAIL = "GET_ALL_COHORTS_FAIL";

export const CREATE_COHORT = "CREATE_COHORT";
export const CREATE_COHORT_SUCCEED = "CREATE_COHORT_SUCCEED";
export const CREATE_COHORT_FAIL = "CREATE_COHORT_FAIL";

export const DELETE_COHORT = "DELETE_COHORT";
export const DELETE_COHORT_SUCCEED = "DELETE_COHORT_SUCCEED";
export const DELETE_COHORT_FAIL = "DELETE_COHORT_FAIL";

export const UPDATE_COHORT = "UPDATE_COHORT";
export const UPDATE_COHORT_SUCCEED = "UPDATE_COHORT_SUCCEED";
export const UPDATE_COHORT_FAIL = "UPDATE_COHORT_FAIL";

export const GET_BRANCHES = "GET_BRANCHES";
export const GET_BRANCHES_SUCCEED = "GET_BRANCHES_SUCCEED";
export const GET_BRANCHES_FAIL = "GET_BRANCHES_FAIL";

export const GET_ALL_BRANCHES = "GET_ALL_BRANCHES";
export const GET_ALL_BRANCHES_SUCCEED = "GET_ALL_BRANCHES_SUCCEED";
export const GET_ALL_BRANCHES_FAIL = "GET_ALL_BRANCHES_FAIL";

export const CREATE_BRANCH = "CREATE_BRANCH";
export const CREATE_BRANCH_SUCCEED = "CREATE_BRANCH_SUCCEED";
export const CREATE_BRANCH_FAIL = "CREATE_BRANCH_FAIL";

export const DELETE_BRANCH = "DELETE_BRANCH";
export const DELETE_BRANCH_SUCCEED = "DELETE_BRANCH_SUCCEED";
export const DELETE_BRANCH_FAIL = "DELETE_BRANCH_FAIL";

export const UPDATE_BRANCH = "UPDATE_BRANCH";
export const UPDATE_BRANCH_SUCCEED = "UPDATE_BRANCH_SUCCEED";
export const UPDATE_BRANCH_FAIL = "UPDATE_BRANCH_FAIL";

export const GET_INSTITUTION = "GET_INSTITUTION";
export const GET_INSTITUTION_SUCCEED = "GET_INSTITUTION_SUCCEED";
export const GET_INSTITUTION_FAIL = "GET_INSTITUTION_FAIL";

export const UPDATE_INSTITUTION = "UPDATE_INSTITUTION";
export const UPDATE_INSTITUTION_SUCCEED = "UPDATE_INSTITUTION_SUCCEED";
export const UPDATE_INSTITUTION_FAIL = "UPDATE_INSTITUTION_FAIL";

export const GET_ADMINISTRATOR = "GET_ADMINISTRATOR";
export const GET_ADMINISTRATOR_SUCCEED = "GET_ADMINISTRATOR_SUCCEED";
export const GET_ADMINISTRATOR_FAIL = "GET_ADMINISTRATOR_FAIL";

export const DELETE_ADMINISTRATOR = "DELETE_ADMINISTRATOR";
export const DELETE_ADMINISTRATOR_SUCCEED = "DELETE_ADMINISTRATOR_SUCCEED";
export const DELETE_ADMINISTRATOR_FAIL = "DELETE_ADMINISTRATOR_FAIL";

export const UPDATE_ADMINISTRATOR = "UPDATE_ADMINISTRATOR";
export const UPDATE_ADMINISTRATOR_SUCCEED = "UPDATE_ADMINISTRATOR_SUCCEED";
export const UPDATE_ADMINISTRATOR_FAIL = "UPDATE_ADMINISTRATOR_FAIL";

export const CREATE_ADMINISTRATOR = "CREATE_ADMINISTRATOR";
export const CREATE_ADMINISTRATOR_SUCCEED = "CREATE_ADMINISTRATOR_SUCCEED";
export const CREATE_ADMINISTRATOR_FAIL = "CREATE_ADMINISTRATOR_FAIL";

export const GET_ROLES = "GET_ROLES";
export const GET_ROLES_SUCCEED = "GET_ROLES_SUCCEED";
export const GET_ROLES_FAIL = "GET_ROLES_FAIL";

export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const GET_ALL_ROLES_SUCCEED = "GET_ALL_ROLES_SUCCEED";
export const GET_ALL_ROLES_FAIL = "GET_ALL_ROLES_FAIL";

export const GET_ROLES_BUILD = "GET_ROLES_BUILD";
export const GET_ROLES_BUILD_SUCCEED = "GET_ROLES_BUILD_SUCCEED";
export const GET_ROLES_BUILD_FAIL = "GET_ROLES_BUILD_FAIL";

export const GET_ROLE_DETAIL = "GET_ROLE_DETAIL";
export const GET_ROLE_DETAIL_SUCCEED = "GET_ROLE_DETAIL_SUCCEED";
export const GET_ROLE_DETAIL_FAIL = "GET_ROLE_DETAIL_FAIL";

export const DELETE_ROLE = "DELETE_ROLE";
export const DELETE_ROLE_SUCCEED = "DELETE_ROLE_SUCCEED";
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL";

export const UPDATE_ROLE = "UPDATE_ROLE";
export const UPDATE_ROLE_SUCCEED = "UPDATE_ROLE_SUCCEED";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";

export const GET_SOC = "GET_SOC";
export const GET_SOC_SUCCEED = "GET_SOC_SUCCEED";
export const GET_SOC_FAIL = "GET_SOC_FAIL";

export const CREATE_ROLE = "CREATE_ROLE";
export const CREATE_ROLE_SUCCEED = "CREATE_ROLE_SUCCEED";
export const CREATE_ROLE_FAIL = "CREATE_ROLE_FAIL";

export const GET_LICENSURE = "GET_LICENSURE";
export const GET_LICENSURE_SUCCEED = "GET_LICENSURE_SUCCEED";
export const GET_LICENSURE_FAIL = "GET_LICENSURE_FAIL";

export const GET_STUDENTS = "GET_STUDENTS";
export const GET_STUDENTS_SUCCEED = "GET_STUDENTS_SUCCEED";
export const GET_STUDENTS_FAIL = "GET_STUDENTS_FAIL";

export const GET_STUDENT = "GET_STUDENT";
export const GET_STUDENT_SUCCEED = "GET_STUDENT_SUCCEED";
export const GET_STUDENT_FAIL = "GET_STUDENT_FAIL";

export const IMPORT_STUDENT = "IMPORT_STUDENT";
export const IMPORT_STUDENT_SUCCEED = "IMPORT_STUDENT_SUCCEED";
export const IMPORT_STUDENT_FAIL = "IMPORT_STUDENT_FAIL";

export const GET_ROW = "GET_ROW";
export const GET_ROW_SUCCEED = "GET_ROW_SUCCEED";
export const GET_ROW_FAIL = "GET_ROW_FAIL";

export const GET_FILE_STATUS = "GET_FILE_STATUS";
export const GET_FILE_STATUS_SUCCEED = "GET_FILE_STATUS_SUCCEED";
export const GET_FILE_STATUS_FAIL = "GET_FILE_STATUS_FAIL";

export const CREATE_STUDENT = "CREATE_STUDENT";
export const CREATE_STUDENT_SUCCEED = "CREATE_STUDENT_SUCCEED";
export const CREATE_STUDENT_FAIL = "CREATE_STUDENT_FAIL";

export const DELETE_STUDENT = "DELETE_STUDENT";
export const DELETE_STUDENT_SUCCEED = "DELETE_STUDENT_SUCCEED";
export const DELETE_STUDENT_FAIL = "DELETE_STUDENT_FAIL";

export const GET_SURVEYS = "GET_SURVEYS";
export const GET_SURVEYS_SUCCEED = "GET_SURVEYS_SUCCEED";
export const GET_SURVEYS_FAIL = "GET_SURVEYS_FAIL";

export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const UPDATE_STUDENT_SUCCEED = "UPDATE_STUDENT_SUCCEED";
export const UPDATE_STUDENT_FAIL = "UPDATE_STUDENT_FAIL";

export const GET_ENROLLMENTS = "GET_ENROLLMENTS";
export const GET_ENROLLMENTS_SUCCEED = "GET_ENROLLMENTS_SUCCEED";
export const GET_ENROLLMENTS_FAIL = "GET_ENROLLMENTS_FAIL";

export const CREATE_ENROLLMENT = "CREATE_ENROLLMENT";
export const CREATE_ENROLLMENT_SUCCEED = "CREATE_ENROLLMENT_SUCCEED";
export const CREATE_ENROLLMENT_FAIL = "CREATE_ENROLLMENT_FAIL";

export const DELETE_ENROLLMENT = "DELETE_ENROLLMENT";
export const DELETE_ENROLLMENT_SUCCEED = "DELETE_ENROLLMENT_SUCCEED";
export const DELETE_ENROLLMENT_FAIL = "DELETE_ENROLLMENT_FAIL";

export const UPDATE_ENROLLMENT = "UPDATE_ENROLLMENT";
export const UPDATE_ENROLLMENT_SUCCEED = "UPDATE_ENROLLMENT_SUCCEED";
export const UPDATE_ENROLLMENT_FAIL = "UPDATE_ENROLLMENT_FAIL";

export const GET_PLACEMENTS = "GET_PLACEMENTS";
export const GET_PLACEMENTS_SUCCEED = "GET_PLACEMENTS_SUCCEED";
export const GET_PLACEMENTS_FAIL = "GET_PLACEMENTS_FAIL";

export const CREATE_PLACEMENT = "CREATE_PLACEMENT";
export const CREATE_PLACEMENT_SUCCEED = "CREATE_PLACEMENT_SUCCEED";
export const CREATE_PLACEMENT_FAIL = "CREATE_PLACEMENT_FAIL";

export const DELETE_PLACEMENT = "DELETE_PLACEMENT";
export const DELETE_PLACEMENT_SUCCEED = "DELETE_PLACEMENT_SUCCEED";
export const DELETE_PLACEMENT_FAIL = "DELETE_PLACEMENT_FAIL";

export const UPDATE_PLACEMENT = "UPDATE_PLACEMENT";
export const UPDATE_PLACEMENT_SUCCEED = "UPDATE_PLACEMENT_SUCCEED";
export const UPDATE_PLACEMENT_FAIL = "UPDATE_PLACEMENT_FAIL";

export const GET_LICENSURE_EXAMS = "GET_LICENSURE_EXAMS";
export const GET_LICENSURE_EXAMS_SUCCEED = "GET_LICENSURE_EXAMS_SUCCEED";
export const GET_LICENSURE_EXAMS_FAIL = "GET_LICENSURE_EXAMS_FAIL";

export const GET_PROGRAMS_LICENSURE_EXAMS = "GET_PROGRAMS_LICENSURE_EXAMS";
export const GET_PROGRAMS_LICENSURE_EXAMS_SUCCEED =
  "GET_PROGRAMS_LICENSURE_EXAMS_SUCCEED";
export const GET_PROGRAMS_LICENSURE_EXAMS_FAIL =
  "GET_PROGRAMS_LICENSURE_EXAMS_FAIL";

export const CREATE_LICENSURE_EXAM = "CREATE_LICENSURE_EXAM";
export const CREATE_LICENSURE_EXAM_SUCCEED = "CREATE_LICENSURE_EXAM_SUCCEED";
export const CREATE_LICENSURE_EXAM_FAIL = "CREATE_LICENSURE_EXAM_FAIL";

export const DELETE_LICENSURE_EXAM = "DELETE_LICENSURE_EXAM";
export const DELETE_LICENSURE_EXAM_SUCCEED = "DELETE_LICENSURE_EXAM_SUCCEED";
export const DELETE_LICENSURE_EXAM_FAIL = "DELETE_LICENSURE_EXAM_FAIL";

export const UPDATE_LICENSURE_EXAM = "UPDATE_LICENSURE_EXAM";
export const UPDATE_LICENSURE_EXAM_SUCCEED = "UPDATE_LICENSURE_EXAM_SUCCEED";
export const UPDATE_LICENSURE_EXAM_FAIL = "UPDATE_LICENSURE_EXAM_FAIL";

export const GET_STUDENT_FINANCIAL_AID = "GET_STUDENT_FINANCIAL_AID";
export const GET_STUDENT_FINANCIAL_AID_SUCCEED =
  "GET_STUDENT_FINANCIAL_AID_SUCCEED";
export const GET_STUDENT_FINANCIAL_AID_FAIL = "GET_STUDENT_FINANCIAL_AID_FAIL";

export const GET_STUDENT_TASKS = "GET_STUDENT_TASKS";
export const GET_STUDENT_TASKS_SUCCEED = "GET_STUDENT_TASKS_SUCCEED";
export const GET_STUDENT_TASKS_FAIL = "GET_STUDENT_TASKS_FAIL";

export const GET_STUDENT_FINANCIAL_AID_PROGRAMS =
  "GET_STUDENT_FINANCIAL_AID_PROGRAMS";
export const GET_STUDENT_FINANCIAL_AID_PROGRAMS_SUCCEED =
  "GET_STUDENT_FINANCIAL_AID_PROGRAMS_SUCCEED";
export const GET_STUDENT_FINANCIAL_AID_PROGRAMS_FAIL =
  "GET_STUDENT_FINANCIAL_AID_PROGRAMS_FAIL";

export const CREATE_STUDENT_FINANCIAL_AID = "CREATE_STUDENT_FINANCIAL_AID";
export const CREATE_STUDENT_FINANCIAL_AID_SUCCEED =
  "CREATE_STUDENT_FINANCIAL_AID_SUCCEED";
export const CREATE_STUDENT_FINANCIAL_AID_FAIL =
  "CREATE_STUDENT_FINANCIAL_AID_FAIL";

export const DELETE_STUDENT_FINANCIAL_AID = "DELETE_STUDENT_FINANCIAL_AID";
export const DELETE_STUDENT_FINANCIAL_AID_SUCCEED =
  "DELETE_STUDENT_FINANCIAL_AID_SUCCEED";
export const DELETE_STUDENT_FINANCIAL_AID_FAIL =
  "DELETE_STUDENT_FINANCIAL_AID_FAIL";

export const UPDATE_STUDENT_FINANCIAL_AID = "UPDATE_STUDENT_FINANCIAL_AID";
export const UPDATE_STUDENT_FINANCIAL_AID_SUCCEED =
  "UPDATE_STUDENT_FINANCIAL_AID_SUCCEED";
export const UPDATE_STUDENT_FINANCIAL_AID_FAIL =
  "UPDATE_STUDENT_FINANCIAL_AID_FAIL";

export const GET_CONTACT_LOGS = "GET_CONTACT_LOGS";
export const GET_CONTACT_LOGS_SUCCEED = "GET_CONTACT_LOGS_SUCCEED";
export const GET_CONTACT_LOGS_FAIL = "GET_CONTACT_LOGS_FAIL";

export const CREATE_CONTACT_LOG = "CREATE_CONTACT_LOG";
export const CREATE_CONTACT_LOG_SUCCEED = "CREATE_CONTACT_LOG_SUCCEED";
export const CREATE_CONTACT_LOG_FAIL = "CREATE_CONTACT_LOG_FAIL";

export const DELETE_CONTACT_LOG = "DELETE_CONTACT_LOG";
export const DELETE_CONTACT_LOG_SUCCEED = "DELETE_CONTACT_LOG_SUCCEED";
export const DELETE_CONTACT_LOG_FAIL = "DELETE_CONTACT_LOG_FAIL";

export const UPDATE_CONTACT_LOG = "UPDATE_CONTACT_LOG";
export const UPDATE_CONTACT_LOG_SUCCEED = "UPDATE_CONTACT_LOG_SUCCEED";
export const UPDATE_CONTACT_LOG_FAIL = "UPDATE_CONTACT_LOG_FAIL";

export const GET_STUDENT_CHANGE_LOG = "GET_STUDENT_CHANGE_LOG";
export const GET_STUDENT_CHANGE_LOG_SUCCEED = "GET_STUDENT_CHANGE_LOG_SUCCEED";
export const GET_STUDENT_CHANGE_LOG_FAIL = "GET_STUDENT_CHANGE_LOG_FAIL";

export const SET_DATA_CHANG_LOG = "SET_DATA_CHANG_LOG";

export const GET_STUDENT_DOCUMENTS = "GET_STUDENT_DOCUMENTS";
export const GET_STUDENT_DOCUMENTS_SUCCEED = "GET_STUDENT_DOCUMENTS_SUCCEED";
export const GET_STUDENT_DOCUMENTS_FAIL = "GET_STUDENT_DOCUMENTS_FAIL";

export const SET_SELECTED_FILTER_REPORT = "SET_SELECTED_FILTER_REPORT";

export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCEED = "GET_USERS_SUCCEED";
export const GET_USERS_FAIL = "GET_USERS_FAIL";

export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const DELETE_DOCUMENT_SUCCEED = "DELETE_DOCUMENT_SUCCEED";
export const DELETE_DOCUMENT_FAIL = "DELETE_DOCUMENT_FAIL";

export const GET_SURVEY_INFO = "GET_SURVEY_INFO";
export const GET_SURVEY_INFO_SUCCEED = "GET_SURVEY_INFO_SUCCEED";
export const GET_SURVEY_INFO_FAIL = "GET_SURVEY_INFO_FAIL";

export const GET_INTEGRATIONS = "GET_INTEGRATIONS";
export const GET_INTEGRATIONS_SUCCEED = "GET_INTEGRATIONS_SUCCEED";
export const GET_INTEGRATIONS_FAIL = "GET_INTEGRATIONS_FAIL";

export const GET_SALESFORCE_ACTIVATE = "GET_SALESFORCE_ACTIVATE";
export const GET_SALESFORCE_ACTIVATE_SUCCEED =
  "GET_SALESFORCE_ACTIVATE_SUCCEED";
export const GET_SALESFORCE_ACTIVATE_FAIL = "GET_SALESFORCE_ACTIVATE_FAIL";

export const GET_SALESFORCE_OBJECTS = "GET_SALESFORCE_OBJECTS";
export const GET_SALESFORCE_OBJECTS_SUCCEED = "GET_SALESFORCE_OBJECTS_SUCCEED";
export const GET_SALESFORCE_OBJECTS_FAIL = "GET_SALESFORCE_OBJECTS_FAIL";

export const GET_SALESFORCE_ATTRIBUTES = "GET_SALESFORCE_ATTRIBUTES";
export const GET_SALESFORCE_ATTRIBUTES_SUCCEED =
  "GET_SALESFORCE_ATTRIBUTES_SUCCEED";
export const GET_SALESFORCE_ATTRIBUTES_FAIL = "GET_SALESFORCE_ATTRIBUTES_FAIL";

export const CREATE_SALESFORCE_AUTHORIZE = "CREATE_SALESFORCE_AUTHORIZE";
export const CREATE_SALESFORCE_AUTHORIZE_SUCCEED =
  "CREATE_SALESFORCE_AUTHORIZE_SUCCEED";
export const CREATE_SALESFORCE_AUTHORIZE_FAIL =
  "CREATE_SALESFORCE_AUTHORIZE_FAIL";

export const CREATE_SALESFORCE_MAPPING = "CREATE_SALESFORCE_MAPPING";
export const CREATE_SALESFORCE_MAPPING_SUCCEED =
  "CREATE_SALESFORCE_MAPPING_SUCCEED";
export const CREATE_SALESFORCE_MAPPING_FAIL = "CREATE_SALESFORCE_MAPPING_FAIL";

export const CREATE_SALESFORCE_BULK_MAPPING = "CREATE_SALESFORCE_BULK_MAPPING";
export const CREATE_SALESFORCE_BULK_MAPPING_SUCCEED =
  "CREATE_SALESFORCE_BULK_MAPPING_SUCCEED";
export const CREATE_SALESFORCE_BULK_MAPPING_FAIL =
  "CREATE_SALESFORCE_BULK_MAPPING_FAIL";

export const CREATE_SALESFORCE_SYNC = "CREATE_SALESFORCE_SYNC";
export const CREATE_SALESFORCE_SYNC_SUCCEED = "CREATE_SALESFORCE_SYNC_SUCCEED";
export const CREATE_SALESFORCE_SYNC_FAIL = "CREATE_SALESFORCE_SYNC_FAIL";

export const GET_SALESFORCE_MAPPINGS = "GET_SALESFORCE_MAPPINGS";
export const GET_SALESFORCE_MAPPINGS_SUCCEED =
  "GET_SALESFORCE_MAPPINGS_SUCCEED";
export const GET_SALESFORCE_MAPPINGS_FAIL = "GET_SALESFORCE_MAPPINGS_FAIL";

export const GET_SALESFORCE_DESTROY_ALL = "GET_SALESFORCE_DESTROY_ALL";
export const GET_SALESFORCE_DESTROY_ALL_SUCCEED =
  "GET_SALESFORCE_DESTROY_ALL_SUCCEED";
export const GET_SALESFORCE_DESTROY_ALL_FAIL =
  "GET_SALESFORCE_DESTROY_ALL_FAIL";

export const DELETE_INTEGRATION = "DELETE_INTEGRATION";
export const DELETE_INTEGRATION_SUCCEED = "DELETE_INTEGRATION_SUCCEED";
export const DELETE_INTEGRATION_FAIL = "DELETE_INTEGRATION_FAIL";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCEED = "GET_PROFILE_SUCCEED";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_SUCCEED = "UPDATE_PROFILE_SUCCEED";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const OPEN_MODAL_MESSAGE_DELETE = "OPEN_MODAL_MESSAGE_DELETE";

export const CLOSE_MODAL_MESSAGE_DELETE = "CLOSE_MODAL_MESSAGE_DELETE";

export const OPEN_MODAL_MESSAGE_INTEGRATION = "OPEN_MODAL_MESSAGE_INTEGRATION";

export const CLOSE_MODAL_MESSAGE_INTEGRATION =
  "CLOSE_MODAL_MESSAGE_INTEGRATION";

export const GET_HUBSPOT_ACTIVATE = "GET_HUBSPOT_ACTIVATE";
export const GET_HUBSPOT_ACTIVATE_SUCCEED = "GET_HUBSPOT_ACTIVATE_SUCCEED";
export const GET_HUBSPOT_ACTIVATE_FAIL = "GET_HUBSPOT_ACTIVATE_FAIL";

export const CREATE_HUBSPOT_AUTHORIZE = "CREATE_HUBSPOT_AUTHORIZE";
export const CREATE_HUBSPOT_AUTHORIZE_SUCCEED =
  "CREATE_HUBSPOT_AUTHORIZE_SUCCEED";
export const CREATE_HUBSPOT_AUTHORIZE_FAIL = "CREATE_HUBSPOT_AUTHORIZE_FAIL";

export const GET_HUBSPOT_ATTRIBUTES = "GET_HUBSPOT_ATTRIBUTES";
export const GET_HUBSPOT_ATTRIBUTES_SUCCEED = "GET_HUBSPOT_ATTRIBUTES_SUCCEED";
export const GET_HUBSPOT_ATTRIBUTES_FAIL = "GET_HUBSPOT_ATTRIBUTES_FAIL";

export const CREATE_HUBSPOT_MAPPING = "CREATE_HUBSPOT_MAPPING";
export const CREATE_HUBSPOT_MAPPING_SUCCEED = "CREATE_HUBSPOT_MAPPING_SUCCEED";
export const CREATE_HUBSPOT_MAPPING_FAIL = "CREATE_HUBSPOT_MAPPING_FAIL";

export const CREATE_HUBSPOT_SYNC = "CREATE_HUBSPOT_SYNC";
export const CREATE_HUBSPOT_SYNC_SUCCEED = "CREATE_HUBSPOT_SYNC_SUCCEED";
export const CREATE_HUBSPOT_SYNC_FAIL = "CREATE_HUBSPOT_SYNC_FAIL";

export const GET_HUBSPOT_MAPPINGS = "GET_HUBSPOT_MAPPINGS";
export const GET_HUBSPOT_MAPPINGS_SUCCEED = "GET_HUBSPOT_MAPPINGS_SUCCEED";
export const GET_HUBSPOT_MAPPINGS_FAIL = "GET_HUBSPOT_MAPPINGS_FAIL";

export const GET_AGENCIES = "GET_AGENCIES";
export const GET_AGENCIES_SUCCEED = "GET_AGENCIES_SUCCEED";
export const GET_AGENCIES_FAIL = "GET_AGENCIES_FAIL";

export const SEND_SURVEY = "SEND_SURVEY";
export const SEND_SURVEY_SUCCEED = "SEND_SURVEY_SUCCEED";
export const SEND_SURVEY_FAIL = "SEND_SURVEY_FAIL";

export const GET_STUDENT_PROGRAMS = "GET_STUDENT_PROGRAMS";
export const GET_STUDENT_PROGRAMS_SUCCEED = "GET_STUDENT_PROGRAMS_SUCCEED";
export const GET_STUDENT_PROGRAMS_FAIL = "GET_STUDENT_PROGRAMS_FAIL";

export const GET_BRANCHES_REPORTING = "GET_BRANCHES_REPORTING";
export const GET_BRANCHES_REPORTING_SUCCEED = "GET_BRANCHES_REPORTING_SUCCEED";
export const GET_BRANCHES_REPORTING_FAIL = "GET_BRANCHES_REPORTING_FAIL";

export const GET_CIRR_SURVEY_INFO = "GET_CIRR_SURVEY_INFO";
export const GET_CIRR_SURVEY_INFO_SUCCEED = "GET_CIRR_SURVEY_INFO_SUCCEED";
export const GET_CIRR_SURVEY_INFO_FAIL = "GET_CIRR_SURVEY_INFO_FAIL";

export const GET_CIRR_SURVEY_SIGN = "GET_CIRR_SURVEY_SIGN";
export const GET_CIRR_SURVEY_SIGN_SUCCEED = "GET_CIRR_SURVEY_SIGN_SUCCEED";
export const GET_CIRR_SURVEY_SIGN_FAIL = "GET_CIRR_SURVEY_SIGN_FAIL";

export const CREATE_CIRR_SURVEY = "CREATE_CIRR_SURVEY";
export const CREATE_CIRR_SURVEY_SUCCEED = "CREATE_CIRR_SURVEY_SUCCEED";
export const CREATE_CIRR_SURVEY_FAIL = "CREATE_CIRR_SURVEY_FAIL";

export const CREATE_CIRR_SURVEY_COMPLETE = "CREATE_CIRR_SURVEY_COMPLETE";
export const CREATE_CIRR_SURVEY_COMPLETE_SUCCEED =
  "CREATE_CIRR_SURVEY_COMPLETE_SUCCEED";
export const CREATE_CIRR_SURVEY_COMPLETE_FAIL =
  "CREATE_CIRR_SURVEY_COMPLETE_FAIL";

export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const CREATE_CAMPAIGN_SUCCEED = "CREATE_CAMPAIGN_SUCCEED";
export const CREATE_CAMPAIGN_FAIL = "CREATE_CAMPAIGN_FAIL";

export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const UPDATE_CAMPAIGN_SUCCEED = "UPDATE_CAMPAIGN_SUCCEED";
export const UPDATE_CAMPAIGN_FAIL = "UPDATE_CAMPAIGN_FAIL";

export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN";
export const DELETE_CAMPAIGN_SUCCEED = "DELETE_CAMPAIGN_SUCCEED";
export const DELETE_CAMPAIGN_FAIL = "DELETE_CAMPAIGN_FAIL";

export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_CAMPAIGNS_SUCCEED = "GET_CAMPAIGNS_SUCCEED";
export const GET_CAMPAIGNS_FAIL = "GET_CAMPAIGNS_FAIL";

export const GET_CAMPAIGN_DETAIL = "GET_CAMPAIGN_DETAIL";
export const GET_CAMPAIGN_DETAIL_SUCCEED = "GET_CAMPAIGN_DETAIL_SUCCEED";
export const GET_CAMPAIGN_DETAIL_FAIL = "GET_CAMPAIGN_DETAIL_FAIL";

export const OPEN_MODAL_PAYMENT = "OPEN_MODAL_PAYMENT";
export const CLOSE_MODAL_PAYMENT = "CLOSE_MODAL_PAYMENT";

export const GET_RESPONSES = "GET_RESPONSES";
export const GET_RESPONSES_SUCCEED = "GET_RESPONSES_SUCCEED";
export const GET_RESPONSES_FAIL = "GET_RESPONSES_FAIL";

export const GET_TASKS = "GET_TASKS";
export const GET_TASKS_SUCCEED = "GET_TASKS_SUCCEED";
export const GET_TASKS_FAIL = "GET_TASKS_FAIL";

export const REJECT_TASK = "REJECT_TASK";
export const REJECT_TASK_SUCCEED = "REJECT_TASK_SUCCEED";
export const REJECT_TASK_FAIL = "REJECT_TASK_FAIL";

export const APPROVE_TASK = "APPROVE_TASK";
export const APPROVE_TASK_SUCCEED = "APPROVE_TASK_SUCCEED";
export const APPROVE_TASK_FAIL = "APPROVE_TASK_FAIL";
