import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import FormGroup from "../common/FormGroup";
import logo from "../../assets/images/logo2.svg";

function ResetPassword({
  form,
  formErrors,
  onSubmit,
  onChange,
  onFocus,
  isSubmitting,
}) {
  const [typePassword, setTypePassword] = React.useState("password");
  const [typeNewPassword, setTypeNewPassword] = React.useState("password");

  const showHide = () => {
    if (typePassword === "password") {
      setTypePassword("text");
    } else {
      setTypePassword("password");
    }
  };

  const showHideNewPassword = () => {
    if (typeNewPassword === "password") {
      setTypeNewPassword("text");
    } else {
      setTypeNewPassword("password");
    }
  };
  return (
    <section className="ResetPassword bg-form">
      <Link to="/" className="logo-form">
        <img src={logo} alt="" />
      </Link>
      <div className="container">
        <div className="ResetPassword__inner academy-form">
          <Form noValidate onSubmit={onSubmit}>
            <p className="text--xlarge bold">Create a New Password</p>
            <div className="form__inner">
              <FormGroup
                propsInput={{
                  name: "password",
                  placeholder: "New Password",
                  type: typePassword,
                  onChange,
                  onFocus,
                  value: form.password,
                  disabled: isSubmitting,
                }}
                propsIcon={{
                  onClick: showHide,
                }}
                error={formErrors.password}
                label="New Password"
                variant="Input-password"
              />
              <FormGroup
                propsInput={{
                  name: "password_confirmation",
                  placeholder: "Repeat New Password",
                  type: typeNewPassword,
                  onChange,
                  onFocus,
                  disabled: isSubmitting,
                }}
                propsIcon={{
                  onClick: showHideNewPassword,
                }}
                error={formErrors.password_confirmation}
                label="Repeat New Password"
                variant="Input-password"
              />

              <button
                className="button button--primary"
                type="submit"
                disabled={isSubmitting}
              >
                Update Password
              </button>
            </div>
          </Form>
        </div>
      </div>
    </section>
  );
}

ResetPassword.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    password_confirmation: PropTypes.string,
  }),
  // eslint-disable-next-line react/forbid-prop-types
  formErrors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

ResetPassword.defaultProps = {
  form: {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
  },
  formErrors: {},
};

export default ResetPassword;
