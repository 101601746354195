import React from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import { Table } from "react-bootstrap";
import { ArrowRight2, Delete, Edit, Plus } from "../../common/icons";
import border from "../../../assets/images/border-dark.svg";
import {
  AUDIT_ACTIONS,
  AUDIT_TYPES,
  AUDIT_TYPE_STUDENT,
} from "../../../utils/constants";
import noTable from "../../../assets/images/noTable.png";
import noActivity from "../../../assets/images/noActivity.png";

function Survey() {
  const storeDashboard = useSelector((store) => store.dashboard);
  const data = storeDashboard.data || {};
  const [dataSurveys, setDataSurveys] = React.useState([]);
  const [dataOutcomes, setDataOutcomes] = React.useState([]);
  const dataAudits = data.recent_audits || [];

  React.useEffect(() => {
    const newDataSurveys = data.recent_surveys ? [...data.recent_surveys] : [];
    const length = newDataSurveys.length;

    if (newDataSurveys.length < 5) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < 5 - length; index++) {
        newDataSurveys.push({
          name: "",
          status: "",
          response_rate: 0,
          scheduled_at: "",
        });
      }
    }
    setDataSurveys(newDataSurveys);
  }, [data.recent_surveys]);

  React.useEffect(() => {
    const newDataOutcomes = data.recent_outcomes
      ? [...data.recent_outcomes]
      : [];
    const length = newDataOutcomes.length;

    if (newDataOutcomes.length < 5) {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < 5 - length; index++) {
        newDataOutcomes.push({
          student: "",
          type: "",
          outcome: 0,
          date: "",
        });
      }
    }
    setDataOutcomes(newDataOutcomes);
  }, [data.recent_outcomes]);

  return (
    <div className="Survey ">
      <div className="Survey__inner">
        <div className="Survey__inner__left">
          <div className="dashboard--children">
            <p className="text--title bold title">Survey Schedule</p>
            {!data.recent_surveys || !data.recent_surveys.length ? (
              <div className="Nodata">
                <p className="--noData">
                  There’s currently no survey data to display
                </p>
                <img src={noTable} alt="" />
              </div>
            ) : (
              <div className="table-wrap">
                <Table striped>
                  <thead>
                    <tr>
                      <th>Survey </th>
                      <th>Status</th>
                      <th>Response Rate</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataSurveys.map((item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <tr key={`row-survey-${index}`}>
                        <td>{item.name}</td>
                        <td>{item.status}</td>
                        <td>
                          {item.response_rate !== 0 ? item.response_rate : "-"}
                        </td>
                        <td>
                          {item.scheduled_at ? (
                            <>
                              {moment(item.scheduled_at).format("MM/DD/YY")}
                              <ArrowRight2 />
                            </>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
          <div className="dashboard--children">
            <p className="text--title bold title">Recent Outcomes</p>
            {!data.recent_outcomes || !data.recent_outcomes.length ? (
              <div className="Nodata">
                <p className="--noData">
                  There’s currently no outcomes data to display
                </p>
                <img src={noTable} alt="" />
              </div>
            ) : (
              <div className="table-wrap table__recent-outcome">
                <Table striped>
                  <thead>
                    <tr>
                      <th>Student </th>
                      <th>Type</th>
                      <th>Outcome</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataOutcomes.map((item, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <tr key={`row-outcome-${index}`}>
                        <td>{item.student}</td>
                        <td>{item.type}</td>
                        <td>{item.outcome}</td>
                        <td>
                          {item.date ? (
                            <>
                              {moment(item.date).format("MM/DD/YY")}
                              <ArrowRight2 />
                            </>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </div>

        <div className="Survey__inner__right dashboard--children">
          <p className="text--title bold title">Recent Activity</p>
          {!data.recent_audits || !data.recent_audits.length ? (
            <div className="Nodata">
              <p className="--noData">
                There’s currently no outcomes data to display
              </p>
              <img src={noActivity} alt="" />
            </div>
          ) : (
            <div>
              <p className="text--xsmall today">Today</p>
              {dataAudits.map((item) => (
                <div className="activity" key={`audit-${item.id}`}>
                  <div className="activity__left">
                    <div
                      className={classNames("icon", {
                        edit: item.action === "update",
                        plus: item.action === "create",
                        delete: item.action === "destroy",
                      })}
                    >
                      {item.action === "update" && <Edit />}
                      {item.action === "create" && <Plus />}
                      {item.action === "destroy" && <Delete />}
                    </div>
                    <img src={border} alt="" />
                  </div>
                  <div className="activity__right">
                    <p className="text--small bold activity__text">
                      <span>
                        {item.user ? item.user.full_name : "AcademySync"}
                      </span>{" "}
                      {AUDIT_ACTIONS[item.action]}{" "}
                      {AUDIT_TYPES[item.auditable_type].text}{" "}
                      <span>
                        {item.auditable_type === AUDIT_TYPE_STUDENT &&
                        item.auditable
                          ? `${item.auditable.first_name} ${item.auditable.last_name}`
                          : ""}
                        {item.auditable_type !== AUDIT_TYPE_STUDENT &&
                        item.associated
                          ? `${item.associated.first_name} ${item.associated.last_name}`
                          : ""}
                      </span>
                      .
                    </p>
                    <div>
                      <p className="text--small">
                        {AUDIT_TYPES[item.auditable_type].label}
                      </p>
                      <p className="text--small">
                        {moment(item.created_at).format("MM/DD/YY [at] h:mma")}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Survey;
