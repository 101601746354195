import "./styles/global.css";

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import { GraduateFollowUpSurvey } from "features/GraduateFollowUpSurvey";
import { StudentsRegister } from "features/StudentsRegister";
import DashboardStudent from "features/dashboardStudent";
import Message from "./components/common/Message";
import { Dashboard } from "./containers/dashboard";
import {
  ContactUs,
  Landing,
  PrivacyPolicy,
  SignUp,
  TearmsOfService,
  Login,
  ForgotPassword,
  Onboarding,
  ResetPassword,
  CIRRSurveyIntend,
  CIRRSurveyIntendComplete,
  CookiePolicy,
  UserPolicy,
} from "./containers";
import {
  withAuth,
  withMainLayout,
  withRedirect,
  withBootstrap,
  withAuthStudent,
} from "./HOCs";
import { ROUTES } from "./utils/constants";
import Logout from "./containers/Logout";
import Pricing from "./containers/Pricing";
import { ModalMessageDelete } from "./components/common";
import ModalMessageIntegrate from "./components/common/ModalMessageIntegrate";

import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/_main.scss";

function ScrollToTop(props: any) {
  React.useEffect(() => {
    if (props.location && props.location.pathname !== "/") {
      window.scrollTo(0, 0);
    }
  }, [props.location]);

  return props.children;
}

const ScrollToTopRouter = withRouter(ScrollToTop);

function App() {
  return (
    <Router>
      <ScrollToTopRouter>
        <Message />
        <ModalMessageDelete />
        <ModalMessageIntegrate />
        <Switch>
          <Route
            path={ROUTES.contactUs}
            component={withBootstrap(withRedirect(withMainLayout(ContactUs)))}
            exact
          />
          <Route
            path={ROUTES.pricing}
            component={withBootstrap(withRedirect(withMainLayout(Pricing)))}
            exact
          />
          <Route
            path={ROUTES.privacy}
            component={withBootstrap(
              withRedirect(withMainLayout(PrivacyPolicy))
            )}
            exact
          />
          <Route
            path={ROUTES.tos}
            component={withBootstrap(
              withRedirect(withMainLayout(TearmsOfService))
            )}
            exact
          />
          <Route
            path={ROUTES.cookiePolicy}
            component={withBootstrap(
              withRedirect(withMainLayout(CookiePolicy))
            )}
            exact
          />
          <Route
            path={ROUTES.userPolicy}
            component={withBootstrap(withRedirect(withMainLayout(UserPolicy)))}
            exact
          />
          <Route path={ROUTES.signup} component={withBootstrap(SignUp)} exact />
          <Route
            path={ROUTES.login}
            component={withBootstrap(withRedirect(Login))}
            exact
          />
          <Route path={ROUTES.logout} component={withBootstrap(Logout)} exact />
          <Route
            path={ROUTES.forgotPassword}
            component={withBootstrap(withRedirect(ForgotPassword))}
            exact
          />
          <Route
            path={ROUTES.resetPassword}
            component={withBootstrap(withRedirect(ResetPassword))}
            exact
          />
          <Route
            path={ROUTES.dashboardStudent}
            component={withBootstrap(withAuth(DashboardStudent))}
          />
          <Route
            path={ROUTES.dashboard}
            component={withBootstrap(withAuth(Dashboard))}
          />
          <Route
            path={ROUTES.onBoarding}
            component={withBootstrap(withAuth(Onboarding))}
            exact
          />
          <Route
            path={ROUTES.surveySteps}
            component={withAuth(withAuthStudent(GraduateFollowUpSurvey))}
          />
          <Route
            path={ROUTES.surveyComplete}
            component={withAuth(withAuthStudent(GraduateFollowUpSurvey))}
          />
          <Route
            path={ROUTES.cirrSurveyIntend}
            component={withBootstrap(CIRRSurveyIntend)}
            exact
          />
          <Route
            path={ROUTES.cirrSurveyIntendComplete}
            component={withBootstrap(CIRRSurveyIntendComplete)}
            exact
          />
          <Route
            path={ROUTES.studentsRegister}
            component={StudentsRegister}
            exact
          />
          <Route
            path={ROUTES.home}
            component={withBootstrap(withRedirect(withMainLayout(Landing)))}
          />
        </Switch>
      </ScrollToTopRouter>
    </Router>
  );
}

export default App;
