import React from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { TableActions } from "components";
import { ROUTES, SEARCH_NAME, SORT_NAME } from "../../../utils/constants";
import { FormGroup } from "../../common";
import { Plus } from "../../common/icons";
import Table from "../../common/Table";

function CohortsTable({
  storeCohorts = { data: [], meta: {}, loading: false, error: null },
  onPageChange = () => null,
  params = {},
  onSort = () => {},
  onSearch = () => {},
  onDelete = () => {},
  onEdit = () => {},
  onClone = () => {},
  onSendSurvey = () => {},
  onSendStudentRegisterForm = () => {},
  onCopyInvitationToken = () => {},
}) {
  const history = useHistory();

  const handleClick = () => {
    history.push(ROUTES.addCohorts);
  };

  const columns = [
    {
      title: "Name ",
      dataIndex: "name",
      sortAble: true,
      render: (row) => row.name,
    },
    {
      title: "Program Name ",
      dataIndex: "program_name",
      sortAble: true,
      render: (row) => row.program.name,
    },
    {
      title: "Branch Name",
      dataIndex: "branch_name",
      sortAble: true,
      render: (row) => row.branch.name,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      sortAble: true,
      render: (row) => moment(row.start_date).utc().format("MM/DD/YYYY"),
    },
    {
      title: "Graduation Date",
      dataIndex: "graduation_date",
      sortAble: true,
      render: (row) => moment(row.graduation_date).utc().format("MM/DD/YYYY"),
    },
    {
      title: "Action",
      dataIndex: "id",
      render: (row) => (
        <TableActions
          actions={[
            {
              label: "Send Survey",
              icon: ["fas", "share"],
              onSelect: onSendSurvey(row),
            },
            {
              label: "Copy Student Registration Link",
              icon: ["fas", "user-graduate"],
              onSelect: onSendStudentRegisterForm(row),
            },
            {
              label: "Copy Invitation Token",
              icon: ["fas", "user-graduate"],
              onSelect: onCopyInvitationToken(row),
            },
            {
              label: "Edit",
              icon: ["fas", "pen-to-square"],
              onSelect: onEdit(row),
            },
            {
              label: "Clone",
              icon: ["fas", "clone"],
              onSelect: onClone(row),
            },
            {
              label: "Delete",
              icon: ["fas", "trash"],
              onSelect: onDelete(row),
            },
          ]}
        />
      ),
      align: "right",
    },
  ];

  return (
    <div className="Cohorts__inner dashboard__item__inner">
      <div className="filter">
        <div className="filter__left">
          <FormGroup
            propsInput={{
              name: "name",
              className: "inputSearch",
              placeholder: "What are you looking for?",
              onChange: onSearch,
              value: params[SEARCH_NAME] || "",
            }}
          />
        </div>

        <button type="submit" className="button--icon" onClick={handleClick}>
          <Plus />
          Cohort
        </button>
      </div>
      <Table
        loading={storeCohorts.loading}
        columns={columns}
        data={storeCohorts.data}
        tableProps={{ striped: true }}
        sort={params[SORT_NAME] || ""}
        onSort={onSort}
        paginationProps={{
          itemsLength: storeCohorts.meta.total,
          perPage: storeCohorts.meta.perPage,
          onPageChange,
          activePage: params.page || 1,
        }}
      />
    </div>
  );
}

export default CohortsTable;
