import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmail } from "validator";
import { Banner, Form } from "../components/contactUs";
import { postContactUs } from "../redux/actions/contactUs";
import { openMessage } from "../redux/actions/message";
import { formatPhoneNumber } from "../utils/helpers";

const DEFAULT_FORM_CONTACT_US = {
  name: "",
  email: "",
  phone: "",
  school: "",
  message: "",
};

export default function () {
  const dispatch = useDispatch();
  const [form, setForm] = React.useState({ ...DEFAULT_FORM_CONTACT_US });
  const [formErrors, setFormErrors] = React.useState({});

  const storeContactUs = useSelector((store) => store.contactUs);

  const callApiContactUs = async () => {
    try {
      await dispatch(postContactUs(form));
      dispatch(
        openMessage({
          title: "Your form has been submitted. We will respond to you soon!",
          type: "success",
        })
      );
      setForm({ ...DEFAULT_FORM_CONTACT_US });
    } catch (error) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const errors = {};

    if (!form.name) {
      errors.name = "Name is required";
    }

    if (!form.email || !isEmail(form.email)) {
      errors.email = "Email is invalid";
    }

    if (!form.message) {
      errors.message = "Message is invalid";
    }

    setFormErrors(errors);

    // call api
    if (!Object.keys(errors).length) {
      callApiContactUs();
    }
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const handleChangeNumber = (e) => {
    const phoneNumber = e.target.value;
    if (phoneNumber.length > 14) {
      return;
    }
    if (phoneNumber.length === 4) {
      return setForm({ ...form, phoneNumber });
    }
    setForm({ ...form, phone: formatPhoneNumber(phoneNumber) });
  };

  const handleFocus = (event) => {
    setFormErrors({
      ...formErrors,
      [event.target.name]: "",
    });
  };

  return (
    <>
      <Banner />
      <Form
        form={form}
        formErrors={formErrors}
        onSubmit={handleSubmit}
        onChange={handleChange}
        onChangeNumber={handleChangeNumber}
        onFocus={handleFocus}
        isSubmitting={storeContactUs.loading}
      />
    </>
  );
}
