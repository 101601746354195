import React from "react";
import { SurveyInfo, usePlacementInfo } from "../../api";
import { SchoolLogo } from "../SchoolLogo";
import css from "./styles.module.scss";

type Props = {
  surveyInfo: SurveyInfo;
  title?: string;
};

export function StepHeader(props: Props) {
  const { surveyInfo } = props;
  const title = props?.title;
  const placementInfo = usePlacementInfo();
  const dataPlacementInfo = placementInfo.data;
  const changeRequest = dataPlacementInfo?.surveyResult?.changeRequests?.find(
    (r) => r.status === "pending"
  );

  return (
    <>
      <header className={css.header}>
        <SchoolLogo school={surveyInfo.school} />
        <h2 className={css.smallTitle}>Graduate Follow-Up Survey</h2>
        {changeRequest && (
          <div className={css.changeRequest}>
            <h2>Change request notes:</h2>
            {changeRequest.notes}
          </div>
        )}
      </header>
      {title && <h1 className={css.title}>{title}</h1>}
    </>
  );
}
