/* eslint-disable react/prop-types */
import React from "react";
import { Form } from "react-bootstrap";
import Range from "./Range";

function StepComplete({ dataSurvey = {}, event, schoolData }) {
  return (
    <div className="Step step10">
      <div className="Step__inner ">
        <div className="form_wrap">
          <Form noValidate>
            <div className="form__inner">
              <div className="Step__inner__header">
                <img
                  src={schoolData.logo}
                  alt=""
                  style={{ display: "inline" }}
                />
                <p>CIRR Student Intent Survey</p>
              </div>
              <div className="Step__inner__body">
                <p className="title">
                  {event === "signing_complete"
                    ? "Survey completed - Thank you!"
                    : "Uncomplete!"}
                </p>
                <p className="text--small">
                  {event === "signing_complete"
                    ? " Thank you for taking the time to complete this survey. Your answers will help your school report on graduate employment. Have a great day."
                    : "Please sign the document."}
                </p>
                {event === "signing_complete" && dataSurvey?.file?.url && (
                  <a href={dataSurvey?.file?.url}>
                    <button className="button button--primary" type="button">
                      Download Survey
                    </button>
                  </a>
                )}
              </div>
            </div>
          </Form>
          <Range percent={100} iconActive />
        </div>
      </div>
    </div>
  );
}

export default StepComplete;
