import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  RequiredStringSchema,
  DateInputSchema,
  NumberSchema,
  YesNoSchema,
  PhoneNumberSchema,
  EmailSchema,
} from "lib/zod";
import type { SurveyInfo } from "../api";
import { useSurveyStore } from "../store";
import {
  Card,
  ButtonArea,
  Button,
  Form,
  StepHeader,
  TextInput,
  RadioGroup,
} from "../components";

type Props = {
  surveyInfo: SurveyInfo;
  progress?: string;
  onNextClick: () => void;
  onBackClick: () => void;
};

const schema = z
  .object({
    jobTitle: RequiredStringSchema,
    hoursPerWeek: NumberSchema,
    chosePartTime: z.enum(["YES", "NO"]).nullable(),
    duration: z.enum(["Less than 3 months", "3-6 months", "6+ months"], {
      invalid_type_error: "Required",
    }),
    longerThanFiveWeeks: YesNoSchema,
    offerDate: DateInputSchema,
    startDate: DateInputSchema,
    salaryType: z.enum(["Annual", "Hourly", "Weekly"], {
      invalid_type_error: "Required",
    }),
    salary: NumberSchema,
    hiredBySchool: YesNoSchema,
    placedBySchool: YesNoSchema,
    employerName: RequiredStringSchema,
    employerWebsite: RequiredStringSchema,
    employerAddress: RequiredStringSchema,
    employerCity: RequiredStringSchema,
    employerState: RequiredStringSchema,
    employerZipCode: RequiredStringSchema,
    employerPhone: PhoneNumberSchema,
    employerContactName: RequiredStringSchema,
    employerContactPhone: PhoneNumberSchema,
    employerContactEmail: EmailSchema,
  })
  .superRefine(({ hoursPerWeek, chosePartTime }, ctx) => {
    if (hoursPerWeek < 30 && !chosePartTime) {
      ctx.addIssue({
        path: ["chosePartTime"],
        code: z.ZodIssueCode.custom,
        message: "Required",
      });
    }
  });

type Inputs = z.infer<typeof schema>;

export function EmploymentInformationStep(props: Props) {
  const { surveyInfo, progress, onNextClick, onBackClick } = props;
  const [state, setState] = useSurveyStore((store) => [
    store.employmentInformation,
    store.setEmploymentInformation,
  ]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: state,
    resolver: zodResolver(schema),
  });

  const showChosePartTime =
    watch("hoursPerWeek") !== null &&
    !Number.isNaN(watch("hoursPerWeek")) &&
    watch("hoursPerWeek") < 30;

  const handleAddressAutocomplete = (result: AutocompleteAddress) => {
    if (!result) return;
    setValue("employerAddress", result.address);
    setValue("employerCity", result.city);
    setValue("employerState", result.state);
    setValue("employerZipCode", result.zip);
  };

  const handleBackClick = () => {
    setState(getValues());
    onBackClick();
  };

  const handleNextClick = async () => {
    await handleSubmit((data) => {
      setState(data);
      onNextClick();
    })();
  };

  return (
    <Card progress={progress}>
      <StepHeader surveyInfo={surveyInfo} />

      <Form>
        <TextInput
          label="What is your current job title?"
          placeholder="Job Title"
          error={errors?.jobTitle?.message}
          {...register("jobTitle")}
        />
        <TextInput
          type="number"
          label="Hours per week"
          placeholder="Hours per week"
          error={errors?.hoursPerWeek?.message}
          {...register("hoursPerWeek", { valueAsNumber: true })}
        />
        {showChosePartTime && (
          <RadioGroup<Exclude<Inputs["chosePartTime"], null>>
            vertical
            align="left"
            label="Did you specifically choose to seek part-time employment rather than full time employment after graduation?"
            options={[
              { label: "Yes", value: "YES" },
              { label: "No", value: "NO" },
            ]}
            error={errors?.chosePartTime?.message}
            {...register("chosePartTime")}
          />
        )}
        <RadioGroup<Inputs["duration"]>
          vertical
          align="left"
          label="What is the expected duration of this job?"
          options={[
            { label: "Less than 3 months", value: "Less than 3 months" },
            { label: "3-6 months", value: "3-6 months" },
            { label: "6+ months", value: "6+ months" },
          ]}
          error={errors?.duration?.message}
          {...register("duration")}
        />
        <RadioGroup<Inputs["longerThanFiveWeeks"]>
          vertical
          align="left"
          label="Do you expect the job to last longer than 5 weeks?"
          options={[
            { label: "Yes", value: "YES" },
            { label: "No", value: "NO" },
          ]}
          error={errors?.longerThanFiveWeeks?.message}
          {...register("longerThanFiveWeeks")}
        />
        <TextInput
          type="date"
          label="When did you sign their offer letter?"
          placeholder="YYYY-MM-DD"
          error={errors?.offerDate?.message}
          {...register("offerDate")}
        />
        <TextInput
          type="date"
          label="What is/was your start date?"
          placeholder="YYYY-MM-DD"
          error={errors?.startDate?.message}
          {...register("startDate")}
        />
        <RadioGroup<Inputs["salaryType"]>
          vertical
          align="left"
          label="Which of the following best describes how you will get paid?"
          options={[
            { label: "Annual Salary", value: "Annual" },
            { label: "Hourly Wage", value: "Hourly" },
            { label: "Weekly Compensation", value: "Weekly" },
          ]}
          error={errors?.salaryType?.message}
          {...register("salaryType")}
        />
        <TextInput
          type="number"
          label="What is your salary? (please exclude dollar signs and commas: for example, 70000)"
          placeholder="Salary"
          error={errors?.salary?.message}
          {...register("salary", { valueAsNumber: true })}
        />
        <RadioGroup<Inputs["hiredBySchool"]>
          vertical
          align="left"
          label="For this position, were you hired by your school, by an employer owned by the school, or by an employer who shares ownership with the school?"
          options={[
            { label: "Yes", value: "YES" },
            { label: "No", value: "NO" },
          ]}
          error={errors?.hiredBySchool?.message}
          {...register("hiredBySchool")}
        />
        <RadioGroup<Inputs["placedBySchool"]>
          vertical
          align="left"
          label="For this position, were you placed by your school?"
          options={[
            { label: "Yes", value: "YES" },
            { label: "No", value: "NO" },
          ]}
          error={errors?.placedBySchool?.message}
          {...register("placedBySchool")}
        />
        <TextInput
          label="Name of the firm/company"
          placeholder="Name"
          error={errors?.employerName?.message}
          {...register("employerName")}
        />
        <TextInput
          label="Website of the firm/company"
          placeholder="Website"
          error={errors?.employerWebsite?.message}
          {...register("employerWebsite")}
        />
        <TextInput
          label="Address of the firm/company"
          placeholder="Address"
          autoComplete
          onPlaceSelected={handleAddressAutocomplete}
          error={errors?.employerAddress?.message}
          {...register("employerAddress")}
        />
        <TextInput
          label="City of the firm/company"
          placeholder="City"
          error={errors?.employerCity?.message}
          {...register("employerCity")}
        />
        <TextInput
          label="State of the firm/company"
          placeholder="State"
          error={errors?.employerState?.message}
          {...register("employerState")}
        />
        <TextInput
          label="Zip Code of the firm/company"
          placeholder="Zip code"
          error={errors?.employerZipCode?.message}
          {...register("employerZipCode")}
        />
        <TextInput
          type="tel"
          label="Telephone number of the firm/company"
          placeholder="Telephone Number"
          error={errors?.employerPhone?.message}
          {...register("employerPhone")}
        />
        <TextInput
          label="Your immediate supervisor's full name"
          placeholder="Supervisor's Full Name"
          error={errors?.employerContactName?.message}
          {...register("employerContactName")}
        />
        <TextInput
          type="tel"
          label="Your immediate supervisor's telephone number"
          placeholder="Supervisor's Telephone Number"
          error={errors?.employerContactPhone?.message}
          {...register("employerContactPhone")}
        />
        <TextInput
          label="Your immediate supervisor's email"
          placeholder="Supervisor's Email"
          error={errors?.employerContactEmail?.message}
          {...register("employerContactEmail")}
        />
      </Form>

      <ButtonArea>
        <Button label="Back" onClick={handleBackClick} />
        <Button primary label="Continue" onClick={handleNextClick} />
      </ButtonArea>
    </Card>
  );
}
