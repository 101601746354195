import { requestClient } from "lib/api";
import qs from "query-string";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import { useSurveyStore } from "../store";
import { mapStoreToFormData } from "../mapStoreToFormData";

type QueryParams = {
  token: string;
};

const RequiredDocumentsSchema = z.array(z.string());

export function useRequiredDocuments() {
  const location = useLocation();
  const { token } = qs.parse(location.search) as QueryParams;
  const [setHasDocumentsToSign] = useSurveyStore((store) => [
    store.setHasDocumentsToSign,
  ]);

  const query = useQuery([], async () => {
    const formData = mapStoreToFormData();
    const response = await requestClient().post(
      `/surveys/graduate_follow_up/documents?token=${token}`,
      formData
    );

    const documents = RequiredDocumentsSchema.parse(response.data.data);
    setHasDocumentsToSign(documents.length > 0);
    return documents;
  });

  const isLoading = query.isLoading;
  const isError =
    !query.isLoading && (query.isError || query.data === undefined);
  const data = query.data;

  return { isLoading, isError, data };
}
