/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import { Collapse, Table, Spinner } from "react-bootstrap";
import { ArrowUp, ArrowDown } from "../../../common/icons";

function CollapseMapping({
  item = {},
  id = "",
  columns,
  step,
  isLoadingTable,
}) {
  const [open, setOpen] = React.useState(false);
  const formatColumns = {};
  columns.forEach((col) => {
    formatColumns[col.dataIndex] = col;
  });

  return (
    <div
      className={classNames("CollapseMapping", {
        CollapseMapping__step3: step === 3,
      })}
    >
      <div
        onClick={() => setOpen(!open)}
        role="button"
        tabIndex="0"
        className="CollapseMapping__button"
      >
        <p className="text--xlarge bold">{item.title}</p>
        <div className="iconArrow">{open ? <ArrowUp /> : <ArrowDown />}</div>
      </div>
      <Collapse in={open}>
        <div className="CollapseMapping__table">
          <Table>
            <tbody>
              {item.data.map((row, i) => (
                <tr key={`tr-${id || i}-${row.id}`}>
                  {columns.map((col, index) => (
                    <td
                      // eslint-disable-next-line react/no-array-index-key
                      key={`tr-${id || i}-${row.id}-td-${col.id || index}`}
                      className={`text__${
                        formatColumns[col.dataIndex].align || "left"
                      }`}
                    >
                      {formatColumns[col.dataIndex].render
                        ? formatColumns[col.dataIndex].render(row)
                        : row[col.dataIndex]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          {isLoadingTable && (
            <div className="loading">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
}

export default CollapseMapping;
