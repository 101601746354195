import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { useLocation } from "react-router-dom";
import { DataSync } from "../../../../components/dashboard/institution";
import { createSalesforceSync } from "../../../../redux/actions/integration";
import { openMessage } from "../../../../redux/actions/message";

export default function () {
  const dispatch = useDispatch();
  const location = useLocation();
  const dataSync = location.state;
  const storeSalesforceSync = useSelector(
    (store) => store.integration.salesforce.sync
  );
  const isSubmitting = storeSalesforceSync.loading;

  const handleResyncData = async (event) => {
    event.preventDefault();
    try {
      await dispatch(createSalesforceSync());
      dispatch(
        openMessage({
          title: " Sync was successful",
          type: "success",
        })
      );
    } catch (err) {
      dispatch(
        openMessage({
          title: "Something went wrong, please try again!",
          type: "error",
        })
      );
    }
  };
  return (
    <DataSync
      dataSync={dataSync}
      onSubmit={handleResyncData}
      isSubmitting={isSubmitting}
    />
  );
}
